import React from "react";

const PrivacyPolicyOtherCompany = () => {
    return (
        <>
            <section>
                <p>
                    (주)그린잇(이하 "회사")은 제휴 골프장 예약 건에 한하여
                    회원의 동의를 받은 후 개인정보를 제3자에게 제공합니다. 본
                    약관 외에 회원의 별도 동의가 있거나 법령에 규정된 경우를
                    제외하고는 회원의 개인정보를 제3자에게 제공하지 않습니다.
                </p>
                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                    <colgroup>
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "28%" }} />
                        <col style={{ width: "28%" }} />
                        <col style={{ width: "24%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>제공받는 자</th>
                            <th>이용 목적</th>
                            <th>제공 항목</th>
                            <th>제공받는 자의 보유 및 이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="alignC">서원힐스 컨트리클럽</td>
                            <td rowSpan="4">
                                캐디 및 사물함 배정, 정산 내용 확인
                            </td>
                            <td rowSpan="4">
                                <strong className="underLine">
                                    체크인 여부, 결제정보(결제항목, 결제금액,
                                    결제카드, 동반자 정보(이름, 휴대전화번호,
                                    성별)
                                </strong>
                            </td>
                            <td rowSpan="4">
                                <strong className="underLine">
                                    서비스 제공 목적 달성시까지 단, 관계 법령이
                                    정한 시점까지 보존
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td className="alignC">포도 컨트리클럽</td>
                        </tr>
                        <tr>
                            <td className="alignC">센테리움 컨트리클럽</td>
                        </tr>
                        <tr>
                            <td className="alignC">클럽디금강 컨트리클럽</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </>
    );
};

export default PrivacyPolicyOtherCompany;
