import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {PrivateRoute} from './_components/PrivateRoute';
import Home from './components/home/Home';
import Login from './components/membership/Login';
import Main from './components/main/Main';
import Membership from './components/membership/Membership';
import Authentication from './components/membership/Authentication';
import SettingIdPw from './components/membership/SettingIdPw';
import JoinComplete from './components/membership/JoinComplete';
import JoinKakao from './components/membership/JoinKakao';
// import SnsJoinComplete from './components/membership/SnsJoinComplete';
// import KakaoCallback from './components/auth/kakao/Callback';
import KakaoMessage from './components/test/KakaoMessage';
import Profile from './components/auth/kakao/Profile';
// import FindId from './components/membership/FindId';
import FindIdResult from './components/membership/FindIdResult';
import FindPW from './components/membership/FindPW';
import SettingPw from './components/membership/ModifyPw';
import FindPwResult from './components/membership/FindPwResult';
import Food from './components/food/Food';
import Booking from './components/round-booking/Booking';
import ReservationAgree from './components/agreement/ReservationAgree';
import ServiceAgree from './components/mypage/agreement/ServiceAgree';
import ServiceAgreePop from './components/agreement/popup/ServiceAgreePop';
import PersonalInformationPop from './components/agreement/popup/PersonalInformationPop';
import PrivacyPolicyOtherCompanyPop from './components/agreement/popup/PrivacyPolicyOtherCompanyPop';
import MarketingPolicyPop from './components/agreement/popup/MarketingPolicyPop';
import ClubBoard from './components/club-board/ClubBoard';
import ClubBoardDetail from './components/club-board/ClubBoardDetail';
import AppPermission from './components/agreement/AppPermission';
import Search from './components/search/Search';
import Score from './components/score/Score';
import MyPage from './components/mypage/MyPage';
import CS from './components/mypage/CS';
import MyInfoModify from './components/mypage/profile/MyInfoModify';
import MyBooking from './components/mypage/booking/Booking';
import MyBookingDetail from './components/mypage/booking/BookingDetail';
import AuthenticationNumber from './components/mypage/Authentication';
import AuthPw from './components/mypage/profile/AuthPw';
import ModifyPw from './components/mypage/profile/ModifyPw';
import Withdrawal from './components/mypage/Withdrawal';
import MyCardList from './components/mypage/card/MyCardList';
import SetAlarm from './components/mypage/SetAlarm';
import Policy from './components/mypage/Policy';
import CourseGuide from './components/course-guide/CourseGuide';
import Company from './components/mypage/Company';
import Alarm from './components/alarm/Alarm';
import GatePage from './components/gatePage/GatePage';
import GateApp from './components/gatePage/GateApp';
import GateAppInvite from './components/gatePage/GateAppInvite';
import {pageTitle, subTitle} from './common/messages';
import LocationService from './components/mypage/agreement/LocationService';
import Error404 from './components/error/Error404';
import AppBoard from './components/mypage/app-board/AppBoard';
import AppBoardDetail from './components/mypage/app-board/AppBoardDetail';
import PrivacyPolicy from './components/agreement/PrivacyPolicy';
import Intro from './components/intro/Intro';
import DownloadInfo from './components/download/DownloadInfo';
import AttendeeList from './components/attendee/AttendeeList';
import {RouteIf} from './_components/RouteIf';
import OpenLicense from './components/mypage/agreement/OpenLicense';
import {userActions} from './_store';
import {useDispatch} from 'react-redux';
import {localStorageUtil} from './common/utils';
import FilterTest from './components/reservation/FilterTest';
import FieldMain from './components/reservation/FieldMain';
import FieldTeaTime from './components/reservation/OutdoorDetail/FieldTeaTime';
import OutdoorDetail from './components/reservation/OutdoorDetail/OutdoorDetail';
import GolfClubGatePage from './components/gatePage/GolfClubGatePage';

//예약하기 페이지 import
import FieldBooking from './components/reservation/field-booking/FieldBooking';
//예약완료 페이지 import
import FieldBookingComplete from './components/reservation/field-booking/FieldBookingComplete';
import Redirect from './_components/Redirect';
import SearchResult from './components/search/SearchResult';
import GateAppShare from './components/gatePage/GateAppShare';
import Favorites from './components/mypage/favorites/Favorites';
import GateAppAlarm from './components/gatePage/GateAppAlarm';
import AlarmTalk from './components/test/AlarmTalk';
import JoinApple from './components/membership/JoinApple';
import KakaoWelcome from './components/membership/KakaoWelcome';
import KakaoFound from './components/membership/KakaoFound';
import LeftMember from './components/membership/LeftMember';
import GateAppAlarmSM from './components/gatePage/GateAppAlarmSM';
import NotFoundAccount from './components/membership/NotFoundAccount';
import WithdrawalComplete from './components/mypage/WithdrawalComplete';
import GateAppAlarmCourse from './components/gatePage/GateAppAlarmCourse';
import SmartRoundGuide from './components/smart-round/SmartRoundGuide';
import LocationPolicyPop from './components/agreement/popup/LocationPolicyPop';
import ErrorLogin from './components/error/ErrorLogin';
import GateAppHome from './components/gatePage/GateAppHome';
import GetMyReservation from './components/mypage/get-my-reservation/GetMyReservation';
import SuccessConnection from './components/mypage/get-my-reservation/SuccessConnection';
import GetReservationInfoAgree from './components/agreement/reservation/GetReservationInfoAgree';
import MyReservationAgreePop from './components/agreement/popup/MyReservationAgreePop';
import GateAppSelfCheckInGuide from './components/gatePage/GateAppSelfCheckInGuide';
import LinkedGolfClub from './components/mypage/LinkedGolfClub';
import LinkedGolfClubV2 from './components/mypage/LinkedGolfClubV2'; // 로그인시 에러 발생 라우팅 페이지
import SmartRoundV2 from 'components/SmartRoundV2';
import MaintainanceNotice from 'components/maintainance-notice';

import {rootTaxonomy} from './_helpers/amplitude/taxonomy/root.taxonomy';
import NoPhoneUser from 'components/membership/NoPhoneUser';

import {paymentRoutes} from './routes/paymentRoutes';

function AppRoutes({}) {
    const dispatch = useDispatch();

    const location = useLocation();
    window.ReactNativeWebView?.postMessage(JSON.stringify({webViewPath: location.pathname}));

    let userData = localStorageUtil.get('user');
    if (!userData) {
        localStorageUtil.remove('user');
    }
    useEffect(() => {
        userData && dispatch(userActions.getAll());
    }, [dispatch]);

    const pathList = [
        {
            path: '/',
            element: <Home />,
            isLogin: true
        },
        {
            path: '/login',
            element: <Login />,
            showBack: true,
            isLogin: true
        },
        {
            path: '/main',
            element: <Main />,
            isLogin: true
        },
        // === 멤버쉽 ===
        {
            path: '/member',
            element: <Membership />,
            isLogin: false
        },
        {
            path: '/member/join',
            element: <Authentication title={pageTitle.join} />,
            isLogin: false
        },
        {
            path: '/member/join/:channel',
            element: <Authentication title={pageTitle.join} />,
            isLogin: false
        },
        {
            path: '/member/join/setting',
            element: <SettingIdPw title={pageTitle.joinProcess} />,
            isLogin: false
        },
        {
            path: '/member/join/complete',
            element: <JoinComplete />,
            isLogin: true
        },
        // {
        //     path: '/member/join/kakao',
        //     element: <JoinKakao title={pageTitle.SnsJoin} />,
        //     isLogin: false
        // },
        // {
        //     path: '/member/join/sns/complete',
        //     element: <SnsJoinComplete title={pageTitle.snsJoinResult} />,
        //     isLogin: false
        // },
        {
            path: '/member/welcome/kakao',
            element: <JoinComplete />,
            // element: <JoinComplete />,
            isLogin: true
        },
        {
            path: '/auth/kakao/callback',
            // element: <KakaoCallback />,
            element: <JoinKakao title={pageTitle.SnsJoin} />,
            isLogin: true
        },
        {
            path: '/auth/kakao/profile',
            element: <Profile />,
            isLogin: true
        },
        {
            path: '/member/join/apple/agree',
            // element: <KakaoCallback />,
            element: <JoinApple title={pageTitle.AppleJoin} />,
            isLogin: false
        },
        {
            path: '/member/login',
            element: <Login showBack={true} />,
            isLogin: false
        },
        // ==== [채널톡] 고객센터 게이트 페이지를 통해 로그인할 경우
        {
            path: '/member/login/cs/:csStatus',
            element: <Login showBack={true} cs={true} />,
            isLogin: false
        },
        // ==== [예약] 동반자 초대 링크를 통해 로그인 한 경우
        {
            path: '/member/login/invite/:inviteChannel/:bookingId/:bookingMemberId',
            element: <Login showBack={true} />,
            isLogin: false
        },
        // ==== [필드] 공유하기 링크를 통해 로그인할 경우
        {
            path: '/member/login/share/:golfclubId/:shareStatus',
            element: <Login showBack={true} share={true} />,
            isLogin: false
        },
        // ==== [필드] 알림톡 링크를 통해 로그인할 경우
        {
            path: '/member/login/alarm/:alarmStatus/:alarmId',
            element: <Login showBack={true} alarm={true} />,
            isLogin: false
        },
        // ==== [필드] 알림톡 > 스마트라운드 링크를 통해 로그인할 경우
        {
            path: '/member/login/alarm/:smStatus/:smGolfClubId/:smBookingId/:smClubName',
            element: <Login showBack={true} sm={true} />,
            isLogin: false
        },
        // ==== [필드] 알림톡 > 코스 정보 링크를 통해 로그인할 경우
        {
            path: '/member/login/alarm-course/:courseStatus/:courseGolfClubId/:courseCode/:mapControlYN',
            element: <Login showBack={true} course={true} />,
            isLogin: false
        },
        // findID && findPW 통합
        // {
        //     path: '/member/find-id',
        //     element: <FindId title={pageTitle.findId} />,
        //     isLogin: false
        // },
        {
            path: '/member/found/:channel',
            element: <KakaoFound title={pageTitle.findIdResult} titleTxt={subTitle.findIdResult} />,
            isLogin: false
        },
        // sns 간편로그인 탈퇴 후 접속 url
        {
            path: '/member/left/:channel',
            element: <LeftMember title={pageTitle.withdrawnAccount} titleTxt={subTitle.withdrawnAccount} />,
            isLogin: false
        },
        // sns 로그인 진행 중, 이미 사용중인 이메일인 경우
        {
            path: '/member/existingId/:channel',
            element: <LeftMember title={pageTitle.existingId} titleTxt={subTitle.existingId} />,
            isLogin: false
        },
        // 핸드폰 번호 없는 유저인 경우
        {
            path: '/member/no-phone',
            element: <NoPhoneUser title={pageTitle.noPhoneUser} titleTxt={subTitle.existingId} />,
            isLogin: false
        },
        // 개인정보 찾기에서 조회된 정보가 없는 경우
        {
            path: '/member/notFoundAccount',
            element: <NotFoundAccount title={pageTitle.notFoundAccount} titleTxt={subTitle.notFoundAccount} />,
            isLogin: false
        },
        // 내 예약정보 가져오기 연동 완료
        {
            path: '/myReservation/success',
            element: <SuccessConnection title={pageTitle.successConnection} titleTxt={subTitle.successConnection} />,
            isLogin: false
        },
        {
            path: '/member/find-id/result',
            element: <FindIdResult title={pageTitle.findIdResult} titleTxt={subTitle.findIdResult} />,
            isLogin: false
        },
        // {
        //     path: '/member/find-pw',
        //     element: <FindPW title={pageTitle.findPw} />,
        //     isLogin: false
        // },
        {
            path: '/member/find-id',
            element: <FindPW title={pageTitle.authentication_common} type={'id'} />,
            isLogin: false
        },
        {
            path: '/member/find-pw',
            element: <FindPW title={pageTitle.authentication_common} type={'pw'} />,
            isLogin: false
        },
        {
            path: '/member/find-pw/setting-pw',
            element: <SettingPw title={pageTitle.settingPw} />,
            isLogin: false
        },
        {
            path: '/member/find-pw/result',
            element: <FindPwResult title={pageTitle.findPwResult} />,
            isLogin: false
        },
        {
            path: '/food',
            element: <Food usePath={rootTaxonomy?.smartround} />,
            isLogin: true
        },
        {
            path: '/booking',
            element: <Booking title={pageTitle.smartRound} />,
            isLogin: true
        },
        {
            path: '/smart-round-guide',
            element: <SmartRoundGuide />,
            isLogin: true
        },
        {
            // 현재 미사용 페이지
            path: '/reservation-agree',
            element: <ReservationAgree title={pageTitle.bkgAgreement} />,
            isLogin: true
        },
        {
            path: '/service-agree',
            element: <ServiceAgree title={pageTitle.serviceAgree} />,
            isLogin: false
        },
        {
            path: '/club-board/:id',
            element: <ClubBoard />,
            isLogin: true
        },
        {
            path: '/club-board/detail',
            element: <ClubBoardDetail />,
            isLogin: true
        },
        {
            path: '/permission',
            element: <AppPermission title={pageTitle.appPermission} />,
            isLogin: true
        },
        {
            path: '/search',
            element: <Search title={pageTitle.search} />,
            isLogin: true
        },
        {
            path: '/search-result',
            element: <SearchResult />,
            isLogin: true
        },
        {
            path: '/score',
            element: <Score title={pageTitle.score} />,
            isLogin: true
        },
        // === 내정보 ===
        {
            path: '/mypage',
            element: <MyPage />,
            isLogin: true
        },
        {
            path: '/cs',
            element: <CS />,
            isLogin: true
        },
        {
            path: '/mypage/modify',
            element: <MyInfoModify />,
            isLogin: true
        },
        {
            path: '/mypage/booking',
            element: <MyBooking />,
            isLogin: true
        },
        {
            path: '/mypage/favorites',
            element: <Favorites />,
            isLogin: true
        },
        {
            path: '/mypage/booking/detail',
            element: <MyBookingDetail />,
            isLogin: true
        },
        {
            path: '/mypage/auth-phone',
            element: <AuthenticationNumber title={pageTitle.authNumber} />,
            isLogin: true
        },
        {
            path: '/mypage/auth-pw',
            element: <AuthPw title={pageTitle.authPw} />,
            isLogin: true
        },
        {
            path: '/mypage/modify-pw',
            element: <ModifyPw title={pageTitle.settingPw} />,
            keyName: 'userMail', // -> RouteIf
            isLogin: true
        },
        {
            path: '/mypage/withdrawal',
            element: <Withdrawal title={pageTitle.withdrawal} />,
            isLogin: true
        },
        {
            path: '/mypage/withdrawal-complete',
            element: <WithdrawalComplete title={pageTitle.withdrawalComplete} titleTxt={subTitle.withdrawalComplete} />,
            isLogin: false
        },
        {
            path: '/mypage/my-card',
            element: <MyCardList title={pageTitle.myCard} />,
            isLogin: true
        },
        {
            path: '/mypage/app-board',
            element: <AppBoard />,
            isLogin: true
        },
        {
            path: '/mypage/app-board/:id',
            element: <AppBoardDetail />,
            isLogin: true
        },
        {
            path: '/mypage/setting-alarm',
            element: <SetAlarm title={pageTitle.pushSetting} />,
            isLogin: true
        },
        {
            path: '/mypage/policy',
            element: <Policy />,
            isLogin: true
        },
        {
            path: '/linked-golfclub',
            element: <LinkedGolfClub title={pageTitle.linkedgc} />,
            isLogin: false
        },
        {
            path: '/linked-golfclub-v2',
            element: <LinkedGolfClubV2 title={pageTitle.linkedgc} />,
            isLogin: true
        },
        {
            path: '/company',
            element: <Company title={pageTitle.company} />,
            isLogin: true
        },
        {
            path: '/alarm',
            element: <Alarm title={pageTitle.alarm} />,
            isLogin: true
        },
        {
            path: '/course-guide',
            element: <CourseGuide title={pageTitle.courseGuide} />,
            keyName: 'roundData',
            isLogin: true
        },
        // 예약정보 동의 페이지
        {
            path: '/get-my-reservation',
            element: <GetMyReservation title={pageTitle.getMyReservation} />,
            isLogin: true
        },
        // === 게이트 페이지 ===
        {
            path: '/gate-page',
            element: <GatePage />,
            isLogin: false
        },
        // ==== [홈]
        {
            path: '/gate-main',
            element: <GateAppHome />,
            isLogin: false
        },
        // ==== [채널톡]
        {
            path: '/gate-app/:cs',
            element: <GateApp />,
            isLogin: false
        },
        // ==== [예약] 동반자 초대 gate-app
        {
            path: '/gate-app/:inviteChannel/:bookingId/:bookingMemberId',
            element: <GateAppInvite />,
            isLogin: false
        },
        // ==== [필드] 공유하기 gate-app
        {
            path: '/gate-app/share/:golfClubId/:shareStatus',
            element: <GateAppShare />,
            isLogin: false
        },
        // ==== [공통] 알림톡 gate-app
        {
            path: '/gate-alarm/alarm/:status/:golfClubId/:bookingId',
            element: <GateAppAlarm />,
            isLogin: false
        },
        // ==== [공통] 알림톡 > 스마트라운드 & 공지사항 gate-app
        {
            path: '/gate-sm/alarm/:status/:golfClubId/:bookingId/:golfClubName',
            element: <GateAppAlarmSM />,
            isLogin: false
        },
        // ==== [공통] 알림톡 > 셀프체크인 가이드
        {
            path: '/gate-selfCh/:status/:golfClubId/:bookingId/:golfClubName',
            element: <GateAppSelfCheckInGuide />,
            isLogin: false
        },
        // ==== [공통] 알림톡 > 코스 정보 gate-app
        {
            path: '/gate-course/alarm/:status/:golfClubId/:courseCode/:mapControlYN/:bookingId',
            element: <GateAppAlarmCourse />,
            isLogin: false
        },
        // 알림톡 테스트
        {
            path: '/alarm-talk',
            element: <AlarmTalk />,
            isLogin: false
        },
        {
            path: '/gate-app/:bookingId/:bookingMemberId',
            element: <GateApp />,
            isLogin: false
        },
        {
            path: '/location-service_policy',
            element: <LocationService />,
            isLogin: true
        },
        // === 위치기반 서비스 이용약관 동의 ===
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />,
            isLogin: false
        },
        {
            path: '/open-license',
            element: <OpenLicense title={pageTitle.openLicense} />,
            isLogin: true
        },
        // 예약정보 조회,전송 동의 약관
        {
            path: '/get-reservation-agree',
            element: <MyReservationAgreePop title={pageTitle.reservationAgree} />,
            isLogin: true
        },
        // === 개인정보처리방침 ===
        {
            path: '/error',
            element: <Error404 />,
            isLogin: false
        },
        // 2022.04.25 로그인 에러 페이지 추가
        {
            path: '/error-login',
            element: <ErrorLogin />,
            isLogin: false
        },
        {
            path: '/intro',
            element: <Intro />,
            isLogin: true
        },
        {
            path: '/download',
            element: <DownloadInfo />,
            isLogin: true
        },
        {
            path: '/attendee-list',
            element: <AttendeeList />,
            isLogin: true
        },
        {
            path: '*',
            element: <Navigate to="/error" />,
            isLogin: true
        },
        // === 카카오 가입용 약관(윈도우 새창 닫기 이벤트 적용) ===
        {
            path: '/service-agree-pop',
            element: <ServiceAgreePop title={pageTitle.serviceAgree} />,
            isLogin: false
        },
        {
            path: '/personal-info-pop',
            element: <PersonalInformationPop title={pageTitle.personalInfo} />,
            isLogin: false
        },
        {
            path: '/privacy-policy-other-pop',
            element: <PrivacyPolicyOtherCompanyPop title={pageTitle.privacyPolicyOther} />,
            isLogin: false
        },
        {
            path: '/marketing-policy-pop',
            element: <MarketingPolicyPop title={pageTitle.marketingPolicy} />,
            isLogin: false
        },
        {
            path: '/location-policy-pop',
            element: <LocationPolicyPop title={pageTitle.locationService} />,
            isLogin: false
        },
        {
            path: '/test',
            element: <KakaoMessage title="카카오 테스트" />,
            isLogin: false
        },
        // === 예약 ===
        {
            path: '/golf-club-gate-page',
            element: <GolfClubGatePage />,
            isLogin: false
        },
        {
            path: '/main',
            element: <FieldMain />,
            isLogin: true
        },
        {
            path: '/field-tea-time/:id',
            element: <FieldTeaTime />,
            isLogin: true
        },
        {
            path: '/field-detail/:id',
            element: <OutdoorDetail />,
            isLogin: true
        },
        {
            path: '/drawer-test',
            element: <FilterTest />, // TODO 테스트 후 삭제
            isLogin: false
        },
        //예약하기 페이지
        {
            path: '/field-booking/:id',
            element: <FieldBooking title={pageTitle.booking} />,
            isLogin: true
        },
        //예약완료하기 페이지
        {
            path: '/field-booking-complete',
            element: <FieldBookingComplete title={pageTitle.bookingComplete} />,
            isLogin: true
        },
        // 외부링크로 리다이렉트
        {
            path: '/redirect',
            element: <Redirect />,
            isLogin: false
        },
        // 스마트라운드 UX개선
        {
            path: '/self-check-in-v2',
            element: <SmartRoundV2 />,
            isLogin: true
        },
        {
            path: '/partner-v2',
            element: <SmartRoundV2 />,
            isLogin: true
        },
        {
            path: '/course-guide-v2',
            element: <SmartRoundV2 />,
            isLogin: true
        },
        {
            path: '/food-v2',
            element: <SmartRoundV2 />,
            isLogin: true
        },
        {
            path: '/maintainance-notice',
            element: <MaintainanceNotice />,
            isLogin: true
        },
        /*
        =======================================================================
                              결제/정산 추가되는 페이지 라우팅
        =======================================================================
        */
        ...paymentRoutes
    ];

    return (
        <Routes>
            {pathList.map((v, idx) => {
                if (v.isLogin) {
                    return (
                        <Route
                            key={idx}
                            path={v?.path}
                            element={
                                <PrivateRoute
                                    children={
                                        v?.keyname ? <RouteIf children={v.element} keyName={v.keyName} /> : v?.element
                                    }
                                />
                            }
                        />
                    );
                } else {
                    return <Route key={idx} path={v?.path} element={v?.element} />;
                }
            })}
        </Routes>
    );
}

export default AppRoutes;
