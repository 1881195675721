import React from "react";
import { appUtils } from "../../../common/utils";

function FinishedDetailPay({ data }) {
    return (
        <>
            <div className="gap"></div>
            <section className="section">
                <div className="infoType01">
                    <div className="totalArea">
                        <dl>
                            <dt>내 결제금액</dt>
                            <dd>{appUtils.numberFormat(data.totalAmount)}원</dd>
                        </dl>
                    </div>
                    <div>
                        <dl>
                            <dt>
                                그린피{" "}
                                <span>본인+동반자 1인 [150,000 x 2]</span>
                            </dt>
                            <dd>
                                {" "}
                                {appUtils.numberFormat(data.greenAmount)}원
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                카트피 <span>본인+동반자 1인 [20,000 x 2]</span>
                            </dt>
                            <dd> {appUtils.numberFormat(data.cartAmount)}원</dd>
                        </dl>
                        <dl>
                            <dt>
                                식음료{" "}
                                <span>
                                    [조식] 서양식 조식 [15,000 x 2]
                                    <br />
                                    카스 맥주 [5,000원 x 2]
                                </span>
                            </dt>
                            <dd> {appUtils.numberFormat(data.foodAmount)}원</dd>
                        </dl>
                        <dl>
                            <dt>
                                할인 금액 <span>스코어 최저타 혜택</span>
                            </dt>
                            <dd>
                                {" "}
                                {appUtils.numberFormat(data.disCountAmount)}원
                            </dd>
                        </dl>
                    </div>
                    <div className="totalPayment">
                        <dl>
                            <dt>
                                총 결제 금액 <span>삼성카드 · 일시불</span>
                            </dt>
                            <dd>
                                {" "}
                                {appUtils.numberFormat(
                                    data.totalAmount - data.disCountAmount
                                )}
                                원<span>290,000원</span>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="tooltipSection">
                    <strong className="tit">비용 정산 및 결제 안내</strong>
                    <ul className="listType02">
                        <li>
                            골라가는 제휴 골프장 예약에 대한 간편 결제를
                            대행하는 자로서 청구 받는 당사자가 아니며, 결제 관련
                            사항에 대해 책임을 지지 않습니다.
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
}

export default FinishedDetailPay;
