import {smartRoundActions} from '_store/smartRound.slice';
import moment from 'moment';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {fetchWrapper, history} from '../../_helpers';
import {getTodayBooking} from '../../_store/checkin.slice';
import {NO_PHONE_USER, SUCCESS_CODE} from '../../common/constants';
import {inviteUtils} from '../../common/inviteUtils';
import {localStorageUtil} from '../../common/utils';
import Main from '../main/Main';

function Home() {
    const dispatch = useDispatch();
    const [inviteProcessing, setInviteProcessing] = useState(false);
    const {todayReserv, todayReservData, rendering} = useSelector((x) => x.checkin);
    const member = useSelector((x) => x?.users?.users);

    useMemo(() => {
        dispatch(getTodayBooking());
    }, []);

    let skip = JSON.parse(localStorageUtil.get('skip'));

    // let isSkip = skip?.bookingId && todayReservData?.bookingId === skip.bookingId;

    let isSkip =
        skip?.length > 0
            ? skip?.findIndex(
                  (item) =>
                      item?.bookingId === todayReservData?.bookingId &&
                      todayReservData?.date === moment(new Date()).format('YYYYMMDD')
              ) !== -1
            : false;

    // 유저의 핸드폰 번호 여부 체크 후 인증 페이지로 이동
    const getMemberPhone = () => {
        fetchWrapper.get(`/member/phone/check`, null).then((response) => {
            if (response?.code === NO_PHONE_USER) {
                history.navigate('/member/no-phone');
            }
        });
    };

    useEffect(() => {
        getMemberPhone();
    }, []);

    useEffect(() => {
        // 이메일/카카오톡/애플 로그인 시 바로 Home으로 이동 > localStorage 확인 후 경로 이동
        const joinInviteAction = JSON.parse(localStorageUtil.get('joinInviteAction'));
        const joinShareAction = JSON.parse(localStorageUtil.get('joinShareAction'));
        const joinAlarmAction = JSON.parse(localStorageUtil.get('joinAlarmAction'));
        const joinSMAction = JSON.parse(localStorageUtil.get('joinSMAction'));
        const joinCourseAction = JSON.parse(localStorageUtil.get('joinCourseAction'));
        const joinClubBoardAction = JSON.parse(localStorageUtil.get('joinClubBoardAction'));
        const joinCSAction = JSON.parse(localStorageUtil.get('joinCSAction'));
        const joinSelfChInGuideAction = JSON.parse(localStorageUtil.get('joinSelfChInGuideAction'));
        const joinSelfChInPromotionEvent = JSON.parse(localStorageUtil.get('joinSelfChInPromotionEvent'));

        if (
            joinInviteAction ||
            joinShareAction ||
            joinAlarmAction ||
            joinSMAction ||
            joinCourseAction ||
            joinClubBoardAction ||
            joinCSAction ||
            joinSelfChInGuideAction ||
            joinSelfChInPromotionEvent
        ) {
            setTimeout(() => {
                setInviteProcessing(true);
                setTimeout(() => {
                    setInviteProcessing(false);
                }, 500);

                // 동반자 초대
                if (joinInviteAction) {
                    const {inviteChannel, bookingId, bookingMemberId} = joinInviteAction.inviteProp;
                    localStorageUtil.remove('joinInviteAction');
                    inviteUtils.inviteProc(bookingId, bookingMemberId).then((res) => {
                        setInviteProcessing(false);
                    });
                }

                // 공유하기
                if (joinShareAction) {
                    // console.log('#### joinShareAction ####');
                    const {golfClubId, shareStatus} = joinShareAction.shareProp;
                    localStorageUtil.remove('joinShareAction');
                    history.navigate(`/field-detail/${golfClubId}`);
                }

                // 알림톡 > 동반자 초대하기(예약내역), 취소위약규정, 취소내역, 티타임 예약하기
                if (joinAlarmAction) {
                    const {status, golfClubId, bookingId} = joinAlarmAction.alarmProp;

                    // 취소/위약규정은 queryParam 전송
                    let checkState = '';
                    if (status === 'cancellation') {
                        checkState = 'CANCEL_RECEPTION';
                        if (history.location.pathname === `/field-detail/${golfClubId}`) {
                            history.navigate(0, {state: {flag: 'drawerOpen'}});
                        } else {
                            history.navigate(`/field-detail/${golfClubId}`, {state: {flag: 'drawerOpen'}});
                        }
                    } else if (status === 'roundCancel') {
                        // 취소내역 버튼은 바로 해당페이지 이동
                        localStorageUtil.remove('joinAlarmAction');
                        history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=cancel`);
                    } else {
                        fetchWrapper
                            .get(`/booking/check/${bookingId}?checkType=${checkState}`, null)
                            .then((response) => {
                                if (response?.code === SUCCESS_CODE) {
                                    localStorageUtil.remove('joinAlarmAction');
                                    if (status === 'bookingList') {
                                        history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=pre`);
                                    } else if (status === 'cancellation') {
                                        history.navigate(`/field-detail/${golfClubId}`, {state: {flag: 'drawerOpen'}});
                                    } else if (status === 'teaTime') {
                                        history.navigate(`/main`);
                                    }
                                } else {
                                    // 메인 이동 후 Alert 오픈
                                    localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                                    history.navigate(`/main`);
                                }
                            });
                    }
                }

                // 알림톡 > 스마트라운드
                if (joinSMAction) {
                    // console.log('#### joinSMAction ####');
                    const {status, golfClubId, bookingId, clubName} = joinSMAction.smProp;

                    fetchWrapper.get(`/booking/check/${bookingId}`, null).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            localStorageUtil.remove('joinSMAction');
                            history.navigate(`/self-check-in-v2?bookingId=${bookingId}&golfClubId=${golfClubId}`);
                        } else {
                            // 메인 이동 후 Alert 오픈
                            localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                            history.navigate(`/main`);
                        }
                    });
                }

                // 알림톡 > 코스가이드
                if (joinCourseAction) {
                    // console.log('#### joinCourseAction ####');
                    const {status, golfClubId, courseCode, mapControlYN, bookingId, handicapDisplayYN} =
                        joinCourseAction.courseProp;

                    fetchWrapper.get(`/booking/check/${bookingId}`, null).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            localStorageUtil.remove('joinCourseAction');
                            window.ReactNativeWebView?.postMessage(
                                JSON.stringify({
                                    type: 'goNavi',
                                    golfClubId,
                                    courseCode,
                                    teeingGround: member?.teeingGround,
                                    mapControlYN,
                                    handicapDisplayYN
                                })
                            );
                        } else {
                            // 메인 이동 후 Alert 오픈
                            localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                            history.navigate(`/main`);
                        }
                    });
                }

                // 알림톡 > 공지사항
                if (joinClubBoardAction) {
                    // console.log('#### joinClubBoardAction ####');
                    const {status, golfClubId, bookingId, golfClubName} = joinClubBoardAction.courseProp;

                    fetchWrapper.get(`/booking/check/${bookingId}`, null).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            localStorageUtil.remove('joinClubBoardAction');
                            dispatch(smartRoundActions.setNoticeFrom({noticeFrom: '/'}));
                            history.navigate(`/club-board/${golfClubId}`);
                        } else {
                            // 메인 이동 후 Alert 오픈
                            localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                            history.navigate(`/main`);
                        }
                    });
                }

                // 알림톡 > 셀프체크인 가이드
                if (joinSelfChInGuideAction) {
                    const {status, golfClubId, bookingId, golfClubName} = joinSelfChInGuideAction.selfChInGuideProp;

                    fetchWrapper.get(`/booking/check/${bookingId}`, null).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            // localStorageUtil.remove('joinSelfChInGuideAction');
                            history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=pre`);
                        } else {
                            // 메인 이동 후 Alert 오픈
                            localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                            history.navigate(`/main`);
                        }
                    });
                }

                if (joinSelfChInPromotionEvent) {
                    history.navigate('/main');
                }

                // 채널톡
                if (joinCSAction) {
                    // console.log('#### joinCSAction ####');
                    const {status} = joinCSAction.csProp;
                    localStorageUtil.remove('joinCSAction');
                    history.navigate(`/cs`);
                }
            }, 200);
        }
    }, []);

    // if (todayReserv && isSkip) {
    //     window.ReactNativeWebView?.clearHistory();
    // }
    /**
     * 홈 화면 정책
     * 1. 당일 예약건이 있으면 코스가이드 진입 - 셀프체크인 화면노출
     */

    return (
        !rendering &&
        !inviteProcessing && (
            <>
                {todayReserv ? (
                    isSkip ? (
                        <Navigate
                            to={`/course-guide`}
                            state={{
                                from: `/course-guide`,
                                roundData: {...todayReservData},
                                smartRound: true
                            }}
                        />
                    ) : (
                        <Navigate
                            to={`/intro`}
                            state={{
                                from: `/intro`,
                                roundData: {...todayReservData},
                                intro: true
                            }}
                        />
                    )
                ) : (
                    <Main />
                )}
            </>
        )
    );
}

export default Home;
