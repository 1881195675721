import React, {useState} from 'react';

import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import Icon from '../../icons/Icon';

import useCollapse from 'react-collapsed';
import {appUtils} from 'common/utils';
import {paymentConstant} from 'common/payment';

function Collapse(props) {
    const collapseProps = useCollapse(props);
    return props.children(collapseProps);
}

//선결제
const PaymentBookingSuccessPre = (props) => {
    // 예약 정보
    const {bookingData} = props;
    const {payment, caddieType} = bookingData;
    // 결제 정보
    const {
        // paymentType,
        paymentTypeName,
        // paymentDetailType,
        // paymentDetailTypeName,
        prepaymentType,
        // prepaymentTypeName,
        transactionMethod,
        totalAmount,
        totalGreenFee,
        // greenFeePerPerson,
        expectOnsiteAmount,
        cartFee,
        caddieFee,
        // caddieFeeInfo,
        // reserveAmount,
        // onsiteGreenFee,
        totalExpectAmount,
        // otherAmountMessage,
        isCartFeeIncluded
    } = payment;

    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div className="payment-booking-success-info">
            <div className="sub-title-area">
                <h2>{paymentInfoProperty.payment_infomation}</h2>
            </div>
            <dl className="total-payment">
                <dt className="payment-booking-init payment-success-700">{paymentInfoProperty.payment_all_amount}</dt>
                <dd className="payment-success-800">{appUtils.amountFormat(totalAmount)}</dd>
            </dl>
            <div className={`payment-expanded-content border-bottom`}>
                <dl>
                    <dt>
                        <i className="teeTimeBoxIcon-advance-payment">
                            <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                        </i>
                        {paymentInfoProperty.totalGreenFee}
                    </dt>
                    <dd>
                        {' '}
                        {totalGreenFee === 0
                            ? paymentInfoProperty.greenFee_golf_contact
                            : appUtils.amountFormat(totalGreenFee)}
                    </dd>
                </dl>
                {/* prepaymentType === "20"  ? 카트피 결제 정보에 노출 :  현장 결제 예상금액에 노출 */}
                {prepaymentType === paymentConstant.prepaymentType.PAYMENT_TYPE_20 && (
                    <dl>
                        <dt>
                            <i className="teeTimeBoxIcon-advance-payment">
                                <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                            </i>
                            {paymentInfoProperty.cartFee}
                        </dt>
                        {/* 카트피 포함 여부에 따라 '그린피에 포함 노출' */}
                        <dd>{isCartFeeIncluded ? '그린피에 포함' : appUtils.amountFormat(cartFee)}</dd>
                    </dl>
                )}
            </div>
            <Collapse isExpanded={isExpanded} setHasDisabledAnimation={true}>
                {({isExpanded, getToggleProps, getCollapseProps}) => (
                    <div className="payment-booking-success-info">
                        <dl className="total-payment black">
                            <dt className="payment-booking-init payment-success-700">
                                {paymentInfoProperty.onsite_payment_predict}
                            </dt>
                            <dd>
                                {appUtils.amountFormat(expectOnsiteAmount)}
                                <button
                                    type="button"
                                    className={`btnDetail ${isExpanded ? 'collapse' : 'expand'}`}
                                    {...getToggleProps({
                                        onClick: () => {
                                            setIsExpanded((prevExpanded) => !prevExpanded);
                                        }
                                    })}
                                ></button>
                            </dd>
                        </dl>

                        <div className="payment-collapse-content" {...getCollapseProps()}>
                            {/* prepaymentType === "20"  ? 카트피 결제 정보에 노출 :  현장 결제 예상금액에 노출 */}
                            {prepaymentType !== paymentConstant.prepaymentType.PAYMENT_TYPE_20 && (
                                <dl>
                                    <dt>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.cartFee}
                                    </dt>
                                    {/* 카트피 포함 여부에 따라 '그린피에 포함 노출' */}
                                    <dd>{`${isCartFeeIncluded ? '그린피에 포함' : appUtils.amountFormat(cartFee)}`}</dd>
                                </dl>
                            )}

                            {/* 캐디 타입이 SELF(노캐디) 일 경우 캐디피 비노출  */}
                            {['SELF', '노캐디'].includes(caddieType) ? null : (
                                <dl className="border-dashed-bottom">
                                    <dt>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.caddieFee}
                                    </dt>
                                    <dd>{appUtils.amountFormat(caddieFee)}</dd>
                                </dl>
                            )}

                            <dl className="total-payment black">
                                <dt className="payment-booking-init payment-success-700">
                                    {paymentInfoProperty.all_payment_predict}
                                </dt>
                                <dd>{appUtils.amountFormat(totalExpectAmount)}</dd>
                            </dl>
                        </div>
                    </div>
                )}
            </Collapse>
            <div className="payment-booking-success-info border-top">
                <dl>
                    <dt>{paymentInfoProperty.payment_type}</dt>
                    <dd>{paymentTypeName}</dd>
                </dl>
                <dl>
                    <dt>{paymentInfoProperty.payment_method}</dt>
                    <dd>{transactionMethod}</dd>
                </dl>
            </div>
        </div>
    );
};

export default PaymentBookingSuccessPre;
