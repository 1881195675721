import React, {useEffect, useState} from 'react';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import DefaultAlert from '../alert/DefaultAlert';
import './membership.scss';
import {fetchWrapper, history} from '_helpers';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {alertMsg, errMsg} from '../../common/messages';
import {appUtils} from '../../common/utils';
import AuthenticationFields from './AuthenticationFields';
import {appActions} from '../../_store/app.slice';
import {CNFRMTITMESSAGE, SETTINGMESSAGE, SUCCESS_CODE} from '../../common/constants';
import {useLocation, navigate} from 'react-router-dom';
import {useParams} from 'react-router';

import CommonAlert from '../alert/CommonAlert';
import {alertActions} from '../../_store/alert.slice';
import {
    view_signup_apple_step1,
    view_signup_email_step1,
    viewSignupAppleStep1,
    viewSignupEmailStep1
} from '../../_helpers/amplitude/events/view.events';
import {result} from 'lodash';

/**
 * 2023.03.28 기준 인증관련 협의 코드
 * 사용가능 : 0000
 * 필수파라미터 없음 : 1010
 * 유효하지 않은 이메일 : 8011
 * 사용중인 이메일 : 5000
 * 탈퇴한 회원 : 8015
 * 알수없는 오류 : 9999
 * 유저 정보 없음 : 8000
 * */

function Authentication({title}) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const params = useParams();
    // console.debug('params', params);

    // 카카오 계정에 휴대전화번호가 존재하지 않는 경우 해당 케이스에 값을 할당함
    let hasNot_phoneNumber = location.state;

    const [userMail, setUserMail] = useState(searchParams.get('userMail'));

    const {height} = useWindowDimensions(); // 디바이스 높이값 체크
    const dispatch = useDispatch();
    const authUser = useSelector((x) => x.auth.user);
    const [formModify, setFormModify] = useState(true); // 폼 내 인풋제어

    // 만 14세 이상 회원가입 안내 모달
    const [open, setOpen] = useState(false);
    const [alertData, setAlertData] = useState({
        title: '',
        description: ''
    });
    const [authValidation, setAuthValidation] = useState({
        message: '',
        error: false
    });
    const handleClose = () => setOpen(false);

    // 연속 클릭 시 로딩 안내 모달
    const [doubleClickOpen, setDoubleClickOpen] = useState(false);

    const handleDoubleClickClose = () => setDoubleClickOpen(false);

    let [clickCount, setClickCount] = useState();

    const [findIdData, setFindIdData] = useState({
        userBirth: '',
        userGender: 1,
        userName: '',
        userPhone: ''
    });

    // 하단 '인증번호', '다음'버튼
    const [activeButton, setActiveButton] = useState(false);
    const [activeTimer, setActiveTimer] = useState(false);
    const [reactive, setReactive] = useState(false);
    const [reqNum, setReqNum] = useState('');
    const [_certDate, setCertDate] = useState('');
    const nextPage = () => {
        // debugger;

        if (params?.channel?.toLowerCase() === 'apple') {
            history.navigate('/member/join/apple/agree', {
                state: {
                    userMail: userMail
                }
            });
        } else {
            /*debugger;*/
            history.navigate('/member/join/setting');
        }
    };

    // 기등록된 유저정보인 경우 등록된 아이디를 조회
    const nextPage_FindId = (userData) => {
        history.navigate('/member/find-id/result', {state: {userData}});
    };

    const findIdInfo = () => {
        fetchWrapper
            .post(`/member/find-id`, findIdData)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    nextPage_FindId(response?.data);
                } else if (response.code === '4000') {
                    // TODO : mvp 개선방향으로 변경필요
                    setAlertData({
                        title: '인증정보 불일치',
                        description: response.message || response.data.message
                    });

                    setOpen(true);
                } else {
                    setAlertData({
                        title: response?.data?.title,
                        description: response?.data?.message
                    });

                    setOpen(true);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };
    /**
     * 인증 요청
     */
    const requestIdentity = (payloadData) => {
        dispatch(appActions.setReqNum(null));
        fetchWrapper.post(`/authentication/phone`, {...payloadData}).then((response) => {
            if (response?.code === '0000') {
                setReqNum(response?.data?.contents?.reqNum);
                setCertDate(response?.data?.contents?.certDate);

                // 카카오 로그인 연동 중 휴대전화정보가 없는 고객인 경우 강제 세팅
                if (hasNot_phoneNumber) {
                    hasNot_phoneNumber.cellPhone = payloadData.cellPhone;
                }

                if (response?.alertFlag) {
                    authProcess(response?.data?.contents?.title);
                } else {
                    authProcess();
                }
            } else {
                setAlertData({
                    title: response?.data?.title,
                    description: response?.data?.message
                });
                setOpen(true);
            }
        });
    };

    /**
     * 인증 확인
     */
    const checkAuthNumber = () => {
        const smsNum = document.querySelector("input[name='sms']")?.value;
        const confirmSeq = '01';

        const clickAlert = document.querySelector('#button-check');
        clickAlert.addEventListener('click', () => {
            clickCount = clickCount++;
        });

        if (clickCount > 1) {
            setDoubleClickOpen(true);
            return;
        }
        fetchWrapper
            .post(`/authentication/confirm`, {confirmSeq, reqNum, smsNum})
            .then((response) => {
                if (response?.code === '0000' || response?.code === '8015') {
                    console.log('hasNot_phoneNumber :::', hasNot_phoneNumber);
                    // dispatch(appActions.setReqNum(response?.data?.contents?.reqNum));
                    // 8015인 경우에는 reqNum 전달안함, 확인필요
                    dispatch(appActions.setReqNum(reqNum));

                    // 카카오 계정에 휴대전화 정보가 없어서 넘어온 경우를 확인 후 다음화면으로 이동 0000, 5000, 8015 케이스 체크
                    // email의 값이 존재해야만 넘김 , pathName이 자꾸 넘어오는 케이스가 발생함
                    if (hasNot_phoneNumber && hasNot_phoneNumber.hasOwnProperty('email')) {
                        return fetchWrapper.post(`/member/join/email`, hasNot_phoneNumber).then((response) => {
                            if (response?.code === '0000') {
                                console.log('웹에서 카카오톡 인증없는 계정 확인');
                                history.navigate('/member/join/complete', {
                                    state: {member: response.data}
                                });
                            } else if (response?.code === '5000') {
                                console.log(
                                    '웹에서 카카오톡 인증없는 계정으로 가입시도, 활성화되있는 기등록데이터 존재'
                                );
                                nextPage_FindId(response.data);
                            }
                        });
                    }
                    nextPage();
                } else if (response?.code === '5000') {
                    console.log('활성화된 개인 아이디 존재');
                    findIdInfo();
                    nextPage_FindId(response?.data);
                } else if (response?.code === 'I999') {
                    setAlertData({
                        title: '',
                        description: response?.message
                    });
                    setOpen(true);
                    setFormModify(true); // form disabled 처리
                    setActiveButton(false);
                    setActiveTimer(false); // 타이머 노출 처리
                    setReactive(false); // 동작처리
                } else if (response?.code === 'NME0074') {
                    setAlertData({
                        title: '',
                        description: '이미 인증완료된 인증번호입니다.'
                    });
                    setOpen(true);
                    setActiveButton(false);
                    setActiveTimer(false); // 타이머 노출 처리
                    setReactive(false); // 동
                } else {
                    setAlertData({
                        title: response?.data?.title || null,
                        description: response?.data?.message || response?.message
                    });
                    setOpen(true);
                }
            })
            .finally(() => {
                setDoubleClickOpen(false);
            });
    };

    /**
     * 입력값 validation
     */
    const onClickValidation = (data) => {
        let year = '19';
        let genderNumber = parseInt(data.userGender);
        // 1/2 : 19--, 3/4 : 20--, 5/6 : 19-- 외국인, 7/8 : 20-- 외국인, 9/0 : 18--
        if (genderNumber === 3 || genderNumber === 4 || genderNumber === 7 || genderNumber === 8) {
            year = '20';
        } else if (genderNumber === 9 || genderNumber === 0) {
            year = '18';
        }
        let birth = `${year}${data.userBirth}`;
        let age = appUtils.calcAge(birth);
        // 만 14세 미만 가입불가
        if (age < 14) {
            setAlertData({
                title: alertMsg.introTit,
                description: errMsg.USER_BIRTH_UNDER_14
            });
            setOpen(true);
            return false;
        }
        let payloadData = {
            name: data.userName,
            birth: birth,
            gender: genderNumber,
            rrnGender: genderNumber,
            telco: data.userTelecom, // 통신사
            cellPhone: data.userPhone
        };

        // id 찾기 위해 변수로 선언
        setFindIdData({
            userBirth: birth.substring(2),
            userGender: genderNumber,
            userPhone: data.userPhone,
            userName: data.userName
        });

        // 인증번호 호출
        requestIdentity(payloadData);
    };

    const authProcess = (title = '인증번호 발송', message = '인증번호를 확인해주세요.') => {
        setAlertData({
            title: title,
            description: message
        });
        setOpen(true);
        setFormModify(false); // form disabled 처리
        setActiveTimer(true); // 타이머 노출 처리
        setReactive(true); // 동작처리
    };

    const validationSchema = Yup.object().shape(appUtils.getValidationConfig('AUTH'));

    const {
        handleSubmit,
        control,
        watch,
        formState: {errors},
        setValue,
        setFocus,
        isValid,
        submitCount
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(validationSchema)
    });

    // 페이지 진입시 첫 input 자동 포커스
    useEffect(() => {
        appUtils.firstInputFocus();

        /*appUtils.openSignUpConfirmPostMsg();*/
    }, []);

    useEffect(() => {
        function handleEvent(message) {
            console.log(message.data);
            let type = JSON.parse(message.data).type;
            let isAlertOpen = JSON.parse(message.data).isAlertOpen;
            console.log('type', type);
            console.log('isAlertOpen', isAlertOpen);

            if (type === 'authenticationOpen') {
                if (isAlertOpen === true) {
                    dispatch(
                        alertActions.showAlert({
                            type: CNFRMTITMESSAGE,
                            messageData: {
                                title: '가입취소 안내',
                                message: '입력하신 모든 내용이 삭제됩니다. \n 회원가입 진행을 취소하시겠습니까?'
                            }
                        })
                    );

                    appUtils.openSignUpConfirmPostMsg();
                } else {
                    confirmClose();
                }
                /*appUtils.openSignUpConfirmPostMsg();*/
            } else if (type === 'webModalClose') {
                console.log('여기');
            }
        }

        if (window.location.pathname.includes('apple')) {
            viewSignupAppleStep1();
        } else {
            viewSignupEmailStep1();
        }
        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);

        return () => {
            document.removeEventListener('message', handleEvent);
            appUtils.closeSignUpConfirmPostMsg();
        };
    }, []);

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
            }
        };
        document.addEventListener('keydown', listener);

        if (authUser) {
            history.navigate('/');
        }
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);

    const confirmClose = () => {
        dispatch(alertActions.closeAlert());
        appUtils.closeSignUpConfirmPostMsg();
    };

    const handleYes = () => {
        /*console.log('에 눌렀음');*/
        appUtils.closeSignUpConfirmPostMsg();
        history.navigate('/member');
    };

    const handleNo = () => {
        appUtils.closeSignUpConfirmPostMsg();
        /*console.log('아니오 눌렀음');*/
    };

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="membership">
                    <div className="inner">
                        <form onSubmit={handleSubmit(onClickValidation)}>
                            <TitleArea pageTitle={title} />
                            <div className="fieldAreaWrap">
                                <AuthenticationFields
                                    formProp={{
                                        errors,
                                        control,
                                        watch,
                                        setValue,
                                        setFocus,
                                        isValid,
                                        submitCount
                                    }}
                                    onSubmit={handleSubmit(onClickValidation)}
                                    formModify={formModify}
                                    activeTimer={activeTimer}
                                    setActiveTimer={setActiveTimer}
                                    reactive={reactive}
                                    setReactive={setReactive}
                                    setActiveButton={setActiveButton}
                                    authValid={{
                                        authValidation: authValidation,
                                        setAuthValidation: setAuthValidation
                                    }}
                                    hasNot_phoneNumber={hasNot_phoneNumber}
                                />
                            </div>
                            <div className="btnFixedBottom">
                                {/* 버튼이 키보드 위에 고정되어야 하는 경우 btnFixedBottom에 클래스 "fixed" 추가 */}
                                <div className="inner">
                                    <Button
                                        id="button-check"
                                        type="button"
                                        disabled={!activeButton}
                                        background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                        onClick={checkAuthNumber}
                                    >
                                        다음
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* 만 14세 미만 가입불가 */}
            <DefaultAlert
                open={open}
                handleClose={handleClose}
                title={alertData.title}
                contents={alertData.description}
            />
            {/* 연속 클릭 시 알림 창 */}
            <DefaultAlert
                open={doubleClickOpen}
                handleClose={handleDoubleClickClose}
                title={'안내'}
                contents={'본인 인증 처리중입니다. 잠시만 기다려주세요.'}
            />

            <CommonAlert successCallBack={handleYes} failCallBack={handleNo} />
        </>
    );
}

export default Authentication;
