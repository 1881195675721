import React, {useState} from 'react';

function Checkbox({isRoundBg, onChecked, defaultChecked = false, labelTxt}) {
    const [isChecked, setIsChecked] = useState(defaultChecked);
    const onChangeClick = () => {
        setIsChecked(!isChecked);
        onChecked(!isChecked, labelTxt);
    };
    return (
        <div className={`customCheck ${isChecked ? 'checked' : ''}`}>
            <input type="checkbox" checked={isChecked} onChange={onChangeClick} />
            <i className={`icon ${isRoundBg ? 'round' : ''} ${isChecked ? 'checked' : ''}`}>
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {isRoundBg ? (
                        <>
                            <circle cx="12" cy="12" r="12" fill={isChecked ? '#3906C3' : 'white'} />
                            <path
                                d="M17 9L11.0525 16L7 11.9168"
                                stroke={isChecked ? 'white' : '#A0A4AF'}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </>
                    ) : (
                        <path
                            d="M17 8L11.0525 15L7 10.9168"
                            stroke={isRoundBg ? '#fff' : isChecked ? '#3906C3 ' : '#A0A4AF'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    )}
                </svg>
            </i>
            {labelTxt ? labelTxt : ''}
        </div>
    );
}

export default Checkbox;
