import React, {useCallback} from 'react';
import {fetchWrapper, history} from '../../../_helpers';
import Icon from '../../icons/Icon';
import {SUCCESS_CODE, YN} from '../../../common/constants';
import Badge from 'components/badge/Badge';

function GolfZoneListItem({golfzoneListItem, isSearchRank, rankNum, removeFavoriteItem, onClickSelfCheckInBtn}) {
    const deleteInterestClub = () => {
        fetchWrapper
            .delete(`/golfClub/pre/interestClub/delete/${golfzoneListItem.golfClubId}`, null)
            .then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    removeFavoriteItem(golfzoneListItem);
                }
            });
    };

    const onClickLike = () => {
        deleteInterestClub();
    };

    const selectGolfZone = () => {
        history.navigate(`/field-detail/${golfzoneListItem.golfClubId}`, {
            state: {
                flag: 'search'
            }
        });
    };

    return (
        <li className="golfZoneListItem">
            <div className="golfZoneWrapper">
                {isSearchRank && <div className="rankNumber">{rankNum + 1}</div>}

                <div className="golfZoneInfo">
                    <div className="golfZoneInfoArea">
                        <div className="infoTitleArea">
                            <div className="golfZoneName pr-5" onClick={selectGolfZone}>
                                {golfzoneListItem.golfClubShortName}
                            </div>
                            {/* 배지 스타일 변경 */}
                            {golfzoneListItem?.isSelfCheckInPossible && (
                                <>
                                    {golfzoneListItem?.promotionActiveYn === YN.Y && <Badge type={'EVENT'} />}
                                    <Badge type={'SELF_CHECK_IN_ARROW'} onClick={onClickSelfCheckInBtn} />
                                </>
                            )}
                            {!golfzoneListItem.isPartner && <Badge type={'UNAFFILIATED'} />}
                            {/* {golfzoneListItem?.isSelfCheckInPossible && (
                                <button
                                    type="button"
                                    className="statusIcon selfCheckIn"
                                    onClick={onClickSelfCheckInBtn}
                                >
                                    셀프체크인
                                </button>
                            )} */}
                            {/* {!golfzoneListItem.isPartner && <span className="statusIcon line">미제휴</span>} */}
                        </div>

                        <div className="golfZoneSubInfo" onClick={selectGolfZone}>
                            {golfzoneListItem.address} · {golfzoneListItem.distance} km
                        </div>
                    </div>
                </div>
                {golfzoneListItem.isInterest && (
                    <div className="itemSideArea">
                        <button type="button" onClick={onClickLike} className="btnInterest">
                            <Icon name={'like-on'} width="26" height="26" viewBox="0 0 26 26" />
                        </button>
                    </div>
                )}
            </div>
        </li>
    );
}

export default GolfZoneListItem;
