import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper} from '_helpers';
import {store} from '_store';
import {tossUtils} from 'common/tossUtils';
import {SUCCESS_CODE} from '../common/constants';

const name = 'smartRound';

// InitialState -
const initialState = {
    user: null,
    golfClubId: null,
    bookingId: null,
    courseList: null,
    smartRound: null,
    round: null,
    caddieList: null,
    golfCartList: null,
    checkinMessage: null,
    golfBagList: null,
    golfBag: null,
    bookingMemberList: null,
    playerLimit: null,
    roundPlayer: null,
    bookingData: null,
    rendering: true,
    qrTime: 59,
    timer: null,
    checkInPossibility: null,
    checkin: false,
    qr: null,
    from: '/main',
    checkInAlertMessage: null,
    noticeFrom: '/course-guide-v2',
    checkInMember: null
};

// Reducer - API 호출을 제외한 일반 reducer 정의
const reducers = {
    setSmartRoundData: {
        reducer: (state, action) => {
            const {golfClubId, bookingId} = action.payload;
            state.golfClubId = golfClubId;
            state.bookingId = bookingId;
        }
    },
    setFrom: {
        reducer: (state, action) => {
            const {from} = action.payload;
            state.from = from;
        }
    },
    setUser: {
        reducer: (state, action) => {
            const {user} = action.payload;
            state.user = user;
        }
    },
    setGolfBag: {
        reducer: (state, action) => {
            const {golfBag} = action.payload;
            state.golfBag = golfBag;
        }
    },
    setQrTime: {
        reducer: (state, action) => {
            const {time} = action.payload;
            state.qrTime = time;
        }
    },
    setTimer: {
        reducer: (state, action) => {
            const {timer} = action.payload;
            state.timer = timer;
            if (timer === null) {
                state.qrTime = 59;
            }
        }
    },
    setCheckin: {
        reducer: (state, action) => {
            const {checkin} = action.payload;
            state.checkin = checkin;
            if (checkin && state.timer !== null) {
                clearInterval(state.timer);
                state.qrTime = 59;
                state.timer = null;
            }
        }
    },
    initState: {
        reducer: (state, action) => {
            state.user = initialState.user;
            state.golfClubId = initialState.golfClubId;
            state.bookingId = initialState.bookingId;
            state.courseList = initialState.courseList;
            state.smartRound = initialState.smartRound;
            state.round = initialState.round;
            state.caddieList = initialState.caddieList;
            state.golfCartList = initialState.golfCartList;
            state.checkinMessage = initialState.checkinMessage;
            state.golfBagList = initialState.golfBagList;
            state.golfBag = initialState.golfBag;
            state.bookingMemberList = initialState.bookingMemberList;
            state.playerLimit = initialState.playerLimit;
            state.roundPlayer = initialState.roundPlayer;
            state.bookingData = initialState.bookingData;
            state.rendering = initialState.rendering;
            state.qrTime = initialState.qrTime;
            state.timer = initialState.timer;
            state.checkInPossibility = initialState.checkInPossibility;
            state.checkin = initialState.checkin;
            state.qr = initialState.qr;
            state.from = initialState.from;
            state.checkInAlertMessage = initialState.checkInAlertMessage;
            state.noticeFrom = initialState.noticeFrom;
        }
    },
    setFrom: {
        reducer: (state, action) => {
            const {from} = action.payload;
            state.from = from;
        }
    },
    setCheckInAlertMessage: {
        reducer: (state, action) => {
            const {checkInAlertMessage} = action.payload;
            state.checkInAlertMessage = checkInAlertMessage;
        }
    },
    setNoticeFrom: {
        reducer: (state, action) => {
            const {noticeFrom} = action.payload;
            state.noticeFrom = noticeFrom;
        }
    }
};

// ExtraAction - 사용할 API 호출부 정의
const extraActions = {
    getCourseList: createAsyncThunk(`${name}/getCourseList`, async (golfClubId) => {
        const response = await fetchWrapper.get(`/course/list/${golfClubId}`);
        if (response.code === SUCCESS_CODE) {
            if (response.data) {
                return response.data;
            }
        }
        return false;
    }),
    getSmartRound: createAsyncThunk(`${name}/getSmartRound`, async (golfClubId) => {
        const response = await fetchWrapper.get(`/golfClub/${golfClubId}/smart-round`);
        if (response.code === SUCCESS_CODE) {
            if (response.data) {
                return response.data;
            }
        }
        return false;
    }),
    getRound: createAsyncThunk(`${name}/getRound`, async (bookingId) => {
        const response = await fetchWrapper.get(`/booking/${bookingId}/round`);
        if (response.code === SUCCESS_CODE) {
            if (response.data) {
                return response.data;
            }
        }
        return false;
    }),
    getBags: createAsyncThunk(`${name}/getBags`, async () => {
        const response = await fetchWrapper.get(`/member/bags`);
        if (response.code === SUCCESS_CODE) {
            if (response.data) {
                return response.data;
            }
        }
        return false;
    }),
    getBookingMemberList: createAsyncThunk(`${name}/getBookingMemberList`, async (bookingId) => {
        const response = await fetchWrapper.get(`/booking/member-list/${bookingId}`);
        if (response.code === SUCCESS_CODE) {
            if (response.data) {
                const bookingMemberList = response.data.bookingMemberList;
                const auth = store.getState().auth?.user;

                bookingMemberList?.forEach((v) => {
                    // Id가 일치하면 해당 객체 전송
                    if (v.memberId === tossUtils.decodeSubject(auth?.accessToken) || null) {
                        response.data.user = v;
                    }
                });
                return response.data;
            }
        }
        return false;
    }),
    getCheckInPossibility: createAsyncThunk(`${name}/getCheckInPossibility`, async (bookingId) => {
        const response = await fetchWrapper.get(
            `/booking/checkin/possibility?bookingId=${bookingId}&checkinType=QRCODE`
        );
        if (response.code === SUCCESS_CODE) {
            if (response.data) {
                return response.data;
            }
        }
        return false;
    }),
    getQr: createAsyncThunk(`${name}/getQrCheckIn`, async (params) => {
        const {bookingId, userId} = params;
        const response = await fetchWrapper.get(`/booking/${bookingId}/${userId}/qr/char`);
        if (response.code === SUCCESS_CODE) {
            if (response.data) {
                return response.data;
            }
        }
        return false;
    }),
    getCheckInMember: createAsyncThunk(`${name}/getCheckInMember`, async (bookingId) => {
        const response = await fetchWrapper.get(`/booking/checkin-member?roundBookingId=${bookingId}`);
        return response;
    })
};

// ExtraReducer - fetch에 대한 callback 함수 정의
const extraReducers = {
    getCourseList: () => {
        let {pending, fulfilled, rejected} = extraActions.getCourseList;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                state.courseList = action.payload.courseList;
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                state.rendering = false;
            }
        };
    },
    getSmartRound: () => {
        let {pending, fulfilled, rejected} = extraActions.getSmartRound;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                state.smartRound = action.payload;
                if (!state.bookingId && action.payload.bookingId) {
                    state.bookingId = action.payload.bookingId;
                }
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                state.rendering = false;
            }
        };
    },
    getRound: () => {
        let {pending, fulfilled, rejected} = extraActions.getRound;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                state.round = action.payload;
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                state.rendering = false;
            }
        };
    },
    getBags: () => {
        let {pending, fulfilled, rejected} = extraActions.getBags;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                state.golfBagList = action.payload.golfBagList;
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                state.rendering = false;
            }
        };
    },
    getBookingMemberList: () => {
        let {pending, fulfilled, rejected} = extraActions.getBookingMemberList;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                state.user = action.payload.user;
                state.bookingMemberList = action.payload.bookingMemberList;
                state.caddieList = action.payload.caddieList;
                state.golfCartList = action.payload.golfCartList;
                state.checkinMessage = action.payload.checkinMessage;
                state.playerLimit = action.payload.playerLimit;
                state.roundPlayer = action.payload.roundPlayer;
                state.bookingData = action.payload.bookingData;
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                state.rendering = false;
            }
        };
    },
    getCheckInPossibility: () => {
        let {pending, fulfilled, rejected} = extraActions.getCheckInPossibility;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                state.checkInPossibility = true;
                // state.checkInPossibility = action.payload;
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                state.rendering = false;
            }
        };
    },
    getQr: () => {
        let {pending, fulfilled, rejected} = extraActions.getQr;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                state.qr = action.payload;
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                state.rendering = false;
            }
        };
    },
    getCheckInMember: () => {
        let {pending, fulfilled, rejected} = extraActions.getCheckInMember;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                state.checkInMember = action.payload;
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                state.rendering = false;
            }
        };
    }
};

// ExtraReducer 등록
const createExtraReducers = () => {
    return {
        ...extraReducers.getCourseList(),
        ...extraReducers.getSmartRound(),
        ...extraReducers.getRound(),
        ...extraReducers.getBags(),
        ...extraReducers.getBookingMemberList(),
        ...extraReducers.getCheckInPossibility(),
        ...extraReducers.getQr(),
        ...extraReducers.getCheckInMember()
    };
};

// create slice
const smartRoundSlice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: createExtraReducers()
});

// exports
export const smartRoundActions = {...smartRoundSlice.actions, ...extraActions};
export const smartRoundReducer = smartRoundSlice.reducer;
