import {viewOtherBookingPolicy} from '_helpers/amplitude/events/view.events';
import React, {useEffect} from 'react';

function GetReservationInfoAgree() {
    useEffect(() => {
        viewOtherBookingPolicy();
    }, []);

    return (
        <>
            <section>
                <h2>예약정보 조회 · 전송동의</h2>
                <p>
                    골프장 셀프체크인 서비스 제공을 위해 회원에게 동의를 구한 후 아래 정보를 제3자에게 제공 및 전송받고
                    있습니다.
                    <br />
                    <br />
                    예약정보 조회를 위한 개인정보 제3자 제공 및 예약정보 전송에 대한 동의는 선택사항으로 거부하셔도 예약
                    서비스를 사용하실 수 있습니다.
                    <br />
                    <br />
                    <ul className="listType04">
                        <li>개인정보 제공자/예약정보 수신자 : 골라가</li>
                        <li>개인정보 수신자/예약정보 제공자 : 회원이 예약한 골라가 제휴 골프장</li>
                        <li>제공하는 개인정보 : 이름, 휴대전화번호</li>
                        <li>제공목적 : 예약정보 조회</li>
                        <li>전송받는 정보 : 회원의 예약정보(골프장명, 라운드일시, 코스, 홀 정보)</li>
                        <li>전송목적 : 골라가 외부 예약 건에 대해 스마트라운드 서비스 제공</li>
                        <li>보유 및 이용기간 : 서비스 이용계약 해지(탈퇴) 시</li>
                        <li>본 약관은 제휴 예정 골프장을 포함하여 적용됩니다.</li>
                        <li>
                            동의한 시점기준으로 라운드 예정인 예약정보 를 조회∙전송하며, 동의를 철회하셔도 기존
                            조회∙전송된 정보는 유지됩니다.
                        </li>
                    </ul>
                </p>
            </section>
        </>
    );
}

export default GetReservationInfoAgree;
