import React, {useEffect, useState, forwardRef} from 'react';
import Header from '../../components/Header';
import Boarditem from './Boarditem';
import Icon from '../../components/icons/Icon';
import Loading from '../../common/loading/Loading';
import {useSelector} from 'react-redux';
import {YN} from 'common/constants';

const Board = forwardRef(({golfClubId, boardDataList, clubName, backLink, isLoading}, ref) => {
    const [boardTitle, setBoardTitle] = useState('');
    const {displayYn, activeYn} = useSelector((state) => state?.promotion);
    const promotionYn = displayYn === YN.Y && activeYn === YN.Y;

    useEffect(() => {
        setBoardTitle(clubName === '골라가' ? (promotionYn ? '이벤트' : '골라가 공지사항') : `${clubName} 공지사항`);
    }, [clubName, promotionYn]);

    return (
        <>
            {/* <Header back={true} backLink={backLink} pageTitle={`${clubName} 공지사항`} /> */}
            <Header back={true} backLink={backLink} pageTitle={`${boardTitle}`} />
            <div id="contents">
                <div className="contentsArea">
                    <div id="notice">
                        <div className="inner">
                            <div className="noticeCon">
                                <ul className="boardList">
                                    {isLoading ? (
                                        <Loading />
                                    ) : boardDataList?.length ? (
                                        <>
                                            {boardDataList.map((item) => {
                                                return (
                                                    <Boarditem
                                                        key={item?.id}
                                                        golfClubId={golfClubId}
                                                        item={item}
                                                        clubName={clubName}
                                                    />
                                                );
                                            })}
                                            <li ref={ref} className="ref"></li>
                                        </>
                                    ) : (
                                        <li className="nodata" ref={ref}>
                                            <div>
                                                <i className="icon">
                                                    <Icon name="notice-nodata" width="60px" height="60px" />
                                                </i>
                                                공지사항이 없습니다.
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default Board;
