import React from 'react';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {bookingCancel} from 'common/payment/locales/kr/booking-cancel';
import {bookingConstant, paymentConstant} from 'common/payment/constants';
import {appUtils} from 'common/utils';

import {validationUtil} from 'common/payment';

const PaymentDetailModalCancelRound = (props) => {
    const {paymentType, paymentTradeData} = props;
    switch (paymentType) {
        case paymentConstant.paymentType.PREPAY:
            return (
                <>
                    <div className="payment-info-type">
                        <div className="sub-title-area">
                            <h2>{paymentInfoProperty.payment_infomation}</h2>
                        </div>
                        <dl>
                            <dt>{paymentInfoProperty.payment_date}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.tradeCreatedAt)
                                    ? '-'
                                    : appUtils.dateFormatTypeFull(paymentTradeData?.tradeCreatedAt)}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{paymentInfoProperty.payment_person_name}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.tradeCustomerName)
                                    ? '-'
                                    : paymentTradeData?.tradeCustomerName}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{paymentInfoProperty.payment_method}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.paymentMethod)
                                    ? '-'
                                    : paymentTradeData?.paymentMethod}
                            </dd>
                        </dl>
                        <dl className="border-bottom black">
                            <dt>{paymentInfoProperty.payment_card_classification}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.cardName)
                                    ? '-'
                                    : paymentTradeData?.cardName}
                            </dd>
                        </dl>
                        <dl className="border-bottom black">
                            <dt>{paymentInfoProperty.payment_card_number}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.cardNumber)
                                    ? '-'
                                    : validationUtil.cardNumberFormatter(paymentTradeData?.cardNumber)}
                            </dd>
                        </dl>
                        <dl className="border-bottom">
                            <dt>{paymentInfoProperty.payment_all_amount}</dt>
                            <dd className="primary">{appUtils.amountFormat(paymentTradeData?.tradeAmount)}</dd>
                        </dl>
                    </div>
                    {paymentTradeData?.transactionCancel?.map((item) => (
                        <div key={item.transactionCancelId}>
                            <div className="payment-info-type">
                                <div className="sub-title-area">
                                    <h2>{bookingCancel.booking_cancel_infomation}</h2>
                                </div>

                                <dl>
                                    <dt>{bookingCancel.booking_cancel_complete_date}</dt>
                                    {paymentTradeData?.bookingStatusDetail ===
                                    bookingConstant.bookingStatusDetail.BOOKING_STATUS_DETAIL_F23 ? (
                                        <dd>{bookingCancel.cancel_receipt}</dd>
                                    ) : (
                                        <dd>
                                            {validationUtil.isNullChk(item?.cancelCreatedAt)
                                                ? '-'
                                                : appUtils.dateFormatTypeFull(item?.cancelCreatedAt)}
                                        </dd>
                                    )}
                                </dl>
                                <dl>
                                    <dt>{bookingCancel.booking_cancel_receipt_date}</dt>
                                    <dd className="primary">
                                        {validationUtil.isNullChk(paymentTradeData?.cancelCompletionDate)
                                            ? '-'
                                            : appUtils.dateFormatTypeFull(paymentTradeData?.cancelCompletionDate)}
                                    </dd>
                                </dl>
                                {/* 취소 사유 운영자 환불 일 경우 - 사유 안보이게 처리 */}
                                <dl className="border-bottom black">
                                    <dt>{bookingCancel.booking_cancel_reason}</dt>
                                    {paymentTradeData?.serviceType === 'ADMIN' ? (
                                        <dd>-</dd>
                                    ) : (
                                        <dd>
                                            {validationUtil.isNullChk(item?.cancelMessage) ? '-' : item?.cancelMessage}
                                        </dd>
                                    )}
                                </dl>
                            </div>
                            <div className="payment-info-type">
                                <span className="total-payment">
                                    <dl>
                                        <dt>결제 {bookingCancel.booking_cancel_amount}</dt>
                                        {/* 취소 접수 상태 일때 (백엔드에서는 F23) 
                                        취소 완료 일시에 취소 접수 문구 노출
                                        */}
                                        {paymentTradeData?.bookingStatusDetail ===
                                        bookingConstant.bookingStatusDetail.BOOKING_STATUS_DETAIL_F23 ? (
                                            <dd>{bookingCancel.cancel_receipt}</dd>
                                        ) : (
                                            <dd>
                                                {validationUtil.isNullChk(item?.cancelledAmount)
                                                    ? '-'
                                                    : appUtils.amountFormat(item?.cancelledAmount)}
                                            </dd>
                                        )}
                                    </dl>
                                </span>
                            </div>
                        </div>
                    ))}
                </>
            );
            break;
        case paymentConstant.paymentType.RESERVE:
            return (
                <>
                    <div className="payment-info-type">
                        <div className="sub-title-area">
                            <h2>{paymentInfoProperty.payment_infomation}</h2>
                        </div>
                        <dl>
                            <dt>{paymentInfoProperty.payment_date}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.tradeCreatedAt)
                                    ? '-'
                                    : appUtils.dateFormatTypeFull(paymentTradeData?.tradeCreatedAt)}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{paymentInfoProperty.payment_person_name}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.tradeCustomerName)
                                    ? '-'
                                    : paymentTradeData?.tradeCustomerName}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{paymentInfoProperty.payment_method}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.paymentMethod)
                                    ? '-'
                                    : paymentTradeData?.paymentMethod}
                            </dd>
                        </dl>
                        <dl className="border-bottom black">
                            <dt>{paymentInfoProperty.payment_card_classification}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.cardName)
                                    ? '-'
                                    : paymentTradeData?.cardName}
                            </dd>
                        </dl>
                        <dl className="border-bottom black">
                            <dt>{paymentInfoProperty.payment_card_number}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.cardNumber)
                                    ? '-'
                                    : validationUtil.cardNumberFormatter(paymentTradeData?.cardNumber)}
                            </dd>
                        </dl>
                        <dl className="border-bottom">
                            <dt>{paymentInfoProperty.payment_deposit}</dt>
                            <dd className="primary">
                                {validationUtil.isNullChk(paymentTradeData?.tradeAmount)
                                    ? '-'
                                    : appUtils.amountFormat(paymentTradeData?.tradeAmount)}
                            </dd>
                        </dl>
                    </div>

                    {paymentTradeData?.transactionCancel?.map((item) => (
                        <div key={item.transactionCancelId}>
                            <div className="payment-info-type">
                                <div className="sub-title-area">
                                    <h2>{bookingCancel.booking_cancel_infomation}</h2>
                                </div>
                                <dl>
                                    <dt>{bookingCancel.booking_cancel_complete_date}</dt>
                                    {/* 취소 접수 상태 일때 (백엔드에서는 F21) 
                                        취소 완료 일시에 취소 접수 문구 노출
                                    */}
                                    {paymentTradeData?.bookingStatusDetail ===
                                    bookingConstant.bookingStatusDetail.BOOKING_STATUS_DETAIL_F23 ? (
                                        <dd>{bookingCancel.cancel_receipt}</dd>
                                    ) : (
                                        <dd>
                                            {validationUtil.isNullChk(item?.cancelCreatedAt)
                                                ? '-'
                                                : appUtils.dateFormatTypeFull(item?.cancelCreatedAt)}
                                        </dd>
                                    )}
                                </dl>
                                <dl>
                                    <dt>{bookingCancel.booking_cancel_receipt_date}</dt>
                                    <dd className="primary">
                                        {validationUtil.isNullChk(paymentTradeData?.cancelReceptionDate)
                                            ? '-'
                                            : appUtils.dateFormatTypeFull(paymentTradeData?.cancelReceptionDate)}
                                    </dd>
                                </dl>

                                {/* 취소 사유 운영자 환불 일 경우 - 사유 안보이게 처리 */}
                                <dl className="border-bottom black">
                                    <dt>{bookingCancel.booking_cancel_reason}</dt>
                                    {paymentTradeData?.serviceType === 'ADMIN' ? (
                                        <dd>-</dd>
                                    ) : (
                                        <dd>
                                            {validationUtil.isNullChk(item?.cancelMessage) ? '-' : item?.cancelMessage}
                                        </dd>
                                    )}
                                </dl>
                            </div>
                            <div className="payment-info-type">
                                <span className="total-payment">
                                    <dl>
                                        <dt>결제 {bookingCancel.booking_cancel_amount}</dt>
                                        {/* 취소 접수 상태 일때 (백엔드에서는 F23) 
                                        취소 완료 일시에 취소 접수 문구 노출
                                        */}
                                        {paymentTradeData?.bookingStatusDetail ===
                                        bookingConstant.bookingStatusDetail.BOOKING_STATUS_DETAIL_F23 ? (
                                            <dd>{bookingCancel.cancel_receipt}</dd>
                                        ) : (
                                            <dd>
                                                {validationUtil.isNullChk(item?.cancelledAmount)
                                                    ? '-'
                                                    : appUtils.amountFormat(item?.cancelledAmount)}
                                            </dd>
                                        )}
                                    </dl>
                                </span>
                            </div>
                        </div>
                    ))}
                </>
            );
            break;
    }
};

export default PaymentDetailModalCancelRound;
