import React from "react";

function IdentificationKTF() {
    return (
        <>
            <section>
                <h2>&lt;케이티 귀중&gt;</h2>
                <p>
                    (주)케이티 (이하 "본인확인기관")가 SCI평가정보(주) (이하
                    "회사")을 통해 제공하는 휴대폰 본인확인 서비스 관련하여
                    이용자로부터 수집한 고유식별정보를 이용하거나 타인에게
                    제공할 때에는 '정보통신망 이용촉진 및 정보보호 등에 관한
                    법률'(이하 "정보통신망법")에 따라 이용자의 동의를 얻어야
                    합니다.
                </p>
                <br />
                <h3>■ 제 1 조[고유식별정보의 처리 동의]</h3>
                <p>
                    "본인확인기관"은 정보통신망법 제23조의2 제2항에 따라
                    인터넷상에서 주민등록번호를 입력하지 않고도 본인임을 확인할
                    수 있는 휴대폰 본인확인서비스를 제공하기 위해 고유식별정보를
                    처리합니다.
                </p>
                <br />
                <h3>■ 제 2 조[고유식별정보의 제공 동의]</h3>
                <p>
                    "본인확인기관 지정 등에 관한 기준(방송통신위원회 고시)"에
                    따라 "회사"와 계약한 정보통신서비스 제공자 의 연계서비스 및
                    중복가입확인을 위해 아래와 같이 본인의 고유식별정보를 '다른
                    본인확인기관'에 제공하는 것에 동의합니다.
                </p>
                <p>
                    1. 제공자(본인확인기관)
                    <br />
                    (주)케이티
                    <br />
                    <br />
                    2. 제공 받는자(본인확인기관)
                    <br />
                    <strong className="underLine">
                        코리아크레딧뷰로(주), SCI평가정보(주)
                    </strong>
                    <br />
                    <br />
                    3. 제공하는 항목
                    <br />
                    주민등록번호(내국인), 외국인등록번호(국내거주외국인)
                    <br />
                    <br />
                    4. 제공 목적
                    <br />
                    <strong className="underLine">
                        CI(연계정보), DI(중복가입확인정보)의 생성 및 전달
                    </strong>
                    <br />
                    <br />
                    5. 보유 및 이용기간
                    <br />
                    <strong className="underLine">
                        CI(연계정보), DI(중복가입확인정보) 생성 즉시 폐기
                    </strong>
                    <br />
                    <br />
                    6. 위 개인정보 제공에 동의하지 않으실 경우, 서비스를 이용할
                    수 없습니다.
                </p>
            </section>
        </>
    );
}

export default IdentificationKTF;
