export const validationUtil = {
    /**
     * null & undefined check
     * @param {*} data
     * @returns null, undefined : true , other : false
     */
    isNullChk: (data) => {
        if (data === null || data === undefined) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 카드 넘버 포맷 변경 (-)
     * @param {*} cardNumber
     * @returns 4자리마다 하이픈 붙여준 string 리턴
     */
    cardNumberFormatter: (cardNumber) => {
        let cardLen = cardNumber.length;
        let cardNumberArr = cardNumber.split('');

        let cardNumberHyehenStr = '';

        cardNumberArr.map((item, idx) => {
            if (idx === 0 || idx === cardLen - 1) {
                cardNumberHyehenStr += item;
            } else {
                if ((idx + 1) % 4 === 0) {
                    cardNumberHyehenStr += item + '-';
                } else {
                    cardNumberHyehenStr += item;
                }
            }
        });

        return cardNumberHyehenStr; // 공백이 들어있다면 지워주고
    },

    /**
     * 빈 문자열인지 판별
     * @param {*} str
     * @returns
     * null ,undefined, emptyString : true
     * other : false
     */
    emptyStringCheck: (str) => {
        if (validationUtil.isNullChk(str)) {
            return true;
        } else {
            if (str.trim() === '') {
                return true;
            } else {
                return false;
            }
        }
    }
};
