import React, {useState} from 'react';

function SearchResultBelowItem({placeListItem, onClickButton}) {
    const [active, setActive] = useState(false);

    return (
        <li className="resultList">
            <div className="txt">
                <strong>
                    {placeListItem.city_do} {placeListItem.gu_gun} {placeListItem.newRoadName}{' '}
                    {placeListItem.newBuildingIndex}{' '}
                    {placeListItem.buildingName === '' ? '' : `( ${placeListItem.buildingName} )`}
                </strong>
                <span>
                    {placeListItem.city_do} {placeListItem.gu_gun}{' '}
                    {placeListItem.legalDong === ''
                        ? `${placeListItem.eup_myun} ${placeListItem.ri}`
                        : `${placeListItem.legalDong}`}{' '}
                    {placeListItem.bunji}
                </span>
            </div>
            <div className="btn">
                <button onClick={() => onClickButton('HOME', placeListItem)}>집</button>
                <button onClick={() => onClickButton('COMPANY', placeListItem)}>회사</button>
                <button
                    className={active ? 'active-btn' : ''}
                    onClick={() => {
                        setActive(!active);
                        onClickButton('SELECT', placeListItem);
                    }}
                >
                    출발위치
                </button>
            </div>
        </li>
    );
}

export default SearchResultBelowItem;
