import React, {useCallback, useEffect, useState} from 'react';
import useCollapse from 'react-collapsed';
import ToolTip from '../../../components/tooltip/Tooltip';
import BookingCancelDrawer from './layer/BookingCancelDrawer';
import CancelRegulationLayer from './layer/CancelRegulationLayer';
import BookingCancelReasonLayer from './layer/BookingCancelReasonLayer';
import {fetchWrapper, history} from '../../../_helpers';
import NoImg from '../../common/NoImg';
import PolicyLayer from '../../membership/PolicyLayer';
import {appUtils} from '../../../common/utils';
import PartnerInvite from '../../../_view/booking/PartnerInvite';
import SmartRoundButton from '../../../_view/booking/SmartRoundButton';
import {SUCCESS_CODE} from '../../../common/constants';
import {debounce} from 'lodash';
import Modal from '@mui/material/Modal';
import DefaultAlert from '../../alert/DefaultAlert';
import PaymentBookingCard from 'components/Payment/PaymentBookingCard/PaymentBookingCard';
import Badge from 'components/badge/Badge';
import {bookingCancel} from 'common/payment/locales/kr/booking-cancel';
import PaymentBookingCancelConfirm from 'components/Payment/PaymentBookingCancelConfirm/PaymentBookingCancelConfirm';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {dateUtil, validationUtil} from 'common/payment';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {clickCancelPolicy} from '_helpers/amplitude/events/click.event';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';

function Collapse(props) {
    const collapseProps = useCollapse(props);
    return props.children(collapseProps);
}

function BookingCardList(props) {
    const {
        bookingData,
        bookingList,
        setBookingListReset,
        setMemberReset,
        status,
        hasDisabledAnimation,
        setHasDisabledAnimation,
        getDetailData,
        InviteOpen,
        setInviteOpen,
        handleInviteOpen,
        onClickSelfCheckInBtn
    } = props;

    // 예약취소 Drawer
    const [bookingCancelDrawer, setBookingCancelDrawer] = useState(false);
    const bookingCancelOpen = () => setBookingCancelDrawer(true);
    const bookingCancelClose = () => setBookingCancelDrawer(false);

    // 취소 안내 Alert - 타 플랫폼 예약건일 경우
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleAlertOpen = (msg) => {
        setAlertMessage(msg);
        setAlertOpen(true);
    };
    const handleAlertClose = () => setAlertOpen(false);

    // 취소 안내 Alert - 전화/홈페이지 예약건일 경우
    const [alertState, setAlertState] = useState(false);
    const onAlertClose = () => {
        setAlertState(false);
    };
    const onPhoneConnection = (phoneNumber) => {
        window.location.href = 'tel:' + phoneNumber;
    };

    // 취소 가능 기한 경과 유무
    const [passCancelAble, setPassCancelAble] = useState(true);

    // 취소사유 선택 풀 팝업창 (취소가능기한 경과한 건)
    const [cancelRegLayer, setCancelRegLayer] = useState(false);
    const cancelRegLayerOpen = () => setCancelRegLayer(true);
    const cancelRegLayerClose = () => setCancelRegLayer(false);

    // 필드예약 취소사유 레이어
    const [bookingCancelReasonLayer, setBookingCancelReasonLayer] = useState(false);
    const bookingCancelReasonOpen = () => setBookingCancelReasonLayer(true);
    const bookingCancelReasonClose = () => setBookingCancelReasonLayer(false);

    // 위약규정 Layer
    const [policyTitle, setPolicyTitle] = useState(null);
    const [policyContents, setPolicyContents] = useState(null);
    const [agreementOpen, setAgreementOpen] = useState(false);

    // 상세정보 열고 닫기
    const [isExpanded, setIsExpanded] = useState(false);

    // 라운드예정 > 예약취소 버튼 비활성화 여부 : 티타임이 지났거나 취소접수인경우
    const [isBookingCancel, setIsBookingCancel] = useState(false);
    const [isBookingCancelBtnDisabled, setIsBookingCancelBtnDisabled] = useState(false);

    // 취소/위약규정 노출 여부
    const [policyVisible, setPolicyVisible] = useState(false);

    // D-day
    const [dDay, setDDay] = useState('');

    // 외부예약
    const [externalBooking, setExternalBooking] = useState(false);

    useEffect(() => {
        // 라운드예정 > 예약취소 버튼 비활성화 여부 : 취소접수인경우
        const diffTeaTime = appUtils.calcTime(bookingData?.roundAt);
        if (bookingData?.bookingStatusDetail === 'F23' || bookingData?.bookingStatusDetail === 'F01') {
            setIsBookingCancel(true);
        }
        // 라운드예정 > 예약취소 버튼 비활성화 여부 : 티타임이 지난경우
        if (diffTeaTime < 0) {
            setIsBookingCancelBtnDisabled(true);
        }
    }, []);

    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                if (agreementOpen === true) {
                    agreementLayerClose();
                }
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);
        return () => {
            document.removeEventListener('message', handleEvent);
        };
    }, [agreementOpen]);

    useEffect(() => {
        if (bookingData?.bookingType !== 'GOLAGA') {
            setExternalBooking(true);
        } else {
            setExternalBooking(false);
        }
    }, [bookingData]);

    // 예약 상태별 아이콘
    const statusIcon = (state) => {
        if (state !== null) {
            if (status === 'pre' && bookingData?.bookingStatusDetail === 'F00') {
                if (bookingData?.bookingType !== 'GOLAGA') {
                    return <b className="statusIcon outReserve">외부예약</b>;
                } else {
                    return <b className="statusIcon">{state}</b>;
                }
            } else {
                if (bookingData?.bookingStatusDetail !== 'F00') {
                    // '예약 완료' 일때 제외하고 나와야됨
                    return <b className="statusIcon black">{state}</b>;
                }
            }
        }
    };

    // 예약 상태별 노출되는 버튼
    const rezBtn = (golfClubId) => {
        if (status === 'pre') {
            return <SmartRoundButton bookingData={bookingData} disabled={isBookingCancel} />;
        } else if (status === 'post') {
            return (
                <button
                    type="button"
                    className="btnPrimary"
                    onClick={() =>
                        history.navigate(`/field-detail/${golfClubId}`, {
                            state: {
                                flag: 'bookingList',
                                tab: 'post'
                            }
                        })
                    }
                >
                    다시 예약
                </button>
            );
        } else if (status === 'cancel') {
            return (
                <button
                    type="button"
                    className="btnPrimary"
                    onClick={() =>
                        history.navigate(`/field-detail/${golfClubId}`, {
                            state: {
                                flag: 'bookingList',
                                tab: 'cancel'
                            }
                        })
                    }
                >
                    예약 가능한 티타임 보기
                </button>
            );
        }
    };

    // 취소/위약규정 레이어
    const agreementLayerOpen = (data) => {
        setPolicyTitle(data.golfClubName);
        setPolicyContents(data.placeboInformation);
        setAgreementOpen(true);

        appUtils.openWebViewModalPostMsg();
    };
    const agreementLayerClose = () => {
        setAgreementOpen(false);
    };

    useEffect(() => {
        // 취소/위약규정 노출 여부 : 라운드예정 또는 지난라운드가 노쇼인경우
        if (status === 'pre' || (status === 'post' && bookingData?.bookingStatusDetail === 'F02')) {
            setPolicyVisible(true);
        }

        // 상세정보 열고닫기
        setIsExpanded(false);
    }, [status]);

    // 필드 상세로 이동
    const goFiledDetail = () => {
        history.navigate(`/field-detail/${bookingData.golfClubId}`, {
            state: {
                flag: 'bookingList',
                tab: status
            }
        });
    };

    // 디데이 체크
    const diffDay = useCallback(() => {
        if (!bookingData?.roundAt) {
            setDDay('');
            return;
        }
        let bookingDate = bookingData?.roundAt;
        let diff = appUtils.calcDDay(bookingDate);
        if (diff === 0) {
            setDDay('D-day');
        } else if (diff > 0) {
            setDDay(`D-${diff}`);
        } else {
            setDDay('');
        }
    }, [bookingData]);

    useEffect(() => {
        diffDay();
    }, [diffDay]);

    // 예약취소 버튼 클릭
    const [cancelAlert, setCancelAlert] = useState({
        open: false,
        title: null,
        message: null
    });

    const handleCancelAlertOpen = (alert) => {
        setCancelAlert(alert);
    };
    const handleCancelAlertClose = () => {
        setCancelAlert({
            open: false,
            title: null,
            message: null
        });
    };

    const onBookingCancel = async () => {
        //예약취소가능여부 조회
        fetchWrapper
            .get(`/cancel/possibility?bookingId=${bookingData.id}&timeSheetId=${bookingData?.timeSheetId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    bookingCancelOpen();

                    //7994 : 취소 경과
                    if (response?.data?.impossibleCode === '7994') {
                        // 취소가능기한이 경과한 경우 > 취소사유 선택 풀 팝업(규정 및 유의사항 체크 팝업) 오픈
                        setPassCancelAble(true);
                    } else {
                        // 필드예약 취소사유 선택 레이어 오픈
                        setPassCancelAble(false);
                    }
                } else {
                    // handleCancelAlertOpen({
                    //     open: true,
                    //     title: '취소 안내',
                    //     message: response?.data?.impossibleReason
                    // });

                    handleAlertOpen(response?.data?.impossibleReason);

                    // setPassCancelAble(true);
                }
            });
    };

    const onClickBookingCancel = debounce(() => {
        if (bookingData?.bookingType === 'OTHER') {
            handleAlertOpen(
                `본 예약 건은 ${bookingData.bookingTypeName}을(를) 통해\n예약하신 건으로 해당 서비스에서 취소해주세요.`
            );
        } else if (bookingData?.bookingType === 'HOMEPAGE' || bookingData?.bookingType === 'TEL') {
            setAlertState(true);
        } else {
            onBookingCancel();
        }
    }, 300);

    const getBookingTypeName = (bookingType) => {
        if (bookingData?.bookingType === 'HOMEPAGE') {
            return <dd>{bookingData?.golfClubShortName} 홈페이지</dd>;
        } else if (bookingData?.bookingType === 'TEL') {
            return <dd>{bookingData?.golfClubShortName} 전화예약</dd>;
        } else {
            return <dd>{bookingData?.bookingTypeName}</dd>;
        }
    };

    return (
        <>
            <>
                <div className="cardList02" id={bookingData.id}>
                    <div className="cardAnchor"></div>
                    <div className="innerSection">
                        <div className="rezInfoWrap">
                            <div className="rezInfo">
                                {statusIcon(bookingData.bookingStatusDetailName)}
                                <span className="num">예약 번호 : {bookingData.gbNo}</span>
                            </div>
                            {status === 'pre' && <b className="dDay">{bookingData?.beforeDays}</b>}
                        </div>
                        <div className="fieldInfo">
                            <div className="linkCon">
                                <div className="txtArea">
                                    <strong className="tt" onClick={goFiledDetail}>
                                        {bookingData.golfClubShortName}
                                    </strong>
                                    {bookingData?.isSelfCheckInPossible && (
                                        <button
                                            type="button"
                                            className="statusIcon selfCheckIn"
                                            onClick={onClickSelfCheckInBtn}
                                        >
                                            셀프체크인
                                        </button>
                                    )}
                                    <div className="roundInfo" onClick={goFiledDetail}>
                                        <p className="date">{appUtils.dateFormatTypeFull(bookingData.roundAt)}</p>
                                        <p className="list">
                                            <span>{bookingData.courseName}</span>
                                            <span>{bookingData.hole}</span>
                                        </p>
                                        {/* 
                                         TODO: 락커키 확인하고 주석 없애기 
                                        캐디 , 락커 정보 추가 */}
                                        {status === 'post' && (
                                            <p className="list">
                                                <span>
                                                    <b>캐디 :</b>
                                                    {validationUtil.isNullChk(bookingData?.caddieNames) ||
                                                    bookingData?.caddieNames === ''
                                                        ? ' -'
                                                        : ' ' + bookingData?.caddieNames}
                                                </span>
                                                <span>
                                                    <b>락커 : </b>
                                                    {validationUtil.isNullChk(bookingData?.lockerNo) ||
                                                    bookingData?.lockerNo === ''
                                                        ? ' -'
                                                        : ' ' + bookingData?.lockerNo}
                                                </span>
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="thumbArea" onClick={goFiledDetail}>
                                    {bookingData.stillImageUrl ? (
                                        <img src={bookingData.stillImageUrl} alt={bookingData.golfClubName} />
                                    ) : (
                                        <NoImg logoWidth="20px" />
                                    )}
                                </div>
                            </div>
                            {bookingData?.bookingTagList?.length > 0 && (
                                <div className="drawerTagList">
                                    <div className="drawer-tag-title">
                                        {bookingInfoProperty.booking_inner_condition}
                                    </div>
                                    {bookingData?.bookingTagList?.map((item, idx) => {
                                        if (!validationUtil.emptyStringCheck(item?.tagName)) {
                                            return <span key={idx}>{item?.tagName}</span>;
                                        }
                                    })}
                                </div>
                            )}

                            <div className="btnArea">{rezBtn(bookingData.golfClubId)}</div>
                        </div>
                        {status === 'pre' && (
                            <PartnerInvite
                                bookingData={bookingData}
                                bookingList={bookingList}
                                isBookingCancel={isBookingCancel}
                                setMemberReset={setMemberReset}
                                InviteOpen={InviteOpen}
                                setInviteOpen={setInviteOpen}
                                handleInviteOpen={handleInviteOpen}
                            />
                        )}
                        {externalBooking ? (
                            <>
                                <div className={`external-reservation `}>
                                    <div className="list">
                                        <dl>
                                            <dt>예약처</dt>
                                            {getBookingTypeName(bookingData?.bookingType)}
                                        </dl>
                                    </div>
                                    {status === 'post' && bookingData.promotionApplyYn === 'Y' ? (
                                        <div className="self-checkIn-promtion-wrap">
                                            <div className="self-checkIn-promotion-div">
                                                <h2>{paymentInfoProperty.self_checkIn_promotion}</h2>
                                                <span className="discountTxt">
                                                    {paymentInfoProperty.self_checkIn_promotion_discount}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="promotion-apply-n"></div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                <PaymentBookingCard status={status} bookingData={bookingData} />
                                {/* <div className={`rezDetailWrap ${isExpanded ? 'collapse' : 'expand'}`}>
                                            <button
                                                type="button"
                                                className="btnDetail"
                                                {...getToggleProps({
                                                    onClick: () => {
                                                        bookingData.isActive = !bookingData.isActive;
                                                        setIsExpanded((prevExpanded) => !prevExpanded);
                                                        setHasDisabledAnimation(false);
                                                    }
                                                })}
                                            >
                                                상세정보
                                            </button>

                                           <div className="rezDetailCon" {...getCollapseProps()}>
                                                <div className="rezDetail">
                                                    {bookingData.promotionApplyYn === 'Y' ? (
                                                        <div className="infoType06">
                                                            <dl>
                                                                <dt>셀프체크인 프로모션 적용</dt>
                                                                <dd className="discountTxt">1만원 할인</dd>
                                                            </dl>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <div className="infoType06">
                                                        <dl>
                                                            <dt>그린피(1인)</dt>
                                                            <dd>{appUtils.numberFormat(bookingData.perAmount)}원</dd>
                                                        </dl>
                                                        {bookingData?.bookingType === 'HOMEPAGE' ||
                                                        bookingData?.bookingType === 'TEL' ||
                                                        bookingData?.bookingType === 'OTHER' ? (
                                                            <dl>
                                                                <dt>
                                                                    총 현장 결제 금액
                                                                </dt>
                                                                <dd>
                                                                    <b>
                                                                        {bookingData.totalAmount === 0
                                                                            ? '그린피 골프장 문의'
                                                                            : `${appUtils.numberFormat(
                                                                                  bookingData.totalAmount
                                                                              )}원`}
                                                                    </b>
                                                                </dd>
                                                            </dl>
                                                        </>
                                                        <dl>
                                                            <dt>결제방법</dt>
                                                            <dd>{bookingData.paymentMethod}</dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                                <>
                                                    <div
                                                        className={
                                                            bookingData?.isCartFeeIncluded === true
                                                                ? 'rezDetail '
                                                                : 'rezDetail hide'
                                                        }
                                                    >
                                                        <div className="titleArea02">
                                                            <h2>
                                                                부대비용<span>(포함)</span>
                                                            </h2>
                                                        </div>
                                                        <div className="infoType06">
                                                            <dl>
                                                                <dt>카트피</dt>
                                                                <dd className="greenFee-include">그린피에 포함</dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                    <div className="rezDetail">
                                                        <div className="titleArea02">
                                                            <h2>
                                                                부대비용<span>(불포함)</span>
                                                            </h2>
                                                        </div>
                                                        <div className="infoType06">
                                                            <dl
                                                                className={
                                                                    bookingData?.isCartFeeIncluded === true
                                                                        ? 'hide'
                                                                        : ''
                                                                }
                                                            >
                                                                <dt>
                                                                    카트피<span>(현장결제)</span>
                                                                </dt>
                                                                <dd className="greenFee-include">
                                                                    {appUtils.numberFormat(bookingData?.cartAmount)}원
                                                                </dd>
                                                            </dl>
                                                            {bookingData?.caddieType === '노캐디' ? (
                                                                <></>
                                                            ) : (
                                                                <dl>
                                                                    <dt>
                                                                        캐디피<span>(현장결제)</span>
                                                                    </dt>
                                                                    <dd>{bookingData?.caddieAmount}</dd>
                                                                </dl>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                                {bookingData?.timeSheetTagList?.length > 0 && (
                                                    <div className="rezDetail">
                                                        <div className="infoType06 innerCondition">
                                                            <dl>
                                                                <dt>내장조건</dt>
                                                                <dd>
                                                                    {bookingData.timeSheetTagList.map((item, idx) => {
                                                                        return <p key={idx}>{item.tagName}</p>;
                                                                    })}
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                )}
                                                {(bookingData.cancelReceptionAt ||
                                                    bookingData.cancelCompletionDate) && (
                                                    <div className="rezDetail">
                                                        <div className="infoType06">
                                                            <h4>※취소 정보</h4>
                                                            <div>
                                                                {bookingData.cancelReceptionAt && (
                                                                    <dl>
                                                                        <dt>취소 접수 일시</dt>
                                                                        <dd>
                                                                            {appUtils.dateFormatTypeFull(
                                                                                bookingData.cancelReceptionAt
                                                                            )}
                                                                        </dd>
                                                                    </dl>
                                                                )}
                                                                {bookingData.cancelCompletionDate && (
                                                                    <dl>
                                                                        <dt>취소 완료 일시</dt>
                                                                        <dd>
                                                                            {appUtils.dateFormatTypeFull(
                                                                                bookingData.cancelCompletionDate
                                                                            )}
                                                                        </dd>
                                                                    </dl>
                                                                )}
                                                                <dl>
                                                                    <dt>취소 사유</dt>
                                                                    <dd>
                                                                        {bookingData.cancelReasonDetail
                                                                            ? bookingData.cancelReasonDetail
                                                                            : bookingData.cancelReasonName}
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div> */}
                            </>
                        )}
                        {policyVisible && (
                            <div className="cancelPeriodArea">
                                {status === 'pre' && (
                                    <>
                                        {bookingData?.bookingType === 'GOLAGA' ? (
                                            <div className="ttWrap">
                                                <h3>
                                                    {bookingCancel.cancel_possible}

                                                    <Badge
                                                        type={`${
                                                            bookingData?.isAfterCancelPeriod
                                                                ? 'CANCEL_RECEPTION_MYPAGE'
                                                                : 'CANCEL_POSSIBILITY_DATE_MYPAGE'
                                                        }`}
                                                        date={dateUtil.cancelDateRestTime(
                                                            bookingData?.cancelPossiblePeriod
                                                        )}
                                                    />
                                                </h3>
                                                <b
                                                    className={`cancel-period-date ${
                                                        bookingData?.isAfterCancelPeriod ? 'c-red' : 'c-primary'
                                                    }`}
                                                >
                                                    {appUtils.dateFormatTypeFull(bookingData?.cancelPossiblePeriod)}
                                                </b>
                                            </div>
                                        ) : (
                                            <div className="ttWrap external-booking">
                                                <h3>{bookingCancel.cancel_possible}</h3>

                                                <b>{bookingInfoProperty.booking_external_confirm}</b>
                                            </div>
                                        )}
                                    </>
                                )}
                                {policyVisible && (
                                    <div className="InfoFlexCon">
                                        <p>
                                            {status === 'post'
                                                ? '사전에 취소하지 않고 노쇼 시 페널티가 발생할 수 있습니다.'
                                                : bookingCancel.cancel_possible_contract}
                                        </p>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                fetchWrapper
                                                    .get(
                                                        `/amplitude-events/click-reservation-btn?roundBookingId=${bookingData?.id}`,
                                                        null
                                                    )
                                                    .then((response) => {
                                                        if (response.code === SUCCESS_CODE) {
                                                            clickCancelPolicy(response.data, getAmplitudeRootPath());
                                                        }
                                                    });

                                                agreementLayerOpen(bookingData);
                                            }}
                                        >
                                            취소/위약규정
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        {status === 'pre' && !isBookingCancel && (
                            <div className="btnArea">
                                <button
                                    type="button"
                                    className="btnDarkGray"
                                    onClick={onClickBookingCancel}
                                    disabled={isBookingCancelBtnDisabled}
                                >
                                    예약 취소
                                </button>

                                {/* ----------------------- 0509 by joanna ios 앱심사 승인완료 다시살리기 --------------------*/}
                                {/* 3. 내 정보> 예약내역
                                        - 예정 라운드(탭)> 예약 목록 하단 '길 찾기' 버튼 삭제(히든)
                                        예약취소 버튼 길게*/}
                                <button
                                    type="button"
                                    /*className="btnPrimary hide"*/
                                    className="btnPrimary"
                                    onClick={() => getDetailData(bookingData.golfClubId)}
                                >
                                    길 찾기
                                </button>
                                {/* ----------------------- 0509 by joanna ios 앱심사 승인완료 다시살리기 --------------------*/}
                            </div>
                        )}
                    </div>
                </div>
            </>
            {/*예약 취소 Drawer*/}
            {/* <BookingCancelDrawer
                handleOpen={bookingCancelDrawer}
                handleClose={bookingCancelClose}
                bookingData={bookingData}
                cancelRegLayerOpen={cancelRegLayerOpen}
                bookingCancelReasonOpen={bookingCancelReasonOpen}
                passCancelAble={passCancelAble}
            /> */}

            <PaymentBookingCancelConfirm
                handleOpen={bookingCancelDrawer}
                handleClose={bookingCancelClose}
                bookingData={bookingData}
                cancelRegLayerOpen={cancelRegLayerOpen}
                bookingCancelReasonOpen={bookingCancelReasonOpen}
                passCancelAble={passCancelAble}
                policyVisible={policyVisible}
                agreementLayerOpen={agreementLayerOpen}
                status={status}
            />

            {/*예약 취소 실패시 Alert*/}
            <Modal open={cancelAlert.open}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">{cancelAlert.title}</div>
                        <div className="alertContents">{cancelAlert.message}</div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={handleCancelAlertClose}>
                            확인
                        </button>
                    </div>
                </div>
            </Modal>

            {/*취소 안내 Alert - 타 플랫폼 예약건일 경우 */}
            <DefaultAlert
                open={alertOpen}
                handleOpen={handleAlertOpen}
                handleClose={handleAlertClose}
                title={''}
                contents={alertMessage}
            />

            {/*취소 안내 Alert - 전화/홈페이지 예약건일 경우 */}
            <Modal open={alertState} onClose={onAlertClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div className="alertContents">{`본 예약 건은 골프장을 통해서만
                            취소하실 수 있습니다.
                            ${bookingData?.golfClubShortName} ${bookingData?.golfClubPhoneNumber}`}</div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={onAlertClose}>
                            닫기
                        </button>
                        <button type="button" onClick={() => onPhoneConnection(bookingData?.golfClubPhoneNumber)}>
                            전화연결
                        </button>
                    </div>
                </div>
            </Modal>

            {/*취소사유 선택 풀 팝업창 : 규정 및 유의사항 체크 팝업 (취소가능기한 경과한 건)*/}
            <CancelRegulationLayer
                handleOpen={cancelRegLayer}
                handleClose={cancelRegLayerClose}
                contents={bookingData?.placeboInformation || ''}
                bookingData={bookingData}
                bookingCancelReasonOpen={bookingCancelReasonOpen}
            />

            {/*취소사유 선택*/}
            <BookingCancelReasonLayer
                handleOpen={bookingCancelReasonLayer}
                handleClose={bookingCancelReasonClose}
                bookingData={bookingData}
                setBookingListReset={setBookingListReset}
                passCancelAble={passCancelAble}
            />

            {/*위약규정 Layer*/}
            <PolicyLayer
                open={agreementOpen}
                agreeType="GOLF_CLUB_AGREE"
                title={`${policyTitle} 취소/위약 규정` || ''}
                handleClose={agreementLayerClose}
                contents={policyContents || ''}
            />
        </>
    );
}

export default BookingCardList;
