import Drawer from '@mui/material/Drawer';
import Modal from '@mui/material/Modal';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {fetchWrapper} from '../../../_helpers';
import {setAddress} from '../../../_helpers/amplitude/events/etc.event';
import {alertActions} from '../../../_store/alert.slice';
import {CANCELTMESSAGE, SETTINGMESSAGE, SUCCESS_CODE} from '../../../common/constants';
import {localStorageUtil} from '../../../common/utils';
import CommonAlert from '../../alert/CommonAlert';
import Icon from '../../icons/Icon';
import RecentSearchBelow from './RecentSearchBelow';
import SearchGuideBelow from './SearchGuideBelow';
import SearchResultBelow from './SearchResultBelow';

const {kakao} = window;

let _onSelectedLocation = null;
let _setLocationFilterOpen = null;
let _dispatch = null;

const checkPermission = () => {
    if (!_dispatch) return;
    const locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
    const device = locationStorage.device;

    // 위치정보 동의 권한 확인
    if (device === 'android' && locationStorage.gpsrequest === 1) {
        _dispatch(
            alertActions.showAlert({
                type: CANCELTMESSAGE,
                messageData: {
                    title: '앱 권한 요청',
                    message:
                        '현재 위치 설정 사용을 위해 디바이스 > 설정 > 앱 권한에서 위치 권한을 허용해주세요. (필수권한)'
                }
            })
        );
    } else if (device === 'android' && locationStorage.gpsrequest === 2) {
        _dispatch(
            alertActions.showAlert({
                type: SETTINGMESSAGE,
                messageData: {
                    title: '앱 권한 요청',
                    message:
                        '현재 위치 설정 사용을 위해 디바이스 > 설정 > 앱 권한에서 위치 권한을 허용해주세요. (필수권한)'
                }
            })
        );
    } else if (device === 'ios' && locationStorage.gpsrequest === 1) {
        _dispatch(
            alertActions.showAlert({
                type: SETTINGMESSAGE,
                messageData: {
                    title: '앱 권한 요청',
                    message:
                        '현재 위치 설정 사용을 위해 디바이스 > 설정 > 골라가 > 위치에서 권한을 허용해주세요. (필수권한)'
                }
            })
        );
    }
};

export const checkGPSOnOFfAndsetLocation = () => {
    const isGpsOn = JSON.parse(localStorageUtil.get('GPSONOFF'));

    if (isGpsOn === 1) {
        checkPermission();
        return;
    } else if (isGpsOn === 2) {
        const locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
        const device = locationStorage.device;
        if (device === 'ios') {
            _dispatch(
                alertActions.showAlert({
                    type: SETTINGMESSAGE,
                    messageData: {
                        title: '위치 서비스 사용',
                        message: '현재 위치 설정 사용을 위해 기기의 "설정 > 개인정보 보호"에서 위치 서비스를 켜주세요.'
                    }
                })
            );
        }
        return;
    }

    const locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));

    if (locationStorage.gpsrequest === 0) {
        if (_onSelectedLocation && _setLocationFilterOpen) {
            // 위치 권한 설정 했으면
            _onSelectedLocation('currentPlace', null);
            _setLocationFilterOpen(false);
        }
    }
};

function LocationDrawer({locationFilterOpen, setLocationFilterOpen, onSelectedLocation, snackBarOpen}) {
    // 최근검색 유무 (없을 시 검색가이드 화면)
    const [isRecentSearch, setIsRecentSearch] = useState(true);
    // 컨텐츠 보여줄 조건 세팅
    const [contentsViewer, setContentsViewer] = useState('default');
    // 장소 검색 객체를 생성합니다
    const kakaoPlaces = kakao ? new kakao.maps.services.Places() : null;
    // 장소 검색 결과 리스트
    const [placeList, _setPlaceList] = useState([]);
    const myPlaceList = useRef(placeList);
    const setPlaceList = (list) => {
        myPlaceList.current = list;
        _setPlaceList(list);
    };

    // 페이지 갯수
    const [page, _setPage] = useState(0);
    const myPage = useRef(page);
    const setPage = (pageNum) => {
        myPage.current = pageNum;
        _setPage(pageNum);
    };

    const _recentSearchList = JSON.parse(localStorageUtil.get('recentSearchAreaLocation'));
    // 최근 검색 리스트
    const [recentSearchList, setRecentSearchList] = useState(_recentSearchList ? _recentSearchList : []);

    // 저장된 출발위치
    const [savedStartingPoint, setSavedStartingPoint] = useState([]);

    // 집 위치설정
    const [homeLocation, setHomeLocation] = useState({address: ''});
    // 회사 위치설정
    const [companyLocation, setCompanyLocation] = useState({address: ''});

    // Input
    const [deleteBtnShow, setDeleteBtnShow] = useState(''); // 삭제 버튼 Show, Hide 제어
    const [searchWord, setSearchWord] = useState('');
    let preSearchWord = useRef('');
    const inputRef = useRef();

    // 집 또는 회사 설정 Alert open
    const [yesOrNoAlertOpen, setYesOrNoAlertOpen] = useState(false);
    const [homeOrCompanyText, setHomeOrCompanyText] = useState('');
    const [selectedPlace, setSelectedPlace] = useState({});

    //GPS 위성 정보(현재 좌표) 확인
    let locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
    // 위치정보 동의 권한 확인
    let gpsRequest = locationStorage?.gpsrequest || '';
    const dispatch = useDispatch();
    _dispatch = dispatch;
    _setLocationFilterOpen = setLocationFilterOpen;
    _onSelectedLocation = onSelectedLocation;

    // 디바이스 체크
    const [device, setDevice] = useState(locationStorage?.device || '');
    // 스크롤
    const [hasNext, _setHasNext] = useState(false);
    const myHasNext = useRef(hasNext);
    const setHasNext = (isNext) => {
        myHasNext.current = isNext;
        _setHasNext(isNext);
    };

    useEffect(() => {
        // 최근 검색 내역 있으면 최근 검색 결과 화면 보여주기
        isRecentSearch && setContentsViewer('recentSearch');

        // RN에서 웹으로 데이터를 전송했을때 message이벤트가 실행됩니다.
        document.addEventListener('message', (e) => {
            if (typeof e.data == 'object') {
                return;
            }
            try {
                gpsRequest = JSON.parse(e.data)?.gpsrequest;
            } catch (e) {}
        });

        window.addEventListener('message', (e) => {
            if (typeof e.data == 'object') {
                return;
            }
            try {
                gpsRequest = JSON.parse(e.data)?.gpsrequest;
            } catch (e) {}
        });
    }, []);

    useEffect(() => {
        if (locationFilterOpen) {
            setSearchWord('');
            setPlaceList([]);
            setPage(1);
            setContentsViewer('recentSearch');
            preSearchWord = '';

            fetchWrapper.get(`/member/pre/starting-position`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    //console.log('저장된 출발위치(집, 회사) 검색 완료', response);
                    setSavedStartingPoint(response.data);
                } else {
                    console.log(response?.error);
                }
            });
        }
    }, [locationFilterOpen]);

    useEffect(() => {
        if (savedStartingPoint.length !== 0) {
            for (let i = 0; i < savedStartingPoint.length; i++) {
                if (savedStartingPoint[i].locationType === 'HOME') {
                    setHomeLocation(savedStartingPoint[i]);
                } else {
                    setCompanyLocation(savedStartingPoint[i]);
                }
            }
        }
    }, [savedStartingPoint]);

    // 주소지 재설정 alert 예/아니오 버튼 함수
    const alertYes = () => {
        const address_do_gu_gun = `${selectedPlace.city_do} ${selectedPlace.gu_gun}`;
        homeOrCompanyText === '집' ? setAddress(address_do_gu_gun, 'home') : setAddress(address_do_gu_gun, 'company');

        setYesOrNoAlertOpen(false);
        savePlace(homeOrCompanyText === '집' ? 'HOME' : 'COMPANY', selectedPlace);
    };

    const alertNo = () => {
        setYesOrNoAlertOpen(false);
    };

    const getPlaceAddr = (selectedPlace) => {
        const roadAddr = `${selectedPlace.city_do} ${selectedPlace.gu_gun} ${selectedPlace.newRoadName} ${selectedPlace.newBuildingIndex}`;

        const orginAddr = `${selectedPlace.city_do} ${selectedPlace.gu_gun} ${
            selectedPlace.legalDong === ''
                ? `${selectedPlace.eup_myun} ${selectedPlace.ri}`
                : `${selectedPlace.legalDong}`
        } ${selectedPlace.bunji}`;

        const townName = `${selectedPlace.legalDong === '' ? `${selectedPlace.ri}` : `${selectedPlace.legalDong}`}`;

        return {
            roadAddr: roadAddr,
            orginAddr: orginAddr,
            townName: townName
        };
    };

    const savePlace = (type, selectedPlace) => {
        const mySelectedPlace = getPlaceAddr(selectedPlace);
        const payload = {
            address: mySelectedPlace.roadAddr,
            locationLat: parseFloat(selectedPlace.lat),
            locationLon: parseFloat(selectedPlace.lon),
            locationType: type,
            townName: mySelectedPlace.townName
        };

        fetchWrapper.post(`/member/pre/starting-position/create`, payload).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                snackBarOpen(`출발위치를 ${mySelectedPlace.townName} (으)로 설정했어요!`);
            } else {
                console.log('starting-position error', response);
            }
        });

        const myLocationData = {
            ...payload,
            orginAddr: mySelectedPlace.orginAddr
        };

        type === 'HOME' ? setHomeLocation(myLocationData) : setCompanyLocation(myLocationData);

        // 중복제거
        const duplicationRemoveList = recentSearchList.filter((item) => item.address !== mySelectedPlace.roadAddr);
        setRecentSearchList([myLocationData, ...duplicationRemoveList]);
    };

    // 검색 결과 리스트 아이템의 버튼 (집, 회사, 선택) 클릭
    const onClickButton = (type, place) => {
        setSelectedPlace(place);
        const mySelectedPlace = getPlaceAddr(place);

        if (type === 'HOME' || type === 'COMPANY') {
            // '집' '회사' 버튼클릭
            setHomeOrCompanyText(type === 'HOME' ? '집' : '회사');
            if (homeLocation.address === '' || companyLocation.address === '') {
                savePlace(type, place);
            } else {
                setYesOrNoAlertOpen(true);
            }
        } else if (type === 'SELECT') {
            // '출발위치' 클릭
            const address_do_gu_gun = `${place?.city_do} ${place?.gu_gun}`;
            setAddress(address_do_gu_gun, 'departure');

            if (!place?.lat) throw new Error('NO LAT');

            // '선택' 클릭
            const placeInfo = {
                address: mySelectedPlace.roadAddr,
                locationLat: Number(place?.lat),
                locationLon: Number(place?.lon),
                locationType: type,
                orginAddr: mySelectedPlace.orginAddr,
                townName: mySelectedPlace.townName
            };

            // 최근검색리스트 중복제거
            const duplicationRemoveList = recentSearchList.filter((item) => item.address !== mySelectedPlace.roadAddr);
            setRecentSearchList([placeInfo, ...duplicationRemoveList]);
            onSelectedLocation('SELECT', placeInfo);
            setLocationFilterOpen(false);
        }
    };

    const onClickRecentSearchItem = (placeItem) => {
        // 최근검색리스트 중복제거
        const duplicationRemoveList = recentSearchList.filter((item) => item.address !== placeItem.address);
        setRecentSearchList([placeItem, ...duplicationRemoveList]);

        onSelectedLocation('SELECT', placeItem);
        setLocationFilterOpen(false);
    };

    const onRemove = (place) => {
        setRecentSearchList(recentSearchList.filter((item) => item.address !== place.address));
    };

    const onRemoveAll = () => {
        setRecentSearchList([]);
    };

    // 집 또는 회사 설정된 주소 삭제
    const onClickAreaClear = (type) => {
        let locationId = '';
        if (type === 'home') {
            locationId = homeLocation.locationId;
            setHomeLocation({address: ''});
        } else if (type === 'company') {
            locationId = companyLocation.locationId;
            setCompanyLocation({address: ''});
        }

        fetchWrapper.delete(`/member/pre/starting-position/delete/${locationId}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                console.log(`${type} 위치 삭제`);
            } else {
                console.log(response.message);
            }
        });
    };

    const gpsOnOffCheck = () => {
        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                gpsreturn: 'gpsOnOffCheck',
                callLocation: 'locationDrawer'
            })
        );
    };

    const goSetting = useCallback(async () => {
        const locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
        //console.log('goSetting() IN gpsRequest: ', locationStorage.gpsrequest, 'locationStorage', locationStorage);
        const device = locationStorage.device;
        const isGpsOn = JSON.parse(localStorageUtil.get('GPSONOFF'));

        if (device === 'ios' && isGpsOn === 2) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    gpsreturn: 'iosGoSetting'
                })
            );

            return;
        }

        if (locationStorage.gpsrequest === 1) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    gpsreturn: 'Rerequest'
                })
            );
        } else if (locationStorage.gpsrequest === 2) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    gpsreturn: 'goSetting'
                })
            );
        }
    }, []);

    // 드로어 상단에 현재위치, 집위치, 회사위치 클릭했을때
    const onClickArea = (type) => {
        if (type === 'HOME') {
            if (homeLocation.address !== '') {
                onSelectedLocation('home', homeLocation);
                setLocationFilterOpen(false);
            }
        } else if (type === 'COMPANY') {
            if (companyLocation.address !== '') {
                onSelectedLocation('company', companyLocation);
                setLocationFilterOpen(false);
            }
        } else if (type === 'currentPlace') {
            // gpsOnOffCheck();
            const locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
            const device = locationStorage?.device;

            // 위치정보 동의 권한 확인
            if (device === 'android' && locationStorage.gpsrequest === 1) {
                _dispatch(
                    alertActions.showAlert({
                        type: CANCELTMESSAGE,
                        messageData: {
                            title: '앱 권한 요청',
                            message:
                                '현재 위치 설정 사용을 위해 디바이스 > 설정 > 앱 권한에서 위치 권한을 허용해주세요. (필수권한)'
                        }
                    })
                );
            } else if (device === 'android' && locationStorage.gpsrequest === 2) {
                _dispatch(
                    alertActions.showAlert({
                        type: SETTINGMESSAGE,
                        messageData: {
                            title: '앱 권한 요청',
                            message:
                                '현재 위치 설정 사용을 위해 디바이스 > 설정 > 앱 권한에서 위치 권한을 허용해주세요. (필수권한)'
                        }
                    })
                );
            } else if (device === 'ios' && locationStorage.gpsrequest === 1) {
                _dispatch(
                    alertActions.showAlert({
                        type: SETTINGMESSAGE,
                        messageData: {
                            title: '앱 권한 요청',
                            message:
                                '현재 위치 설정 사용을 위해 디바이스 > 설정 > 골라가 > 위치에서 권한을 허용해주세요. (필수권한)'
                        }
                    })
                );
            } else {
                onSelectedLocation('currentPlace', null);
                setLocationFilterOpen(false);
            }
        }
    };

    let belowViewerCondition = {
        default: <SearchGuideBelow />, // 검색 가이드
        recentSearch: (
            <RecentSearchBelow
                recentSearchList={recentSearchList}
                onRemove={onRemove}
                onRemoveAll={onRemoveAll}
                onClickRecentItem={onClickRecentSearchItem}
            />
        ), // 최근 검색
        // 검색 결과
        searchResult: (
            <SearchResultBelow
                placeList={myPlaceList.current}
                onClickButton={onClickButton}
                hasNext={myHasNext}
                setPage={setPage}
                currentPage={myPage.current}
            />
        )
    };

    useEffect(() => {
        if (page !== 1) {
            getPlaceList();
        }
    }, [page]);

    useEffect(() => {
        if (contentsViewer === 'default' || contentsViewer === 'searchResult') {
            setPlaceList([]);
            setPage(1);
        }
    }, [contentsViewer]);

    useEffect(() => {
        if (recentSearchList.length > 20) {
            recentSearchList.pop();
            setRecentSearchList(recentSearchList);
        }
        localStorageUtil.set('recentSearchAreaLocation', JSON.stringify(recentSearchList));
    }, [recentSearchList]);

    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                if (locationFilterOpen === true) {
                    onDrawerClose();
                }
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);
        return () => {
            document.removeEventListener('message', handleEvent);
        };
    }, [locationFilterOpen]);

    // drawer 닫기 버튼
    const onDrawerClose = () => {
        setLocationFilterOpen(false);
    };

    const handleOnKeyPress = (e) => {
        if (e.keyCode === 13) {
            if (searchWord === '') {
                return;
            }
            inputRef.current.blur();
            onClickSearch();
        }
    };

    // 검색창 입력 이벤트
    const onChangeSearch = (e) => {
        setDeleteBtnShow(e.target.value);
        setSearchWord(e.target.value);
        setContentsViewer('default');
    };

    // 검색창 초기화
    const clearSearchWord = () => {
        setSearchWord('');
        setDeleteBtnShow('');
        setContentsViewer('recentSearch');
    };

    // 검색 버튼 클릭 이벤트
    const onClickSearch = () => {
        setContentsViewer('searchResult');

        setPage(1);
        setPlaceList([]);

        getPlaceList();
    };

    let searchResultPageCount = 0;

    const getPlaceList = () => {
        if (searchWord === '') {
            return;
        }

        const searchOption = {
            size: 10, //한번에 보여줄 주소 갯수
            page: myPage.current //보고싶은 페이지
        };

        // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
        fetch(
            `https://apis.openapi.sk.com/tmap/geo/postcode?appKey=EjLNVEBVhu8j8ch3AT37V2cmowkakcCN90PdxxL8&coordType=WGS84GEO&addressFlag=F00&addr=${searchWord}&format=json&page=${myPage.current}&count=${searchOption.size}`,
            {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json'
                }
            }
        )
            .then((response) => response.json())
            .then((response) => {
                if (response?.coordinateInfo?.length !== 0) {
                    const searchPoiInfo = response?.coordinateInfo;
                    const dividePageCount = parseInt(searchPoiInfo.totalCount / searchOption.size);
                    const remainPage = searchPoiInfo.totalCount % searchOption.size;

                    if (remainPage === 0) {
                        searchResultPageCount = dividePageCount;
                    } else {
                        searchResultPageCount = dividePageCount + 1;
                    }

                    if (myPage.current < searchResultPageCount) {
                        setHasNext(true);
                    } else {
                        setHasNext(false);
                    }

                    if (myPlaceList.current.length === 0) {
                        setPlaceList(searchPoiInfo.coordinate);
                    } else {
                        setPlaceList([...myPlaceList.current, ...searchPoiInfo.coordinate]);
                    }

                    preSearchWord.current = searchWord;
                }
            })
            .catch((error) => {
                console.log('Tmap API error!!', error);
            });
    };

    return (
        <Drawer anchor="bottom" open={locationFilterOpen} onClose={onDrawerClose} className="locationDrawer">
            <div className="drawer-content">
                <div className="drawerTitle">
                    <div className="tt">출발위치 설정</div>
                    <button type="button" className="btnClose" onClick={onDrawerClose}>
                        <Icon name="close02" width="24" height="24" viewBox="0 0 24 24" />
                    </button>
                </div>
                <div className="drawerContents">
                    <div className="inner">
                        <div className="searchBox">
                            <div className="iconInput">
                                <input
                                    type="text"
                                    value={searchWord}
                                    placeholder="출발위치를 검색하세요."
                                    onChange={onChangeSearch}
                                    onKeyDown={handleOnKeyPress}
                                    ref={inputRef}
                                />
                            </div>
                            <div className="btnArea">
                                {deleteBtnShow?.length > 0 && (
                                    <button type="button" className="btnDelete" onClick={clearSearchWord}></button>
                                )}
                                <span onClick={onClickSearch}>
                                    <Icon name="search" width="15" height="17" viewBox="0 0 15 17" />
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="inner">
                        <div className="locationSetting">
                            <div className="locationIcon" onClick={() => onClickArea('currentPlace')}>
                                <Icon name="gps_02" width="20" height="20" viewBox="0 0 20 20" />
                                현재 위치로 설정하기
                            </div>
                            <div
                                className={`locationIcon addClose ${
                                    homeLocation.address === '' ? 'isNotSelected' : ''
                                }`}
                            >
                                <div onClick={() => onClickArea('HOME')}>
                                    <Icon
                                        name="home"
                                        width="20"
                                        height="20"
                                        fill={`${homeLocation.address === '' ? '#A0A4AF' : '#000'}`}
                                    />
                                    {`${homeLocation.address === '' ? '집' : `집 - ${homeLocation.address}`}`}
                                </div>
                                <div onClick={() => onClickAreaClear('home')}>
                                    {homeLocation.address !== '' && (
                                        <Icon name="close" width="12" height="12" fill="#848588" />
                                    )}
                                </div>
                            </div>
                            <div
                                className={`locationIcon addClose ${
                                    companyLocation.address === '' ? 'isNotSelected' : ''
                                }`}
                            >
                                <div onClick={() => onClickArea('COMPANY')}>
                                    <Icon
                                        name="office"
                                        width="20"
                                        height="20"
                                        fill={`${companyLocation.address === '' ? '#A0A4AF' : '#000'}`}
                                    />
                                    {`${companyLocation.address === '' ? '회사' : `회사 - ${companyLocation.address}`}`}
                                </div>
                                <div onClick={() => onClickAreaClear('company')}>
                                    {companyLocation.address !== '' && (
                                        <Icon name="close" width="12" height="12" fill="#848588" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 최근검색 / 검색가이드 / 검색결과 */}
                    <div className="contentsArea">{belowViewerCondition[contentsViewer]}</div>
                </div>
            </div>

            {/* 출발 위치 설정 Alert */}
            <Modal open={yesOrNoAlertOpen}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">출발위치 설정</div>
                        <div className="alertContents">
                            이미 {homeOrCompanyText}으로 설정된 주소가 있습니다.
                            <br />재 설정 하시겠습니까?
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={alertYes}>
                            예
                        </button>
                        <button type="button" onClick={alertNo}>
                            아니오
                        </button>
                    </div>
                </div>
            </Modal>

            {/* 위치정보 동의 권한 확인 Alert */}
            <CommonAlert successCallBack={goSetting} />
        </Drawer>
    );
}

export default LocationDrawer;
