// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getRemoteConfig} from 'firebase/remote-config';
import {getValue} from 'firebase/remote-config';
import {fetchAndActivate} from 'firebase/remote-config';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyA5YL3wdQrjhiVTzeS_GNjLkG5L1oEQj_U',
    authDomain: 'golaga-b7b3c.firebaseapp.com',
    projectId: 'golaga-b7b3c',
    storageBucket: 'golaga-b7b3c.appspot.com',
    messagingSenderId: '751795080205',
    appId: '1:751795080205:web:3b41975831e079d8989944',
    measurementId: 'G-DBNDZC1DXV'
};

// Initialize Firebase
let app = null;
// Initialize Remote Config and get a reference to the service
let remoteConfigInstance = null;

const Firebase = {
    initializeFirebase: () => {
        app = initializeApp(firebaseConfig);
    },
    remoteConfig: {
        initialize: () => {
            remoteConfigInstance = getRemoteConfig(app);
            remoteConfigInstance.settings.minimumFetchIntervalMillis = 60000;
            remoteConfigInstance.defaultConfig = {
                appUnderReview: true
            };
        },
        getConfigValue: (parameter) => {
            return fetchAndActivate(remoteConfigInstance)
                .then(() => {
                    const remoteConfigValue = getValue(remoteConfigInstance, parameter).asString();
                    return JSON.parse(remoteConfigValue);
                })
                .catch((err) => {
                    console.log('Error fetching remote config:', err);
                    return null;
                });
        }
    }
};

export const CONFIG = {
    APP_VER: 'appVersion'
};

export default Firebase;
