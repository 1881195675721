import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {authReducer} from './auth.slice';
import {usersReducer} from './users.slice';
import {timerReducer} from './timer.slice';
import {appReducer} from './app.slice';
import {persistReducer} from 'redux-persist';
import thunk from 'redux-thunk';
import {alertReducer} from './alert.slice';
import {checkinReducer} from './checkin.slice';
import {inviteReducer} from './invite.slice';
import {kakaoNotInstallReducer} from './kakaoNotInstall.slice';
import {joinReducer} from './joinOnShareLink.slice';
import {globalReducer} from './global.slice';
import {smartRoundReducer} from './smartRound.slice';
import {paymentBookingReducer} from './paymentBooking.slice';
import {loadingReducer} from './loading.slice';
import {promotionReducer} from './promotion.slice';

export * from './auth.slice';
export * from './users.slice';

const reducers = combineReducers({
    auth: authReducer,
    users: usersReducer,
    timer: timerReducer,
    app: appReducer,
    alert: alertReducer,
    checkin: checkinReducer,
    invite: inviteReducer,
    kakaoNotInstall: kakaoNotInstallReducer,
    joinOnShareLink: joinReducer,
    global: globalReducer,
    smartRound: smartRoundReducer,
    paymentBooking: paymentBookingReducer,
    loading: loadingReducer,
    promotion: promotionReducer
});

const persistedReducer = persistReducer(
    {
        key: 'root',
        storage,
        whitelist: ['users']
    },
    reducers
);

const reducerProxy = (state, action) => {
    if (action.type === 'auth/logout') {
        return persistedReducer(undefined, action);
    }
    return persistedReducer(state, action);
};

export const store = configureStore({
    reducer: reducerProxy,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
});
