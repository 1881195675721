import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import Icon from "../icons/Icon";

function GolfClubGatePage() {
    // 스크롤 없는 풀페이지 스타일 선언을 위한 클래스명 추가
    useEffect(() => {
        document.documentElement.classList.add("fullPage");
        return () => {
            document.documentElement.classList.remove("fullPage");
        };
    }, []);

    return (
        <>
            <div id="golfClubGate" className="contentsArea">
                <div className="inner">
                    <div className="gateTitle">
                        <h1>당신에게 맞는 골프 장소<br/><strong>편하게 골라가</strong></h1>
                        <p>골프를 즐기기 위해 가고자 하는 곳을<br/>선택해주세요</p>
                    </div>
                    <div className="gateBtnArea">
                        <Link to="/field-main"><Icon name="out-door" viewBox="0 0 32 32"/> 골프장</Link>
                        <Link to="#" className="readyAlert"><Icon name="in-door" viewBox="0 0 32 32"/> 실내 연습장</Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GolfClubGatePage;
