import React, {useCallback, useEffect, useState} from 'react';
import Header from '../Header';
import TitleArea from '../common/TitleArea';
import CardList from '../../_view/booking/CardList';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import CommonAlert from '../alert/CommonAlert';
import {useDispatch, useSelector} from 'react-redux';
import Navigation from '../navigation/Navigation';
import Modal from '@mui/material/Modal';
import {kakaoNotInstallActions} from '../../_store/kakaoNotInstall.slice';
import {installLink} from '../../common/links';
import SelfCheckInGuideLayer from '../smart-round/SelfCheckInGuideLayer';
import {appUtils} from '../../common/utils';
import {viewSmartRoundList} from '../../_helpers/amplitude/events/view.events';

function Booking({title}) {
    const [bookingData, setBookingData] = useState(null);
    const [memberReset, setMemberReset] = useState(false);

    const dispatch = useDispatch();

    // 셀프체크인 가이드 레이어 오픈 여부
    const [selfCheckInGuideLayerOpen, setSelfCheckInGuideLayerOpen] = useState(false);
    const handleOpen = () => setSelfCheckInGuideLayerOpen(true);
    const handleClose = () => setSelfCheckInGuideLayerOpen(false);

    const getBookingList = useCallback(() => {
        fetchWrapper.get(`/booking/list/smartround?includeMember=true`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setBookingData(response?.data.lists || null);
                setMemberReset(false);

                viewSmartRoundList(response?.data.lists?.length);
            } else {
                setBookingData([]);
            }
        });
    }, [memberReset]);

    useEffect(() => {
        getBookingList();
    }, [getBookingList]);

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    // Alert 확인 클릭 시 멤버 리셋
    const goSetting = useCallback(async () => {
        setMemberReset(true);
        // console.log('goSetting 실행');
    }, []);

    // ===== 카카오톡 미 설치 시 설치 페이지로 이동 =====
    // 플레이스토어, 앱스토어 링크
    const androidLink = installLink.KAKAO_ANDROID;
    const iosLink = installLink.KAKAO_IOS;

    // 디바이스 구분
    let _device = '';
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) {
        _device = 'android';
    } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
        _device = 'ios';
    }

    // 디바이스 초기값 세팅
    const [device, setDevice] = useState(_device);

    function redirectStore() {
        if (device === 'android') {
            window.location.assign(androidLink);
        } else if (device === 'ios') {
            window.location.assign(iosLink);
        }
    }

    // 카톡 미 설치 시 Alert
    const kakaoNotInstall = useSelector((x) => x?.kakaoNotInstall?.open);
    const [kakaoAlert, setKakaoAlert] = useState(false);
    const kakaoAlertOpen = () => setKakaoAlert(true);
    const kakaoAlertClose = () => {
        setKakaoAlert(false);
        dispatch(
            kakaoNotInstallActions.openAlert({
                open: false
            })
        );
    };

    // 동반자 초대방식 선택 팝업
    const [InviteOpen, setInviteOpen] = useState(false);
    const handleInviteOpen = () => setInviteOpen(true);

    const onCloseAlert = () => {
        // 동반자 초대 모달창 오픈
        handleInviteOpen();
        kakaoAlertClose();
    };

    const onClickSelfCheckInBtn = (e) => {
        handleOpen();
        appUtils.openWebViewModalPostMsg();
    };

    useEffect(() => {
        // '카카오톡에서 친구 초대' 후 앱 미설치 시 App에서 Error state 전송받아서 redux에 저장 -> state 변경 시 Alert 오픈
        if (kakaoNotInstall?.open) {
            kakaoAlertOpen();
        }
        // console.log('kakaoNotInstall >>>>>>', kakaoNotInstall);
    }, [kakaoNotInstall]);

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="smartRoundBookingList" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <CardList
                            bookingData={bookingData}
                            setMemberReset={setMemberReset}
                            bookingType="round"
                            status={{
                                value: 'smartRound',
                                includeMember: 'true'
                            }}
                            setBookingListReset={false}
                            InviteOpen={InviteOpen}
                            setInviteOpen={setInviteOpen}
                            handleInviteOpen={handleInviteOpen}
                            onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                        />
                    </div>
                </div>
            </div>
            <Navigation />

            {/* 카카오톡 초대완료 후 확인 Alert */}
            <CommonAlert successCallBack={goSetting} />

            {/* 카카오톡 미설치 시 확인 Alert */}
            <Modal open={kakaoAlert} onClose={kakaoAlertClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">서비스 안내</div>
                        <div className="alertContents">
                            카카오톡 앱이 설치되어 있지 않습니다.
                            <br /> 앱을 설치하시겠습니까?
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" className="button cancel" onClick={onCloseAlert}>
                            취소
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                kakaoAlertClose();
                                // 카카오톡 설치 페이지로 이동
                                redirectStore();
                            }}
                        >
                            확인
                        </button>
                    </div>
                </div>
            </Modal>

            {/* 셀프체크인 가이드 Layer */}
            <SelfCheckInGuideLayer handleOpen={selfCheckInGuideLayerOpen} handleClose={handleClose} />
        </>
    );
}

export default Booking;
