/* 정규식 */
export const EMAIL_REG =
    /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
export const PASSWORD_REG = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{8,30}$/;
export const NUMBER_REG = /[0-9]$/;
export const KOREAN_REG = /^[ㄱ-ㅎ|가-힣]+$/;
export const PHONE_REG = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
export const ENG_KOREAN_REG = /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]+$/; /* 한글,영문 */
export const NUMBER_INC_REG = /[0-9]/g; /* 전역, 숫자*/
export const SPECIAL_CHAR_REG = /[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/g; /* 특수문자 */
export const CHK_KOR_INPUT = /[^ㄱ-ㅎㅏ-ㅣ가-힣]/gi; /* 한글만 입력 여부 확인  */
export const CHK_EMAIL_INPUT = /[^a-zA-Z0-9._@-]/gi; /* 이메일 형식으로만 입력 여부 확인  */
export const PARTNER_INVITE_REG =
    /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?0-9\s]{1,20}$/; /* 한글,영문,특수문자,숫자,띄어쓰기 */

/* API 관련 */
export const SUCCESS_CODE = '0000';
export const CHECKIN_TIME_ONE_HOUR_CODE = '8097';
export const ALREADY_CHECKEDIN = '8083';
export const NO_PHONE_USER = '6001';
export const FAIL_CODE = '0003';
export const CNFRMTITMESSAGE = '00';
export const ALERTTITMESSAGE = '01';
export const CANCELTMESSAGE = '02';
export const SETTINGMESSAGE = '03';
export const KAKAOINVITE = '04';
export const SETTINGMESSAGE_CHECKIN = '05';
export const EMAIL_MAXLENGTH = 40; // 이메일 최대 입력가능길이
export const PW_MAXLENGTH = 30; // 패스워드 최대 입력가능길이
export const NAME_MAXLENGTH = 20; // 한글 최대 입력길이

export const ENVIRONMENT = {
    DEVELOPMENT: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

export const YN = {
    Y: 'Y',
    N: 'N'
};
