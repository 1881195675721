import {sendAmplitudeEvent} from '../amplitude.helper';
import {authTaxonomy} from '../taxonomy/auth-event.taxonomy';

/**
 * 로그인 성공 시 이벤트
 * @param {method: string, user_id: string} data
 */
export const loginSuccess = (data) => {
    if (!data) return;
    sendAmplitudeEvent(authTaxonomy.login_success, data);
};

/**
 * 로그인 실패 시 메시지
 * TODO: 메시지 전송
 * @param {*} loginFail
 */
export const loginFail = (loginFail) => {
    sendAmplitudeEvent(authTaxonomy.login_fail, loginFail);
};

/**
 * 로그아웃 성공 시 이벤트 - 로그인이랑 동일
 * @param {method: string, user_id: string} data
 */
export const logoutSuccess = (data) => {
    sendAmplitudeEvent(authTaxonomy.logout_success, data);
};

/**
 * create_account_success - backEnd에서 회원가입 성공 처리 시
 * @param {method: string, user_id: string} data
 * */
export const createAccountSuccess = (data) => {
    sendAmplitudeEvent(authTaxonomy.create_account_success, data);
};
