import React, {useCallback, useEffect, useState} from 'react';
import TitleArea from 'components/common/TitleArea';
import {pageTitle} from 'common/messages';
import './course-guide.scss';
import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE} from 'common/constants';
import CourseGuideContainer from './CourseGuideContainer';
import {useDispatch, useSelector} from 'react-redux';
import DefaultAlert from 'components/alert/DefaultAlert';
import {checkinActions} from '_store/checkin.slice';
import {viewIntroCourseguide} from '_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';

function CourseGuideV2() {
    const golfClubId = useSelector((x) => x?.smartRound.golfClubId);
    const smartRound = useSelector((x) => x?.smartRound.smartRound);
    const dispatch = useDispatch();

    const [smartRoundOpen, _setSmartRoundOpen] = useState((smartRound && smartRound.bookingId) || false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleAlertOpen = (msg) => {
        setAlertMessage(msg);
        setAlertOpen(true);
    };
    const handleAlertClose = () => setAlertOpen(false);

    /**
     * self checkin 제어
     * 1. 당일건이면 한번만 노출
     * 2. 라운드 예정 하루전 항시 노출
     * 3. 그외 미노출
     */
    useEffect(() => {
        dispatch(checkinActions.showSmartRoundCheckIn(smartRoundOpen));
    }, [smartRoundOpen]);

    useEffect(() => {
        fetchWrapper.get(`/amplitude-events/view-intro-courseguide?golfClubId=${golfClubId}`).then((response) => {
            let detailData = null;
            if (response?.code === SUCCESS_CODE) {
                detailData = response?.data;
                viewIntroCourseguide(detailData, getAmplitudeRootPath());
            }
        });

        // 스크롤 없는 풀페이지 스타일 선언을 위한 클래스명 추가
        document.documentElement.classList.add('fullPage');
        return () => {
            document.documentElement.classList.remove('fullPage');
        };
    }, []);

    return (
        <>
            <div id="contents">
                <div id="courseGuide" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={pageTitle.courseGuide} />
                        <CourseGuideContainer handleAlertOpen={handleAlertOpen} />
                    </div>
                </div>
            </div>

            <DefaultAlert
                open={alertOpen}
                handleOpen={handleAlertOpen}
                handleClose={handleAlertClose}
                title={''}
                contents={alertMessage}
            />
        </>
    );
}

export default CourseGuideV2;
