import React, {useState, useEffect, useCallback} from 'react';
import {Dialog} from '@mui/material';
import TitleArea from 'components/common/TitleArea';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {paymentConstant} from 'common/payment/constants';
import PaymentDetailModalPreRound from './PaymentDetailModalPreRound';
import PaymentDetailModalPastRound from './PaymentDetailModalPastRound';
import PaymentDetailModalCancelRound from './PaymentDetailModalCancelRound';

import './payment-detail-modal.scss';
import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE} from 'common/constants';
import {appUtils} from 'common/utils';
import {validationUtil} from 'common/payment';

const Status = (bookingData, paymentTradeData) => ({
    preRound: (
        <PaymentDetailModalPreRound
            paymentType={bookingData?.paymentType}
            bookingData={bookingData}
            paymentTradeData={paymentTradeData}
        />
    ),
    pastRound: (
        <PaymentDetailModalPastRound
            paymentType={bookingData?.paymentType}
            bookingData={bookingData}
            paymentTradeData={paymentTradeData}
        />
    ),
    cancelRound: (
        <PaymentDetailModalCancelRound
            paymentType={bookingData?.paymentType}
            bookingData={bookingData}
            paymentTradeData={paymentTradeData}
        />
    )
});

const Detail = (props) => {
    const {status, bookingData, paymentTradeData} = props;

    return Status(bookingData, paymentTradeData)[status];
};

const PaymentDetailModal = (props) => {
    const {openDetailModal, detailModalControl, bookingData, status} = props;

    const [paymentTradeData, setPaymentTradeData] = useState([]);

    useEffect(() => {
        if (openDetailModal) {
            getDetailData();
        }
    }, [openDetailModal]);

    const getDetailData = useCallback(() => {
        fetchWrapper.get(`/round-booking/${bookingData?.id}/trade-amount`).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setPaymentTradeData(response?.data || null);
            } else {
            }
        });
    }, []);

    const statusData = {
        //예정라운드
        preRound: 'preRound',
        //지난라운드
        pastRound: 'pastRound',
        //취소라운드
        cancelRound: 'cancelRound'
    };

    let roundStatus;

    switch (status) {
        case 'pre':
            roundStatus = statusData.preRound;
            break;
        case 'post':
            roundStatus = statusData.pastRound;
            break;
        case 'cancel':
            roundStatus = statusData.cancelRound;
            break;
        default:
            break;
    }
    // const paymentTradeAmountType = paymentTradeAmountTypeData.RESERVE;

    return (
        <Dialog fullScreen open={openDetailModal} className="full-page-modal">
            <div className="layer-pop">
                <div className="layer-header">
                    <div className="inner">
                        <button type="button" className="btn-close" onClick={() => detailModalControl(false)}>
                            닫기
                        </button>
                    </div>
                </div>
                <div className="inner">
                    <TitleArea pageTitle={paymentInfoProperty.payment_detail_title} paddingBottm={'paddingBottm'} />
                    <div className="payment-info-type">
                        <dl>
                            <dt>{bookingInfoProperty.booking_number}</dt>
                            <dd>{validationUtil.isNullChk(paymentTradeData.gbNo) ? '-' : paymentTradeData?.gbNo}</dd>
                        </dl>
                        <dl>
                            <dt>{bookingInfoProperty.booking_golfclub_name}</dt>
                            <dd>
                                {validationUtil.isNullChk(bookingData?.golfClubShortName)
                                    ? '-'
                                    : bookingData?.golfClubShortName}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{bookingInfoProperty.booking_teetime}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.roundAt)
                                    ? '-'
                                    : appUtils.dateFormatTypeFull(paymentTradeData?.roundAt)}
                            </dd>
                        </dl>
                        <dl className="border-bottom">
                            <dt>{paymentInfoProperty.payment_type}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.paymentType)
                                    ? '-'
                                    : paymentTradeData?.paymentType}
                            </dd>
                        </dl>
                    </div>
                    <Detail status={roundStatus} bookingData={bookingData} paymentTradeData={paymentTradeData} />
                </div>
            </div>
        </Dialog>
    );
};

export default PaymentDetailModal;
