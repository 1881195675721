import React from 'react';
import {useInView} from 'react-intersection-observer';
import {useSelector} from 'react-redux';

function CourseInfo(props) {
    const {} = props;
    const smartRound = useSelector((x) => x?.smartRound.smartRound);

    const {ref, inView} = useInView({
        threshold: 1
    });

    return (
        <>
            <div className={`courseDetail ${inView ? 'afterHide' : ''}`}>
                <div className="courseImgArea">
                    {smartRound?.imgUrl ? (
                        <img src={smartRound?.imgUrl} alt={smartRound?.name} />
                    ) : (
                        <div className="courseNoImg">
                            <p>
                                해당 골프장은 제휴 진행중입니다.
                                <br />
                                빠른 시일내에 제휴될 수 있도록
                                <br />
                                코스가이드를 많이 활용해 보세요!
                            </p>
                        </div>
                    )}
                </div>
                <div className="courseInfo">
                    <h3>
                        {smartRound?.courseName} ({smartRound?.holeNo})
                    </h3>
                    <p>{smartRound?.description}</p>
                    <div className="locationRef" ref={ref}></div>
                </div>
            </div>
        </>
    );
}

export default CourseInfo;
