import React from 'react';
import MyListItem from './MyListItem';

function MyContentsSection({menu, isNewNotice}) {
    return (
        <>
            <div className="menuListWrap">
                <ul className="menuList">
                    {menu?.contents?.contentsList &&
                        menu?.contents.contentsList.map((item, idx) => {
                            return (
                                <MyListItem
                                    item={item}
                                    key={idx}
                                    isNewNotice={item.title === '이벤트' ? true : isNewNotice}
                                />
                            );
                        })}
                </ul>
            </div>
        </>
    );
}

export default MyContentsSection;
