import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {appUtils} from 'common/utils';

const PaymentBookingUserInfo = (props) => {
    const {prepareRoundBooking} = props;

    return (
        <div className="payment-booking-user-info-wrapper">
            <h2>{bookingInfoProperty.booking_person_info}</h2>
            <div className="payment-booking-user-info-container">
                <div className="payment-booking-user-info">
                    <span>이름</span>
                    <span className="fw-regular">{prepareRoundBooking?.bookerName}</span>
                </div>
                <div className="payment-booking-user-info">
                    <span>연락처</span>
                    <span className="fw-regular">
                        {appUtils.formatPhoneNumber(prepareRoundBooking?.bookerPhoneNumber)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PaymentBookingUserInfo;
