import Modal from '@mui/material/Modal';
import {useSelector} from 'react-redux';

const CheckInFailModal = (props) => {
    const {open, onClose} = props;
    const frontDeskCheckInMemberInfoList = useSelector(
        (x) => x.smartRound.checkInMember?.data?.frontDeskCheckInMemberInfoList
    );

    return (
        <>
            <Modal open={open} onClose={() => onClose()}>
                <div id="check-in-modal">
                    <div id="check-in-modal-wrapper" style={{paddingBottom: 0}}>
                        <div id="alertTitle">
                            <p>골프장 프론트에 문의해주세요!</p>
                        </div>
                        <div className="alertContents">
                            <p>
                                해당 티타임의 내장객이 모두
                                <br />
                                체크인 되었습니다.
                            </p>
                        </div>
                        <div className="member-list">
                            {frontDeskCheckInMemberInfoList &&
                                frontDeskCheckInMemberInfoList.length > 0 &&
                                frontDeskCheckInMemberInfoList.map((item) => {
                                    return (
                                        <div className="member-list-item" key={item.memberId}>
                                            <div className="member-info">
                                                <div className="member-name">{item.memberName}</div>
                                                <div className="member-detail">
                                                    <div className="member-check-in">
                                                        {item.isCheckinYn ? '체크인 완료' : '체크인 전'}
                                                    </div>
                                                    <div className="member-locker">락커번호 {item.lockerNo}</div>
                                                </div>
                                                {item.isCheckinYn && (
                                                    <div
                                                        className={`member-checkin-type${
                                                            item.checkinType === '골라가'
                                                                ? ' golaga'
                                                                : item.checkinType === '프론트'
                                                                ? ' front'
                                                                : ''
                                                        }`}
                                                    >
                                                        {item.checkinType}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={onClose}>
                            확인
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CheckInFailModal;
