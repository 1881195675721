import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Icon from '../icons/Icon';
import {clickCs} from '../../_helpers/amplitude/events/click.event';

function MyListItem({item, beforeSurfix, myTee, handleTeeOpen, isNewNotice}) {
    return (
        <li>
            {/* 화살표 아이콘 있는 경우 */}
            {item?.arrowBtn ? (
                <Link
                    to={item?.link ? item?.link : ''}
                    onClick={() => {
                        if (item?.link === '/cs') {
                            clickCs();
                        }
                        item?.teeListModal && handleTeeOpen();
                    }}
                    className={item?.readyAlert ? 'readyAlert' : ''}
                >
                    {/* Title */}
                    <strong>
                        {item?.iconName && <Icon name={item?.iconName} />}
                        {item?.title}
                        {/**
                         * 프로모션이 존재하는 경우 이벤트로 타이틀이 변경되므로 조건 추가
                         * {item?.title === '공지사항' && isNewNotice && <div className="newIcon">N</div>}
                         * */}
                        {['공지사항', '이벤트'].includes(item?.title) && isNewNotice && (
                            <div className="newIcon">N</div>
                        )}
                    </strong>

                    {item?.surfix && (
                        <span className="surfix">
                            {/* before-surfix */}
                            {item.link.includes('booking') && <b className="num">{beforeSurfix}</b>}
                            {/* surfix */}
                            {item?.teeListModal ? myTee?.name : item?.surfix}
                        </span>
                    )}

                    {/* 화살표 */}
                    <Icon name="arrowRight" fill="#A0A4AF" width="6" height="12" />
                </Link>
            ) : (
                // 화살표 아이콘 없는 경우
                <div className="flex">
                    <strong>{item?.title}</strong>
                    {item?.version && <span>{item?.version}</span>}
                </div>
            )}
        </li>
    );
}

export default MyListItem;
