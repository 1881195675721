
/*
 *  map 호출 정의
 *
 * */

export const mapTaxonomy = {
    kakao_map: 'kakao_map',
    t_map: 't_map',
    naver_map: 'naver_map',
    apple_map: 'apple_map'
};
