import React from "react";
import {Link} from "react-router-dom";

function AlarmList({active, nodata}) {
    return (
        <>
            {nodata ? (
                <li className="nodata">받은 알림이 없습니다.</li>
            ) : (
                <li className={active ? "active" : null}>
                    <Link to="#">
                        <b className="category">골프장</b>
                        <p className="title">
                            박동근님, 라운드 전 데스크 방문 없이 3초만에 셀프
                            체크인하세요!
                        </p>
                        <span className="date">1개월 전</span>
                    </Link>
                </li>
            )}
        </>
    );
}

export default AlarmList;
