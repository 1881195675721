import moment from 'moment';

export const dateUtil = {
    /**
     *
     * @param {*} date
     *
     * 취소 가능 기한 1일 미만시 취소 남은 시간 - 현재 시간
     *
     */
    cancelDateRestTime: (date) => {
        const payloadDate = date;

        const payloadDateMoment = moment(payloadDate);

        const nowDate = new Date();

        const now = moment(nowDate).format('YYYY-MM-DD HH:mm:ss');

        let restMinute = payloadDateMoment.diff(now, 'minute');

        if (restMinute < 1440) {
            // 1일 미만인 경우
            let hour = parseInt(restMinute / 60);
            let minute = restMinute % 60;

            return moment({hour, minute}).format('HH:mm');
        } else {
            // 1일 이상인 경우
            let restDay = Math.round(restMinute / 1440);
            return restDay + '일';
        }
    }
};
