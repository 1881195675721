import React, {useState, useEffect} from 'react';
import Drawer from '@mui/material/Drawer';
import mapT from '../../../assets/images/reservation/t_map.svg';
import mapNaver from '../../../assets/images/reservation/naver_map.svg';
import mapKakao from '../../../assets/images/reservation/kakao_map.svg';
import mapApple from '../../../assets/images/reservation/apple_map.svg';
import {installLink} from '../../../common/links';
import {useParams} from 'react-router-dom';
import {clickNavigation} from '../../../_helpers/amplitude/events/click.event';
import {rootTaxonomy} from '../../../_helpers/amplitude/taxonomy/root.taxonomy';
import {mapTaxonomy} from '../../../_helpers/amplitude/taxonomy/map.taxonomy';

function SearchMapDrawer({mapDrawerOpen, setMapDrawerOpen, detailData}) {
    const {id} = useParams();

    useEffect(() => {
        function handleEvent(message) {
            // console.log(message.data);
            let type = JSON.parse(message.data).type;
            // console.log('type', type);

            if (type === 'webModalClose') {
                onDrawerClose();
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);

        return () => document.removeEventListener('message', handleEvent);
    }, [mapDrawerOpen]);

    const onDrawerClose = () => {
        setMapDrawerOpen(false);
    };

    // 디바이스 구분
    let _device = '';
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) {
        _device = 'android';
    } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
        _device = 'ios';
    }

    // 디바이스 초기값 세팅
    const [device, setDevice] = useState(_device);

    // 지도앱 딥링크
    const kakaomap = `kakaomap://look?p=${detailData?.latitude},${detailData?.longitude}`;
    const navermap = `nmap://place?lat=${detailData?.latitude}&lng=${detailData?.longitude}&name=${detailData?.golfClubName}&appname=golaga`;
    const tmap = `https://apis.openapi.sk.com/tmap/app/map?appKey=${process.env.REACT_APP_TMAP_API_KEY}&name=${detailData?.golfClubName}&lon=${detailData?.longitude}&lat=${detailData?.latitude}&url=/field-detail/${id}`;
    const appleMap = `http://maps.apple.com/?q=${detailData?.latitude},${detailData?.longitude}`;

    // url 인코딩
    // const ENCODE_URL = encodeURI(navermap);

    // 딥링크 이동
    function exeDeepLink(deepLink) {
        window.location.assign(deepLink);
    }

    // 기기별 앱 설치페이지 링크 이동
    function redirectStore(androidLink, iosLink) {
        if (device === 'android') {
            window.location.assign(androidLink);
        } else if (device === 'ios') {
            window.location.assign(iosLink);
        }
    }

    // 앱 오픈 확인
    function checkInstallApp(androidLink, iosLink) {
        let check = null;
        let timer = null;

        function clearTimers() {
            clearInterval(check);
            clearTimeout(timer);
        }

        function isHideWeb() {
            // TODO jenna - 앱 닫혀있을 땐 document.hidden = false 떨어짐
            if (document.webkitHidden || document.hidden) {
                clearTimers();
            }
        }

        check = setInterval(isHideWeb, 200);

        timer = setTimeout(function () {
            redirectStore(androidLink, iosLink);
        }, 1000);
    }

    // 카카오맵 오픈
    const openKakaoMap = () => {
        clickNavigation(rootTaxonomy.cc_detail, mapTaxonomy.kakao_map);
        exeDeepLink(kakaomap);
        checkInstallApp(installLink.KAKAOMAP_ANDROID, installLink.KAKAOMAP_IOS);
    };

    // 네이버지도 오픈
    const openNaverMap = () => {
        clickNavigation(rootTaxonomy.cc_detail, mapTaxonomy.naver_map);
        exeDeepLink(navermap);
        checkInstallApp(installLink.NAVERMAP_ANDROID, installLink.NAVERMAP_IOS);
    };

    // 티맵 오픈
    const openTmap = () => {
        clickNavigation(rootTaxonomy.cc_detail, mapTaxonomy.t_map);
        exeDeepLink(tmap);
        checkInstallApp(installLink.TMAP_ANDROID, installLink.TMAP_IOS);
    };

    // 애플지도 오픈
    const openAppleMap = () => {
        clickNavigation(rootTaxonomy.cc_detail, mapTaxonomy.apple_map);
        exeDeepLink(appleMap);
        checkInstallApp(installLink.TMAP_ANDROID, installLink.TMAP_IOS);
    };

    return (
        <>
            <Drawer anchor="bottom" open={mapDrawerOpen} className="openMapDrawer" onClose={onDrawerClose}>
                <div className="drawerContents">
                    <ul>
                        <li onClick={openTmap}>
                            <img src={mapT} alt="티맵" />
                            <span>티맵</span>
                        </li>
                        <li onClick={openNaverMap}>
                            <img src={mapNaver} alt="네이버지도" />
                            <span>네이버지도</span>
                        </li>
                        <li onClick={openKakaoMap}>
                            <img src={mapKakao} alt="카카오맵" />
                            <span>카카오맵</span>
                        </li>

                        {device === 'ios' && (
                            <li onClick={openAppleMap}>
                                <img src={mapApple} alt="애플지도" />
                                <span>애플지도</span>
                            </li>
                        )}
                    </ul>
                </div>
            </Drawer>
        </>
    );
}

export default SearchMapDrawer;
