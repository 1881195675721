import React from 'react';
import Button from '../button/Button';
import Header from '../Header';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {history} from '../../_helpers';
import './membership.scss';
import Icon from '../icons/Icon';

function NoPhoneUser(props) {
    const {title} = props;

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    //하단 버튼
    const nextPage = () => {
        history.navigate('mypage/auth-phone', {replace: true});
    };
    return (
        <>
            <Header back={false} />
            <div className="completeArea" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <div className="completeMsg">
                        <i className="icon">
                            <Icon name="phoneMark" width="60px" height="60px" viewBox="0 0 60 60" fill="none" />
                        </i>
                        <strong className="tt">{title}</strong>
                    </div>

                    <div className="btnFixedBottom">
                        <div className="inner">
                            <Button type="button" onClick={nextPage}>
                                확인
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NoPhoneUser;
