import React, {useState, useEffect, useCallback} from 'react';
import {Dialog} from '@mui/material';

import './payment-booking-cancel-confirm.scss';
import TitleArea from 'components/common/TitleArea';
import {bookingCancel} from 'common/payment/locales/kr/booking-cancel';

import Button from 'components/button/Button';
import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE} from 'common/constants';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import {viewModalCancel} from '_helpers/amplitude/events/view.events';
import {dateUtil, paymentConstant} from 'common/payment';
import PaymentBookingCancelDetailInfo from './PaymentBookingCancelDetailInfo';
import {appUtils} from 'common/utils';
import Badge from 'components/badge/Badge';
import {btn} from 'common/payment/locales/kr/btn';
import PaymentCancelConfirmInfo from './PaymentCancelConfirmInfo';
import {clickCancelPolicy} from '_helpers/amplitude/events/click.event';

const PaymentBookingCancelConfirm = (props) => {
    const {
        handleOpen,
        handleClose,
        bookingData,
        cancelRegLayerOpen,
        bookingCancelReasonOpen,
        passCancelAble,
        policyVisible,
        agreementLayerOpen,
        status
    } = props;

    const [tradeAmountData, setTradeAmountData] = useState([]);

    useEffect(() => {
        if (handleOpen === true) {
            fetchWrapper
                .get(`/amplitude-events/view-modal-cancel?bookingId=${bookingData?.id}`, null)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        viewModalCancel(response.data, getAmplitudeRootPath());
                    }
                });

            if (
                bookingData?.paymentType === paymentConstant.paymentType.PREPAY ||
                bookingData?.paymentType === paymentConstant.paymentType.RESERVE
            ) {
                getPaymentInfo();
            }
        }
    }, [handleOpen]);

    const getPaymentInfo = useCallback(() => {
        fetchWrapper.get(`/round-booking/${bookingData?.id}/trade-amount`).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setTradeAmountData(response.data || []);
            }
        });
    }, [bookingData]);

    // 예약취소 요청 버튼 클릭
    const onClickBookingCancel = () => {
        handleClose();

        if (passCancelAble) {
            // 취소가능기한이 경과한 경우 > 취소사유 선택 풀 팝업(규정 및 유의사항 체크 팝업) 오픈
            cancelRegLayerOpen();
        } else {
            // 필드예약 취소사유 선택 레이어 오픈
            bookingCancelReasonOpen();
        }
    };
    return (
        <Dialog fullScreen open={handleOpen} className="payment-booking-cancel-confirm">
            <div className="layer-wrap">
                <div className="layer-header">
                    <div className="inner">
                        <button type="button" className="btn-close" onClick={handleClose}>
                            닫기
                        </button>
                    </div>
                </div>
                <div className="inner">
                    <TitleArea pageTitle={bookingCancel.cancel_confirm_title} paddingBottm={'paddingBottm'} />
                    <PaymentCancelConfirmInfo bookingData={bookingData} />

                    <PaymentBookingCancelDetailInfo bookingData={bookingData} tradeAmountData={tradeAmountData} />
                    <div className="cancel-possible">
                        <h3>
                            {bookingCancel.cancel_possible}
                            <Badge
                                type={`${
                                    bookingData?.isAfterCancelPeriod
                                        ? 'CANCEL_RECEPTION_MYPAGE'
                                        : 'CANCEL_POSSIBILITY_DATE_MYPAGE'
                                }`}
                                date={dateUtil.cancelDateRestTime(bookingData?.cancelPossiblePeriod)}
                            />
                        </h3>
                        <b
                            className={`date ${bookingData?.isAfterCancelPeriod > 0 ? 'c-red' : 'c-primary'}`}
                        >{`${appUtils.dateFormatTypeFull(bookingData?.cancelPossiblePeriod)}`}</b>
                    </div>
                    {policyVisible && (
                        <div className="cancel-info">
                            <p>
                                {status === 'post'
                                    ? '사전에 취소하지 않고 노쇼 시 페널티가 발생할 수 있습니다.'
                                    : bookingCancel.cancel_possible_contract}
                            </p>
                            <button
                                type="button"
                                onClick={() => {
                                    fetchWrapper
                                        .get(
                                            `/amplitude-events/click-reservation-btn?roundBookingId=${bookingData?.id}`,
                                            null
                                        )
                                        .then((response) => {
                                            if (response.code === SUCCESS_CODE) {
                                                clickCancelPolicy(response.data, getAmplitudeRootPath());
                                            }
                                        });

                                    agreementLayerOpen(bookingData);
                                }}
                            >
                                취소/위약규정
                            </button>
                        </div>
                    )}
                </div>
                <div className="btn-bottom">
                    <div className="inner">
                        <Button type="button" darkGray onClick={handleClose}>
                            {btn.no_btn}
                        </Button>
                        <Button type="button" onClick={onClickBookingCancel}>
                            {btn.booking_cancel_btn}
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default PaymentBookingCancelConfirm;
