import React, {useEffect, useState} from 'react';
import {ReactComponent as CheckTouch} from '../../../assets/images/reservation/check_in_touch.svg';
import {appUtils} from '../../../common/utils';

function CheckInBefore({distance, checkInData, tagNameCheckOpen}) {
    return (
        <>
            {/*{distance && (*/}
            {/*    <p style={{ color: "white" }}>*/}
            {/*        남은 거리 : {Math.round(distance)} m*/}
            {/*    </p>*/}
            {/*)}*/}
            <div className="selfCheckInArea">
                <div className="flexCon" onClick={tagNameCheckOpen}>
                    <div className="roundInfo">
                        <p className="num">예약 번호 : {checkInData?.gbNo}</p>
                        <h2 className="tt">{checkInData?.golfClubName}</h2>
                        <span className="location">{appUtils.dateFormatTypeFull(checkInData?.roundAt)} </span>
                        <span className="location">
                            {checkInData?.addr1} | {checkInData?.course}
                        </span>
                    </div>
                    <div className="checkInImg">
                        <CheckTouch />
                    </div>
                    <p className="infoTxt">
                        프론트 데스크 방문없이 한 번의 터치로 <br />
                        간편하게 체크인하세요!
                    </p>
                </div>
            </div>
        </>
    );
}

export default CheckInBefore;
