import React, {useCallback, useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import moment from 'moment';
import 'moment/locale/ko';
import {appUtils} from '../../../common/utils';
import ScrollCalendar from '../../../common/calendar';
import Icon from '../../icons/Icon';
import '../../../common/calendar/react-scroll-calendar.css';
import classNames from 'classnames';

function CalenderDrawer(props) {
    const {calenderOpen, setCalenderOpen, selectDate, setSelectDate, holidayDate, startDate, endDate} = props;

    //필터 창 열릴때 안드로이드 백버튼 눌렀을때 webview 통신하여 닫기
    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                if (calenderOpen === true) {
                    onDrawerClose();
                }
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);
        return () => {
            document.removeEventListener('message', handleEvent);
        };
    }, [calenderOpen]);

    const onDrawerClose = () => {
        setCalenderOpen(false);
    };

    // 날짜 선택
    const onChangeDate = (e) => {
        setSelectDate(e.toDate());
        onDrawerClose();

        setTimeout(() => {
            const viewport = document.querySelector('.dateSlider');
            const scrollParent = viewport.querySelector('.dr');
            const dayOn = viewport.querySelector('.dayOn');
            const offset = dayOn.getBoundingClientRect().left - scrollParent.getBoundingClientRect().left;
            scrollParent.scrollBy(offset, 0);
        }, [10]);
    };

    return (
        <Drawer anchor="bottom" open={calenderOpen} onClose={onDrawerClose} className="basicDrawer calendarDrawer">
            <div className="drawer-content">
                <div className="drawerTitle">
                    <div className="tt">날짜 선택</div>
                    <button type="button" className="btnClose" onClick={onDrawerClose}>
                        <Icon name="close02" width="24" height="24" viewBox="0 0 24 24" />
                    </button>
                </div>
                <div className="drawerContents">
                    <ScrollCalendar
                        minDate={moment(startDate, 'YYYY-MM-DD')}
                        maxDate={moment(endDate, 'YYYY-MM-DD')}
                        selectedDate={moment(appUtils.dateFormatTypeDash(selectDate), 'YYYY-MM-DD')}
                        onSelect={onChangeDate}
                        holidayDate={holidayDate}
                    />
                </div>
            </div>
        </Drawer>
    );
}

export default CalenderDrawer;
