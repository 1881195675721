import React from "react";
import SkeletonElement from "./SkeletonElement";
import styles from "../food/food.module.scss";

function SkeletonFoodMenuList({ num }) {
    function list() {
        let array = [];
        for (let i = 0; i < num; i++) {
            array.push(
                <div className={styles.listArea} key={i}>
                    <SkeletonElement type="bestTxt" variant="rectangular" />
                    <SkeletonElement type="desTxt" variant="rectangular" />
                    <SkeletonElement type="priceTxt" variant="rectangular" />
                </div>
            );
        }
        return array;
    }

    return list();
}

SkeletonFoodMenuList.defaultProps = {
    num: 3
};
export default SkeletonFoodMenuList;
