import React, {useState, useEffect} from 'react';

function HeaderTooltip({noticeAlert}) {
    // 툴팁 오픈 상태
    const [toolTipOpen, setToolTipOpen] = useState(true);
    // 현재 트랜지션 효과를 보여주고 있는 중이라는 상태 값
    const [animate, setAnimate] = useState(true);
    // 실제 컴포넌트가 사라지는 시점을 지연시키기 위한 값
    const [visible, setVisible] = useState(toolTipOpen);

    const closeToolTip = () => {
        setToolTipOpen(false);
        setTimeout(() => setVisible(false), 500);
    };

    // useEffect(() => {
    //     setTimeout(closeToolTip, 3000);
    // }, [toolTipOpen]);

    if (!animate && !visible) return null;

    return (
        <>
            <div
                className={toolTipOpen ? 'noticeTooltip open' : 'noticeTooltip close'}
                onAnimationEnd={() => setAnimate(false)}
            >
                <div className="txtBox">
                    <span className="txt">{noticeAlert}</span>
                </div>
            </div>
        </>
    );
}

export default HeaderTooltip;
