import React, {useEffect, useState} from 'react';
import {Link} from '@mui/material';
import AppIcon from '../../assets/images/app/logo_app.svg';
import GooglePlayStore from '../../assets/images/app/google_play_store.svg';
import './gatepage.scss';
import {useParams} from 'react-router-dom';
import {installLink} from '../../common/links';
import AppleAppStore from '../../assets/images/app/apple_store.png';

function GateAppInvite() {
    const {inviteChannel, bookingId, bookingMemberId} = useParams();

    // 딥링크
    const deepLink = `golaga://invite/${inviteChannel}/${bookingId}/${bookingMemberId}`;

    // 플레이스토어, 앱스토어 링크
    const androidLink = installLink.GOLAGA_ANDROID;
    const iosLink = installLink.GOLAGA_IOS;

    // 디바이스 구분
    let _device = '';
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) {
        _device = 'android';
    } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
        _device = 'ios';
    }

    // 디바이스 초기값 세팅
    const [device, setDevice] = useState(_device);

    function exeDeepLink() {
        setTimeout(function () {
            window.location.assign(deepLink);
        }, 500);
    }

    function redirectStore() {
        if (device === 'android') {
            window.location.assign(androidLink);
        } else if (device === 'ios') {
            window.location.assign(iosLink);
        }
    }

    function checkInstallApp() {
        function clearTimers() {
            clearInterval(check);
            clearTimeout(timer);
        }

        function isHideWeb() {
            if (document.webkitHidden || document.hidden) {
                clearTimers();
            }
        }

        const check = setInterval(isHideWeb, 200);

        const timer = setTimeout(function () {
            redirectStore();
        }, 1000);
    }

    const redireactApp = () => {
        exeDeepLink();
        checkInstallApp();
    };

    useEffect(() => {
        redireactApp();

        // 스크롤 없는 풀페이지 스타일 선언을 위한 클래스명 추가
        document.documentElement.classList.add('fullPage');
        return () => {
            document.documentElement.classList.remove('fullPage');
        };
    }, []);

    return (
        <>
            <div id="gateApp" className="contentsArea">
                <div className="inner">
                    <div className="AppInfo">
                        <Link href={device === 'android' ? androidLink : iosLink}>
                            <img src={AppIcon} alt="golaga" />
                            <strong className="name">golaga</strong>
                            <p>체크인부터 코스가이드까지 한 번에!</p>
                        </Link>
                    </div>
                    <div className="BtnArea">
                        {device ? (
                            device === 'android' ? (
                                <Link href={androidLink}>
                                    <img src={GooglePlayStore} alt="구글 플레이 스토어" />
                                </Link>
                            ) : (
                                <Link href={iosLink}>
                                    <img src={AppleAppStore} alt="애플 앱 스토어" style={{width: '116px'}} />
                                </Link>
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default GateAppInvite;
