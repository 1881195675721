//예약관련 const 변수 저장 파일
//enum, 변수 등
export const bookingConstant = {
    //예약 취소 상태 분기
    //BOOKING_CANCEL_RECEIPT_COM : 예약 취소 접수 완료
    //BOOKING_CANCEL_COM : 예약 취소 완료
    //BOOKING_CANCEL_FAULT : 예약 취소 실패
    bookingCancelStatusType: {
        BOOKING_CANCEL_RECEIPT_COM: 'BOOKING_CANCEL_RECEIPT_COM',
        BOOKING_CANCEL_COM: 'BOOKING_CANCEL_COM',
        BOOKING_CANCEL_FAULT: 'BOOKING_CANCEL_FAULT'
    },
    /**
     * 예약 완료 : 0000
     * 예약 실패 : round.booking.0118
     */
    roundBookingResultCode: {
        SUCCESS: '0000',
        FAIL: 'round.booking.0118'
    },

    // 예약상세상태코드 F00: 예약완료
    // 예약상세상태코드 F01: 내방완료
    // 예약상세상태코드 F02: No show
    // 예약상세상태코드 F21: 취소
    // 예약상세상태코드 F22: 취소 (관리자)
    // 예약상세상태코드 F23: 취소 접수
    // 예약상세상태코드 F24: 오류로 인한 취소
    bookingStatusDetail: {
        BOOKING_STATUS_DETAIL_F00: 'F00',
        BOOKING_STATUS_DETAIL_F01: 'F01',
        BOOKING_STATUS_DETAIL_F02: 'F02',
        BOOKING_STATUS_DETAIL_F21: 'F21',
        BOOKING_STATUS_DETAIL_F22: 'F22',
        BOOKING_STATUS_DETAIL_F23: 'F23',
        BOOKING_STATUS_DETAIL_F24: 'F24'
    },

    // 기본 필터 코드
    // GC12 : 골프장 권역
    // GC06 : 티타임
    // GC09 : 그린피
    // GC07 : 내장인원
    // GC08 : 홀 수
    // GC24 : 결제 방식
    basicFilterCode: {
        BASIC_FILTER_REGION: 'GC12',
        BASIC_FILTER_TEETIME: 'GC06',
        BASIC_FILTER_GREENFEE: 'GC09',
        BASIC_FILTER_ROUNDPLAYER: 'GC07',
        BASIC_FILTER_HOLE: 'GC08',
        BASIC_FILTER_PAYMENT: 'GC24'
    }
};
