import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import Icon from '../../icons/Icon';
import SearchingList from './searchingList';
import {fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import CircularProgress from '@mui/material/CircularProgress';

function SearchingView({searchWord, searchResultAll, onClickGolfClubDetail}) {
    // 검색 결과 전체 갯수
    const [serchingViewTotal, _setSerchingViewTotal] = useState(0);
    const mySerchingViewTotal = useRef(serchingViewTotal);
    const setSerchingViewTotal = (total) => {
        mySerchingViewTotal.current = total;
        _setSerchingViewTotal(total);
    };

    // 키워드를 포함하는 골프클럽 리스트
    const [keywordList, _setKeyWordList] = useState([]);
    const myKeyWordList = useRef(keywordList);
    const setKeyWordList = (list) => {
        myKeyWordList.current = list;
        _setKeyWordList(list);
    };

    const dispatch = useDispatch();

    // 페이지 갯수
    const [page, setPage] = useState(0);

    // 페이지별 낱개 갯수
    const [size, setSize] = useState(30);

    // 스크롤뷰 ref
    const scrollRef = useRef(null);

    // 다음페이지
    const [hasNext, _setHasNext] = useState(false);
    const myHasNext = useRef(hasNext);
    const setHasNext = (isNext) => {
        myHasNext.current = isNext;
        _setHasNext(isNext);
    };

    const mySearchWord = useRef(searchWord);
    const setSearchWord = (searchWord) => {
        mySearchWord.current = searchWord;
    };

    let scrollDebounce = null;

    const onScroll = useCallback((e) => {
        if (scrollDebounce) {
            clearTimeout(scrollDebounce);
        }

        scrollDebounce = setTimeout(() => {
            const scrollViewPort = e.target;
            const ceil = Math.ceil(scrollViewPort.scrollHeight - scrollViewPort.clientHeight);
            const scrollTopCeil = Math.ceil(scrollViewPort.scrollTop);

            if (ceil <= scrollTopCeil || ceil - 1 <= scrollTopCeil) {
                if (myHasNext.current) {
                    setPage((pre) => pre + 1);
                }
            }
        }, 500);
    }, []);

    useEffect(() => {
        getAutoCompleteSearchList();
    }, [page]);

    useEffect(() => {
        if (searchWord === '') {
            return;
        }

        setSearchWord(searchWord);
        setKeyWordList([]);

        const debounce = setTimeout(() => {
            dispatch(() => {
                // 실시간 검색 api
                fetchWrapper
                    .get(`/search/golf-club?keyword=${searchWord}&page=${0}&size=${size}`, null)
                    .then((response) => {
                        if (response.code === SUCCESS_CODE) {
                            setSerchingViewTotal(response?.data?.totalCount);
                            setHasNext(response.data.hasNext);
                            setKeyWordList(response.data.searchGolfClubs);
                        } else {
                            console.log(response?.error);
                        }
                    });
            });
        }, 200);

        return () => {
            clearTimeout(debounce);
        };
    }, [searchWord]);

    const getAutoCompleteSearchList = () => {
        fetchWrapper
            .get(`/search/golf-club?keyword=${mySearchWord.current}&page=${page}&size=${size}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    //console.log(`${page}번째 페이지`, mySearchWord.current, response.data.searchGolfClubs);
                    setHasNext(response.data.hasNext);

                    if (response.data.searchGolfClubs.length !== 0) {
                        setKeyWordList([...myKeyWordList.current, ...response.data.searchGolfClubs]);
                    }
                } else {
                    console.log(response?.error);
                }
            });
    };

    return (
        <div className="searchingView">
            <div className="inner allSearchArea" onClick={() => searchResultAll(searchWord)}>
                <div className="allSearch">
                    <div className="searchingContent">{searchWord}</div>
                    <span className="allSearchingCheck">검색결과 전체 보기 ({serchingViewTotal}건)</span>
                </div>

                <div className="rightArrow">
                    <Icon name="arrowRight" fill="#A0A4AF" className="rightArrow" width="6" height="12" />
                </div>
            </div>

            <div className="scrollArea02" ref={scrollRef} onScroll={onScroll}>
                <SearchingList
                    searchingList={myKeyWordList.current}
                    searchContent={searchWord}
                    onClickGolfClubDetail={onClickGolfClubDetail}
                />
                {myHasNext.current && (
                    <CircularProgress
                        color="success"
                        size={20}
                        sx={{position: 'relative', left: '50%', marginLeft: '-15px', marginTop: '10px'}}
                        style={{marginBottom: 10}}
                    />
                )}
            </div>
        </div>
    );
}

export default SearchingView;
