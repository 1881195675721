import React from 'react';
import Avatar from '../../common/Avatar';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Icon from '../../icons/Icon';

function MyProfile(props) {
    // 마이페이지내에서 api 호출
    const {memberInfo} = props;
    const member = useSelector((x) => x?.users?.users);

    return (
        <div className="myInfoArea">
            <Link to="/mypage/modify" className="myInfo">
                <Avatar src={member?.profileImageURL || memberInfo.profileImageURL} />
                <div className="infoCon">
                    {/*<strong className="name">{member?.name}</strong>*/}
                    <strong className="name">{member?.maskedName || memberInfo.maskedName}</strong>
                    <span className="modify">내 정보 수정하기</span>
                </div>
            </Link>
            {/* 화살표 */}
            <Icon name="arrowRight" fill="#A0A4AF" width="6" height="12" />
        </div>
    );
}

export default MyProfile;
