import React, {useCallback, useEffect, useState} from 'react';
import Header from '../Header';
import TitleArea from '../common/TitleArea';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';

function LinkedGolfClubV2({title}) {
    const [golfClubPartners, setGolfClubPartners] = useState('');

    const getGolfClubPartners = useCallback(() => {
        fetchWrapper.get(`/golfClub/partners?apiVersion=v2`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setGolfClubPartners(response.data);
            }
        });
    }, []);

    useEffect(() => {
        getGolfClubPartners();
        sessionStorage.removeItem('keepCheck');
    }, []);

    return (
        <>
            <Header close={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner linkedGolfClub">
                        <TitleArea pageTitle={title} paddingBottm={true} />
                        <ul className="listType08">
                            {golfClubPartners?.length
                                ? golfClubPartners.map((v, idx) => {
                                      return (
                                          <>
                                              <li key={v.id}>
                                                  <div> {v.golfClubShortName}</div>
                                                  <div className="address">{v.address}</div>
                                              </li>
                                          </>
                                      );
                                  })
                                : ''}
                        </ul>
                        <div className="noti">
                            <div>
                                <p>*&nbsp;</p>
                                <p>제휴 골프장은 계속해서 추가될 예정입니다.</p>
                            </div>
                            <div>
                                <p>*&nbsp;</p>
                                <p>각 골프장 정책에 따라 연동되는 예약 정보는 다를 수 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LinkedGolfClubV2;
