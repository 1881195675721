import React from "react";
import GolfZoneSearch from "./InDoorSearch/GolfZoneSearch";

function Search({ title }) {
    return (
        <>
            <GolfZoneSearch />
        </>
    );
}

export default Search;
