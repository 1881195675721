import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE} from '../common/constants';

const name = 'paymentBooking';

const initialState = {
    paymentType: '',
    paymentTypeName: '',
    paymentDetailType: '',
    paymentDetailTypeName: '',
    prepaymentType: '',
    prepaymentTypeName: '',
    transactionMethod: '',
    totalAmount: null,
    totalGreenFee: null,
    greenFeePerPerson: null,
    expectOnsiteAmount: null,
    cartFee: null,
    caddieFee: null,
    caddieFeeInfo: '',
    reserveAmount: null,
    onsiteGreenFee: null,
    totalExpectAmount: null,
    isCartFeeIncluded: false,
    rendering: true,
    /* 내장 인원  */
    roundPlayer: null
};

// Reducer
const reducers = {
    setInnerPeople: {
        reducer: (state, action) => {
            const {roundPlayer} = action.payload;
            state.roundPlayer = roundPlayer;
        }
    }
};

// ExtraAction
const extraActions = {
    getPrepareRoundBookingTradeAmount: createAsyncThunk(
        `payment/getPrepareRoundBookingTradeAmount`,
        async (roundPlayer) => {
            const response = await fetchWrapper.get(`/prepare/round-booking/trade-amount?roundPlayer=${roundPlayer}`);
            if (response.code === SUCCESS_CODE) {
                return response.data;
            }
            throw new Error('예약하려는 티타임의 가격 상세 정보 조회 중 에러');
        }
    )
};

// ExtraReducer - fetch에 대한 callback 함수 정의
const extraReducers = {
    getPrepareRoundBookingTradeAmount: () => {
        let {pending, fulfilled, rejected} = extraActions.getPrepareRoundBookingTradeAmount;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                const payloadData = action.payload;
                for (const key in payloadData) {
                    if (payloadData.hasOwnProperty(key)) {
                        state[key] = payloadData[key];
                    }
                }
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                console.error(action.error);
                state.rendering = false;
            }
        };
    }
};

// ExtraReducer 등록
const createExtraReducers = () => {
    return {
        ...extraReducers.getPrepareRoundBookingTradeAmount()
    };
};

// create slice
const paymentBookingSlice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: createExtraReducers()
});

// exports
export const paymentBookingActions = {...paymentBookingSlice.actions, ...extraActions};
export const paymentBookingReducer = paymentBookingSlice.reducer;
