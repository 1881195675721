import React from 'react';
import Icon from '../icons/Icon';

function ShareButton({handleShareOpen}) {
    return (
        <>
            <button type="button" id="shareBtn" onClick={handleShareOpen}>
                <Icon name="share-detail" width="18" height="22" viewBox="0 0 18 22" />
            </button>
        </>
    );
}

export default ShareButton;
