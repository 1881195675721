import React from 'react';
import Avatar from '../common/Avatar';

function BookingAvatar({data, editable, handleAlertOpen, setDeleteMemberId, showIcon, isBookingCancel}) {
    return (
        <li>
            <Avatar
                handleAlertOpen={handleAlertOpen}
                setDeleteMemberId={setDeleteMemberId}
                data={data}
                editable={editable}
                showIcon={showIcon}
                isBookingCancel={isBookingCancel}
            />
        </li>
    );
}

export default BookingAvatar;
