import React, {useState} from 'react';
import useCollapse from 'react-collapsed';
import Icon from '../../icons/Icon';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {paymentConstant, validationUtil} from 'common/payment';
import {appUtils} from 'common/utils';

function Collapse(props) {
    const collapseProps = useCollapse(props);
    return props.children(collapseProps);
}

const PaymentBookingCardPost = (props) => {
    const {paymentType, bookingData, detailModalControl} = props;

    const [isExpanded, setIsExpanded] = useState(false);

    switch (paymentType) {
        case paymentConstant.paymentType.ONSITE:
            return (
                <Collapse isExpanded={isExpanded} setHasDisabledAnimation={true}>
                    {({isExpanded, getToggleProps, getCollapseProps}) => (
                        <div className={`mypage-booking-card-info`}>
                            <dl
                                className="total-payment black cursor-pointer"
                                {...getToggleProps({
                                    onClick: () => {
                                        setIsExpanded((prevExpanded) => !prevExpanded);
                                    }
                                })}
                            >
                                <dt className="payment-weight-700">{paymentInfoProperty.onsite_payment_predict}</dt>
                                <dd className="c-primary payment-weight-800">
                                    {validationUtil.isNullChk(bookingData?.expectOnsiteAmount)
                                        ? '-'
                                        : appUtils.amountFormat(bookingData?.expectOnsiteAmount)}
                                    <button
                                        type="button"
                                        className={`btnDetail ${isExpanded ? 'collapse' : 'expand'}`}
                                    ></button>
                                </dd>
                            </dl>
                            <div className="payment-collapse-content" {...getCollapseProps()}>
                                {/* 셀프 체크인 프로모션 적용 -지난 라운드 추가 */}
                                {bookingData?.promotionApplyYn === 'Y' && (
                                    <div className="self-checkIn-promtion-wrap">
                                        <div className="self-checkIn-promotion-div">
                                            <h2>{paymentInfoProperty.self_checkIn_promotion}</h2>
                                            <span className="discountTxt">
                                                {paymentInfoProperty.self_checkIn_promotion_discount}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <div className="sub-title-area">
                                    <h2>{paymentInfoProperty.payment_infomation}</h2>
                                </div>
                                <dl>
                                    <dt>
                                        {`${paymentInfoProperty.totalGreenFee} (${
                                            validationUtil.isNullChk(bookingData?.playerLimit)
                                                ? '-'
                                                : bookingData?.playerLimit
                                        }인)`}
                                    </dt>
                                    <dd>{`${
                                        validationUtil.isNullChk(bookingData?.totalGreenFee)
                                            ? '-'
                                            : bookingData?.totalGreenFee === 0
                                            ? paymentInfoProperty.greenFee_golf_contact
                                            : appUtils.amountFormat(bookingData?.totalGreenFee)
                                    }`}</dd>
                                </dl>

                                <dl>
                                    <dt>{paymentInfoProperty.cartFee}</dt>
                                    <dd>{`${
                                        bookingData?.isCartFeeIncluded === true
                                            ? '그린피에 포함'
                                            : appUtils.amountFormat(bookingData?.cartFee)
                                    }`}</dd>
                                </dl>

                                {/* 지난 라운드는 캐디 타입이 SELF(노캐디) 일 경우 캐디피 비노출  */}
                                {['SELF', '노캐디'].includes(bookingData?.caddieType) ? (
                                    <></>
                                ) : (
                                    <dl className="border-bottom">
                                        <dt>{paymentInfoProperty.caddieFee}</dt>
                                        <dd>
                                            {validationUtil.isNullChk(bookingData?.caddieFee)
                                                ? '-'
                                                : appUtils.amountFormat(bookingData?.caddieFee)}
                                        </dd>
                                    </dl>
                                )}
                                <dl className="payment-info">
                                    <dt>{paymentInfoProperty.payment_type}</dt>
                                    <dd>
                                        {validationUtil.isNullChk(bookingData?.paymentTypeName)
                                            ? '-'
                                            : bookingData?.paymentTypeName}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    )}
                </Collapse>
            );
            break;

        case paymentConstant.paymentType.PREPAY:
        case paymentConstant.paymentType.RESERVE:
            return (
                <Collapse isExpanded={isExpanded} setHasDisabledAnimation={true}>
                    {({isExpanded, getToggleProps, getCollapseProps}) => (
                        <div className={`mypage-booking-card-info `}>
                            <dl
                                className="total-payment black cursor-pointer"
                                {...getToggleProps({
                                    onClick: () => {
                                        setIsExpanded((prevExpanded) => !prevExpanded);
                                    }
                                })}
                            >
                                <dt className="payment-weight-700">
                                    {paymentType === paymentConstant.paymentType.PREPAY
                                        ? paymentInfoProperty.payment_all_amount
                                        : paymentInfoProperty.payment_deposit}
                                </dt>
                                <dd className="total-amount payment-weight-800">
                                    {`${appUtils.amountFormat(bookingData?.totalAmount)}`}
                                    <button
                                        type="button"
                                        className={`btnDetail ${isExpanded ? 'collapse' : 'expand'}`}
                                    ></button>
                                </dd>
                            </dl>
                            <div className="payment-collapse-content" {...getCollapseProps()}>
                                <div className="sub-title-area">
                                    <h2>{paymentInfoProperty.payment_infomation}</h2>
                                    {bookingData?.isHost && (
                                        <button
                                            type="button"
                                            className="cursor-pointer"
                                            onClick={() => detailModalControl(true)}
                                        >
                                            <i className="teeTimeBoxIcon-advance-payment">
                                                <Icon
                                                    name="payment-detail-icon"
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                ></Icon>
                                                <span>{paymentInfoProperty.payment_detail}</span>
                                            </i>
                                        </button>
                                    )}
                                </div>
                                <dl
                                    className={`${
                                        bookingData?.prepaymentType !== paymentConstant.prepaymentType.PAYMENT_TYPE_20
                                            ? 'border-bottom'
                                            : ''
                                    }`}
                                >
                                    <dt>
                                        {paymentInfoProperty.totalGreenFee + '(' + bookingData?.playerLimit + '인)'}
                                    </dt>
                                    <dd>{`${
                                        validationUtil.isNullChk(bookingData?.totalGreenFee)
                                            ? '-'
                                            : bookingData?.totalGreenFee === 0
                                            ? paymentInfoProperty.greenFee_golf_contact
                                            : appUtils.amountFormat(bookingData?.totalGreenFee)
                                    }`}</dd>
                                </dl>

                                {bookingData?.prepaymentType === paymentConstant.prepaymentType.PAYMENT_TYPE_20 && (
                                    <dl className="border-bottom">
                                        <dt>{paymentInfoProperty.cartFee}</dt>
                                        <dd>{`${
                                            bookingData?.isCartFeeIncluded === true
                                                ? '그린피에 포함'
                                                : validationUtil.isNullChk(bookingData?.cartFee)
                                                ? '-'
                                                : appUtils.amountFormat(bookingData?.cartFee)
                                        }`}</dd>
                                    </dl>
                                )}
                                <dl className="payment-info">
                                    <dt>{paymentInfoProperty.payment_type}</dt>
                                    <dd>
                                        {validationUtil.isNullChk(bookingData?.paymentTypeName)
                                            ? '-'
                                            : bookingData?.paymentTypeName}
                                    </dd>
                                </dl>
                                <dl className="payment-info">
                                    <dt>{paymentInfoProperty.payment_method}</dt>
                                    <dd>
                                        {validationUtil.isNullChk(bookingData?.transactionMethod)
                                            ? '-'
                                            : bookingData?.transactionMethod}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    )}
                </Collapse>
            );
            break;
    }
};

export default PaymentBookingCardPost;
