import React from "react";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { FiX } from "react-icons/fi";

function BasicAlert(props) {
    // open State, 닫기 버튼 부모로부터 받아옴
    const { open, handleClose } = props;

    return (
        <>
            <Modal
                open={open}
                className="basicAlert"
                disableScrollLock
                hideBackdrop
            >
                <Slide
                    direction="down"
                    in={open}
                    timeout={{
                        enter: 400,
                        exit: 300
                    }}
                >
                    <div className="alertBox">
                        <button
                            type="button"
                            className="btnClose"
                            onClick={handleClose}
                        >
                            닫기 <FiX size={"1.3125rem"} color={"999"} />
                        </button>
                        <strong className="tit">{props.title}</strong>
                        <p>{props.contents}</p>
                    </div>
                </Slide>
            </Modal>
        </>
    );
}

export default BasicAlert;
