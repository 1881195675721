import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import {clickYouAreHere} from '_helpers/amplitude/events/click.event';
import {viewCourseguide} from '_helpers/amplitude/events/view.events';
import {smartRoundActions} from '_store/smartRound.slice';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchWrapper, history} from '../_helpers';
import {rootTaxonomy} from '../_helpers/amplitude/taxonomy/root.taxonomy';
import {alertActions} from '../_store/alert.slice';
import {globalActions} from '../_store/global.slice';
import {inviteActions} from '../_store/invite.slice';
import {kakaoNotInstallActions} from '../_store/kakaoNotInstall.slice';
import {SUCCESS_CODE} from '../common/constants';
import {inviteUtils} from '../common/inviteUtils';
import {localStorageUtil} from '../common/utils';
import {checkGPSOnOFfAndsetLocation} from '../components/reservation/drawer/LocationDrawer';

const RNListener = () => {
    const auth = useSelector((x) => x?.auth);
    const member = useSelector((x) => x?.users?.users);
    const dispatch = useDispatch();

    /** react native 환경에서만 가능 */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const listener = (event) => {
        if (typeof event?.data === 'object') return;
        try {
            const {data, type} = JSON.parse(event?.data);
            const pickType = JSON.parse(event?.data).pickType;

            if (type === 'TOKEN') {
                // type이 TOKEN이기 때문에 이곳에 콘솔이 찍히게 됩니다.
            } else if (type === 'NOTIFICATION') {
                // 앱에서 알림 처리
            } else if (type === 'LOCATION') {
                localStorageUtil.set('LOCATION', event?.data);
            } else if (type === 'GPSONOFF') {
                localStorageUtil.set('GPSONOFF', JSON.parse(event?.data).value);
                const callLocation = JSON.parse(event?.data).callLocation;
                if (callLocation === 'locationDrawer') {
                    checkGPSOnOFfAndsetLocation();
                }
            } else if (type === 'HOME') {
                history.navigate(`/main`);
            } else if (type === 'INVITE') {
                const {inviteChannel, bookingId, bookingMemberId} = JSON.parse(event?.data);

                if (auth.user) {
                    inviteUtils.initPage(inviteChannel, bookingId, bookingMemberId);
                } else {
                    // 회원가입 완료 후 페이지 이동 시 localStorage 저장 > Home에서 분기처리
                    localStorageUtil.set(
                        'joinInviteAction',
                        JSON.stringify({
                            inviteProp: {
                                inviteChannel: inviteChannel,
                                bookingId: bookingId,
                                bookingMemberId: bookingMemberId
                            }
                        })
                    );

                    // history.navigate(`/member/login/invite/${inviteChannel}/${bookingId}/${bookingMemberId}`);
                }
            } else if (type === 'SHARE') {
                sessionStorage.setItem('root', rootTaxonomy.sharing);
                const golfClubId = JSON.parse(event?.data).golfClubId;
                const shareStatus = JSON.parse(event?.data).shareStatus;

                // 로그인 시 골프클럽 티타임/상세페이지로, 비로그인 시 로그인 페이지로 이동
                if (auth.user) {
                    history.navigate(`/field-detail/${golfClubId}`);
                } else {
                    // 회원가입 완료 후 페이지 이동 시 localStorage 저장 > Home에서 분기처리
                    localStorageUtil.set(
                        'joinShareAction',
                        JSON.stringify({
                            shareProp: {
                                golfClubId: golfClubId,
                                shareStatus: shareStatus
                            }
                        })
                    );

                    // history.navigate(`/member/login/share/${golfClubId}/${shareStatus}`);
                }
            } else if (type === 'SELFCHECKIN_PROMOTION_GOLAGA_BOARD_EVENT') {
                // 카카오 친구톡 > 셀프체크인 프로모션 공지사항 이벤트로 이동
                localStorageUtil.set('joinSelfChInPromotionEvent', true);
                if (auth.user) {
                    history.navigate('/main');
                }
            } else if (type === 'ALARM') {
                const status = JSON.parse(event?.data).status;
                const golfClubId = JSON.parse(event?.data).golfClubId;
                const bookingId = JSON.parse(event?.data).bookingId;

                localStorageUtil.set(
                    'joinAlarmAction',
                    JSON.stringify({
                        alarmProp: {
                            status,
                            golfClubId,
                            bookingId
                        }
                    })
                );

                if (auth.user) {
                    // 취소/위약규정은 queryParam 전송
                    let checkState = '';
                    if (status === 'cancellation') {
                        checkState = 'CANCEL_RECEPTION';
                        if (history.location.pathname === `/field-detail/${golfClubId}`) {
                            history.navigate(0, {state: {flag: 'drawerOpen'}});
                        } else {
                            history.navigate(`/field-detail/${golfClubId}`, {state: {flag: 'drawerOpen'}});
                        }
                    } else if (status === 'roundCancel') {
                        // 취소내역 버튼은 바로 해당페이지 이동
                        localStorageUtil.remove('joinAlarmAction');
                        if (history.location.pathname === '/mypage/booking') {
                            dispatch(inviteActions.setRefresh(true));
                        } else {
                            history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=cancel`);
                        }
                    } else {
                        fetchWrapper
                            .get(`/booking/check/${bookingId}?checkType=${checkState}`, null)
                            .then((response) => {
                                if (response?.code === SUCCESS_CODE) {
                                    localStorageUtil.remove('joinAlarmAction');
                                    if (status === 'bookingList') {
                                        history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=pre`);
                                    } else if (status === 'teaTime') {
                                        if (['/main', '/'].includes(history.location.pathname)) {
                                            dispatch(inviteActions.setRefresh(true));
                                        } else {
                                            history.navigate(`/main`);
                                        }
                                    }
                                } else {
                                    // 메인 이동 후 Alert 오픈
                                    localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                                    if (['/main', '/'].includes(history.location.pathname)) {
                                        dispatch(inviteActions.setRefresh(true));
                                    } else {
                                        history.navigate(`/main`);
                                    }
                                }
                            });
                    }
                }
            } else if (type === 'SMARTROUND') {
                const status = JSON.parse(event?.data).status;
                let golfClubId = JSON.parse(event?.data).golfClubId;
                let bookingId = JSON.parse(event?.data).bookingId;
                let clubName = JSON.parse(event?.data).golfClubName;

                localStorageUtil.set(
                    'joinSMAction',
                    JSON.stringify({
                        smProp: {
                            status,
                            golfClubId,
                            bookingId,
                            clubName
                        }
                    })
                );

                if (auth.user) {
                    fetchWrapper.get(`/booking/check/${bookingId}`, null).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            localStorageUtil.remove('joinSMAction');
                            history.navigate(`/self-check-in-v2?bookingId=${bookingId}&golfClubId=${golfClubId}`);
                        } else {
                            // 메인 이동 후 Alert 오픈
                            localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                            if (['/main', '/'].includes(history.location.pathname)) {
                                dispatch(inviteActions.setRefresh(true));
                            } else {
                                history.navigate(`/main`);
                            }
                        }
                    });
                }
            } else if (type === 'COURSEINFO') {
                const status = JSON.parse(event?.data).status;
                const golfClubId = JSON.parse(event?.data).golfClubId;
                const courseCode = JSON.parse(event?.data).courseCode;
                const mapControlYN = JSON.parse(event?.data).mapControlYN;
                const bookingId = JSON.parse(event?.data).bookingId;
                const handicapDisplayYN = JSON.parse(event?.data).handicapDisplayYN;

                localStorageUtil.set(
                    'joinCourseAction',
                    JSON.stringify({
                        courseProp: {
                            status,
                            golfClubId,
                            courseCode,
                            mapControlYN,
                            bookingId
                        }
                    })
                );

                if (auth.user) {
                    fetchWrapper.get(`/booking/check/${bookingId}`, null).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            localStorageUtil.remove('joinCourseAction');
                            window.ReactNativeWebView?.postMessage(
                                JSON.stringify({
                                    type: 'goNavi',
                                    golfClubId,
                                    courseCode,
                                    teeingGround: member?.teeingGround,
                                    mapControlYN,
                                    handicapDisplayYN
                                })
                            );
                        } else {
                            // 메인 이동 후 Alert 오픈
                            localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                            if (['/main', '/'].includes(history.location.pathname)) {
                                dispatch(inviteActions.setRefresh(true));
                            } else {
                                history.navigate(`/main`);
                            }
                        }
                    });
                }
            } else if (type === 'CLUBBOARD') {
                const status = JSON.parse(event?.data).status;
                const golfClubId = JSON.parse(event?.data).golfClubId;
                const bookingId = JSON.parse(event?.data).bookingId;
                const golfClubName = JSON.parse(event?.data).golfClubName;

                localStorageUtil.set(
                    'roundData',
                    JSON.stringify({
                        golfClubId,
                        bookingId,
                        golfClubName
                    })
                );

                localStorageUtil.set(
                    'joinClubBoardAction',
                    JSON.stringify({
                        courseProp: {
                            status,
                            golfClubId,
                            bookingId,
                            golfClubName
                        }
                    })
                );

                if (auth.user) {
                    fetchWrapper.get(`/booking/check/${bookingId}`, null).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            localStorageUtil.remove('joinClubBoardAction');
                            dispatch(smartRoundActions.setNoticeFrom({noticeFrom: '/'}));
                            history.navigate(`/club-board/${golfClubId}`);
                        } else {
                            // 메인 이동 후 Alert 오픈
                            localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                            if (['/main', '/'].includes(history.location.pathname)) {
                                dispatch(inviteActions.setRefresh(true));
                            } else {
                                history.navigate(`/main`);
                            }
                        }
                    });
                }
            } else if (type === 'SELFCHINGUIDE') {
                const {status, golfClubId, bookingId, golfClubName} = JSON.parse(event?.data);

                localStorageUtil.set(
                    'joinSelfChInGuideAction',
                    JSON.stringify({
                        selfChInGuideProp: {
                            status,
                            golfClubId,
                            bookingId,
                            golfClubName
                        }
                    })
                );

                if (auth.user) {
                    fetchWrapper.get(`/booking/check/${bookingId}`, null).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            // localStorageUtil.remove('joinSelfChInGuideAction');
                            history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=pre`);
                        } else {
                            // 메인 이동 후 Alert 오픈 => 메인에 있는 상태에서 다시 버튼 클릭해서 진입 시 refresh
                            localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                            if (['/main', '/'].includes(history.location.pathname)) {
                                dispatch(inviteActions.setRefresh(true));
                            } else {
                                history.navigate(`/main`);
                            }
                        }
                    });
                }
            } else if (type === 'CAMERA') {
                const value = JSON.parse(event?.data).value;
                localStorageUtil.set('CAMERA', value);
            } else if (type === 'PHOTO') {
                const value = JSON.parse(event?.data).value;
                localStorageUtil.set('PHOTO', value);
            } else if (type === 'EXTERNAL_STORAGE') {
                const value = JSON.parse(event?.data).value;
                localStorageUtil.set('EXTERNAL_STORAGE', value);
            } else if (type === 'CS') {
                if (auth.user) {
                    history.navigate(`/cs`);
                } else {
                    // 회원가입 완료 후 페이지 이동 시 localStorage 저장 > Home에서 분기처리
                    localStorageUtil.set(
                        'joinCSAction',
                        JSON.stringify({
                            csProp: {
                                status: 'cs'
                            }
                        })
                    );
                    // history.navigate(`/member/login/cs/0`);
                }
            } else if (type === 'KAKAOINVITE') {
                const bookingId = JSON.parse(event?.data).bookingId;
                const bookingMemberId = JSON.parse(event?.data).bookingMemberId;

                fetchWrapper
                    .get(`/booking/invite/check?bookingId=${bookingId}&bookingMemberId=${bookingMemberId}`, null)
                    .then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            dispatch(smartRoundActions.getBookingMemberList(bookingId));

                            dispatch(
                                alertActions.showAlert({
                                    type: 'KAKAOINVITE',
                                    id: 'partnerInvite',
                                    messageData: {
                                        // title: '앱 권한 설정 이동',
                                        message: '카카오톡에서 1명을 초대하였습니다'
                                    }
                                })
                            );
                        } else {
                            console.log('실패 - 액션 없음 ');
                        }
                    });
            } else if (pickType === 'KEYIN') {
                const friend = JSON.parse(event?.data).friends[0];

                dispatch(
                    inviteActions.setFriend({
                        type: 'KEYIN',
                        friend: {
                            name: friend?.name,
                            phone: friend?.number
                        }
                    })
                );
            } else if (type === 'kakaoNotInstall') {
                dispatch(
                    kakaoNotInstallActions.openAlert({
                        open: true
                    })
                );
            } else if (type === 'appVersion') {
                const myAppVersion = JSON.parse(event?.data).appVersion;
                dispatch(
                    globalActions.setAppVersion({
                        appVersion: myAppVersion
                    })
                );
            } else if (type === 'deviceInfo') {
                const deviceInfo = JSON.parse(event?.data).Platform.OS;
                dispatch(
                    globalActions.setDeviceInfo({
                        deviceInfo: deviceInfo
                    })
                );
            } else if (type === 'SELFCHECKINPROMOTION') {
                localStorageUtil.set('joinSelfChInPromotionAction', true);
            } else if (type === 'viewCourseguide') {
                viewCourseguide(data, getAmplitudeRootPath());
            } else if (type === 'clickYouAreHere') {
                clickYouAreHere(data, getAmplitudeRootPath());
            } else if (type === 'SELFCHECKINPROMOTION') {
                localStorageUtil.set('joinSelfChInPromotionAction', true);
            }
        } catch (e) {
            console.debug(e);
        }
    };

    useEffect(() => {
        const _listener = listener;

        if (window.ReactNativeWebView) {
            /** android */
            document.addEventListener('message', _listener);
            /** ios */
            window.addEventListener('message', _listener);
        } else {
            //TODO @Shon 다운로드 페이지로 이동
        }

        return () => {
            document.removeEventListener('message', _listener);
            window.removeEventListener('message', _listener);
        };
    }, [listener]);
};

export default RNListener;
