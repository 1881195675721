import React from 'react';
import Button from '../../../components/button/Button';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import {history} from '../../../_helpers';
import Icon from '../../icons/Icon';

function SuccessConnection(props) {
    const {title, titleTxt} = props;

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    // 라운드 예약 페이지로 이동
    const moveToMyReserv = () => {
        history.navigate(`/mypage/booking`);
    };

    // 홈으로 돌아가기
    const moveToHome = () => {
        history.navigate('/');
    };

    return (
        <div id="contents">
            <div className="completeArea" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <div className="completeMsg">
                        <i className="icon">
                            <Icon name="check" width="60px" height="60px" viewBox="0 0 60 60" fill="none" />
                        </i>
                        <strong className="tt">{title}</strong>
                        <p className="txt">{titleTxt}</p>
                    </div>

                    <div className="btnFixedBottom">
                        <div className="inner">
                            <Button type="button" darkGray onClick={moveToHome}>
                                홈으로 돌아가기
                            </Button>
                            <Button type="button" onClick={moveToMyReserv}>
                                예약내역 보기
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuccessConnection;
