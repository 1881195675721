import React from 'react';

/*
 * TODO : contents 내용을 props 값으로 변경, 스타일 최종 변경 필요
 * */
function GolfClubDetailAgree({contents}) {
    return (
        <>
            {contents ? (
                <>
                    <section dangerouslySetInnerHTML={{__html: contents}}></section>
                </>
            ) : (
                <>
                    <section>
                        <h2>취소가능기한</h2>
                        <p>
                            - 주중 : 4일전 17:00 까지
                            <br />
                            - 주말 및 공휴일 : 4일전 17:00 까지
                            <br />- 임박 취소 시 위약은 ‘취소 가능 기한’ 에 명시된 기준으로 변동되며, 기준 이후 취소할
                            경우에는 다음 일자 취소로 인정 됩니다.
                        </p>
                    </section>
                    <section>
                        <h2>취소횟수제한</h2>
                        <p>
                            - 취소 횟수 월 5회 제공 / 동일 골프장 월 3회(골프장별 상이함) ← 골라가 MVP 오픈시에는 횟수
                            제한 없음
                            <br />- 횟수 초과 시 고객센터 문의 후 취소 가능(단, 월 취소 횟수 초과 시 1~3개월 간 서비스
                            이용이 제한될 수 있습니다.)
                        </p>
                    </section>
                    <section>
                        <h2>위약규정</h2>
                        <p>
                            취소가능기한 이후 취소 또는 미내장 시 위약금이 발생하며 위약금 배상 후 취소 가 가능합니다.
                        </p>
                        <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                            <thead>
                                <tr>
                                    <th>위약규정</th>
                                    <th>패널티</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        1일전 17시 이후
                                        <br />& 당일취소/미내장(노쇼)
                                    </td>
                                    <td>
                                        - 주중 : 팀별 이용요금 30%
                                        <br />- 주말 : 팀별 이용요금 30%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2일전 17시 이후
                                        <br />~ 1일전 17시 까지
                                    </td>
                                    <td>
                                        - 주중 : 팀별 이용요금 20%
                                        <br />- 주말 : 팀별 이용요금 20%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3일전 17시 이후
                                        <br />~ 2일전 17시 까지
                                    </td>
                                    <td>
                                        - 주중 : 팀별 이용요금 10%
                                        <br />- 주말 : 팀별 이용요금 10%
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4일전 17시 이후
                                        <br />~ 3일전 17시 까지
                                    </td>
                                    <td>
                                        - 주중 : 3일전 17시까지 취소 가능
                                        <br />- 주말 : 팀별 이용요금 10%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            *위약 발생 시 예약불가 등 서비스 이용에 제약을 받을 수 있으며, 민사상 법적인 책임일 질 수
                            있습니다.
                            <br />
                            *위약 발생 후 골프장 현장 방문 시 불이익을 받을 수 있습니다.(골프장별 상이)
                        </p>
                    </section>
                </>
            )}
        </>
    );
}

export default GolfClubDetailAgree;
