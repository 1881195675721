import React from "react";
import { Link } from "@mui/material";
// import crawler from 'npm-license-crawler';
import * as data from "./licenses.json";

export default function OpenLicenseCard() {
    const licenses = JSON.parse(JSON.stringify(data));

    return (
        <>
            {Object.keys(data)?.map((key, i) =>
                key !== "default" ? (
                    <section key={key}>
                        <h2>{key}</h2>
                        <p>
                            license: {data[key].licenses}
                            <br />
                            {/*repository: {licenses[e].repository}<br/>*/}
                            license URL:{" "}
                            <Link href={data[key].licenseUrl}>here</Link>
                        </p>
                    </section>
                ) : null
            )}
        </>
    );
}
