import React, {useCallback, useEffect, useState} from 'react';
import {appUtils} from '../../../common/utils';
import Icon from '../../icons/Icon';
import {QRCodeCanvas} from 'qrcode.react';
import {fetchWrapper} from '../../../_helpers';
import {ALREADY_CHECKEDIN, CHECKIN_TIME_ONE_HOUR_CODE, SUCCESS_CODE} from '../../../common/constants';
import {viewQrSelfCheckin} from '../../../_helpers/amplitude/events/view.events';
import DefaultAlert from '../../alert/DefaultAlert';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import {failSelfCheckinGps} from '_helpers/amplitude/events/etc.event';

function QrCheckIn(props) {
    const {checkInData, dDay, roundData, isActive, time, clickResetTimer, qrCheckIn, qrImg, setIsActive, setQrCheckIn} =
        props;

    const bookingId = roundData?.bookingId;

    // qr 체크인 불가 Alert
    const [selfCheckIn, setSelfCheckIn] = useState(false);
    const [alertContents, setAlertContents] = useState('');
    const handleSelfCheckInOpen = () => setSelfCheckIn(true);
    const handleSelfCheckInClose = () => {
        setQrCheckIn(false);
        setSelfCheckIn(false);
    };

    let number = String(time).padStart(2, 0);

    useEffect(() => {
        if (qrCheckIn === true) {
            fetchWrapper
                .get(`/amplitude-events/view-qr-self-checkin?bookingId=${roundData.bookingId}`, null)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        viewQrSelfCheckin(response.data);
                    }
                });
        }
    }, [qrCheckIn]);

    useEffect(() => {
        // QR체크인 가능 여부 체크
        // >> 이미 QR체크인 진입 한 상태에서 셀프체크인 가능 시간 초과 시 Alert 즉시 노출
        if (qrCheckIn) {
            fetchWrapper
                .get(`/booking/checkin/possibility?bookingId=${roundData?.bookingId}&checkinType=QRCODE`)
                .then((response) => {
                    if (response?.code === SUCCESS_CODE || response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                        // QR 체크인 가능
                    } else {
                        // QR체크인 Alert Open
                        handleSelfCheckInOpen();
                        setAlertContents(response?.message);
                        setIsActive(false);

                        fetchWrapper
                            .get(`/amplitude-events/reservation-complete?bookingId=${roundData.bookingId}`, null)
                            .then((response) => {
                                if (response.code === SUCCESS_CODE) {
                                    failSelfCheckinGps(response.data, getAmplitudeRootPath());
                                }
                            });
                    }
                });
        }

        // if (qrCheckIn && leftHour) {
        //     if (leftHour < 0 && leftHour > -1) {
        //         handleSelfCheckInOpen();
        //         setAlertContents(
        //             '티타임 시간이 지났습니다. 원활한 라운드 진행을 위해 프론트 데스크에 내장 정보를 알려주세요!'
        //         );
        //         setIsActive(false);
        //     }
        // }
    }, [time]);

    return (
        <>
            <div className="smCard back">
                <div className="detailArea">
                    <div className="selfCheckInArea qr smCard">
                        <div className="flexCon">
                            <div className="roundInfo">
                                <h2 className="tt">QR코드 체크인하세요!</h2>
                                <p className="num">예약 번호 : {checkInData?.gbNo}</p>
                            </div>
                            <div className="checkInImg qr">
                                {dDay && qrImg ? (
                                    <>
                                        <div className="qrWrapper">
                                            <QRCodeCanvas
                                                className={isActive && time ? '' : 'disabled'}
                                                value={qrImg}
                                                size={140}
                                                level={'H'}
                                            />
                                        </div>
                                        {isActive && time ? (
                                            <p className="qrInfoTxt">QR코드 유효시간 00:{number}</p>
                                        ) : (
                                            <div onClick={clickResetTimer}>
                                                <p className="qrInfoTxt">
                                                    <i className="icon">
                                                        <Icon
                                                            name="refresh"
                                                            width="16"
                                                            height="17"
                                                            viewBox="0 0 16 17"
                                                        />
                                                    </i>{' '}
                                                    QR코드 유효시간이 만료되었습니다
                                                </p>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="qrWrapper">
                                            <i className="noQr">발급된 QR 코드가 없습니다.</i>
                                        </div>
                                        <p className="qrInfoTxt">QR코드는 당일에 발급됩니다</p>
                                    </>
                                )}
                                <div className="roundInfo">
                                    <span>{appUtils.dateFormatTypeFull(checkInData?.roundAt)}</span>
                                    <span>
                                        {checkInData?.golfClubName} {checkInData?.course}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DefaultAlert
                open={selfCheckIn}
                handleClose={handleSelfCheckInClose}
                contents={alertContents}
                setQrCheckIn={qrCheckIn && setQrCheckIn}
            />
        </>
    );
}

export default React.memo(QrCheckIn);
