import React from 'react';

function PartnerInviteIcon({handleInviteOpen, smroundInvitation}) {
    return (
        <li>
            <div className="Avatar">
                <div className="thumbArea">
                    <div className="btnCon">
                        <button type="button" className="btnAdd" onClick={handleInviteOpen}></button>
                    </div>
                </div>
            </div>
            {smroundInvitation && (
                <div className="userInfo invite" onClick={handleInviteOpen}>
                    <strong className="name">동반자 초대하기</strong>
                    <div className="state">터치하여 초대해 주세요</div>
                </div>
            )}
        </li>
    );
}

export default PartnerInviteIcon;
