import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Firebase, {CONFIG} from '../Firebase';
import {osName} from 'react-device-detect';

/**
 * type 00 confirm : title + message
 * @type {string}
 */
const name = 'global';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const reducers = createReducers();
const globalSlice = createSlice({name, initialState, reducers, extraReducers});

export const globalActions = {...globalSlice.actions, ...extraActions};
export const globalReducer = globalSlice.reducer;

function createInitialState() {
    return {
        iosMinVer: null,
        iosLatestVer: null,
        aosMinVer: null,
        aosLatestVer: null,
        appUpdate: {
            update: false,
            forceUpdate: false
        },
        appVersion: null,
        deviceInfo: null
    };
}

function createReducers() {
    return {
        setAppVersion,
        setDeviceInfo
    };

    function setAppVersion(state, action) {
        state.appVersion = action.payload.appVersion;
    }

    function setDeviceInfo(state, action) {
        state.deviceInfo = action.payload?.deviceInfo;
    }
}

function createExtraActions() {
    return {
        getAppVersion: getAppVersion()
    };

    function getAppVersion() {
        // 앱 업데이트 버전 체크
        return createAsyncThunk(
            `${name}/appVersion`,
            async () => await Firebase.remoteConfig.getConfigValue(CONFIG.APP_VER)
        );
    }
}

function createExtraReducers() {
    return {
        ...getAppVersion()
    };

    function getAppVersion() {
        var {pending, fulfilled, rejected} = extraActions.getAppVersion;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                let appMinVer = '';
                let appLatestVer = '';
                const remoteConfigAppVersion = action?.payload;

                if (osName === 'Android') {
                    appMinVer = remoteConfigAppVersion?.aosMinVer;
                    appLatestVer = remoteConfigAppVersion?.aosLatestVer;
                } else if (osName === 'iOS') {
                    appMinVer = remoteConfigAppVersion?.iosMinVer;
                    appLatestVer = remoteConfigAppVersion?.iosLatestVer;
                }

                if (state.appVersion) {
                    //앱 강제 업데이트 체크
                    const configAppMinVer = appMinVer?.split('.').map(Number);
                    const configAppLatestVer = appLatestVer?.split('.').map(Number);
                    const myAppVer = state.appVersion?.split('.').map(Number);

                    for (let i = 0; i < configAppMinVer?.length; i++) {
                        if (configAppMinVer[i] > myAppVer[i]) {
                            //업데이트 필수
                            state.appUpdate.update = true;
                            state.appUpdate.forceUpdate = true;
                        } else if (configAppMinVer[i] <= myAppVer[i] && myAppVer[i] < configAppLatestVer[i]) {
                            //업데이트 권장
                            state.appUpdate.update = true;
                            state.appUpdate.forceUpdate = false;
                        }
                    }
                }
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
}
