import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { userActions } from "_store";
import Main from "../main/Main";
import { Navigate } from "react-router-dom";
import { getTodayBooking } from "../../_store/checkin.slice";
import { localStorageUtil } from "../../common/utils";
const { Kakao } = window;
function KakaoMessage() {
    if(Kakao.Picker === undefined){
        // Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
        Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
    const picker = () => {
// 팝업 방식
        Kakao.Picker.selectFriends({
            title: '친구 선택',
            maxPickableCount: 3,
            minPickableCount: 1,
        })
        .then(function(response) {
            alert(response);
        })
        .catch(function(error) {
            alert(error);
        });
    };
    return (
            <>
                <button onClick={picker}>picker</button>
            </>
    );
}

export default KakaoMessage;
