import React from 'react';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {appUtils} from 'common/utils';
import {validationUtil} from 'common/payment';

const PaymentCancelConfirmInfo = (props) => {
    const {bookingData} = props;
    return (
        <div className="payment-booking-cancel-confirm-info">
            <dl>
                <dt className="payment-success-700">{bookingInfoProperty.booking_golfclub_name}</dt>
                <dd>{`${validationUtil.isNullChk(bookingData?.golfClubName) ? '-' : bookingData?.golfClubName}`}</dd>
            </dl>
            <dl>
                <dt className="payment-success-700">{bookingInfoProperty.booking_teetime}(일/시)</dt>
                <dd>{`${
                    validationUtil.isNullChk(bookingData.roundAt)
                        ? '-'
                        : appUtils.dateFormatTypeFull(bookingData?.roundAt)
                }`}</dd>
            </dl>
            <dl>
                <dt className="payment-success-700">
                    {bookingInfoProperty.booking_course_name}/{bookingInfoProperty.booking_hole_info}
                </dt>
                <dd>
                    {validationUtil.isNullChk(bookingData?.courseName) ? '-' : bookingData?.courseName} ∙{' '}
                    {validationUtil.isNullChk(bookingData?.hole) ? '-' : bookingData?.hole}
                </dd>
            </dl>
            <dl>
                <dt className="payment-success-700">{bookingInfoProperty.booking_round_player}</dt>
                <dd>{validationUtil.isNullChk(bookingData?.roundPlayer) ? '-' : bookingData?.roundPlayer + '명'} </dd>
            </dl>

            {bookingData?.bookingTagList?.length > 0 && (
                <dl>
                    <dt className="payment-success-700">{bookingInfoProperty.booking_inner_condition}</dt>
                    <dd>
                        <ul style={{whiteSpace: 'break-spaces'}}>
                            {bookingData?.bookingTagList?.map((item, idx) => {
                                if (!validationUtil.emptyStringCheck(item?.tagName)) {
                                    return <li key={idx}>{item.tagName}</li>;
                                }
                            })}
                        </ul>
                    </dd>
                </dl>
            )}

            <dl className="border-bottom black">
                <dt className="payment-success-700">{paymentInfoProperty.payment_type}</dt>
                <dd>{validationUtil.isNullChk(bookingData?.paymentTypeName) ? '-' : bookingData?.paymentTypeName}</dd>
            </dl>
        </div>
    );
};

export default PaymentCancelConfirmInfo;
