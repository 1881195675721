import React, {useCallback, useEffect, useState} from 'react';
import DatePicker from '../../common/calendar/DatePicker';
import '../reservation.scss';
import TeaTime from './TeaTime';
import {useParams} from 'react-router-dom';
import {fetchWrapper, history} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import {appUtils} from '../../../common/utils';
import {clickDate} from '_helpers/amplitude/events/click.event';

function FieldTeaTime({isOutdoor}) {
    const {id} = useParams();

    const [shareStatus, setShareStatus] = useState('teaTime');
    const [navigateStateData, setNavigateStateData] = useState(history.location.state);
    const [searchBackLink, setSearchBackLink] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const historyObj = history.location?.state;

    let selectDefaultDate = new Date();
    if (historyObj?.searchDate !== undefined) {
        selectDefaultDate = new Date(historyObj?.searchDate);
    } else {
        selectDefaultDate.setDate(new Date().getDate() + 3);
    }
    //날짜 선택
    const [selectDate, setSelectDate] = useState(selectDefaultDate);

    // 제휴 요청 여부
    const [isAlready, setIsAlready] = useState(false);
    const [bookingUrl, setBookingUrl] = useState('');
    // 타임시트 조회
    const [teaTimeData, setTeaTimeData] = useState(null);
    const getTeaTimeData = useCallback((selectDate) => {
        /*fetchWrapper
            .get(`/booking/pre/timesheet/${id}?startDate=${appUtils.dateFormatTypeDash(selectDate)}`, null)
            .then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    setTeaTimeData(response?.data || null);
                }
            });*/

        /*
         *      2023-05-11 티타임 Drawer 팝업 변경 by brown
         *      1. 기본 필터 적용 데이터에 맞게 티타임을 내려주어야함
         *      2. 티타임 뿌려주는곳
         *         - 골프장 상세페이지 -> FieldTeaTime
         *           전체 티타임 조회
         *           payload = [날짜(roundDate) / 골프클럽id(golfClubId)]
         *         - 티타임 Drawer -> TeaTimeDrawer (이 부분 수정)
         *           설정된 필터 값에 해당하는 티타임 보여주기
         *           payload = [날짜(roundDate) / 골프클럽id(golfClubId) / 지역 필터 설정값(address) / 티타임 필터 설정값(teeTimeCode)
         *            / 홀 수 필터 설정값(hole) /  그린피 필터 설정값(greenFeeCode)
         *      3. 백엔드 변경하면 아래 주석 풀면 됨
         * */

        const payload = {
            golfClubId: id,
            roundDate: appUtils.dateFormatTypeDash(selectDate)
        };

        /*console.log(payload);*/

        fetchWrapper.post(`/booking/pre/timesheet/`, payload).then((response) => {
            let totalCount;
            let shortName;
            if (response?.code === SUCCESS_CODE) {
                setBookingUrl(response?.data?.bookingUrl);
                setTeaTimeData(response?.data || null);

                totalCount = response?.data?.timeSheetCount;
                shortName = response?.data?.golfClubShortName;
                const urlData = new URLSearchParams(history?.location?.search).get('data');
                if (urlData) {
                    const urlParams = JSON.parse(urlData);
                    if (urlParams && urlParams.selectDate) {
                        fetchWrapper
                            .get(`/amplitude-events/click-date?roundDate=${payload.roundDate}`)
                            .then((response) => {
                                response.data.location = 'cc_detail';
                                response.data.result_cc_count = 1;
                                response.data.cc_short_name = shortName;
                                response.data.available_teetime_count = totalCount;
                                clickDate(response.data);
                            });
                    }
                }
            }
        });

        setSearchBackLink(navigateStateData);

        fetchWrapper.get(`/golfClub/partner/request/${id}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                //console.log('result: ', response?.data);
                setIsAlready(response?.data.isAlready);
            }
        });
    }, []);

    useEffect(() => {
        getMaintainDate();
    }, []);

    const getMaintainDate = useCallback(() => {
        const urlData = new URLSearchParams(history?.location?.search).get('data');

        const urlParams = JSON.parse(urlData);

        if (urlParams && urlParams.selectDate) {
            let selectDate = decodeURI(urlParams.selectDate);
            const dateWithoutTimezone = selectDate.replace('GMT 0900', '');
            const date = new Date(dateWithoutTimezone);
            setSelectDate(date);
        }
    }, []);

    useEffect(() => {
        getTeaTimeData(selectDate);
    }, [getTeaTimeData, selectDate]);

    // 공유하기를 위한 골프클럽 상세 데이터
    const [detailData, setDetailData] = useState(null);
    const getDetailData = useCallback(() => {
        fetchWrapper.get(`/golfClub/detail/${id}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setDetailData(response?.data || null);
                setIsLoading(false);
            }
        });
    }, [id]);

    useEffect(() => {
        getDetailData();
    }, [getDetailData]);

    return (
        <>
            <div className="contentsArea" id="golfZoneDetail">
                <div className="dateTimePickerArea ">
                    {detailData?.isPartner && (
                        <DatePicker isOutdoor={isOutdoor} selectDate={selectDate} setSelectDate={setSelectDate} />
                    )}
                </div>
                <TeaTime
                    teaTimeCount={teaTimeData?.timeSheetCount}
                    teaTimeData={teaTimeData?.timeSheetTables}
                    golfClubId={id}
                    isPartner={detailData?.isPartner}
                    isAlready={isAlready}
                    setIsAlready={setIsAlready}
                    golfClubName={teaTimeData?.golfClubName}
                    isLoading={isLoading}
                    isDrawer={false}
                    flag="field-teaTime"
                    detailData={detailData}
                    selectDate={selectDate}
                    bookingUrl={bookingUrl}
                />
            </div>
        </>
    );
}

export default FieldTeaTime;
