import React, {useState} from 'react';
import CalenderDrawer from "../common/calendar/CalenderDrawer";
import BasicFilterDrawer from "./drawer/BasicFilterDrawer";
import AdvancedFilterDrawer from "./drawer/AdvancedFilterDrawer";

function FilterTest() {
    // 날짜 선택 Layer
    const [calenderOpen, setCalenderOpen] = useState(false)
    const onCalenderOpen = () => {
        setCalenderOpen(true)
    }

    // 기본 필터 Layer
    const [basicFilterOpen, setBasicFilterOpen] = useState(false)
    const onBasicFilterOpen = () => {
        setBasicFilterOpen(true)
    }

    // 고급 필터 Layer
    const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false)
    const onAdvancedFilterOpen = () => {
        setAdvancedFilterOpen(true)
    }

    return (
        <div>
            <div>
                <button style={{border: "1px solid red", display: "block"}} onClick={onCalenderOpen}>달력..</button>
                <button style={{border: "1px solid yellow", display: "block"}} onClick={onBasicFilterOpen}>기본필터</button>
                <button style={{border: "1px solid green", display: "block"}} onClick={onAdvancedFilterOpen}>고급필터
                </button>
            </div>

            {/* 날짜 선택 Layer */}
            <CalenderDrawer calenderOpen={calenderOpen} setCalenderOpen={setCalenderOpen}/>
            {/* 기본 필터 Layer */}
            <BasicFilterDrawer basicFilterOpen={basicFilterOpen} setBasicFilterOpen={setBasicFilterOpen}/>
            {/* 고급 필터 Layer */}
            <AdvancedFilterDrawer advancedFilterOpen={advancedFilterOpen}
                                  setAdvancedFilterOpen={setAdvancedFilterOpen}/>
        </div>
    );
}

export default FilterTest;