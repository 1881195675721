import React, {useCallback, useEffect, useState} from 'react';
import Icon from '../icons/Icon';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import DirectionSnackbar from '../alert/DirectionSnackbar';
import {likeMsg} from '../../common/messages';
/*import {appUtils} from '../../common/utils';*/

import {debounce} from 'lodash';
import {clickCCHomepageLink} from '../../_helpers/amplitude/events/click.event';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';
import {addFavoriteCc, deleteFavoriteCc} from '../../_helpers/amplitude/events/etc.event';
import AirbridgeUtil from '../../common/airbridgeUtil';

function LikeButton({clubId, interest, setIsSnackBarOpen, deleteInterestItem, isMyPage, clickItem, golfClubName}) {
    const [like, setLike] = useState(interest);

    useEffect(() => {
        setLike(interest);
    }, [interest]);

    const createInterestClub = debounce(() => {
        fetchWrapper
            .post(`/golfClub/pre/interestClub/create`, {
                golfClubId: clubId
            })
            .then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    setLike(true);
                    setIsSnackBarOpen({
                        open: true,
                        msg: `${likeMsg.likeCreate}`
                    });

                    fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${clubId}`, null).then((response) => {
                        if (response.code === SUCCESS_CODE) {
                            addFavoriteCc(response.data, sessionStorage.getItem('curUrl'));
                        }
                    });
                    AirbridgeUtil.event.addToWishlist(clubId, golfClubName);
                }
            });
    }, 300);

    const deleteInterestClub = debounce(() => {
        fetchWrapper.delete(`/golfClub/pre/interestClub/delete/${clubId}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setLike(false);
                setIsSnackBarOpen({
                    open: true,
                    msg: `${likeMsg.likeDelete}`
                });

                fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${clubId}`, null).then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        deleteFavoriteCc(response.data, sessionStorage.getItem('curUrl'));
                    }
                });
            }
        });

        // mypage > 즐찾골프장 이면 리프레시
        if (isMyPage) {
            deleteInterestItem(clickItem);
        }
    }, 300);

    const onClickLike = useCallback(() => {
        if (like) {
            deleteInterestClub();
        } else {
            createInterestClub();
        }
    }, [like, clubId]);

    return (
        <>
            <button type="button" onClick={onClickLike} className="btnInterest">
                <Icon name={like ? 'like-on' : 'like'} width="26" height="26" viewBox="0 0 26 26" />
            </button>
        </>
    );
}

export default LikeButton;
