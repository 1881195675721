import qs from 'qs';

export const qsUtil = {
    /**
     * addQueryPrefix : 쿼리스트링 문자열 앞에 '?' 추가여부
     * default true
     */
    stringifyQueryString: (queryParams, addQueryPrefix = true) => {
        const queryString = qs.stringify(queryParams, {addQueryPrefix: addQueryPrefix});
        return queryString;
    },

    parseQueryString: (queryString) => {
        const queryParams = qs.parse(queryString);
        return queryParams;
    }
};
