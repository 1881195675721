export const clickTaxonomy = {
    click_kakao_login_btn: 'click_kakao_login_btn',
    click_bnb_home: 'click_bnb_home',
    click_bnb_search: 'click_bnb_search',
    click_bnb_smartround: 'click_bnb_smartround',
    click_bnb_myinfo: 'click_bnb_myinfo',
    click_search_key_input: 'click_search_key_input',
    click_call_cc_detail: 'click_call_cc_detail',
    click_map_cc_detail: 'click_map_cc_detail',
    click_share_cc_detail: 'click_share_cc_detail',
    click_cc_homepage_link: 'click_cc_homepage_link',
    click_set_basic_filter: 'click_set_basic_filter',
    click_init_basic_filter: 'click_init_basic_filter',
    click_adv_filter: 'click_adv_filter',
    click_set_adv_filter: 'click_set_adv_filter',
    click_init_adv_filter: 'click_init_adv_filter',
    click_invite_companion_try: 'click_invite_companion_try',
    click_gps_self_checkin: 'click_gps_self_checkin',
    click_intro_courseguide: 'click_intro_courseguide',
    click_food_menu: 'click_food_menu',
    click_navigation: 'click_navigation',
    click_cs: 'click_cs',
    click_home_at_reservation_complete: 'click_home_at_reservation_complete',
    click_invite_at_reservation_complete: 'click_invite_at_reservation_complete',
    click_teetime_accordian_btn: 'click_teetime_accordian_btn',
    click_teetime_item_at_accordian: 'click_teetime_item_at_accordian',
    click_teetime_reservation: 'click_teetime_reservation',
    click_logout: 'click_logout',
    click_date: 'click_date',
    click_self_checkin: 'click_self_checkin',
    click_companion_info: 'click_companion_info',
    click_you_are_here: 'click_you_are_here',

    //    ================결제 Amplitude 추가 항목======================

    // - 수정 필요한 항목 (페이로드 추가)
    click_basic_filter: 'click_basic_filter',

    // - 추가 항목
    /**
     * @TODO : modal_check_reservation (etc-event.taxonomy.js에서 삭제)
     *         -> click_reservation_btn 으로 변경
     */
    click_reservation_btn: 'click_reservation_btn',
    click_cancel_policy: 'click_cancel_policy',

    //    ================결제 Amplitude 추가 항목======================
    //    ================Promotion Amplitude 항목======================
    click_banner_promotion_A: 'click_banner_promotion_A',
    click_banner_promotion_B: 'click_banner_promotion_B',
    click_notice_promotion: 'click_notice_promotion',
    click_promotion_complete: 'click_promotion_complete'
};
