import React from 'react';
import {Link} from 'react-router-dom';
import Icon from '../icons/Icon';
import {viewOtherBookingDetails} from '_helpers/amplitude/events/view.events';
import {rootTaxonomy} from '_helpers/amplitude/taxonomy/root.taxonomy';
import {history} from '_helpers';

function MyQuickMenu({bookingCount, interestClubCount, member}) {
    const onClickViewDetail = () => {
        history.navigate('/get-my-reservation');
        viewOtherBookingDetails(rootTaxonomy.myinfo_tooltip);
    };

    return (
        <>
            <div className="myQuickMenu">
                <Link to="/mypage/booking" className="cols">
                    <strong className="tt">예약관리</strong>
                    <dl>
                        <dt>필드 예약</dt>
                        <dd>{bookingCount}</dd>
                    </dl>
                </Link>
                <Link to="/mypage/favorites" className="cols">
                    <strong className="tt">즐겨찾기</strong>
                    <dl>
                        <dt>골프장</dt>
                        <dd>{interestClubCount}</dd>
                    </dl>
                </Link>
                {member?.isBookingCheckAgree === false && (
                    <div className="tooltip-box">
                        <div className="tooltip" onClick={onClickViewDetail}>
                            <span>예약정보 불러오고 간편하게 예약관리 하기</span>
                            <Icon className="arrow" name="arrowRight" fill="#fff" width="6" height="12" />
                        </div>
                        <svg
                            className="svg"
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="12"
                            viewBox="0 0 8 10"
                            fill="none"
                        >
                            <path d="M0 10L8 0H0L0 10Z" fill="#FE6215" />
                        </svg>
                    </div>
                )}
            </div>
        </>
    );
}

export default MyQuickMenu;
