import React from 'react';
import {appUtils, localStorageUtil} from '../../common/utils';
import {fetchWrapper, history} from '../../_helpers';
import Button from '../../components/button/Button';
import {rootTaxonomy} from '../../_helpers/amplitude/taxonomy/root.taxonomy';
import {SUCCESS_CODE} from '../../common/constants';
import {failSelfCheckinGps} from '../../_helpers/amplitude/events/etc.event';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';
import {viewSmartroundVideo} from '../../_helpers/amplitude/events/view.events';
import {useDispatch} from 'react-redux';
import {smartRoundActions} from '_store/smartRound.slice';
import {useLocation} from 'react-router-dom';

const {REACT_APP_ASSET_URL} = process.env;
const DEFAULT_VIDEO_URL = REACT_APP_ASSET_URL + '/golf-club/intro/golfclub-default_movie.mp4';

function SmartRoundButton({bookingData, disabled}) {
    const location = useLocation();
    const dispatch = useDispatch();
    return (
        <Button
            type="button"
            onClick={() => {
                let skip = JSON.parse(localStorageUtil.get('skip'));

                let isSkip;

                // 배열일 떄 실행
                if (skip && Array.isArray(skip)) {
                    isSkip = skip ? skip.findIndex((item) => item.bookingId === bookingData?.id) !== -1 : false;
                }

                if (!isSkip && appUtils.isToday(bookingData?.roundAt)) {
                    const roundData = {
                        title: bookingData?.introDesc,
                        areaInfo: bookingData?.address,
                        golfClubId: bookingData?.golfClubId,
                        bookingId: bookingData?.id,
                        introUrl: bookingData?.videoClipUrl ? bookingData.videoClipUrl : DEFAULT_VIDEO_URL,
                        golfClubName: bookingData?.golfClubName,
                        roundAt: bookingData?.roundAt,
                        courseName: bookingData?.courseName
                    };

                    fetchWrapper
                        .get(`/amplitude-events/reservation-complete?bookingId=${bookingData?.id}`, null)
                        .then((response) => {
                            if (response.code === SUCCESS_CODE) {
                                viewSmartroundVideo(response.data, getAmplitudeRootPath());
                            }
                        });

                    history.navigate('/intro', {
                        state: {
                            roundData: roundData,
                            intro: true
                        }
                    });
                } else {
                    sessionStorage.setItem('root', rootTaxonomy.info_reservation);
                    dispatch(smartRoundActions.setFrom({from: location.pathname}));
                    history.navigate(
                        `/self-check-in-v2?bookingId=${bookingData?.id}&golfClubId=${bookingData?.golfClubId}`
                    );
                    // history.navigate('/course-guide', {
                    //     state: {
                    //         roundData: {
                    //             golfClubId: bookingData?.golfClubId,
                    //             bookingId: bookingData?.id,
                    //             golfClubName: bookingData?.golfClubName
                    //         },
                    //         smartRound: true,
                    //         isDetail: false
                    //     }
                    // });
                }
            }}
            disabled={disabled} // 내정보 > 라운드예정 탭에서 취소접수건은 스마트 라운드 disabled 처리
        >
            셀프체크인 시작하기
        </Button>
    );
}

export default SmartRoundButton;
