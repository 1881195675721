import React, {useState} from 'react';
import useCollapse from 'react-collapsed';

import {paymentConstant, validationUtil} from 'common/payment';
import {bookingCancel} from 'common/payment/locales/kr/booking-cancel';

import Icon from '../../icons/Icon';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {appUtils} from 'common/utils';

function Collapse(props) {
    const collapseProps = useCollapse(props);
    return props.children(collapseProps);
}

const PaymentBookingCardCancel = (props) => {
    const {paymentType, bookingData, detailModalControl} = props;

    const [isExpanded, setIsExpanded] = useState(false);

    switch (paymentType) {
        //현장결제
        case paymentConstant.paymentType.ONSITE:
            return (
                <Collapse isExpanded={isExpanded} setHasDisabledAnimation={true}>
                    {({isExpanded, getToggleProps, getCollapseProps}) => (
                        <div className={`mypage-booking-card-info`}>
                            <dl
                                className="total-payment black cursor-pointer"
                                {...getToggleProps({
                                    onClick: () => {
                                        setIsExpanded((prevExpanded) => !prevExpanded);
                                    }
                                })}
                            >
                                <dt className="payment-weight-700">{bookingCancel.booking_cancel_detail}</dt>
                                <dd className="c-primary payment-weight-800">
                                    <button
                                        type="button"
                                        className={`btnDetail ${isExpanded ? 'collapse' : 'expand'}`}
                                    ></button>
                                </dd>
                            </dl>
                            <div className="payment-collapse-content" {...getCollapseProps()}>
                                <div className="sub-title-area">
                                    <h2>{bookingInfoProperty.booking_infomation}</h2>
                                </div>
                                <dl>
                                    <dt>
                                        {`${paymentInfoProperty.totalGreenFee} (${
                                            validationUtil.isNullChk(bookingData?.playerLimit)
                                                ? '-'
                                                : bookingData?.playerLimit
                                        }인)`}
                                    </dt>
                                    <dd>{`${
                                        validationUtil.isNullChk(bookingData?.totalGreenFee)
                                            ? '-'
                                            : bookingData?.totalGreenFee === 0
                                            ? paymentInfoProperty.greenFee_golf_contact
                                            : appUtils.amountFormat(bookingData?.totalGreenFee)
                                    }`}</dd>
                                </dl>

                                <dl>
                                    <dt>{paymentInfoProperty.cartFee}</dt>
                                    <dd>{`${
                                        bookingData?.isCartFeeIncluded === true
                                            ? '그린피에 포함'
                                            : appUtils.amountFormat(bookingData?.cartFee)
                                    }`}</dd>
                                </dl>
                                <dl>
                                    <dt>{paymentInfoProperty.onsite_payment_predict}</dt>
                                    <dd className="total-payment">
                                        {validationUtil.isNullChk(bookingData?.expectOnsiteAmount)
                                            ? '-'
                                            : appUtils.amountFormat(bookingData?.expectOnsiteAmount)}
                                    </dd>
                                </dl>
                                <dl className="border-bottom">
                                    <dt>{paymentInfoProperty.payment_type}</dt>
                                    <dd>
                                        {validationUtil.isNullChk(bookingData?.paymentTypeName)
                                            ? '-'
                                            : bookingData?.paymentTypeName}
                                    </dd>
                                </dl>
                                <div className="sub-title-area">
                                    <h2>{bookingCancel.booking_cancel_infomation}</h2>
                                </div>
                                <dl>
                                    <dt>{bookingCancel.booking_cancel_complete_date}</dt>
                                    <dd className="c-primary">
                                        {validationUtil.isNullChk(bookingData?.cancelCompletionDate)
                                            ? '-'
                                            : appUtils.dateFormatTypeFull(bookingData?.cancelCompletionDate)}
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{bookingCancel.booking_cancel_reason}</dt>
                                    <dd>
                                        {validationUtil.isNullChk(bookingData?.cancelReasonName)
                                            ? '-'
                                            : bookingData?.cancelReasonName}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    )}
                </Collapse>
            );
            break;
        //선결제, //예약금 결제
        case paymentConstant.paymentType.PREPAY:
        case paymentConstant.paymentType.RESERVE:
            return (
                <Collapse isExpanded={isExpanded} setHasDisabledAnimation={true}>
                    {({isExpanded, getToggleProps, getCollapseProps}) => (
                        <div className={`mypage-booking-card-info `}>
                            <dl
                                className="total-payment black cursor-pointer"
                                {...getToggleProps({
                                    onClick: () => {
                                        setIsExpanded((prevExpanded) => !prevExpanded);
                                    }
                                })}
                            >
                                <dt className="payment-weight-700">{paymentInfoProperty.payment_cancel_amount}</dt>
                                <dd className="total-amount payment-weight-800">
                                    {`${
                                        validationUtil.isNullChk(bookingData?.totalAmount)
                                            ? '-'
                                            : appUtils.amountFormat(bookingData?.totalAmount)
                                    }`}
                                    <button
                                        type="button"
                                        className={`btnDetail ${isExpanded ? 'collapse' : 'expand'}`}
                                    ></button>
                                </dd>
                            </dl>
                            <div className="payment-collapse-content" {...getCollapseProps()}>
                                <div className="sub-title-area">
                                    <h2>{paymentInfoProperty.payment_infomation}</h2>
                                    {bookingData?.isHost && (
                                        <button
                                            type="button"
                                            className="cursor-pointer"
                                            onClick={() => detailModalControl(true)}
                                        >
                                            <i className="teeTimeBoxIcon-advance-payment">
                                                <Icon
                                                    name="payment-detail-icon"
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                ></Icon>
                                                <span>{paymentInfoProperty.payment_detail}</span>
                                            </i>
                                        </button>
                                    )}
                                </div>
                                <dl>
                                    <dt>
                                        {`${paymentInfoProperty.totalGreenFee} (${
                                            validationUtil.isNullChk(bookingData?.playerLimit)
                                                ? '-'
                                                : bookingData?.playerLimit
                                        }인)`}
                                    </dt>
                                    <dd>{`${
                                        validationUtil.isNullChk(bookingData?.totalGreenFee)
                                            ? '-'
                                            : bookingData?.totalGreenFee === 0
                                            ? paymentInfoProperty.greenFee_golf_contact
                                            : appUtils.amountFormat(bookingData?.totalGreenFee)
                                    }`}</dd>
                                </dl>
                                {/* prepaymentType === "20" 일경우 그린피 + 카트피 결제라서
                                    카트피가 결제 정보에 노출 그 이외의 경우 현장 결제 예상금액에 노출
                                */}
                                {/* 카트피 포함 여부에 따라 '그린피에 포함 노출' */}
                                {bookingData?.prepaymentType === paymentConstant.prepaymentType.PAYMENT_TYPE_20 && (
                                    <dl className="border-dashed-bottom">
                                        <dt>{paymentInfoProperty.cartFee}</dt>
                                        <dd>{`${
                                            bookingData?.isCartFeeIncluded === true
                                                ? '그린피에 포함'
                                                : validationUtil.isNullChk(bookingData?.cartFee)
                                                ? '-'
                                                : appUtils.amountFormat(bookingData?.cartFee)
                                        }`}</dd>
                                    </dl>
                                )}
                                <dl>
                                    <dt>
                                        {paymentType === paymentConstant.paymentType.PREPAY
                                            ? paymentInfoProperty.payment_all_amount
                                            : paymentInfoProperty.payment_deposit}
                                    </dt>
                                    <dd className="total-payment">{`${
                                        validationUtil.isNullChk(bookingData?.totalAmount)
                                            ? '-'
                                            : appUtils.amountFormat(bookingData?.totalAmount)
                                    }`}</dd>
                                </dl>
                                <dl>
                                    <dt>{paymentInfoProperty.payment_type}</dt>
                                    <dd>
                                        {validationUtil.isNullChk(bookingData?.paymentTypeName)
                                            ? '-'
                                            : bookingData?.paymentTypeName}
                                    </dd>
                                </dl>
                                <dl className="border-bottom">
                                    <dt>{paymentInfoProperty.payment_method}</dt>
                                    <dd>
                                        {validationUtil.isNullChk(bookingData?.transactionMethod)
                                            ? '-'
                                            : bookingData?.transactionMethod}
                                    </dd>
                                </dl>
                                <div className="sub-title-area">
                                    <h2>{bookingCancel.booking_cancel_infomation}</h2>
                                </div>
                                <dl>
                                    <dt>{bookingCancel.booking_cancel_complete_date}</dt>
                                    <dd className="c-primary">
                                        {validationUtil.isNullChk(bookingData?.cancelCompletionDate)
                                            ? '-'
                                            : appUtils.dateFormatTypeFull(bookingData?.cancelCompletionDate)}
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{bookingCancel.booking_cancel_reason}</dt>
                                    <dd>
                                        {validationUtil.isNullChk(bookingData?.cancelReasonName)
                                            ? '-'
                                            : bookingData?.cancelReasonName}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    )}
                </Collapse>
            );

            break;
    }
};

export default PaymentBookingCardCancel;
