import React, {useEffect, useState} from 'react';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {fetchWrapper, history} from '../../_helpers';
import './membership.scss';
import {useLocation} from 'react-router-dom';
import {appUtils} from '../../common/utils';
import icon from '../icons/Icon';
import {inviteUtils} from '../../common/inviteUtils';
import {SUCCESS_CODE} from '../../common/constants';

function FindIdResult(props) {
    const {title, titleTxt, userMail, createdAt, channel} = props;
    const location = useLocation();

    let initValue = {
        email: userMail ?? '',
        channel: channel ?? 'EMAIL',
        createdAt: createdAt ?? '',
        memberInfoCorrected: false
    };

    if (location?.state?.userData) {
        const {email, channel, createdAt} = location.state.userData;
        initValue = {...initValue, email, channel, createdAt};
        // setUserData(location.state.userData);
    }

    const [userData, setUserData] = useState(initValue);
    const iconClassName = userData?.channel ? 'icon' + appUtils.toUpperLowerCaseStr(userData.channel) : 'iconEmail';

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    const [appleAuthUrl, setAppleAuthUrl] = useState('');
    const [kakaoAuthInfo, setKakaoAuthInfo] = useState({
        redirectUri: '',
        state: '',
        nonce: ''
    });

    useEffect(() => {
        fetchWrapper.get(`/authentication/kakao`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                // const payload = {...response?.data};
                setKakaoAuthInfo(response?.data || '');
            }
        });
        fetchWrapper.get(`/authentication/apple`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setAppleAuthUrl(response?.data || '');
            }
        });
    }, []);

    const moveToLogin = () => {
        console.log(initValue.channel);
        if (initValue.channel === 'kakao') {
            window.Kakao?.Auth?.authorize({
                ...kakaoAuthInfo,
                prompts: 'login',
                redirectUri: `${process.env.REACT_APP_WEB_URL}/auth/kakao/callback`
            });
        } else if (initValue.channel === 'apple') {
            if (appleAuthUrl.includes('localhost')) {
                alert('Apple login not available at localhost!');
                return;
            }
            window.location.href = appleAuthUrl;
        }
    };

    //하단 버튼
    const nextPage = () => {
        history.navigate('/member/login', {replace: true});
    };
    return (
        <>
            <Header back={false} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <form>
                        <TitleArea pageTitle={title} titleTxt={titleTxt} />
                        <div className="resultIdInfoArea">
                            <div className="resultIdInfo">
                                <p>
                                    {<i className={iconClassName} onClick={moveToLogin}></i>}
                                    {userData?.email}
                                </p>
                            </div>
                            <span className="date">가입날짜: {userData?.createdAt}</span>
                        </div>
                        <div className="btnFixedBottom">
                            <div className="inner">
                                <Button type="button" onClick={nextPage}>
                                    로그인하러 가기
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default FindIdResult;
