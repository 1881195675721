import React, {useEffect, useState} from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';

const modifiers = [
    {
        name: 'offset',
        options: {
            offset: [0, 8]
        }
    },
    {
        name: 'preventOverflow',
        enabled: true,
        options: {
            altAxis: false,
            altBoundary: false,
            tether: false,
            rootBoundary: 'document'
            //padding: 20
        }
    }
];

function Tooltip(props) {
    const [controlledVisible, setControlledVisible] = useState(false);
    const {getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible} = usePopperTooltip(
        {
            trigger: 'click',
            visible: controlledVisible,
            onVisibleChange: setControlledVisible
        },
        {
            placement: props.placement ? props.placement : 'bottom',
            modifiers
        }
    );

    useEffect(() => {
        const handleKeyDown = ({key}) => {
            if (key === 'Escape') {
                setControlledVisible(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <button ref={setTriggerRef} className="btnTooltip">
                자세히보기
            </button>

            {visible && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: 'defaultTooltip pricePopover'
                    })}
                >
                    <div className="tooltipContainer">
                        <div className={`tooltipBox ${props.closeBtn && 'flexCon'}`}>
                            <div className="txtCon">{props.contents}</div>
                            {props.closeBtn && (
                                <button type="button" className="btnClose" onClick={() => setControlledVisible(false)}>
                                    닫기
                                </button>
                            )}
                        </div>
                    </div>
                    <div {...getArrowProps({className: 'tooltipArrow'})} />
                </div>
            )}
        </>
    );
}

export default Tooltip;
