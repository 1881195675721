import React from "react";

function ThridPrivacyKTF() {
    return (
        <>
            <section>
                <h2>&lt;KT&gt;</h2>
                <p>
                    "알뜰폰(MVNO)"사업자는 다음과 같이 개인정보를 제3자에게
                    제공하고 있습니다.
                </p>
            </section>
            <section>
                <h2>제1조 (알뜰폰(MVNO) 사업자)</h2>
                <p>
                    (주)케이티의 알뜰폰(MVNO) 사업자는 LG헬로비전(헬로모바일),
                    세종텔레콤(스노우맨), 씨앤커뮤니케이션(WMVNO),
                    에넥스텔레콤(A mobile), 프리텔레콤(프리티),
                    코드모바일(이지모바일), KT파워텔, 위너스텔(Well),
                    ACN코리아(플래시모바일), 앤알커뮤니케이션(앤텔레콤),
                    에스원(안심모바일), 아이즈비전(아이즈모바일),
                    머천드코리아(마이월드), KT M모바일(M모바일), 유니컴즈(모빙),
                    더원플랫폼(IplusU), 스테이지파이브(핀플레이),
                    드림라인(드림모바일), 한국케이블텔레콤(TPLUS),
                    와이엘랜드(여유알뜰폰), 큰사람커넥트(이야기모바일),
                    니즈텔레콤(니즈텔레콤), KT스카이라이프(스카이라이프모바일),
                    에이프러스(아시아모바일), 한국피엠오(밸류컴),
                    (주)스마텔입니다.
                </p>
            </section>
            <section>
                <h2>제2조 (제공목적)</h2>
                <p>
                    본 동의는 본인확인 서비스를 제공하기 위하여 본인확인기관인
                    (주)케이티와 본인확인서비스 이용자간 본인확인서비스 이용에
                    필요한 고객정보를 위탁하여{" "}
                    <strong className="underLine">본인확인서비스를 제공</strong>{" "}
                    하는 것에 대해 동의합니다.
                </p>
            </section>
            <section>
                <h2>제3조 (제공정보)</h2>
                <p>
                    휴대폰 본인확인 서비스 제공에 필요한 개인정보로 성명,
                    휴대폰번호, 생년월일, 내.외국인구분, 성별정보입니다.
                </p>
            </section>
            <section>
                <h2>제4조 (제공받는자)</h2>
                <p>
                    <strong className="underLine">(주)케이티</strong>
                </p>
            </section>
            <section>
                <h2>제5조 (제공기간)</h2>
                <p>
                    <strong className="underLine">
                        이동통신사에서 보유중인 정보로서 별도 이용기간은
                        없습니다.
                    </strong>
                </p>
            </section>
            <section>
                <p>
                    "본인"은 정보제공에 동의하지 않으실 수 있으며, 동의하지
                    않으실 경우 휴대폰 본인 확인 서비스를 이용하실 수 없습니다.
                </p>
            </section>
        </>
    );
}

export default ThridPrivacyKTF;
