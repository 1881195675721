import React, {useEffect, useState} from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';
import {appUtils} from '../../common/utils';

const modifiers = [
    {
        name: 'offset',
        options: {
            offset: [0, 10]
        }
    },
    {
        name: 'flip',
        enabled: false
    },
    {
        name: 'preventOverflow',
        enabled: true,
        options: {
            altAxis: false,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document'
            //padding: 20
        }
    }
];

function ToolTip({placement, bookingData}) {
    const [controlledVisible, setControlledVisible] = useState(false);
    const {getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible} = usePopperTooltip(
        {
            trigger: 'click',
            visible: controlledVisible,
            onVisibleChange: setControlledVisible
        },
        {
            placement: placement ? placement : 'top',
            modifiers
        }
    );

    useEffect(() => {
        const handleKeyDown = ({key}) => {
            if (key === 'Escape') {
                setControlledVisible(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const onClickClose = () => {
        setControlledVisible(false);
    };

    return (
        <>
            <button ref={setTriggerRef} className="btnTooltip">
                자세히보기
            </button>

            {visible && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: 'defaultTooltip pricePopover'
                    })}
                >
                    <div className="tooltipContainer">
                        <div className="tooltipBox">
                            <div className="ttWrap">
                                <div className="tt">불포함 부대비용</div>
                                <button type="button" className="btnClose" onClick={onClickClose}>
                                    툴팁 닫기
                                </button>
                            </div>
                            <ul className="priceInfo">
                                <li className={bookingData?.isCartFeeIncluded === true ? 'hide' : ''}>
                                    - 카트피(현장결제) : {appUtils.numberFormat(bookingData?.cartAmount)}원
                                </li>
                                <li>- 캐디피(현장결제) : {bookingData?.caddieAmount}</li>
                            </ul>
                            <p>※ 라운드 홀수 및 골프장 상황에 따라 비용이 변동될 수 있습니다.</p>
                        </div>
                    </div>
                    <div {...getArrowProps({className: 'tooltipArrow'})} />
                </div>
            )}
        </>
    );
}

export default ToolTip;
