import React from 'react';
import styled from 'styled-components';
import {history} from '../../_helpers';

function AlarmTalk() {
    const AlarmTalkList = styled.div`
        text-align: center;
        margin-top: 50px;

        h1 {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 15px;
            color: lightcoral;
        }

        ul {
            li {
                margin-bottom: 3px;

                button {
                    padding: 10px;
                    background: pink;
                }
            }
        }
    `;

    const golfClubId = '2c5d13f7-b336-11ed-ae09-040300000000'; // 포도 컨트리
    const bookingId = '6fed2031-f1cd-495a-974a-de5b111ceffb'; // 하드코딩
    const club = '포도 컨트리클럽'; // 하드코딩
    const golfClubName = encodeURI(club);
    // const golfClubName = club;
    const courseCode = '2';
    const mapControlYN = 'Y';
    const csStatus = 0;

    // '동반자 초대하기, 예약내역' 버튼 -> 예약 리스트로 이동
    const moveBookingList = () => {
        history.navigate(`/gate-alarm/alarm/bookingList/${golfClubId}/${bookingId}`); // 비즈엠에서 설정할 url
    };

    // '취소/위약규정' 버튼 -> 골프장 상세페이지 이동 후 레이어팝업 오픈
    const moveCancellationLayer = () => {
        history.navigate(`/gate-alarm/alarm/cancellation/${golfClubId}/${bookingId}`);
    };

    // '취소내역' 버튼 -> 내정보 라운드취소 탭 이동 후 스크롤
    const moveRoundCancelTab = () => {
        history.navigate(`/gate-alarm/alarm/roundCancel/${golfClubId}/${bookingId}`);
    };

    // '셀프체크인 이용방법' 버튼 -> 셀프체크인 이용방법 페이지 오픈 >>>> 버튼 사용안함
    // const moveHowToUseCheckIn = () => {
    //     history.navigate(`/gate-page`);
    // };

    // '티타임 예약하기' 버튼 -> 메인으로 이동
    const moveTeaTime = () => {
        history.navigate(`/gate-alarm/alarm/teaTime/${golfClubId}/${bookingId}`);
    };

    // '스마트라운드' 버튼 -> 스마트라운드로 이동
    const moveSmartRound = () => {
        history.navigate(`/gate-sm/alarm/smartRound/${golfClubId}/${bookingId}/${golfClubName}`);
    };

    // '골프장 공지사항' 버튼 -> 스마트라운드 공지사항 이동
    const moveClubBoard = () => {
        history.navigate(`/gate-sm/alarm/clubBoard/${golfClubId}/${bookingId}/${golfClubName}`);
    };

    // '코스 정보' 버튼 -> 코스 앱으로 이동 : courseCode / member?.teeingGround 필요...
    const moveCourseInfo = () => {
        history.navigate(`/gate-course/alarm/courseInfo/${golfClubId}/${courseCode}/${mapControlYN}/${bookingId}`);
    };
    const moveHome = () => {
        history.navigate(`/gate-main`);
    };

    // 채널톡
    const moveChannelTalk = () => {
        history.navigate(`/gate-app/cs/${csStatus}`);
    };

    // '이용가이드' 버튼
    const moveHowToUseSmartRound = () => {
        history.navigate(`/gate-selfCh/self-checkin-guide/${golfClubId}/${bookingId}/${golfClubName}`);
    };

    /**
     * 알림톡 버튼 노출 기준
     * 1. 예약완료(임박) = 동반자 초대하기 / 취소/위약규정 / 골프장 공지사항
     * 2. 7일전 = 예약내역 / 취소/위약규정 / 골프장 공지사항
     * 3. 1일전(셀체가능,셀체불가) = 스마트라운드 / 골프장 공지사항
     * 4. 취소접수 = 취소/위약규정
     * 5. 취소완료 = 취소내역
     * 6. 회원가입 = 티타임 예약하기
     */

    return (
        <AlarmTalkList>
            <h1>🎈 알림톡 버튼 :-) ㅎㅎ</h1>
            <ul>
                <li onClick={moveBookingList}>
                    <button>동반자 초대하기</button>
                </li>
                <li onClick={moveCancellationLayer}>
                    <button>취소/위약규정</button>
                </li>
                <li onClick={moveClubBoard}>
                    <button>골프장 공지사항</button>
                </li>
                <li onClick={moveBookingList}>
                    <button>예약내역</button>
                </li>
                <li onClick={moveSmartRound}>
                    <button>스마트라운드</button>
                </li>
                <li onClick={moveRoundCancelTab}>
                    <button>취소내역</button>
                </li>
                {/*<li onClick={moveHowToUseCheckIn}>*/}
                {/*    <button>셀프체크인 이용방법</button>*/}
                {/*</li>*/}
                <li onClick={moveTeaTime}>
                    <button>티타임 예약하기</button>
                </li>
                <li onClick={moveCourseInfo}>
                    <button>코스 정보</button>
                </li>
                <li onClick={moveChannelTalk}>
                    <button>고객센터</button>
                </li>
                <li onClick={moveHowToUseSmartRound}>
                    <button>이용가이드 보기</button>
                </li>
                <li onClick={moveHome}>
                    <button>home~</button>
                </li>
            </ul>
        </AlarmTalkList>
    );
}

export default AlarmTalk;
