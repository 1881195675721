import React, {useCallback, useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import AuthenticationPhone from './AuthenticationPhone';
import {GrDown} from 'react-icons/gr';
import SelectDrawer from '../common/SelectDrawer';
import Timer from '../common/Timer';
import {useDispatch, useSelector} from 'react-redux';
import {timerActions} from '../../_store/timer.slice';
import {errMsg, successMsg} from '../../common/messages';
import {appUtils} from '../../common/utils';
import {NAME_MAXLENGTH} from '../../common/constants';
import moment from 'moment';

const telecomInfo = [
    {
        value: 'SKT',
        name: 'SKT'
    },
    {
        value: 'KTF',
        name: 'KT'
    },
    {
        value: 'LGT',
        name: 'LG U+'
    },
    {
        value: 'SKM',
        name: '알뜰폰 SKT'
    },
    {
        value: 'KTM',
        name: '알뜰폰 KT'
    },
    {
        value: 'LGM',
        name: '알뜰폰 LG U+'
    }
];
const drawerLabel = '통신사 선택';

function AuthenticationFields({
    formProp: {errors, control, watch, setValue, setFocus},
    activeTimer,
    setActiveTimer,
    formModify,
    reactive,
    setReactive,
    setActiveButton,
    authValid,
    onSubmit,
    hasNot_phoneNumber // 카카오 회원가입에서 폰넘버가 존재하지 않아 해당 객체로 넘어옴
}) {
    const {done} = useSelector((x) => x.timer);
    const {authValidation, setAuthValidation} = authValid;
    const [authAll, setAuthAll] = useState(false);

    const defaultValues = {
        userName: hasNot_phoneNumber?.name || '',
        userGender: hasNot_phoneNumber
            ? hasNot_phoneNumber.hasOwnProperty('gender') === false
                ? ''
                : hasNot_phoneNumber.gender === 'FEMALE'
                ? '2'
                : '1'
            : '',
        userBirth: hasNot_phoneNumber?.birthdate ? moment(hasNot_phoneNumber?.birthdate).format('YYMMDD') : ''
    };

    // console.log('defaultValues', defaultValues);
    // console.log('hasNot_phoneNumber.name ', hasNot_phoneNumber?.name, !!hasNot_phoneNumber?.name);
    // console.log('formModify', formModify);

    // 통신사 선택
    const [telecomCode, setTelecomCode] = useState();
    const [telecomName, setTelecomName] = useState(null);
    const [telecomChecked, setTelecomChecked] = useState(false);
    const [mvnoChecked, setMvnoChecked] = useState(false);

    // Drawer 제어
    const [drawerState, setDrawerState] = useState(false);
    const [resetDisabled, setResetDisabled] = useState(false);

    // 체크박스 상태관리
    const [allChecked, setAllChecked] = useState(false);

    const dispatch = useDispatch();

    const setRetryAction = useCallback(() => {
        if (reactive) {
            dispatch(timerActions.setReset(true));
            setResetDisabled(false);
            setAuthValidation({message: '', error: false});
            setReactive(false);
        }
    }, [reactive, dispatch]);

    // 인증전체 동의시
    useEffect(() => {
        setValue('authAgree', `${authAll}`);
    }, [authAll, setValue]);

    // 재발송시
    useEffect(() => {
        setRetryAction();
    }, [setRetryAction]);

    // 인증완료시
    useEffect(() => {
        if (done) {
            setResetDisabled(done);
        }
    }, [done]);

    // 통신사선택
    const toggleDrawer = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState(!drawerState);
    };
    // drawer 선택
    const onClickTelecom = (e) => {
        setTelecomName(e.target.textContent);
        setTelecomCode(e.target.value);
        setUserTelecom(e.target.value);
        setTelecomChecked(true);
        e.target.closest('div.drawer-content').querySelector('button.btnClose').click();

        if (e.target.value === 'SKM' || e.target.value === 'KTM' || e.target.value === 'LGM') {
            setMvnoChecked(true);
        } else {
            setMvnoChecked(false);
        }
    };

    // 통신사
    const setUserTelecom = (value) => {
        setValue('userTelecom', value);
    };

    const replaceInputNumber = (value) => {
        return value.replace(/[^0-9]/gi, '') || '';
    };

    // 생년월일
    const onChangeBirth = (e) => {
        let value = e.target.value;
        e.target.value = replaceInputNumber(value);

        if (e.target.value.length === 6) {
            setFocus('userGender');
        }
    };
    // 성별
    const onChangeGender = (e) => {
        let value = e.target.value;
        e.target.value = replaceInputNumber(value);

        if (value.length === 1 && watch('userBirth') && watch('userBirth').length === 6) {
            setDrawerState(!drawerState);
            e.target.blur();
        }
    };

    // 연락처
    const onChangeUserPhone = (e) => {
        let value = e.target.value;
        e.target.value = replaceInputNumber(value);
    };

    const onClickTimerReset = () => {
        dispatch(timerActions.setReset(true));
        setAuthValidation({
            error: false,
            message: successMsg.USER_AUTH_TIME_RESET
        });
    };
    // 인증번호 6자리 -> 인증완료
    const onChangeAuthNumber = (e) => {
        let value = e.target.value;

        if (!resetDisabled && value.length === 6) {
            setActiveButton(true);
        } else {
            setActiveButton(false);
        }
    };
    // 시간 초과시, 시과초과 메시지 노출
    const setTimeOverAction = () => {
        setAuthValidation({error: true, message: errMsg.USER_AUTH_TIME_OVER});
        setResetDisabled(true);
        setActiveTimer(false);
        setActiveButton(false);
    };

    // 인증약관 필수 항목 모두 동의 시 핸드폰 필드로 자동 포커스 이동
    useEffect(() => {
        if (allChecked) {
            setFocus('userPhone');
        }
    }, [allChecked]);

    useEffect(() => {
        if (hasNot_phoneNumber) {
            for (const [key, value] of Object.entries(defaultValues)) {
                setValue(key, value);
            }
        }
    }, [control, setValue, hasNot_phoneNumber]);

    return (
        <>
            <div className="row fieldArea">
                <Controller
                    name="userName"
                    control={control}
                    // defaultValue=""
                    render={({field: {value, onChange, ...field}}) => (
                        <input
                            {...field}
                            name="userName"
                            inputMode="text"
                            type="text"
                            defaultValue={defaultValues.userName}
                            className={`textField ${errors.userName && 'is-invalid error'}`}
                            placeholder="이름 입력"
                            maxLength={NAME_MAXLENGTH}
                            disabled={hasNot_phoneNumber?.name ? true : !formModify}
                            onChange={(e) => {
                                // appUtils.isValidInputCheck(e, NAME_MAXLENGTH, CHK_KOR_INPUT);
                                appUtils.noSpaceForm(e);
                                onChange(e.target.value);
                            }}
                            onFocus={appUtils.offsetPadding}
                            onBlur={appUtils.defaultPadding}
                        />
                    )}
                />
                {errors?.userName && <p className="infoMsg error">{errors?.userName?.message}</p>}
            </div>
            <div className="row fieldArea">
                <div className={`idNumberArea ${errors.userBirth || errors.userGender ? 'is-invalid error' : ''}`}>
                    <Controller
                        type="text"
                        name="userBirth"
                        control={control}
                        // defaultValue=""
                        render={({field: {value, onChange, ...field}}) => (
                            <input
                                {...field}
                                type="text"
                                inputMode="numeric"
                                defaultValue={defaultValues.userBirth}
                                className={`textField ${errors.userBirth && 'is-invalid error'}`}
                                placeholder="주민등록번호 앞 6자리"
                                maxLength={6}
                                disabled={hasNot_phoneNumber?.birthdate ? true : !formModify}
                                onChange={(e) => {
                                    onChangeBirth(e);
                                    onChange(e.target.value);
                                }}
                                onFocus={appUtils.offsetPadding}
                                onBlur={appUtils.defaultPadding}
                            />
                        )}
                    />
                    <span className="bul">-</span>
                    <Controller
                        type="text"
                        name="userGender"
                        control={control}
                        defaultValue=""
                        render={({field: {value, onChange, ...field}}) => (
                            <input
                                {...field}
                                type="text"
                                inputMode="numeric"
                                name="userGender"
                                defaultValue={defaultValues.userGender}
                                className={`textField num1 ${errors.userGender ? 'is-invalid error' : ''}`}
                                placeholder=" "
                                disabled={hasNot_phoneNumber?.gender ? true : !formModify}
                                onChange={(e) => {
                                    onChangeGender(e);
                                    onChange(e.target.value);
                                }}
                                maxLength="1"
                                onFocus={appUtils.offsetPadding}
                                onBlur={appUtils.defaultPadding}
                            />
                        )}
                    />
                    <div className="dotsArea">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                    </div>
                </div>
                {errors?.userBirth ? (
                    <p className="infoMsg error">{errors?.userBirth?.message}</p>
                ) : (
                    errors?.userGender && <p className="infoMsg error">{errors?.userGender?.message}</p>
                )}
            </div>
            <input type="hidden" name="authAgree" value="false" />
            {/* 통신사 선택 */}
            <div className="row fieldArea">
                <button type="button" className="btnSelectModal" onClick={toggleDrawer} disabled={!formModify}>
                    {telecomName || drawerLabel}
                    <i className="icon">
                        <GrDown size={'0.9625rem'} />
                    </i>
                </button>
                {errors?.userTelecom && <p className="infoMsg error">{errors?.userTelecom?.message}</p>}
            </div>
            <AuthenticationPhone
                setAuthAll={setAuthAll}
                error={errors?.authAgree?.message}
                setDisabled={!formModify}
                telecomChecked={telecomChecked}
                telecomCode={telecomCode}
                mvnoChecked={mvnoChecked}
                allChecked={allChecked}
                setAllChecked={setAllChecked}
            />
            {/* 휴대전화번호 입력 */}
            <div className="row fieldArea">
                <div className="authNumArea">
                    <Controller
                        type="text"
                        name="userPhone"
                        control={control}
                        defaultValue=""
                        render={({field: {value, onChange, ...field}}) => (
                            <input
                                {...field}
                                type="text"
                                inputMode="numeric"
                                name="userPhone"
                                className={`textField ${errors.userPhone ? 'is-invalid error' : ''}`}
                                placeholder="휴대전화 번호(- 없이 입력)"
                                disabled={!formModify}
                                onChange={(e) => {
                                    onChangeUserPhone(e);
                                    onChange(e.target.value);
                                }}
                                maxLength={11}
                                onFocus={appUtils.offsetPadding}
                                onBlur={appUtils.defaultPadding}
                            />
                        )}
                    />
                    {!activeTimer ? (
                        <button
                            type="button"
                            className={`btnSmall auth ${done || 'on'}`}
                            disabled={done}
                            onClick={onSubmit}
                        >
                            인증요청
                        </button>
                    ) : (
                        <button
                            type="button"
                            className={done || resetDisabled ? `btnSmall` : `btnSmall btnSecondary`}
                            disabled={done || resetDisabled}
                            onClick={onSubmit}
                        >
                            재발송
                        </button>
                    )}
                    {/* 버튼 활성화 되었을때 클래스 "on" 추가 */}
                </div>
                {errors?.userPhone && <p className="infoMsg error">{errors?.userPhone?.message}</p>}
                {/*2023.03.27 만료시간 지나면 다시 받을 수 있게 처리*/}
                {!activeTimer && authValidation.error ? (
                    <p className="infoMsg error">{authValidation.message}</p>
                ) : null}
            </div>
            {/* 인증번호 입력 */}
            {activeTimer && (
                <div className="row fieldArea">
                    <div className="authNumArea">
                        <input
                            name="sms"
                            type="text"
                            inputMode="numeric"
                            className="textField"
                            placeholder="인증번호 입력"
                            pattern="[0-9]*"
                            disabled={done}
                            maxLength={6}
                            onChange={onChangeAuthNumber}
                            onFocus={appUtils.offsetPadding}
                            onBlur={appUtils.defaultPadding}
                            autoFocus
                        />
                        <Timer setOverAction={setTimeOverAction} />
                        <button
                            type="button"
                            className={`btnSmall ${resetDisabled || 'on'}`}
                            onClick={onClickTimerReset}
                            disabled={resetDisabled}
                        >
                            시간연장
                        </button>
                    </div>
                    {authValidation.error ? (
                        <p className="infoMsg error">{authValidation.message}</p>
                    ) : (
                        <p className="infoMsg">{authValidation.message}</p>
                    )}
                </div>
            )}
            {/* 통신사 선택 Drawer 분리 */}
            <SelectDrawer
                label={drawerLabel}
                items={telecomInfo}
                selected={telecomCode}
                onSelected={onClickTelecom}
                isOpen={drawerState}
                onClickClose={toggleDrawer}
            />
            <input type="hidden" name="userTelecom" />
        </>
    );
}

export default AuthenticationFields;
