import React, {useCallback, useContext, useEffect, useState} from 'react';
import Icon from '../../icons/Icon';
import {FieldMainContext} from '../../../_store/context/FieldMainContext';
import '../reservation.scss';
import FilterCategory from './FilterCategory';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Pagination} from 'swiper';
import {FieldMainContext_searchResult} from '../../../_store/context/FieldMainContext_SearchResult';
import {FieldMainContext_myPage} from '../../../_store/context/FieldMainContext_myPage';

import _ from 'lodash';
import {bookingConstant} from 'common/payment';

function FilterArea({
    basicFilter,
    onBasicFilterOpen,
    onAdvancedFilterOpen,
    isFilterInitBtnDisable,
    filterInitBtn,
    selectAdvancedList,
    contextFlag
}) {
    const {region, teeTime, playerCnt, holeCnt, greenFee, paymentType} = useContext(
        contextFlag === 'main'
            ? FieldMainContext
            : contextFlag === 'search-result'
            ? FieldMainContext_searchResult
            : FieldMainContext_myPage
    );

    useEffect(() => {
        sortSelectFilter();
    }, [basicFilter]);

    /*console.log(basicFilter);*/

    let [sortFilterItem, setSortFilterItem] = useState([]);

    const sortSelectFilter = useCallback(() => {
        let sortedItem = [];

        let index = 0;

        basicFilter.filter((item) => {
            switch (item.filterCode) {
                case bookingConstant.basicFilterCode.BASIC_FILTER_REGION:
                    if (region.length > 0) {
                        sortedItem[index] = item;
                        index++;
                    }
                    break;
                case bookingConstant.basicFilterCode.BASIC_FILTER_TEETIME:
                    if (teeTime.length > 0) {
                        sortedItem[index] = item;
                        index++;
                    }
                    break;
                case bookingConstant.basicFilterCode.BASIC_FILTER_ROUNDPLAYER:
                    if (playerCnt.length > 0) {
                        sortedItem[index] = item;
                        index++;
                    }
                    break;
                case bookingConstant.basicFilterCode.BASIC_FILTER_HOLE:
                    if (holeCnt.length > 0) {
                        sortedItem[index] = item;
                        index++;
                    }
                    break;
                case bookingConstant.basicFilterCode.BASIC_FILTER_GREENFEE:
                    if (greenFee.length > 0) {
                        sortedItem[index] = item;
                        index++;
                    }
                    break;
                /* 2022.07.07 결제방식 추가 */
                case bookingConstant.basicFilterCode.BASIC_FILTER_PAYMENT:
                    if (paymentType.length > 0) {
                        sortedItem[index] = item;
                        index++;
                    }
                    break;
                default:
                    break;
            }
        });

        const allSortItem = sortedItem.concat(basicFilter.filter((item) => sortedItem.indexOf(item < 0)));

        let allSortitemDup = _.uniqBy(allSortItem, 'filterCode');

        setSortFilterItem(allSortitemDup);
    }, [region, teeTime, playerCnt, holeCnt, greenFee, paymentType]);

    return (
        <div className="filterWrap">
            <div className="filterList">
                <ul>
                    {isFilterInitBtnDisable === false && (
                        <li>
                            <button type="button" className="filterRefresh" onClick={filterInitBtn}>
                                <span>
                                    <i className="btnRefresh"></i>초기화
                                </span>
                            </button>
                        </li>
                    )}
                    {isFilterInitBtnDisable === true ? (
                        <>
                            {basicFilter
                                ? basicFilter.map((filter, idx) => {
                                      return (
                                          <FilterCategory
                                              key={idx}
                                              idx={idx}
                                              onBasicFilterOpen={onBasicFilterOpen}
                                              filter={filter}
                                              codeName={filter.filterCodeName}
                                              contextFlag={contextFlag}
                                          ></FilterCategory>
                                      );
                                  })
                                : ''}
                        </>
                    ) : (
                        <>
                            {sortFilterItem
                                ? sortFilterItem.map((filter, idx) => {
                                      return (
                                          <FilterCategory
                                              key={idx}
                                              idx={idx}
                                              onBasicFilterOpen={onBasicFilterOpen}
                                              filter={filter}
                                              codeName={filter.filterCodeName}
                                              contextFlag={contextFlag}
                                          ></FilterCategory>
                                      );
                                  })
                                : ''}
                        </>
                    )}
                </ul>
            </div>
            {!(contextFlag === 'mypage-favorites') && (
                <div className="hashFilterWrap">
                    {selectAdvancedList?.length <= 0 ? (
                        <div className="hashInputArea">
                            <div className="hashInputBox leftCon hashInputList">
                                <Swiper
                                    className="hashFilter-swiper"
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                        reverseDirection: false
                                    }}
                                    slidesPerView={1}
                                    modules={[Autoplay]}
                                    direction={'vertical'}
                                    speed={1000}
                                    allowTouchMove={false} //드래그 금지
                                    /*effect="slide"*/
                                    loop={true}
                                >
                                    <SwiperSlide>
                                        <span onClick={onAdvancedFilterOpen}>#셀프라운드</span>
                                        <span onClick={onAdvancedFilterOpen}>#퇴근하고야간라운드</span>
                                        <span onClick={onAdvancedFilterOpen}>#인생샷찍기좋은</span>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div onClick={onAdvancedFilterOpen}>
                                            <span className="hash">#</span>{' '}
                                            <span className="basicTooltip left">취향에 맞는 골프장을 찾아보세요</span>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className="searchBtnArea">
                                <button type="button" onClick={onAdvancedFilterOpen}>
                                    <Icon name="search-2" width="30px" height="30px" fill="#ffffff" />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="hashTagList">
                            <div className="hashTagBox">
                                {selectAdvancedList?.map((item, idx) => (
                                    <span key={idx} onClick={onAdvancedFilterOpen}>
                                        <button># {item.name}</button>
                                    </span>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default FilterArea;
