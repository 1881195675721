import React from 'react';
import logo from '../../assets/images/common/noImg_logo.svg';

function NoImg({logoWidth, height}) {
    const style = {
        height: height ? height : '100%'
    };
    const logoStyle = {
        width: logoWidth ? logoWidth : 'auto'
    };

    return (
        <div className="noImgLogo" style={style}>
            <img src={logo} alt="golaga" style={logoStyle} />
        </div>
    );
}

export default NoImg;
