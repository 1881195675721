import React, {useCallback, useEffect, useState} from 'react';
import Header from '../../components/Header';
import Navigation from '../navigation/Navigation';
import Modal from '@mui/material/Modal';
import './mypage.scss';
import {useDispatch, useSelector} from 'react-redux';
import {authActions} from '../../_store';
import {osName} from 'react-device-detect';
import MyProfile from './profile/MyProfile';
import MyContentsSection from './MyContentsSection';
import MyLogoutSection from './MyLogoutSection';
import {useNavigate} from 'react-router-dom';
import {fetchWrapper, history} from '../../_helpers';
import {SUCCESS_CODE, YN} from '../../common/constants';
import MyQuickMenu from './MyQuickMenu';
import {localStorageUtil} from '../../common/utils';
import useWindowDimensions from '../hooks/UseWindowDimensions';
// import getMyReservBanner from '../../assets/images/reservation/get_my_reserve_banner_last.png';
import {logoutSuccess} from '_helpers/amplitude/events/auth.events';
import {viewMyinfo, viewOtherBookingDetails} from '../../_helpers/amplitude/events/view.events';
import WebAccessInfoModal from '../modal/WebAccessInfoModal';
import AirbridgeUtil from '../../common/airbridgeUtil';
import {clickLogout} from '../../_helpers/amplitude/events/click.event';
import {rootTaxonomy} from '_helpers/amplitude/taxonomy/root.taxonomy';
import {promotionActions} from '_store/promotion.slice';
import Banner from 'components/common/Banner/Banner';
import banner_mypage from '../../assets/images/banner/banner_mypage.png';

const {Kakao} = window;

function MyPage() {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    history.navigate = useNavigate();

    //배너별 타이틀
    const bannerTitle = {
        mainTitle: '홈페이지, 전화, 타 App에서 예약했어도',
        subTitle: '골라가 하나로 셀프체크인!'
    };

    const logout = () => {
        clickLogout();

        fetchWrapper
            .get('/amplitude-events/login-success')
            .then((result) => {
                logoutSuccess(result.data);
                kakaoLogout(); // 카카오 로그아웃
                // Amplitude event - 로그인 이벤트와 동일
                dispatch(authActions.logout());
                AirbridgeUtil.event.logout();
            })
            .catch((err) => {
                kakaoLogout(); // 카카오 로그아웃
                // Amplitude event - 로그인 이벤트와 동일
                dispatch(authActions.logout());
                AirbridgeUtil.event.logout();
            });
    };
    const auth = useSelector((x) => x?.auth?.user);

    // 골프장 예약 건수 데이터
    const [bookingCount, setBookingCount] = useState('0');
    const [member, setMember] = useState({});
    const getBookingCount = () => {
        fetchWrapper.get(`/booking/count`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setBookingCount(response?.data);
            }
        });

        viewMyinfo();
    };

    // 관심클럽 건수 데이터
    const [interestClubCount, setInterestClubCount] = useState('0');
    const getInterestClubCount = () => {
        fetchWrapper.get(`/golfClub/like/golfClubs/count`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setInterestClubCount(response?.data);
            }
        });
    };

    // 로그아웃 확인 Alert
    const [logoutAlertOpen, setLogoutAlertOpen] = useState(false);
    const handleLogoutAlertOpen = () => setLogoutAlertOpen(true);
    const handleLogoutAlertClose = () => setLogoutAlertOpen(false);

    // 앱 버전
    const [appVersion, setAppVersion] = useState('1.0');

    const updateAppVersion = (version) => {
        setAppVersion(`${osName + ' ' + version}`);
        /* 하위 랜더링을 위한 state 재지정 */
        const jsonMenu = JSON.parse(JSON.stringify(menu));
        // jsonMenu.contents.contentsList[3].version = `${osName + ' ' + version}`;
        const found = jsonMenu.contents.contentsList[jsonMenu.contents.contentsList.length - 1];
        found.version = `${osName + ' ' + version}`;
        found.title = '앱 버전';
        setMenu(jsonMenu);
    };

    useEffect(() => {
        const listener = (e) => {
            if (!e || typeof e?.data === 'object') {
                return;
            }
            try {
                let data = JSON.parse(e?.data);
                if (data && data?.type === 'appVersion' && data?.appVersion?.length) {
                    console.debug(`appVersion=${data.appVersion}`);
                    updateAppVersion(data.appVersion);
                }
                if (data && data?.type === 'deviceInfo') {
                    // console.debug(`deviceInfo=${JSON.stringify(data, null, 2)}`);
                }
                return true;
            } catch (e) {
                return false;
            }
        };

        // RN에서 웹으로 데이터를 전송했을때 message이벤트가 실행됩니다.
        document.addEventListener('message', listener);
        window.addEventListener('message', listener);
        return () => {
            document.removeEventListener('message', listener);
            window.removeEventListener('message', listener);
        };
    });

    useEffect(() => {
        let payload = {
            type: 'appVersion'
        };
        // console.debug(`postMessage ${JSON.stringify(payload)}`);
        window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
    }, []);

    useEffect(() => {
        let payload = {
            type: 'deviceInfo'
        };
        // console.debug(`postMessage ${JSON.stringify(payload)}`);
        window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
    }, []);

    const init = async () => {
        getBookingCount();
        getInterestClubCount();
        const res = await dispatch(promotionActions.getPromotionApplicable());

        if (res?.payload?.displayYn === YN.Y && res?.payload?.activeYn === YN.Y) {
            /* 
            셀프체크인 프로모션 기간(~2023.09.30)동안 
            1. 공지사항 => 이벤트로 명칭변경 
            2. New 아이콘 표시
            */
            setMenu((prevMenu) => {
                const updatedContentsList = prevMenu.contents.contentsList.map((content) => {
                    if (content.title === '공지사항') {
                        return {...content, title: '이벤트'};
                    }
                    return content;
                });

                return {
                    ...prevMenu,
                    contents: {
                        ...prevMenu.contents,
                        contentsList: updatedContentsList
                    }
                };
            });
        }
    };

    useEffect(() => {
        init();
    }, []);

    const selfChInPromotionEvent = localStorageUtil.get('joinSelfChInPromotionEvent');
    useEffect(() => {
        if (selfChInPromotionEvent) {
            history.navigate('/main');
        }
    }, [selfChInPromotionEvent]);

    // 내정보 컴포넌트 공통화
    const [menu, setMenu] = useState({
        contents: {
            flag: true,
            contentsList: [
                {
                    title: '공지사항',
                    link: '/mypage/app-board',
                    arrowBtn: true
                },
                {
                    title: '고객센터',
                    link: '/cs',
                    arrowBtn: true
                },
                {
                    title: '약관 및 정책',
                    link: '/mypage/policy',
                    arrowBtn: true
                },
                {
                    title: '회사정보',
                    link: '/company',
                    arrowBtn: true
                },
                {
                    title: '웹 버전',
                    version: `WEB ${process.env.REACT_APP_VER}`,
                    arrowBtn: false
                }
            ]
        }
    });

    const kakaoLogout = async () => {
        try {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                        type: 'kakao/logout'
                    })
                );
            } else {
                if (!Kakao?.Auth?.getAccessToken()) {
                    return;
                }
                Kakao.Auth.logout(function () {
                    deleteCookie();
                });
            }
            // navigate("/member");
        } catch (err) {
            // console.log(err);
        }
    };

    const kakaoUnlink = async () => {
        try {
            Kakao?.API?.request({
                url: '/v1/user/unlink',
                success: function (res) {
                    // alert("success: " + JSON.stringify(res));
                    deleteCookie();
                    logout();
                },
                fail: function (err) {
                    // alert("fail: " + JSON.stringify(err));
                }
            });
            // navigate("/member");
        } catch (err) {
            // console.log(err);
        }
    };

    function deleteCookie() {
        document.cookie = 'authorize-access-token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    const [checkInData, setCheckInData] = useState(JSON.parse(localStorageUtil.get('smart-round')));
    const [kakaoMember, setKakaoMember] = useState(null);

    const kakaoFriendsPicker = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'kakao/friend'}));
        } else {
            Kakao?.Picker?.selectFriends({
                title: '동반자 초대',
                maxPickableCount: 10,
                minPickableCount: 1,
                showMyProfile: false,

                success: function (response) {
                    const users = response.users[0];
                    setKakaoMember({
                        friends: [
                            {
                                name: users.profile_nickname,
                                socialId: users.uuid,
                                inviteChannel: 'KAKAO'
                            }
                        ]
                    });
                },
                fail: function (error) {
                    console.log(error);
                }
            });
        }
    };

    useEffect(() => {
        if (kakaoMember) {
            fetchWrapper.post(`/booking/invite/`, kakaoMember).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    console.log('성공!!', response);
                } else {
                    console.log('실패!!', response.message);
                }
            });
        }
    }, [kakaoMember, checkInData?.bookingId]);

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    // 공지사항 체크
    const [isNewNotice, setIsNewNotice] = useState(false);

    useEffect(() => {
        fetchWrapper.get(`/notice/list?isNew=true`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                const data = response?.data;
                if (data.totalCount > 0) {
                    setIsNewNotice(true);
                } else {
                    setIsNewNotice(false);
                }
            }
        });
    }, []);

    // member api
    const getMemberInfo = useCallback(() => {
        fetchWrapper.get(`/member`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setMember(response.data ?? {});
            }
        });
    }, []);

    useEffect(() => {
        getMemberInfo();
    }, [getMemberInfo]);

    const moveToMyReservation = () => {
        // '예약정보 불러오기' 확인 페이지로 이동
        history.navigate('/get-my-reservation');

        viewOtherBookingDetails(rootTaxonomy.myinfo);
    };

    return (
        <>
            <Header fixed={false} pageTitle="내 정보" />
            <div id="contents" className="bgGray" style={{minHeight: `${height}px`}}>
                <div id="myPage">
                    <section>
                        {/*프로필*/}
                        <MyProfile memberInfo={member} />

                        {/*예약관리, 즐겨찾기 퀵메뉴*/}
                        <MyQuickMenu
                            bookingCount={bookingCount}
                            interestClubCount={interestClubCount}
                            member={member}
                        />
                        {/*광고*/}
                        <div className="advSection">
                            <Banner
                                type="06"
                                bannerTitle={bannerTitle}
                                src={banner_mypage}
                                radius={true}
                                onClick={moveToMyReservation}
                            />
                            {/* <img
                                src={getMyReservBanner}
                                alt="한 눈에 모아보는 내 예약정보"
                                onClick={moveToMyReservation}
                            /> */}
                            {/* <Link to="#">
                                <img src="/images/banner/Banner_03.png" alt="라운드 코스 가이드" />
                            </Link> */}
                        </div>

                        {/*마이페이지 메뉴*/}
                        <MyContentsSection menu={menu} isNewNotice={isNewNotice} />
                    </section>
                    <MyLogoutSection menu={menu} handleLogoutAlertOpen={handleLogoutAlertOpen} />
                    {/* 로그아웃 */}
                    <div className="sideBtnArea">
                        <button type="button" onClick={handleLogoutAlertOpen}>
                            로그아웃
                        </button>
                    </div>
                </div>
            </div>

            <Navigation />

            {/* 로그아웃 확인 Alert */}
            <Modal open={logoutAlertOpen}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div className="alertContents">로그아웃 하시겠습니까?</div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={handleLogoutAlertClose}>
                            취소
                        </button>
                        <button type="button" onClick={logout}>
                            로그아웃
                        </button>
                    </div>
                </div>
            </Modal>

            {/*웹 외부 접속 허용 대응 안내*/}
            <WebAccessInfoModal />
        </>
    );
}

export default MyPage;
