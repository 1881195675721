import Modal from '@mui/material/Modal';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchWrapper} from '../../_helpers';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';
import {clickInviteCompanionTry} from '../../_helpers/amplitude/events/click.event';
import AirbridgeUtil from '../../common/airbridgeUtil';
import {SUCCESS_CODE} from '../../common/constants';
import {appUtils, localStorageUtil} from '../../common/utils';
import DefaultAlert from '../alert/DefaultAlert';
import Button from '../button/Button';

const {Kakao} = window;

// 카카오톡 공유하기 안내모달에서 확인 버튼 클릭 시 카카오톡 공유
function KakaoAlert({open, onClose, successCallBack, bookingData, handleInviteClose, member, memberInfo, handleClose}) {
    const callback = () => {
        AirbridgeUtil.event.inviteCompanion('kakao');
        onClose();
    };

    const kakaoFriendsPicker = () => {
        fetchWrapper
            .get(`/amplitude-events/reservation-complete?bookingId=${bookingData?.id}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    clickInviteCompanionTry(
                        response.data,
                        'kakao',
                        'reservation_details',
                        getAmplitudeRootPath(),
                        bookingData?.roundPlayer
                    );
                }
            });

        // 카카오 공유하기
        fetchWrapper.get(`/booking/invite/init-data?golfClubId=${bookingData?.golfClubId}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                const backdropClick = document.querySelector('.MuiModal-backdrop');
                backdropClick.id = 'backdropClick';

                // 예약맴버ID 생성
                const data = response?.data;

                const userName = member?.name || memberInfo?.name;
                const golfClubName = data?.golfClubShortName;
                const courseName = bookingData?.courseName;
                const roundAt = appUtils.dateFormatTypeFull(bookingData?.roundAt);
                const bookingId = bookingData?.id;
                const golfClubImg = data?.golfClubImg;
                const bookingMemberId = data?.bookingMemberId;

                // 카카오 초대 안내 모달 backdrop 클릭 시 카카오 공유
                Kakao?.Share?.createCustomButton({
                    container: '#backdropClick',
                    templateId: Number(process.env.REACT_APP_KAKAO_INVITE_MESSAGE_ID),
                    templateArgs: {
                        ROUND_AT: roundAt,
                        USER_NAME: userName,
                        GOLF_CLUB_NAME: golfClubName,
                        WEB_PATH: `${process.env.REACT_APP_KAKAO_INVITE_PATH}?bookingId=${bookingId}&bookingMemberId=${bookingMemberId}`
                    },
                    serverCallbackArgs: {
                        bookingId: bookingId,
                        bookingMemberId: bookingMemberId
                    },
                    always: callback
                });

                // 카카오 초대 안내 모달 확인 클릭 시 카카오 공유
                Kakao?.Share?.createCustomButton({
                    container: '#btnKakaoInvite',
                    templateId: Number(process.env.REACT_APP_KAKAO_INVITE_MESSAGE_ID),
                    templateArgs: {
                        ROUND_AT: roundAt,
                        USER_NAME: userName,
                        GOLF_CLUB_NAME: golfClubName,
                        WEB_PATH: `${process.env.REACT_APP_KAKAO_INVITE_PATH}?bookingId=${bookingId}&bookingMemberId=${bookingMemberId}`
                    },
                    serverCallbackArgs: {
                        bookingId: bookingId,
                        bookingMemberId: bookingMemberId
                    },
                    always: callback
                });
                window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                        type: 'kakaoInvite',
                        bookingId,
                        bookingMemberId
                    })
                );
            }
        });
    };

    useEffect(() => {
        if (open) {
            kakaoFriendsPicker();
            handleInviteClose();
        }
    }, [open]);

    return (
        <>
            <Modal open={open} onClose={() => onClose()}>
                <div className="defaultAlert">
                    <div id="alertConWrap" style={{paddingBottom: 0}}>
                        <div id="alertTitle">동반자 초대는 한 번에 한 사람씩!</div>
                        <div className="alertContents">
                            <dl className="subContents">
                                <dt>• 카카오톡 친구를 초대할 때</dt>
                                <dd>한 사람씩 선택해서 진행!</dd>
                                <dt>• 단톡방에 초대링크를 공유할 때</dt>
                                <dd>처음 수락완료한 첫번째 사람만 초대가능!</dd>
                            </dl>
                        </div>
                        <img src={'/images/smart-round/kakaoAlertImg.png'} alt={'안내이미지'} />
                    </div>
                    <div className="alertBtnArea">
                        <button id="btnKakaoInvite" type="button">
                            확인
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

function PartnerInviteModal(props) {
    const member = useSelector((x) => x?.users?.users);
    const keyInFriend = useSelector((x) => x?.invite?.friend);
    const dispatch = useDispatch();

    // member api
    const [memberInfo, setMemberInfo] = useState({});

    // 동반자 초대방식 선택 팝업 props로 상태 받아오기
    const {InviteOpen, handleInviteClose, setInviteOpen, bookingData, setMemberReset} = props;
    // 동반자 초대 결과 안내 팝업 - 카카오톡에서 초대
    const [kakaoInviteResultOpen, setKakaoInviteResultOpen] = useState(false);
    // 재확인 팝업 - 직접 초대
    const [adrInviteCheckOpen, setAdrInviteCheckOpen] = useState(false);
    // 본인초대 / 중복초대 state
    const [inviteMe, setInviteMe] = useState(false);
    const [inviteOverlap, setInviteOverlap] = useState(false);
    const [openKakaoAlert, setOpenKakaoAlert] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);

    const handleAlertOpen = () => setAlertOpen(true);
    const handleAlertClose = () => setAlertOpen(false);

    // member api >> user 정보 가져오지 못했을때 Api 로 가져옴
    const getMemberInfo = useCallback(() => {
        fetchWrapper.get(`/member`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setMemberInfo(response.data ?? {});
            }
        });
    }, []);
    useEffect(() => {
        getMemberInfo();
    }, [getMemberInfo]);

    // 동반자 초대 모달창 > '동반자 초대' 버튼 클릭 시 open/close
    const handleAdrInviteResultOpen = () => {
        setInviteOpen(false); // 동반자 초대 모달창 close
        setAdrInviteCheckOpen(true); // 재확인 팝업 open
    };

    // 재확인 팝업 > '취소' 버튼 클릭 시 open/close
    const handleInviteCheckClose = () => {
        setAdrInviteCheckOpen(false); // 재확인 팝업 close
    };

    const handleKakaoInviteResultOpen = () => {
        setInviteOpen(false);
        setKakaoInviteResultOpen(true);
    };

    const handleKakaoInvitResultClose = () => setKakaoInviteResultOpen(false);

    const [friend, setFriend] = useState({
        name: '',
        phone: '',
        inviteChannel: ''
    });

    // 휴대폰에서 주소록 가져오기
    const [pickType, setPickType] = useState('');
    const pickFriends = (pickType) => {
        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'pickFriends',
                pickType: pickType
            })
        );
    };

    const sendTextMessage = (invite) => {
        const greenFee = invite?.greenFee.toLocaleString();
        const golfClubName = invite.golfClubShortName.replace('&', '＆');
        let message =
            `[${invite?.hostName}님의 라운드 초대]\n\n` +
            `- 일시: ${appUtils.dateFormatTypeRoundAt(invite?.roundAt)}\n` +
            `- 장소: ${golfClubName}\n\n` +
            `골라가 앱 회원만 초대를 수락할 수 있어요!\n` +
            `앱을 다운받고, 초대 수락 버튼을 눌러주세요\n\n` +
            `앱에서 초대 링크 수락하기\n` +
            `${invite?.inviteUrl}`;

        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'sendInviteMessage',
                phone: invite.phone,
                message: message
            })
        );

        AirbridgeUtil.event.inviteCompanion('system');
    };

    // 동반자 초대 모달창 > '동반자 초대' 버튼 클릭 시 실행
    const onSubmit = (data) => {
        // 본인 초대 체크
        // let myPhone = member?.cellPhone.replace(/-/g, '');
        // if (myPhone === watchPhone) {
        //     setInviteMe(true);
        //     setInviteOverlap(false); // 초기화
        //     setFocus('phone');
        //     return;
        // }

        // 중복 초대 체크
        // const isInviteOverlap = _.filter(bookingData?.bookingMemberList, {phone: watchPhone});
        // if (isInviteOverlap.length >= 1) {
        //     setInviteOverlap(true);
        //     setInviteMe(false); // 초기화
        //     setFocus('phone');
        //     return;
        // }

        // Avatar에 보낼 data 세팅
        setFriend({
            name: '수락대기',
            phone: '01000000000',
            inviteChannel: appUtils.isNotEmpty(pickType) ? pickType : 'KEYIN'
        });

        handleAdrInviteResultOpen(); // 동반자 초대 모달창 닫고, 재확인 알림창 오픈
    };

    // 재확인 팝업 > '초대문자 전송' 버튼 클릭 시 실행
    const sendInvitationMsg = () => {
        if (!window.ReactNativeWebView) {
            handleAlertOpen();
            return;
        }

        const phone = '010' + Math.floor(Math.random() * 99999999);
        let curUrl = sessionStorage.getItem('curUrl');
        let location;
        if (curUrl.includes('/mypage/booking')) {
            location = 'reservation_details';
        } else {
            location = 'smartround';
        }

        fetchWrapper
            .get(`/amplitude-events/reservation-complete?bookingId=${bookingData?.id}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    clickInviteCompanionTry(
                        response.data,
                        'contacts',
                        location,
                        getAmplitudeRootPath(),
                        bookingData?.roundPlayer
                    );
                }
            });

        setFriend({
            name: '수락대기',
            phone: phone,
            inviteChannel: appUtils.isNotEmpty(pickType) ? pickType : 'KEYIN'
        });
        const bookingId = bookingData?.id;

        fetchWrapper
            .post(`/booking/invite/` + bookingId, {
                friends: new Array({
                    name: '수락대기',
                    phone: phone,
                    inviteChannel: appUtils.isNotEmpty(pickType) ? pickType : 'KEYIN'
                })
            })
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    if (response.data?.length) {
                        let invite = response.data[0];

                        if ('KEYIN' === invite?.inviteChannel) {
                            sendTextMessage(invite);
                            setMemberReset(true); // 동반자 리스트 업데이트
                        } else if ('KAKAO' === pickType) {
                            handleKakaoInviteResultOpen();
                        }
                    }
                } else {
                    alert(`[${response.code}] ${response.message}`);
                }
            });
    };

    // KAKAO 친구 초대
    const [checkInData, setCheckInData] = useState(
        // 임시 bookingId
        JSON.parse(localStorageUtil.get('smart-round'))
    );
    const [kakaoMember, setKakaoMember] = useState(null);

    // 모달 뒤 backdrop 클릭했을때 모달창 닫기 이벤트
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            handleInviteClose(false);
        }
    };

    const kakaoAlert = sessionStorage.getItem('kakaoAlert');

    // 카카오 초대하기 안내 모달 최초 1회만 띄우기
    // 어플 종료 시 다시 나올 수 있게
    const handleKakaoAlert = () => {
        if (!kakaoAlert) {
            sessionStorage.setItem('kakaoAlert', true);
            setOpenKakaoAlert(true);
        } else if (kakaoAlert) {
            kakaoFriendsPicker();
        }
    };

    const callback = () => {
        AirbridgeUtil.event.inviteCompanion('kakao');
        handleInviteClose(false);
        handleClose();
    };

    // 카카오톡에서 친구 초대 버튼 클릭 시 카카오톡 공유
    const kakaoFriendsPicker = () => {
        if (kakaoAlert) {
            fetchWrapper
                .get(`/amplitude-events/reservation-complete?bookingId=${bookingData?.id}`, null)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        clickInviteCompanionTry(
                            response.data,
                            'kakao',
                            'reservation_details',
                            getAmplitudeRootPath(),
                            bookingData?.roundPlayer
                        );
                    }
                });

            // 카카오 공유하기
            fetchWrapper
                .get(`/booking/invite/init-data?golfClubId=${bookingData?.golfClubId}`, null)
                .then((response) => {
                    if (response?.code === SUCCESS_CODE) {
                        // 예약맴버ID 생성
                        const data = response?.data;

                        const userName = member?.name || memberInfo?.name;
                        const golfClubName = data?.golfClubShortName;
                        const roundAt = appUtils.dateFormatTypeFull(bookingData?.roundAt);
                        const bookingId = bookingData?.id;
                        const bookingMemberId = data?.bookingMemberId;

                        Kakao?.Share?.createCustomButton({
                            container: '#kakaoInvite',
                            templateId: Number(process.env.REACT_APP_KAKAO_INVITE_MESSAGE_ID),
                            templateArgs: {
                                ROUND_AT: roundAt,
                                USER_NAME: userName,
                                // GOLF_CLUB_IMG: golfClubImg,
                                GOLF_CLUB_NAME: golfClubName,
                                WEB_PATH: `${process.env.REACT_APP_KAKAO_INVITE_PATH}?bookingId=${bookingId}&bookingMemberId=${bookingMemberId}`
                            },
                            serverCallbackArgs: {
                                bookingId: bookingId,
                                bookingMemberId: bookingMemberId
                            },
                            always: callback
                        });
                        window.ReactNativeWebView?.postMessage(
                            JSON.stringify({
                                type: 'kakaoInvite',
                                bookingId,
                                bookingMemberId
                            })
                        );
                    }
                });
        }
    };

    useEffect(() => {
        if (InviteOpen) {
            kakaoFriendsPicker();
        }
    }, [InviteOpen]);

    return (
        <>
            {/* 동반자 초대방식 선택 팝업 */}
            <Modal open={InviteOpen} onClose={handleClose}>
                <div className="defaultModal">
                    <div className="modalContents">
                        <div id="modalTitle">
                            같이 라운드 할 동반자를 <br />
                            초대해 주세요
                            <button
                                type="button"
                                className="button btnClose invite"
                                onClick={() => {
                                    handleInviteClose(false);
                                }}
                            >
                                닫기
                            </button>
                        </div>
                        <div className="buttonWrapper">
                            <Button
                                id="kakaoInvite"
                                type="button"
                                background="#FEE500"
                                color="#212121"
                                className="kakao"
                                onClick={() => handleKakaoAlert()}
                            >
                                카카오톡에서 친구 초대
                            </Button>
                            <Button type="button" className="system" onClick={sendInvitationMsg}>
                                다른 수단으로 초대하기
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <KakaoAlert
                open={openKakaoAlert}
                onClose={() => setOpenKakaoAlert(false)}
                successCallBack={setMemberReset}
                bookingData={bookingData}
                handleInviteClose={handleInviteClose}
                member={member}
                memberInfo={memberInfo}
            />
            <DefaultAlert
                open={alertOpen}
                handleOpen={handleAlertOpen}
                handleClose={handleAlertClose}
                title={'미지원 디바이스'}
                contents={'공유하기 기능을 지원하지 않는 디바이스 입니다.'}
            />
        </>
    );
}

export default PartnerInviteModal;
