import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {createTheme} from '@mui/material/styles';

function FilterSpinner(props) {
    const {spinnerSize = 16, position = 'absolute'} = props;

    const themeBg = createTheme({
        palette: {
            primary: {
                main: '#fff'
            }
        }
    });
    return (
        <Box
            sx={{
                display: 'flex',
                marginLeft: '6px'
            }}
        >
            <CircularProgress size={spinnerSize} thickness={6} value={75} theme={themeBg} />
        </Box>
    );
}

export default FilterSpinner;
