import React, {useCallback, useEffect, useState} from 'react';
import DefaultAlert from '../../alert/DefaultAlert';
import TagNameModal from '../../modal/TagNameModal';
import BasicAlert from '../../alert/BasicAlert';
import CommonAlert from '../../alert/CommonAlert';
import {alertActions} from '../../../_store/alert.slice';
import {fetchWrapper, history} from '../../../_helpers';
import {CHECKIN_TIME_ONE_HOUR_CODE, SETTINGMESSAGE_CHECKIN, SUCCESS_CODE} from '../../../common/constants';
import {appUtils, localStorageUtil} from '../../../common/utils';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import CheckInContainer from './CheckInContainer';
import QrCheckIn from './QrCheckIn';
import {CANCELTMESSAGE, SETTINGMESSAGE} from '../../../common/constants';
import {clickGpsSelfCheckIn, clickInviteAtReservationComplete} from '../../../_helpers/amplitude/events/click.event';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import {completeGpsSelfCheckin, failSelfCheckinGps} from '../../../_helpers/amplitude/events/etc.event';
import AirbridgeUtil from '../../../common/airbridgeUtil';

function CheckIn({
    user,
    golfBagList,
    cartList,
    caddieList,
    checkInData,
    getCheckInData,
    setCheckInReady,
    setAssignAlert,
    assignAlertOpen,
    qrCheckIn,
    host,
    bookingMemberId,
    getCartData,
    getCaddieData,
    time,
    isActive,
    qrImg,
    clickResetTimer,
    setIsActive,
    setTime,
    _time,
    setQrCheckIn
}) {
    const dispatch = useDispatch();
    const alertData = useSelector((x) => x.alert);

    const member = useSelector((x) => x?.users?.users);

    const location = useLocation();
    const [roundData, setRoundData] = useState(location?.state?.roundData);

    const [selfCheckIn, setSelfCheckIn] = useState(false);
    const [tagNameCheck, setTagNameCheck] = useState(false);
    const [golfBag, setGolfBag] = useState(''); // 초기값은 유저 이름으로 지정
    const [alertContents, setAlertContents] = useState('');

    // 디데이 체크
    const [dDay, setDDay] = useState(false);
    const checkInDay = appUtils.dateFormatTypeDay(checkInData?.roundAt);

    // 카트 및 캐디 배정 Alert
    const assignAlertClose = () => setAssignAlert(false);

    // 셀프 체크인 Alert
    const handleSelfCheckInOpen = () => setSelfCheckIn(true);
    const handleSelfCheckInClose = () => setSelfCheckIn(false);

    //GPS 위성 정보(현재 좌표) 확인
    let locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));

    const [latitude, setLatitude] = useState(locationStorage?.lat || '');
    const [longitude, setLongitude] = useState(locationStorage?.lon || '');

    // 위치정보 동의 권한 확인
    const [gpsRequest, setGpsRequest] = useState(locationStorage?.gpsrequest || '');
    // 디바이스 체크
    const [device, setDevice] = useState(locationStorage?.device || '');

    useEffect(() => {
        // RN에서 웹으로 데이터를 전송했을때 message이벤트가 실행됩니다.
        document.addEventListener('message', (e) => {
            if (typeof e.data == 'object') {
                return;
            }
            setLatitude(JSON.parse(e.data)?.lat);
            setLongitude(JSON.parse(e.data)?.lon);
            setGpsRequest(JSON.parse(e.data)?.gpsrequest);
        });

        window.addEventListener('message', (e) => {
            if (typeof e.data == 'object') {
                return;
            }
            setLatitude(JSON.parse(e.data)?.lat);
            setLongitude(JSON.parse(e.data)?.lon);
            setGpsRequest(JSON.parse(e.data)?.gpsrequest);
        });
    }, []);

    // 좌표 구하기 (m)
    function getdistance() {
        // RN에서 받아온 현재 내 위치
        let lat1 = latitude;
        let lon1 = longitude;

        // 클럽하우스 기준 좌표
        let golfClubPoint = JSON.parse(localStorageUtil.get('checkInData'));
        let lat2 = golfClubPoint?.golfClubLatitude;
        let lon2 = golfClubPoint?.golfClubLongitude;

        // The math module contains a function
        // named toRadians which converts from
        // degrees to radians.
        lon1 = (lon1 * Math.PI) / 180;
        lon2 = (lon2 * Math.PI) / 180;
        lat1 = (lat1 * Math.PI) / 180;
        lat2 = (lat2 * Math.PI) / 180;
        // Haversine formula
        let dlon = lon2 - lon1;
        let dlat = lat2 - lat1;
        let a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
        let c = 2 * Math.asin(Math.sqrt(a));
        let r = 6371e3;
        // calculate the result
        return c * r;
    }

    // 남은 거리
    let distance = getdistance();

    let userEmail = member?.email;
    let isTestEmail = userEmail && (userEmail.includes('@test.com') || userEmail.includes('@greenit.cc'));

    // 체크인 클릭 시  Alert 조건설정
    const tagNameCheckOpen = () => {
        let roundAtStr = checkInData?.roundAt;
        if (!roundAtStr) return;

        // 위치정보 동의 권한 확인
        if (device === 'android' && gpsRequest === 1) {
            dispatch(
                alertActions.showAlert({
                    type: CANCELTMESSAGE,
                    id: 'checkInLocation',
                    messageData: {
                        title: '앱 권한 요청',
                        message: '셀프 체크인 사용을 위해 위치 권한을 허용해주세요. (필수권한)'
                    }
                })
            );
            return;
        } else if (device === 'android' && gpsRequest === 2) {
            dispatch(
                alertActions.showAlert({
                    type: SETTINGMESSAGE,
                    id: 'checkInLocation',
                    messageData: {
                        title: '앱 권한 설정 이동',
                        message:
                            '셀프 체크인 사용을 위해 디바이스 > 설정 > 앱 권한에서 위치 권한을 허용해주세요. (필수권한)'
                    }
                })
            );

            return;
        } else if (device === 'ios' && gpsRequest === 1) {
            dispatch(
                alertActions.showAlert({
                    type: SETTINGMESSAGE_CHECKIN,
                    id: 'checkInLocation',
                    messageData: {
                        title: '앱 권한 설정 이동',
                        message:
                            '셀프 체크인 사용을 위해 디바이스 > 설정 > 골라가 > 위치에서 권한을 허용해주세요. (필수권한)'
                    }
                })
            );

            return;
        }

        /*
         * 스마트라운드 미제공 골프장 대응 > 팝업 노출
         * 1. 스마트라운드가 false인 경우
         * 2. 스마트라운드가 true지만 v1인 경우 (v1:셀프체크인 미제공)
         * */
        const isSmartRoundCheck = checkInData?.isSmartRoundApplied;
        const isGolfClubVersion = checkInData?.apiVersion;
        if (!isSmartRoundCheck || (isSmartRoundCheck && isGolfClubVersion === 'v1')) {
            handleSelfCheckInOpen();
            setAlertContents(
                `해당 골프장은 셀프 체크인 서비스를\n 지원하지 않습니다.\n 추후 이용 가능 하도록 준비하겠습니다.`
            );

            fetchWrapper
                .get(`/amplitude-events/reservation-complete?bookingId=${roundData.bookingId}`, null)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        failSelfCheckinGps(response.data, getAmplitudeRootPath());
                    }
                });

            return;
        }

        if (!appUtils.isToday(roundAtStr)) {
            handleSelfCheckInOpen();
            setAlertContents(`셀프체크인은 당일에만 할 수 있습니다.\n${checkInDay}에 다시 시도해주세요.`);

            fetchWrapper
                .get(`/amplitude-events/reservation-complete?bookingId=${roundData.bookingId}`, null)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        failSelfCheckinGps(response.data, getAmplitudeRootPath());
                    }
                });
            return;
        }

        selfCheckInCheckAlert(); // 셀프체크인 가능여부 체크

        fetchWrapper
            .get(`/amplitude-events/view-gps-self-checkin?bookingId=${roundData.bookingId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    clickGpsSelfCheckIn(response.data, getAmplitudeRootPath());
                }
            });
    };

    const tagNameCheckClose = () => setTagNameCheck(false);

    // 셀프체크인 가능 여부 체크 > 불가 시 Alert 노출
    const selfCheckInCheckAlert = useCallback(() => {
        fetchWrapper
            .get(
                `/booking/checkin/possibility?bookingId=${roundData?.bookingId}&checkinType=CHECKIN&userLat=${latitude}&userLon=${longitude}`
                // `/booking/checkin/possibility?bookingId=${roundData?.bookingId}&checkinType=CHECKIN`
            )
            .then((response) => {
                if (response?.code === SUCCESS_CODE || response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                    setTagNameCheck(true);
                } else {
                    // 셀프체크인 Alert Open
                    handleSelfCheckInOpen();
                    setAlertContents(response?.message);

                    fetchWrapper
                        .get(`/amplitude-events/reservation-complete?bookingId=${roundData.bookingId}`, null)
                        .then((response) => {
                            if (response.code === SUCCESS_CODE) {
                                failSelfCheckinGps(response.data, getAmplitudeRootPath());
                            }
                        });
                }
            });
    }, [roundData]);

    // 골프백 네임 입력 (useCallback 사용 금지!)
    const selfCheckInProcess = () => {
        const payload = {
            tagName: golfBag
        };
        if (!payload.tagName) {
            payload.tagName = golfBagList ? golfBagList[0]?.tagName : member?.name;
        }

        fetchWrapper.post(`/booking/${roundData?.bookingId}/process`, payload).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setCheckInReady(true);

                // 티타임 경과 1시간 이내일 시 > 체크인 완료 후 Alert 노출
                fetchWrapper
                    .get(
                        `/booking/checkin/possibility?bookingId=${roundData?.bookingId}&checkinType=CHECKIN&userLat=${latitude}&userLon=${longitude}`
                        // `/booking/checkin/possibility?bookingId=${roundData?.bookingId}&checkinType=CHECKIN`
                    )
                    .then((response) => {
                        if (response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                            // 셀프체크인 Alert Open
                            handleSelfCheckInOpen();
                            setAlertContents(response?.message);
                        }
                    });

                fetchWrapper
                    .get(`/amplitude-events/reservation-complete?bookingId=${roundData.bookingId}`, null)
                    .then((response) => {
                        if (response.code === SUCCESS_CODE) {
                            completeGpsSelfCheckin(response.data, getAmplitudeRootPath());
                        }
                    });
                AirbridgeUtil.event.completeSelfCheckIn();
            }
        });
    };

    // 새로고침 rotate 이벤트 임시 처리
    const [isRefreshing, setIsRefreshing] = useState(false);

    // CMS에 체크인 정보를 물어보는 함수 (내방해야 내려주는 정보는 임의로 세팅)
    const callCMSCheckedInData = useCallback(() => {
        if (roundData?.bookingId && bookingMemberId) {
            fetchWrapper
                .get(`/booking/${roundData?.bookingId}/checkin/check/${bookingMemberId}`, null)
                .then((response) => {
                    console.log('callCMSCheckedInData -->', response);
                });
        }
    }, [roundData?.bookingId, bookingMemberId]);

    // '5분 전' 리셋버튼 이벤트
    const onResetBtn = () => {
        callCMSCheckedInData();
        getCheckInData();
        assignAlertOpen();
        getCartData();
        getCaddieData();

        // 새로고침 rotate 이벤트 임시 처리
        setIsRefreshing(true);

        setTimeout(() => setIsRefreshing(false), 1000);
    };

    // 날짜 형식 변경
    const diffDay = useCallback(() => {
        if (!checkInData?.roundAt) {
            setDDay(false);
            return;
        }
        let bookingDate = checkInData?.roundAt;
        let diff = appUtils.calcDDay(bookingDate);
        if (diff === 0) {
            setDDay(true);
        } else {
            setDDay(false);
        }
    }, [checkInData]);

    useEffect(() => {
        diffDay();
    }, [diffDay]);

    // 위치정보 동의 권한 확인 > 설정 페이지로 넘겨주는 콜백
    const goSettingLocation = useCallback(async () => {
        if (gpsRequest === 1) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    gpsreturn: 'Rerequest'
                })
            );
        } else if (gpsRequest === 2) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    gpsreturn: 'goSetting'
                })
            );
        }
    }, [gpsRequest]);

    return (
        <>
            <div className={`flipArea ${qrCheckIn ? 'qrOn' : ''}`}>
                <div className="card">
                    {/* GPS 기반 */}
                    <div className={checkInData && user?.checkinAt ? 'smCard front' : 'smCard inBg front'}>
                        <CheckInContainer
                            distance={distance}
                            checkInData={checkInData}
                            onResetBtn={onResetBtn}
                            isRefreshing={isRefreshing}
                            user={user}
                            golfBagList={golfBagList}
                            cartList={cartList}
                            caddieList={caddieList}
                            tagNameCheckOpen={tagNameCheckOpen}
                            host={host}
                            roundData={roundData}
                        />
                    </div>
                    {/* QR 체크인 */}
                    <QrCheckIn
                        checkInData={checkInData}
                        dDay={dDay}
                        roundData={roundData}
                        isActive={isActive}
                        time={time}
                        clickResetTimer={clickResetTimer}
                        qrCheckIn={qrCheckIn}
                        qrImg={qrImg}
                        setIsActive={setIsActive}
                        setTime={setTime}
                        _time={_time}
                        setQrCheckIn={setQrCheckIn}
                    />
                </div>
            </div>

            {/* 셀프 체크인 Alert */}
            <DefaultAlert
                open={selfCheckIn}
                handleClose={handleSelfCheckInClose}
                contents={alertContents}
                //contents={"골프장 반경 1km 이내일 때 진행 가능합니다. 골프장에 도착할 때 즈음 다시 시도해보세요."}
            />

            {/* 골프백 테그네임 Alert, Modal */}
            <TagNameModal
                alertOpen={tagNameCheck}
                setAlertOpen={setTagNameCheck}
                alertClose={tagNameCheckClose}
                selfCheckInProcess={selfCheckInProcess}
                setGolfBag={setGolfBag}
                golfBag={golfBag}
                golfBagList={golfBagList}
                assignAlertOpen={assignAlertOpen}
            />

            {/* 카트 및 캐디 배정 완료 Alert */}
            <BasicAlert
                title="카트 및 캐디가 배정되었어요!"
                contents="카트와 캐디 정보 확인하시고 이용해주시기 바랍니다."
                open={false} // 배너 임시 미노출 처리
                handleClose={assignAlertClose}
            />

            {/* 위치정보 동의 권한 확인 Alert */}
            {(alertData?.open && alertData?.id === 'checkInLocation' && (
                <CommonAlert id={alertData?.id} successCallBack={goSettingLocation} />
            )) ||
                ''}
        </>
    );
}

export default React.memo(CheckIn);
