import React from 'react';

function SearchGuideBelow({}) {
    return (
        <>
            <div className="contentsTitle">
                <div className="inner">
                    <div className="title">이렇게 검색해 보세요</div>
                </div>
            </div>
            <div className="filterScrollArea">
                <div className="inner">
                    <div className="searchGuide">
                        <div className="tit">도로명 + 건물번호</div>
                        <div className="ex">예) 한강대로 98길 3</div>
                    </div>
                    <div className="searchGuide">
                        <div className="tit">지역명 + 번지</div>
                        <div className="ex">예) 갈월동 7-50</div>
                    </div>
                    <div className="searchGuide">
                        <div className="tit">지역명 + 건물명</div>
                        <div className="ex">예) 갈월동 KCC IT빌딩</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchGuideBelow;