export const url = {
    home: "/",
    main: "/main",
};

export const apiUrl = {
    toss: {
        cardList: "/toss/cards",
        cardRemove: "/toss/cards/remove",
        selected: "/toss/cards/selected",
    },
};