import React, {useEffect} from 'react';
import CheckInTop from './CheckInTop';
import CheckInAfter from './CheckInAfter';
import CheckInBefore from './CheckInBefore';
import CheckInBottom from './CheckInBottom';
import {fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import {viewGpsSelfCheckIn} from '../../../_helpers/amplitude/events/view.events';

function CheckInContainer({
    distance,
    checkInData,
    onResetBtn,
    isRefreshing,
    user,
    golfBagList,
    cartList,
    caddieList,
    tagNameCheckOpen,
    host,
    qrReset,
    roundData
}) {
    useEffect(() => {
        fetchWrapper
            .get(`/amplitude-events/view-gps-self-checkin?bookingId=${roundData.bookingId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    viewGpsSelfCheckIn(response.data, getAmplitudeRootPath());
                }
            });
    }, [roundData]);

    return (
        <div className="detailArea">
            <CheckInTop checkInData={checkInData} onResetBtn={onResetBtn} isRefreshing={isRefreshing} user={user} />
            {/* 체크인한 화면 : 체크인 여부에 의존 */}
            {checkInData && user?.checkinAt ? (
                <CheckInAfter
                    user={user}
                    golfBagList={golfBagList}
                    cartList={cartList}
                    caddieList={caddieList}
                    checkInData={checkInData}
                    host={host}
                />
            ) : (
                // 체크인 대기 화면
                <CheckInBefore distance={distance} checkInData={checkInData} tagNameCheckOpen={tagNameCheckOpen} />
            )}
        </div>
    );
}

export default React.memo(CheckInContainer);
