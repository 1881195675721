import React, { useState } from "react";
import "./download.scss";
import DefaultAlert from "../../components/alert/DefaultAlert";

function DownloadInfo() {
    // iOS 디바이스 체크
    const iOS =
        typeof navigator !== "undefined" &&
        /iPad|iPhone|iPod/.test(navigator.userAgent);

    // apk 다운로드 링크
    const downloadLink = () => {
        window.location.href = "https://golaga.golf/aos/download";
    };

    // iOS 서비스 안내 Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const handleAlertOpen = () => {
        setAlertOpen(true);
    };
    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    return (
        <>
            <div id="downloadInfo">
                <div className="logoArea">
                    <div className="flexCon">
                        <div className="imgArea">
                            <img
                                src="/images/download/app_icon.png"
                                alt="logo"
                            />
                        </div>
                        <div className="txtArea">
                            <span>골프 라운드 가자!</span>
                            <h1>golaga</h1>
                        </div>
                    </div>
                </div>
                <div className="section cautionSection">
                    <strong className="tt">안내사항</strong>
                    <p>
                        골라가 앱은 현재 테스트 진행중입니다.
                        <br />
                        하단에 있는 다운로드 버튼을 눌러 다운로드 받은 apk파일을
                        통해서만 앱을 설치하실 수 있습니다.
                    </p>
                    <p>* 아이폰 앱은 추후 지원예정입니다.</p>
                    <div className="btnArea">
                        <button
                            type="button"
                            className="button btnPrimary btnDownload"
                            onClick={iOS ? handleAlertOpen : downloadLink}
                        >
                            앱 다운로드
                        </button>
                    </div>
                </div>
                <div className="downloadDetail">
                    <h2>
                        골라가 앱 설치방법 <span>apk 파일</span>
                    </h2>
                    <div className="section">
                        <span className="step">STEP 01</span>
                        <p>
                            현재 페이지 상단에 있는 <br />
                            <strong>앱 다운로드 버튼</strong>을 누른다
                        </p>
                    </div>
                    <div className="section">
                        <span className="step">STEP 02</span>
                        <p>
                            앱 설치 경고 팝업창에서 <strong>다운로드</strong>를
                            눌러 <br />
                            apk파일을 다운로드한다
                        </p>
                        <img src="/images/download/step_02.png" alt="STEP 02" />
                    </div>
                    <div className="section">
                        <span className="step">STEP 03</span>
                        <p>
                            내 파일에 들어가서 <br />
                            <strong>APK 설치파일</strong>을 누른다
                        </p>
                        <img src="/images/download/step_03.png" alt="STEP 03" />
                    </div>
                    <div className="section">
                        <span className="step">STEP 04</span>
                        <p>
                            목록에서 <strong>app_golaga.apk 파일</strong>을{" "}
                            <br />
                            선택하고, 설치를 눌러 앱 설치를 진행한다
                        </p>
                        <span>
                            ※ ‘내 파일’에 대한 설정이 안 된 경우, 설정에서{" "}
                            <br />
                            ‘내 파일’을 눌러 앱 설치를 허용해주세요
                        </span>
                        <img src="/images/download/step_04.png" alt="STEP 04" />
                    </div>
                    <div className="section">
                        <span className="step">STEP 05</span>
                        <p>
                            출처를 알 수 없는 앱 설치 팝업창에서 <br />
                            <strong>무시하고 설치</strong>를 선택한다.
                        </p>
                        <img src="/images/download/step_05.png" alt="STEP 05" />
                    </div>
                    <div className="section">
                        <span className="step">STEP 06</span>
                        <p>
                            Play 프로텍트 팝업창에서 <br />
                            <strong>무시하고 설치</strong>를 선택한다
                        </p>
                        <img src="/images/download/step_06.png" alt="STEP 06" />
                    </div>
                    <div className="section">
                        <span className="step">STEP 07</span>
                        <p>
                            설치 완료한 앱을 열고 <br />
                            골라가 서비스를 이용한다
                        </p>
                        <img src="/images/download/step_07.png" alt="STEP 07" />
                    </div>
                </div>
                <div className="copyright">
                    Copyright© GREENIT CO., LTD. All rights reserved.
                </div>
            </div>

            {/* iOS 서비스 안내 Alert */}
            <DefaultAlert
                open={alertOpen}
                handleClose={handleAlertClose}
                title={"서비스 안내"}
                contents={"아이폰 앱은 추후 지원예정입니다."}
            />
        </>
    );
}

export default DownloadInfo;
