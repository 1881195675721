import React from "react";

function PersonalInfoKTF() {
    return (
        <>
            <section>
                <h2>&lt;케이티 귀중&gt;</h2>
                <p>
                    (주)케이티(이하 ‘회사’라 함)가 제공하는 휴대폰
                    본인확인서비스를 이용하기 위해, 다음과 같이 ‘회사’가 본인의
                    개인정보를 수집 및 이용하고, 개인정보의 취급을 위탁하는 것에
                    동의합니다.
                </p>
                <br />
                <h3>1. 수집항목</h3>
                <p>
                    - 이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                    생년월일, 내/외국인 구분
                    <br />
                    - 연계정보(CI), 중복가입확인정보(DI)
                    <br />- 이용자가 본인확인을 요청한 서비스명 및 URL정보,
                    본인확인이용일시, IP주소
                </p>
                <br />
                <h3>2. 이용목적</h3>
                <p>
                    <strong className="underLine">
                        - 인터넷사업자의 서비스(회원가입, ID/PW찾기 등) 이용에
                        필요한 이용자 본인확인 여부 및 정보 전달 (※ 이용자가
                        본인확인을 요청한 인터넷사업자에 한합니다.)
                        <br />
                        - (주)케이티 등 이동통신사에 이용자 정보를 전송하여
                        본인확인 및 휴대폰 정보의 일치 여부 확인
                        <br />
                        - 휴대폰 사용자 확인을 위한 SMS 인증번호 전송
                        <br />
                        - 부정 이용 방지
                        <br />
                        - 이용자 본인 요청에 의한 본인확인 이력정보 제공
                        <br />
                        - 휴대폰번호보호서비스 가입여부 확인(서비스 가입자에
                        한함)
                        <br />- 기타 법률에서 정한 목적
                    </strong>
                </p>
                <br />
                <h3>3. 개인정보 및 이용기간 보유</h3>
                <p>
                    <strong className="underLine">
                        ‘회사’는 이용자의 개인정보를 이용목적이 달성되거나 보유
                        및 보존기간이 종료하면 해당 정보를 지체 없이 파기하며
                        별도의 보관을 하지 않습니다. 단, 아래의 경우는
                        제외합니다.
                    </strong>
                    <br />- 법령에서 정하는 경우 해당 기간까지 보유 (상세 사항은
                    회사의 개인정보처리방침에 기재된 바에 따름)
                </p>
                <br />
                <h3>4. 본인확인서비스 제공을 위한 개인정보의 취급 위탁</h3>
                <p>
                    <strong className="underLine">
                        - 수탁자 : SCI평가정보(주)
                    </strong>
                    <br />- 취급위탁 업무 : 본인확인정보의 정확성 여부 확인,
                    연계정보(CI)/중복가입확인정보(DI) 전송, 서비스 관련 상담 및
                    불만 처리 등
                </p>
                <br />
                <h3>
                    5. 상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않으실
                    경우, 서비스를 이용하실 수 없습니다.
                </h3>
                <p>
                    * ‘회사’가 제공하는 서비스의 개인정보 취급과 관련된 사항은,
                    아래의 홈페이지에 기재된 개인정보취급방침에 따릅니다.
                    (www.kt.com)
                    <br />
                    <br />
                    본인은 위 내용을 숙지하였으며 이에 동의합니다.
                </p>
            </section>
        </>
    );
}

export default PersonalInfoKTF;
