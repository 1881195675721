import React, {forwardRef, useCallback, useEffect, useRef} from 'react';
import SearchResultBelowItem from './SearchResultBelowItem';

const SearchResultBelow = forwardRef(({placeList, onClickButton, hasNext, setPage, currentPage}, ref) => {
    const scrollRef = useRef(null);
    let scrollDebounce = null;

    const onScroll = useCallback((e) => {
        if (scrollDebounce) {
            clearTimeout(scrollDebounce);
        }

        scrollDebounce = setTimeout(() => {
            const scrollViewPort = e.target;
            const ceil = Math.ceil(scrollViewPort.scrollHeight - scrollViewPort.clientHeight);
            const scrollTopCeil = Math.ceil(scrollViewPort.scrollTop);
            if (ceil <= scrollTopCeil) {
                if (hasNext.current) {
                    currentPage += 1;
                    setPage(currentPage);
                }
            }
        }, 500);
    }, []);

    return (
        <div className="filterScrollArea" ref={scrollRef} onScroll={onScroll}>
            <div className="inner">
                <ul className="searchResult">
                    {placeList.length !== 0 ? (
                        <>
                            {placeList.map((placeListItem, idx) => (
                                <SearchResultBelowItem
                                    placeListItem={placeListItem}
                                    key={idx}
                                    onClickButton={onClickButton}
                                />
                            ))}
                        </>
                    ) : (
                        <li className="nodata">검색 결과 없음</li>
                    )}
                </ul>
            </div>
        </div>
    );
});

export default SearchResultBelow;
