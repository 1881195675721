import React, {useState} from 'react';

import SelectItem from './SelectItem';
import classNames from 'classnames';

function SelectItemList({data, selectCategoryItem, isFilterReset, filterFlag}) {
    return (
        <div className="filterWrap">
            <div className="title">{data?.filterCodeName}</div>
            <ul className="btnWrap">
                {data
                    ? data.searchFilters.map((item) => {
                          return (
                              <SelectItem
                                  filterCodeName={data?.filterCodeName}
                                  filterCode={data.filterCode}
                                  selectCategoryItem={selectCategoryItem}
                                  item={item}
                                  key={item.codeId}
                                  isFilterReset={isFilterReset}
                                  filterFlag={filterFlag}
                              />
                          );
                      })
                    : ''}
            </ul>
        </div>
    );
}

export default SelectItemList;
