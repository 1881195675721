import React from "react";
import TitleArea from "../../../components/common/TitleArea";
import Header from "../../../components/Header";
import JoinServiceAgree from "../member/JoinServiceAgree";

function ServiceAgreePop({ title }) {
    return (
        <>
            <Header close={true} closeWindow={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="agreeCon">
                            <JoinServiceAgree />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceAgreePop;
