import React from 'react';
import {useDispatch} from 'react-redux';
import {checkinActions} from '../../_store/checkin.slice';
import {history} from '../../_helpers';
import {localStorageUtil} from '../../common/utils';
import Icon from '../icons/Icon';

function Home() {
    const dispatch = useDispatch();

    const goToHome = () => {
        hideCheckin();
        localStorageUtil.remove('checkInData');
        localStorageUtil.remove('roundData');
        history.navigate('/main');
    };

    const hideCheckin = () => {
        dispatch(checkinActions.showSmartRoundCheckIn(false));
    };

    return (
        <>
            <button className="btnHome" onClick={goToHome}>
                {/*Home*/}
                <Icon name="home-white" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" />
            </button>
        </>
    );
}

export default Home;
