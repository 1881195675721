import React from 'react';
import {appUtils} from '../../../common/utils';
import moment from 'moment';

function FieldBookingCancelPeriod({cancelPossibility, cancelPossiblePeriod}) {
    return (
        <>
            {/* 취소 가능 기한 컴포넌트 분리해야됨 (경과 분기도 추가해야됨 )*/}
            <div className="cancelDeadLineWrap">
                <strong className={` ${cancelPossibility === false && 'cancelDeadLineErrorMsg'}`}>
                    취소 가능 기한{' '}
                    <b className={`${(cancelPossibility === true || cancelPossiblePeriod === null) && 'hide'}`}>
                        (경과)
                    </b>
                </strong>
                <p className={`${cancelPossibility === false && 'cancelDeadLineErrorMsg'}`}>
                    {moment(cancelPossiblePeriod, moment.ISO_8601, true).isValid() === true
                        ? appUtils.dateFormatTypeFull(cancelPossiblePeriod)
                        : cancelPossiblePeriod}
                    <span className={`${(cancelPossibility === true || cancelPossiblePeriod === null) && 'hide'}`}>
                        (취소 시 위약금&패널티 발생)
                    </span>
                </p>
            </div>
        </>
    );
}

export default FieldBookingCancelPeriod;
