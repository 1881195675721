import React, {useState, useEffect, useCallback, useContext} from 'react';
import Icon from '../../icons/Icon';
import Drawer from '@mui/material/Drawer';
import TeaTime from '../OutdoorDetail/TeaTime';
import {history, fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import {appUtils} from '../../../common/utils';
import {FieldMainContext} from '../../../_store/context/FieldMainContext';
import {FieldMainContext_searchResult} from '../../../_store/context/FieldMainContext_SearchResult';
import {FieldMainContext_myPage} from '../../../_store/context/FieldMainContext_myPage';

function TeaTimeDrawer({teaTimeOpen, setTeaTimeOpen, golfClubId, searchDate, contextFlag}) {
    const {region, teeTime, playerCnt, holeCnt, greenFee, paymentType} = useContext(
        contextFlag === 'main'
            ? FieldMainContext
            : contextFlag === 'search-result'
            ? FieldMainContext_searchResult
            : FieldMainContext_myPage
    );

    const [teaTimeData, setTeaTimeData] = useState(null);
    const [isPartner, setIsPartner] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    /*   const getTeaTimeData = useCallback(
         (golfClubId, selectDate) => {
             fetchWrapper
                 .get(`/booking/pre/timesheet/${golfClubId}?startDate=${appUtils.dateFormatTypeDash(selectDate)}`, null)
                 .then((response) => {
                     if (response?.code === SUCCESS_CODE) {
                         setTeaTimeData(response?.data || null);
                         setIsLoading(false);
                     }
                 });
         },
         [searchDate]
     );*/
    /*
     *      2023-05-11 티타임 Drawer 팝업 변경 by brown
     *      1. 기본 필터 적용 데이터에 맞게 티타임을 내려주어야함
     *      2. 티타임 뿌려주는곳
     *         - 골프장 상세페이지 -> FieldTeaTime
     *           전체 티타임 조회
     *           payload = [날짜(roundDate) / 골프클럽id(golfClubId)]
     *         - 티타임 Drawer -> TeaTimeDrawer (이 부분 수정)
     *           설정된 필터 값에 해당하는 티타임 보여주기
     *           payload = [날짜(roundDate) / 골프클럽id(golfClubId) / 지역 필터 설정값(address) / 티타임 필터 설정값(teeTimeCode)
     *            / 홀 수 필터 설정값(hole) /  그린피 필터 설정값(greenFeeCode)
     *      3. 백엔드 변경하면 아래 주석 풀면 됨
     * */
    const getTeaTimeData = useCallback(
        (golfClubId, selectDate) => {
            /*console.log('selectDate ', selectDate);
            console.log('region', region);*/
            const payload = {
                golfClubId: `${golfClubId}`,
                roundDate: appUtils.dateFormatTypeDash(selectDate),
                address: appUtils.filterObjToArr(region, 0),
                teeTimeCode: appUtils.filterObjToArr(teeTime, 0),
                minimumRoundPlayer: appUtils.filterObjToArr(playerCnt, 0),
                hole: appUtils.filterObjToArr(holeCnt, 0),
                greenFeeCode: appUtils.filterObjToArr(greenFee, 0),
                /* 2023.07.07 결제방식 추가 */
                paymentType: appUtils.filterObjToArr(paymentType, 0)
            };

            /*console.log(payload);*/

            fetchWrapper.post(`/booking/pre/timesheet/`, payload).then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    setTeaTimeData(response?.data || null);
                    setIsLoading(false);
                }
            });
        },
        [region, teeTime, playerCnt, holeCnt, greenFee, paymentType]
    );

    const getIsPartner = useCallback(
        (golfClubId) => {
            fetchWrapper.get(`/golfClub/partner/request/${golfClubId}`, null).then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    setIsPartner(response?.data.isPartner);
                }
            });
        },
        [isPartner]
    );

    useEffect(() => {
        if (teaTimeOpen) {
            getTeaTimeData(golfClubId, searchDate);
            getIsPartner(golfClubId);
        }
    }, [teaTimeOpen]);

    const onClickTeaTimeDetail = () => {
        history.navigate(`/field-detail/${golfClubId}`, {state: {flag: contextFlag}});
    };

    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                if (teaTimeOpen === true) {
                    teaTimeClose();
                }
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);
        return () => {
            document.removeEventListener('message', handleEvent);
        };
    }, [teaTimeOpen]);

    const teaTimeClose = () => {
        setTeaTimeOpen(false);
        setTimeout(() => {
            setTeaTimeData(null);
            setIsLoading(true);
            setIsPartner(false);
        }, 500);
    };

    return (
        <Drawer anchor="bottom" open={teaTimeOpen} onClose={teaTimeClose} className="teaTimeDrawer">
            <div className="drawer-content">
                <div className="drawerTitle">
                    <div className="ttArea" onClick={onClickTeaTimeDetail}>
                        <strong className="tt">{teaTimeData?.golfClubShortName}</strong>
                        <span className="date">{appUtils.dateFormatTypeBooking(teaTimeData?.searchDate)}</span>
                    </div>
                    <button type="button" className="btnClose" onClick={teaTimeClose}>
                        <Icon name="close02" width="24" height="24" viewBox="0 0 24 24" />
                    </button>
                </div>
                <div className="drawerContents">
                    <TeaTime
                        teaTimeData={teaTimeData?.timeSheetTables}
                        teaTimeCount={teaTimeData?.timeSheetCount}
                        golfClubId={golfClubId}
                        golfClubName={teaTimeData?.golfClubName}
                        isPartner={isPartner}
                        isLoading={isLoading}
                        teeTime={teeTime}
                        isDrawer={true}
                        flag="teaTime-drawer"
                    />
                </div>
            </div>
        </Drawer>
    );
}

export default TeaTimeDrawer;
