import React, {useRef, useState, useCallback, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import TitleArea from '../../../common/TitleArea';
import Checkbox from '../../../button/Checkbox';
import {fetchWrapper} from '../../../../_helpers';
import {SUCCESS_CODE} from '../../../../common/constants';
import {viewCancelPolicy, viewModalCancel} from '../../../../_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '../../../../_helpers/amplitude/amplitude.helper';

function CancelRegulationLayer({handleOpen, handleClose, contents, bookingCancelReasonOpen, bookingData}) {
    // 스크롤 이벤트
    const scrollRef = useRef(null);
    const [isTitleVisible, setIsTitleVisible] = useState(false);

    useEffect(() => {
        if (handleOpen === true) {
            fetchWrapper
                .get(`/amplitude-events/view-modal-cancel?bookingId=${bookingData?.id}`, null)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        viewCancelPolicy(response.data, getAmplitudeRootPath());
                    }
                });
        }
    }, [handleOpen]);

    const onScroll = useCallback((evt) => {
        const div = evt.target;

        const currentScrollPosition = div.scrollTop;

        if (currentScrollPosition > 0) {
            setIsTitleVisible(true);
        } else {
            setIsTitleVisible(false);
        }
    }, []);

    // 동의 체크 버튼 활성화
    const [activeButton, setActiveButton] = useState(false);
    const onChecked = (state) => {
        setActiveButton(state);
    };

    // 레이어 닫기
    const layerClose = () => {
        handleClose();
        setTimeout(() => {
            setIsTitleVisible(false);
            setActiveButton(false);
        }, [100]);
    };

    // 다음 버튼
    const nextStep = () => {
        layerClose();
        bookingCancelReasonOpen();
    };

    return (
        <Dialog fullScreen open={handleOpen} onClose={handleClose} transitionDuration={0} className="fullPageModal">
            <div className="layerPop">
                <div className={`layerHeader2 ${isTitleVisible ? 'border' : ''}`}>
                    <div className={`layerHeader_title ${isTitleVisible ? 'visible' : ''}`}>취소 접수</div>
                    <button type="button" className="btnClose" onClick={layerClose}>
                        닫기
                    </button>
                </div>
                <div className="layerContents3" ref={scrollRef} onScroll={onScroll}>
                    <div id="agreeContents" className="inner">
                        <div>
                            <TitleArea pageTitle={'취소 접수 전, \n 규정 및 유의사항을 확인해주세요'} />
                            <div className="agreeCon">
                                <section dangerouslySetInnerHTML={{__html: contents}}></section>
                                <section>
                                    <h2>유의사항</h2>
                                    <ul className="listType04">
                                        <li>
                                            취소가능기한을 경과한 예약 건을 취소 시, 취소 ‘접수’ 를 요청하실 수 있으며,
                                            이는 즉시 취소가 완료된 것이 아닙니다.
                                        </li>
                                        <li>
                                            취소 접수가 가능한 시간은 09:00~17:00이며, 17:00 이후의 접수 건일 경우 익일
                                            취소 건으로 처리되어 위약 적용을 받게 됩니다.
                                        </li>
                                        <li>
                                            취소 접수된 건은 확인 후 확정여부를 예약자에게 알림톡으로 안내해드립니다.
                                        </li>
                                        <li>취소 확정 시, 위약금/페널티가 발생할 수 있습니다.</li>
                                    </ul>
                                </section>
                            </div>
                        </div>
                        <div>
                            <div className="agreeCheckLabel">
                                <label>
                                    <Checkbox isRoundBg={true} onChecked={onChecked} />
                                    <p>취소/위약규정 및 유의사항을 확인하였습니다.</p>
                                </label>
                            </div>
                            <button type="button" className="btnPrimary" onClick={nextStep} disabled={!activeButton}>
                                다음
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default CancelRegulationLayer;
