import React from "react";
import ReactPlayer from "react-player";
import BGCard from "assets/images/smart-round/BG-Card.png"

function CheckInBackGround({ checkInData, videoUrl, imageUrl, isPlaying }) {
    return (
        <div className="videoArea">
            {videoUrl ? (
                <ReactPlayer
                    url={videoUrl}
                    playing={isPlaying}
                    muted={true}
                    playsinline={true}
                    light={false}
                    loop={true}
                    width="100%"
                    height="100%"
                    className="videoPlayer"
                />
            ) : imageUrl ? (
                <img src={imageUrl} alt={checkInData?.name} />
            ) : (
                <img src={BGCard} alt="기본이미지" />
            )}
        </div>
    );
}

export default React.memo(CheckInBackGround);
