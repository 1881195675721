import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {history, fetchWrapper} from '_helpers';
import {localStorageUtil} from '../common/utils';
import {getCookie, removeCookie, setCookie} from '../common/cookieUtils';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

// exports

export const authActions = {...slice.actions, ...extraActions};
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    let userData = localStorageUtil.get('user');
    if (!userData) {
        localStorageUtil.remove('user');
    }
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(userData),
        error: null
    };
}

function createReducers() {
    return {
        logout,
        refresh,
        setToken,
        initToken,
        leave
    };

    function logout(state, action) {
        state.user = '';
        localStorage.setItem('user', '');
        localStorage.removeItem('user');
        setCookie('accessToken', '');
        setCookie('refreshToken', '');
        removeCookie('accessToken');
        removeCookie('refreshToken');
        // history.navigate(action?.payload?.uri || '/member/login', {state: {flag: 'logout'}});

        history.navigate(action?.payload?.uri || '/member', {state: {flag: 'logout'}});
        // history.navigate('/member/login');
    }

    function refresh(state) {
        state.user = {
            accessToken: getCookie('accessToken') || JSON.parse(localStorageUtil.get('user')).accessToken,
            refreshToken: getCookie('refreshToken') || JSON.parse(localStorageUtil.get('user')).refreshToken
        };
    }

    // 회원가입완료시 토큰값 로컬스토리지와 스토어에 저장
    function setToken(state, action) {
        localStorageUtil.set(
            'user',
            JSON.stringify({
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken
            })
        );
        state.user = {
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken
        };
    }

    //토큰, 쿠키, 스토어 변수 전체 삭제
    function initToken(state) {
        state.user = '';
        state.error = null;
        localStorage.setItem('user', '');
        localStorage.removeItem('user');
        setCookie('accessToken', '');
        setCookie('refreshToken', '');
        removeCookie('accessToken');
        removeCookie('refreshToken');
    }

    function leave(state) {
        state.user = '';
        state.error = null;
        localStorage.setItem('user', '');
        localStorage.removeItem('user');
        setCookie('accessToken', '');
        setCookie('refreshToken', '');
        removeCookie('accessToken');
        removeCookie('refreshToken');
        // history.navigate(action?.payload?.uri || '/member/login', {state: {flag: 'logout'}});
        // history.navigate('/mypage/withdrawal-complete', {state: {flag: 'logout'}});
    }
}

function createExtraActions() {
    return {
        login: login()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({username, password, channel}) =>
                await fetchWrapper.post(`/authentication`, {
                    username,
                    password,
                    channel,
                    visitInfo: window.visitInfo
                })
        );
    }
}

function createExtraReducers() {
    return {
        ...login()
    };

    function login() {
        var {pending, fulfilled, rejected} = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload.data;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                if (user) {
                    localStorageUtil.set('user', JSON.stringify(user));
                    state.user = user;
                    // 로그아웃이나 탈퇴 후 로그인 재시도시 flag의 값이 할당되어 로그인이 이중클릭됨
                    if (history.location?.state?.flag) {
                        delete history.location.state;
                    }
                    const {from} = history.location?.state || {
                        from: {pathname: '/'}
                    };
                    history.navigate(from);
                } else {
                    //현재 유효한 유저 정보가 없는 경우
                    state.error = action.payload.message;
                }
                // get return url from location state or default to home page
            },
            [rejected]: (state, action) => {
                // console.log('action', action);
                state.error = action.error;
            }
        };
    }
}
