import React from "react";

function ThridPrivacyLGT() {
    return (
        <>
            <section>
                <h2>&lt;LGU+&gt;</h2>
                <p>
                    "알뜰폰(MVNO)"사업자는 다음과 같이 개인정보를 제3자에게
                    제공하고 있습니다.
                </p>
            </section>
            <section>
                <h2>제1조 (알뜰폰(MVNO) 사업자)</h2>
                <p>
                    엘지유플러스의 알뜰폰(MVNO) 사업자는 프리티, 마이월드,
                    U+알뜰모바일, 서경모바일, 여유텔레콤, 플래시모바일, 이지톡,
                    모빙, 이야기모바일, 스마텔, A모바일, 온국민폰, 셀모바일,
                    조이텔, 아이즈모바일, 에스원안심모바일, 원텔레콤,
                    리브엠(Liiv M), 헬로모바일, 밸류컴, 엔티온텔레콤, 스노우맨,
                    앤텔레콤, 제주방송, 이마트, 도시락모바일, 인스코리아,
                    니즈텔레콤, 인스모바일, 아시아모바일, 슈가모바일, 코나아이
                    입니다.
                </p>
            </section>
            <section>
                <h2>제2조 (제공목적)</h2>
                <p>
                    <strong className="underLine">
                        본 동의는 본인확인 서비스를 제공하기 위하여
                        본인확인기관인 (주)엘지유플러스와 본인확인서비스
                        이용자간본인확인서비스 이용에 필요한 고객정보를 위탁하여
                        본인확인서비스를 제공 하는 것에 대해 동의합니다.
                    </strong>
                </p>
            </section>
            <section>
                <h2>제3조 (제공정보)</h2>
                <p>
                    휴대폰 본인확인 서비스 제공에 필요한 개인정보로 성명,
                    휴대폰번호, 생년월일, 내.외국인구분, 성별정보입니다.
                </p>
            </section>
            <section>
                <h2>제4조 (제공받는자)</h2>
                <p>
                    <strong className="underLine">(주)엘지유플러스</strong>
                </p>
            </section>
            <section>
                <h2>제5조 (제공기간)</h2>
                <p>
                    <strong className="underLine">
                        이동통신사에서 보유중인 정보로서 별도 이용기간은
                        없습니다.
                    </strong>
                </p>
            </section>
            <section>
                <p>
                    "본인"은 정보제공에 동의하지 않으실 수 있으며, 동의하지
                    않으실 경우 휴대폰 본인 확인 서비스를 이용하실 수 없습니다.
                </p>
            </section>
        </>
    );
}

export default ThridPrivacyLGT;
