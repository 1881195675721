import React, {useCallback, useEffect, useState} from 'react';
import Button from '../button/Button';
import Header from '../Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import './membership.scss';
import SettingPwField from './SettingPwField';
import {appUtils} from '../../common/utils';
import {fetchWrapper, history} from '../../_helpers';
// import {appActions} from '../../_store/app.slice';
import {SUCCESS_CODE} from '../../common/constants';

function ModifyPw({title}) {
    //디바이스 높이값 체크
    const {height} = useWindowDimensions();
    const [formValue, setFormValue] = useState({
        userPassword: '',
        userMail: history.location?.state?.userId
    });
    const [passwordMinLength, setPasswordMinLength] = useState(false);
    const [passwordRegYN, setPasswordRegYN] = useState(false);
    const [passwordIncludeId, setPasswordIncludeId] = useState(false);

    //하단 '다음'버튼
    const [activeButton, setActiveButton] = useState(false);
    const isActive = useCallback(() => {
        let isOk = passwordMinLength && passwordRegYN && passwordIncludeId;

        setActiveButton(!!isOk);
    }, [passwordMinLength, passwordIncludeId, passwordRegYN]);

    const [payload, _setPayload] = useState({
        loginId: history.location.state.userId,
        reqNum: history.location.state.reqNum,
        certDate: history.location.state.certDate,
        smsNum: history.location.state.smsNum,
        newPassword: ''
    });

    useEffect(() => {
        isActive();
    }, [isActive]);

    const setPasswordValid = () => {
        setPasswordMinLength(appUtils.minLengthCheck(formValue.userPassword, window.config.password.minLength));
        setPasswordIncludeId(appUtils.passwordValidCheck(formValue));
        setPasswordRegYN(appUtils.passwordRegCheck(formValue.userPassword));
    };

    const nextPage = () => {
        payload.newPassword = formValue.userPassword;
        if (activeButton) {
            fetchWrapper.put(`/member/change-pw`, payload).then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    history.navigate('/member/find-pw/result');
                } else {
                }
            });
        }
    };

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="membership">
                    <div className="inner">
                        <form>
                            <TitleArea pageTitle={title} />
                            <div className="fieldAreaWrap">
                                <SettingPwField
                                    formValue={formValue}
                                    setFormValue={setFormValue}
                                    passwordMinLength={passwordMinLength}
                                    passwordRegYN={passwordRegYN}
                                    passwordIncludeId={passwordIncludeId}
                                    setPasswordValid={setPasswordValid}
                                />
                            </div>
                            <div className="btnFixedBottom">
                                {' '}
                                {/* 버튼이 키보드 위에 고정되어야 하는 경우 btnFixedBottom에 클래스 "fixed" 추가 */}
                                <div className="inner">
                                    <Button
                                        type="button"
                                        background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                        onClick={nextPage}
                                    >
                                        설정 완료
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModifyPw;
