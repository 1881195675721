import React, {useState} from 'react';
import TitleArea from '../common/TitleArea';
import Checkbox from '../button/Checkbox';
import Button from '../button/Button';
import DefaultAlert from '../alert/DefaultAlert';
import {history} from '../../_helpers';

function ReservationAgree({title}) {
    const [activeButton, setActiveButton] = useState(false);

    // 동의 체크 Alert
    const [handleAlert, setHandleAlert] = useState(false);
    const handleAlertOpen = () => setHandleAlert(true);
    const handleAlertClose = () => setHandleAlert(false);

    const nextPage = () => {
        history.navigate('/');
    };

    const onChecked = (state) => {
        setActiveButton(state);
    };

    return (
        <>
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="agreeCon">
                            <section>
                                <p>
                                    스마트라운드 서비스 제공을 위해 골라가(그린잇) 의 제휴골프장 회원 예약 정보 조회에
                                    대한 회원의 동의가 필요합니다.
                                    <br />
                                    <br />
                                    아래 약관을 모두 읽으신 후 약관에 동의해주세요.
                                    <br />
                                    <br />
                                    골라가(그린잇)은 개인정보 보호법 등 관련 법령에 따라 개인정보를 처리합니다.
                                </p>
                            </section>
                            <section>
                                <h2>정보 제공자</h2>
                                <p>센테리움CC, 포도CC, 서원힐스CC, 클럽 디 금강</p>
                            </section>
                            <section>
                                <h2>정보 수신자</h2>
                                <p>골라가(그린잇)</p>
                            </section>
                            <section>
                                <h2>목적</h2>
                                <p>
                                    제휴 골프장 예약 조회 및 스마트라운드 서비스(셀프 체크인, 동반자 초대, 무인 간편
                                    결제) 이용
                                </p>
                            </section>
                            <section>
                                <h2>수집 · 이용 정보 </h2>
                                <p>
                                    라운드 일시, 예약 번호, 코스 정보, 할인 혜택, 이용 요금, 주문내역, 예약자 및 동반자
                                    정보(이름, 휴대전화번호)
                                </p>
                            </section>
                            <section>
                                <h2>예약 정보 보유・이용기간</h2>
                                <p>예약 조회 및 수집・이용에 동의한 시점부터 예약 티타임 종료 시까지</p>
                            </section>
                            <section>
                                <h2>결제 정보 보유기간</h2>
                                <p>전자금융거래법에 의해 5년간 보관</p>
                            </section>
                            <section>
                                <p>
                                    위 내용은 셀프 체크인, 동반자 초대, 무인 간편 결 제 등 스마트라운드 서비스를 위한
                                    필수 사항으로 회원의 동의가 꼭 필요하며 동의를 하지 않으면 서 비스 이용이
                                    어렵습니다.
                                </p>
                            </section>
                        </div>
                        <div className="agreeCheckLabel">
                            <label>
                                {/* CheckBox 안의 isChecked가 true이면 아래 버튼 activeButton을 true로 */}
                                <Checkbox isRoundBg={true} onChecked={onChecked} />
                                <p>
                                    위 내용을 모두 읽었으며, 제휴 골프장 예약 정보 조회 및 개인정보 수집・이용에
                                    동의합니다.
                                </p>
                            </label>
                        </div>
                        <Button
                            type="button"
                            background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                            onClick={activeButton ? nextPage : handleAlertOpen}
                        >
                            동의하고 계속하기
                        </Button>
                    </div>
                </div>
            </div>

            <DefaultAlert
                open={handleAlert}
                handleClose={handleAlertClose}
                title={'안내'}
                contents={'제휴 골프장 예약 정보 조회 및 개인정보 수집 · 이용 동의를 체크해주세요.'}
            />
        </>
    );
}

export default ReservationAgree;
