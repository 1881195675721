import Drawer from '@mui/material/Drawer';
import Icon from '../../icons/Icon';
import React, {useEffect, useState} from 'react';
import PolicyLayer from '../../membership/PolicyLayer';
import {fetchWrapper, history} from '../../../_helpers';
import {useSelector} from 'react-redux';
import {store, userActions} from '../../../_store';
import {connectOtherBookingComplete, viewOtherBookingAgreeModal} from '_helpers/amplitude/events/view.events';
import {SUCCESS_CODE} from 'common/constants';

function AgreementDrawer({open, onClose, isAgreementCheck}) {
    const member = useSelector((x) => x?.users?.users);
    const [checkStatus, setCheckStatus] = useState(false);

    //예약정보 불러오기 약관 레이어
    const [agreementOpen, setAgreementOpen] = useState(false);

    //예약정보 불러오기 약관 레이어 오픈
    const agreementLayerOpen = () => {
        setAgreementOpen(true);
    };

    //예약정보 불러오기 약관 레이어 닫기
    const agreementLayerClose = () => {
        setAgreementOpen(false);
    };

    // 예약정보 조회,전송 동의 처리
    const checkReservAgreement = (event) => {
        const payload = {
            agreementType: 'BOOKING_CHECK',
            isAgreement: true,
            email: member?.email
        };

        // 예약정보 조회,전송 동의 & User 정보 member에 저장
        fetchWrapper.post(`/member/change/agreement/status`, payload).then((res) => {
            store.dispatch(userActions.setUser({key: 'isBookingCheckAgree', value: true}));
            const curUrl = sessionStorage.getItem('curUrl');
            connectOtherBookingComplete({agree_type: 'change_myinfo_toggle'}, curUrl); // 앰플리튜드 이벤트 전송
        });
        onClose();
    };

    const getMyReserve = async (event) => {
        // 예약정보 조회,전송 동의 api 호출
        checkReservAgreement(event);

        // 타 플랫폼 예약정보 불러오는 api 호출
        const resLinkedGolfClub = await fetchWrapper.get('/booking/linkedGolfClub');
        if (resLinkedGolfClub.code === SUCCESS_CODE) {
            isAgreementCheck();
        }
    };
    //#endregion

    const moveSuccessPage = (event) => {
        // 예약정보 조회,전송 동의 api 호출
        getMyReserve(event);
    };

    useEffect(() => {
        if (open) {
            viewOtherBookingAgreeModal();
        }
    }, [open]);

    return (
        <>
            <Drawer anchor="bottom" open={open} onClose={onClose} className="defaultDrawer">
                <div className="drawer-content my-reserv">
                    <div className="drawerTitle">
                        <div className="ttReserv">예약정보를 불러오기 위해 아래 내용에 동의해주세요</div>
                        <button type="button" className="btnClose" onClick={onClose}>
                            <Icon name="close02" width="24" hei ght="24" viewBox="0 0 24 24" />
                        </button>
                    </div>
                    <div className="drawerContents">
                        <div className="agreeCheckArea">
                            <ul className="agreeCheckList">
                                <li>
                                    <div className="checkLabel">
                                        <label>
                                            <div className="customCheck">
                                                <input
                                                    type="checkbox"
                                                    onChange={() => {
                                                        setCheckStatus((prev) => !prev);
                                                    }}
                                                    checked={checkStatus}
                                                />
                                                <i className={`icon ${checkStatus ? 'checked' : ''}`}>
                                                    <svg
                                                        width="100%"
                                                        height="100%"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M17 8L11.0525 15L7 10.9168"
                                                            stroke={checkStatus ? '#3906C3' : '#A0A4AF'}
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </i>
                                            </div>
                                            <span className={checkStatus ? '' : 'gray'}>[필수] </span>
                                            예약정보 조회 &middot; 전송동의
                                        </label>
                                        <button type="button" className="btn_ar" onClick={agreementLayerOpen}>
                                            자세히보기
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="drawerBtnArea">
                            <button className="button btnPrimary" disabled={!checkStatus} onClick={moveSuccessPage}>
                                예약정보 불러오기
                            </button>
                        </div>
                    </div>
                </div>
            </Drawer>

            {/* 예약정보 불러오기 약관 레이어 */}
            <PolicyLayer
                open={agreementOpen}
                agreeType="GET_RESERVATION_INFO_AGREE"
                title="예약정보 조회 · 전송동의"
                handleClose={agreementLayerClose}
            />
        </>
    );
}

export default AgreementDrawer;
