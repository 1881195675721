import React from "react";
import SkeletonElement from "./SkeletonElement";
import "../skeleton/skeleton.scss";

function SkeletonCourseCon() {
    return (
        <div className="skeleton">
            <div className="courseCon">
                <div className="ttArea">
                    <h2>
                        <SkeletonElement type="titTxt" variant="rectangular" />
                    </h2>
                    {/*<button>*/}
                    {/*    <SkeletonElement*/}
                    {/*        type="courseChoice"*/}
                    {/*        variant="rectangular"*/}
                    {/*    />*/}
                    {/*</button>*/}
                </div>
                {/* 코스 상세 정보 */}
                <div className="courseDetail">
                    <div className="courseImgArea">
                        <SkeletonElement
                            type="courseImg"
                            variant="rectangular"
                        />
                    </div>
                    <div className="courseInfo">
                        <h3>
                            <SkeletonElement
                                type="titTxt"
                                variant="rectangular"
                            />
                        </h3>
                        <p>
                            <SkeletonElement
                                type="courseDes"
                                variant="rectangular"
                            />
                            <SkeletonElement
                                type="courseDes"
                                variant="rectangular"
                            />
                        </p>
                        {/* <SkeletonElement
                            type="courseInfo"
                            variant="rectangular"
                        /> */}
                    </div>
                </div>
            </div>

            {/*/!* 버튼 *!/*/}
            {/*<div className="btnArea">*/}
            {/*    <SkeletonElement type="courseBtn" variant="rectangular" />*/}
            {/*</div>*/}
        </div>
    );
}

export default SkeletonCourseCon;
