import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Icon from '../../icons/Icon';
import '../reservation.scss';
import OutdoorDetailVisualSlider from './OutdoorDetailVisualSlider';
import OutdoorDetailHeader from './OutdoorDetailHeader';
import {fetchWrapper, history} from '../../../_helpers';
import {SUCCESS_CODE, YN} from '../../../common/constants';
import SearchMapDrawer from '../drawer/SearchMapDrawer';
import PolicyLayer from '../../membership/PolicyLayer';
import DefaultAlert from '../../alert/DefaultAlert';
import {appUtils, localStorageUtil} from '../../../common/utils';
import FieldTeaTime from './FieldTeaTime';
import GolfClubDetail from './GolfClubDetail';
import GolfClubNoticeModal from '../drawer/GolfClubNoticeModal';
import DirectionSnackbar from '../../alert/DirectionSnackbar';
import SelfCheckInGuideLayer from '../../smart-round/SelfCheckInGuideLayer';
import ShareDrawer from '../drawer/ShareDrawer';
import {viewCcDetail, viewTabCCDetail, viewCcCancelPolicy} from '_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import {rootTaxonomy} from '../../../_helpers/amplitude/taxonomy/root.taxonomy';
import {clickCallCCDetail, clickMapCCDetail, clickShareCcDetail} from '../../../_helpers/amplitude/events/click.event';

import Food from '../../food/Food';
import {useDispatch} from 'react-redux';
import {smartRoundActions} from '_store/smartRound.slice';
import {openNotWatchingTodayNotice} from 'common/notWatchingTodayUtils';
import Badge from 'components/badge/Badge';

const {Kakao} = window;

function OutdoorDetail() {
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [shareStatus, setShareStatus] = useState('golfClubDetail');
    const [open, setOpen] = useState(false);

    const [navigateStateData, setNavigateStateData] = useState(history.location?.state?.flag);

    const [searchBackLink, setSearchBackLink] = useState('');
    const [shareOpen, setShareOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [detailData, setDetailData] = useState(null);

    const noticeName = 'golfclub_detail_modal_expires';
    const currentId = detailData?.id;

    // 골프장 별 공지사항 팝업
    const [golfClubNoticeOpen, setGolfClubNoticeOpen] = useState(false);
    // 탭메뉴
    const [tabStatus, setTabStatus] = useState({value: 'teaTime'});

    const handleNoticeOpen = () => setGolfClubNoticeOpen(true);
    const handleNoticeClose = () => setGolfClubNoticeOpen(false);

    const drawerOpen = history.location?.state?.flag;

    const [isSnackBarOpen, setIsSnackBarOpen] = useState({
        open: false,
        msg: ''
    });

    // 셀프체크인 가이드 레이어 오픈 여부
    const [selfCheckInGuideLayerOpen, setSelfCheckInGuideLayerOpen] = useState(false);
    const selfCheckInGuideHandleOpen = () => setSelfCheckInGuideLayerOpen(true);
    const selfCheckInGuideHandleClose = () => setSelfCheckInGuideLayerOpen(false);

    const handleShareOpen = () => setShareOpen(true);
    const handleShareClose = () => setShareOpen(false);

    useEffect(() => {
        setSearchBackLink(navigateStateData); // todo 엘리 확인!!
    }, [navigateStateData]);

    const [golfClubNoticeData, setGolfClubNoticeData] = useState([]);
    const getGolfClubNoticeData = useCallback(() => {
        setGolfClubNoticeData([]);
        fetchWrapper.get(`/notice/booking?golfClubId=${id}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setGolfClubNoticeData(response?.data || []);
            }
        });
    }, [id]);

    useEffect(() => {
        getGolfClubNoticeData();
    }, [getGolfClubNoticeData]);

    // '오늘 하루 보지 않기' > 모달 오픈
    useEffect(() => {
        if (golfClubNoticeData && golfClubNoticeData.length > 0 && detailData) {
            openNotWatchingTodayNotice(noticeName, currentId, handleNoticeOpen);
        }
    }, [golfClubNoticeData, detailData]);

    // 골프클럽 상세 데이터
    const getDetailData = useCallback(() => {
        setDetailData(null);
        // 로컬스토리지에 저장되어있으면 해당 정보로 셋팅
        let selectedLocation_util = JSON.parse(localStorageUtil.get('selectedLocation'));

        if (selectedLocation_util == null) {
            let latlng;
            const locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
            if (locationStorage === null) {
                // 서울시청
                latlng = {lat: 37.5663, lon: 126.9779};
            } else {
                if (locationStorage.gpsrequest === 0) {
                    // 현재위치
                    latlng = {lat: locationStorage.lat, lon: locationStorage.lon};
                } else {
                    // 서울시청
                    latlng = {lat: 37.5663, lon: 126.9779};
                }
            }
            selectedLocation_util = {
                locationLat: latlng.lat,
                locationLon: latlng.lon
            };
        }

        fetchWrapper
            .get(
                `/golfClub/detail/${id}?userLat=${selectedLocation_util.locationLat.toFixed(
                    5
                )}&userLon=${selectedLocation_util.locationLon.toFixed(5)}`,
                null
            )
            .then((response) => {
                let detailData = null;
                if (response?.code === SUCCESS_CODE) {
                    detailData = response?.data;
                }
                setDetailData(detailData);

                // TODO: root method
                viewCcDetail(detailData, getAmplitudeRootPath());
            });
    }, [id]);

    useEffect(() => {
        getDetailData();
    }, [getDetailData]);

    // 지도(길찾기) Drawer
    const [mapDrawerOpen, setMapDrawerOpen] = useState(false);
    const onClickMapDrawer = () => {
        setMapDrawerOpen(true);

        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${id}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                clickMapCCDetail(response.data, getAmplitudeRootPath());
            }
        });

        appUtils.openWebViewModalPostMsg();
    };

    // 위약규정 Layer
    const [agreementOpen, setAgreementOpen] = useState(drawerOpen === 'drawerOpen' ? true : false);
    const agreementLayerOpen = () => {
        setAgreementOpen(true);

        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${detailData?.id}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                viewCcCancelPolicy(response.data, getAmplitudeRootPath());
            }
        });

        appUtils.openWebViewModalPostMsg();
    };
    const agreementLayerClose = () => {
        setAgreementOpen(false);
    };

    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                if (agreementOpen === true) {
                    agreementLayerClose();
                }
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);
        return () => {
            document.removeEventListener('message', handleEvent);
        };
    }, [agreementOpen]);

    const onSystemShare = () => {
        if (!detailData) return;

        // 골프클럽 약식 주소
        const address = detailData.addressLocal;
        const addressToArray = address?.split(' ');
        const shortAddress = addressToArray?.slice(0, 2).join(' ');
        // 골프장명
        const golfClubName = detailData?.golfClubName;
        const golfClubId = detailData?.id;

        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${id}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                clickShareCcDetail(response.data, getAmplitudeRootPath());
            }
        });

        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'share',
                golfClubName,
                shortAddress,
                golfClubId
            })
        );
    };

    // const kakakoShare = (detailData) => {
    //     // 골프클럽 약식 주소
    //     const address = detailData.addressLocal;
    //     const addressToArray = address?.split(' ');
    //     const shortAddress = addressToArray?.slice(0, 2).join(' ');
    //
    //     // 골프클럽 이름, 이미지, 설명
    //     const golfClubName = detailData.golfClubName;
    //     const golfClubImg = detailData.stillImageUrls[0]?.pathUrl;
    //     const golfClubDsc = detailData.golfClubDescription;
    //
    //     // 카카오 공유하기
    //     Kakao?.Share?.createCustomButton({
    //         container: '#shareBtnInner',
    //         templateId: Number(process.env.REACT_APP_KAKAO_MESSAGE_ID),
    //         templateArgs: {
    //             GOLF_CLUB_IMG: golfClubImg,
    //             GOLF_CLUB_NAME: golfClubName,
    //             ADDRESS: shortAddress,
    //             GOLF_CLUB_DSC: golfClubDsc,
    //             WEB_PATH: `gate-app/share/${id}/${shareStatus}`
    //         }
    //     });
    // };

    // useEffect(() => {
    //     if (!detailData) {
    //         return;
    //     }
    //     kakakoShare(detailData);
    //
    //     return () => {
    //         Kakao?.Share.cleanup();
    //     };
    // }, [detailData]);

    // 주변 인근 골프장
    const [nearbyClubData, setNearbyClubData] = useState(null);
    const getNearbyClubData = useCallback(() => {
        if (id != null) {
            fetchWrapper.get(`/golfClub/nearbyClub/list/${id}`, null).then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    setNearbyClubData(response?.data || null);
                }
            });
        }
    }, [id]);

    useEffect(() => {
        getNearbyClubData();
    }, [getNearbyClubData]);

    useEffect(() => {
        if (drawerOpen) appUtils.openWebViewModalPostMsg();
    }, []);

    // 전화하기
    const onClickCall = (num) => {
        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${id}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                clickCallCCDetail(response.data, getAmplitudeRootPath());
            }
        });

        window.location.href = 'tel:' + num;
    };

    const onClickNearByClub = (id) => {
        history.navigate(`/field-detail/${id}`, {state: {flag: 'nearby'}});
        setNavigateStateData('nearby');
        sessionStorage.setItem('root', rootTaxonomy.near_recommendation);
    };

    const tabClick = (tabName) => {
        if (tabName === 'teaTime') {
            setTabStatus({value: 'teaTime'});
            let tabNameStr = '티타임';
            viewTabCCDetail(tabNameStr);
        } else if (tabName === 'clubDetail') {
            setTabStatus({value: 'clubDetail'});
            let tabNameStr = '골프장소개';
            viewTabCCDetail(tabNameStr);
        } else if (tabName === 'food') {
            setTabStatus({value: 'food'});
            let tabNameStr = '음식메뉴';
            viewTabCCDetail(tabNameStr);
        }
    };

    const goCourseGuide = () => {
        dispatch(smartRoundActions.setFrom({from: location.pathname}));
        history.navigate(`/course-guide-v2?golfClubId=${detailData?.id}`);
        // history.navigate('/course-guide', {
        //     state: {
        //         roundData: {
        //             golfClubId: detailData.id,
        //             bookingId: '',
        //             golfClubName: detailData.golfClubName
        //         },
        //         smartRound: false,
        //         isDetail: true
        //     }
        // });
    };

    const onClickSelfCheckInBtn = (e) => {
        selfCheckInGuideHandleOpen();
        appUtils.openWebViewModalPostMsg();
    };

    return (
        <>
            <OutdoorDetailHeader
                clubId={id}
                detailData={detailData}
                pageTitle={detailData?.golfClubShortName}
                interest={detailData?.isInterest}
                searchBackLink={searchBackLink}
                shareStatus={shareStatus}
                setIsSnackBarOpen={setIsSnackBarOpen}
                handleShareOpen={handleShareOpen}
            />
            <div id="contents">
                <div className="contentsArea" id="golfZoneDetail">
                    <section className="fieldQuickInfo">
                        <div className="fieldVisualSlider">
                            <OutdoorDetailVisualSlider data={detailData?.stillImageUrls} />
                        </div>
                        <div className="section">
                            <div className="ttArea">
                                {detailData?.isSelfCheckInPossible && (
                                    <>
                                        {detailData?.promotionActiveYn === YN.Y && <Badge type={'EVENT'} />}
                                        <Badge type={'SELF_CHECK_IN_ARROW'} onClick={onClickSelfCheckInBtn} />
                                    </>
                                )}
                                <strong className="tt mt-6">{detailData?.golfClubName}</strong>
                                {detailData?.golfClubDescription && (
                                    <p className="description">{detailData?.golfClubDescription}</p>
                                )}
                                <span className="location">
                                    {detailData?.location} · {detailData?.distance} km
                                </span>
                            </div>
                            {detailData?.metaTags?.length > 0 && (
                                <div className="hashList">
                                    {detailData?.metaTags?.map((item, idx) => {
                                        return <span key={idx}># {item?.tagName}</span>;
                                    })}
                                </div>
                            )}
                            <div className="btnArea">
                                <button type="button" onClick={() => onClickCall(detailData?.phoneNumber)}>
                                    <Icon name="phone02" width="18" height="18" viewBox="0 0 18 18" /> 전화
                                </button>

                                {/* ----------------------- 0509 by joanna ios 앱심사 승인완료 다시살리기 --------------------*/}

                                {/* 1. 골프장상세
                                        골프장 정보 영역 '전화' '지도' '공유' 버튼 삭제(히든)
                                   전화 버튼 길게 */}
                                <button type="button" onClick={onClickMapDrawer}>
                                    <Icon name="map-b" width="18" height="18" viewBox="0 0 18 18" /> 지도
                                </button>
                                <button
                                    type="button"
                                    id="shareBtnInner"
                                    onClick={() => {
                                        setShareOpen(true);
                                    }}
                                >
                                    <Icon name="share-detail-b" width="13" height="16" viewBox="0 0 13 16" /> 공유
                                </button>
                                {/* ----------------------- 0509 by joanna ios 앱심사 승인완료 다시살리기 --------------------*/}
                            </div>
                            {detailData?.isCourseGuideExposed && (
                                <button type="button" className="btnPrimary courseGuideBtn" onClick={goCourseGuide}>
                                    코스가이드 바로가기
                                </button>
                            )}
                        </div>
                    </section>

                    {/* 티타임 / 골프장 소개 Tab */}
                    <div id="outdoorTabType01">
                        <ul>
                            <li className={`tab${tabStatus.value === 'teaTime' ? ' on' : ''}`}>
                                <button type="button" onClick={() => tabClick('teaTime')}>
                                    티타임
                                </button>
                            </li>
                            <li
                                className={`tab${tabStatus.value === 'clubDetail' ? ' on' : ''}${
                                    tabStatus.value !== 'food' ? ' border-none' : ''
                                }`}
                            >
                                <button type="button" onClick={() => tabClick('clubDetail')}>
                                    골프장 소개
                                </button>
                            </li>
                            <li
                                className={`tab ${tabStatus.value === 'food' ? 'on' : ''}${
                                    tabStatus.value !== 'teaTime' ? ' border-none' : ''
                                }`}
                            >
                                <button type="button" onClick={() => tabClick('food')}>
                                    음식 메뉴
                                </button>
                            </li>
                        </ul>
                    </div>

                    {tabStatus.value === 'teaTime' ? (
                        <FieldTeaTime isOutdoor={true} />
                    ) : tabStatus.value === 'clubDetail' ? (
                        <GolfClubDetail
                            detailData={detailData}
                            nearbyClubData={nearbyClubData}
                            agreementLayerOpen={agreementLayerOpen}
                            onClickNearByClub={onClickNearByClub}
                            navigate={navigate}
                            onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                        />
                    ) : (
                        <Food
                            usePath={rootTaxonomy.cc_detail}
                            golfClubIdParam={id}
                            detailData={detailData}
                            golfClubNameParam={detailData?.golfClubName}
                            isMenuApplied={detailData?.isMenuApplied}
                        />
                    )}
                </div>
            </div>

            {/*지도 Drawer*/}
            <SearchMapDrawer
                mapDrawerOpen={mapDrawerOpen}
                setMapDrawerOpen={setMapDrawerOpen}
                detailData={detailData}
            />

            {/*위약규정 Layer*/}
            <PolicyLayer
                open={agreementOpen}
                agreeType="GOLF_CLUB_AGREE"
                title={`${detailData?.golfClubName} 취소/위약 규정` || ''}
                handleClose={agreementLayerClose}
                contents={detailData?.placeboInformation || ''}
            />

            <DefaultAlert
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                title={'미지원 디바이스'}
                contents={'공유하기 기능을 지원하지 않는 디바이스 입니다.'}
            />

            {/*골프장 별 공지사항 팝업*/}
            <GolfClubNoticeModal
                open={golfClubNoticeOpen}
                handleClose={handleNoticeClose}
                golfClubNoticeData={golfClubNoticeData}
                golfclubId={id}
            />

            {/* 관심 골프장 on/off 안내 snackBar */}
            <DirectionSnackbar
                direction="up"
                position="bottom"
                open={isSnackBarOpen.open}
                msg={isSnackBarOpen.msg}
                duration={1000}
                setOpen={setIsSnackBarOpen}
                isNavigation={false}
            />

            {/* 셀프체크인 가이드 Layer */}
            <SelfCheckInGuideLayer handleOpen={selfCheckInGuideLayerOpen} handleClose={selfCheckInGuideHandleClose} />
            <ShareDrawer
                open={shareOpen}
                handleClose={handleShareClose}
                golfClubId={id}
                detailData={detailData}
                shareStatus={shareStatus}
            />
        </>
    );
}

export default OutdoorDetail;
