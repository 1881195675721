import React from "react";
import "./gatepage.scss";

function GatePage() {
    return (
        <div id="gatePage">
            <div className="inner">
                <div className="ttArea">
                    <h1>[골라가] 골프 라운드 가자</h1>
                    <p>데스크 방문 없이 3초만에 완료!</p>
                    <h2>
                        <strong>골라가 셀프 체크인</strong> 이용방법
                    </h2>
                </div>
                <div className="howToUse">
                    <ul>
                        <li>
                            <i className="step01"></i>
                            <span className="step">STEP 01</span>
                            <p>
                                미리 휴대폰에
                                <br />
                                골라가 앱을 설치한다
                            </p>
                        </li>
                        <li>
                            <i className="step02"></i>
                            <span className="step">STEP 02</span>
                            <p>
                                골라가 앱에
                                <br />
                                회원으로 가입한다
                            </p>
                        </li>
                        <li>
                            <i className="step03"></i>
                            <span className="step">STEP 03</span>
                            <p>
                                라운드 D-day!
                                <br />
                                골프장 1km 내 위치에서
                                <br />
                                골라가 앱에 접속한다
                                <span className="sTxt">
                                    (앱을 설치한 휴대폰 위치 기준)
                                </span>
                            </p>
                        </li>
                        <li>
                            <i className="step04"></i>
                            <span className="step">STEP 04</span>
                            <p>
                                골라가 앱의 <br />
                                셀프 체크인 화면을 <br />
                                터치하면 체크인 완료!
                            </p>
                        </li>
                    </ul>
                </div>
                <div className="checkCon">
                    <strong className="tt">확인해주세요!</strong>
                    <ul className="listType01">
                        <li>
                            동반자 전원 체크인 후 캐디와 카트 번호를 배정받으실
                            수 있습니다.
                        </li>
                        <li>
                            현장 상황에 따라 캐디와 카트 배정 시점은 달라질 수
                            있습니다.
                        </li>
                        <li>
                            키오스크를 지원하는 골프장 이용 시 QR코드로 체크인도
                            가능합니다.
                        </li>
                        <li>
                            티타임에 맞춰 내장하지 못하실 경우 골프장 안내
                            데스크로 문의 바랍니다.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default GatePage;
