import {appUtils, localStorageUtil} from './utils';

/**
 * 오늘 날짜 형식 변환 '0000-00-00'
 */
export const formatTodayDateToIsoString = appUtils.getYYYYMMDDHyphen();

/**
 * 오늘 하루 보지 않기 > notice 오픈 함수
 * @param {*} noticeName
 * @param {*} currentId
 * @param {*} handleNoticeOpen
 * 
 *  [모달 오픈 조건]
        1.로컬스토리지가 없거나, 있지만 비어있을 때 
        2.로컬스토리지가 있지만, 고유 Id가 같은게 없을 떄 
        3.로컬스토리지가 있고 고유 Id가 같은게 있지만, 유효시간이 오늘이 아닐 때 
 */
export const openNotWatchingTodayNotice = (noticeName, currentId, handleNoticeOpen) => {
    const getLocalstorage = localStorageUtil.get(noticeName);
    let localStorageState;
    if (getLocalstorage) {
        localStorageState = JSON.parse(getLocalstorage);
    } else {
        localStorageState = null;
    }

    // 조건에 따른 notice 오픈
    if (!localStorageState || (localStorageState && localStorageState.length === 0)) {
        handleNoticeOpen();
    } else {
        const findSameId = (value) => {
            return value.id === currentId;
        };
        const checkIdInLocalStorage = localStorageState.find(findSameId);

        // 같은 Id가 존재하지 않을 때
        if (!checkIdInLocalStorage) handleNoticeOpen();

        // 같은 Id가 존재하는데 날짜가 맞지 않을 때
        if (checkIdInLocalStorage && checkIdInLocalStorage.expires !== formatTodayDateToIsoString) {
            const index = localStorageState.indexOf(checkIdInLocalStorage);
            localStorageState.splice(index, 1);
            localStorageUtil.set(noticeName, JSON.stringify(localStorageState));
            handleNoticeOpen();
        }
    }
};

/**
 * 오늘 하루 보지 않기 > 로컬스토리지 생성 함수
 * @param {*} localStorageState
 * @param {*} noticeName
 * @param {*} currentId
 */
export const setNotWatchingTodayExpires = (noticeName, currentId) => {
    const getLocalstorage = localStorageUtil.get(noticeName);
    let localStorageState;
    if (getLocalstorage) {
        localStorageState = JSON.parse(getLocalstorage);
    } else {
        localStorageState = null;
    }

    // 초기 로컬스토리지 없을 시 생성
    if (!localStorageState || (localStorageState && localStorageState.length === 0)) {
        const newExpireArray = [];
        const doNotViewOneDay = {
            id: currentId,
            expires: formatTodayDateToIsoString
        };
        newExpireArray.push(doNotViewOneDay);
        localStorageUtil.set(noticeName, JSON.stringify(newExpireArray));
    } else {
        localStorageState.map((object) => {
            // 로컬스토리지의 id 값이 같지만, 유효시간이 오늘이 아닐 때
            if (object.id === currentId && object.expires !== formatTodayDateToIsoString) {
                const index = localStorageState.indexOf(object);
                localStorageState.splice(index, 1);
                localStorageState.push({
                    id: currentId,
                    expires: formatTodayDateToIsoString
                });
            } else if (object.id !== currentId) {
                // id 값이 같은 게 없을 때
                localStorageState.push({
                    id: currentId,
                    expires: formatTodayDateToIsoString
                });
            }
        });

        // 위에서 세팅한 object를 로컬스토리지에 세팅
        localStorageUtil.set(
            noticeName,
            JSON.stringify([...new Map(localStorageState.map((v) => [JSON.stringify([v.id, v.expires]), v])).values()])
        );
    }
};
