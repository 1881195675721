import Food from 'components/food/Food';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {rootTaxonomy} from '_helpers/amplitude/taxonomy/root.taxonomy';
import './food.scss';

function FoodV2() {
    const golfClubId = useSelector((x) => x.smartRound?.golfClubId);
    const smartRound = useSelector((x) => x.smartRound?.smartRound);

    return (
        <div className="food-wrapper">
            {golfClubId && smartRound && (
                <Food
                    usePath={rootTaxonomy.smartround}
                    golfClubIdParam={golfClubId}
                    detailData={null}
                    golfClubNameParam={smartRound?.golfClubShortName}
                    isMenuApplied={smartRound?.isMenuApplied}
                />
            )}
        </div>
    );
}

export default FoodV2;
