import React, {useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import Icon from '../icons/Icon';

function WebAccessInfoModal() {
    const [open, setOpen] = useState(false);
    const handleWebAccessInfoClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (window.ReactNativeWebView) {
            // 앱 접속일때
            setOpen(false);
        } else {
            // 웹 접속일때
            setOpen(true);
        }
    }, []);

    // 앱 이용하기 링크
    const appStoreLink =
    "https://apps.apple.com/us/app/%EA%B3%A8%EB%9D%BC%EA%B0%80/id1661923157";
    const playStoreLink =
    "https://play.google.com/store/apps/details?id=golf.golaga&referrer=airbridge%3Dtrue%26event_uuid%3D7c1959db-2f65-4a75-a3cb-5a6026c7ab48%26client_id%3D5e99655e-460f-4daa-8b71-f70eb02a6172%26short_id%3Dridkbg%26referrer_timestamp%3D1715035819341%26channel%3Dstart_selfcheck_btn%26tracking_template_id%3D2bbc66ced7809afe1e90c5ad5827d679%26content%3Dkimcheonpodo%26og_tag_id%3D24099844%26routing_short_id%3Dfzfwbf";
  
    const checkIOSAgent = /iPhone|iPad|iPod|Mac|Mac OS/i.test(
        window.navigator?.userAgent
        );

    const suggestAppURL = checkIOSAgent ? appStoreLink : playStoreLink

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={handleWebAccessInfoClose}
            className="defaultDrawer webAccessInfoModal"
        >
            <div className="drawer-content">
                <div className="drawerContents">
                    <div className="logoArea">
                        <i className="logoIcon">
                            <Icon name="logoIcon" width="78" height="78" viewBox="0 0 78 78" />
                        </i>
                        <strong className="logoTxt">golaga</strong>
                    </div>
                    <div className="txtCon">
                        <strong className="bigTxt">골라가 앱에서 쉽고 빠르게!</strong>
                        <p>
                            <span>부킹에서 셀프체크인까지</span> 편리하게
                            <br />
                            이용해보세요!
                        </p>
                    </div>
                    <div className="btnWrap">
                        <a href={suggestAppURL} target="_blank" rel="noopener noreferrer" className="btnPrimary">
                            앱 이용하기
                        </a>
                        <button type="button" onClick={handleWebAccessInfoClose} className="btnAnchor">
                            괜찮아요. 모바일웹으로 볼게요.
                        </button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

export default WebAccessInfoModal;
