import React, {useCallback, useEffect, useState} from 'react';
import Agreement from './Agreement';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import './membership.scss';
import {errMsg, pageTitle, successMsg} from '../../common/messages';
import {appUtils, localStorageUtil} from '../../common/utils';
import SettingPwField from './SettingPwField';
// import { useSelector } from 'react-redux';
import {fetchWrapper, history} from '../../_helpers';
import {CHK_EMAIL_INPUT, CNFRMTITMESSAGE, EMAIL_MAXLENGTH, SUCCESS_CODE} from '../../common/constants';
// import { appActions } from '../../_store/app.slice';
import DefaultAlert from '../alert/DefaultAlert';
import {authActions, store, userActions} from '../../_store';
import {alertActions} from '../../_store/alert.slice';
import CommonAlert from '../alert/CommonAlert';
import {useDispatch, useSelector} from 'react-redux';
import {view_signup_email_step2, viewSignupEmailStep2} from '../../_helpers/amplitude/events/view.events';
// import app from '../../App';

function SettingIdPw() {
    const {height} = useWindowDimensions();

    const [formValue, setFormValue] = useState({
        userMail: '',
        userPassword: ''
    });
    const [activeButton, setActiveButton] = useState(false);
    const [venderOpen, setVenderOpen] = useState(false);
    const [emailValidation, setEmailValidation] = useState({
        message: '',
        error: false
    });
    const [passwordMinLength, setPasswordMinLength] = useState(false);
    const [passwordRegYN, setPasswordRegYN] = useState(false);
    const [passwordIncludeId, setPasswordIncludeId] = useState(false);
    const [requireAllCheck, setRequireAllCheck] = useState(false);

    // 마케팅 정보 수신동의 회원가입 시, 키벨류 필요
    const [isMarketingAgree, setIsMarketingAgree] = useState(false);

    const isActive = useCallback(() => {
        let isOk = emailValidation.message && !emailValidation.error;
        isOk = isOk && requireAllCheck && passwordMinLength && passwordRegYN && passwordIncludeId;

        setActiveButton(!!isOk);
    }, [emailValidation, requireAllCheck, passwordMinLength, passwordIncludeId, passwordRegYN]);

    const [open, setOpen] = useState(false);
    const [alertData, setAlertData] = useState({
        title: '',
        description: ''
    });

    const dispatch = useDispatch();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (formValue) {
            // 패스워드체크
            setPasswordValid();
        }
    }, [formValue]);
    useEffect(() => {
        isActive();
    }, [isActive]);
    // 회원가입 처리
    const nextPage = () => {
        const reqNum = localStorageUtil.get('indentification-reqNum');

        if (reqNum?.length) {
            fetchWrapper
                .post(`/member/join`, {
                    reqNum,
                    email: formValue.userMail.trim().toLowerCase(),
                    password: formValue.userPassword,
                    //마케팅 동의 여부 추가
                    isMarketingAgree
                })
                .then((response) => {
                    if (response?.code === SUCCESS_CODE) {
                        // 스토어 , 로컬스토리지 토큰 세팅
                        const authToken = response?.data?.authToken;
                        // console.log('[authToken] ::: ', authToken);
                        store.dispatch(
                            authActions.setToken({
                                accessToken: authToken.accessToken,
                                refreshToken: authToken.refreshToken
                            })
                        );
                        // 스토어 유저정보 세팅
                        store.dispatch(userActions.getAll());
                        history.navigate('/member/join/complete', {
                            state: {member: response.data}
                        });
                    } else {
                        setAlertData({
                            title: '서비스 안내',
                            message: response?.message
                        });
                        handleOpen();
                    }
                });
        } else {
            //인증오류
            // console.debug("인증오류");
        }
    };

    const onClickMailVendor = (e) => {
        let appendData = e.target.textContent.substring(1);

        setFormValue({
            ...formValue,
            userMail: `${formValue?.userMail}${appendData}`
        });
        closeVender();
        onValidEmail(`${formValue?.userMail}${appendData}`);
    };

    const closeVender = () => setVenderOpen(false);

    const onDeleteInput = (name = '') => {
        setFormValue({
            ...formValue,
            [name]: ''
        });
        clearInputValidation(name);
    };

    const clearInputValidation = (name) => {
        if (name === 'userMail') {
            setEmailValidation({error: false, message: ''});
        } else if (name === 'userPassword') {
            setPasswordMinLength(false);
            setPasswordIncludeId(false);
            setPasswordRegYN(false);
        }
    };

    const onBlurEmail = () => {
        appUtils.defaultPadding();
        if (venderOpen) return;
        let value = formValue?.userMail;
        if (!value) {
            clearInputValidation('userMail');
            return;
        }
        onValidEmail(value);
    };

    const onValidEmail = (value) => {
        let lowerMail = value.toLowerCase();
        // 이메일 형식 확인
        if (!appUtils.checkEmailReg(lowerMail)) {
            setEmailValidation({error: true, message: errMsg.USER_ID_REG});
        }
        // TODO ollie 중복체크 API 호출
        fetchWrapper.get(`/member/email/${lowerMail}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setEmailValidation({
                    error: false,
                    message: successMsg.USER_ID_CHECK_OK
                });
            } else if (response.code === '8015') {
                // 탈퇴한 계정의 이메일 입력한 경우, alert로 띄워주고 email영역을 지움
                setAlertData({
                    title: '가입안내',
                    message: errMsg.USER_ID_WITHDRAWAL_CHECK
                });
                handleOpen();
                onDeleteInput('userMail');
            } else if (response.code === '8011') {
                // 이메잏 형식이 유효하지 않음
                setEmailValidation({
                    error: true,
                    message: errMsg.USER_ID_REG
                });
            } else if (response.code === '5000') {
                // db에 활성화 상태인 중복된 아이디가 존재함
                setEmailValidation({
                    error: true,
                    message: errMsg.USER_ID_EXIST
                });
            } else {
                setEmailValidation({
                    error: true,
                    message: response?.message
                });
            }
        });
    };

    const setPasswordValid = () => {
        setPasswordMinLength(appUtils.minLengthCheck(formValue.userPassword, window.config.password.minLength));
        setPasswordIncludeId(appUtils.passwordValidCheck(formValue));
        setPasswordRegYN(appUtils.passwordRegCheck(formValue.userPassword));
    };

    const onChangeEmail = (e) => {
        let value = e.target.value.replaceAll(/\s/gi, '');
        setFormValue({
            ...formValue,
            [e.target.name]: value
        });
        setVenderOpen(value.endsWith('@'));
    };

    // 페이지 진입시 첫 input 자동 포커스
    useEffect(() => {
        appUtils.firstInputFocus();

        viewSignupEmailStep2();
    }, []);

    useEffect(() => {
        function handleEvent(message) {
            console.log(message.data);
            let type = JSON.parse(message.data).type;
            let isAlertOpen = JSON.parse(message.data).isAlertOpen;
            console.log('type', type);
            console.log('isAlertOpen', isAlertOpen);

            if (type === 'authenticationOpen') {
                if (isAlertOpen === true) {
                    dispatch(
                        alertActions.showAlert({
                            type: CNFRMTITMESSAGE,
                            messageData: {
                                title: '가입취소 안내',
                                message: '입력하신 모든 내용이 삭제됩니다. \n 회원가입 진행을 취소하시겠습니까?'
                            }
                        })
                    );

                    appUtils.openSignUpConfirmPostMsg();
                } else {
                    confirmClose();
                }
            } else if (type === 'webModalClose') {
                console.log('여기');
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);

        return () => {
            document.removeEventListener('message', handleEvent);
            appUtils.closeSignUpConfirmPostMsg();
        };
    }, []);

    const confirmClose = () => {
        dispatch(alertActions.closeAlert());
        appUtils.closeSignUpConfirmPostMsg();
    };

    const handleYes = () => {
        /*console.log('에 눌렀음');*/
        appUtils.closeSignUpConfirmPostMsg();
        history.navigate('/member');
    };

    const handleNo = () => {
        appUtils.closeSignUpConfirmPostMsg();
        /*console.log('아니오 눌렀음');*/
    };

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="membership">
                    <div className="inner">
                        <form autoComplete="off">
                            <TitleArea pageTitle={pageTitle.joinProcess} />
                            <div className="fieldAreaWrap">
                                {/* 에러 메시지 출력시 fieldArea, textField, infoMsg에 클래스 error 추가. */}

                                <div className="row fieldArea">
                                    <input
                                        {...{
                                            type: 'email',
                                            name: 'userMail',
                                            maxLength: EMAIL_MAXLENGTH,
                                            minLength: 6,
                                            value: formValue?.userMail,
                                            placeholder: '이메일 아이디',
                                            className: `textField ${emailValidation?.error && 'is-invalid error'}`,
                                            onFocus: () => appUtils.offsetPadding(),
                                            onBlur: () => onBlurEmail(),
                                            onChange: (e) => {
                                                appUtils.isValidInputCheck(e, EMAIL_MAXLENGTH, CHK_EMAIL_INPUT);
                                                onChangeEmail(e);
                                            }
                                        }}
                                    />
                                    <div className="btnArea">
                                        {formValue?.userMail.length ? (
                                            <button
                                                type="button"
                                                className="btnDelete"
                                                onClick={() => onDeleteInput('userMail')}
                                            >
                                                삭제
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    {emailValidation.message && (
                                        <p className={`infoMsg ${emailValidation.error && 'error'}`}>
                                            {emailValidation.message}
                                        </p>
                                    )}
                                    <div id="mailVendorList" className={`checkLayer ${venderOpen || 'hide'}`}>
                                        <div className="layerCon">
                                            <ul className="mailList">
                                                <li>
                                                    <button type="button" onClick={onClickMailVendor}>
                                                        @naver.com
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={onClickMailVendor}>
                                                        @gmail.com
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={onClickMailVendor}>
                                                        @kakao.com
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={onClickMailVendor}>
                                                        @hanmail.net
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type="button" onClick={onClickMailVendor}>
                                                        @nate.com
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <SettingPwField
                                    formValue={formValue}
                                    setFormValue={setFormValue}
                                    passwordMinLength={passwordMinLength}
                                    passwordRegYN={passwordRegYN}
                                    passwordIncludeId={passwordIncludeId}
                                    setPasswordValid={setPasswordValid}
                                />
                                <Agreement
                                    setRequireAllCheck={setRequireAllCheck}
                                    setIsMarketingAgree={setIsMarketingAgree}
                                />
                            </div>
                            <div className="btnFixedBottom">
                                <div className="inner">
                                    <Button
                                        type="button"
                                        disabled={!activeButton}
                                        background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                        onClick={nextPage}
                                    >
                                        회원가입 완료
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <DefaultAlert open={open} handleClose={handleClose} title={alertData.title} contents={alertData.message} />
            <CommonAlert successCallBack={handleYes} failCallBack={handleNo} />
        </>
    );
}

export default SettingIdPw;
