import React, {useEffect, useState} from 'react';
import {isNil} from 'lodash';
import Badge from 'components/badge/Badge';
import {YN} from 'common/constants';

function SearchingItem({searchingItem, searchContent, onClickGolfClubDetail}) {
    const [nameSplit, setNameSplit] = useState([]);
    const [locationSplit, setLocationSplit] = useState([]);

    useEffect(() => {
        if (isNil(searchingItem.golfClubShortName)) {
            setNameSplit(searchingItem.golfClubName.split(searchContent));
        } else {
            setNameSplit(searchingItem.golfClubShortName.split(searchContent));
        }

        setLocationSplit(searchingItem.address.split(searchContent));
    }, [searchContent]);

    return (
        <li className="searchingItem" onClick={() => onClickGolfClubDetail(searchingItem)}>
            <div className="searchingName">
                <div className="golfZoneName">
                    {nameSplit[0]}
                    {nameSplit.length > 1 ? (
                        <>
                            <span style={{color: '#3906C3'}}>{searchContent}</span>
                            {nameSplit[1]}
                        </>
                    ) : (
                        ''
                    )}
                </div>
                {searchingItem.isSelfCheckInPossible && searchingItem.promotionActiveYn === YN.Y && (
                    <Badge type={'EVENT'} />
                )}
                {!searchingItem.isPartner && <Badge type={'UNAFFILIATED'} />}
            </div>
            <div className="golfZoneLocation">
                {locationSplit[0]}
                {locationSplit.length > 1 ? (
                    <div>
                        <span style={{color: '#3906C3'}}>{searchContent}</span>
                        {locationSplit[1]}
                    </div>
                ) : (
                    ''
                )}
            </div>
        </li>
    );
}

export default SearchingItem;
