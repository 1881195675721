import {fetchWrapper} from '_helpers/fetch-wrapper';
import amplitude from 'amplitude-js';
import {SUCCESS_CODE} from 'common/constants';
import {validationUtil} from 'common/payment';

export const sendAmplitudeEvent = (eventName, eventProperty) => {
    amplitude.getInstance().logEvent(eventName, eventProperty);
};

export const setUserIdentity = (user) => {
    // TODO: fetch user information
    if (!user) return;
    const amplitudeUser = new amplitude.Identify();
    Object.keys(user).map((key) => {
        amplitudeUser.set(`${key}`, user[key]);
    });

    amplitude.identify(amplitudeUser);

    return amplitudeUser;
};

export const getAmplitudeUserInfo = (userPlatform) => {
    fetchWrapper.get('/amplitude-user/property').then((response) => {
        if (response?.code === SUCCESS_CODE) {
            if (!validationUtil.isNullChk(response.data)) {
                if (validationUtil.isNullChk(userPlatform)) {
                    response.data.user_platform = '';
                } else {
                    response.data.user_platform = userPlatform;
                }
            }

            /* userPlatform property 추가*/
            setUserIdentity(response?.data);
        }
    });
};

export const getAmplitudeRootPath = () => {
    return sessionStorage.getItem('root');
};

// getUserAgent Property 추가
/*export const getUserAgent = () => {
    let userAgent = window.navigator.userAgent.toLowerCase();

    // mac / windows / IOS (Ipad, Iphone) / AOS / Mobile Web
    switch (true) {
        // mac
        case userAgent.includes('macintosh'):
            userAgent = 'MacOS';
            break;
        // window
        case userAgent.includes('windows'):
            userAgent = 'Windows';
            break;
        case userAgent.includes('linux'):
            userAgent = 'Linux';
            break;
        case userAgent.includes('iphone'):
            // crios : chrome on ios
            if (userAgent.includes('crios') || userAgent.includes('safari') || userAgent.includes('firefox')) {
                userAgent = 'Mobile Web';
                break;
            } else {
                userAgent = 'iPhone';
                break;
            }
        case userAgent.includes('ipad'):
            if (userAgent.includes('crios') || userAgent.includes('safari') || userAgent.includes('firefox')) {
                userAgent = 'Mobile Web';
                break;
            } else {
                userAgent = 'iPad';
                break;
            }
        case userAgent.includes('android'):
            if (userAgent.includes('chrome') || userAgent.includes('firefox')) {
                userAgent = 'Mobile Web';
                break;
            } else {
                userAgent = 'Android';
                break;
            }
        default:
            userAgent = 'Other';
            break;
    }

    return userAgent;
};*/
