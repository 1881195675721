import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';

const PaymentBookingNotice = (props) => {
    const {prepareRoundBooking} = props;

    return (
        <>
            <div className="payment-booking-info-inner-condition">
                <h2>{bookingInfoProperty.booking_golfclub_notice} </h2>
                <ul className="payment-booking-info-inner-condition-list" style={{whiteSpace: 'break-spaces'}}>
                    <div dangerouslySetInnerHTML={{__html: prepareRoundBooking?.notice}} className="preLine"></div>
                </ul>
            </div>
        </>
    );
};
export default PaymentBookingNotice;
