import React, {useEffect, useState} from 'react';
import Button from '../button/Button';
import Header from '../Header';
import TitleArea from '../common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import Checkbox from '../../components/button/Checkbox';
import './mypage.scss';
import {fetchWrapper, history} from '../../_helpers';
import {CNFRMTITMESSAGE, SUCCESS_CODE} from '../../common/constants';
import CommonAlert from '../../components/alert/CommonAlert';
import {useDispatch, useSelector} from 'react-redux';
import {AlertUtils} from '../../common/alertUtils';
import {authActions, store} from '../../_store';
import {localStorageUtil} from '../../common/utils';
import DefaultAlert from '../alert/DefaultAlert';
import {errMsg} from '../../common/messages';
import {viewWithdrawal} from '../../_helpers/amplitude/events/view.events';

function Withdrawal({title}) {
    const [activeButton, setActiveButton] = useState(false);
    const [_goLogout, setGoLogout] = useState(false);
    const [authUrl, setAuthUrl] = useState('');

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    const dispatch = useDispatch();
    const member = useSelector((x) => x?.users?.users);

    //탈퇴 불가 모달
    const [showWithdrawalNotPossibleModal, setShowWithdrawalNotPossibleModal] = useState(false);
    const handleWithdrawalNotPossibleModalClose = () => setShowWithdrawalNotPossibleModal(false);
    const [modalData, setModalData] = useState({
        title: '',
        description: ''
    });

    window.alert = AlertUtils();

    fetchWrapper.get(`/authentication/apple/revoke`, null).then((response) => {
        if (response.code === SUCCESS_CODE) {
            setAuthUrl(response?.data || '');
        }
    });

    useEffect(() => {
        viewWithdrawal();
    }, []);

    useEffect(() => {
        const listener = (e) => {
            if (!e || typeof e?.data === 'object') {
                return;
            }
            try {
                let data = JSON.parse(e?.data);
                if (data && data?.type === 'kakao/unlink') {
                    fetchWrapper.delete(`/member`).then((response) => {
                        if (response?.code === SUCCESS_CODE) {
                            console.log('카카오탈퇴::');
                            dispatch(authActions.logout({uri: '/mypage/withdrawal-complete'}));
                        } else {
                            alert.show(null, '시스템 장애 발생', '시스템 오류로 인해 정상적으로 처리되지 않았습니다.');
                            setGoLogout(false);
                        }
                    });
                }
                return true;
            } catch (e) {
                return false;
            }
        };

        // RN에서 웹으로 데이터를 전송했을때 message이벤트가 실행됩니다.
        document.addEventListener('message', listener);
        window.addEventListener('message', listener);
        return () => {
            document.removeEventListener('message', listener);
            window.removeEventListener('message', listener);
        };
    });

    //탈퇴 동의 체크
    const onChecked = (state) => {
        setActiveButton(state);
    };

    const onClick = () => {
        alert.show(CNFRMTITMESSAGE, null, '탈퇴하시면 계정을 복구할 수 없습니다. \n그래도 탈퇴하시겠습니까?');
        // setGoLogout(true);
    };

    //Alert 확인 버튼 콜백
    // const handleWithdrawal = () => {
    //     if (goLogout) {
    //         nextPage();
    //     }
    // };

    const nextPage = () => {
        history.navigate('/mypage/withdrawal-complete', {state: {flag: 'logout'}});
    };

    //탈퇴 완료 Alert
    const handleOpen = () => {
        // 사용자 정보 존재 여부 확인
        if (!member) {
            return false;
        }
        // 예약 활성건이 존재하면 탈퇴 불가능
        if (member?.hasBooking === true) {
            setModalData({
                title: '',
                description: errMsg.WITHDRAWAL_NOT_POSSIBLE
            });
            setShowWithdrawalNotPossibleModal(true);

            setGoLogout(false);
        } else if (member?.memberChannel === 'EMAIL' || member?.memberChannel === 'KAKAO') {
            localStorageUtil.set('memberChannel', member?.memberChannel);
            fetchWrapper.delete(`/member`).then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    // 삭제처리 후 정상 진행
                    console.log(member?.memberChannel + '계정 삭제 완료 ');
                    setGoLogout(true);
                    nextPage();
                } else {
                    setModalData({
                        title: '시스템 장애 발생',
                        description: '시스템 오류로 인해 정상적으로 처리되지 않았습니다.'
                    });

                    setShowWithdrawalNotPossibleModal(true);
                    setGoLogout(false);
                }
            });
        } else if (member?.memberChannel === 'APPLE') {
            localStorageUtil.set('memberChannel', member?.memberChannel);
            //회원 탈퇴용 URL 획득
            window.location.href = authUrl;
        } /* else if (member?.memberChannel === 'KAKAO') {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'kakao/unlink'
                })
            );
        }*/
    };

    // 부킹예약건이 존재해서 탈퇴 불가 확인창

    return (
        <>
            <Header back={true} pageTitle="회원탈퇴" />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="withdrawalInfo">
                            <div className="section">
                                <strong className="tt">회원탈퇴 시 처리 내용</strong>
                                <ul className="listType04">
                                    <li>가입 시 회원이 제공한 개인정보를 즉시 파기하며, 복구가 불가능합니다.</li>
                                    <li>
                                        회사의 내부 방침 또는 관련 법령이 정한 내용에 따라 일부 회원정보를 별도로 보관할
                                        수 있으며 법령에 의한 경우를 제외하고 다른 목적으로 이용하지 않습니다.
                                    </li>
                                    <li>고객센터에 문의하신 내용은 탈퇴 여부와 관계 없이 3년간 보관 후 파기합니다.</li>
                                    <li>회원 탈퇴 후 동일한 아이디로 재가입이 불가능합니다.</li>
                                </ul>
                            </div>
                            <div className="section">
                                <strong className="tt">회원탈퇴 불가한 경우</strong>
                                <ul className="listType04">
                                    <li>
                                        예정된 예약 건이 있을 경우 탈퇴가 불가합니다. 예약 취소 또는 이행 후 탈퇴하실 수
                                        있습니다.
                                    </li>
                                </ul>
                            </div>
                            <div className="agreeCheckLabel">
                                <label>
                                    <Checkbox isRoundBg={true} onChecked={onChecked} />
                                    <p>위 내용을 모두 확인했습니다.</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btnFixedBottom">
                    <div className="inner">
                        <Button
                            type="button"
                            background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                            onClick={onClick}
                            disabled={!activeButton}
                        >
                            탈퇴하기
                        </Button>
                    </div>
                </div>
            </div>

            {/* 탈퇴 Alert */}
            <CommonAlert successCallBack={handleOpen} />
            <DefaultAlert
                open={showWithdrawalNotPossibleModal}
                handleClose={handleWithdrawalNotPossibleModalClose}
                title={modalData.title}
                contents={modalData.description}
            />
            {/*<CommonAlert successCallBack={handleWithdrawal}  />*/}
        </>
    );
}

export default Withdrawal;
