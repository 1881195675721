import {Drawer} from '@mui/material';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import {completeSelfCheckin} from '_helpers/amplitude/events/etc.event';
import {smartRoundActions} from '_store/smartRound.slice';
import {debounce} from 'lodash';
import {QRCodeCanvas} from 'qrcode.react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchWrapper} from '../../../_helpers';
import {viewQrSelfCheckin} from '../../../_helpers/amplitude/events/view.events';
import {ReactComponent as IconClose} from '../../../assets/images/smart-round/btnClose.svg';
import {ALREADY_CHECKEDIN, CHECKIN_TIME_ONE_HOUR_CODE, SUCCESS_CODE} from '../../../common/constants';
import {appUtils} from '../../../common/utils';
import DefaultAlert from '../../alert/DefaultAlert';
import Icon from '../../icons/Icon';
import {promotionActions} from '_store/promotion.slice';

let _time = 0;
const startTimer = (inTime, timeOver, setTimer) => {
    _time = 59;
    const _timer = setInterval(() => {
        _time--;
        if (_time > 0) {
            inTime(_time);
        } else {
            timeOver();
            clearInterval(_timer);
            setTimer(null);
        }
    }, 1000);
    setTimer(_timer);
};

function QrCheckInModal(props) {
    const {drawerOpen, drawerClose} = props;

    const user = useSelector((x) => x?.smartRound.user);
    const bookingId = useSelector((x) => x?.smartRound.bookingId);
    const round = useSelector((x) => x?.smartRound.round);
    const qrTime = useSelector((x) => x?.smartRound.qrTime);
    const qr = useSelector((x) => x?.smartRound.qr);
    const timer = useSelector((x) => x?.smartRound.timer);

    const dispatch = useDispatch();

    // qr 체크인 불가 Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContents, setAlertContents] = useState('');
    const [isActive, setIsActive] = useState(false);

    let number = String(qrTime).padStart(2, 0);

    const dDay = () => {
        let bookingDate = round?.roundAt;
        let diff = appUtils.calcDDay(bookingDate);
        if (diff === 0) {
            return true;
        } else {
            return false;
        }
    };

    const timeOverCallback = () => {
        dispatch(smartRoundActions.setQrTime({time: 59}));
        setIsActive(false);
    };

    const clickResetTimer = debounce(() => {
        dispatch(smartRoundActions.getQr({bookingId: bookingId, userId: user.id}))
            .then((res) => {
                console.log('res', res);
                // if (res?.code === ALREADY_CHECKEDIN) {
                // setQrReset(true);
                // } else if (res?.code === SUCCESS_CODE) {
                setIsActive(true);
                _time = 59;
                dispatch(smartRoundActions.setQrTime({time: 59}));
                startTimer(
                    (t) => {
                        dispatch(smartRoundActions.setQrTime({time: t}));

                        // 키오스크 QR체크인 여부 확인 >> 체크인 시 완료 페이지로 렌더링
                        fetchWrapper.get(`/booking/${bookingId}/qrcode/check/${res.payload}`, null).then((response) => {
                            if (response?.code === SUCCESS_CODE || response?.code === ALREADY_CHECKEDIN) {
                                dispatch(smartRoundActions.setCheckin({checkin: true}));
                                // 티타임 경과 1시간 이내일 시 > 체크인 완료 후 Alert 노출
                                fetchWrapper
                                    .get(`/booking/checkin/possibility?bookingId=${bookingId}&checkinType=QRCODE`)
                                    .then((response) => {
                                        if (response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                                            // 셀프체크인 Alert Open
                                            dispatch(
                                                smartRoundActions.setCheckInAlertMessage({
                                                    checkInAlertMessage: response?.message
                                                })
                                            );
                                            clearInterval(timer);
                                            dispatch(smartRoundActions.setTimer({timer: null}));
                                        }
                                    });
                                fetchWrapper
                                    .get(`/amplitude-events/reservation-complete?bookingId=${bookingId}`, null)
                                    .then((response) => {
                                        if (response.code === SUCCESS_CODE) {
                                            const _data = response.data;
                                            _data.checkin_type = 'qr';
                                            completeSelfCheckin(_data, getAmplitudeRootPath());
                                            dispatch(promotionActions.setPromotionCheckinModal(true));
                                        }
                                    });
                            }
                        });
                    },
                    timeOverCallback,
                    (_timer) => {
                        dispatch(smartRoundActions.setTimer({timer: _timer}));
                    }
                );
                //     }
            })
            .catch((error) => console.log('error:', error));
    }, 300);

    useEffect(() => {
        if (drawerOpen) {
            fetchWrapper.get(`/amplitude-events/view-qr-self-checkin?bookingId=${bookingId}`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    viewQrSelfCheckin(response.data);
                }
            });
        }

        if (dDay()) {
            if (drawerOpen && qrTime === 59) {
                // QR체크인 가능 여부 체크
                // >> 이미 QR체크인 진입 한 상태에서 셀프체크인 가능 시간 초과 시 Alert 즉시 노출
                fetchWrapper
                    .get(`/booking/checkin/possibility?bookingId=${bookingId}&checkinType=QRCODE`)
                    .then((response) => {
                        if (response.code === SUCCESS_CODE || response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                            clickResetTimer();
                        } else {
                            setAlertContents(response.message);
                            setAlertOpen(true);
                        }
                    });
            }
        }
    }, [drawerOpen]);

    return (
        <Drawer anchor="bottom" open={drawerOpen} onClose={drawerClose}>
            <div className="qr-drawer">
                <div className="qr-wrapper">
                    <div className="qr-title">
                        <h2>QR코드 체크인하세요!</h2>
                        <p>예약 번호 : {round?.gbNo}</p>
                    </div>
                    <div className="check-in-image">
                        {dDay() && qr ? (
                            <>
                                <div className="qr-code">
                                    <QRCodeCanvas
                                        className={isActive && qrTime ? '' : 'disabled'}
                                        value={qr}
                                        size={140}
                                        level={'H'}
                                    />
                                </div>
                                {isActive && qrTime ? (
                                    <p className="qrInfoTxt">QR코드 유효시간 00:{number}</p>
                                ) : (
                                    <div onClick={clickResetTimer}>
                                        <p className="qrInfoTxt">
                                            <i className="icon">
                                                <Icon name="refresh" width="16" height="17" viewBox="0 0 16 17" />
                                            </i>{' '}
                                            QR코드 유효시간이 만료되었습니다
                                        </p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="qr-code">
                                    <i className="no-qr">발급된 QR 코드가 없습니다.</i>
                                </div>
                                <p className="qrInfoTxt">QR코드는 당일에 발급됩니다</p>
                            </>
                        )}
                        <div className="roundInfo">
                            <span>{appUtils.dateFormatTypeFull(round?.roundAt)}</span>
                            <span>
                                {round?.golfClubShortName} {round?.course}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="btn-wrapper">
                    <button className="btn-close" onClick={drawerClose}>
                        <IconClose />
                    </button>
                </div>
            </div>

            <DefaultAlert open={alertOpen} handleClose={() => setAlertOpen(false)} contents={alertContents} />
        </Drawer>
    );
}

export default QrCheckInModal;
