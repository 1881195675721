import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {bookingConstant, paymentConstant, qsUtil} from 'common/payment';
import {SUCCESS_CODE} from 'common/constants';
import {fetchWrapper, history} from '_helpers';
import {reservationComplete} from '_helpers/amplitude/events/etc.event';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import {etcTaxonomy} from '_helpers/amplitude/taxonomy/etc-event.taxonomy';
import {viewTaxonomy} from '_helpers/amplitude/taxonomy/view.taxonomy';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {appUtils} from 'common/utils';

const PaymentBookingComplete = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const successFailId = searchParams.get('successFailId');
    let bookingData = {};

    // 예약하려는 티타임 정보
    const getPrepareRoundBooking = async () => {
        return await fetchWrapper
            .get(`/prepare/round-booking`)
            .then((response) => response.code === SUCCESS_CODE && response);
    };

    // 예약하려는 티타임 가격 정보 상세
    const getPrepareRoundBookingTradeAmount = async (roundPlayerCount) => {
        return await fetchWrapper
            .get(`/prepare/round-booking/trade-amount?roundPlayer=${roundPlayerCount}`)
            .then((response) => response.code === SUCCESS_CODE && response);
    };

    // 결제 실패 및 오류
    const redirectPaymentUrl = async (paymentFailpayload) => {
        const {eventName, type, code, message, roundPlayer} = paymentFailpayload;

        const prepareRoundBooking = await getPrepareRoundBooking();
        const prepareRoundBookingTradeAmount = await getPrepareRoundBookingTradeAmount(roundPlayer);

        bookingData = {
            eventName: eventName,
            type: type,
            code: code,
            message: message,
            data: {
                ...prepareRoundBooking?.data,
                roundPlayer: roundPlayer,
                paymentType: prepareRoundBookingTradeAmount?.data?.paymentType,
                totalAmount: prepareRoundBookingTradeAmount?.data?.totalAmount,
                totalGreenFee: prepareRoundBookingTradeAmount?.data?.totalGreenFee,
                cartFee: prepareRoundBookingTradeAmount?.data?.isCartFeeIncluded
                    ? paymentInfoProperty.cartFee_included_in_greenFee
                    : appUtils.numberFormat(prepareRoundBookingTradeAmount?.data?.cartFee),
                expectOnsiteAmount: prepareRoundBookingTradeAmount?.data?.expectOnsiteAmount,
                caddieFee: prepareRoundBookingTradeAmount?.data?.caddieFee,
                transactionMethod: prepareRoundBookingTradeAmount?.data?.transactionMethod
            }
        };

        history.navigate('/payment/booking-fail', {state: bookingData});
    };

    // 예약 성공 이후 예약 알림톡 호출
    const smsRoundBooking = async (roundBookingId) => {
        await fetchWrapper
            .post(`/sms/round-booking`, {roundBookingId: roundBookingId})
            .then((response) => response.code === SUCCESS_CODE && response);
    };

    const amplitudeEvents = async (amplitudeEventProperty) => {
        const {eventName, bookingId} = amplitudeEventProperty;

        await fetchWrapper
            .get(`/amplitude-events/click-reservation-btn?roundBookingId=${bookingId}`)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    const data = {...response.data, root: getAmplitudeRootPath()};
                    if (eventName === etcTaxonomy.reservation_complete) {
                        reservationComplete(data);
                    }
                }
            });
    };

    useEffect(() => {
        /* 결제 성공 & 실패 여부 조회 */
        const getPaymentNextUrl = async () => {
            try {
                const viewReservationPaymentFail = viewTaxonomy.view_reservation_payment_fail;
                const reservationComplete = etcTaxonomy.reservation_complete;

                const paymentResponse = await fetchWrapper.get(
                    `/payment/next-url?transactionSuccessFailLogId=${successFailId}`
                );

                const parseQueryString = qsUtil.parseQueryString(paymentResponse?.data?.merchantParameter);
                const roundBookingPayload = {
                    prepareRoundBookingId: Number(parseQueryString.prepareRoundBookingId),
                    pgTradeNumber: paymentResponse?.data?.tradeNumber,
                    roundPlayerCount: Number(parseQueryString.roundPlayerCount)
                };

                // 결제 성공
                if (paymentResponse?.data?.outboundResultCode === paymentConstant.hectoOutResultCode.SUCCESS) {
                    const roundbookingResponse = await fetchWrapper.post('/round-booking', roundBookingPayload);
                    // 예약 실패
                    if (
                        !roundbookingResponse.data?.isBookingComplete ||
                        roundbookingResponse?.code === bookingConstant.roundBookingResultCode.FAIL
                    ) {
                        // 예약 실패 화면으로 이동
                        const paymentFailpayload = {
                            eventName: viewReservationPaymentFail,
                            type: 'booking',
                            code: roundbookingResponse?.code,
                            message: roundbookingResponse?.message,
                            roundPlayer: roundbookingResponse?.data?.roundPlayerCount
                        };

                        await redirectPaymentUrl(paymentFailpayload);
                    }
                    // 예약 성공
                    else {
                        const roundbookingSuccessResponse = await fetchWrapper.get(
                            `/round-booking/${roundbookingResponse?.data?.bookingId}`
                        );

                        await smsRoundBooking(roundbookingResponse?.data?.bookingId);
                        await amplitudeEvents({
                            eventName: reservationComplete,
                            bookingId: roundbookingSuccessResponse?.data?.roundBookingId
                        });

                        bookingData = {
                            eventName: reservationComplete,
                            type: 'booking',
                            code: roundbookingSuccessResponse?.code,
                            message: roundbookingSuccessResponse?.message,
                            data: roundbookingSuccessResponse?.data
                        };

                        history.navigate('/payment/booking-success', {
                            state: bookingData
                        });
                    }
                } else {
                    // 결제 실패 화면으로 이동
                    const paymentFailpayload = {
                        eventName: viewReservationPaymentFail,
                        type: 'payment',
                        code: paymentResponse?.data?.outboundResultCode ?? paymentResponse?.code,
                        message: paymentResponse?.data?.outboundResultMessage ?? paymentResponse?.message,
                        roundPlayer: roundBookingPayload?.roundPlayerCount
                    };
                    await redirectPaymentUrl(paymentFailpayload);
                }
            } catch (error) {
                console.error('결제 진행 중 오류가 발생했습니다:', error);
                history.navigate('/');
            }
        };

        getPaymentNextUrl();
    }, []);

    return <></>;
};

export default PaymentBookingComplete;
