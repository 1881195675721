import React, {useCallback, useEffect, useState} from 'react';
import {appUtils} from '../../common/utils';
import NoImg from '../../components/common/NoImg';
import {history} from '../../_helpers';

function CardTitle({bookingData, onClickSelfCheckInBtn}) {
    const [dDay, setDDay] = useState('');

    // 날짜 형식 변경
    const bookingTime = appUtils.dateFormatTypeRoundAt(bookingData?.roundAt);

    const diffDay = useCallback(() => {
        if (!bookingData?.roundAt) {
            setDDay('');
            return;
        }
        let bookingDate = bookingData?.roundAt;
        let diff = appUtils.calcDDay(bookingDate);
        if (diff == 0) {
            setDDay('D-day');
        } else if (diff > 0) {
            setDDay(`D-${diff}`);
        } else {
            setDDay('');
        }
    }, [bookingData]);

    useEffect(() => {
        diffDay();
    }, [diffDay]);

    // 필드 상세로 이동
    const goFiledDetail = () => {
        history.navigate(`/field-detail/${bookingData.golfClubId}`, {state: {flag: 'smartRoundBookingList'}});
    };

    // 예약 상태별 아이콘
    const statusIcon = (state) => {
        if (state && state !== '') {
            if (bookingData?.bookingStatusDetail === 'F00') {
                if (['HOMEPAGE', 'TEL', 'OTHER'].includes(bookingData?.bookingType)) {
                    return <b className="statusIcon outReserve">외부예약</b>;
                }
                return <b className="statusIcon">{state}</b>;
            }
            return <b className="statusIcon black">{state}</b>;
        }
    };

    return (
        <>
            <div className="rezInfoWrap">
                <div className="rezInfo">
                    {statusIcon(bookingData?.bookingStatusDetailName)}
                    <span className="num">예약 번호 : {bookingData?.gbNo}</span>
                </div>
                <b className="dDay">{dDay}</b>
            </div>
            <div className="fieldInfo border">
                <div className="linkCon">
                    <div className="txtArea">
                        <strong className="tt" onClick={goFiledDetail}>
                            {bookingData.golfClubShortName}
                        </strong>
                        {bookingData?.isSelfCheckInPossible && (
                            <button type="button" className="statusIcon selfCheckIn" onClick={onClickSelfCheckInBtn}>
                                셀프체크인
                            </button>
                        )}

                        <div className="roundInfo" onClick={goFiledDetail}>
                            <p className="date">{appUtils.dateFormatTypeFull(bookingData.roundAt)}</p>
                            <p className="list">
                                <span>{bookingData.courseName}</span>
                                <span>{bookingData.hole}</span>
                            </p>
                        </div>
                    </div>
                    <div className="thumbArea" onClick={goFiledDetail}>
                        {bookingData.stillImageUrl ? (
                            <img src={bookingData.stillImageUrl} alt={bookingData.golfClubName} />
                        ) : (
                            <NoImg logoWidth="20px" />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardTitle;
