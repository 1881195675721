import React, {useCallback, useEffect, useState} from 'react';
import TitleArea from '../../components/common/TitleArea';
import './course.scss';
import CourseCon from './CourseCon';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import CourseModal from './CourseModal';
import {useLocation} from 'react-router-dom';
import {checkinActions} from '../../_store/checkin.slice';
import {useDispatch, useSelector} from 'react-redux';
import RoundNavigation from '../navigation/RoundNavigation';
import {localStorageUtil} from '../../common/utils';
import DefaultAlert from '../alert/DefaultAlert';
import Header from '../Header';
import {viewIntroCourseguide} from '../../_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';

function CourseGuide({title}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const _roundData = JSON.parse(localStorageUtil.get('smartRound'));
    const {todayReservData} = useSelector((x) => x?.checkin);
    const [roundData, setRoundData] = useState(_roundData || location?.state?.roundData);
    const [smartRoundOpen, setSmartRoundOpen] = useState((roundData && roundData.bookingId) || false);

    // 체크인 데이터 localStorage로 가져오기
    const [checkInData, setCheckInData] = useState(null);
    useEffect(() => {
        setCheckInData(JSON.parse(localStorageUtil.get('checkInData')));
    }, [localStorageUtil.get('checkInData')]);

    const [courseId, setCourseId] = useState('');
    const [courseList, setCourseList] = useState(null);
    const [courseInfo, setCourseInfo] = useState(null);
    const [mapControl, setMapControl] = useState(null);
    const [handicapDisplayYN, setHandicapDisplayYN] = useState(null);

    // 모달창 open, close
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleAlertOpen = (msg) => {
        setAlertMessage(msg);
        setAlertOpen(true);
    };
    const handleAlertClose = () => setAlertOpen(false);

    useEffect(() => {
        setCourseId(checkInData?.courseId);
    }, [checkInData]);

    // 스크롤 없는 풀페이지 스타일 선언을 위한 클래스명 추가
    useEffect(() => {
        document.documentElement.classList.add('fullPage');
        return () => {
            document.documentElement.classList.remove('fullPage');
        };
    }, []);

    // --- 모달창 코스 선택 / CourseList ---
    const getCourseList = useCallback(() => {
        fetchWrapper.get(`/course/list/${roundData?.golfClubId}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setCourseList(response?.data?.courseList || null);
            }
        });

        fetchWrapper
            .get(`/amplitude-events/view-intro-courseguide?golfClubId=${roundData?.golfClubId}`)
            .then((response) => {
                let detailData = null;
                if (response?.code === SUCCESS_CODE) {
                    detailData = response?.data;
                    viewIntroCourseguide(detailData, getAmplitudeRootPath());
                }
            });
    }, [roundData?.golfClubId]);

    useEffect(() => {
        getCourseList();
    }, [getCourseList]);

    // --- 코스 정보 / CourseInfo ---
    // const getCourseInfo = () => {
    //     if (!roundData?.golfClubId || !courseId) return;
    //     fetchWrapper.get(`/course/info/${roundData?.golfClubId}?courseId=${courseId}`, null).then((response) => {
    //         if (response?.code === SUCCESS_CODE) {
    //             setCourseInfo(
    //                 {
    //                     ...response?.data?.courseInfo,
    //                     isMenuApplied: checkInData.isMenuApplied
    //                 } || null
    //             );
    //
    //             console.log('response >>>>>>>>>', response?.data);
    //         }
    //     });
    // };

    const getCourseInfoInDetail = () => {
        fetchWrapper.get(`/golfClub/${roundData?.golfClubId}/smart-round`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setRoundData({
                    ...roundData,
                    bookingId: response?.data.bookingId
                });
                setMapControl(response?.data?.mapControlYN);
                setCourseInfo(response?.data || null);
                setHandicapDisplayYN(response?.data.handicapDisplayYN);
                console.log('####handicap: ', response?.data.handicapDisplayYN);
            }
        });
    };

    useEffect(() => {
        getCourseInfoInDetail();
        //getCourseInfo();
    }, [roundData?.golfClubId, courseId]);

    useEffect(() => {
        window.addEventListener('beforeunload', () => {
            setCheckInData(null);
        });
        return () => {
            window.removeEventListener('beforeunload', () => {
                setCheckInData(null);
            });
        };
    }, []);

    /**
     * self checkin 제어
     * 1. 당일건이면 한번만 노출
     * 2. 라운드 예정 하루전 항시 노출
     * 3. 그외 미노출
     */
    useEffect(() => {
        dispatch(checkinActions.showSmartRoundCheckIn(smartRoundOpen));
    }, [smartRoundOpen]);

    const checkInstallApp = () => {
        const clearTimers = () => {
            clearInterval(check);
            clearTimeout(timer);
        };

        const isHideWeb = () => {
            if (document.webkitHidden || document.hidden) {
                clearTimers();
            }
        };
        const check = setInterval(isHideWeb, 200);

        const timer = setTimeout(function () {
            redirectStore();
        }, 500);
    };

    const redirectStore = () => {
        const ua = navigator.userAgent.toLowerCase();

        if (window.confirm('스토어로 이동하시겠습니까?')) {
            window.location.href =
                ua.indexOf('android') > -1
                    ? 'https://play.google.com/store/apps/details?id=xxxxxx'
                    : 'https://apps.apple.com/kr/app/xxxxxx';
        }
    };

    return (
        <>
            <Header back={true} backLink={-1} />
            <div id="contents">
                <div id="courseGuide" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <CourseCon
                            open={open}
                            data={courseInfo}
                            handleAlertOpen={handleAlertOpen}
                            golfClubId={roundData?.golfClubId}
                            golfClubName={roundData?.golfClubName}
                            mapControl={mapControl}
                            handicapDisplayYN={handicapDisplayYN}
                        />
                        {/* 코스선택 모달창 */}
                        <CourseModal
                            open={open}
                            courseList={courseList}
                            setCourseId={setCourseId}
                            handleDrawerClose={handleDrawerClose}
                        />
                    </div>
                </div>
            </div>
            <RoundNavigation courseInfo={courseInfo} handleAlertOpen={handleAlertOpen} />

            <DefaultAlert
                open={alertOpen}
                handleOpen={handleAlertOpen}
                handleClose={handleAlertClose}
                title={''}
                contents={alertMessage}
            />
        </>
    );
}

export default React.memo(CourseGuide);
