import React from "react";
import { useNavigate } from "react-router-dom";

function Close({ onClick }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1, { replace: true });
    };

    return (
        <>
            <button
                type="button"
                className="btnClose"
                onClick={onClick === null ? goBack : onClick}
            >
                닫기
            </button>
        </>
    );
}

export default Close;
