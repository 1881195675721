import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';

function SortModal(props) {
    const {open, setIsSortModalOpen, onSortApply, sortRadioData, onSortRadioChange} = props;

    let [selectSortItem, setSelectSortItem] = useState([]);

    const onSortChange = (e, item) => {
        setSelectSortItem(item);
        onSortRadioChange(e);
    };

    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                onSortClose();
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);

        return () => document.removeEventListener('message', handleEvent);
    }, []);

    const onSortClose = () => {
        setIsSortModalOpen(false);
    };

    return (
        <>
            <Modal open={open}>
                <div className="defaultModal ">
                    <div className="modalContents">
                        <div id="modalTitle" className="sortModalTitle">
                            정렬기준
                        </div>
                        <div>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    className="radio-sortModal-wrap"
                                >
                                    {sortRadioData?.map((item, key) => {
                                        return (
                                            <div
                                                className="labelList radio-sortModal-wrap-labelList"
                                                key={item.orderSeq}
                                            >
                                                <FormControlLabel
                                                    value={item.name}
                                                    checked={item.checked}
                                                    control={
                                                        <Radio
                                                            disabled={item.disabled}
                                                            onClick={(e) => {
                                                                onSortChange(e, item);
                                                                /* setSelectSortItem(item);
                                                           
                                                            onSortRadioChange*/
                                                            }}
                                                        />
                                                    }
                                                    label={item.name}
                                                />
                                            </div>
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="modalBtnArea">
                        <button type="button" onClick={() => onSortApply(selectSortItem)}>
                            적용
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default SortModal;
