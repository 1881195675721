import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '../../components/button/Button';
import MemGateSlider from './MemGateSlider';
import './membership.scss';
import {fetchWrapper, history} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import {viewIntroPage} from '_helpers/amplitude/events/view.events';
import {clickKakaoLoginBtn} from '_helpers/amplitude/events/click.event';
import WebAccessInfoModal from '../modal/WebAccessInfoModal';
import AirbridgeUtil from "../../common/airbridgeUtil";

function Membership() {
    const [appleAuthUrl, setAppleAuthUrl] = useState('');
    const [kakaoAuthInfo, setKakaoAuthInfo] = useState({
        redirectUri: '',
        state: '',
        nonce: ''
    });

    const onClickKakao = () => {
        // console.debug('here');
        // if (window.ReactNativeWebView) {
        clickKakaoLoginBtn('intro');
        window.Kakao?.Auth?.authorize({...kakaoAuthInfo});
        // } else {
        //     window.Kakao?.Auth?.authorize({
        //         ...kakaoAuthInfo,
        //         prompts: 'login',
        //         redirectUri: `${process.env.REACT_APP_WEB_URL}/auth/kakao/callback`
        //     });
        // }
    };

    // 스크롤 없는 풀페이지 스타일 선언을 위한 클래스명 추가
    useEffect(() => {
        viewIntroPage();
        document.documentElement.classList.add('fullPage');
        return () => {
            document.documentElement.classList.remove('fullPage');
        };
    }, []);

    useEffect(() => {
        fetchWrapper.get(`/authentication/kakao`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                AirbridgeUtil.event.login('KAKAO');
                setKakaoAuthInfo(response?.data || '');
            }
        });
        fetchWrapper.get(`/authentication/apple`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                AirbridgeUtil.event.login('APPLE');
                setAppleAuthUrl(response?.data || '');
            }
        });
        return () => {
            document.documentElement.classList.remove('fullPage');
        };
    }, []);

    // iOS 디바이스 체크
    // const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const onClickApple = () => {
        // debugger;
        if (appleAuthUrl.includes('localhost')) {
            alert('Apple login not available at localhost !!');
            return;
        }
        window.location.href = appleAuthUrl;
    };

    return (
        <>
            <div className="membershipIndex">
                <div className="inner">
                    <h1>
                        <span>골프 라운드 가자</span>
                        <strong className="logo">golaga</strong>
                    </h1>
                    <div className="btnWrap">
                        <div className="btnArea">
                            <Button
                                type="button"
                                height="3.125rem"
                                background={'#FEE500'}
                                color="#000"
                                className="kakao"
                                onClick={onClickKakao}
                                delay={1000}
                            >
                                카카오로 로그인
                            </Button>
                            {appleAuthUrl && (
                                <Button
                                    height="3.125rem"
                                    background={'#fff'}
                                    color="#000"
                                    type="button"
                                    className="apple"
                                    onClick={onClickApple}
                                    delay={1000}
                                >
                                    Apple로 로그인
                                </Button>
                            )}

                            <ul className="emailUtils">
                                <li>
                                    <Link to="/member/join" state={history.location.state}>
                                        이메일 회원가입
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/member/login" state={history.location.state}>
                                        이메일 로그인
                                    </Link>
                                </li>
                            </ul>
                            {/*<ul>*/}
                            {/*    <li><small style={{color:'blueviolet'}}>{process.env.REACT_APP_VER}</small></li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                </div>
                <MemGateSlider />

                {/*웹 외부 접속 허용 대응 안내*/}
                <WebAccessInfoModal />
            </div>
        </>
    );
}

export default Membership;
