import React from 'react';

function MyCard({cardInfo, handleRemoveOpen}) {
    return (
        <>
            {/* 카드 사용불가일때 li에 className="disabled" 추가 */}
            <li className={cardInfo?.status != 'ENABLED' ? 'disabled' : ''}>
                <div className="cardImg">
                    <img src={cardInfo?.iconUrl} alt={`${cardInfo?.cardName} 아이콘`} />
                </div>
                <div className="cardInfo">
                    <strong className="name">
                        {cardInfo?.cardName}({cardInfo?.cardNumber})
                    </strong>
                    {cardInfo?.status === 'ENABLED' ? <span>{cardInfo?.cardType}카드</span> : <span>사용불가</span>}
                </div>
                <button
                    type="button"
                    className="btnDelete btnGray small"
                    onClick={() => handleRemoveOpen(cardInfo?.methodKey)}
                >
                    삭제
                </button>
            </li>
        </>
    );
}

export default MyCard;
