import React, {useCallback, useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import self_checkIn_step_01 from '../../assets/images/smart-round/guide/self_checkIn_step_01.png';
import self_checkIn_step_02 from '../../assets/images/smart-round/guide/self_checkIn_step_02.png';
import self_checkIn_step_03 from '../../assets/images/smart-round/guide/self_checkIn_step_03.png';
import flag_step_01 from '../../assets/images/smart-round/guide/flag_step_01.png';
import flag_info_balloon from '../../assets/images/smart-round/guide/flag_info_balloon.png';

function SelfCheckInGuideLayer(props) {
    const {handleOpen, handleClose} = props;

    // 스크롤 이벤트
    const scrollRef = useRef(null);
    // 타이틀 활성화
    const [isTitleVisible, setIsTitleVisible] = useState(false);

    // 스크롤 이벤트
    const onScroll = useCallback((evt) => {
        const div = evt.target;

        const currentScrollPosition = div.scrollTop;

        if (currentScrollPosition > 0) {
            setIsTitleVisible(true);
        } else {
            setIsTitleVisible(false);
        }
    }, []);

    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                if (handleOpen === true) {
                    layerClose();
                }
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);
        return () => {
            document.removeEventListener('message', handleEvent);
        };
    }, [handleOpen]);

    // 레이어 닫기
    const layerClose = () => {
        handleClose();
        setTimeout(() => {
            setIsTitleVisible(false);
        }, [100]);
    };

    return (
        <>
            <Dialog fullScreen open={handleOpen} onClose={handleClose} className="fullPageModal selfCheckInGuideLayer">
                <div className="layerPop">
                    <div className={`layerHeader2 self-check-in ${isTitleVisible ? 'border' : ''}`}>
                        <button type="button" className="btnClose" onClick={layerClose}>
                            닫기
                        </button>
                    </div>
                    <div className="layerContents3 " ref={scrollRef} onScroll={onScroll}>
                        <section className="bgSection">
                            <h2>
                                쉽고 빠른 셀프체크인 하는 법<br />
                                라운드 당일 이용해보세요.
                            </h2>
                            <div className="innerSection">
                                <h3>
                                    프론트 방문없이 화면터치!
                                    <br />
                                    셀프체크인 가능해요!
                                </h3>
                                <img
                                    src={self_checkIn_step_01}
                                    alt="STEP 01 골라가 스마트라운드 진입"
                                    className="deepShadow"
                                />
                            </div>
                            <div className="innerSection">
                                <h3>
                                    배정된 락커번호는
                                    <br />
                                    언제든지 확인 할 수 있어요!
                                </h3>
                                <img src={self_checkIn_step_02} alt="STEP 02 위치정보 서비스 이용 동의" />
                            </div>
                            <div className="innerSection">
                                <h3>
                                    동반자 체크인 현황도
                                    <br />
                                    앱에서 확인 할 수 있어요!
                                </h3>
                                <img
                                    src={self_checkIn_step_03}
                                    alt="STEP 03 내 모바일 화면에서 터치하면,셀프 체크인 완료!"
                                />
                            </div>
                        </section>
                        <section>
                            <h2>
                                티타임 목록<span>에서</span>
                                <br />
                                셀프체크인 플래그를
                                <br />
                                <span>확인하세요!</span>
                            </h2>
                            <div className="flagCon">
                                <img src={flag_step_01} alt="셀프체크인 플래그" className="flagShadow" />
                                <img
                                    src={flag_info_balloon}
                                    alt="셀프체크인 편하고 좋네! 셀프체크인 가능한 골프장 또 어디지?"
                                    className="flagBalloon"
                                />
                            </div>
                        </section>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default SelfCheckInGuideLayer;
