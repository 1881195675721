import React from 'react';
import CourseInfo from './CourseInfo';
import SkeletonCourseCon from 'components/skeleton/SkeletonCourseCon';
import {useSelector} from 'react-redux';
import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE} from 'common/constants';
import {clickIntroCourseguide} from '_helpers/amplitude/events/click.event';

function CourseGuideContainer(props) {
    const {handleAlertOpen} = props;
    const member = useSelector((x) => x?.users?.users);
    const golfClubId = useSelector((x) => x?.smartRound.golfClubId);
    const smartRound = useSelector((x) => x?.smartRound.smartRound);

    const redireactApp = () => {
        if (!smartRound?.isCourseGuideExposed) {
            handleAlertOpen('해당 골프장은\n코스 확인 기능을 지원하지 않습니다.');
            return;
        }

        if (!window.ReactNativeWebView) {
            handleAlertOpen('해당 기능은 앱에서만 실행 가능합니다.');
            return;
        }

        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${golfClubId}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                clickIntroCourseguide(response.data, smartRound?.isCourseGuideExposed);
                window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                        type: 'goNavi',
                        golfClubId: smartRound?.golfClubId,
                        courseCode: smartRound?.courseCode,
                        teeingGround: member?.teeingGround,
                        mapControlYN: smartRound?.mapControlYN,
                        handicapDisplayYN: smartRound?.handicapDisplayYN,
                        amplitudeData: response.data
                    })
                );
            } else {
                window.ReactNativeWebView?.postMessage(
                    JSON.stringify({
                        type: 'goNavi',
                        golfClubId: smartRound?.golfClubId,
                        courseCode: smartRound?.courseCode,
                        teeingGround: member?.teeingGround,
                        mapControlYN: smartRound?.mapControlYN,
                        handicapDisplayYN: smartRound?.handicapDisplayYN,
                        amplitudeData: null
                    })
                );
            }
        });
    };

    return (
        <>
            {smartRound ? (
                <div className="courseCon">
                    <div className="ttArea">
                        <div className="golfClubTitle">{smartRound?.golfClubShortName}</div>
                    </div>
                    {/* 코스 상세 정보 */}
                    <CourseInfo />
                </div>
            ) : (
                <SkeletonCourseCon />
            )}

            {/* 버튼 */}
            <div className="btnArea">
                <button type="button" className="btnPrimary" onClick={redireactApp}>
                    홀별 코스 확인하기
                </button>
            </div>
        </>
    );
}

export default CourseGuideContainer;
