import './promotion-checkin-notice.scss';

const PromotionCheckinNotice = () => {
    return (
        <div className="promotion-checkin-notice-wrapper">
            <div className="promotion-checkin-notice-icon" />
            <div className="promotion-checkin-notice">
                <div className="promotion-checkin-notice-label">셀프체크인 경품 프로모션 안내</div>
                <div className="promotion-checkin-notice-content">
                    해당 예약으로 골라가에서 <span className="font-weight-bold">셀프체크인</span>하면, 이벤트에 자동
                    응모 되며 추첨을 통해 경품이 지급됩니다.
                </div>
            </div>
        </div>
    );
};

export default PromotionCheckinNotice;
