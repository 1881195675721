import {yupResolver} from '@hookform/resolvers/yup';
import Modal from '@mui/material/Modal';
import {smartRoundActions} from '_store/smartRound.slice';
import {errMsg} from 'common/messages';
import {useCallback, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

function TagNameModal(props) {
    const {alertOpen, setAlertOpen, alertClose, selfCheckInProcess} = props;
    const golfBagList = useSelector((x) => x?.smartRound.golfBagList);
    const golfBag = useSelector((x) => x?.smartRound.golfBag);
    const user = useSelector((x) => x?.smartRound.user);
    const dispatch = useDispatch();

    // 골프백의 테그네임 재설정 Modal
    const [tagNameOpen, setTagNameOpen] = useState(false);
    const [golfBagName, setGolfBagName] = useState(golfBag);

    const handleTagNameOpen = () => {
        setAlertOpen(false);
        setTagNameOpen(true);
    };
    const handleTagNameClose = () => {
        enableScroll();
        setTagNameOpen(false);
        dispatch(smartRoundActions.setGolfBag({golfBag: ''}));
    };

    // 골프백 태그 네임 '예' 클릭 시 실행
    const moveToCheckInPage = useCallback(() => {
        dispatch(smartRoundActions.setGolfBag({golfBag: ''}));
        selfCheckInProcess(); // 체크인 + checkInReady -> true
        alertClose();
    }, [selfCheckInProcess]);

    // 태그네임 입력 후, '수정완료' 클릭 시 실행
    const modifyDone = useCallback(() => {
        enableScroll();
        selfCheckInProcess(); // 체크인 + checkInReady -> true
        setTagNameOpen(false);
    }, [selfCheckInProcess]);

    const checkInWrapDocument = document.querySelector('.self-check-in-wrapper');
    // 스크롤 가능
    const enableScroll = () => {
        const {body} = document;
        checkInWrapDocument.style.removeProperty('height');
        body.style.removeProperty('touch-action');
    };

    // todo ios 자판 노출 시 화면 이슈
    const focusEvent = () => {
        const {body} = document;

        body.style.touchAction = `none`;
        checkInWrapDocument.style.cssText = 'height: initial;';

        window.scrollTo(0, 0);
    };

    const validationSchema = Yup.object().shape({
        length: Yup.string().max(10, errMsg.BAG_NAME_LENGTH).min(2, errMsg.BAG_NAME_LENGTH)
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    };

    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    return (
        <>
            {/* 골프백의 테그네임 정보 확인 Alert */}
            <Modal open={alertOpen} onClose={alertClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">골프백 네임태그 확인</div>
                        <div className="alertContents">
                            가져오신 골프백 네임태그가 {golfBagList?.length ? golfBagList[0]?.tagName : user?.name}
                            인가요?
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={handleTagNameOpen}>
                            아니오
                        </button>
                        <button type="button" onClick={moveToCheckInPage}>
                            예
                        </button>
                    </div>
                </div>
            </Modal>

            {/* 골프백의 테그네임 재설정 Modal */}
            <Modal open={tagNameOpen} onClose={handleTagNameClose}>
                <div className="defaultModal">
                    <form onSubmit={handleSubmit(modifyDone)}>
                        <div className="modalContents tag-name-modal">
                            <p>{user?.name}님의 골프백 네임태그를 입력해주세요</p>

                            <div className="fieldAreaWrap">
                                <div className="row fieldArea">
                                    <input
                                        onFocus={focusEvent}
                                        type="text"
                                        {...register('length')}
                                        className={`textField small${errors.length ? ' error' : ''}`}
                                        value={golfBagName || ''}
                                        onChange={(event) => {
                                            setGolfBagName(event.target.value);
                                            dispatch(smartRoundActions.setGolfBag({golfBag: event.target.value}));
                                        }}
                                    />
                                    {golfBag?.length > 0 ? (
                                        <div className="btnArea">
                                            <button
                                                type="button"
                                                className="btnDelete"
                                                onClick={() => {
                                                    setGolfBagName('');
                                                    dispatch(smartRoundActions.setGolfBag({golfBag: ''}));
                                                }}
                                            >
                                                삭제
                                            </button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {errors.length && (
                                    <p className="invalid-feedback infoMsg error">{errors.length?.message}</p>
                                )}
                            </div>
                        </div>
                        <div className="modalBtnArea">
                            <button type="button" onClick={handleTagNameClose} className="btnGray">
                                취소
                            </button>
                            <button type="submit">수정 완료</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </>
    );
}

export default TagNameModal;
