import React, {useEffect, useState, useRef, useCallback} from 'react';
import Icon from '../../icons/Icon';
import Drawer from '@mui/material/Drawer';
import {fetchWrapper, history} from '../../../_helpers';
import {localStorageUtil} from '../../../common/utils';
import HistoryList from './HistoryList';
import SearchingView from './SearchingView';
import {SUCCESS_CODE} from '../../../common/constants';
import {searchKeyword} from '../../../_helpers/amplitude/events/etc.event';

function SearchDrawer({basicFilterOpen, setBasicFilterOpen}) {
    const [historyList, _setHistoryList] = useState([]);
    const myHistoryList = useRef(historyList);
    const setHistoryList = (list) => {
        myHistoryList.current = list;
        _setHistoryList(list);
    };

    const nextId = useRef(0);

    const [addBtnClass, setAddBtnClass] = useState(null); // 삭제 버튼 클래스 Add, Remove 제어

    // input내에 전체 지우기 버튼
    const [deleteBtnShow, setDeleteBtnShow] = useState(''); // 삭제 버튼 Show, Hide 제어

    // 검색어
    const [searchWord, setSearchWord] = useState('');

    useEffect(() => {
        if (localStorageUtil.get('historyList') === 'undefined') {
            return;
        }

        const history = JSON.parse(localStorageUtil.get('historyList'));
        if (history !== null) {
            history.map((item, idx) => {
                item.id = idx;
                nextId.current += 1;
            });
            setHistoryList(history);
        }
    }, []);

    useEffect(() => {
        if (basicFilterOpen) {
            setSearchWord('');
        } else {
            document.querySelector('input').blur();
        }
    }, [basicFilterOpen]);

    useEffect(() => {
        // 검색창 입력 시 삭제버튼
        if (deleteBtnShow?.length > 0) {
            setAddBtnClass('btnDelete');
        } else {
            setAddBtnClass(null);
        }
    }, [deleteBtnShow]);

    const onRemove = (id) => {
        setHistoryList(historyList.filter((historyItem) => historyItem.id !== id));
    };

    const onRemoveAll = () => {
        setHistoryList([]);
    };

    const onBasicFilterClose = () => {
        if (searchWord && searchWord.length > 0) searchKeyword(searchWord);
        setBasicFilterOpen(false);
    };

    const handleOnKeyPress = (e) => {
        if (e.keyCode === 13) {
            const trimWord = searchWord.trim();
            setSearchWord(trimWord);
            if (trimWord === '') {
                return;
            }

            onClickHistoryItem(trimWord);
        }
    };

    // 최근 검색어 리스트 아이템 클릭
    const onClickHistoryItem = (keyword = '', isSearchingView = false) => {
        const trimWord = searchWord.trim();
        setSearchWord(trimWord);
        if (trimWord === '') {
            return;
        }

        addHistoryList(keyword.trim());
        searchResultAll(keyword.trim());
        onBasicFilterClose();
    };

    const onClickHistory = (keyword = '') => {
        addHistoryList(keyword);
        searchResultAll(keyword);
        onBasicFilterClose();
    };

    // 최근검색어리스트 저장
    const addHistoryList = (keyword) => {
        const historyItem = {
            id: nextId.current,
            searchContent: searchWord
        };

        if (keyword !== '') {
            historyItem.searchContent = keyword;
        }

        //중복제거
        const duplicationRemoveList = myHistoryList.current.filter(
            (item) => item.searchContent !== historyItem.searchContent
        );
        setHistoryList(
            duplicationRemoveList.map((item, idx) => {
                item.id = idx;
                nextId.current += 1;
            })
        );

        setHistoryList([historyItem, ...duplicationRemoveList]);
        setSearchWord('');
    };

    useEffect(() => {
        // 20개 제한
        if (historyList.length > 20) {
            historyList.pop();
            setHistoryList(historyList);
        }

        localStorageUtil.set('historyList', JSON.stringify(historyList));
    }, [historyList]);

    // 전체 검색결과 보기
    const searchResultAll = (keyWord) => {
        addHistoryList(keyWord);
        localStorageUtil.set('historyList', JSON.stringify(myHistoryList.current));
        history.navigate('/search-result', {state: keyWord});
    };

    const onChange = (e) => {
        // 불필요한 전송 막기 - 2023.06.21 주석
        // searchKeyword(e.target.value);

        setSearchWord(e.target.value);
        setDeleteBtnShow(e.target.value);
    };

    // 검색창 초기화
    const clearSearchWord = () => {
        setDeleteBtnShow('');
        setSearchWord('');
    };

    // 선택한 골프장의 골프 소개 페이지로 이동
    const onClickGolfClubDetail = (searchingItem) => {
        if (searchWord && searchWord.length > 0) searchKeyword(searchWord);

        addHistoryList(searchingItem.golfClubName);
        localStorageUtil.set('historyList', JSON.stringify(myHistoryList.current));
        setBasicFilterOpen(false);

        fetchWrapper.post(`/search/golf-club/create/${searchingItem.golfClubId}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                //console.log('골프장 검색', searchingItem.golfClubName);
            } else {
                console.log(response?.error);
            }
        });

        history.navigate(`/field-detail/${searchingItem.golfClubId}`, {
            state: {
                flag: 'realTimeSearchList'
            }
        });
    };

    const searchInput = useCallback((inputElement) => {
        if (inputElement) {
            inputElement.focus();
        }
    }, []);

    return (
        <Drawer
            anchor="bottom"
            open={basicFilterOpen}
            onClose={onBasicFilterClose}
            className="defaultDrawer searchDrawer"
        >
            <div className="drawer-content">
                <div className="drawerTitle">
                    <div className="tt">검색</div>
                    <button type="button" className="btnClose" onClick={onBasicFilterClose}>
                        <Icon name="close" />
                    </button>
                </div>

                <div className="searchBox">
                    <div className="iconInput">
                        <input
                            type="text"
                            value={searchWord}
                            placeholder="지역, 골프장명으로 검색하세요"
                            onChange={onChange}
                            onKeyDown={handleOnKeyPress}
                            maxLength={20}
                            ref={searchInput}
                        />
                    </div>
                    <div className="btnArea">
                        {deleteBtnShow?.length > 0 && (
                            <button type="button" className="btnDelete" onClick={clearSearchWord}></button>
                        )}
                        <span onClick={() => onClickHistoryItem(searchWord)}>
                            <Icon name="search" width="15" height="17" viewBox="0 0 15 17" />
                        </span>
                    </div>
                </div>

                <div className="drawerContents">
                    {searchWord === '' ? (
                        <div className="historyView">
                            <div className="historyTop">
                                <div className="recentSearch">최근 검색어</div>
                                <button className="resetBtn" onClick={onRemoveAll}>
                                    초기화
                                </button>
                            </div>
                            <div className="scrollArea02">
                                <HistoryList
                                    historyList={historyList}
                                    onRemove={onRemove}
                                    onClickHistoryItem={onClickHistory}
                                />
                            </div>
                        </div>
                    ) : (
                        <SearchingView
                            searchWord={searchWord}
                            searchResultAll={searchResultAll}
                            onClickGolfClubDetail={onClickGolfClubDetail}
                        />
                    )}
                </div>
            </div>
        </Drawer>
    );
}

export default SearchDrawer;
