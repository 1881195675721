import React, {useCallback} from 'react';

import Drawer from '@mui/material/Drawer';
import Icon from '../../icons/Icon';

import {appUtils} from '../../../common/utils';
import {fetchWrapper, history} from '../../../_helpers';
import {ALERTTITMESSAGE, SUCCESS_CODE} from '../../../common/constants';
import {alertActions} from '../../../_store/alert.slice';
import {useDispatch, useSelector} from 'react-redux';
import CommonAlert from '../../alert/CommonAlert';
import {clickTeetimeAtAccordian, clickTeetimeReservation} from '../../../_helpers/amplitude/events/click.event';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import Badge from 'components/badge/Badge';
import {btn} from 'common/payment/locales/kr/btn';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';

const TeaTimeReservationDrawer = ({
    TeaTimeReservationDrawerOpen,
    setTeaTimeOpen,
    selectTeeTimeSheet,
    selectTeeTimeDataPayload
}) => {
    const alertData = useSelector((x) => x.alert);
    const dispatch = useDispatch();

    const teaTimeClose = () => {
        setTeaTimeOpen(false);

        const querySelector = document.querySelectorAll('.teeTimeBoxSpan-select');
        if (querySelector.length > 0) {
            selectTeeTimeSheet.isActive = false;

            querySelector[0].classList.remove('teeTimeBoxSpan-select');
        }

        /*  setTimeout(() => {
            setTeaTimeData(null);
            setIsLoading(true);
            setIsPartner(false);
        }, 500);*/
    };

    const routingTeeTime = () => {
        fetchWrapper
            .get(
                `/amplitude-events/view-reservation?golfClubId=${selectTeeTimeDataPayload.golfClubId}&timeSheetId=${selectTeeTimeSheet?.timeSheetId}`
            )
            .then((response) => {
                let teetime_range = selectTeeTimeSheet?.roundTime.split(':');
                if (teetime_range.length > 0)
                    clickTeetimeReservation(
                        response.data,
                        getAmplitudeRootPath(),
                        teetime_range.length > 0 ? Number(teetime_range[0]) : ''
                    );
            });

        const bookingParams = {
            timeSheetId: selectTeeTimeSheet?.timeSheetId,
            courseId: selectTeeTimeSheet?.courseId,
            paymentType: selectTeeTimeSheet?.paymentType,
            golfClubName: selectTeeTimeDataPayload?.golfCLubName,
            golfClubId: selectTeeTimeDataPayload.golfClubId
        };

        // 예약하기 페이지로 이동
        history.navigate(`/payment/booking/${selectTeeTimeDataPayload.golfClubId}`, {state: bookingParams});
    };

    const routinginAppView = () => {
        console.log('selectTeeTimeDataPayload.bookingUrl', selectTeeTimeDataPayload.bookingUrl);
        if (window.ReactNativeWebView) {
            //앱 버전 RN에서 받아옴
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    inappurl: selectTeeTimeDataPayload?.bookingUrl,
                    type: 'openInappBrowser',
                    inapptitle: selectTeeTimeDataPayload?.golfClubShortName
                })
            );
        } else {
            window.location.href = selectTeeTimeDataPayload.bookingUrl;
        }
    };

    // 예약 시 회원 나이 제한 체크
    const getBookingPossible = useCallback(() => {
        const payload = {
            courseId: selectTeeTimeSheet?.courseId,
            timeSheetId: selectTeeTimeSheet?.timeSheetId,
            golfClubId: selectTeeTimeDataPayload?.golfClubId
        };

        // 예약이 가능한지 조회
        /**
         * 선결제 이후 골프장별 2가지 조건 확인
         * 1. 예약 가능한 회원 나이 체크
         * 2. 예약 가능여부
         * */
        let isPossibleility = false;

        fetchWrapper
            .get(
                `/booking/possibility?&golfClubId=${payload.golfClubId}&courseId=${payload.courseId}&timeSheetId=${payload.timeSheetId}`,
                null
            )
            // .get(
            //     `/booking/possibility?ageLimit=true&golfClubId=${payload.golfClubId}&courseId=${payload.courseId}&timeSheetId=${payload.timeSheetId}`,
            //     null
            // )
            .then((response) => {
                isPossibleility = response?.data?.isPossibility;

                if (isPossibleility === true) {
                    routingTeeTime();
                } else {
                    dispatch(
                        alertActions.showAlert({
                            type: ALERTTITMESSAGE,
                            id: 'error',
                            messageData: {
                                message: response?.data.impossibleReason || response?.message
                            }
                        })
                    );
                }
            })
            .catch((err) => {
                console.log('err : ' + err);
            });
    }, [selectTeeTimeSheet]);

    return (
        <>
            <Drawer
                anchor="bottom"
                open={TeaTimeReservationDrawerOpen}
                onClose={teaTimeClose}
                className="teaTimeReservationDrawer"
            >
                <div className="drawer-content">
                    <div className="drawerTitle">
                        <div className="ttArea">
                            <Badge type={selectTeeTimeSheet?.paymentType || 'PREPAY'} />
                            <strong className="tt">{selectTeeTimeDataPayload?.golfClubShortName}</strong>
                        </div>
                        <button type="button" className="btnClose" onClick={teaTimeClose}>
                            <Icon name="close02" width="24" height="24" viewBox="0 0 24 24" />
                        </button>
                    </div>
                    {/* <div className="drawerDate">
                        <dl>
                            <dt>
                                {selectTeeTimeDataPayload?.dateStr}
                                <span> {selectTeeTimeDataPayload?.timeStr}</span>
                            </dt>
                            <dd>
                                <b>
                                    {selectTeeTimeSheet?.courseName}∙{selectTeeTimeSheet?.adminHole}
                                </b>
                            </dd>
                        </dl>
                    </div>
                    <div className="drawerPrice">
                        {selectTeeTimeSheet?.greenFee === 0 ? (
                            <span>그린피 골프장 문의</span>
                        ) : (
                            <span>{appUtils.numberFormat(selectTeeTimeSheet?.greenFee)}원/1인</span>
                        )}
                    </div> */}
                    <div className="drawer-booking-container">
                        <div className="drawer-booking-datetime">
                            {selectTeeTimeDataPayload?.dateStr} {selectTeeTimeDataPayload?.timeStr}
                        </div>
                        <div className="drawer-booking-content">
                            <span className="drawer-booking-course-info">
                                {selectTeeTimeSheet?.courseName}∙{selectTeeTimeSheet?.adminHole}
                            </span>
                            <span className="drawer-booking-amount">
                                {(selectTeeTimeSheet?.greenFeePerPerson &&
                                    `${appUtils.amountFormat(selectTeeTimeSheet.greenFeePerPerson, '원/1인')}`) ||
                                    '그린피 골프장 문의'}
                            </span>
                        </div>
                    </div>

                    {selectTeeTimeSheet?.timeSheetTags?.length > 0 && (
                        <div className="drawerTagList">
                            <div className="drawer-tag-title">{bookingInfoProperty.booking_inner_condition}</div>
                            {selectTeeTimeSheet?.timeSheetTags?.map((item, idx) => (
                                <span key={idx}>{item.tagName}</span>
                            ))}
                        </div>
                    )}
                    <div className="drawerBtnArea">
                        {!selectTeeTimeSheet?.isHomepageOpen ? (
                            <button className="button btnPrimary" onClick={getBookingPossible}>
                                {btn.booking_btn}
                            </button>
                        ) : (
                            <button className="button btnPrimary" onClick={routinginAppView}>
                                {btn.booking_homepage_btn}
                            </button>
                        )}
                    </div>
                </div>
            </Drawer>

            {/* 예약 시 회원 나이 제한 Alert */}
            <CommonAlert id={alertData?.id} successCallBack={teaTimeClose} />
        </>
    );
};

export default TeaTimeReservationDrawer;
