import React from 'react';
import Button from '../../components/button/Button';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {history} from '../../_helpers';
import Icon from '../icons/Icon';

function Error404() {
    //디바이스 높이값 체크
    const {height} = useWindowDimensions();
    //하단 버튼
    const nextPage = () => {
        history.navigate('/main');
    };

    return (
        <div id="contents">
            <div className="completeArea" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <div className="completeMsg">
                        <i className="icon">
                            <Icon name="questionMark" width="60" height="60" viewBox="0 0 60 60" />
                        </i>
                        <strong className="tt">{'페이지를 찾을 수 없습니다'}</strong>
                        <p className="txt">{'요청하신 페이지는 삭제 또는 변경되어\n이용하실 수 없습니다'}</p>
                    </div>
                </div>
            </div>
            <div className="btnFixedBottom">
                <div className="inner">
                    <Button type="button" onClick={nextPage}>
                        골라가 홈으로 가기
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Error404;
