import React, {useState} from 'react';

function ListCheckbox({onCheckClick, isRoundBg, checkList, idx, allCheckDisabled}) {
    return (
        <div className="customCheck">
            <input
                type="checkbox"
                disabled={allCheckDisabled}
                onChange={() => {
                    onCheckClick(idx);
                }}
                checked={checkList[idx]}
            />
            <i className={`icon ${isRoundBg ? 'round' : ''} ${checkList[idx] ? 'checked' : ''}`}>
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {isRoundBg ? (
                        <>
                            <circle cx="12" cy="12" r="12" fill={checkList[idx] ? '#3906C3' : 'white'} />
                            <path
                                d="M17 9L11.0525 16L7 11.9168"
                                stroke={checkList[idx] ? 'white' : '#A0A4AF'}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </>
                    ) : (
                        <path
                            d="M17 8L11.0525 15L7 10.9168"
                            stroke={isRoundBg ? '#fff' : checkList[idx] ? '#3906C3' : '#A0A4AF'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    )}
                </svg>
            </i>
        </div>
    );
}

export default ListCheckbox;
