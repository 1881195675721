import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {localStorageUtil} from 'common/utils';

import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE} from '../common/constants';
import AirbridgeUtil from '../common/airbridgeUtil';

// create slice

const name = 'users';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const reducers = createReducers();
const slice = createSlice({name, initialState, extraReducers, reducers});

// exports

export const userActions = {...slice.actions, ...extraActions};
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        users: null
    };
}

function createReducers() {
    return {
        setUser,
        initUser
    };
    // 유저정보 스토어내에 수정필요할 때 keyValue로 호출
    function setUser(state, action) {
        const {key, value} = action.payload;
        state.users[key] = value;
    }
    function initUser(state) {
        state.users = null;
    }
}
function createExtraActions() {
    const baseUrl = `/member`;

    return {
        getAll: getAll()
    };

    function getAll() {
        return createAsyncThunk(`${name}/getAll`, async () => await fetchWrapper.get(baseUrl));
    }
}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        var {pending, fulfilled, rejected} = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.users = {loading: true};
            },
            [fulfilled]: (state, action) => {
                if (action.payload?.code === SUCCESS_CODE) {
                    state.users = action.payload?.data;
                    localStorageUtil.set('lastUser', JSON.stringify(state.users));
                    const {memberChannel, gender, ageGroup} = action.payload?.data;
                    AirbridgeUtil.setUser(null, {memberChannel, gender, ageGroup});
                }
            },
            [rejected]: (state, action) => {
                state.users = {error: action.error};
            }
        };
    }
}
