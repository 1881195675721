import React from "react";

function ThridPrivacySKT() {
    return (
        <>
            <section>
                <h2>&lt;SKT&gt;</h2>
                <p>
                    "알뜰폰(MVNO)"사업자는 다음과 같이 개인정보를 제3자에게
                    제공하고 있습니다.
                </p>
            </section>
            <section>
                <h2>제1조 (알뜰폰(MVNO) 사업자)</h2>
                <p>
                    SK텔레콤(주)의 알뜰폰(MVNO) 사업자는 티플러스, 아이즈모바일,
                    모빙, 이야기모바일, 스마텔, SK세븐모바일, 이마트알뜰폰,
                    안심모바일, 프리티, 헬로모바일, 조이텔, 마이월드 입니다.
                </p>
            </section>
            <section>
                <h2>제2조 (제공목적)</h2>
                <p>
                    <strong className="underLine">
                        본 동의는 본인확인 서비스를 제공하기 위하여
                        본인확인기관인 SK텔레콤(주)와 본인확인서비스
                        이용자간본인확인서비스 이용에 필요한 고객정보를 위탁하여
                        본인확인서비스를 제공 하는 것에 대해 동의합니다.
                    </strong>
                </p>
            </section>
            <section>
                <h2>제3조 (제공정보)</h2>
                <p>
                    휴대폰 본인확인 서비스 제공에 필요한 개인정보로 성명,
                    휴대폰번호, 생년월일, 내.외국인구분, 성별정보입니다.
                </p>
            </section>
            <section>
                <h2>제4조 (제공받는자)</h2>
                <p>
                    <strong className="underLine">SK텔레콤(주)</strong>
                </p>
            </section>
            <section>
                <h2>제5조 (제공기간)</h2>
                <p>
                    <strong className="underLine">
                        이동통신사에서 보유중인 정보로서 별도 이용기간은
                        없습니다.
                    </strong>
                </p>
            </section>
            <section>
                <p>
                    "본인"은 정보제공에 동의하지 않으실 수 있으며, 동의하지
                    않으실 경우 휴대폰 본인 확인 서비스를 이용하실 수 없습니다.
                </p>
            </section>
        </>
    );
}

export default ThridPrivacySKT;
