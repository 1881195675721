import {sendAmplitudeEvent} from '../amplitude.helper';
import {clickTaxonomy} from '../taxonomy/click-event.taxonomy';

// Auth 클릭 이벤트

/**
 * 카카오 로그인 버튼 클릭 이벤트
 * @param {*} location
 */
export const clickKakaoLoginBtn = (location) => {
    // location values: intro, email_login
    // {locatio: location}
    sendAmplitudeEvent(clickTaxonomy.click_kakao_login_btn, {location: location});
};

// Navigation 이벤트

export const clickBnbHome = () => {
    sendAmplitudeEvent(clickTaxonomy.click_bnb_home);
};

export const clickBnbSearch = () => {
    sendAmplitudeEvent(clickTaxonomy.click_bnb_search);
};

export const clickBnbSmartRound = () => {
    sendAmplitudeEvent(clickTaxonomy.click_bnb_smartround);
};

export const clickBnbMyInfo = () => {
    sendAmplitudeEvent(clickTaxonomy.click_bnb_myinfo);
};

/**
 * 기본 필터 열기
 * @param {string} item 필터 선택시 해당 카테고리
 * @param {string} location 선택한 위치
 */
export const clickBasicFilter = (item, location) => {
    sendAmplitudeEvent(clickTaxonomy.click_basic_filter, {item: item, location: location});
};
/**
 * 기본 필터 초기화 버튼 클릭
 * @param {string} location 선택한 위치
 */
export const clickInitBasicFilter = (location) => {
    sendAmplitudeEvent(clickTaxonomy.click_init_basic_filter, {location: location});
};
/**
 * 고급필터 열기
 * @param {string} location 선택한 위치
 */
export const clickAdvFilter = (location) => {
    sendAmplitudeEvent(clickTaxonomy.click_adv_filter, {location: location});
};

/**
 * 고급 필터 초기화
 * @param {string} location 선택한 위치
 */
export const clickInitAdvFilter = (location) => {
    sendAmplitudeEvent(clickTaxonomy.click_init_adv_filter, {location: location});
};

/**
 * 필터 적용하기 버튼 클릭시
 * @param {*} data 선택한 위치
 * @param {string} location 선택한 위치
 */

export const clickSetAdvFilter = (data, location) => {
    const eventData = {
        location: location,
        category: data?.category,
        item: data?.item
    };

    sendAmplitudeEvent(clickTaxonomy.click_set_adv_filter, eventData);
};

/**
 * 검색>input 영역 클릭
 */
export const clickSearchKeyInput = () => {
    sendAmplitudeEvent(clickTaxonomy.click_search_key_input);
};

/**
 * 음식 메뉴 클릭시 - click_food_menu
 * @param {*} data 선택한 위치
 * @param {*} available 음식메뉴 기능 제공하는 골프장인지 여부
 * 선택한 위치
 */
export const clickFoodMenu = (data, available, root) => {
    const eventData = {
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,
        available: available
    };

    sendAmplitudeEvent(clickTaxonomy.click_food_menu, eventData);
};

/**
 * 홀별 코스 확인하기 버튼 선택이벤트 - click_intro_courseguide
 * @param {*} data
 * @param {boolean} available 코스가이드 기능 제공하는 골프장인지 여부
 */
export const clickIntroCourseguide = (data, available) => {
    const eventData = {
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,
        available: available
    };

    sendAmplitudeEvent(clickTaxonomy.click_intro_courseguide, eventData);
};

/**
 * click_you_are_hear - 코스가이드 현위치 버튼 선택
 * @param {*} data
 * @param {*} root
 */
export const clickYouAreHere = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_courseguide */
        course_info: data?.course_info,
        hole_info: data?.hole_info,
        par_info: data?.par_info,
        map_view: data?.map_view,
        distance_view: data?.distance_view,
        tee_ground: data?.tee_ground,
        smartdot_yn: data?.smartdot_yn,
        you_are_hear_yn: data?.you_are_hear_yn
    };

    sendAmplitudeEvent(clickTaxonomy.click_you_are_here, eventData);
};

/**
 * 지도 네비게이션 클릭이벤트 - click_navigation
 * @param {string} root
 * @param {string} method 네이버, 카카오, 티맵, 애플맵
 */

export const clickNavigation = (root, method) => {
    sendAmplitudeEvent(clickTaxonomy.click_navigation, {root: root, method: method});
};

/**
 * 골프장상세 전화 버튼 클릭 - click_call_cc_detail
 * @param {*} data
 * @param {string} root
 */
export const clickCallCCDetail = (data, root) => {
    const eventData = {
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(clickTaxonomy.click_call_cc_detail, eventData);
};

/**
 * 골프장상세 지도 버튼 클릭 - click_map_cc_detail
 * @param {*} data
 * @param {string} root
 */
export const clickMapCCDetail = (data, root) => {
    const eventData = {
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(clickTaxonomy.click_map_cc_detail, eventData);
};

/**
 * 골프장상세 지도 버튼 클릭 - click_share_cc_detail
 * @param {*} data
 * @param {string} root
 */
export const clickShareCcDetail = (data, root) => {
    const eventData = {
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(clickTaxonomy.click_share_cc_detail, eventData);
};

/**
 * 골프장상세 홈페이지 링크 클릭 - click_cc_homepage_link
 * @param {*} data
 * @param {string} root
 */
export const clickCCHomepageLink = (data, root) => {
    const eventData = {
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(clickTaxonomy.click_cc_homepage_link, eventData);
};

/**
 * 예약완료 페이지에서 홈으로 가기 버튼 클릭- click_home_at_reservation_complete
 * @param {*} data
 * @param {string} root
 * */
export const clickHomeAtReservationComplete = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people
    };

    sendAmplitudeEvent(clickTaxonomy.click_home_at_reservation_complete, eventData);
};

/**
 * 예약완료 페이지에서 동반자 초대하기 버튼 클릭 - click_home_at_reservation_complete
 * @param {*} data
 * @param {string} root
 * */
export const clickInviteAtReservationComplete = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people
    };

    sendAmplitudeEvent(clickTaxonomy.click_invite_at_reservation_complete, eventData);
};

/**
 * click_gps_self_checkIn - gps셀프 체크인 버튼 클릭
 * @param {*} data view_gps_self_checkin
 * */
export const clickGpsSelfCheckIn = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        //추가
        tee_time_left_hour: data?.tee_time_left_hour,
        reservation_type: data?.reservation_type
    };
    sendAmplitudeEvent(clickTaxonomy.click_gps_self_checkin, eventData);
};

/**
 * click_teetime_accordian_btn - 티타임 아코디언 버튼 클릭시
 * @param {*} data view_cc_detail과 동일
 * @param {string} open_close 여는 클릭인지 닫는 클릭인지
 * */

export const clickTeetimeAccordianBtn = (data, root, open_close) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        open_close: open_close === true ? 'open' : 'close'
    };

    sendAmplitudeEvent(clickTaxonomy.click_teetime_accordian_btn, eventData);
};

/**
 * click_teetime_item - 티타임 클릭 시
 * @param {*} data view_reservation과 동일
 * @param {string} root 여는 클릭인지 닫는 클릭인지
 * @param {number} teetime_range 클릭한 티탕미 시간대
 * */

export const clickTeetimeItem = (data, root, teetime_range, location) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,

        /* teetime_range 클릭한 티타임 시간대*/
        teetime_range: teetime_range,
        location: location,

        day_type: data?.day_type,
        buffer_period: data?.buffer_period,
        day_of_the_week: data?.day_of_the_week
    };

    sendAmplitudeEvent(clickTaxonomy.click_teetime_item_at_accordian, eventData);
};

/**
 * click_teetime_reservation - 티타임 선택 후 노출되는 하닫ㄴ 모달에서 '티타임예약하기' 버튼 선택
 * @param {*} data clickTeetimeAtAccordian 동일
 * @param {string} root 여는 클릭인지 닫는 클릭인지
 * @param {number} teetime_range 클릭한 티탕미 시간대
 * */

export const clickTeetimeReservation = (data, root, teetime_range) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,

        /* teetime_range 클릭한 티타임 시간대*/
        teetime_range: teetime_range,

        day_type: data?.day_type,
        buffer_period: data?.buffer_period,
        day_of_the_week: data?.day_of_the_week
    };

    sendAmplitudeEvent(clickTaxonomy.click_teetime_reservation, eventData);
};

/**
 * click_invite_companion_try - 티타임 선택 후 노출되는 하닫ㄴ 모달에서 '티타임예약하기' 버튼 선택
 * @param {*} data modal_check_reservation 동일
 * @param {string} method kakao, contacts
 * @param {string} location reservation_details, smartround
 * */

export const clickInviteCompanionTry = (data, method, location, root, people) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: people
    };

    sendAmplitudeEvent(clickTaxonomy.click_invite_companion_try, eventData);
};

/**
 *  click_logout - 로그아웃 클릭 이벤트
 *
 * */
export const clickLogout = () => {
    sendAmplitudeEvent(clickTaxonomy.click_logout);
};

/**
 * click_cs - 고객센터 클릭
 *
 * */
export const clickCs = () => {
    sendAmplitudeEvent(clickTaxonomy.click_cs);
};

/**
 * click_date
 * @param {*} data
 */
export const clickDate = (data) => {
    sendAmplitudeEvent(clickTaxonomy.click_date, data);
};

/**
 * click_self_checkin - 스마트라운드 하단 네비게이션 메뉴에서 '셀프체크인' 선택
 * @param {*} data modal_check_reservation 동일
 * @param {string} method kakao, contacts
 * @param {string} location reservation_details, smartround
 * */

export const clickSelfCheckin = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* 추가 */
        available: data?.available
    };

    sendAmplitudeEvent(clickTaxonomy.click_self_checkin, eventData);
};

/**
 * click_companion_info - 티타임 선택 후 노출되는 하닫ㄴ 모달에서 '티타임예약하기' 버튼 선택
 * @param {*} data modal_check_reservation 동일
 * @param {string} method kakao, contacts
 * @param {string} location reservation_details, smartround
 * */

export const clickCompanionInfo = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* 추가 */
        available: data?.available
    };

    sendAmplitudeEvent(clickTaxonomy.click_companion_info, eventData);
};

/** ====================선결제 관련 추가  ================= */

/**
 * click_reservation_btn 예약하기 페이지 내 예약하기(결제하기) 버튼
 * @param {*} data view_reservation과 동일
 * @param {string} root
 */

export const clickReservationBtn = (data) => {
    const startPoint = sessionStorage.getItem('reservation_start_point');
    const eventData = {
        /* view_cc_detail */
        root: startPoint ? startPoint : data?.root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,

        /* click_reservation_btn */
        people: data?.people,
        total_prepayment_amount: data?.total_prepayment_amount,
        total_green_fee: data?.total_green_fee,
        cart_fee: data?.cart_fee,
        total_fieldpayment_amount: data?.total_fieldpayment_amount,
        caddie_fee: data?.caddie_fee,
        payment_method: data?.payment_method,

        buffer_period: data?.buffer_period,
        day_of_the_week: data?.day_of_the_week,
        day_type: data?.day_type
    };

    sendAmplitudeEvent(clickTaxonomy.click_reservation_btn, eventData);
};

/**
 * click_cancel_policy - 취소/위약규정 버튼 클릭
 * @param {*} data click_reservation_btn 동일
 *
 */

export const clickCancelPolicy = (data, root) => {
    const startPoint = sessionStorage.getItem('reservation_start_point');
    /* ============== click_reservation_btn ============= */

    data.root = startPoint ? startPoint : root;
    data.payment_method = data?.pay_condition === '현장결제' ? '' : '신용카드';

    /* ============== click_reservation_btn ============= */

    sendAmplitudeEvent(clickTaxonomy.click_cancel_policy, data);
};

/**
 * 필터 적용하기 버튼 클릭시
 * @param {*} data 선택한 위치  => + pay_condition (결제방법 필터 값)
 * @param {string} location 선택한 위치
 */
export const clickSetBasicFilter = (data, location) => {
    let replaceTeeTime = '';
    let replaceGreenFee = '';
    if (data?.teetime.length > 0) {
        replaceTeeTime = data?.teetime.replace(/~/g, '-');
    }
    if (data?.greenfee.length > 0) {
        replaceGreenFee = data?.greenfee.replace(/~/g, '-');
    }

    const eventData = {
        location: location,
        sector: data?.sector,
        teetime: replaceTeeTime,
        people: data?.people,
        count_hole: data?.count_hole,
        greenfee: replaceGreenFee,
        pay_condition: data?.pay_condition
    };

    sendAmplitudeEvent(clickTaxonomy.click_set_basic_filter, eventData);
};

/**
 * promotion 관련 앰플리튜드 추가
 */
export const clickBannerPromotion = (click_banner_promotion_type) => {
    sendAmplitudeEvent(click_banner_promotion_type);
};
export const clickNoticePromotion = () => {
    sendAmplitudeEvent(clickTaxonomy.click_notice_promotion);
};
export const clickPromotionComplete = (data) => {
    /* view_cc_detail */
    sendAmplitudeEvent(clickTaxonomy.click_promotion_complete, data);
};
