import React, {useCallback, useEffect, useState} from 'react';
import {Link, Navigate, useParams} from 'react-router-dom';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import Checkbox from '../../components/button/Checkbox';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {ReactComponent as Logo} from '../../assets/images/GreenIT_Logo.svg';
import './membership.scss';
import {useDispatch, useSelector} from 'react-redux';
import {authActions, store} from '../../_store';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {fetchWrapper, history} from '_helpers';
import {CHK_EMAIL_INPUT, EMAIL_MAXLENGTH, EMAIL_REG, PW_MAXLENGTH, SUCCESS_CODE} from '../../common/constants';
import {appUtils, localStorageUtil} from '../../common/utils';
import {inviteUtils} from '../../common/inviteUtils';
import Icon from '../icons/Icon';
import {checkinActions} from '../../_store/checkin.slice';
import {getCookie, removeCookie} from '../../common/cookieUtils';
import {userActions} from '_store';
import {joinActions} from '../../_store/joinOnShareLink.slice';
import {viewEmaiLoginPage} from '_helpers/amplitude/events/view.events';
import {loginFail, loginSuccess, logoutSuccess} from '_helpers/amplitude/events/auth.events';
import {clickKakaoLoginBtn} from '_helpers/amplitude/events/click.event';
import AirbridgeUtil from '../../common/airbridgeUtil';

function Login(args) {
    const dispatch = useDispatch();

    // store.dispatch(authActions.refresh());

    const member = useSelector((x) => x?.users?.users);
    const authUser = useSelector((x) => x.auth.user);
    const authError = useSelector((x) => x.auth.error);
    const lastUser = JSON.parse(localStorageUtil.get('lastUser'));

    const [from, setFrom] = useState(history.location?.state?.from);
    //  로그아웃 페이지 유무
    const [logoutYn, setLogoutYn] = useState(history?.location);
    const [lastLoginKakao, setLastLoginKakao] = useState(false);
    const [lastLoginApple, setLastLoginApple] = useState(false);
    const {
        inviteChannel,
        bookingId,
        bookingMemberId,
        golfClubId,
        shareStatus,
        alarmStatus,
        alarmId,
        smStatus,
        smGolfClubId,
        smBookingId,
        smClubName,
        courseStatus,
        courseGolfClubId,
        courseCode,
        mapControlYN,
        csStatus
    } = inviteUtils.getInviteParams(useParams()); // 동반자 초대관련 파라미터 (예약ID, 라운드맴버ID(초대자ID)) + 공유하기 + 알림톡

    const [appleAuthUrl, setAppleAuthUrl] = useState('');
    const [kakaoAuthInfo, setKakaoAuthInfo] = useState({
        redirectUri: '',
        state: '',
        nonce: ''
    });

    // amplitude event
    useEffect(() => {
        viewEmaiLoginPage();
    }, []);

    useEffect(() => {
        fetchWrapper.get(`/authentication/kakao`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                /* TODO 카카오 로그인 앰플리튜드 에러로 주석 처리  */
                // fetchWrapper.get('/amplitude-events/login-success').then((result) => {
                //     result.data.method = 'kakao';
                //     loginSuccess(result.data);
                // });

                // const payload = {...response?.data};
                AirbridgeUtil.event.login('KAKAO');
                setKakaoAuthInfo(response?.data || '');
            }
        });
        fetchWrapper.get(`/authentication/apple`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                AirbridgeUtil.event.login('APPLE');
                setAppleAuthUrl(response?.data || '');
            }
        });
    }, []);

    // form validation rules
    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .trim()
            .required('이메일 아이디를 입력하세요.')
            .matches(EMAIL_REG, '이메일이 올바르지 않습니다.'),
        password: Yup.string().trim().required('비밀번호를 입력하세요.')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema)
    };

    const {register, handleSubmit, formState, watch, resetField, setValue} = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    const [passwordShow, setPasswordShow] = useState(false);
    const [autoLogin, setAutoLogin] = useState(true);

    // 비밀번호 입력칸 에러메시지
    const [errorMsg, setErrorMsg] = useState(true);
    const hideErrorMsg = () => {
        setErrorMsg(false);
    };
    const showErrorMsg = () => {
        setErrorMsg(true);
    };

    function onSubmit(credential) {
        showErrorMsg();
        dispatch(authActions.login(credential)).then((result) => {
            if (result.type === 'auth/login/fulfilled') {
                //사용자정보가 일치하지 않을 경우
                if (!result?.payload?.data) {
                    // 로그아웃 amplitude event
                    loginFail({
                        error: document.getElementById('login-error-message')?.innerText
                    });
                    return showErrorMsg();
                } else {
                    // login success method for amplitude
                    fetchWrapper.get('/amplitude-events/login-success').then((result) => {
                        loginSuccess(result?.data);
                    });

                    // airbridge event
                    AirbridgeUtil.event.login('EMAIL');
                }
            }
        });
        // return dispatch(authActions.login(credential));
    }

    const onClickKakao = () => {
        // if (window.ReactNativeWebView) {
        clickKakaoLoginBtn('email_login');
        window.Kakao?.Auth?.authorize({...kakaoAuthInfo});
        // } else {
        //     window.Kakao?.Auth?.authorize({
        //         ...kakaoAuthInfo,
        //         prompts: 'login',
        //         redirectUri: `${process.env.REACT_APP_WEB_URL}/auth/kakao/callback`
        //     });
        // }
    };

    const onClickApple = () => {
        if (appleAuthUrl.includes('localhost')) {
            alert('Apple login not available at localhost!');
            return;
        }
        window.location.href = appleAuthUrl;
    };

    const clearUsername = () => {
        resetField('username');
    };

    const clearUserPassword = () => {
        resetField('password');
    };

    // 최근 로그인
    const lastUserEmail = lastUser?.email;
    const lastUserChannel = lastUser?.memberChannel;
    useEffect(() => {
        if (lastUserChannel === 'EMAIL') {
            setValue('username', lastUserEmail);
        } else if (lastUserChannel === 'KAKAO') {
            setLastLoginKakao(true);
        } else if (lastUserChannel === 'APPLE') {
            setLastLoginApple(true);
        }
    }, [lastUserChannel, lastUserEmail]);

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    const watchUsername = watch('username');
    const watchPassword = watch('password');

    // iOS 디바이스 체크
    // const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
    useEffect(() => {
        if (logoutYn?.state && logoutYn?.state.hasOwnProperty('flag') === true && logoutYn.state.flag === 'logout') {
            appUtils.openWebViewModalPostMsg('andBackOn');
        }
        return () => {
            appUtils.openWebViewModalPostMsg('andBackOff');
        };
    }, [history?.location]);

    return (
        <>
            <Header showBack={args.showBack} fixed={false} />
            <div id="contents" style={{height: `${height}px`}}>
                <div id="login" className="contentsArea">
                    <div className="inner">
                        <div className="loginArea">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="logoArea">
                                    <Logo />
                                </div>
                                <div className="fieldAreaWrap">
                                    {/* 에러 메시지 출력시 fieldArea, textField, infoMsg에 클래스 error 추가. */}

                                    <div className="row fieldArea">
                                        <div className="usernameArea form-group">
                                            <input
                                                name="username"
                                                autoComplete="username"
                                                type="email"
                                                {...register('username', {
                                                    onChange: (e) => {
                                                        appUtils.isValidInputCheck(e, EMAIL_MAXLENGTH, CHK_EMAIL_INPUT);
                                                    }
                                                })}
                                                className={`textField form-control ${
                                                    errors.username ? 'is-invalid error' : ''
                                                }`}
                                                maxLength={EMAIL_MAXLENGTH}
                                                placeholder="이메일 아이디"
                                                onFocus={appUtils.offsetPadding}
                                                onBlur={appUtils.defaultPadding}
                                            />
                                            <div className="btnArea">
                                                {watchUsername ? (
                                                    <button type="button" className="btnDelete" onClick={clearUsername}>
                                                        삭제
                                                    </button>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            {errors.username && (
                                                <p className="infoMsg error invalid-feedback">
                                                    {errors.username?.message}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="row fieldArea">
                                        <div className="passwordArea form-group">
                                            <input
                                                name="password"
                                                autoComplete="current-password"
                                                type={passwordShow ? 'text' : 'password'}
                                                {...register('password')}
                                                className={`textField form-control ${
                                                    errors.password ? 'is-invalid error' : ''
                                                }`}
                                                maxLength={PW_MAXLENGTH}
                                                placeholder="비밀번호 입력"
                                                onFocus={hideErrorMsg}
                                            />
                                            <div className="btnArea">
                                                {watchPassword ? (
                                                    <button
                                                        type="button"
                                                        className="btnDelete"
                                                        onClick={clearUserPassword}
                                                    >
                                                        삭제
                                                    </button>
                                                ) : (
                                                    ''
                                                )}
                                                <button
                                                    type="button"
                                                    className={passwordShow ? 'btnHidePw' : 'btnShowPw'}
                                                    onClick={() => {
                                                        setPasswordShow(!passwordShow);
                                                    }}
                                                >
                                                    비밀번호 감추기
                                                </button>
                                            </div>
                                        </div>
                                        {errors.password && (
                                            <p className="invalid-feedback infoMsg error">{errors.password?.message}</p>
                                        )}

                                        {!watchPassword ? (
                                            ''
                                        ) : authError ? (
                                            errorMsg ? (
                                                // 로그인 실패 시 카운트 횟수가 추가된 에러메세지 표출
                                                <p id="login-error-message" className="infoMsg error">
                                                    {authError}
                                                </p>
                                            ) : (
                                                ''
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div className="autoLoginCheck" style={{display: 'none'}}>
                                    <label>
                                        <Checkbox
                                            isRoundBg={true}
                                            onChecked={setAutoLogin}
                                            defaultChecked={autoLogin}
                                        />
                                        자동로그인
                                    </label>
                                </div>
                                <button
                                    id="btnLogin"
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={handleSubmit(onSubmit)}
                                    className="btn btnLogin btnPrimary"
                                >
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    로그인
                                </button>
                                <ul className="loginUtil">
                                    <li>
                                        <Link to="/member/find-id">아이디 찾기</Link>
                                    </li>
                                    <li>
                                        <Link to="/member/find-pw">비밀번호 찾기</Link>
                                    </li>
                                    <li>
                                        <Link className="memberjoin" to="/member/join">
                                            회원가입
                                        </Link>
                                    </li>
                                </ul>
                            </form>
                        </div>
                        {appleAuthUrl && (
                            <div className="otherLogin">
                                <div className="bul">
                                    <span>SNS계정으로 로그인하기</span>
                                </div>

                                <div className={'btnWrap2'}>
                                    <Button
                                        type="button"
                                        height="3.125rem"
                                        background={'none'}
                                        className={`kakao2 ${lastLoginKakao ? 'lastLogin' : ''}`}
                                        onClick={onClickKakao}
                                        delay={1000}
                                    >
                                        <Icon name={'loginKakao'} width="56" height="56" viewBox="0 0 56 56" />
                                    </Button>

                                    {appleAuthUrl && (
                                        <Button
                                            type="button"
                                            height="3.125rem"
                                            background={'fff'}
                                            className={`apple2 ${lastLoginApple ? 'lastLogin' : ''}`}
                                            onClick={onClickApple}
                                            delay={1000}
                                        >
                                            <Icon name={'loginApple'} width="56" height="56" viewBox="0 0 56 56" />
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
