import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import {osName} from 'react-device-detect';
import {installLink} from '../../common/links';
import {useSelector} from 'react-redux';

function AppUpdateModal(props) {
    const appUpdate = useSelector((x) => x?.global?.appUpdate);
    const [open, setOpen] = useState(false);

    // 플레이스토어, 앱스토어 링크
    const androidLink = installLink.GOLAGA_ANDROID;
    const iosLink = installLink.GOLAGA_IOS;

    // '나중에' 버튼 클릭시
    const handleModalClose = () => {
        setOpen(false);
    };

    // '업데이트' 버튼 클릭시
    const handleAppUpdate = () => {
        if (osName === 'Android') {
            window.location.assign(androidLink);
        } else if (osName === 'iOS') {
            window.location.assign(iosLink);
        }
    };

    useEffect(() => {
        if (appUpdate.update) {
            setOpen(true);
        }
    }, [appUpdate]);

    return (
        <>
            <Modal open={open}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">골라가 업데이트 안내</div>
                        <div className="alertContents">
                            {appUpdate.forceUpdate
                                ? '더욱 좋아진 golaga의 최신 버전을 준비했습니다. 새로운 버전으로 업데이트 하지 않으면 서비스 이용이 불가합니다. \n앱을 업데이트 합니다.'
                                : '더욱 좋아진 golaga의 최신 버전을 \n준비했습니다. 새로운 버전으로 업데이트 후 이용해 주시기 바랍니다.'}
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        {appUpdate.forceUpdate === false && (
                            <button type="button" onClick={handleModalClose}>
                                나중에
                            </button>
                        )}
                        <button type="button" onClick={handleAppUpdate}>
                            업데이트
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AppUpdateModal;
