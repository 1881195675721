import React from 'react';

function TitleArea({pageTitle, titleTxt, border, paddingBottm}) {
    return (
        <div className={`titleArea title-area ${border ? 'border' : ''} ${paddingBottm ? 'nonPaddingbtm' : ''}`}>
            {pageTitle ? (
                <h1>
                    {pageTitle.split('\n').map((pageTitle, index) => (
                        <React.Fragment key={index}>
                            {pageTitle}
                            <br />
                        </React.Fragment>
                    ))}
                </h1>
            ) : null}

            {titleTxt ? (
                <p>
                    {titleTxt.split('\n').map((titleTxt, index) => (
                        <React.Fragment key={index}>
                            {titleTxt}
                            <br />
                        </React.Fragment>
                    ))}
                </p>
            ) : null}
        </div>
    );
}

export default TitleArea;
