import crypto from 'crypto-js';

/**
 * CRA webpack 현 버전에서 nodeJS 의 내장 함수 사용 불가
 */
// 암호화
export const cryptoUtil = {
    encryptWithAES256: (plainText) => {
        const key = process.env.REACT_APP_HECTO_ENCRYPT_KEY;
        const secretKey = crypto.enc.Utf8.parse(key);
        const encryptedValue = crypto.AES.encrypt(plainText, secretKey, {
            mode: crypto.mode.ECB,
            padding: crypto.pad.Pkcs7
        });
        return encryptedValue.toString();
    },
    //복호화
    decryptWithAES256: (plainText) => {
        const key = process.env.REACT_APP_HECTO_ENCRYPT_KEY;
        const secretKey = crypto.enc.Utf8.parse(key);
        const decryptedValue = crypto.AES.decrypt(plainText, secretKey, {
            mode: crypto.mode.ECB,
            padding: crypto.pad.Pkcs7
        });
        return decryptedValue.toString(crypto.enc.Utf8);
    },
    //해시 생성
    encryptWithSHA256: (plainText) => {
        const hash = crypto.SHA256(plainText);
        const hexHash = hash.toString(crypto.enc.Hex);
        return hexHash;
    }
};
