import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import SmartRound from './SmartRound';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {checkinActions} from '../../_store/checkin.slice';

function SelfCheckin() {
    const dispatch = useDispatch();
    const {smartRoundCheckIn} = useSelector((x) => x.checkin);

    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const showCheckin = () => {
        dispatch(checkinActions.showSmartRoundCheckIn(true));
    };

    const hideCheckin = () => {
        dispatch(checkinActions.showSmartRoundCheckIn(false));
    };

    return (
        <>
            <SwipeableDrawer
                open={smartRoundCheckIn}
                anchor="top"
                onOpen={showCheckin}
                onClose={hideCheckin}
                transitionDuration={{enter: 400, exit: 300}}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                swipeAreaWidth={0}
                className="heightFull"
            >
                <SmartRound onClose={hideCheckin} />
            </SwipeableDrawer>
        </>
    );
}

export default SelfCheckin;
