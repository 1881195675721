import React from 'react';
import ToolTip from './ToolTip';
import {appUtils} from '../../common/utils';
import {dateUtil, paymentConstant, validationUtil} from 'common/payment';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';

import Badge from 'components/badge/Badge';
import {bookingCancel} from 'common/payment/locales/kr/booking-cancel';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';

function BookingPrice({bookingData}) {
    const cancelAbleDue = appUtils.dateFormatTypeFull(bookingData?.cancelAbleDue);
    const totalAmount = appUtils.numberFormat(bookingData.totalAmount);

    let paymentTypeStr;
    let paymentAmount;

    switch (bookingData?.paymentType) {
        case paymentConstant.paymentType.ONSITE:
            paymentTypeStr = paymentInfoProperty.onsite_payment_predict;
            paymentAmount = validationUtil.isNullChk(bookingData?.expectOnsiteAmount)
                ? '-'
                : appUtils.numberFormat(bookingData?.expectOnsiteAmount);
            break;
        case paymentConstant.paymentType.PREPAY:
            paymentTypeStr = paymentInfoProperty.payment_all_amount;
            paymentAmount = validationUtil.isNullChk(bookingData?.totalAmount)
                ? '-'
                : appUtils.numberFormat(bookingData?.totalAmount);
            break;
        case paymentConstant.paymentType.RESERVE:
            paymentTypeStr = paymentInfoProperty.payment_deposit;
            paymentAmount = validationUtil.isNullChk(bookingData?.totalAmount)
                ? '-'
                : appUtils.numberFormat(bookingData?.totalAmount);
            break;
        default:
            break;
    }

    return (
        <div className="rezDetailWrap BookingPrice">
            <div>
                {bookingData?.bookingType === 'GOLAGA' ? (
                    <div className="smart-round-booking-price">
                        <dl className="border-dashed-bottom">
                            <dt>{paymentTypeStr}</dt>
                            <dd className="total-payment">{`${paymentAmount}원`}</dd>
                        </dl>
                        <dl className="badge-wrap">
                            <dt>
                                {bookingCancel.cancel_possible}
                                <Badge
                                    type={`${
                                        bookingData?.isAfterCancelPeriod
                                            ? 'CANCEL_RECEPTION_MYPAGE'
                                            : 'CANCEL_POSSIBILITY_DATE_MYPAGE'
                                    }`}
                                    date={dateUtil.cancelDateRestTime(bookingData?.cancelPossiblePeriod)}
                                />
                            </dt>
                            <dd className={`cancel-possible-date ${bookingData?.isAfterCancelPeriod} date`}>
                                {validationUtil.isNullChk(bookingData?.cancelPossiblePeriod)
                                    ? '-'
                                    : appUtils.dateFormatTypeFull(bookingData?.cancelPossiblePeriod)}
                            </dd>
                        </dl>
                    </div>
                ) : (
                    <div className="smart-round-booking-price">
                        <dl className="border-dashed-bottom">
                            <dt>예약처</dt>
                            <dd className="external-dd">{`${
                                validationUtil.isNullChk(bookingData?.bookingTypeName)
                                    ? '-'
                                    : bookingData?.bookingTypeName
                            }`}</dd>
                        </dl>
                        <dl>
                            <h3>{bookingCancel.cancel_possible}</h3>
                            <dd className="external-dd">{bookingInfoProperty.booking_external_confirm}</dd>
                        </dl>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BookingPrice;
