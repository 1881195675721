import {loadBrandPay} from '@tosspayments/brandpay-sdk';
import jwt_decode from 'jwt-decode';
import {fetchWrapper} from '../_helpers';
import {apiUrl} from './path';
import {SUCCESS_CODE} from './constants';

const ck = `${process.env.REACT_APP_TOSS_CK}`;
const sk = `${process.env.REACT_APP_TOSS_SK}`;
const redirectUrl = `${process.env.REACT_APP_API_URL}/toss/access-token`;
export let tossItem = null;

export const tossUtils = {
    init: async (token) => {
        let sub = tossUtils.decodeSubject(token);
        if (sub) {
            tossItem = await loadBrandPay(ck, sub, {
                redirectUrl: redirectUrl,
                ui: {
                    highlightColor: '#3906C3',
                    buttonStyle: 'default',
                    labels: {
                        oneTouchPay: '그린잇'
                    }
                }
            });
        }
    },
    addPaymentMethod: (successCall, failCall) => {
        tossItem
            .addPaymentMethod('카드')
            .then((res) => successCall && successCall(res))
            .catch((err) => {
                if (err?.code === 'USER_CANCEL') {
                    return;
                }
                failCall && failCall(err);
            });
    },
    getPaymentMethods: (successCall, failCall) => {
        fetchWrapper.get(apiUrl.toss.cardList, null).then((result) => {
            if (result.code === SUCCESS_CODE) {
                successCall(result?.data?.cards);
            } else {
                failCall();
            }
        });
    },
    getLastEnrollCard: (successCall, failCall) => {
        fetchWrapper.get(apiUrl.toss.selected, null).then((result) => {
            if (result.code === SUCCESS_CODE) {
                successCall(result?.data);
            } else {
                failCall();
            }
        });
    },
    decodeSubject: (token) => {
        if (!token) return '';
        let data = jwt_decode(token);

        return data ? data.sub : '';
    }
};
