import React from "react";
import { history } from "../../_helpers";

function Alarm() {
    const onNotice = () => {
        history.navigate("/alarm");
    };

    // TODO jenna - Alarm 컴포넌트에 데이터가 있으면 클래스 on, 없으면 X
    return (
        <>
            <button onClick={onNotice} className="btnAlarm">
                알림
            </button>
        </>
    );
}

export default Alarm;
