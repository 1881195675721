import React, { useCallback, useEffect, useState } from "react";
import Header from "../../Header";
import TitleArea from "../../common/TitleArea";
import MyCard from "./MyCard";
import "../mypage.scss";
import { tossItem, tossUtils } from "../../../common/tossUtils";
import { useDispatch, useSelector } from "react-redux";
import CommonAlert from "../../alert/CommonAlert";
import { fetchWrapper } from "../../../_helpers";
import { ALERTTITMESSAGE, CNFRMTITMESSAGE, SUCCESS_CODE } from "../../../common/constants";
import { alertActions } from "../../../_store/alert.slice";
import { apiUrl } from "../../../common/path";

function MyCardList({ title }) {
    const auth = useSelector((x) => x.auth);
    const alertData = useSelector((x) => x.alert);
    const [cardList, setCardList] = useState([]);
    const [deleteKey, setDeleteKey] = useState("");

    const dispatch = useDispatch();

    const getCardList = useCallback(() => {
        tossUtils.getPaymentMethods((result) => setCardList(result || []), () => setCardList([]));
    }, [auth?.user?.accessToken]);

    useEffect(() => {
        if (!tossItem) {
            tossUtils.init(auth?.user?.accessToken);
        }
    }, [tossItem]);

    useEffect(() => {
        getCardList();
    }, [getCardList]);

    const onClickAddPayment = () => {
        tossUtils.addPaymentMethod(
            (res) => {
                if (res?.cards) {
                    getCardList();
                }
            },
            (failError) => {
                dispatch(alertActions.showAlert({
                    type: ALERTTITMESSAGE,
                    id: "error",
                    messageData: {
                        message: failError?.message,
                    },
                }));
            },
        );
    };

    //카드 삭제 Alert
    const handleOpen = (key) => {
        setDeleteKey(key);
        dispatch(alertActions.showAlert({
            type: CNFRMTITMESSAGE,
            id: "removeCard",
            messageData: {
                title: "간편결제 카드 삭제",
                message: "선택하신 카드를 삭제하시겠습니까?",
            },
        }));
    };

    const onDelete = useCallback(async () => {
        if (deleteKey) {
            let cardResponse = await fetchWrapper.delete(apiUrl.toss.cardRemove, {
                methodKey: deleteKey,
            });
            if (cardResponse?.code === SUCCESS_CODE) {
                setCardList(cardResponse?.data?.cards);
                setDeleteKey("");
            }
        }
    }, [deleteKey]);


    return (
        <>
            <Header back={true} />
            <div id="contents">
                <div className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="cardListWrap">
                            <ul className="cardList">
                                {cardList.map((v, i) => (
                                    <MyCard cardInfo={v} key={v.methodKey}
                                            handleRemoveOpen={(key) => handleOpen(key)} />
                                ))}
                            </ul>
                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className="btnAddCard"*/}
                            {/*    onClick={onClickAddPayment}*/}
                            {/*>*/}
                            {/*    카드 등록*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </div>

            {(alertData?.open && alertData?.id === "removeCard" &&
                <CommonAlert id={alertData?.id} successCallBack={onDelete} />) || ""}
            {(alertData?.open && alertData?.id === "error" &&
                <CommonAlert id={alertData?.id} />) || ""}
        </>
    );
}

export default MyCardList;
