import React, {useCallback, useEffect, useState} from 'react';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import DefaultAlert from '../alert/DefaultAlert';
import {fetchWrapper, history} from '_helpers';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {alertMsg, errMsg, successMsg} from '../../common/messages';
import {appUtils} from '../../common/utils';
import AuthenticationFields from '../membership/AuthenticationFields';
import {timerActions} from '../../_store/timer.slice';
import '../membership/membership.scss';
import {appActions} from '../../_store/app.slice';
import {viewChangeMobileNum} from '../../_helpers/amplitude/events/view.events';
import {mobileNumChangeSuccess} from '../../_helpers/amplitude/events/etc.event';

function AuthenticationNumber({title}) {
    const {height} = useWindowDimensions(); // 디바이스 높이값 체크
    const dispatch = useDispatch();
    const authUser = useSelector((x) => x.auth.user);

    const [reqNum, setReqNum] = useState('');
    const [_certDate, setCertDate] = useState('');

    const [formModify, setFormModify] = useState(true); // 폼 내 인풋제어
    // 하단 '인증번호', '다음'버튼
    const [activeButton, setActiveButton] = useState(false);
    const [activeTimer, setActiveTimer] = useState(false);
    const [reactive, setReactive] = useState(false);

    const [requestConfirm, setRequestConfirm] = useState(false);
    const [cellPhone, setCellPhone] = useState('');

    const prevUrl = sessionStorage.getItem('prevUrl');

    // 만 14세 이상 회원가입 안내 모달
    const [open, setOpen] = useState(false);
    const [alertData, setAlertData] = useState({
        title: '',
        description: ''
    });
    const [authValidation, setAuthValidation] = useState({
        message: '',
        error: false
    });
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        viewChangeMobileNum();
    }, []);

    useEffect(() => {
        if (requestConfirm) {
            dispatch(timerActions.setDone(requestConfirm));
            setAuthValidation({
                error: false,
                message: successMsg.USER_AUTH_SUCCESS
            });
            // 인증완료시
        }
    }, [requestConfirm]);

    const nextPage = useCallback(() => {
        if (prevUrl) {
            if (prevUrl === '/member/no-phone') {
                history.navigate('/main', {replace: true});
            } else if (prevUrl === '/mypage/modify') {
                history.navigate('/mypage/modify', {replace: true});
            }
        }
    }, [prevUrl]);

    /**
     * 인증 요청
     */
    const requestIdentity = (payloadData) => {
        dispatch(appActions.setReqNum(null));
        fetchWrapper.post(`/authentication/phone`, {...payloadData}).then((response) => {
            if (response?.code === '0000') {
                setReqNum(response?.data?.contents?.reqNum);
                setCertDate(response?.data?.contents?.certDate);
                setCellPhone(payloadData.cellPhone);

                if (response?.alertFlag) {
                    authProcess(response?.data?.contents?.title);
                } else {
                    authProcess();
                }
            } else {
                setAlertData({
                    title: response?.data?.title,
                    description: response?.data?.message
                });
                setOpen(true);
            }
        });
    };

    /**
     * 인증 확인
     */
    const checkAuthNumber = () => {
        const smsNum = document.querySelector("input[name='sms']")?.value;
        const confirmSeq = '01';
        fetchWrapper.post(`/authentication/confirm`, {confirmSeq, reqNum, smsNum}).then((response) => {
            if (response?.code === '5000') {
                // 중복된 사용자가 존재해야 휴대전화벙호 업서트 가능
                dispatch(appActions.setReqNum(reqNum));
                changePhoneNumber(cellPhone);
                // nextPage();
            } else if (response?.code === 'I999') {
                setAlertData({
                    title: '',
                    description: response?.message
                });
                setOpen(true);
                setFormModify(true); // form disabled 처리
                setActiveButton(false);
                setActiveTimer(false); // 타이머 노출 처리
                setReactive(false); // 동작처리
            } else if (response?.code === 'NME0074') {
                setAlertData({
                    title: '',
                    description: '이미 인증완료된 인증번호입니다.'
                });
                setOpen(true);
                setActiveButton(false);
                setActiveTimer(false); // 타이머 노출 처리
                setReactive(false); // 동
            } else {
                setAlertData({
                    title: response?.data?.title || null,
                    description: response?.data?.message || response?.message
                });
                setOpen(true);
            }
        });
    };

    /*
     * 휴대전화번호 변경
     * */
    const changePhoneNumber = (cellPhone) => {
        const formatNewCellPhone = appUtils.formatPhoneNumber(cellPhone);
        fetchWrapper.put(`/member/cellphone`, {newCellPhone: formatNewCellPhone}).then((response) => {
            if (response?.code === '0000') {
                mobileNumChangeSuccess();
                nextPage();
            } else {
                setAlertData({
                    title: response?.data?.title || null,
                    description: response?.data?.message || response?.message
                });
                setOpen(true);
            }
        });
    };

    /**
     * 입력값 validation
     */
    const onClickValidation = (data) => {
        let year = '19';
        let genderNumber = parseInt(data.userGender);
        // 1/2 : 19--, 3/4 : 20--, 5/6 : 19-- 외국인, 7/8 : 20-- 외국인, 9/0 : 18--
        if (genderNumber === 3 || genderNumber === 4 || genderNumber === 7 || genderNumber === 8) {
            year = '20';
        } else if (genderNumber === 9 || genderNumber === 0) {
            year = '18';
        }
        let birth = `${year}${data.userBirth}`;
        let age = appUtils.calcAge(birth);
        // 만 14세 미만 가입불가
        if (age < 14) {
            setAlertData({
                title: alertMsg.introTit,
                description: errMsg.USER_BIRTH_UNDER_14
            });
            setOpen(true);
            return false;
        }
        let payloadData = {
            name: data.userName,
            birth: birth,
            gender: genderNumber,
            rrnGender: genderNumber,
            // fgnGbn: '1',
            // certDate: appUtils.getYYYYMMDDHHMMSS(),
            telco: data.userTelecom, // 통신사
            cellPhone: data.userPhone
        };

        // 인증번호 호출
        requestIdentity(payloadData);
    };

    const authProcess = () => {
        setAlertData({
            title: '인증번호 발송',
            description: '인증번호를 확인해주세요.'
        });
        setOpen(true);
        setFormModify(false); // form disabled 처리
        setActiveTimer(true); // 타이머 노출 처리
        setReactive(true); // 동작처리
    };

    const validationSchema = Yup.object().shape(appUtils.getValidationConfig('AUTH'));

    const {
        handleSubmit,
        control,
        watch,
        formState: {errors},
        setValue,
        setFocus,
        isValid,
        submitCount
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(validationSchema)
    });

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="membership">
                    <div className="inner">
                        <form onSubmit={handleSubmit(onClickValidation)}>
                            <TitleArea pageTitle={title} />
                            <div className="fieldAreaWrap">
                                <AuthenticationFields
                                    formProp={{
                                        errors,
                                        control,
                                        watch,
                                        setValue,
                                        setFocus,
                                        isValid,
                                        submitCount
                                    }}
                                    onSubmit={handleSubmit(onClickValidation)}
                                    formModify={formModify}
                                    activeTimer={activeTimer}
                                    reactive={reactive}
                                    setReactive={setReactive}
                                    setActiveButton={setActiveButton}
                                    authValid={{
                                        authValidation: authValidation,
                                        setAuthValidation: setAuthValidation
                                    }}
                                />
                            </div>

                            <div className="btnFixedBottom">
                                {/* 버튼이 키보드 위에 고정되어야 하는 경우 btnFixedBottom에 클래스 "fixed" 추가 */}
                                <div className="inner">
                                    {!requestConfirm ? (
                                        <Button
                                            type="button"
                                            disabled={!activeButton}
                                            background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                            onClick={checkAuthNumber}
                                        >
                                            인증 완료
                                        </Button>
                                    ) : (
                                        <Button
                                            type="button"
                                            background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                            onClick={nextPage}
                                        >
                                            다음
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* 만 14세 미만 가입불가 */}
            <DefaultAlert
                open={open}
                handleClose={handleClose}
                title={alertData.title}
                contents={alertData.description}
            />
        </>
    );
}

export default AuthenticationNumber;
