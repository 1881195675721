import React from "react";

function PersonalInfoSKT() {
    return (
        <>
            <section>
                <h2>&lt;SK텔레콤 귀중&gt;</h2>
                <p>
                    본인은 SK텔레콤(주)(이하 ‘회사’라 합니다)가 제공하는
                    본인확인서비스(이하 ‘서비스’라 합니다)를 이용하기 위해,
                    다음과 같이 ‘회사’가 본인의 개인정보를 수집/이용하고,
                    개인정보의 취급을 위탁하는 것에 동의합니다.
                </p>
                <br />
                <h3>1. 수집항목</h3>
                <p>
                    - 이용자의 성명, 이동전화번호, 가입한 이동전화 회사,
                    생년월일, 성별
                    <br />
                    - 연계정보(CI), 중복가입확인정보(DI)
                    <br />
                    - 이용자가 이용하는 웹사이트 또는 Application 정보, 이용일시
                    <br />
                    - 내외국인 여부
                    <br />- 가입한 이동전화회사 및 이동전화브랜드
                </p>
                <br />
                <h3>2. 이용목적</h3>
                <p>
                    <strong className="underLine">
                        - 이용자가 웹사이트 또는 Application에 입력한
                        본인확인정보의 정확성 여부 확인 (본인확인서비스 제공)
                    </strong>
                    <br />
                    <strong className="underLine">
                        - 해당 웹사이트 또는 Application에
                        연계정보(CI)/중복가입확인정보(DI) 전송
                    </strong>
                    <br />
                    <strong className="underLine">
                        - 서비스 관련 상담 및 불만 처리 등
                    </strong>
                    <br />
                    <strong className="underLine">
                        - 이용 웹사이트/Application 정보 등에 대한 분석 및
                        세분화를 통한, 이용자의 서비스 이용 선호도 분석
                    </strong>
                </p>
                <br />
                <h3>3. 개인정보의 보유 및 이용기간</h3>
                <p>
                    <strong className="underLine">
                        - 이용자가 서비스를 이용하는 기간에 한하여 보유 및 이용.
                        다만, 아래의 경우는 제외
                    </strong>
                    <br />
                    <strong className="underLine">
                        - 법령에서 정하는 경우 해당 기간까지 보유(상세 사항은
                        회사의 개인정보취급방침에 기재된 바에 따름)
                    </strong>
                </p>
                <br />
                <h3>4. 본인확인서비스 제공을 위한 개인정보의 취급 위탁</h3>
                <p>
                    <strong className="underLine">
                        수탁자 : (주)다날, (주)드림시큐리티, (주)KG모빌리언스,
                        NHN한국사이버결제(주), 한국모바일인증(주),
                        NICE평가정보(주), SCI평가정보(주), 코리아크레딧뷰로
                    </strong>
                    <br />
                    취급위탁 업무 : 본인확인정보의 정확성 여부
                    확인(본인확인서비스 제공), 연계정보(CI)/중복가입확인정보(DI)
                    생성 및 전송, 서비스 관련 상담 및 불만 처리, 휴대폰인증보호
                    서비스, 이용자의 서비스 이용 선호도 분석정보 제공관련 시스템
                    구축 광고매체 연동 및 위탁영업 등<br />
                    ※수탁자의 상세 개인정보 취급 위탁 내용은 각 수탁자가 정하는
                    절차와 방법에 따라 수탁자 홈페이지 등에 게시된 수탁자의
                    개인정보 취급방침 및 제3자 제공 동의 방침 등에 따릅니다.
                </p>
                <br />
                <h3>
                    5. 상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않으실
                    경우, 서비스를 이용하실 수 없습니다.
                </h3>
                <br />
                <p>
                    * 회사가 제공하는 서비스와 관련된 개인정보의 취급과 관련된
                    사항은, 회사의 개인정보취급방침(회사 홈페이지
                    www.sktelecom.com)에 따릅니다.
                </p>
            </section>
        </>
    );
}

export default PersonalInfoSKT;
