import React from 'react';

function JoinServiceAgree() {
    return (
        <>
            <section>
                <h2>제 1 조 [목적]</h2>
                <p>
                    이 약관은 (주)그린잇(이하 “회사”라 함)이 제공하는 골라가 골프장 예약 서비스 앱의 이용 및 기타 유관
                    서비스와 관련하여 회사와 서비스 이용약관에 동의한 자(이하 “회원”이라 함)간의 권리, 의무 및 책임사항,
                    기타 필요한 사항을 규정하는데 목적이 있습니다. 단, 제공되는 서비스에 따라 개별약관의 동의가 필요할
                    수 있으며 이 경우에는 통합약관보다 개별약관이 우선 적용됩니다.
                </p>
            </section>
            <section>
                <h2>제 2 조 [용어의 정의]</h2>
                <p>
                    이 약관에서 사용하는 주요 용어의 정의는 다음과 같습니다.
                    <br />
                    1. “서비스”란 회사에서 제공하는 골라가 골프장 예약 서비스 앱을 말하며 회사의 서비스를 가입한
                    회원이면 누구나 이용하실 수 있습니다.
                    <br />
                    2. “회원”이라 함은 회사의 서비스에 접속하여 약관에 따라 회원등록을 하고 회사가 제공하는 서비스를
                    이용하는 고객을 말합니다.
                    <br />
                    3. “비밀번호”라 함은 회원이 가입 시 설정한 아이디와 일치되는 회원임을 확인하고 비밀보호를 위해 회원
                    자신이 정한 문자 또는 숫자의 조합을 의미합니다.
                    <br />
                    4. “게시물”이란 회사나 회원이 서비스 내에서 게시한 글, 사진, 각종파일, 링크 등을 말합니다.
                    <br />
                    5. “제휴사”란 회사와 계약을 체결하여 회원에게 상품 등을 공급하거나 각종 정보 및 혜택 등을 제공하는
                    온라인 및 오프라인 업체를 의미합니다. 제휴사는 서비스에 고지되어 있으며, 회사 및 제휴사의 사정에
                    따라 해지 또는 추가될 수 있습니다.
                </p>
            </section>
            <section>
                <h2>제 3 조 [약관의 효력과 변경]</h2>
                <p>
                    1. 약관은 회사에서 제공하는 서비스에 직접 공시함으로써 효력이 발생하며, 합리적인 사유가 발생할 경우
                    『약관의 규제에 관한 법률』, 『전자상거래 등에서의 소비자보호에 관한 법률』 및 『정보통신망이용촉진
                    및 정보보호 등에 관한 법률』 등 관련법령에 위배되지 않는 범위 안에서 개정될 수 있습니다.
                    <br />
                    2. 개정된 약관은 서비스에 공지함으로써 효력을 발휘하며, 회원의 권리 또는 의무 등 중요한 규정의
                    개정은 사전에 공지합니다.
                    <br />
                    3. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 그 적용일자 7일
                    이전부터 적용일자 전까지 공지합니다. 다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 최소한
                    30일 이상의 사전 유예기간을 두고 공지합니다.
                    <br />
                    4. 약관에 동의하는 것은 정기적으로 서비스에 방문하여 약관의 변경사항을 확인하는 것에 동의함을
                    의미합니다.
                    <br />
                    5. 변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는 회사에서 책임지지 않습니다.
                    <br />
                    6. 회원은 변경된 약관에 동의하지 않을 권리가 있고, 변경된 약관에 동의하지 않을 경우 회원
                    탈퇴(해지)를 요청할 수 있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를 표시하지
                    아니하고 서비스를 계속 사용할 경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
                </p>
            </section>
            <section>
                <h2>제 4 조 [약관 및 준칙]</h2>
                <p>
                    1. 이 약관은 회사가 제공하는 서비스에 관한 이용안내에 적용합니다.
                    <br />
                    2. 약관에서 정하지 아니한 사항과 약관의 해석에 관하여는 『전자상거래 등에서의 소비자보호에 관한
                    법률』, 『약관의 규제에 관한 법률』, 『상법』 및 공정거래위원회가 정하는 『전자상거래 등에서의
                    소비자 보호지침』 등 관련 법령 및 상관례에 따릅니다.
                </p>
            </section>
            <section>
                <h2>제 5 조 [이용계약 체결]</h2>
                <p>
                    1. 회원이 되고자 하는 자(이하 “가입신청자”라 함)는 회사가 정한 약관의 내용에 대하여 동의한 후
                    회원가입신청을 하고 회사가 이러한 신청에 승낙함으로써 이용계약이 체결됩니다.
                    <br />
                    2. 회사는 가입신청자의 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각호에 해당하는
                    신청에 대해서 서비스 이용을 승낙하지 않거나 사후에 이용계약을 해지할 수 있습니다.
                    <blockquote>
                        - 가입신청자가 이 약관에 의해 회원자격을 이전에 상실한 적이 있는 경우 (단, 회사의 회원 재가입
                        승낙을 얻은 경우 예외)
                        <br />
                        - 실명이 아니거나 타인의 명의를 이용한 경우
                        <br />
                        - 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
                        <br />- 기타 회사가 합리적인 판단에 의하여 이용계약 해지가 필요하다고 인정하는 경우
                    </blockquote>
                    3. 제2항의 신청을 승낙함에 있어 회사는 관계법령 및 회사정책에 따라 서비스의 이용 가능한 범위, 시간,
                    횟수 등에 차등을 두어 이를 승낙할 수 있습니다.
                    <br />
                    4. 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수
                    있습니다.
                    <br />
                    5. 제2항과 제4항에 따라 회원가입신청의 승낙을 하지 않거나 유보한 경우, 회사는 원칙적으로 이를
                    가입신청자에게 알리도록 합니다.
                    <br />
                    6. 제1항과 2항에 따른 신청에 있어 회사는 전문기관을 통해 회원의 실명확인 및 본인인증을 요청할 수
                    있습니다.
                    <br />
                    7. 회원가입의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.
                    <br />
                    8. 관련법령에 의거 회원가입 신청일 현재 만 14세 미만의 회원 가입이 제한될 수 있습니다.
                </p>
            </section>
            <section>
                <h2>제 6 조 [회원정보의 변경]</h2>
                <p>
                    1. 회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다.
                    <br />
                    2. 회원은 회원가입 신청 시 등록한 사항이 변경되었을 경우 개인정보관리화면에서 회원정보를 수정하는
                    방법으로 회사에 그 변경사항을 알려야 합니다. 변경사항을 회사에 알리지 않아 발생한 불이익에 대해
                    회사는 책임지지 않습니다.
                </p>
            </section>
            <section>
                <h2>제 7 조 [개인정보 보호]</h2>
                <p>
                    1. 회사는 『개인정보보호법』 등 관계법령에 따라 회원의 개인정보를 보호하기 위해 노력하며, 서비스
                    제공을 위한 최소한의 개인정보를 수집합니다.
                    <br />
                    2. 개인정보의 보호 및 사용에 대해서 관계법령과 회사의 개인정보취급방침을 적용합니다. 다만, 회사의
                    공식 서비스 사이트 및 앱 이외의 링크된 사이트에서는 회사의 개인정보취급방침이 적용되지 않습니다.
                    <br />
                    3. 회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 개인정보처리방침에 따라
                    이용자의 개인정보를 제공할 필요가 있는 경우 해당 시점에 회원의 동의를 받아 개인정보를 제3자에 제공할
                    수 있습니다. 이때 회사는 제공되는 개인정보의 항목, 제공받는 자, 제공받는 자의 개인정보 이용목적 및
                    보유∙이용기간 등을 회원에게 미리 명시하거나 고지합니다.
                </p>
            </section>
            <section>
                <h2>제 8 조 [회원의 아이디 및 비밀번호 관리 의무]</h2>
                <p>
                    1. 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 해서는 안
                    됩니다.
                    <br />
                    2. 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에
                    통지하고 회사의 안내에 따라야 합니다.
                    <br />
                    3. 제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지
                    않아 발생한 불이익에 대해 회사는 책임지지 않습니다.
                    <br />
                    4. 회사의 귀책사유 없이 아이디 또는 비밀번호의 유출, 양도, 대여로 인해 발생하는 손실이나 손해에
                    대해서 회원 본인이 책임을 부담합니다.
                </p>
            </section>
            <section>
                <h2>제 9 조 [회사의 의무]</h2>
                <p>
                    1. 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며, 안정적으로 서비스를
                    제공하기 위하여 최선을 다해 노력합니다.
                    <br />
                    2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(예약 및 결제정보 포함)보호를 위해
                    책임을 다합니다.
                    <br />
                    3. 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를
                    처리하고 이에 대해서 고객센터 혹은 이메일 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.
                </p>
            </section>
            <section>
                <h2>제 10 조 [회원의 의무]</h2>
                <p>
                    1. 회원은 다음 행위를 해서는 안 됩니다.
                    <blockquote>
                        - 회원가입 신청 또는 변경 시 허위내용 등록
                        <br />
                        - 타인의 정보 도용
                        <br />
                        - 회사가 게시한 정보를 악의적으로 변경 및 유포
                        <br />
                        - 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해
                        <br />
                        - 회사와 제휴사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                        <br />
                        - 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는
                        게시하는 행위
                        <br />
                        - 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
                        <br />- 기타 불법이거나 부당한 행위
                    </blockquote>
                    2. 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는
                    사항 등을 준수해야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안 됩니다.
                </p>
            </section>
            <section>
                <h2>제 11 조 [회원에 대한 통지]</h2>
                <p>
                    회사가 회원에게 알려야 할 사항이 있는 경우, 가입 시 회원이 회사에게 제공한 이메일 주소 또는
                    휴대전화번호를 통해 개별 통지하거나 서비스 앱 또는 웹사이트를 통해 통지합니다.
                </p>
            </section>
            <section>
                <h2>제 12 조 [서비스 이용시간 및 제공]</h2>
                <p>
                    1. 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 회사의 업무상 또는 기술상의 이유로
                    서비스가 일시 중지될 수 있고, 또한 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수
                    있습니다.
                    <br />
                    2. 회사는 서비스를 일정범위로 분할하여 각 범위별로 이용 가능한 시간을 별도로 정할 수 있습니다.
                    <br />
                    3. 회사는 회원에게 아래와 같은 서비스를 제공합니다.
                    <blockquote>
                        - 골라가 골프장 예약 서비스
                        <br />
                        - 골프장 비대면 서비스 (셀프 체크인 등)
                        <br />- 위치정보 서비스 (서비스별 선택사항)
                    </blockquote>
                    4. 회사는 모바일 셀프 체크인 및 코스 가이드 서비스 제공을 위해 회원의 위치정보를 이용하며, 위치정보
                    사용을 위해 회원가입 시 관련 내용을 필수 동의해야 하는 사항으로 규정합니다. 회사는 회원이 제공한
                    개인위치정보를 고객의 동의 없이 서비스 제공 이외의 목적으로 이용하지 않습니다.
                    <br />
                    5. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는
                    경우 서비스의 제공을 일시적으로 중단할 수 있습니다.
                    <br />
                    6. 제5항의 경우 회사는 제11조 [회원에 대한 통지]에서 정한 방법으로 회원에게 통지합니다. 다만, 회사가
                    사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다
                    <br />
                    7. 회사는 서비스의 제공에 필요한 경우 정기점검 또는 수시점검을 실시할 수 있으며, 점검시간은 서비스
                    제공 화면에 공지한 바에 따릅니다.
                </p>
            </section>
            <section>
                <h2>제 13조 [게시물의 관리]</h2>
                <p>
                    1. 회사가 작성한 게시물 또는 저작물에 대한 저작권 등 일체의 지적재산권은 회사에 귀속합니다.
                    <br />
                    2. 회원의 게시물이 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보 보호법』 및
                    『저작권법』 등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라
                    해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 법령에 따라 조치를 취하여야
                    합니다. 회사는 다음 각 호에 해당하는 게시물이나 자료를 사전통지 없이 삭제하거나 이동 또는 등록
                    거부를 할 수 있습니다.
                    <blockquote>
                        - 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우
                        <br />
                        - 공서양속에 위반되는 내용을 유포하거나 링크시키는 경우
                        <br />
                        - 불법복제 또는 해킹을 조장하는 내용인 경우
                        <br />
                        - 영리를 목적으로 하는 광고의 경우
                        <br />
                        - 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
                        <br />
                        - 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 내용인 경우
                        <br />- 회사에서 규정한 게시물 원칙에 어긋나거나, 게시판 성격에 부합하지 않는 경우
                    </blockquote>
                </p>
            </section>
            <section>
                <h2>제 14 조 [게시물에 대한 저작권]</h2>
                <p>
                    1. 회원이 서비스 화면 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다.
                    <br />
                    2. 회사는 게시자의 동의 없이 게시물을 상업적으로 이용할 수 없습니다.
                    <br />
                    3. 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 서비스에 게재된 자료를
                    상업적으로 사용할 수 없습니다.
                    <br />
                    4. 회사는 회원이 게시하거나 등록하는 서비스 내의 내용물, 게시 내용에 대해 제13조 제2항 각 호에
                    해당된다고 판단되는 경우 사전통지 없이 삭제, 이동하거나 등록 거부를 할 수 있습니다.
                    <br />
                    5. 회원이 탈퇴하는 경우, 탈퇴 전에 등록 또는 게재한 게시물(댓글 포함)은 삭제되지 않습니다. 회원 탈퇴
                    시 회원정보가 삭제되어 작성자 본인을 확인할 수 없게 되므로 게시물에 대한 편집 또는 삭제가 원천적으로
                    불가하며, 게시물의 삭제를 원하는 회원은 반드시 회원 탈퇴 이전에 해당 게시물을 삭제하여야 합니다.
                </p>
            </section>
            <section>
                <h2>제 15 조 [정보의 제공 및 광고 게재]</h2>
                <p>
                    1. 회사는 회원의 사전 동의를 받은 경우에 한하여 이메일, 카카오톡, 문자메시지(SMS, MMS) 등 전자적
                    전송 매체를 통해 회원의 서비스 이용에 필요하다고 인정되는 정보를 제공할 수 있습니다. 다만, 회원은
                    거래 관련 정보 및 고객문의 등에 대한 답변을 제외하고 관련 법에 따라 언제든지 정보 수신동의를
                    철회하실 수 있으며, 이 경우 회사는 마케팅 정보 등을 제공하는 행위를 중단합니다.
                    <br />
                    2. 회사는 서비스 개선 및 회원을 대상으로 하는 서비스 소개 등의 목적으로 회원에게 동의를 받고 관련
                    법령에 따라 추가적인 개인 정보를 수집할 수 있습니다.
                </p>
            </section>
            <section>
                <h2>제 16 조 [이용계약 해지]</h2>
                <p>
                    1. 회원은 이용계약을 해지하고자 하는 때에는 회원 본인이 회원탈퇴 메뉴를 이용해 가입해지 가능합니다.
                    다만, 회사는 이미 골프예약이 되어 있거나 위약금 발생 상황에 따라 이용계약 해지를 막을 수 있으며, 그
                    외에 회사는 관련 법령 등이 정하는 바에 따라 해지 요청 시 제공하는 전체서비스 이용계약은 해지되고
                    이를 즉시 처리합니다. 이용계약 해지로 인해 발생하는 서비스 이용제한, 이용내역 삭제 등의 불이익은
                    회원 본인이 부담합니다.
                    <br />
                    2. 회원이 해지 요청 시 관련법 및 개인정보취급방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고
                    즉시 회원의 부속된 모든 데이터는 소멸됨과 동시에 복구할 수 없습니다.
                    <br />
                    3. 이용계약 해지 이후 확인이 필요한 사항(예약, 결제 등)은 회사 고객센터에서 확인이 가능합니다.
                </p>
            </section>
            <section>
                <h2>제 17 조 [이용 제한]</h2>
                <p>
                    1. 회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지,
                    영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다.
                    <br />
                    2. 회사는 전항에도 불구하고 『주민등록법』을 위반한 명의도용 및 결제도용, 『저작권법』 및
                    『컴퓨터프로그램보호법』을 위반한 불법프로그램의 제공 및 운영방해, 『정보통신망이용촉진 및 정보보호
                    등에 관한 법률』을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 회원이
                    관련법을 위반한 경우에 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 서비스
                    이용을 통해 획득한 기타 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
                    <br />
                    3. 본 조에 따라 서비스 이용을 제한하거나 계정을 정지하는 경우에는 회사는 제11조 [회원에 대한 통지]에
                    따라 통지합니다.
                    <br />
                    4. 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때
                    이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.
                </p>
            </section>
            <section>
                <h2>제 18 조 [결제 수단]</h2>
                <p>
                    1. 회원은 회사에서 제공하는 서비스 또는 상품에 대한 대금지급을 다음 각 호의 방법 중 하나로 할 수
                    있습니다. (일부의 결합하여 결제 가능)
                    <blockquote>
                        (ㄱ) 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
                        <br />
                        (ㄴ) 카카오페이, 토스페이, 네이버페이, 삼성페이, 페이코 등 간편결제
                        <br />
                        (ㄷ) 실시간 계좌입금, 가상계좌입금
                        <br />
                        (ㄹ) 회원 의 경우 쿠폰, 포인트(마일리지), 적립금 등
                        <br />
                        (ㅁ) 기타 회사가 추가 지정하는 결제 수단
                    </blockquote>
                    2. 회사는 회원의 요금 정산에 법적, 기술적 문제가 있거나 회사가 예견하지 못한 장애(전산 통신망 장애
                    등)가 발생한 경우를 제외한 정산 오류에 대해 책임을 다합니다.
                    <br />
                    3. 회원의 제휴 골프장 예약 관련 위약금은 해당 골프장의 규정을 기준으로 하며, 예약 관련 문의는 골라가
                    고객센터를 통해 문의가 가능합니다.
                    <br />
                    4. 회사의 정책에 따라 회원에게 결제 수단의 변경을 요청하거나 결제를 보류 또는 거부할 수 있습니다.
                    <br />
                    5. 결제대금과 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로
                    회원이 부담합니다.
                </p>
            </section>
            <section>
                <h2>제 19 조 [과오금 및 환불]</h2>
                <p>
                    1. 과오금이 발생한 경우 회사는 이용자가 상품 등의 대금을 지급한 결제수단과 동일한 방법으로 과오금을
                    환불합니다. 단, 동일한 방법으로 환불이 불가능할 경우 이용자에게 개별 연락하여 확인 후 계좌로
                    입금합니다.
                    <br />
                    2. 회원에게 책임 있는 사유로 과오금이 발생한 경우 환불에 소요되는 수수료 등은 회원이 부담합니다.
                </p>
            </section>
            <section>
                <h2>제 20 조 [청약철회]</h2>
                <p>
                    1. 상품 등의 구매에 관한 계약을 한 회원은 구매일 또는 이용가능일로부터 7일 이내에 별도의 수수료 없이
                    청약철회의 신청을 할 수 있습니다. 단, 상품의 특성에 따라 별도의 위약 규정이 있는 상품의 경우(골프장
                    이용 예약, 숙박권, 여행상품 등) 해당 상품별로 규정된 위약/규정을 고지하며 회원이 구매 시에 이러한
                    위약 규정을 고지 받은 경우에는 해당 규정이 약관보다 우선 적용 됩니다.
                    <br />
                    2. 회원은 다음 각 호에 해당하는 경우 청약철회를 할 수 없습니다.
                    <blockquote>
                        (ㄱ) 회원에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우
                        <br />
                        (ㄴ) 회원이 재화 등을 사용 혹은 일부 소비사여 재화 등의 가치가 하락한 경우
                        <br />
                        (ㄷ) 시간의 경과에 의하여 재판매가 곤란할 경우
                        <br />
                        (ㄹ) 복제 가능한 재화 등의 포장을 훼손한 경우
                        <br />
                        (ㅁ) 그 밖에 거래의 안전을 위해 법령으로 정해진 경우
                    </blockquote>
                    3. 회사는 회원의 청약철회 또는 계약해지 의사표시를 수령한 날(혹은 상품 등을 받환 받은 날)로부터
                    3영업일 이내에 이미 지급받은 상품 등의 대금을 회원이 상품 등의 대금을 지급한 결제 수단을 통하여
                    환급합니다.
                    <br />
                    4. 회사는 대금을 환급함에 있어 회원이 신용카드 혹은 전자화폐 등의 결제수단으로 상품 등의 결제가
                    이루어진 경우 환불은 이용자가 결제한 수단과 동일한 수단으로 환불하는 것을 원칙으로 합니다. 동일한
                    수단으로 환불하는 것이 불가능한 경우 다음 각 호 중의 방법으로 처리합니다.
                    <blockquote>
                        (ㄱ) 회원에게 개별 연락하여 확인 후 계좌 입금
                        <br />
                        (ㄴ) 그 밖에 회원과 협의한 방법
                    </blockquote>
                    5. 회사는 청약 철회 시 회원의 결제건에 대하여 결제수단을 제공한 사업자로 하여금 상품 등의 대금의
                    청구를 정지 또는 취소 하도록 「전자상거래등에서의 소비자 보호에 관한 법률」에 따른 필요한 조치를
                    취합니다.
                </p>
            </section>
            <section>
                <h2>제 21 조 [손해배상]</h2>
                <p>
                    회사는 서비스에서 무료로 제공하는 서비스의 이용과 관련하여 개인정보보호정책에서 정하는 내용에
                    해당하지 않는 사항에 대하여는 어떠한 손해도 책임을 지지 않습니다.
                </p>
            </section>
            <section>
                <h2>제 22 조 [면책조항]</h2>
                <p>
                    1. 천재지변, 전쟁 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우, 서비스
                    제공에 대한 회사의 책임은 면제됩니다.
                    <br />
                    2. 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 않는 등 회사의 책임없는 사유로
                    손해가 발생한 경우, 회사의 책임이 면제됩니다.
                    <br />
                    3. 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 회사의 책임은
                    면제됩니다.
                    <br />
                    4. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
                    <br />
                    5. 회사는 회원의 컴퓨터 또는 모바일 기기의 오류로 손해가 발생한 경우, 또는 회원이 신상정보 및 이메일
                    주소 또는 연락가능한 수단을 부실하게 기재하여 손해가 발생한 경우에 책임을 지지 않습니다.
                    <br />
                    6. 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.
                    <br />
                    7. 회사는 이용자 상호간 및 이용자와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할
                    의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.
                    <br />
                    8. 회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서 어떠한 손해도 책임을 지지 않습니다.
                </p>
            </section>
            <section>
                <h2>제 23 조 [관할 및 준거법]</h2>
                <p>
                    1. 약관에 명시되지 않은 사항은 『전기통신사업법』 등 관계법령과 상관습에 따릅니다.
                    <br />
                    2. 서비스 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본사 소재지를 관할하는 지방법원을
                    제 1심의 관할 법원으로 합니다.
                    <br />
                </p>
            </section>
            <section>
                <h2>부칙</h2>
                <p>1. 이 약관은 2023년 05월 02일부터 적용됩니다.</p>
            </section>
        </>
    );
}

export default JoinServiceAgree;
