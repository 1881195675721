import React from 'react';

function FoodResList({restData, currentRestIdx, onHandleRestTab}) {
    return (
        <div className="foodresList-category-scroll">
            <ul>
                {restData
                    ? restData.map((rest, idx) => {
                          return (
                              <li
                                  id={idx}
                                  className={`tab ` + (currentRestIdx === idx && 'on')}
                                  onClick={() => {
                                      onHandleRestTab(idx, rest?.id);
                                  }}
                                  key={rest?.id}
                              >
                                  <button type="button">{rest?.restaurantName}</button>
                              </li>
                          );
                      })
                    : ''}
            </ul>
        </div>
    );
}

export default FoodResList;
