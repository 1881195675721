import {fetchWrapper} from '_helpers';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import {clickGpsSelfCheckIn} from '_helpers/amplitude/events/click.event';
import {completeSelfCheckin, failSelfCheckinGps} from '_helpers/amplitude/events/etc.event';
import {alertActions} from '_store/alert.slice';
import {appActions} from '_store/app.slice';
import {smartRoundActions} from '_store/smartRound.slice';
import AirbridgeUtil from 'common/airbridgeUtil';
import {
    CANCELTMESSAGE,
    CHECKIN_TIME_ONE_HOUR_CODE,
    SETTINGMESSAGE,
    SETTINGMESSAGE_CHECKIN,
    SUCCESS_CODE
} from 'common/constants';
import {appUtils, localStorageUtil} from 'common/utils';
import CommonAlert from 'components/alert/CommonAlert';
import DefaultAlert from 'components/alert/DefaultAlert';
import Lottie from 'lottie-react';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import LottieData from '../../../../src/assets/images/reservation/check_in_animation';
import {ReactComponent as CheckTouch} from '../../../assets/images/reservation/check_in_hand.svg';
import CheckInFailModal from './CheckInFailModal';
import CheckInNameModal from './CheckInNameModal';
import QrCheckInModal from './QrCheckInModal';
import TagNameModal from './TagNameModal';
import {promotionActions} from '_store/promotion.slice';

const CheckInBofore = (props) => {
    const {} = props;
    const bookingId = useSelector((x) => x?.smartRound.bookingId);
    const round = useSelector((x) => x?.smartRound.round);
    const smartRound = useSelector((x) => x?.smartRound.smartRound);
    const golfBag = useSelector((x) => x?.smartRound.golfBag);
    const golfBagList = useSelector((x) => x?.smartRound.golfBagList);
    const user = useSelector((x) => x?.smartRound.user);
    const alertData = useSelector((x) => x?.alert);
    const timer = useSelector((x) => x?.smartRound.timer);
    const dispatch = useDispatch();

    //GPS 위성 정보(현재 좌표) 확인
    let locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
    const [latitude, setLatitude] = useState(locationStorage?.lat || '');
    const [longitude, setLongitude] = useState(locationStorage?.lon || '');

    // 위치정보 동의 권한 확인
    // const [gpsRequest, setGpsRequest] = useState(locationStorage?.gpsrequest || '');
    // 디바이스 체크
    const [device, setDevice] = useState(locationStorage?.device || '');

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContents, setAlertContents] = useState('');
    const [tagNameCheck, setTagNameCheck] = useState(false);
    const [qrModalOpen, setQrModalOpen] = useState(false);
    const [checkInFailModalOpen, setCheckInFailModalOpen] = useState(false);
    const [checkInNameModalOpen, setCheckInNameModalOpen] = useState(false);
    const [checkInPlaceModalOpen, setCheckInPlaceModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const onClickQr = () => {
        fetchWrapper.get(`/booking/checkin/possibility?bookingId=${bookingId}&checkinType=QRCODE`).then((response) => {
            if (response?.code === SUCCESS_CODE || response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                setQrModalOpen(true);
            } else {
                // 셀프체크인 Alert Open
                setAlertOpen(true);
                setAlertContents(response?.message);
            }
        });
    };

    const selfCheckInFailProcess = useCallback(
        (item) => {
            const payload = {
                roundBookingId: bookingId,
                checkinNo: item.checkinNo,
                lockerNo: item.lockerNo,
                bagName: item.bagName,
                cartList: item.cartList,
                caddyList: item.caddyList
            };

            if (golfBag && golfBag.length > 0) {
                payload.bagName = golfBag;
            } else if (!payload.bagName) {
                payload.bagName = golfBagList ? golfBagList[0]?.tagName : user?.name;
            }

            dispatch(appActions.setLoading(true));
            fetchWrapper
                .post(`/booking/checkin-info`, payload)
                .then((response) => {
                    if (response?.code === SUCCESS_CODE) {
                        fetchWrapper
                            .get(`/amplitude-events/reservation-complete?bookingId=${bookingId}`, null)
                            .then((response) => {
                                if (response.code === SUCCESS_CODE) {
                                    const _data = response.data;
                                    _data.checkin_type = 'gps';
                                    completeSelfCheckin(_data, getAmplitudeRootPath());
                                }
                            });
                        AirbridgeUtil.event.completeSelfCheckIn();
                        dispatch(promotionActions.setPromotionCheckinModal(true));

                        // 티타임 경과 1시간 이내일 시 > 체크인 완료 후 Alert 노출
                        fetchWrapper
                            .get(
                                `/booking/checkin/possibility?bookingId=${bookingId}&checkinType=CHECKIN&userLat=${latitude}&userLon=${longitude}`
                            )
                            .then((response) => {
                                if (response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                                    // 셀프체크인 Alert Open
                                    dispatch(
                                        smartRoundActions.setCheckInAlertMessage({
                                            checkInAlertMessage: response?.message
                                        })
                                    );
                                }
                            })
                            .finally(() => {
                                dispatch(smartRoundActions.setCheckin({checkin: true}));
                            });
                    } else {
                        setAlertOpen(true);
                        setAlertContents(response?.message);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        dispatch(appActions.setLoading(false));
                    }, 500);
                });
        },
        [golfBag, golfBagList]
    );

    const selfCheckInProcessOnly = useCallback(() => {
        const payload = {
            tagName: golfBag
        };
        if (!payload.tagName) {
            payload.tagName = golfBagList ? golfBagList[0]?.tagName : user?.name;
        }

        dispatch(appActions.setLoading(true));
        fetchWrapper
            .post(`/booking/${bookingId}/process`, payload)
            .then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    fetchWrapper
                        .get(`/amplitude-events/reservation-complete?bookingId=${bookingId}`, null)
                        .then((response) => {
                            if (response.code === SUCCESS_CODE) {
                                const _data = response.data;
                                _data.checkin_type = 'gps';
                                completeSelfCheckin(_data, getAmplitudeRootPath());
                            }
                        });
                    AirbridgeUtil.event.completeSelfCheckIn();
                    dispatch(promotionActions.setPromotionCheckinModal(true));

                    // 티타임 경과 1시간 이내일 시 > 체크인 완료 후 Alert 노출
                    fetchWrapper
                        .get(
                            `/booking/checkin/possibility?bookingId=${bookingId}&checkinType=CHECKIN&userLat=${latitude}&userLon=${longitude}`
                        )
                        .then((response) => {
                            if (response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                                // 셀프체크인 Alert Open
                                dispatch(
                                    smartRoundActions.setCheckInAlertMessage({
                                        checkInAlertMessage: response?.message
                                    })
                                );
                            }
                        })
                        .finally(() => {
                            dispatch(smartRoundActions.setCheckin({checkin: true}));
                        });
                } else {
                    if (response?.message) {
                        setAlertOpen(true);
                        setAlertContents(response?.message);
                    }
                }
            })
            .finally(() => {
                setTimeout(() => {
                    dispatch(appActions.setLoading(false));
                }, 500);
            });
    }, [golfBag, user]);

    const selfCheckInProcess = useCallback(() => {
        dispatch(appActions.setLoading(true));
        dispatch(smartRoundActions.getCheckInMember(bookingId))
            .then((res) => {
                if (res.payload.code === SUCCESS_CODE) {
                    const payload = {
                        tagName: golfBag
                    };
                    if (!payload.tagName) {
                        payload.tagName = golfBagList ? golfBagList[0]?.tagName : user?.name;
                    }

                    dispatch(appActions.setLoading(true));
                    fetchWrapper
                        .post(`/booking/${bookingId}/process`, payload)
                        .then((response) => {
                            if (response?.code === SUCCESS_CODE) {
                                fetchWrapper
                                    .get(`/amplitude-events/reservation-complete?bookingId=${bookingId}`, null)
                                    .then((response) => {
                                        if (response.code === SUCCESS_CODE) {
                                            const _data = response.data;
                                            _data.checkin_type = 'gps';
                                            completeSelfCheckin(_data, getAmplitudeRootPath());
                                        }
                                    });
                                AirbridgeUtil.event.completeSelfCheckIn();
                                dispatch(promotionActions.setPromotionCheckinModal(true));

                                // 티타임 경과 1시간 이내일 시 > 체크인 완료 후 Alert 노출
                                fetchWrapper
                                    .get(
                                        `/booking/checkin/possibility?bookingId=${bookingId}&checkinType=CHECKIN&userLat=${latitude}&userLon=${longitude}`
                                    )
                                    .then((response) => {
                                        if (response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                                            // 셀프체크인 Alert Open
                                            dispatch(
                                                smartRoundActions.setCheckInAlertMessage({
                                                    checkInAlertMessage: response?.message
                                                })
                                            );
                                        }
                                    })
                                    .finally(() => {
                                        dispatch(smartRoundActions.setCheckin({checkin: true}));
                                    });
                            } else {
                                if (response?.message) {
                                    setAlertOpen(true);
                                    setAlertContents(response?.message);
                                }
                            }
                        })
                        .finally(() => {
                            setTimeout(() => {
                                dispatch(appActions.setLoading(false));
                            }, 500);
                        });
                } else {
                    if (['8551', '8553'].includes(res.payload.code)) {
                        const frontDeskCheckInMemberInfoList = res.payload.data?.frontDeskCheckInMemberInfoList;
                        if (
                            frontDeskCheckInMemberInfoList?.length === 1 &&
                            frontDeskCheckInMemberInfoList[0].isCheckinYn &&
                            frontDeskCheckInMemberInfoList[0].checkinType === '프론트'
                        ) {
                            const payload = JSON.parse(JSON.stringify(frontDeskCheckInMemberInfoList[0]));
                            if (golfBag && golfBag.length > 0) {
                                payload.bagName = golfBag;
                            }
                            selfCheckInFailProcess(payload);
                        } else {
                            dispatch(appActions.setLoading(false));
                            setCheckInNameModalOpen(true);
                        }
                    } else if (['8552'].includes(res.payload.code)) {
                        dispatch(appActions.setLoading(false));
                        setCheckInFailModalOpen(true);
                    } else {
                        if (res.payload?.message) {
                            dispatch(appActions.setLoading(false));
                            setAlertOpen(true);
                            setAlertContents(res.payload.message);
                        }
                    }
                }
            })
            .catch((e) => {
                dispatch(appActions.setLoading(false));
                setAlertOpen(true);
                setAlertContents(e);
            });
    }, [golfBag, user]);

    // 체크인 클릭 시  Alert 조건설정
    const tagNameCheckOpen = () => {
        const gpsRequest = JSON.parse(localStorageUtil.get('LOCATION'))?.gpsrequest || '';
        let roundAtStr = round?.roundAt;
        if (!roundAtStr) return;

        // 위치정보 동의 권한 확인
        if (device === 'android' && gpsRequest === 1) {
            dispatch(
                alertActions.showAlert({
                    type: CANCELTMESSAGE,
                    id: 'checkInLocation',
                    messageData: {
                        title: '앱 권한 요청',
                        message:
                            '셀프 체크인 사용을 위해 디바이스 > 설정 > 앱 권한에서 위치 권한을 허용해주세요. (필수권한)'
                    }
                })
            );
            return;
        } else if (device === 'android' && gpsRequest === 2) {
            dispatch(
                alertActions.showAlert({
                    type: SETTINGMESSAGE,
                    id: 'checkInLocation',
                    messageData: {
                        title: '앱 권한 요청',
                        message:
                            '셀프 체크인 사용을 위해 디바이스 > 설정 > 앱 권한에서 위치 권한을 허용해주세요. (필수권한)'
                    }
                })
            );

            return;
        } else if (device === 'ios' && gpsRequest === 1) {
            dispatch(
                alertActions.showAlert({
                    type: SETTINGMESSAGE_CHECKIN,
                    id: 'checkInLocation',
                    messageData: {
                        title: '앱 권한 요청',
                        message:
                            '셀프 체크인 사용을 위해 디바이스 > 설정 > 골라가 > 위치에서 권한을 허용해주세요. (필수권한)'
                    }
                })
            );

            return;
        }

        /*
         * 스마트라운드 미제공 골프장 대응 > 팝업 노출
         * 1. 스마트라운드가 false인 경우
         * 2. 스마트라운드가 true지만 v1인 경우 (v1:셀프체크인 미제공)
         * */
        const isSmartRoundCheck = round?.isSmartRoundApplied;
        const isGolfClubVersion = round?.apiVersion;
        if (!isSmartRoundCheck || (isSmartRoundCheck && isGolfClubVersion === 'v1')) {
            setAlertOpen(true);
            setAlertContents(
                `해당 골프장은 셀프 체크인 서비스를\n 지원하지 않습니다.\n 추후 이용 가능 하도록 준비하겠습니다.`
            );

            fetchWrapper.get(`/amplitude-events/reservation-complete?bookingId=${bookingId}`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    failSelfCheckinGps(response.data, getAmplitudeRootPath());
                }
            });

            return;
        }

        if (!appUtils.isToday(roundAtStr)) {
            setAlertOpen(true);
            setAlertContents(
                `셀프체크인은 당일에만 할 수 있습니다.\n${appUtils.dateFormatTypeDay(
                    round?.roundAt
                )}에 다시 시도해주세요.`
            );

            fetchWrapper.get(`/amplitude-events/reservation-complete?bookingId=${bookingId}`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    failSelfCheckinGps(response.data, getAmplitudeRootPath());
                }
            });
            return;
        }

        showSelfCheckInCheckAlert(); // 셀프체크인 가능여부 체크

        fetchWrapper.get(`/amplitude-events/view-gps-self-checkin?bookingId=${bookingId}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                clickGpsSelfCheckIn(response.data, getAmplitudeRootPath());
            }
        });
    };

    // 셀프체크인 가능 여부 체크 > 불가 시 Alert 노출
    const showSelfCheckInCheckAlert = () => {
        fetchWrapper
            .get(
                `/booking/checkin/possibility?bookingId=${bookingId}&checkinType=CHECKIN&userLat=${latitude}&userLon=${longitude}`
                // `/booking/checkin/possibility?bookingId=${roundData?.bookingId}&checkinType=CHECKIN`
            )
            .then((response) => {
                if (response?.code === SUCCESS_CODE || response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                    if (round.golfClubId === '2c5c8fb8-b336-11ed-ae09-040300000000') {
                        // 용원cc 골프백 네임 입력 skip
                        dispatch(smartRoundActions.setGolfBag({golfBag: ''}));
                        setTagNameCheck(false);
                        selfCheckInProcess();
                    } else {
                        setTagNameCheck(true);
                    }
                } else {
                    // 셀프체크인 Alert Open
                    setAlertOpen(true);
                    setAlertContents(response?.message);

                    fetchWrapper
                        .get(`/amplitude-events/reservation-complete?bookingId=${bookingId}`, null)
                        .then((response) => {
                            if (response.code === SUCCESS_CODE) {
                                failSelfCheckinGps(response.data, getAmplitudeRootPath());
                            }
                        });
                }
            });
    };

    // 위치정보 동의 권한 확인 > 설정 페이지로 넘겨주는 콜백
    const goSettingLocation = useCallback(async () => {
        const gpsRequest = JSON.parse(localStorageUtil.get('LOCATION'))?.gpsrequest || '';
        if (gpsRequest === 1) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    gpsreturn: 'Rerequest'
                })
            );
        } else if (gpsRequest === 2) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    gpsreturn: 'goSetting'
                })
            );
        }
    });

    useEffect(() => {
        // WEB에서 테스트를 위한 좌표 세팅
        if (!window.ReactNativeWebView) {
            if (latitude === '') {
                navigator.geolocation.getCurrentPosition((position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                });
            }
        }

        const listener1 = document.addEventListener('message', (e) => {
            if (typeof e.data == 'object' || !e.data.lat) {
                return;
            }
            setLatitude(JSON.parse(e.data)?.lat);
            setLongitude(JSON.parse(e.data)?.lon);
        });

        const listener2 = window.addEventListener('message', (e) => {
            if (typeof e.data == 'object' || !e.data.lat) {
                return;
            }
            setLatitude(JSON.parse(e.data)?.lat);
            setLongitude(JSON.parse(e.data)?.lon);
        });

        return () => {
            document.removeEventListener('message', listener1);
            window.removeEventListener('message', listener2);
        };
    }, []);

    const lottieStyle = {
        height: 60,
        width: 60
    };

    return (
        <div className="check-in-content">
            <div className="check-in-card check-before" onClick={tagNameCheckOpen}>
                <div className="check-in-touch">
                    <Lottie animationData={LottieData} style={lottieStyle} className="check-in-animation" />
                    <div className="checkInImg">
                        <CheckTouch />
                    </div>
                </div>
                <p className="infoTxt">
                    간편하게 체크인하려면 <br />
                    <span>여기를 누르세요!</span>
                </p>
            </div>
            {smartRound?.isQrCheckin && (
                <button className="qr-card" onClick={onClickQr}>
                    <div className="qr-card-contents">
                        <i className="icon-qr" />
                        <p>QR 체크인</p>
                    </div>
                    <i className="icon-click" />
                </button>
            )}

            <QrCheckInModal
                drawerOpen={qrModalOpen}
                drawerClose={() => {
                    setQrModalOpen(false);
                }}
            />

            {/* 셀프 체크인 Alert */}
            <DefaultAlert
                open={alertOpen}
                handleClose={() => {
                    setAlertOpen(false);
                }}
                contents={alertContents}
                //contents={"골프장 반경 1km 이내일 때 진행 가능합니다. 골프장에 도착할 때 즈음 다시 시도해보세요."}
            />

            {/* 골프백 테그네임 Alert, Modal */}
            <TagNameModal
                alertOpen={tagNameCheck}
                setAlertOpen={setTagNameCheck}
                alertClose={() => setTagNameCheck(true)}
                selfCheckInProcess={selfCheckInProcess}
            />

            {/* 위치정보 동의 권한 확인 Alert */}
            {(alertData?.open && alertData?.id === 'checkInLocation' && (
                <CommonAlert id={alertData?.id} successCallBack={goSettingLocation} />
            )) ||
                ''}

            <CheckInFailModal
                open={checkInFailModalOpen}
                onClose={() => {
                    setCheckInFailModalOpen(false);
                    setTagNameCheck(false);
                }}
            />
            <CheckInNameModal
                open={checkInNameModalOpen}
                onClose={() => {
                    setCheckInNameModalOpen(false);
                    setTagNameCheck(false);
                }}
                selfCheckInFailProcess={selfCheckInFailProcess}
                selfCheckInProcessOnly={selfCheckInProcessOnly}
            />
        </div>
    );
};

export default CheckInBofore;
