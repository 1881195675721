import React, {useEffect, useState} from 'react';
import Button from '../../button/Button';
import Header from '../../Header';
import TitleArea from '../../common/TitleArea';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import '../../membership/membership.scss';
import {Link} from 'react-router-dom';
import DefaultAlert from '../../alert/DefaultAlert';
import * as Yup from 'yup';
import {SUCCESS_CODE} from '../../../common/constants';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {useForm} from 'react-hook-form';
import {fetchWrapper, history} from '../../../_helpers';
import {useSelector} from 'react-redux';

function ModifyPw({title}) {
    //디바이스 높이값 체크
    const member = useSelector((x) => x?.users?.users);

    const {height} = useWindowDimensions();

    //하단 '다음'버튼
    const [activeButton, setActiveButton] = useState(false);
    const nextPage = (data) => {
        if (data.length) {
            return false;
        }
        fetchWrapper
            .post(`/member/check`, data)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    history.navigate('/mypage/modify-pw', {
                        state: {
                            userMail: member?.email
                        }
                    });
                } else {
                    setError('비밀번호를 확인해주세요.');
                }
            })
            .catch((err) => alert(err));
    };

    //비밀번호 변경 실패 Alert
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [addBtnClass, setAddBtnClass] = useState(null); // 삭제 버튼 클래스 Add, Remove 제어
    const [deleteBtnShow, setDeleteBtnShow] = useState(''); // 삭제 버튼 Show, Hide 제어

    useEffect(() => {
        // 인풋에 들어오는 내용이 0보다 크면
        if (deleteBtnShow.length > 0) {
            // btnDelete Class 추가
            setAddBtnClass('btnDelete');
        } else {
            setAddBtnClass(null);
        }
    }, [deleteBtnShow]);

    const onChangePassword = (e) => {
        setError('');
        setPassword(e.target.value);
        if (e.target.value.length) setActiveButton(true);
        else setActiveButton(false);
        // 비밀번호 삭제 버튼 제어
        setDeleteBtnShow(e.target.value);
    };

    const clearUserPassword = () => {
        setPassword('');
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string().required('비밀번호를 입력하세요.')
    });

    const formOptions = {
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit',
        reValidateMode: 'onSubmit'
    };

    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="membership">
                    <div className="inner">
                        <form>
                            <TitleArea pageTitle={title} />
                            <div className="fieldAreaWrap">
                                {/* 에러 메시지 출력시 fieldArea, textField, infoMsg에 클래스 error 추가. */}

                                <div className="row fieldArea">
                                    <div className="passwordArea">
                                        <input
                                            type={passwordShow ? 'text' : 'password'}
                                            {...register('password')}
                                            className={`textField form-control ${
                                                errors.password ? 'is-invalid error' : ''
                                            }`}
                                            placeholder="현재 비밀번호 입력"
                                            value={password}
                                            onChange={onChangePassword}
                                            // onClick={addDeleteBtnClass}
                                        />
                                        <div className="btnArea">
                                            <button
                                                type="button"
                                                className={addBtnClass}
                                                onClick={clearUserPassword}
                                            ></button>
                                            <button
                                                type="button"
                                                className={passwordShow ? 'btnHidePw' : 'btnShowPw'}
                                                onClick={() => {
                                                    setPasswordShow(!passwordShow);
                                                }}
                                            >
                                                비밀번호 감추기
                                            </button>
                                        </div>
                                    </div>
                                    {(errors.password || error) && (
                                        <p className="invalid-feedback infoMsg error">
                                            {errors.password?.message || error}
                                        </p>
                                    )}
                                </div>
                                {/* <div className="linkArea">
                                    <Link to="/member/find-pw">
                                        비밀번호를 잊으셨나요?
                                    </Link>
                                </div> */}
                            </div>
                            <div className="btnFixedBottom">
                                {' '}
                                {/* 버튼이 키보드 위에 고정되어야 하는 경우 btnFixedBottom에 클래스 "fixed" 추가 */}
                                <div className="inner">
                                    <Button
                                        disabled={isSubmitting}
                                        background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                        onClick={handleSubmit(nextPage)}
                                    >
                                        {isSubmitting && (
                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                        )}
                                        다음
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* 비밀번호 변경 실패 Alert */}
            <DefaultAlert
                open={open}
                handleClose={handleClose}
                title={'비밀번호 변경 실패'}
                contents={'현재 비밀번호가 일치하지 않습니다.'}
            />
        </>
    );
}

export default ModifyPw;
