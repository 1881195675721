import React, {useEffect} from 'react';
import Avatar from '../../common/Avatar';
import {ReactComponent as IconCart} from '../../../assets/images/reservation/icon_cart.svg';
import {ReactComponent as IconCaddy} from '../../../assets/images/reservation/icon_caddy.svg';
import {appUtils} from '../../../common/utils';
// import {viewSelfCheckIn} from '../../../_helpers/amplitude/events/view.events';

function CheckInAfter({user, cartList, caddieList, golfBagList, checkInData, host}) {
    useEffect(() => {
        // viewSelfCheckIn();
    }, []);

    return (
        <div className="readyCheckInArea">
            <div className="profileArea">
                <div className="txtArea">
                    <p className="name">
                        {user?.name}({user?.gender === 'MALE' ? '남' : '여'})
                    </p>
                    <span>
                        골프백 네임 : &nbsp;
                        {golfBagList?.length
                            ? golfBagList.map((data) => {
                                  return <i key={data?.memberId}>{data?.tagName ? data?.tagName : user?.name}</i>;
                              })
                            : ''}
                    </span>
                </div>
                <Avatar
                    data={{host: host}}
                    editable={host}
                    src={user?.profileImageURL}
                    name={user?.name}
                    golagaMember={user?.golagaMember}
                    gender={user?.gender}
                />
            </div>
            <div className="roundInfo">
                {appUtils.dateFormatTypeFull(checkInData?.roundAt)} | {checkInData?.golfClubName} {checkInData?.course}
            </div>
            <div className="infoWrap">
                <div className="lockerInfo">
                    <b className="title">락카키 정보</b>
                    <div className="infoArea">
                        <strong className="num">{user?.lockerNo}</strong>
                        {/*<span>({user?.gender === "MALE" ? "남" : "여"})</span>*/}
                    </div>
                </div>
                {/* 카트, 캐디 */}
                {cartList && caddieList ? (
                    <div className="helperInfo">
                        <div className="infoArea">
                            <>
                                <dl className="cart">
                                    <dt>• 카트</dt>
                                    <dd>
                                        {cartList
                                            ? cartList.map((data, idx) => {
                                                  return <span key={idx}>{data?.cartNo}</span>;
                                              })
                                            : ''}
                                    </dd>
                                </dl>
                                <dl className="caddy">
                                    <dt>• 캐디</dt>
                                    <dd>
                                        {caddieList
                                            ? caddieList.map((data, idx) => {
                                                  return <span key={idx}>{data?.caddieName}</span>;
                                              })
                                            : ''}
                                    </dd>
                                </dl>
                            </>
                        </div>
                    </div>
                ) : (
                    <div className="helperInfo">
                        <div className="infoArea">
                            <>
                                <dl className="cart">
                                    <dd>
                                        <span>배정된 카트 및 캐디</span>
                                    </dd>
                                    <dt>정보가 없습니다.</dt>
                                </dl>
                            </>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(CheckInAfter);
