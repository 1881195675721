import React from 'react';
import SearchingItem from './SearchingItem';

function SearchingList({searchingList, searchContent, onClickGolfClubDetail}) {
    return (
        <div className="inner">
            <ul className="searchingList">
                {searchingList.map((searchingItem, idx) => (
                    <SearchingItem
                        key={idx}
                        searchingItem={searchingItem}
                        searchContent={searchContent}
                        onClickGolfClubDetail={onClickGolfClubDetail}
                    />
                ))}
            </ul>
        </div>
    );
}

export default SearchingList;
