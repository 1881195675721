import React, {useCallback, useEffect, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

/* 공통 Slide SnackBar Component*/
/* DirectionSnackBar 컴포넌트 속성 사용법
 - direction :  ( up , down )
   up : 위에서 아래로 Snackbar 내려옴 , down : 반대로 내려옴
 - position : ( top , bottom )
   top : 위쪽 포지션에 위치 , bottom : 아래쪽 포지션에 위치
 - open : open 여부 ( true , false )
 - msg : 메세지
 - duration : 알림창 시간 ( 1s = 1000 ),
 - setOpen : setOpen 함수를 넘겨주어야 닫힘
 * */

export default function DirectionSnackbar({direction, position, open, msg, duration, setOpen, isNavigation}) {
    /*const [open, setOpen] = React.useState(false);*/
    const [transition, _setTransition] = useState(undefined);

    // const handleClick = (Transition) => () => {
    //     setTransition(() => Transition);
    // };

    const handleClose = useCallback(
        (_event, reason) => {
            if (reason === 'clickaway') return;

            setOpen({
                open: false,
                msg: ''
            });
        },
        [setOpen]
    );

    const styleTop = {
        width: '100%',
        top: '0 !important',
        left: '0 !important'
    };

    const styleBottom = {
        width: '100%',
        bottom: isNavigation === true ? '8% !important' : '0 !important',
        left: '0 !important'
    };

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                handleClose();
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [open, duration, handleClose]);

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            anchorOrigin={{vertical: position, horizontal: 'left'}}
            TransitionComponent={direction === 'up' ? TransitionUp : TransitionDown}
            message={msg}
            key={transition ? transition.name : ''}
            //top 일때 left important를 추가하면 내용이안나옴
            sx={position === 'top' ? styleTop : styleBottom}
            className="defaultSnackBar"
            autoHideDuration={duration}
            // onClick={onSnackbarClick}
        />
    );
}

function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
}

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}
