export const btn = {
    /* 공통 버튼 */
    gohome_btn: '홈으로 돌아가기',
    cancel_history_btn: '취소내역 확인',
    re_cancel_btn: '다시 취소하기',
    companion_invite_btn: '동반자 초대하기',
    payment_btn: '결제하기',
    re_payment_btn: '다시 결제하기',
    booking_list_confirm: '예약내역 확인',

    /* 예약하기 모달 버튼*/
    booking_btn: '예약하기',
    booking_homepage_btn: '골프장 홈페이지 예약 바로가기',
    no_btn: '아니오',
    booking_cancel_btn: '예약취소 요청',
    booking_onsite_btn: '현장결제 예약하기'
};
