import React, {Fragment, forwardRef, useCallback, useContext, useEffect, useState} from 'react';
// import {appUtils} from '../../../common/utils';
// import TeaTimeDrawer from '../drawer/TeaTimeDrawer';
// import LikeButton from '../../button/LikeButton';
import Icon from '../../icons/Icon';
import {fetchWrapper, history} from '../../../_helpers';
import Loading from '../../../common/loading/Loading';
// import {throttle, debounce} from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';

import TeaTimeReservationDrawer from '../drawer/TeaTimeReservationDrawer';
import useCollapse from 'react-collapsed';
import TeeTimeHorizonList from '../OutdoorDetail/TeeTimeHorizonList';
// import {SUCCESS_CODE} from '../../../common/constants';
import {FieldMainContext} from '../../../_store/context/FieldMainContext';
import {FieldMainContext_searchResult} from '../../../_store/context/FieldMainContext_SearchResult';
import {FieldMainContext_myPage} from '../../../_store/context/FieldMainContext_myPage';
// import GolfClubListNotice from '../../common/GolfClubListNotice';
import BusinessInformation from 'components/common/BusinessInformation/BusinessInformation';
import WebAccessInfoPaymentModal from 'components/modal/WebAccessInfoPaymentModal';
import Banner from 'components/common/Banner/Banner';
import promotion_gift_titlelist_01 from '../../../assets/images/promotion/promotion_gift_titlelist_01.png';
import promotion_gift_titlelist_02 from '../../../assets/images/promotion/promotion_gift_titlelist_02.png';
import AppBoardDetail from 'components/mypage/app-board/AppBoardDetail';
import {Dialog} from '@mui/material';
import {SUCCESS_CODE, YN} from 'common/constants';
import {useSelector} from 'react-redux';
import {clickTaxonomy} from '_helpers/amplitude/taxonomy/click-event.taxonomy';
import {clickBannerPromotion} from '_helpers/amplitude/events/click.event';
import {appUtils, localStorageUtil} from 'common/utils';

function Collapse(props) {
    const collapseProps = useCollapse(props);
    return props.children(collapseProps);
}

let selectTeeTimeSheet = [];
let selectTeeTimeDataPayload = [];

const GolfClubList = forwardRef(
    (
        {
            bookingData,
            golfClubData,
            searchDate,
            contextFlag,
            setPage,
            pullToGolfListRefresh,
            hasNext,
            isLoading,
            onClickSelfCheckInBtn,
            setIsSnackBarOpen,
            deleteInterestItem,
            isMyPage,
            setGolfClubList
        },
        ref
    ) => {
        const {region, teeTime, playerCnt, holeCnt, greenFee, paymentType} = useContext(
            contextFlag === 'main'
                ? FieldMainContext
                : contextFlag === 'search-result'
                ? FieldMainContext_searchResult
                : FieldMainContext_myPage
        );

        const [copyGolfClubData, setCopyGolfClubData] = useState([]);

        // 티타임 정보 Layer
        const [teaTimeOpen, setTeaTimeOpen] = useState(false);
        // 클릭한 리스트 골프 클럽 아이디
        const [golfClubId, setGolfClubId] = useState(null);
        // 프로그래스 링
        const [isRefreshLoading, setIsRefreshLoading] = useState(false);
        // 사용자 터치 시작 지점
        const [pStart, setPStart] = useState({x: 0, y: 0});

        // const [isNoticeOpen, setIsNoticeOpen] = useState(true);

        const [selectTimeSheet, setSelectTimeSheet] = useState([]);

        const [paymentModalOpen, setPaymentModalOpen] = useState(false);

        // promotion 정보 여부
        const {displayYn, activeYn} = useSelector((state) => state?.promotion);
        const promotionYn = displayYn === YN.Y && activeYn === YN.Y;

        //배너별 타이틀
        const bannerTitle = [
            {
                mainTitle: '골라가로 셀프체크인하고',
                subTitle: '스카티카메론 받자!',
                keyword: '셀프체크인',
                titleType: 'emphasis'
            },
            {
                titleType: 'weight-regular',
                keyword: '경품 이벤트',
                mainTitle: '# 셀프체크인 경품 이벤트',
                subTitle: '# 스카티카메론'
            }
        ];

        // 배너정보
        const [bannerNoticeInfo, setBannerNoticeInfo] = useState({
            bannerId: '',
            golfClubId: ''
        });

        // 배너클릭시 이벤트 공지사항
        const [bannerNoticeModalOpen, setBannerNoticeModalOpen] = useState(false);

        // 배너이벤트 공지사항 닫기
        const handleBannerNoticeModalClose = () => {
            setBannerNoticeModalOpen(false);
        };

        // 배너클릭 시 공지사항 타이틀에 9월, 경품 이라는 키워드 존재여부 확인
        // FIX : 9월 골라가 경품이벤트 안내
        const containsKeywords = (title) => title.includes('9월 골라가 경품이벤트 안내');

        // 배너이벤트 공지사항 열기
        const handleBannerNoticeModalOpen = async (click_banner_promotion_type = '') => {
            const noticeListResponse = await getNoticeList();
            // 공지사항 정보가 없을때
            if (!noticeListResponse || noticeListResponse?.lists.length < 1) return;
            // 배너를 클릭했을때 셀프체크인 프로모션 공지가 아닌 경우
            noticeListResponse.lists.map((list) => {
                if (!containsKeywords(list.title)) return;
                setBannerNoticeInfo({bannerId: list.id, golfClubId: noticeListResponse?.id});
                setBannerNoticeModalOpen(true);
                if (click_banner_promotion_type) {
                    clickBannerPromotion(click_banner_promotion_type);
                }
                //안드로이드 뒤로가기 버튼 클릭시 모달 닫기 위해 추가
                appUtils.openWebViewModalPostMsg();
                localStorageUtil.remove('joinSelfChInPromotionEvent');
            });
        };

        const selfChInPromotionEvent = localStorageUtil.get('joinSelfChInPromotionEvent');
        useEffect(() => {
            if (selfChInPromotionEvent) {
                handleBannerNoticeModalOpen();
            }
        }, [selfChInPromotionEvent]);

        // 공지사항 조회
        const getNoticeList = async () => {
            return await fetchWrapper
                .get(`/notice/list?page=${0}&size=${10}`, null)
                .then((response) => response && response.code === SUCCESS_CODE && response.data)
                .catch((error) => console.error('메인화면 공지사항 조회 에러', error));
        };

        let scrollDebounce = null;

        useEffect(() => {
            const copyGolfClubData = JSON.parse(JSON.stringify(golfClubData));

            setCopyGolfClubData(copyGolfClubData);

            ref.current.style.transform = `translateY(0px)`;
        }, [golfClubData]);

        const onTeaTimeOpen = (id) => {
            /*setTeaTimeOpen(true);*/

            const golfClubList = copyGolfClubData?.map((item) => {
                if (item.id === id) {
                    item['isToggle'] = !item.isToggle;
                }
                return item;
            });

            setCopyGolfClubData(golfClubList);
            setGolfClubList(golfClubList);
        };

        const timeSheetDrawerOpen = (timeSheet, teeTimeDataPayload) => {
            /*console.log('timeSheet', timeSheet);
            console.log('teeTimeDataPayload', teeTimeDataPayload);*/
            selectTeeTimeSheet = timeSheet;
            selectTeeTimeDataPayload = teeTimeDataPayload;

            setTeaTimeOpen(true);
        };

        const onClickTeaTimeDetail = (id) => {
            history.navigate(`/field-detail/${id}`, {state: {flag: contextFlag, searchDate: searchDate}});
        };

        const touchStart = (e) => {
            setIsRefreshLoading(false);
            let touch = e.targetTouches[0];
            setPStart({
                x: touch.pageX,
                y: touch.pageY
            });
        };

        const touchMove = (e) => {
            if (e.target.className === 'teeTimeBox' || e.target.className === 'teeTimeBoxSpan') {
                return;
            }

            if (scrollDebounce) {
                clearTimeout(scrollDebounce);
            }

            let distanceY = pStart.y - e.changedTouches[0].pageY;
            let distanceX = pStart.x - e.changedTouches[0].pageX;
            /*   console.log('distanceY???', distanceY, 'ref.current.scrollTop??', ref.current.scrollTop);
            console.log('distanceX???', distanceX);*/
            if (distanceY <= 0 && ref.current.scrollTop === 0) {
                // 위에서 아래로 스크롤 (리프레쉬)
                if (distanceY <= -45) {
                    distanceY = -45;
                }

                if (Math.abs(distanceX) < Math.abs(distanceY)) {
                    ref.current.style.transform = `translateY(${Math.abs(distanceY)}px)`;
                }

                if (distanceY <= -40 && Math.abs(distanceX) < Math.abs(distanceY)) {
                    setIsRefreshLoading(true);
                }
                /* if (distanceY <= -30) {
                    setIsRefreshLoading(true);
                }*/
            } else if (distanceY > 0) {
                scrollDebounce = setTimeout(() => {
                    const ceil = Math.ceil(ref?.current?.scrollHeight - ref?.current?.clientHeight);
                    const scrollTopCeil = Math.ceil(ref?.current?.scrollTop);

                    if (ceil <= scrollTopCeil || ceil - 1 <= scrollTopCeil) {
                        if (hasNext.current) {
                            setPage((pre) => pre + 1);
                        }
                    }
                }, 500);
            }
        };

        const touchEnd = (e) => {
            ref.current.style.transform = `translateY(0px)`;
            setIsRefreshLoading(false);
            if (isRefreshLoading) {
                // 위에서 아래로 스크롤시 손가락 뗄떼 리프레시
                setPage(0);
                pullToGolfListRefresh(searchDate);
            }
        };

        const onClickGolfClubListItem = (e, id) => {
            /* 이벤트 버블링 막기로 수정*/
            // if (e.target.className.includes('selfCheckIn')) {
            // onClickSelfCheckInBtn();
            // } else {
            onClickTeaTimeDetail(id);
            setGolfClubId(id);
            // }
        };

        // const onNoticeClose = () => {
        //     setIsNoticeOpen(false);
        // };

        useEffect(() => {
            function handleEvent(message) {
                let type = JSON.parse(message.data).type;
                if (type === 'webModalClose' && bannerNoticeModalOpen) {
                    handleBannerNoticeModalClose();
                }
            }

            // app => web 으로 이벤트메세지 받아서 정책
            document.addEventListener('message', handleEvent);
            return () => {
                document.removeEventListener('message', handleEvent);
            };
        }, [bannerNoticeModalOpen]);

        return (
            <>
                <div
                    className={'golfClubList'}
                    ref={ref}
                    onTouchStart={touchStart}
                    onTouchMove={touchMove}
                    onTouchEnd={touchEnd}
                >
                    {/* 홈페이지 예약 알림 팝업 계약이 안되서 홈페이지 예약 되는 홈페이지가 없어서 일단 주석처리 2023-07-18 by 오스틴 */}
                    {/* <GolfClubListNotice isNoticeOpen={isNoticeOpen} onNoticeClose={onNoticeClose} /> */}
                    <ul className={`${isLoading && 'loading'}`}>
                        {contextFlag === 'main' && promotionYn && (
                            <>
                                <Banner
                                    type="07"
                                    bannerTitle={bannerTitle[0]}
                                    onClick={() => handleBannerNoticeModalOpen(clickTaxonomy.click_banner_promotion_A)}
                                    src={promotion_gift_titlelist_01}
                                />
                                <div className="golfListGap" />
                            </>
                        )}
                        {isLoading ? (
                            <Loading />
                        ) : golfClubData?.length > 0 ? (
                            golfClubData?.map((list, index) => {
                                const middleBannerPosition = golfClubData.length >= 5 && index === 4;
                                const showMiddleBanner = contextFlag === 'main' && promotionYn;
                                return (
                                    <Fragment key={list.id}>
                                        <TeeTimeHorizonList
                                            list={list}
                                            contextFlag={contextFlag}
                                            onTeaTimeOpen={onTeaTimeOpen}
                                            timeSheetDrawerOpen={timeSheetDrawerOpen}
                                            searchDate={searchDate}
                                            setIsSnackBarOpen={setIsSnackBarOpen}
                                            onClickGolfClubListItem={onClickGolfClubListItem}
                                            deleteInterestItem={deleteInterestItem}
                                            isMyPage={isMyPage}
                                            setPaymentModalOpen={setPaymentModalOpen}
                                            onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                                        />
                                        {middleBannerPosition && showMiddleBanner && (
                                            <>
                                                <Banner
                                                    layout="image-title"
                                                    type="08"
                                                    bannerTitle={bannerTitle[1]}
                                                    onClick={() =>
                                                        handleBannerNoticeModalOpen(
                                                            clickTaxonomy.click_banner_promotion_B
                                                        )
                                                    }
                                                    src={promotion_gift_titlelist_02}
                                                />
                                                <div className="golfListGap" />
                                            </>
                                        )}
                                    </Fragment>
                                );
                            })
                        ) : (
                            <li className="noDataList golfClubList-nodataList">
                                <i className="icon">
                                    <Icon
                                        name="caution"
                                        viewBox="0 0 60 60"
                                        width="60px"
                                        height="60px"
                                        fill="#F0F1F4"
                                    />
                                </i>
                                {contextFlag.includes('search-result') ? (
                                    <p>검색 결과가 없습니다.</p>
                                ) : contextFlag === 'mypage-favorites' ? (
                                    <>
                                        <p>즐겨찾는 골프장이 없습니다.</p>
                                        <span>
                                            즐겨찾는 골프장을 저장하고
                                            <br />
                                            티타임을 빠르게 예약해보세요.
                                        </span>
                                    </>
                                ) : (
                                    <p>모든 티타임 예약이 완료되었습니다!</p>
                                )}
                            </li>
                        )}

                        {hasNext.current && contextFlag === 'main' && (
                            <CircularProgress
                                color="success"
                                size={20}
                                sx={{position: 'relative', left: '50%', marginLeft: '-15px', marginTop: '10px'}}
                                style={{marginBottom: 10}}
                            />
                        )}
                    </ul>
                    <BusinessInformation />
                </div>

                {/* 플로팅 버튼
                {bookingData?.length > 0 || (
                    <FloatingButton2 scrollTarget={scrollRef} />
                )}
                티타임 정보 Layer
                <TeaTimeDrawer
                    teaTimeOpen={teaTimeOpen}
                    setTeaTimeOpen={setTeaTimeOpen}
                    golfClubId={golfClubId}
                    searchDate={searchDate}
                    contextFlag={contextFlag}
                />*/}

                <TeaTimeReservationDrawer
                    TeaTimeReservationDrawerOpen={teaTimeOpen}
                    setTeaTimeOpen={setTeaTimeOpen}
                    selectTeeTimeSheet={selectTeeTimeSheet}
                    selectTeeTimeDataPayload={selectTeeTimeDataPayload}
                ></TeaTimeReservationDrawer>
                <WebAccessInfoPaymentModal
                    paymentModalOpen={paymentModalOpen}
                    setPaymentModalOpen={setPaymentModalOpen}
                />

                {/* 최상위 배너 클릭시 이벤트 풀모달 호출 */}
                <Dialog
                    fullScreen
                    open={bannerNoticeModalOpen}
                    onClose={handleBannerNoticeModalClose}
                    className="fullPageModal"
                >
                    <div className="layerPop">
                        <div className="layerHeader">
                            <div className="inner">
                                <button type="button" className="btnClose" onClick={handleBannerNoticeModalClose}>
                                    닫기
                                </button>
                            </div>
                        </div>
                        <div className="layerContents">
                            <AppBoardDetail
                                boardId={bannerNoticeInfo?.bannerId}
                                golfClubId={bannerNoticeInfo?.golfClubId}
                            />
                        </div>
                    </div>
                </Dialog>
            </>
        );
    }
);

export default GolfClubList;
