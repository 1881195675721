import React, {useCallback, useEffect, useState} from 'react';
import HorizontalSlider from './HorizontalSlider';
import CalenderDrawer from './CalenderDrawer';
import {fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import moment from 'moment/moment';
import {appUtils} from '../../../common/utils';

function DatePicker({selectDate, setSelectDate, isOutdoor}) {
    const [selectMonth, setSelectMonth] = useState(null);
    const [holidayDate, setHolidayDate] = useState([]);

    // 날짜 선택 Drawer
    const [calenderOpen, setCalenderOpen] = useState(false);

    const onCalenderOpen = () => {
        setCalenderOpen(true);

        appUtils.openWebViewModalPostMsg();
    };

    //공휴일 데이터
    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment().add(2, 'M').format('YYYY-MM-DD');
    const getHolidayDate = useCallback(() => {
        fetchWrapper.get(`/holidays?endDate=${endDate}&startDate=${startDate}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setHolidayDate(response?.data || null);
            }
        });
    }, []);

    useEffect(() => {
        getHolidayDate();
    }, []);

    return (
        <>
            <div className={`datePickerWrap ${isOutdoor && 'datepicker-outdoor-bg'} `} id="datePickerWrap">
                <button type="button" className="btnDate" onClick={onCalenderOpen}>
                    {selectMonth}
                </button>
                <HorizontalSlider
                    setSelectMonth={setSelectMonth}
                    selectDate={selectDate}
                    setSelectDate={setSelectDate}
                    holidayDate={holidayDate}
                    startDate={startDate}
                    endDate={endDate}
                />
            </div>

            {/* 날짜 선택 Layer */}
            <CalenderDrawer
                calenderOpen={calenderOpen}
                setCalenderOpen={setCalenderOpen}
                selectDate={selectDate}
                setSelectDate={setSelectDate}
                holidayDate={holidayDate}
                startDate={startDate}
                endDate={endDate}
            />
        </>
    );
}

export default DatePicker;
