import {sendAmplitudeEvent} from '../amplitude.helper';
import {etcTaxonomy} from '../taxonomy/etc-event.taxonomy';
import {locationTaxonomy} from '../taxonomy/location.taxonomy';

/**
 * 하단 스마트 라운드 버튼 클릭시 가이드 화면 진입
 * @param {preview} 가이드 화면 진입 여부(y,n)
 * */
export const smartroundPreview = (preview) => {
    sendAmplitudeEvent(etcTaxonomy.smartround_preview, {preview: preview});
};

/**
 * 실시간 검색어 입력
 * @param {keyword} 검색어
 * */
export const searchKeyword = (keyword) => {
    sendAmplitudeEvent(etcTaxonomy.search_keyword, {keyword: keyword});
};

/**
 * 주소 설정
 * @param {address} 행정구역 앞 2단계 주소 (ex.서울시 용산구)
 * @param {type} 주소의 타입 (ex. 현재위치, 집, 회사, 출발위치)
 * */
export const setAddress = (address, type) => {
    sendAmplitudeEvent(etcTaxonomy.set_address, {set_address_city: address, type: type});
};

/**
 *  add-favorite-cc 즐겨찾기 추가
 *  @param {*} view_cc_detail과 동일
 *  @param {string} location
 * */

export const addFavoriteCc = (data, path) => {
    let location = '';
    if (path === '/main') {
        location = locationTaxonomy.home;
    } else if (path === '/search-result') {
        location = locationTaxonomy.search;
    } else if (path.includes('/field-detail')) {
        location = locationTaxonomy.cc_detail;
    } else {
        location = path;
    }

    const eventData = {
        location: location,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(etcTaxonomy.add_favorite_cc, eventData);
};

/**
 *  delete-favorite-cc 즐겨찾기 삭제
 *  @param {*} view_cc_detail과 동일
 *  @param {string} location
 * */
export const deleteFavoriteCc = (data, path) => {
    let location = '';
    if (path === '/main') {
        location = locationTaxonomy.home;
    } else if (path === '/search-result') {
        location = locationTaxonomy.search;
    } else if (path.includes('/field-detail')) {
        location = locationTaxonomy.cc_detail;
    } else {
        location = path;
    }

    const eventData = {
        location: location,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(etcTaxonomy.delete_favorite_cc, eventData);
};

// /**
//  *  modal_check_reservation
//  *  @param {*} data (view_reservation 과 동일)
//  *  @param {integer} people
//  *  @param {string} root
//  * */

// export const modalCheckReservation = (data, people, root) => {
//     const eventData = {
//         /* view_cc_detail */
//         root: root,
//         cc_name: data?.cc_name,
//         address: data?.address,
//         partner_cc_yn: data?.partner_cc_yn,
//         cms_type: data?.cms_type,
//         cc_sort: data?.cc_sort,
//         hole_count: data?.hole_count,
//         cc_id: data?.cc_id,

//         /* view_reservation */
//         conditions: data?.conditions,
//         caddytype: data?.caddytype,
//         signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
//         min_customer: data?.min_customer,
//         reservation_date: data?.reservation_date,
//         teetime: data?.teetime,
//         course_name: data?.course_name,
//         hole_info: data?.hole_info,
//         checkin_condition: data?.checkin_condition,
//         available_cancel_date_left: data?.available_cancel_date_left,
//         green_fee: data?.green_fee,
//         pay_condition: data?.pay_condition,
//         people: people,

//         day_type: data?.day_type,
//         buffer_period: data?.buffer_period,
//         day_of_the_week: data?.day_of_the_week
//     };

//     sendAmplitudeEvent(etcTaxonomy.modal_check_reservation, eventData);
// };

/**
 *  fail_self_checkin_gps 셀프 체크인 실패시
 *  @param {*} data reservation_complete와 동일
 *  @param {string} root
 * */
export const failSelfCheckinGps = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people
    };

    sendAmplitudeEvent(etcTaxonomy.fail_self_checkin_gps, eventData);
};

/**
 *  complete_gps_self_checkin 셀프 체크인 성공 시
 *  @param {*} data reservation_complete와 동일
 *  @param {string} root
 *
 *  현재 사용하지 않음. completeSelfCheckin에서 QR과 같이 관리
 * */
export const completeGpsSelfCheckin = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people
    };

    sendAmplitudeEvent(etcTaxonomy.complete_gps_self_checkin, eventData);
};

/**
 *  cancel_reservation 예약 취소 / 요청
 *  @param {*} data cancel_reservatiion
 *  @param {string} root
 * */

export const cancelReservation = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        /* cancel_reservation */
        reason_cancel: data?.reason_cancel,
        etc_reason: data?.etc_reason,
        penalty_period: data?.penalty_period === true ? 'y' : 'n',
        count_companion: data?.count_companion
    };

    sendAmplitudeEvent(etcTaxonomy.cacnel_reservation, eventData);
};

/**
 *  mobile_num_change_success - 번호변경 성공
 *
 * */
export const mobileNumChangeSuccess = () => {
    sendAmplitudeEvent(etcTaxonomy.mobile_num_change_success);
};

/**
 *  complete_self_checkin 셀프 체크인 성공 시
 *  @param {*} data reservation_complete와 동일
 *  @param {string} root
 * */
export const completeSelfCheckin = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        checkin_type: data?.checkin_type
    };

    sendAmplitudeEvent(etcTaxonomy.complete_self_checkin, eventData);
};

/** ====================선결제 관련 추가 ================= */

/**
 *  reservation_complete - 예약 완료 페이지
 *  @param {*} data modal_check_reservation과 동일 =>  click_reservation_btn과 동일로 변경
 *  @param {string} root
 * */

export const reservationComplete = (data, root) => {
    const startPoint = sessionStorage.getItem('reservation_start_point');
    const eventData = {
        /* view_cc_detail */
        root: startPoint ? startPoint : root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,

        /* click_reservation_btn */
        people: data?.people,
        total_prepayment_amount: data?.total_prepayment_amount,
        total_green_fee: data?.total_green_fee,
        cart_fee: data?.cart_fee,
        total_fieldpayment_amount: data?.total_fieldpayment_amount,
        caddie_fee: data?.caddie_fee,
        payment_method: data?.payment_method,

        day_type: data?.day_type,
        buffer_period: data?.buffer_period,
        day_of_the_week: data?.day_of_the_week
    };

    sendAmplitudeEvent(etcTaxonomy.reservation_complete, eventData);
};
