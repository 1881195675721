import React from 'react';
import Spinner from './Spinner';

function Loading({txt, spinnerSize}) {
    return (
        <div className="loadingArea">
            <Spinner />
            <strong className="txt">{txt ? txt : '로딩중입니다.'}</strong>
        </div>
    );
}

export default Loading;
