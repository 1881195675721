import { Navigate, useLocation } from "react-router-dom";
import React from "react";

function RouteIf({ children, keyName }) {
    const location = useLocation();

    if (!location.state?.hasOwnProperty(keyName)) {
        return <Navigate to={"/error"} />;
    }

    return children;
}

export { RouteIf };