import React from "react";
import TitleArea from "../../common/TitleArea";
import Header from "components/Header";
import license from "./licenses.json";
import OpenLicenseCard from "./OpenLicenseCard";

function OpenLicense({ title }) {
    return (
        <>
            <Header close={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="agreeCon">
                          <OpenLicenseCard />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OpenLicense;
