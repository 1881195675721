import React, {useEffect, useState} from 'react';
import Icon from '../../icons/Icon';
import {useDispatch, useSelector} from 'react-redux';
import {history} from '_helpers';
import SearchMapDrawer from 'components/reservation/drawer/SearchMapDrawer';
import {smartRoundActions} from '_store/smartRound.slice';
import {localStorageUtil} from 'common/utils';
import HeaderTooltip from 'components/tooltip/HeaderTooltip';
import {useLocation} from 'react-router-dom';

const SmartRoundHeader = (props) => {
    // Header 스크롤시 border 생기게
    const {} = props;
    const color = props.color ? props.color : 'black';
    const location = useLocation();
    const from = useSelector((x) => x?.smartRound.from);
    const golfClubId = useSelector((x) => x?.smartRound.golfClubId);
    const bookingId = useSelector((x) => x?.smartRound.bookingId);
    const round = useSelector((x) => x?.smartRound.round);
    const smartRound = useSelector((x) => x?.smartRound.smartRound);
    const timer = useSelector((x) => x?.smartRound.timer);
    const dispatch = useDispatch();
    const [mapDrawerOpen, setMapDrawerOpen] = useState(false);
    const noticeTooltipHide = localStorageUtil.get('noticeTooltipHide');

    const onClickExit = () => {
        if (timer !== null) {
            clearInterval(timer);
        }
        history.navigate(from);
    };

    const onClickMap = () => {
        setMapDrawerOpen(true);
    };

    const onClickNoti = () => {
        if (timer !== null) {
            clearInterval(timer);
            dispatch(smartRoundActions.setTimer({timer: null}));
        }
        let path = `${location.pathname}?golfClubId=${golfClubId}`;
        if (bookingId) path = `${path}&bookingId=${bookingId}`;
        dispatch(smartRoundActions.setNoticeFrom({noticeFrom: path}));
        localStorageUtil.set('noticeTooltipHide', true);
        history.navigate(`/club-board/${golfClubId}`);
    };

    useEffect(() => {
        return () => {
            dispatch(smartRoundActions.initState());
        };
    }, []);

    return (
        <>
            <div id="smart-round-header" className={color}>
                <div className="inner header-wrap">
                    <div className="left-con">
                        <button className="btn-exit" onClick={onClickExit}>
                            <Icon
                                name="icon-smart-round-exit"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill={color}
                            />
                        </button>
                    </div>
                    <div className="right-con">
                        <button className="btn-map" onClick={onClickMap}>
                            <Icon name="icon-smart-round-map" width="24" height="24" viewBox="0 0 24 24" fill={color} />
                        </button>
                        {!noticeTooltipHide && (
                            <HeaderTooltip
                                noticeAlert={`${
                                    round ? round.golfClubShortName : smartRound?.golfClubName
                                }의 공지사항을 확인해보세요!`}
                            />
                        )}
                        <button className="btn-noti" onClick={onClickNoti}>
                            <Icon
                                name="icon-smart-round-noti"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill={color}
                            />
                        </button>
                    </div>
                </div>
            </div>
            <SearchMapDrawer
                mapDrawerOpen={mapDrawerOpen}
                setMapDrawerOpen={setMapDrawerOpen}
                detailData={{
                    latitude: smartRound?.latitude,
                    longitude: smartRound?.longitude,
                    golfClubName: round ? round.golfClubShortName : smartRound?.golfClubName
                }}
            />
        </>
    );
};

export default SmartRoundHeader;
