import moment from 'moment';

export function isSameDate(firstDate, secondDate) {
    return moment(firstDate, 'DD/MM/YYYY').diff(moment(secondDate, 'DD/MM/YYYY'), 'days', false) === 0;
}

export function isDisabled(minDate, currentDate, maxDate) {
    let min = moment(moment(minDate).format('DD/MM/YYYY'), 'DD/MM/YYYY');
    let max = moment(moment(maxDate).format('DD/MM/YYYY'), 'DD/MM/YYYY');

    let current = moment(moment(currentDate).format('DD/MM/YYYY'), 'DD/MM/YYYY');

    return !(min <= current && current <= max);
}

//휴일인지 판별하는 함수
export function isHollyDay(currentValue) {
    let curVal = moment(currentValue).format('MM-DD');

    //공휴일 지정 api일지 수동 입력일지는 고민..
    const holiyDay = ['05-01', '05-05', '06-06'];

    let isHoliyDay = false;
    holiyDay.filter((item, index) => {
        if (item === curVal) {
            isHoliyDay = true;
        }
    });

    return isHoliyDay;
}
