import React from "react";

function IdentificationSKT() {
    return (
        <>
            <section>
                <h2>&lt;SK텔레콤 귀중&gt;</h2>
                <p>
                    본인은 SK텔레콤(주)(이하 ‘회사’라 합니다)가 제공하는
                    본인확인서비스(이하 ‘서비스’라 합니다)를 이용하기 위해,
                    다음과 같이 본인의 개인정보를 회사가 아래 기재된 제3자에게
                    제공하는 것에 동의합니다.
                    <br />
                    <br />
                    1. 개인정보를 제공받는 자<br />
                    <strong className="underLine">
                        - NICE평가정보(주), SCI평가정보(주)
                    </strong>
                    <br />
                    <br />
                    2. 개인정보를 제공받는 자의 개인정보 이용목적
                    <br />
                    <strong className="underLine">
                        - 연계정보(CI)/중복가입확인정보(DI) 생성 및 회사에 제공
                    </strong>
                    <br />
                    <strong className="underLine">
                        - 부정 이용 방지 및 민원 처리
                    </strong>
                    <br />
                    <br />
                    3. 제공하는 개인정보 항목
                    <br />
                    - 회사가 보유하고 있는 이용자의 주민등록번호 및
                    외국인등록번호
                    <br />
                    <br />
                    4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간
                    <br />
                    <strong className="underLine">
                        - 연계정보(CI)/중복가입확인정보(DI) 생성 후 6개월
                    </strong>
                    <br />
                    <br />
                    5. 위 개인정보 제공에 동의하지 않으실 경우, 서비스를 이용할
                    수 없습니다.
                </p>
            </section>
        </>
    );
}

export default IdentificationSKT;
