import React from 'react';
import {history} from '../../_helpers';
import {localStorageUtil} from '../../common/utils';

function Back({backLink}) {
    const goBack = () => {
        history.navigate(-1);
    };
    const handleBack = () => {
        let roundData = localStorageUtil.get('roundData');

        if (null !== roundData && 'undefined' !== roundData) {
            history.navigate(backLink, {
                state: {
                    roundData: JSON.parse(roundData),
                    smartRound: true
                }
            });
        } else {
            history.navigate(backLink);
        }
    };

    return (
        <>
            <button className="btnBack" onClick={backLink ? handleBack : goBack}>
                뒤로가기
            </button>
        </>
    );
}

export default Back;
