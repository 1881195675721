import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CheckInBefore from './CheckInBefore';
import CheckInAfter from './CheckInAfter';
import {appUtils} from 'common/utils';
import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE, YN} from 'common/constants';
import {viewSelfCheckin} from '_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import './self-check-in.scss';
import {promotionActions} from '_store/promotion.slice';
import {clickPromotionComplete} from '_helpers/amplitude/events/click.event';
import PromotionCheckinModal from 'components/Promotion/PromotionCheckinModal/PromotionCheckinModal';

const SelfCheckInV2 = (props) => {
    const bookingId = useSelector((x) => x?.smartRound.bookingId);
    const round = useSelector((x) => x?.smartRound.round);
    const user = useSelector((x) => x?.smartRound.user);
    const checkin = useSelector((x) => x?.smartRound.checkin);
    /* 앰플리튜드 전송을 위해 골프아이디 추가  */
    const golfClubId = useSelector((x) => x?.smartRound.golfClubId);
    const {} = props;

    const {REACT_APP_ASSET_URL} = process.env;
    const defaultUrl = (e) => {
        e.currentTarget.src = REACT_APP_ASSET_URL + '/common/sm_default.png';
    };

    /* 프로모션 관련항목 추가 */
    const dispatch = useDispatch();
    const {activeYn, promotionCheckinModal} = useSelector((x) => x?.promotion);
    const promotionCheckinModalClose = () => {
        // 프로모션 확인 버튼 클릭시 모달을 닫고 앰플리튜드 이벤트 전송
        dispatch(promotionActions.setPromotionCheckinModal(false));
        golfClubId &&
            fetchWrapper
                .get(`/amplitude-events/view-cc-detail?golfClubId=${golfClubId}`, null)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        clickPromotionComplete({...response.data, root: getAmplitudeRootPath()});
                    }
                })
                .catch((error) => console.error('프로모션 이벤트 앰플리튜드 전송 중 에러', error));
    };

    useEffect(() => {
        if (bookingId) {
            fetchWrapper.get(`/amplitude-events/view-self-checkin?bookingId=${bookingId}`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    const _data = response.data;
                    viewSelfCheckin(_data, getAmplitudeRootPath());
                }
            });
        }
    }, [bookingId]);

    useEffect(() => {
        dispatch(promotionActions.getPromotionApplicable());
    }, []);

    return (
        round && (
            <div className="self-check-in-wrapper">
                <div className="content-wrapper">
                    <img src={REACT_APP_ASSET_URL + round.imageUrl} onError={defaultUrl} alt={round.name} />
                    <div className="round-info">
                        <p className="round-info-date">{appUtils.dateFormatTypeDayLong(round?.roundAt)}</p>
                        <div className="round-info-course-time">
                            <h2>
                                {round?.course} 코스 <span /> {appUtils.dateFormatTypeOnlyTime(round?.roundAt)}
                            </h2>
                        </div>
                        <span className="round-info-golfclub-name">{round?.golfClubShortName} </span>
                    </div>
                    {user?.checkinAt || checkin ? <CheckInAfter /> : <CheckInBefore />}
                </div>
                {/* 프로모션이 유효한 기간 체크 , 백그라운드 자체를 딤처리해서 선택못하게 해야하므로 상위에 배치*/}
                {activeYn === YN.Y && (
                    <PromotionCheckinModal open={promotionCheckinModal} onClose={promotionCheckinModalClose} />
                )}
            </div>
        )
    );
};

export default SelfCheckInV2;
