import React from "react";

function IdentificationLGT() {
    return (
        <>
            <section>
                <h2>&lt;LG유플러스 귀중&gt;</h2>
                <p>
                    LG유플러스(주)(이하 ‘회사’)가 휴대폰본인확인서비스(이하
                    ‘서비스’)를 제공하기 위해 고유식별정보를 다음과 같이
                    제3자에게 제공 및 처리 하는 데에 동의합니다.
                    <br />
                    <br />
                    1. 고유식별정보를 제공받는자
                    <br />
                    <strong className="underLine">
                        - NICE평가정보(주), SCI평가정보(주)
                    </strong>
                    <br />
                    <br />
                    2. 고유식별정보를 제공받는자의 목적
                    <br />
                    <strong className="underLine">
                        - 연계정보(CI)와 중복가입확인정보(DI)의 생성 및 ‘회사’
                        제공
                    </strong>
                    <br />
                    <strong className="underLine">
                        - 부정 이용 방지 및 민원 처리
                    </strong>
                    <br />
                    <br />
                    3. 고유식별정보 제공 항목: ‘회사’가 보유하고 있는 고객의
                    주민등록번호와{" "}
                    <strong className="underLine">외국인등록번호</strong>
                    <br />
                    <br />
                    4. 고유식별정보를 제공받는 자의 보유 및 이용기간:
                    <strong className="underLine">
                        연계정보(CI) 및 중복가입확인정보(DI) 생성 후 6개월
                    </strong>
                    <br />
                    <br />
                    5. 상기 고유식별정보 처리에 대한 내용에 동의하지 않으실
                    경우, ‘서비스’를 이용할 수 없습니다.
                    <br />
                    <br />
                    상기와 같이 고유식별정보 이용 및 처리에 동의합니다.
                </p>
            </section>
        </>
    );
}

export default IdentificationLGT;
