import {createSlice} from '@reduxjs/toolkit';

const name = 'joinOnShareLink';
const initialState = {
    shareProp: {
        golfClubId: '',
        shareStatus: ''
    },
    inviteProp: {
        inviteChannel: '',
        bookingId: '',
        bookingMemberId: ''
    }
};

const joinSlice = createSlice({
    name,
    initialState,
    reducers: {
        setProperties: {
            reducer: (state, action) => {
                state.data = action.payload;
            }
        }
    }
});

export const joinActions = {...joinSlice.actions};
export const joinReducer = joinSlice.reducer;
