import React, {useEffect, useState} from 'react';
import useCollapse from 'react-collapsed';
import {GrUp} from 'react-icons/gr';
import AllCheckbox from '../button/AllCheckbox';
import PolicyLayer from './PolicyLayer';
import {appUtils} from '../../common/utils';

function Agreement({setRequireAllCheck, setIsMarketingAgree}) {
    const [isExpanded, setExpanded] = useState(true);
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

    const [checkList, setCheckList] = useState([
        {
            label: '만 14세 이상입니다.',
            require: true,
            checked: false
        },
        {
            label: '서비스 이용약관 동의',
            require: true,
            checked: false,
            type: 'JOIN_SERVICE'
        },
        {
            label: '개인정보 수집 및 이용 동의',
            require: true,
            checked: false,
            type: 'JOIN_PRIVACY'
        },
        {
            label: '위치기반서비스 이용약관 동의',
            require: true,
            checked: false,
            type: 'JOIN_LOCATION'
        },
        // {
        //     label: '개인정보 제3자 제공동의',
        //     require: true,
        //     checked: false,
        //     type: 'JOIN_THRID'
        // },
        {
            label: '마케팅 정보 수신 동의',
            name: 'isMarketingAgree', // 2023.03.28 마케팅정보 값 추가
            require: false,
            checked: false,
            type: 'JOIN_MARKETING'
        }
    ]);
    const [allChecked, setAllChecked] = useState(false);

    // 약관 동의 팝업
    const [agreeLayerTitle, setAgreeLayerTitle] = useState('');
    const [agreeType, setAgreeType] = useState('');

    useEffect(() => {
        if (allChecked) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    }, [allChecked]);
    useEffect(() => {
        isRequiredAllCheck();
    }, [checkList]);

    /**
     * checkList onClick handler
     * @param index
     */
    const onCheckClick = (index) => {
        let checkSet = [...checkList];
        let checkedItem = {
            ...checkList[index],
            checked: !checkList[index].checked
        };
        checkSet[index] = checkedItem;

        setCheckList(checkSet);
        setAllChecked(isAllChecked(checkSet));
    };

    const isAllChecked = (checkSet) => {
        let checkedCnt = checkSet.filter((v) => {
            return v.checked;
        }).length;
        return checkSet.length === checkedCnt;
    };

    const isRequiredAllCheck = () => {
        let requireCnt = checkList.filter((v) => {
            return v.require;
        }).length;
        let requireCheckedCnt = checkList.filter((v) => {
            return v.require && v.checked;
        }).length;
        // 필수항목의 값은 무조건 체크해야하므로, 여기서 마케팅 정보 수신 동의 여부를 동시에 파악
        let marketingAgree = checkList.filter((obj) => obj['name'])[0].checked;
        setIsMarketingAgree(marketingAgree);
        setRequireAllCheck(requireCnt === requireCheckedCnt);
    };
    /**
     * allCheck onClick handler
     * Toggle allCheck and checkList state
     */
    const toggleAllCheck = () => {
        setAllChecked(!allChecked);
        setCheckList((checks) =>
            checks.map((c) => ({
                ...c,
                checked: !allChecked
            }))
        );
    };

    const [agreementOpen, setAgreementOpen] = useState(false);
    const agreementLayerOpen = (e) => {
        setAgreeLayerTitle(e.target.dataset.title);
        setAgreeType(e.target.dataset.type);
        setAgreementOpen(true);
        console.log('layerOpen');
        appUtils.openWebViewModalPostMsg();
    };

    useEffect(() => {
        function handleEvent(message) {
            console.log(message.data);
            let type = JSON.parse(message.data).type;
            console.log('type', type);

            if (type === 'webModalClose') {
                agreementLayerClose();
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);

        return () => document.removeEventListener('message', handleEvent);
    }, [agreementOpen]);

    const agreementLayerClose = () => {
        console.log('layerClose');
        setAgreementOpen(false);
    };

    return (
        <>
            <div className="row fieldArea">
                <div className={`agreeCheckArea ${isExpanded ? 'collapse' : 'expand'}`}>
                    <div className="checkAll">
                        <div className="checkLabel">
                            {/* ----------------ALL CHECK BTN--------------- */}
                            <AllCheckbox allChecked={allChecked} toggleAllCheck={toggleAllCheck}>
                                회원 약관 전체 동의
                            </AllCheckbox>
                            <button
                                type="button"
                                {...getToggleProps({
                                    onClick: () => setExpanded((prevExpanded) => !prevExpanded)
                                })}
                                className="btn_ar"
                            >
                                <GrUp size={'0.9625rem'} />
                            </button>
                        </div>
                    </div>

                    <ul className="agreeCheckList" {...getCollapseProps()}>
                        {/* -----------------CHECK LIST------------------- */}
                        {checkList.map((labelData, idx) => (
                            <li key={idx}>
                                <div className="checkLabel">
                                    <label>
                                        <div className="customCheck">
                                            <input
                                                type="checkbox"
                                                onChange={() => {
                                                    onCheckClick(idx);
                                                }}
                                                checked={checkList[idx].checked}
                                            />
                                            <i className={`icon ${checkList[idx].checked ? 'checked' : ''}`}>
                                                <svg
                                                    width="100%"
                                                    height="100%"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M17 8L11.0525 15L7 10.9168"
                                                        stroke={checkList[idx].checked ? '#3906C3 ' : '#A0A4AF'}
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </i>
                                        </div>
                                        <span className={labelData.require ? '' : 'gray'}>
                                            {labelData.require ? '[필수]' : '[선택]'}
                                        </span>
                                        {' ' + labelData.label}
                                    </label>
                                    {labelData.type && (
                                        <button
                                            type="button"
                                            className="btn_ar"
                                            onClick={agreementLayerOpen}
                                            data-title={labelData.label}
                                            data-type={labelData.type}
                                        >
                                            자세히보기
                                        </button>
                                    )}
                                </div>
                            </li>
                        ))}
                        {/* ------------------------------------------ */}
                    </ul>
                </div>
                {/* <p className="infoMsg error">휴대전화 인증 전체 동의를 해주세요.</p> */}
            </div>

            <PolicyLayer
                open={agreementOpen}
                handleClose={agreementLayerClose}
                title={agreeLayerTitle}
                agreeType={agreeType}
            />
        </>
    );
}

export default Agreement;
