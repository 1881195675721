import React, {useEffect, useState, useRef, useCallback} from 'react';
import {appUtils} from '../../../common/utils';
import {fetchWrapper, history} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import Loading from '../../../common/loading/Loading';
import exclamationIcon from '../../../assets/images/common/exclamation.svg';
import '../reservation.scss';
import Icon from '../../icons/Icon';
import TeaTimeReservationDrawer from '../drawer/TeaTimeReservationDrawer';
import {clickTeetimeItem} from '../../../_helpers/amplitude/events/click.event';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {paymentConstant} from 'common/payment';
import WebAccessInfoPaymentModal from 'components/modal/WebAccessInfoPaymentModal';

const {REACT_APP_INSTALL_INDUCE_ENABLED} = process.env;

let selectTeeTimeSheet = [];
let selectTeeTimeDataPayload = [];

function TeaTime({
    teaTimeCount,
    teaTimeData,
    golfClubId,
    isPartner,
    isAlready,
    setIsAlready,
    golfClubName,
    isLoading,
    teeTime,
    isDrawer,
    flag,
    detailData,
    selectDate,
    bookingUrl
}) {
    const anchorListRef = useRef(null);

    const [teaTimeOpen, setTeaTimeOpen] = useState(false);

    const [paymentModalOpen, setPaymentModalOpen] = useState(false);

    const onClickFieldBooking = (selectItem) => {
        /* 웹 환경에서 선결제, 예약금결제 예약시도 시 앱설치 유도 팝업 노출 */
        if (
            [paymentConstant.paymentType.PREPAY, paymentConstant.paymentType.RESERVE].includes(
                selectItem.paymentType
            ) &&
            Number(REACT_APP_INSTALL_INDUCE_ENABLED) &&
            !window.ReactNativeWebView
        ) {
            setPaymentModalOpen(true);
            return;
        }

        setTeaTimeOpen(true);

        fetchWrapper
            .get(`/amplitude-events/view-reservation?golfClubId=${golfClubId}&timeSheetId=${selectItem?.timeSheetId}`)
            .then((response) => {
                let teetime_range = selectItem?.roundTime.split(':');
                sessionStorage.setItem('reservation_start_point', 'cc_detail');
                if (teetime_range.length > 0)
                    clickTeetimeItem(
                        response?.data,
                        getAmplitudeRootPath(),
                        teetime_range.length > 0 ? Number(teetime_range[0]) : '',
                        'cc_detail'
                    );
            });
        const fullIsoString = new Date(
            appUtils.getCompatibleDate(appUtils.dateFormatTypeDash(selectDate)) + ' ' + selectItem?.roundTime
        );

        const fullIsoArr = appUtils.dateFormatTypeFull(fullIsoString).split(' ');
        let dateStr = fullIsoArr[0];
        let timeStr = fullIsoArr[1];

        const teeTimeDataPayload = {
            golfCLubName: golfClubName,
            golfClubShortName: detailData.golfClubShortName,
            searchDate: selectDate,
            bookingUrl: bookingUrl,
            dateStr: dateStr,
            timeStr: timeStr,
            golfClubId: golfClubId
        };

        selectTeeTimeSheet = selectItem;
        selectTeeTimeDataPayload = teeTimeDataPayload;

        const bookingParams = {
            timeSheetId: selectItem.timeSheetId,
            courseId: selectItem.courseId
        };

        /*history.navigate(`/field-booking/${golfClubId}`, {state: bookingParams});*/
    };

    /*let activeId = 'content0';*/
    const [activeId, setActiveId] = useState(null);

    // const [scrollEvent, setScrollEvent] = useState(false);

    const onScrollEventListerner = () => {
        if (flag === 'field-teaTime') {
            const contentList = [...document.getElementsByClassName('content')];
            //헤더 높이
            let headerHeight = document.getElementById('header')?.offsetHeight;
            //탭 높이
            let tabType01 = document.getElementById('outdoorTabType01')?.offsetHeight;

            let datePickerWrap;
            if (document.getElementById('datePickerWrap') !== null) {
                //날짜 선택 높이
                datePickerWrap = document.getElementById('datePickerWrap')?.offsetHeight;
            }
            //티타임 바 높이
            let teaTimeFilterArea = document.getElementById('teaTimeFilterArea')?.offsetHeight;
            //다 더해
            let offSet =
                Number(headerHeight || 0) +
                Number(tabType01 || 0) +
                Number(datePickerWrap || 0) +
                Number(teaTimeFilterArea || 0);

            contentList?.forEach((tag) => {
                let tagTop = window.scrollY + tag?.getBoundingClientRect().top - offSet;

                if (tagTop >= window.scrollY && tagTop <= window.scrollY + 20) {
                    const id = tag?.getAttribute('id');
                    setActiveId(id);
                }
            });
        } else {
            const teaTimeListWrap = [...document.getElementsByClassName('teaTimeList')];

            if (teaTimeListWrap !== undefined) {
                teaTimeListWrap?.forEach((tag) => {
                    // const clientRect = tag.getBoundingClientRect().top;

                    const drawerTitle = document.getElementsByClassName('drawerTitle')[0]?.offsetHeight;
                    const teaTimeFilter = document.getElementsByClassName('teaTimeFilter')[0]?.offsetHeight;

                    let offSet = Number(drawerTitle || 0) + Number(teaTimeFilter || 0) + 40;
                    let tagTop = window.scrollY + tag?.getBoundingClientRect().top - offSet;

                    if (tagTop >= window.scrollY && tagTop <= window.scrollY + 40) {
                        const id = tag?.getAttribute('id');
                        setActiveId(id);
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (teaTimeData !== undefined) {
            initSelectTeaTime();
            const teaTimeListWrap = document.getElementsByClassName('teaTimeListWrap')[0];

            window.addEventListener('scroll', onScrollEventListerner);
            if (teaTimeListWrap !== undefined) {
                teaTimeListWrap?.addEventListener('scroll', onScrollEventListerner);
            }
            return () => {
                window.removeEventListener('scroll', onScrollEventListerner);
                if (teaTimeListWrap !== undefined) {
                    teaTimeListWrap?.removeEventListener('scroll', onScrollEventListerner);
                }
            };
        }
    }, [teaTimeData, teeTime]);

    const initSelectTeaTime = useCallback(() => {
        if (anchorListRef.current !== null && teeTime) {
            const copyTeeTime = JSON.parse(JSON.stringify(teeTime));
            const sortTeeTime = copyTeeTime?.sort((a, b) => a.code - b.code);

            //티타임 선택 안했을 때, 티타임 선택 했을 때

            if (sortTeeTime.length > 0) {
                let teeTimeCode = sortTeeTime[0]?.code;

                document.querySelectorAll('a[href^="#"]')?.forEach((anchor) => {
                    let hrefStr = anchor?.getAttribute('href');
                    let lastIdx = hrefStr.charAt(hrefStr.length - 1);

                    if (lastIdx === teeTimeCode) {
                        anchor.click();
                        setActiveId('content' + lastIdx);
                    }
                });
            } else {
                let anchorOne = document.querySelectorAll('a[href^="#"]')[0];
                if (anchorOne !== undefined) {
                    let hrefStr = anchorOne?.getAttribute('href');
                    let lastIdx = hrefStr.charAt(hrefStr.length - 1);
                    setActiveId('content' + lastIdx);
                    anchorOne?.click();
                }
            }
        } else {
            let anchorOne = document.querySelectorAll('a[href^="#"]')[0];

            if (anchorOne !== undefined) {
                let hrefStr = anchorOne?.getAttribute('href');
                let lastIdx = hrefStr.charAt(hrefStr.length - 1);
                setActiveId('content' + lastIdx);
            }
        }
    }, [teaTimeData, teeTime]);

    document.querySelectorAll('a[href^="#"]')?.forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            if (flag === 'field-teaTime') {
                let hrefStr = anchor.getAttribute('href');
                let lastIdx = hrefStr.charAt(hrefStr.length - 1);

                const element = document.getElementById('content' + lastIdx);
                //헤더 높이
                let headerHeight = document.getElementById('header')?.offsetHeight;
                //탭 높이
                let tabType01 = document.getElementById('outdoorTabType01')?.offsetHeight;

                let datePickerWrap;
                if (document.getElementById('datePickerWrap') !== null) {
                    //날짜 선택 높이료
                    datePickerWrap = document.getElementById('datePickerWrap')?.offsetHeight;
                }
                //티타임 바 높이
                let teaTimeFilterArea = document.getElementById('teaTimeFilterArea')?.offsetHeight;
                //다 더해
                let offSet =
                    Number(headerHeight || 0) +
                    Number(tabType01 || 0) +
                    Number(datePickerWrap || 0) +
                    Number(teaTimeFilterArea || 0);

                //움직인 스크롤 정도
                const bodyRect = document.body.getBoundingClientRect().top;
                const elementRect = element?.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offSet;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
                /*anchor.click();*/
                setActiveId('content' + lastIdx);
            } else {
                let hrefStr = anchor.getAttribute('href');
                let lastIdx = hrefStr.charAt(hrefStr.length - 1);
                /* console.log(hrefStr);
                    console.log('lastIdx', lastIdx);*/

                document.querySelector(this.getAttribute('href'))?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
                /*anchor.click();*/
                setActiveId('content' + lastIdx);
            }

            return () => anchor.removeEventListener('click', {});
        });
    });

    /*useIntersectionObservation(setActiveId, teaTimeData);*/

    const applyPartner = () => {
        if (isAlready) {
            return;
        }

        fetchWrapper.post(`/golfClub/partner/request/${golfClubId}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                //console.log(response);
                if (response?.data.success) {
                    setIsAlready(response?.data.success);
                }
            }
        });
    };

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {isPartner && (
                        <div
                            className={`teaTimeFilterArea ${isDrawer ? 'teaTimeFilterAreaDrawer' : ''}`}
                            id="teaTimeFilterArea"
                        >
                            <div className="teaTimeFilter">
                                <div>
                                    예약 가능 티타임{' '}
                                    <span className={`num ${teaTimeData ? 'on' : ''}`}>
                                        {teaTimeData ? teaTimeCount : '0'}
                                    </span>
                                </div>

                                {teaTimeData && (
                                    <ul className="anchorList" ref={anchorListRef}>
                                        {teaTimeData?.map((data, idx) => {
                                            return (
                                                <li
                                                    key={idx}
                                                    className={
                                                        activeId === `content${data?.teetimeCode}` ? 'active' : ''
                                                    }
                                                >
                                                    <a href={`#content${data?.teetimeCode}`}>
                                                        {data?.teetimeName.slice(0, 2)}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="teaTimeWrap">
                        <div className="teaTimeListWrap">
                            {!isPartner ? (
                                <>
                                    <div className="nodata noPartner">
                                        <div className="clubNameTitleNotice">
                                            '{golfClubName}' 은(는) <br />
                                            아직 제휴 되지 않은 골프장 입니다.
                                        </div>
                                        <button
                                            className={`btnPrimary partnerApplyBtn ${isAlready ? 'isAlreadyBtn' : ''}`}
                                            onClick={applyPartner}
                                            disabled={isAlready}
                                        >
                                            {isAlready ? '제휴 요청 완료' : '제휴 요청 하기'}
                                        </button>
                                        <div className="subTitleNotice">
                                            요청이 많은 순으로 제휴를 진행하고 있습니다.
                                        </div>
                                    </div>

                                    <div className="advSection">
                                        <img src="/images/banner/Banner_02.png" alt="디테일한 골프장 검색필터" />
                                    </div>
                                </>
                            ) : teaTimeCount > 0 && teaTimeData ? (
                                teaTimeData?.map((data, idx) => {
                                    return (
                                        <div
                                            className="teaTimeList content"
                                            key={idx}
                                            id={`content${data?.teetimeCode}`}
                                        >
                                            <div className="tt">{data?.teetimeName}</div>
                                            <ul>
                                                {data?.timeSheets?.map((item, idx) => {
                                                    return (
                                                        <li key={idx} onClick={() => onClickFieldBooking(item)}>
                                                            <div className="teaTimeDetail">
                                                                <div>
                                                                    <strong className="time">{item?.roundTime}</strong>
                                                                    <span className="courseInfo">
                                                                        {item?.courseName} · {item?.adminHole}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <div className="price">
                                                                        {item?.greenFeePerPerson === '0' ||
                                                                        item.greenFeePerPerson === 0
                                                                            ? '그린피 골프장 문의'
                                                                            : appUtils.numberFormat(
                                                                                  item?.greenFeePerPerson
                                                                              ) + '원'}
                                                                    </div>
                                                                    <div className="isHomepageOpen">
                                                                        {item?.isHomepageOpen === true ? (
                                                                            <i className="teeTimeBoxIcon-golf-course red">
                                                                                <Icon
                                                                                    name="icon-home"
                                                                                    width="12"
                                                                                    height="12"
                                                                                    viewBox="0 0 12 12"
                                                                                ></Icon>
                                                                                {bookingInfoProperty.booking_homepage}
                                                                            </i>
                                                                        ) : (
                                                                            ['현장결제', ''].includes(
                                                                                item?.paymentTypeName
                                                                            ) === false && (
                                                                                <i className="teeTimeBoxIcon-advance-payment">
                                                                                    <Icon
                                                                                        name="icon-golf-advancepayment"
                                                                                        width="12"
                                                                                        height="12"
                                                                                        viewBox="0 0 12 12"
                                                                                    ></Icon>
                                                                                    {item?.paymentTypeName}
                                                                                </i>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {item?.timeSheetTags && (
                                                                <div className="condition">
                                                                    {item?.timeSheetTags?.map((list, idx) => {
                                                                        if (idx <= 3) {
                                                                            return (
                                                                                <span key={idx}>{list?.tagName}</span>
                                                                            );
                                                                        }
                                                                    })}
                                                                </div>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    );
                                })
                            ) : (
                                /* <div className="teaTimeNoDataArea">
                                    <div className="teaTimeNoDataImg">
                                        <img src={exclamationIcon} />
                                    </div>
                                    <div className="teaTimeNoData">모든 티타임 예약이 완료되었습니다!</div>
                                </div>*/

                                <div className="noDataList teaTimeNoDataArea">
                                    <i className="icon">
                                        <Icon
                                            name="noDataList"
                                            width="60px"
                                            height="60px"
                                            viewBox="0 0 60 60"
                                            fill="#F0F1F4"
                                        />
                                    </i>
                                    <p className="bigTxt">모든 티타임 예약이 완료되었습니다!</p>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
            <TeaTimeReservationDrawer
                TeaTimeReservationDrawerOpen={teaTimeOpen}
                setTeaTimeOpen={setTeaTimeOpen}
                selectTeeTimeSheet={selectTeeTimeSheet}
                selectTeeTimeDataPayload={selectTeeTimeDataPayload}
            ></TeaTimeReservationDrawer>
            <WebAccessInfoPaymentModal paymentModalOpen={paymentModalOpen} setPaymentModalOpen={setPaymentModalOpen} />
        </>
    );
}

export default TeaTime;
