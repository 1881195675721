import React from 'react';
import Icon from '../../icons/Icon';

function HistoryItem({historyItem, onRemove, onClickHistoryItem}) {
    return (
        <li className="historyItem">
            <div className="historyWord" onClick={() => onClickHistoryItem(historyItem.searchContent)}>
                {historyItem.searchContent}
            </div>
            <button className="historyDeleteBtn" type="button" onClick={() => onRemove(historyItem.id)}>
                <Icon name="close" fill="#777F8A" className="rightArrow" width="9" height="9" />
            </button>
        </li>
    );
}

export default HistoryItem;
