import React, {useEffect} from 'react';
import Drawer from '@mui/material/Drawer';
import getMyReservImg from '../../assets/images/reservation/get_my_reserve_modal_last.png';
import Icon from '../icons/Icon';
import {viewHomeBottomModal} from '_helpers/amplitude/events/view.events';

function GetMyReservDrawer(props) {
    const {open, handleNoticeClose, onClickViewDetail, setPopUpHide} = props;

    useEffect(() => {
        if (open) {
            viewHomeBottomModal('other_booking');
        }
    }, [open]);

    return (
        <Drawer anchor="bottom" open={open} onClose={handleNoticeClose} className="mainAdDrawer">
            <div className="drawerContents" onClick={onClickViewDetail}>
                <img src={getMyReservImg} alt="내 예약정보 불러오기" className="getMyReservImg" />
            </div>
            <div className="btnArea">
                <button type="button" className="btn" onClick={setPopUpHide}>
                    오늘 다시 안 보기
                </button>
                <div className="btn" onClick={handleNoticeClose}>
                    <button type="button" className="close">
                        닫기
                    </button>
                    <i>
                        <Icon name="close" fill="#fff" width="10" height="10" />
                    </i>
                </div>
            </div>
        </Drawer>

        // <Drawer anchor="bottom" open={open} onClose={handleNoticeClose} className="basicDrawer mainDrawer">
        //     <div className="drawer-content">
        //         <div className="drawer-wrapper">
        //             <div className="btnArea">
        //                 <button type="button" className="btn" onClick={setPopUpHide}>
        //                     오늘 다시 안 보기
        //                 </button>
        //                 <div className="btn" onClick={handleNoticeClose}>
        //                     <button type="button" className="close">
        //                         닫기
        //                     </button>
        //                     <i>
        //                         <Icon name="close" fill="#fff" width="10" height="10" />
        //                     </i>
        //                 </div>
        //             </div>
        //             <div className="innerArea" onClick={onClickViewDetail}>
        //                 <div className="txt-wrapper">
        //                     <div className="tit-exp">
        //                         홈페이지, 전화, 타 App에서
        //                         <br />
        //                         예약한 티타임 셀프체크인도
        //                     </div>
        //                     <div className="tit">골라가 하나로!</div>
        //                     <div className="btn">자세히 보기 &#62;</div>
        //                 </div>
        //                 <div className="img-wrapper">
        //                     <img src={getMyReservImg} alt="내 예약정보 불러오기" className="getMyReservImg" />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </Drawer>
    );
}

export default GetMyReservDrawer;
