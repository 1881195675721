import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Scrollbar} from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import SkeletonElement from '../../skeleton/SkeletonElement';

function OutdoorDetailVisualSlider({data}) {
    return (
        <>
            {data?.length > 0 ? (
                <Swiper
                    loop={true}
                    speed={600}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                    scrollbar={true}
                    modules={[Autoplay, Scrollbar]}
                >
                    {data?.map((data, idx) => {
                        return (
                            <SwiperSlide className="list" key={idx}>
                                <div
                                    className={`visualArea ${data.originName === '기본이미지' ? 'noImg' : ''}`}
                                    style={{backgroundImage: `url(${data.pathUrl})`}}
                                ></div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            ) : (
                <SkeletonElement type="visualSlide" variant="rectangular" />
            )}
        </>
    );
}

export default OutdoorDetailVisualSlider;
