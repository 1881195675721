export const etcTaxonomy = {
    smartround_preview: 'smartround_preview',
    search_keyword: 'search_keyword',
    set_address: 'set_address',
    add_favorite_cc: 'add_favorite_cc',
    delete_favorite_cc: 'delete_favorite_cc',
    fail_self_checkin_gps: 'fail_self_checkin_gps',
    complete_gps_self_checkin: 'complete_gps_self_checkin',
    cacnel_reservation: 'cancel_reservation',
    mobile_num_change_success: 'mobile_num_change_success',
    complete_self_checkin: 'complete_self_checkin',

    //    ================결제 Amplitude 추가 항목======================

    /**
     * @TODO : 삭제 필요한 항목 -> click_reservation_btn 으로 대체
     */
    // - 삭제 필요한 항목 (페이로드 추가)
    modal_check_reservation: 'modal_check_reservation',

    // - 수정 필요항목
    reservation_complete: 'reservation_complete'

    //    ================결제 Amplitude 추가 항목======================
};
