import React from "react";
import ThridPrivacySKT from "./ThridPrivacySKT";
import ThridPrivacyLGT from "./ThridPrivacyLGT";
import ThridPrivacyKTF from "./ThridPrivacyKTF";

function ThridPrivacy({ telecomCode }) {
    const type = telecomCode;

    if (type === "SKT" || type === "SKM") {
        return <ThridPrivacySKT />;
    } else if (type === "LGT" || type === "LGM") {
        return <ThridPrivacyLGT />;
    } else if (type === "KTF" || type === "KTM") {
        return <ThridPrivacyKTF />;
    }
}

export default ThridPrivacy;
