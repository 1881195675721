import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Link} from 'react-router-dom';
import NoImg from '../../common/NoImg';

function NearbyClubSlider({nearbyClubData, onClickNearBy, onClickSelfCheckInBtn}) {
    return (
        <>
            <Swiper spaceBetween={6} slidesPerView={'auto'}>
                {nearbyClubData?.map((data, idx) => {
                    return (
                        <SwiperSlide className="list" key={idx}>
                            <div className="nearbyInfoArea" onClick={() => onClickNearBy(data?.id)}>
                                <div className="thumbArea">
                                    {data?.stillImageUrl ? (
                                        <img src={`${data?.stillImageUrl}`} alt={data?.golfClubName} />
                                    ) : (
                                        <NoImg logoWidth="40px" />
                                    )}
                                </div>
                                <div className="prdInfo">
                                    <strong className="tit">{data?.golfClubShortName}</strong>
                                    <span className="location">
                                        {data?.address} · {data?.distance} km
                                    </span>
                                </div>
                            </div>

                            {data?.isSelfCheckInPossible && (
                                <button
                                    type="button"
                                    className="statusIcon selfCheckIn"
                                    onClick={onClickSelfCheckInBtn}
                                >
                                    셀프체크인
                                </button>
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
}

export default NearbyClubSlider;
