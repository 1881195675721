import React from 'react';
import Button from '../../components/button/Button';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {history} from '../../_helpers';
import Icon from '../icons/Icon';

function ErrorLogin() {
    //디바이스 높이값 체크
    const {height} = useWindowDimensions();
    //하단 버튼
    const nextPage = () => {
        history.navigate('/main');
    };

    return (
        <div id="contents">
            <div className="completeArea" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <div className="completeMsg">
                        <i className="icon">
                            <Icon name="exclamationMark" width="60" height="60" viewBox="0 0 60 60" />
                        </i>
                        <strong className="tt">{'로그인에 문제가 발생하였습니다'}</strong>
                        <p className="txt">{'잠시 후 다시 시도해주세요'}</p>
                    </div>
                </div>
            </div>
            <div className="btnFixedBottom">
                <div className="inner">
                    <Button type="button" onClick={nextPage}>
                        다시 시도하기
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ErrorLogin;
