import React, {useCallback, useEffect, useState} from 'react';
import Header from '../Header';
import TitleArea from '../common/TitleArea';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';

function LinkedGolfClub({title}) {
    const [golfClubPartners, setGolfClubPartners] = useState('');

    const getGolfClubPartners = useCallback(() => {
        fetchWrapper.get(`/golfClub/partners`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setGolfClubPartners(response.data);
            }
        });
    }, []);

    useEffect(() => {
        getGolfClubPartners();
    }, []);

    return (
        <>
            <Header close={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner linkedGolfClub">
                        <TitleArea pageTitle={title} paddingBottm={true} />
                        <ul className="listType08">
                            {golfClubPartners?.length
                                ? golfClubPartners.map((v, idx) => {
                                      return (
                                          <>
                                              <li key={v.id}>
                                                  <div> {v.golfClubShortName}</div>
                                                  <div className="address">{v.address}</div>
                                              </li>
                                          </>
                                      );
                                  })
                                : ''}
                        </ul>
                        <div className="noti">* 제휴 골프장은 앞으로 더 늘어날 예정입니다</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LinkedGolfClub;
