import React from "react";
import MyListItem from "./MyListItem";

function MyLogoutSection({ menu }) {
    return (
        <>
            {menu?.logout?.flag && <span className="gap"></span>}
            <section>
                <ul className="menuList">
                    {menu?.logout?.logout &&
                        menu?.logout.logout.map((item, idx) => {
                            return <MyListItem item={item} key={idx} />;
                        })}
                </ul>
            </section>
        </>
    );
}

export default MyLogoutSection;
