import React, {useEffect, useRef, useState} from 'react';
import '../../../_view/board/board.scss';
import Board from '../../../_view/board/Board';
import {fetchWrapper, history} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import useIntersectionObserver from '../../../common/observerUtils';
import {localStorageUtil} from '../../../common/utils';
import {viewNotice} from '../../../_helpers/amplitude/events/view.events';

function AppBoard() {
    const [boardDataList, setBoardDataList] = useState([]);
    const [clubName, setClubName] = useState('');
    const [page, setPage] = useState(0);
    const [size, setsize] = useState(10);

    // 인피니티 스크롤
    const [hasNext, setHasNext] = useState(false);
    const ref = useRef();

    //리스트 로딩 스피너
    const [isLoading, setIsLoading] = useState(false);

    const getBoardList = () => {
        setIsLoading(true);
        fetchWrapper.get(`/notice/list?page=${page}&size=${size}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                const data = response?.data;

                setClubName(data?.golfClubName || null);
                setHasNext(data?.hasNext);

                if (hasNext && page > 0) {
                    setBoardDataList([...boardDataList, ...data?.lists] || null);
                } else {
                    setBoardDataList(data?.lists || null);
                }
            }

            setIsLoading(false);
        });
    };

    useEffect(() => {
        getBoardList();
        viewNotice();
    }, [page]);

    // 인피니티 스크롤 커스텀 훅 사용
    const [observe, unobserve] = useIntersectionObserver(() => {
        setPage((prev) => prev + 1);
    });

    useEffect(() => {
        if (hasNext) {
            observe(ref.current);
        } else if (hasNext === false && page > 0) {
            unobserve(ref.current);
        }
    }, [hasNext]);

    return <Board boardDataList={boardDataList} clubName={clubName} ref={ref} isLoading={isLoading} />;
}

export default AppBoard;
