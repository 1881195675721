import React, { useCallback, useEffect, useState } from "react";
import { fetchWrapper } from "../../_helpers";
import { SUCCESS_CODE } from "../../common/constants";
import { appUtils } from "../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { authActions, userActions } from "../../_store";

function AttendeeList() {
    const [booking, setBooking] = useState(null);
    const [playerCount, setPlayerCount] = useState(0);
    const [checkinAtCount, setCheckinAtCount] = useState(0);
    const [isLoad, setLoad] = useState(false);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    const member = useSelector((x) => x?.users?.users);

    const getBookingList = useCallback(() => {
        fetchWrapper.get(`/booking/attendee-list`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setBooking(response?.data?.bookingList || null);
                setPlayerCount(response?.data.playerCount);
                setCheckinAtCount(response?.data.checkinAtCount);
                setLoad(true);
            }
        });
    }, []);

    useEffect(() => {
        getBookingList();
        dispatch(userActions.getAll());
    }, [getBookingList, dispatch]);

    const [scrollActive, setScrollActive] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 44) {
            setScrollActive(true);
        } else {
            setScrollActive(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div id="contents">
                <div className="contentsArea">
                    <div className="inner">
                        <div
                            className={`fixedTtArea ${
                                scrollActive ? "border" : ""
                            }`}
                        >
                            <div className="titleArea">
                                <h1>
                                    참석자 리스트{" "}
                                    {booking == null || booking.length === 0 ? (
                                        isLoad ? (
                                            <span>
                                                금일 예약된 라운드 정보가
                                                없습니다.
                                            </span>
                                        ) : null
                                    ) : (
                                        <span>
                                            (참석자 {checkinAtCount} /{" "}
                                            {playerCount} 명)
                                        </span>
                                    )}
                                </h1>
                                <p>
                                    {member.name} - {member.email}
                                    <button
                                        style={{ float: "right" }}
                                        onClick={logout}
                                    >
                                        LOGOUT
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div className="section">
                            <div className="infoType03">
                                {booking === null || booking.length === 0 ? (
                                    <div className="list nodata">
                                        금일자로 조회된 목록이 없습니다.
                                    </div>
                                ) : (
                                    booking?.map((e, key) =>
                                        e?.bookingMemberList?.map((m, i) => (
                                            <div
                                                className="list"
                                                key={key * 100 + i}
                                            >
                                                <dl>
                                                    <dt>
                                                        <div>
                                                            {m.name} (
                                                            {m.gender === "MALE"
                                                                ? "남"
                                                                : "여"}
                                                            )
                                                            {m.checkinAt && (
                                                                <span className="green">
                                                                    {"  "}참석
                                                                </span>
                                                            )}
                                                            {/* <span className="red">불참</span> */}
                                                        </div>
                                                        <div className="date">
                                                            예약 번호 :{" "}
                                                            {e.bookingNo}
                                                        </div>
                                                    </dt>
                                                    <dd>
                                                        {appUtils.formatPhoneNumber(
                                                            m.phone
                                                        )}
                                                    </dd>
                                                    {/*<dd>*/}
                                                    {/*    골프백 네임 : 홍석천 / 락커키 정보 :*/}
                                                    {/*    056*/}
                                                    {/*</dd>*/}
                                                    {/*<dd>*/}
                                                    {/*    카트 : 032, 033 / 캐디 : 김영희,*/}
                                                    {/*    김지영*/}
                                                    {/*</dd>*/}
                                                    <dd></dd>
                                                    <dd>
                                                        라운드 일시 :{" "}
                                                        {appUtils.dateFormatTypeFull(
                                                            e.roundAt
                                                        )}
                                                    </dd>
                                                    <dd>
                                                        코스명 :{" "}
                                                        {e.golfClubName +
                                                            " - " +
                                                            e.course}
                                                    </dd>
                                                    <dd>
                                                        체크인 일시 :{" "}
                                                        {appUtils.dateFormatTypeFull(
                                                            m.checkinAt
                                                        )}
                                                    </dd>
                                                </dl>
                                            </div>
                                        ))
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AttendeeList;
