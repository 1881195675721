import React, {useCallback, useEffect, useState} from 'react';
import {Backdrop, SpeedDial, SpeedDialAction, SpeedDialIcon} from '@mui/material';
import KakaoMapIcon from '../../assets/images/smart-round/kakaoMap.svg';
import NaverMapIcon from '../../assets/images/smart-round/naverMap.svg';
import TMapIcon from '../../assets/images/smart-round/tMap.svg';
import AppleMapIcon from '../../assets/images/smart-round/AppleMap.svg';
import {installLink} from '../../common/links';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import Icon from '../icons/Icon';
import {appUtils} from '../../common/utils';
import {clickNavigation} from '../../_helpers/amplitude/events/click.event';
import {rootTaxonomy} from '../../_helpers/amplitude/taxonomy/root.taxonomy';
import {mapTaxonomy} from '../../_helpers/amplitude/taxonomy/map.taxonomy';

let actions = [];

const actionsInAos = [
    {icon: <img src={TMapIcon} alt="" />, name: '티맵'},
    {icon: <img src={NaverMapIcon} alt="" />, name: '네이버지도'},
    {icon: <img src={KakaoMapIcon} alt="" />, name: '카카오맵'}
];

const actionsInIos = [
    {icon: <img src={TMapIcon} alt="" />, name: '티맵'},
    {icon: <img src={NaverMapIcon} alt="" />, name: '네이버지도'},
    {icon: <img src={KakaoMapIcon} alt="" />, name: '카카오맵'},
    {icon: <img src={AppleMapIcon} alt="" />, name: '애플지도'}
];

const IconMap = (
    // <div className="btnMapFloating">
    <Icon name="circleMapIcon" width="44" height="44" viewBox="0 0 44 44" />
);
// </div>
const IconMapOpen = (
    // <div className="btnMapFloating">
    <Icon name="circleMapIconOpen" width="44" height="44" viewBox="0 0 44 44" />
);

// </div>

function MapFloatingButton({golfClubId}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        appUtils.openWebViewModalPostMsg();
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [detailData, setDetailData] = useState(null);
    const getDetailData = () => {
        fetchWrapper.get(`/golfClub/detail/${golfClubId}`, null).then((response) => {
            let detailData = null;
            if (response?.code === SUCCESS_CODE) {
                detailData = response?.data;
            }
            setDetailData(detailData);
        });
    };

    // 디바이스 구분
    let _device = '';
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) {
        _device = 'android';
    } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
        _device = 'ios';
    }

    // 디바이스 초기값 세팅
    const [device, setDevice] = useState(_device);

    actions = device === 'ios' ? actionsInIos : actionsInAos;

    // 지도앱 딥링크
    const kakaomap = `kakaomap://look?p=${detailData?.latitude},${detailData?.longitude}`;
    const navermap = `nmap://place?lat=${detailData?.latitude}&lng=${detailData?.longitude}&name=${detailData?.golfClubName}&appname=golaga`;
    const tmap = `https://apis.openapi.sk.com/tmap/app/map?appKey=${process.env.REACT_APP_TMAP_API_KEY}&name=${detailData?.golfClubName}&lon=${detailData?.longitude}&lat=${detailData?.latitude}&url=/field-detail/${golfClubId}`;
    const appleMap = `http://maps.apple.com/?q=${detailData?.latitude},${detailData?.longitude}`;

    // url 인코딩
    // const ENCODE_URL = encodeURI(navermap);

    // 딥링크 이동
    function exeDeepLink(deepLink) {
        window.location.assign(deepLink);
    }

    // 기기별 앱 설치페이지 링크 이동
    function redirectStore(androidLink, iosLink) {
        if (device === 'android') {
            window.location.assign(androidLink);
        } else if (device === 'ios') {
            window.location.assign(iosLink);
        }
    }

    // 앱 오픈 확인
    function checkInstallApp(androidLink, iosLink) {
        let check = null;
        let timer = null;

        function clearTimers() {
            clearInterval(check);
            clearTimeout(timer);
        }

        function isHideWeb() {
            // TODO jenna - 앱 닫혀있을 땐 document.hidden = false 떨어짐
            if (document.webkitHidden || document.hidden) {
                clearTimers();
            }
        }

        check = setInterval(isHideWeb, 200);

        timer = setTimeout(function () {
            redirectStore(androidLink, iosLink);
        }, 1000);
    }

    // 카카오맵 오픈
    const openKakaoMap = () => {
        clickNavigation(rootTaxonomy.smartround, mapTaxonomy.kakao_map);
        exeDeepLink(kakaomap);
        checkInstallApp(installLink.KAKAOMAP_ANDROID, installLink.KAKAOMAP_IOS);
        handleClose();
    };

    // 네이버지도 오픈
    const openNaverMap = () => {
        clickNavigation(rootTaxonomy.smartround, mapTaxonomy.naver_map);
        exeDeepLink(navermap);
        checkInstallApp(installLink.NAVERMAP_ANDROID, installLink.NAVERMAP_IOS);
        handleClose();
    };

    // 티맵 오픈
    const openTmap = () => {
        clickNavigation(rootTaxonomy.smartround, mapTaxonomy.t_map);
        exeDeepLink(tmap);
        checkInstallApp(installLink.TMAP_ANDROID, installLink.TMAP_IOS);
        handleClose();
    };

    // 애플지도 오픈
    const openAppleMap = () => {
        clickNavigation(rootTaxonomy.smartround, mapTaxonomy.apple_map);
        exeDeepLink(appleMap);
        checkInstallApp(installLink.TMAP_ANDROID, installLink.TMAP_IOS);
    };

    useEffect(() => {
        getDetailData();
    }, []);

    return (
        <div className="btnMapFloatingWrap">
            <Backdrop
                open={open}
                onClick={() => {
                    handleClose();
                }}
            />
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{position: 'absolute', bottom: 16, right: 16}}
                // icon={<SpeedDialIcon openIcon={IconMapOpen}/>}
                icon={
                    <SpeedDialIcon
                        icon={IconMap}
                        openIcon={IconMapOpen}
                        onClick={() => {
                            if (open) {
                                handleClose();
                            } else {
                                handleOpen();
                            }
                        }}
                    />
                }
                // onClose={handleClose}
                //onOpen={handleOpen}
                open={open}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => {
                            switch (action.name) {
                                case '카카오맵':
                                    openKakaoMap();
                                    break;
                                case '네이버지도':
                                    openNaverMap();
                                    break;
                                case '티맵':
                                    openTmap();
                                    break;
                                case '애플지도':
                                    openAppleMap();
                                    break;
                                default:
                                    break;
                            }
                        }}
                    />
                ))}
            </SpeedDial>
        </div>
    );
}

export default MapFloatingButton;
