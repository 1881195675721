import React from "react";
import { appUtils } from "../../../common/utils";

function ScheduledDetailPay({ data }) {
    return (
        <>
            <div className="gap"></div>
            <section className="section">
                <div className="infoType01">
                    <div className="totalArea">
                        <dl>
                            <dt>1인 그린피</dt>
                            <dd>{appUtils.numberFormat(data.totalAmount)}원</dd>
                        </dl>
                    </div>
                    <div>
                        <dl className="defaultTxt">
                            <dt>부대비용</dt>
                            <dd>(별도)</dd>
                        </dl>
                        <dl>
                            <dt>카트피</dt>
                            <dd>{appUtils.numberFormat(data.cartAmount)}원</dd>
                        </dl>
                        <dl>
                            <dt>캐디피</dt>
                            <dd>
                                {appUtils.numberFormat(data.caddieAmount)}원
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="tooltipSection">
                    <strong className="tit">비용 정산 및 결제 안내</strong>
                    <ul className="listType02">
                        <li>
                            예상 결제금액은 부대비용을 포함하지 않은 금액입니다.
                        </li>
                        <li>
                            라운드 종료 후 그린피와 부대비용이 함께 정산될
                            예정입니다.
                        </li>
                        <li>
                            총 결제금액은 라운드 홀수 및 골프장 상황에 따라
                            변동될 수 있습니다.
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
}

export default ScheduledDetailPay;
