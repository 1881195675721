// loading.slice.js

import {createSlice} from '@reduxjs/toolkit';

const initialState = {isLoading: false};

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.isLoading = true;
        },
        hideLoading: (state) => {
            state.isLoading = false;
        }
    }
});

export const loadingActions = {...loadingSlice.actions};
export const loadingReducer = loadingSlice.reducer;
