export const paymentConstant = {
    paymentType: {
        ONSITE: 'ONSITE',
        PREPAY: 'PREPAY',
        RESERVE: 'RESERVE'
    },
    // 선결제 옵션 선택 항목
    //10 : 그린피만 결제
    //20 : 그린피 + 카트피 결제
    //30 : 예약금 결제(1인 그린피)
    //40 : 예약금 결제(직접입력)
    prepaymentType: {
        PAYMENT_TYPE_10: '10',
        PAYMENT_TYPE_20: '20',
        PAYMENT_TYPE_30: '30',
        PAYMENT_TYPE_40: '40'
    },
    /**
     * 거래상태코드
     * 0021 : 성공
     * 0031 : 실패
     */
    hectoOutStatusCode: {
        SUCCESS: '0021',
        FAIL: '0031'
    },
    /**
     * 거래결과코드
     * 0000 : 정상거래
     * 0001 : 결제취소
     * 0009 : 결제실패 ( 사용자가 결제하지 않아도 실패케이스임 )
     */
    hectoOutResultCode: {
        SUCCESS: '0000',
        CANCEL: '0001',
        FAIL: '0009'
    }
};
