import React, {useState, useEffect} from 'react';
import Header from '../../../components/Header';

import './fieldbooking.scss';
import adImg from '../../../assets/images/test/IMG_4244 1.jpg';
import {history} from '../../../_helpers';

import {fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';

import FieldBookingCancelPeriod from './FieldBookingCancelPeriod';
import {appUtils} from '../../../common/utils';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import {Link} from 'react-router-dom';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import {reservationComplete} from '../../../_helpers/amplitude/events/etc.event';
import {
    clickHomeAtReservationComplete,
    clickInviteAtReservationComplete
} from '../../../_helpers/amplitude/events/click.event';
import AirbridgeUtil from '../../../common/airbridgeUtil';

function FieldBookingComplete(props) {
    const {height} = useWindowDimensions(); // 디바이스 높이값 체크

    // 선택한 필드 정보
    const [fieldData, setFieldData] = useState(history.location?.state);

    useEffect(() => {
        getBookingComplete();
    }, [fieldData]);

    //취소 가능 기간 경과 여부 (true 경과, false 경과안함)
    const [cancelPeriod, setCancelPeriod] = useState(true);

    const [bookingCompleteData, setBookingCompleteData] = useState([]);

    //총 결제 금액
    const [sumGreentFee, setSumGreenFee] = useState(0);

    const getBookingComplete = async () => {
        fetchWrapper
            .get(
                `/booking/after/detail?golfClubId=${history?.location.state.golfClubId}&bookingId=${history?.location.state.bookingId}`,
                null
            )
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    setSumGreenFee(response?.data.greenFeePerPerson * response?.data.roundPlayer);
                    setBookingCompleteData(response?.data || null);

                    const bookingData = response.data;
                    AirbridgeUtil.event.reservation(
                        bookingData.golfClubId,
                        bookingData.golfClubName,
                        bookingData.greenFeePerPerson,
                        bookingData.roundAt,
                        bookingData.roundPlayer
                    );
                }
            })
            .catch((err) => console.log('err : ' + err));

        //테스트 메세지

        fetchWrapper
            .get(`/amplitude-events/reservation-complete?bookingId=${history?.location.state.bookingId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    reservationComplete(response.data, getAmplitudeRootPath());
                }
            });
    };

    const handleHomeBack = () => {
        fetchWrapper
            .get(`/amplitude-events/reservation-complete?bookingId=${history?.location.state.bookingId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    clickHomeAtReservationComplete(response.data, getAmplitudeRootPath());
                }
            });

        history.navigate('/');
    };

    /*const handleGoMyBooking = () => {
        history.navigate('/booking');
    };*/

    const handleGoMyBooking = () => {
        fetchWrapper
            .get(`/amplitude-events/reservation-complete?bookingId=${history?.location.state.bookingId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    clickInviteAtReservationComplete(response.data, getAmplitudeRootPath());
                }
            });

        history.navigate(`/mypage/booking?bookingId=${history?.location.state.bookingId}&flag=pre`);
    };
    return (
        <>
            <Header pageTitle={props.title} backLink="/" />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div className="fieldBookingComplete">
                    <div className="section">
                        <div className="ttArea">
                            {bookingCompleteData?.isSelfCheckInPossible && (
                                <span className="statusIcon selfCheckIn">셀프체크인</span>
                            )}

                            <strong className="tt">
                                {bookingCompleteData?.golfClubName}
                                <br />
                                클럽 예약이 완료되었습니다
                            </strong>
                        </div>

                        {/* 예약 완료 정보*/}
                        <div className="fieldBookingComContentWrap">
                            <div className="infoType07">
                                <dl>
                                    <dt>예약자 정보</dt>
                                    <dd>
                                        {bookingCompleteData?.bookerName === null
                                            ? '-'
                                            : bookingCompleteData.bookerName}{' '}
                                        ({bookingCompleteData?.bookerPhoneNumber})
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>티타임(일/시)</dt>
                                    <dd>
                                        {bookingCompleteData?.roundAt === null
                                            ? '-'
                                            : appUtils.dateFormatTypeRoundAt(bookingCompleteData?.roundAt)}
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>코스명 / 홀 정보</dt>
                                    <dd>
                                        {bookingCompleteData?.courseName === null
                                            ? '-'
                                            : bookingCompleteData?.courseName}{' '}
                                        ·{' '}
                                        {bookingCompleteData?.hole === '' || bookingCompleteData?.hole === null
                                            ? '-'
                                            : bookingCompleteData?.hole}
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>내장인원</dt>
                                    <dd>{bookingCompleteData?.roundPlayer}명</dd>
                                </dl>
                                {bookingCompleteData?.entryRequirements?.length > 0 && (
                                    <dl>
                                        <dt>내장조건</dt>
                                        <dd>
                                            <ul style={{whiteSpace: 'break-spaces'}}>
                                                {bookingCompleteData?.entryRequirements?.map((item, idx) => {
                                                    return <li key={idx}>{item}</li>;
                                                })}
                                            </ul>
                                        </dd>
                                    </dl>
                                )}
                            </div>
                            <div className="infoType07">
                                <div className={'fieldBookingCompleteBorderLine'}>
                                    <dl>
                                        <dt>결제방법</dt>
                                        <dd>현장결제</dd>
                                    </dl>
                                    <dl>
                                        <dt>총 현장 결제 금액</dt>
                                        <dd>
                                            {sumGreentFee === 0 ? (
                                                <b>그린피 골프장 문의</b>
                                            ) : (
                                                appUtils.numberFormat(sumGreentFee) + '원'
                                            )}
                                        </dd>
                                    </dl>
                                </div>
                            </div>

                            {/* 취소 가능 기한 */}
                            <FieldBookingCancelPeriod
                                cancelPossibility={bookingCompleteData?.isCancelPossibility}
                                cancelPossiblePeriod={appUtils.dateFormatTypeRoundAt(
                                    bookingCompleteData?.cancelPossiblePeriod
                                )}
                            />
                        </div>

                        {/* 광고 넣을 이미지 */}
                        <div className="advSection">
                            <Link to="#">
                                {/*광고 링크 넣어야됨*/}
                                <img src="/images/banner/Banner_03.png" alt="라운드 코스 가이드" />
                            </Link>
                        </div>
                    </div>

                    {/* 하단 버튼*/}
                    <div className={'btnFixedBottom'}>
                        <div className={'inner'}>
                            <button type="button" className="btnDarkGray" onClick={handleHomeBack}>
                                홈으로 돌아가기
                            </button>
                            <button type="button" className="btnPrimary" onClick={handleGoMyBooking}>
                                동반자 초대하기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FieldBookingComplete;
