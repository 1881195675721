import React, {useEffect, useState} from 'react';
import useCollapse from 'react-collapsed';
import {GrUp} from 'react-icons/gr';
import ListCheckbox from '../button/ListCheckbox';
import AllCheckbox from '../button/AllCheckbox';
import DefaultAlert from '../alert/DefaultAlert';
import PolicyLayer from './PolicyLayer';
import {appUtils} from '../../common/utils';

const mvnoLabel = {
    title: '개인정보 제3자 제공 동의',
    type: 'TELECOM_THRID'
};
const labels = [
    {
        title: '휴대전화 인증 서비스 이용약관',
        type: 'TELECOM_SERVICE'
    },
    {
        title: '고유식별정보 처리 동의',
        type: 'TELECOM_ID'
    },
    {
        title: '통신사 이용약관 동의',
        type: 'TELECOM_AGREE'
    },
    {
        title: '개인정보 수집 및 이용동의',
        type: 'TELECOM_PRIVACY'
    }
];

function AuthenticationPhone({
    setAuthAll,
    error,
    setDisabled,
    telecomChecked,
    telecomCode,
    mvnoChecked,
    allChecked,
    setAllChecked
}) {
    const [isExpanded, setExpanded] = useState(true);
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

    const [labelList, setLabelList] = useState(labels);

    // 체크박스 상태관리
    const [checkList, setCheckList] = useState([false, false, false, false]);
    const [telecomChange, setTelecomChange] = useState('');

    // 통신사를 선택 Alert
    const [selTelecomAlert, setTelecomAlert] = useState(false);
    const selTelecomAlertOpen = () => setTelecomAlert(true);
    const selTelecomAlertClose = () => setTelecomAlert(false);

    // 약관 동의 팝업
    const [agreeLayerTitle, setAgreeLayerTitle] = useState('');
    const [agreeType, setAgreeType] = useState('');

    /**
     * 전체동의 여부
     */
    useEffect(() => {
        setAuthAll(allChecked);
        if (allChecked) {
            setExpanded(false);
        } else {
            setExpanded(true);
        }
    }, [allChecked]);

    /**
     * 통신사 변경되면 전체 동의 체크 해제
     */
    useEffect(() => {
        setTelecomChange(telecomCode);
        setAllChecked(false);
        setCheckList((checks) => checks.map((c) => false));
    }, [telecomCode, telecomChange]);

    /**
     * not checked telecom select Alert
     */
    const openAlert = () => {
        selTelecomAlertOpen();
    };

    /**
     * checkList onClick handler
     * @param index
     */
    const onCheckClick = (index) => {
        if (telecomChecked) {
            setCheckList((checks) => checks.map((c, i) => (i === index ? !c : c)));
            setAllChecked(checkList.every((x, i) => (i === index ? !x : x)));
        } else {
            openAlert();
            appUtils.openWebViewModalPostMsg();
        }
    };

    /**
     * allCheck onClick handler
     * Toggle allCheck and checkList state
     */
    const toggleAllCheck = () => {
        if (telecomChecked) {
            setAllChecked(!allChecked);
            setCheckList((checks) => checks.map((c) => !allChecked));
        } else {
            openAlert();
        }
    };

    const [agreementOpen, setAgreementOpen] = useState(false);
    const agreementLayerOpen = (e) => {
        if (telecomChecked) {
            setAgreeLayerTitle(e.target.dataset.title);
            setAgreeType(e.target.dataset.type);
            setAgreementOpen(true);

            appUtils.openWebViewModalPostMsg();
        } else {
            openAlert();
        }
    };

    useEffect(() => {
        function handleEvent(message) {
            console.log(message.data);
            let type = JSON.parse(message.data).type;
            console.log('type', type);

            if (type === 'webModalClose') {
                agreementLayerClose();
                selTelecomAlertClose();
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);

        return () => document.removeEventListener('message', handleEvent);
    }, [agreementOpen]);

    const agreementLayerClose = () => {
        setAgreementOpen(false);
    };

    /**
     * 알뜰 통신사 선택시 필수 체크 항목 추가
     */
    useEffect(() => {
        if (mvnoChecked) {
            setLabelList([...labelList, mvnoLabel]);
            setCheckList([...checkList, false]);
        } else {
            setLabelList(labels);
        }
    }, [mvnoChecked]);

    return (
        <>
            <div className="row fieldArea">
                <div className={`agreeCheckArea ${isExpanded ? 'collapse' : 'expand'}`}>
                    <div className="checkAll">
                        <div className="checkLabel">
                            {/* ---------------ALL CHECK BTN--------------- */}
                            <AllCheckbox
                                allChecked={allChecked}
                                toggleAllCheck={toggleAllCheck}
                                allCheckDisabled={setDisabled}
                            >
                                휴대전화 인증 전체 동의
                            </AllCheckbox>
                            <button
                                type="button"
                                {...getToggleProps({
                                    onClick: () => setExpanded((prevExpanded) => !prevExpanded)
                                })}
                                className="btn_ar"
                            >
                                <GrUp size={'0.9625rem'} />
                            </button>
                        </div>
                    </div>
                    <ul className="agreeCheckList" {...getCollapseProps()}>
                        {/* -----------------CHECK LIST------------------- */}
                        {labelList.map((label, idx) => (
                            <li key={idx}>
                                <div className="checkLabel">
                                    <label>
                                        <ListCheckbox
                                            checkList={checkList}
                                            onCheckClick={onCheckClick}
                                            isRoundBg={false}
                                            idx={idx}
                                            allCheckDisabled={setDisabled}
                                        />
                                        <span>[필수]</span> {label.title}
                                    </label>
                                    <button
                                        type="button"
                                        className="btn_ar"
                                        onClick={agreementLayerOpen}
                                        data-title={label.title}
                                        data-type={label.type}
                                    >
                                        자세히보기
                                    </button>
                                </div>
                            </li>
                        ))}
                        {/* ------------------------------------------ */}
                    </ul>
                </div>
                {!allChecked && <p className="infoMsg error">{error}</p>}
            </div>

            <DefaultAlert
                open={selTelecomAlert}
                handleClose={selTelecomAlertClose}
                title={'휴대전화 인증 안내'}
                contents={'이용중인 통신사를 선택해주세요'}
            />

            <PolicyLayer
                open={agreementOpen}
                handleClose={agreementLayerClose}
                title={agreeLayerTitle}
                telecomCode={telecomCode}
                agreeType={agreeType}
            />
        </>
    );
}

export default AuthenticationPhone;
