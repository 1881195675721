import React from "react";
import Header from "../Header";
import TitleArea from "../common/TitleArea";
import "./alarm.scss";
import AlarmList from "./AlarmList";

function Alarm({ title }) {
    return (
        <>
            <Header back={true} />
            <div id="contents">
                <div className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="fullWidth alarmList">
                            <ul className="boardList">
                                {/* <AlarmList active={true} /> */}
                                {/* 읽지 않은 알림일 경우 */}
                                {/*<AlarmList/>*/}
                                <AlarmList nodata={true} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Alarm;
