import React, {useState} from 'react';
import classNames from 'classnames';
import Icon from '../icons/Icon';
import Drawer from '@mui/material/Drawer';
import {FieldMainContext} from '../reservation/FieldMain';
import FilterSpinner from 'common/loading/FilterSpinner';

function FilterDrawer({
    drawerOpen,
    children,
    title,
    btnStatus,
    gradationBottom,
    filterInitBtn,
    isFilterInitBtnDisable,
    drawerClose,
    //필터 적용하기 클릭여부
    btnApplyClicked,
    //필터 적용하기 버튼
    onBtnApplyClicked,

    filterListCnt,
    isApplyBtnActive,
    filterFlag,
    isFilterLoding
}) {
    // bottom btn 클릭 이벤트
    const [btnInitClicked, setBtnInitClicked] = useState(false);

    // '필터 초기화' 버튼
    const onBtnInitClicked = () => {
        setBtnInitClicked(!btnInitClicked);
        filterInitBtn();
    };

    return (
        <Drawer anchor="bottom" open={drawerOpen} onClose={drawerClose} className="basicDrawer">
            <div className="drawer-content">
                <div className="drawerTitle">
                    <div className="tt">{title}</div>
                    <button type="button" className="btnClose" onClick={drawerClose}>
                        <Icon name="close02" width="24" height="24" viewBox="0 0 24 24" />
                    </button>
                </div>
                <div className="drawerContents">{children}</div>
                <div
                    className={classNames('drawerBtnWrap', {
                        noBtn: gradationBottom
                    })}
                >
                    {btnStatus === 'isDoubleBtn' ? (
                        <>
                            <button
                                type="button"
                                className={classNames('drawerBtn btnFilterInit', {
                                    btnInitClicked: btnInitClicked
                                })}
                                onClick={onBtnInitClicked}
                                disabled={isFilterInitBtnDisable}
                            >
                                필터 초기화
                            </button>
                            <button
                                type="button"
                                className={`drawerBtn btnFilterApply ${isApplyBtnActive === true ? 'on' : ''}`}
                                /*className={classNames('drawerBtn btnFilterApply', {
                                    /!*  btnApplyClicked: btnApplyClicked*!/
                                })}*/
                                onClick={onBtnApplyClicked}
                                /*disabled={isFilterInitBtnDisable}*/
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            >
                                필터 적용하기{isFilterLoding === true ? <FilterSpinner /> : `(${filterListCnt})`}
                            </button>
                        </>
                    ) : btnStatus === 'isOneBtn' ? (
                        <button
                            type="button"
                            className={classNames('drawerBtn btnPrimary', {
                                btnApplyClicked: btnApplyClicked
                            })}
                            onClick={onBtnApplyClicked}
                        >
                            예약하기
                        </button>
                    ) : null}
                </div>
                {gradationBottom && <div className="gradation"></div>}
            </div>
        </Drawer>
    );
}

export default FilterDrawer;
