import React from "react";
import PersonalInfoSKT from "./PersonalInfoSKT";
import PersonalInfoLGT from "./PersonalInfoLGT";
import PersonalInfoKTF from "./PersonalInfoKTF";

function PersonalInfo({ telecomCode }) {
    const PersonalInfo = () => {
        const type = telecomCode;

        if (type === "SKT" || type === "SKM") {
            return <PersonalInfoSKT />;
        } else if (type === "LGT" || type === "LGM") {
            return <PersonalInfoLGT />;
        } else if (type === "KTF" || type === "KTM") {
            return <PersonalInfoKTF />;
        }
    };

    return (
        <>
            {PersonalInfo()}
            <section>
                <h2>&lt;SCI평가정보(주) 귀중&gt;</h2>
                <p>
                    SCI평가정보(주)는 SK텔레콤(주), (주)케이티,
                    (주)엘지유플러스의 업무를 대행하여 휴대폰본인확인서비스를
                    제공함에 있어 본인의 개인정보를 수집·이용하고자 하는
                    경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한
                    법률」및 「신 용정보의 이용 및 보호에 관한 법률」 에 따라
                    본인의 동의를 얻어야 합니다. 이에 본인은 귀사가 아래와 같이
                    본인 의 개인정보를 수집·이용하는데 동의합니다.
                </p>
                <br />
                <h3>[제1조] 개인정보의 수집·이용 목적</h3>
                <p>
                    <strong className="underLine">
                        가. 사업자의 서비스(회원가입, ID/PW찾기, 거래동의 등)
                        이용을 위한 본인확인, 식별확인 업무처리 및 정보제공
                        <br />
                        나. SK텔레콤(주), (주)케이티, (주)엘지유플러스에 이용자
                        정보를 전송하여 본인확인 및 휴대폰 정보의 일치 여부 확인
                        <br />
                        다. 휴대폰 사용자 확인을 위한 SMS(또는 LMS) 인증번호
                        전송
                        <br />
                        라. 부정 이용 방지 및 수사의뢰
                        <br />
                        마. 이용자 본인 요청에 의한 본인확인 이력정보 제공,
                        민원처리, 추후 분쟁조정을 위한 기록보존, 고지사항 전달
                        등<br />
                        바. 부가서비스 제공 및 해제(서비스 가입자에 한함)
                        <br />
                        사. 기타 법률에서 정한 목적
                    </strong>
                </p>
                <br />
                <h3>[제2조] 수집하는 개인정보의 항목</h3>
                <p>
                    <strong className="underLine">
                        가. 이용자가 가입한 이동통신사, 휴대폰번호, 성명, 성별,
                        생년월일, 내/외국인 구분
                        <br />
                        나. 인증처 및 사이트 URL, 인증일시, IP주소 등
                    </strong>
                </p>
                <br />
                <h3>[제3조] 개인정보의 보유·이용 기간</h3>
                <p>
                    <strong className="underLine">
                        개인정보는 개인정보의 수집목적 또는 제공받은 목적이
                        소멸되면 파기됩니다. 단, ‘개인정보보호법’, ‘정보통신망
                        이용 촉진 및 정보보호 등에 관한 법률’, ‘신용정보의 이용
                        및 보호에 관한 법률’등 기타 관련법령의 규정에 의하여
                        법률관계의 확인 등을 이유로 특정한 기간 동안 보유하여야
                        할 필요가 있을 경우에는 아래에 정한 기간 동안
                        보유합니다.
                    </strong>
                    <br />
                    <br />
                    가. 회사 내부 방침에 의한 정보보유 사유 : 본인확인 발생 및
                    차단기록, 휴대폰번호
                    <br />
                    - 보유 이유 : 부정이용 방지 및 민원 처리
                    <br />
                    - 보유 기간 : 1년
                    <br />
                    <br />
                    나. 관계 법령에 의한 정보보유 사유 : 이용자 불만 또는 분쟁
                    처리에 관한 기록
                    <br />
                    - 보유 이유 : 전자상거래 등에서의 소비자 보호에 관한 법률
                    <br />- 보유 기간 : 3년
                </p>
            </section>
        </>
    );
}

export default PersonalInfo;
