import {paymentConstant, validationUtil} from 'common/payment';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {appUtils} from 'common/utils';
import React from 'react';

const PaymentBookingCancelDetailInfo = (props) => {
    const {bookingData, tradeAmountData} = props;

    switch (bookingData?.paymentType) {
        case paymentConstant.paymentType.PREPAY:
        case paymentConstant.paymentType.RESERVE:
            return (
                <div className="payment-info-type">
                    <div className="sub-title-area">
                        <h2>{paymentInfoProperty.payment_infomation}</h2>
                    </div>
                    <dl>
                        <dt>{paymentInfoProperty.payment_date}</dt>
                        <dd>
                            {validationUtil.isNullChk(tradeAmountData?.tradeCreatedAt)
                                ? '-'
                                : appUtils.dateFormatTypeFull(tradeAmountData?.tradeCreatedAt)}
                        </dd>
                    </dl>
                    <dl>
                        <dt>{paymentInfoProperty.payment_person_name}</dt>
                        <dd>
                            {validationUtil.isNullChk(tradeAmountData?.tradeCreatedAt)
                                ? '-'
                                : tradeAmountData?.tradeCustomerName}
                        </dd>
                    </dl>
                    <dl>
                        <dt>{paymentInfoProperty.payment_method}</dt>
                        <dd>
                            {validationUtil.isNullChk(tradeAmountData?.paymentMethod)
                                ? '-'
                                : tradeAmountData?.paymentMethod}
                        </dd>
                    </dl>
                    <dl className="border-bottom black">
                        <dt>{paymentInfoProperty.payment_card_classification}</dt>
                        <dd>{`${
                            validationUtil.isNullChk(tradeAmountData?.cardName) ? '-' : tradeAmountData?.cardName
                        }`}</dd>
                    </dl>
                    <dl className="border-bottom black">
                        <dt>{paymentInfoProperty.payment_card_number}</dt>
                        <dd>{`${
                            validationUtil.isNullChk(tradeAmountData?.cardNumber)
                                ? '-'
                                : validationUtil.cardNumberFormatter(tradeAmountData?.cardNumber)
                        }`}</dd>
                    </dl>
                    <dl className="border-bottom black">
                        {bookingData?.paymentType === paymentConstant.paymentType.PREPAY ? (
                            <dt>{paymentInfoProperty.payment_all_amount}</dt>
                        ) : (
                            <dt>{paymentInfoProperty.payment_deposit}</dt>
                        )}

                        <dd className="total-payment">{`${
                            validationUtil.isNullChk(tradeAmountData?.tradeAmount)
                                ? '-'
                                : appUtils.amountFormat(tradeAmountData?.tradeAmount)
                        }`}</dd>
                    </dl>
                </div>
            );
            break;
        default:
            return <></>;
            break;
    }
};

export default PaymentBookingCancelDetailInfo;
