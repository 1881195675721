import React, {useState, useEffect} from 'react';
import Header from '../../../components/Header';
import './fieldbooking.scss';
import FieldBookingLayer from './FieldBookingLayer';
import {fetchWrapper, history} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';

import {appUtils} from '../../../common/utils';
import DefaultAlert from '../../alert/DefaultAlert';

import InnerPeopleBtn from './InnerPeopleBtn';
import {useParams} from 'react-router-dom';

import moment from 'moment';
import {viewReservation} from '../../../_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';

function FieldBooking(props) {
    const {id} = useParams();

    //예약하기 Layer
    const [fieldBookingOpen, setFieldBookingOpen] = useState(false);
    const onFieldBookingLayerOpen = () => {
        setFieldBookingOpen(true);
    };

    // 선택한 필드 정보
    const [fieldData, setFieldData] = useState(history.location.state);

    //예약하기 데이터
    const [fieldBookingData, setFieldBookingData] = useState([]);

    //취소 가능 기한 알림창
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    //취소 가능 기한 타이틀 문구
    const AFTER_CANCEL_PERIOD_ALERT_TITLE = '취소 불가 티타임';
    const AFTER_CANCEL_PRRIOD_ALERT_CONTENT =
        '해당 티타임은 예약 취소 시 위약금이 발생할 수 있습니다. 예약 시 참고 부탁 드립니다.';

    //내장인원
    const [innerPeople, setInnerPeople] = useState(0);
    //현재 클릭된 내장 인원 버튼
    const [innerPeopleCurBtn, setInnerPeopleCurBtn] = useState(2);
    //내장인원 선택된 명수
    const [innerSelectPeople, setInnerSelectPeople] = useState(0);

    //내장인원 버튼 카운트
    const [innerBtnCnt, setInnerBtnCnt] = useState(0);

    //총 결제 금액
    const [sumGreentFee, setSumGreenFee] = useState(0);

    //const [cancelDate, setCancelDate] = useState([]);

    //내장인원 버튼 선택시 실행되는 함수
    const handleInnerPeopleClick = (e) => {
        setInnerSelectPeople(Number(e.target.value));
        setInnerPeopleCurBtn(Number(e.target.dataset.idx));

        //내장인원 버튼 누를 시 총 결제 금액 계산
        setSumGreenFee(e.target.value * fieldBookingData?.greenFeePerPerson);
    };

    //예약하기 데이터 리스트 현재는 어떤 파라미터를 보내도 데이터가 들어옴
    const getBookingSheet = async () => {
        fetchWrapper
            .get(`/booking/sheet/${fieldData.timeSheetId}?golfClubId=${id}&courseId=${fieldData?.courseId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    // console.log(response.data);
                    // console.log(response?.data.notice);

                    setFieldBookingData(response?.data || null);

                    //최소 인원
                    let minInnerPeople = response?.data.minPlayer;

                    //최대예약인원
                    let minPlayerLimit = response?.data.playerLimit;
                    /*let minPlayerLimit = 3;*/
                    //1. 최소인원 == 최대인원 일때

                    if (minInnerPeople === minPlayerLimit) {
                        setInnerPeople(minInnerPeople);
                    } else if (minInnerPeople > 0 && minInnerPeople <= 4) {
                        setInnerPeople(minInnerPeople);
                    } else {
                        setInnerPeople(4);
                    }
                    /*let roundPlayer = response?.data.roundPlayer;*/

                    //console.log('minInnerPeople : ' + minInnerPeople);
                    //최소 예약 인원 2 = 버튼 3개( 2/ 3/ 4) , 3 = 버튼 2개 (3 / 4) , 4 = 내장인원 선택 비노출
                    //버튼 개수
                    let btnCnt = 0;
                    if (minInnerPeople === 2) {
                        btnCnt = 3;

                        setInnerBtnCnt(btnCnt);
                        setInnerPeopleCurBtn(btnCnt - 1);
                    } else if (minInnerPeople === 3) {
                        btnCnt = 2;
                        setInnerBtnCnt(btnCnt);

                        setInnerPeopleCurBtn(btnCnt - 1);
                    } else if (minInnerPeople === 4) {
                        setInnerSelectPeople(4);
                    } else {
                        btnCnt = 3;

                        setInnerBtnCnt(btnCnt);
                        setInnerPeopleCurBtn(btnCnt - 1);
                    }

                    //초기 최대인원 세팅 기본 4 : max값으로 설정
                    setInnerSelectPeople(4);
                    setSumGreenFee(response?.data.greenFeePerPerson * 4);
                } else {
                    console.log('getBookingSheet fail', response);
                }
            });

        fetchWrapper
            .get(`/amplitude-events/view-reservation?golfClubId=${id}&timeSheetId=${fieldData.timeSheetId}`, null)
            .then((response) => {
                viewReservation(response.data, getAmplitudeRootPath());
            });
    };
    useEffect(() => {
        getBookingSheet();
        /*getCancelDateChk();*/
    }, []);

    useEffect(() => {
        //취소 가능 기한 넘을시 알림창 현시
        if (fieldBookingData?.isCancelPossibility === false) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [fieldBookingData]);

    return (
        <>
            <Header back={true} pageTitle={props.title} />
            <div id="contents">
                <div className="contentsArea fieldBookingContentArea fieldBookingMain">
                    <div className="inner">
                        <div className="fieldBookingInfoTop">
                            <div className="fieldBookingTitleArea">
                                <div className="bookingState">
                                    <b className="statusIcon">현장결제</b>
                                    {fieldBookingData?.isSelfCheckInPossible && (
                                        <span className="statusIcon selfCheckIn">셀프체크인</span>
                                    )}
                                </div>

                                <h2>{fieldBookingData?.golfClubName}</h2>
                            </div>
                            <div className="fieldBookingDetailList">
                                <div className="infoType06">
                                    <dl>
                                        <dt>티타임</dt>
                                        <dd>
                                            {fieldBookingData?.roundAt !== null
                                                ? appUtils.dateFormatTypeFull(fieldBookingData?.roundAt)
                                                : ''}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>코스명</dt>
                                        {/* 코스 명 데이터 미 조회시 - 로 노출*/}
                                        <dd>
                                            {fieldBookingData?.courseName !== null ? fieldBookingData?.courseName : '-'}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>홀정보</dt>
                                        {/* 홀 정보 데이터 미 조회시 - 로 노출*/}
                                        <dd>
                                            {fieldBookingData?.adminHole !== ''
                                                ? `${fieldBookingData?.adminHole}`
                                                : '-'}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        {/* 내장 조건*/}
                        {/* 기획서 2.2v 선택 내장 조건 이 없는 경우 내장조건 영역 비노출*/}
                        {fieldBookingData?.entryRequirements?.length > 0 && (
                            <>
                                <div className="section">
                                    <div className="titleArea02">
                                        <h2>내장조건</h2>
                                    </div>
                                    <div className="fieldBookingInnerCondition">
                                        <ul className="listType06">
                                            {fieldBookingData?.entryRequirements?.map((item, idx) => {
                                                return <li key={idx}>{item}</li>;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        )}

                        {/* 내장 인원 */}
                        {fieldBookingData?.minPlayer === 4 || fieldBookingData?.minPlayer === 0 ? (
                            <></>
                        ) : (
                            <>
                                <div className="section">
                                    <div className="titleArea02">
                                        <h2>내장인원</h2>
                                    </div>
                                    <div className="fieldBookingInnerPeopleBtnWrap">
                                        <InnerPeopleBtn
                                            innerPeopleNum={innerPeople}
                                            innerPeopleCurBtn={innerPeopleCurBtn}
                                            handleInnerPeopleClick={handleInnerPeopleClick}
                                            playerLimit={fieldBookingData?.playerLimit}
                                            innerBtnCnt={innerBtnCnt}
                                            innerSelectPeople={innerSelectPeople}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {/* 취소 가능 기한*/}
                        <div className="section">
                            <div className="titleArea02 fieldBookingCancelDateWrap">
                                <h2>취소 가능 기한</h2>
                                <b
                                    className={`${
                                        fieldBookingData?.isCancelPossibility === true
                                            ? 'fieldBookingIsCancelTrue'
                                            : 'fieldBookingIsCancelFalse'
                                    }`}
                                >
                                    {moment(fieldBookingData?.cancelPossiblePeriod, moment.ISO_8601, true)
                                        ? appUtils.dateFormatTypeFull(fieldBookingData?.cancelPossiblePeriod)
                                        : fieldBookingData?.cancelPossiblePeriod}
                                </b>
                            </div>
                        </div>
                        {/* 총 결재 금액*/}
                        <div className="section fieldBookingAllPaymentWrap">
                            <div className="infoType06">
                                <dl>
                                    <dt>그린피(1인)</dt>
                                    {/* 기획서 2.2v 그린피 : 그린피 미 조회시 '그린피 골프장 문의' 노출*/}
                                    <dd>
                                        {fieldBookingData?.greenFeePerPerson === 0 ||
                                        fieldBookingData?.greenFeePerPerson === null
                                            ? '그린피 골프장 문의'
                                            : `${appUtils.numberFormat(fieldBookingData?.greenFeePerPerson)}원`}
                                    </dd>
                                </dl>
                                {/* 기획서 2.2v 총 결제 금액 : 내장인원 or 그린피 데이터 미 조회시 총 결제 금액 비 노출*/}
                                <dl
                                    className={`${
                                        fieldBookingData?.greenFeePerPerson === 0 ||
                                        fieldBookingData?.greenFeePerPerson === null ||
                                        innerPeople === 0
                                            ? 'hide'
                                            : ''
                                    } totalPayment`}
                                >
                                    <dt>총 현장 결제 금액</dt>
                                    <dd>{appUtils.numberFormat(sumGreentFee)}원</dd>
                                </dl>
                            </div>
                        </div>
                        {/* 예약자 정보 */}
                        <div className="section fieldBookingInfoWrap">
                            <div className="borderSection">
                                <div className="titleArea02">
                                    <h2>예약자 정보</h2>
                                </div>
                                <div className="infoType06">
                                    <dl>
                                        <dt>이름</dt>
                                        <dd>{fieldBookingData?.bookerName}</dd>
                                    </dl>
                                    <dl>
                                        <dt>연락처</dt>
                                        <dd>{appUtils.formatPhoneNumber(fieldBookingData?.bookerPhoneNumber)}</dd>
                                    </dl>
                                </div>
                            </div>
                            {/* 카트피가 0원일경우 부대비용에서 비노출 && 캐디피가 null일 경우 부대비용에서 비노출*/}
                            <>
                                <div
                                    className={
                                        fieldBookingData?.isCartFeeIncluded === true
                                            ? 'borderSection '
                                            : 'borderSection hide'
                                    }
                                >
                                    <div className="titleArea02">
                                        <h2>
                                            부대비용<span>(포함)</span>
                                        </h2>
                                    </div>

                                    <div className="infoType06">
                                        <dl>
                                            <dt>카트피</dt>
                                            <dd className="greenFee-include">그린피에 포함</dd>
                                        </dl>
                                    </div>
                                </div>

                                <div className="borderSection">
                                    <>
                                        <div className="titleArea02">
                                            <h2>
                                                부대비용<span>(불포함)</span>
                                            </h2>
                                        </div>

                                        <div className="infoType06">
                                            {/*카트피 0 일 경우 부대 비용에서 카트피 비노출*/}
                                            <dl className={fieldBookingData.isCartFeeIncluded === true ? 'hide' : ''}>
                                                <dt>
                                                    카트피<span>(현장결제)</span>
                                                </dt>
                                                <dd>{appUtils.numberFormat(fieldBookingData?.cartFee)}원</dd>
                                            </dl>
                                            {fieldBookingData?.caddieType === '노캐디' ? (
                                                <></>
                                            ) : (
                                                <dl>
                                                    <dt>
                                                        캐디피<span>(현장결제)</span>
                                                    </dt>
                                                    <dd>{fieldBookingData?.caddieFee}</dd>
                                                </dl>
                                            )}
                                        </div>
                                        <ul className="listType01 fz13">
                                            <li>라운드 홀수 및 골프장 상황에 따라 비용이 변동될 수 있습니다.</li>
                                        </ul>
                                    </>
                                </div>
                            </>

                            <div className="borderSection">
                                <div className="titleArea02">
                                    <h2>골프장 예약 공지</h2>
                                </div>
                                <div className="infoTxtCon">
                                    <div
                                        dangerouslySetInnerHTML={{__html: fieldBookingData?.notice}}
                                        className="preLine"
                                    >
                                        {/* <ul className="listType06" style={{whiteSpace: 'break-spaces'}}>
                                        <li >{fieldBookingData?.notice}
                                            dangerouslySetInnerHTML={{__html: data?.content}}
                                        </li>
                                         <li>결제금액은 부대 비용이 불포함 금액입니다.</li>
                                    <li>
                                        라운드를 마치신 후 그린피와 더불어 부대비용까지 정산될 예정이며, 캐디피는
                                        캐디에게 별도로 현금 지불하셔야 합니다.
                                    </li>
                                    <li>
                                        취소 가능 기한 이후에 예약을 취소하거나 예약 당일 아무런 통지 없이 골프장을
                                        이용하지 않을 경우 패널티가 있으니 신중한 예약 바랍니다.
                                    </li>
                                    </ul>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*법적고지*/}
                    <div className="section fieldBookingRowNotice">
                        <h2>법적고지</h2>
                        <p>
                            ㈜그린잇은 통신판매중개시스템의 제공자로서, 통신판매의 당사자가 아니며 상품의 예약 및 결제,
                            환불 등과 관련한 의무는 각 판매자(골프장)에 있습니다.
                        </p>
                    </div>
                    {/* 예약하기 버튼 */}
                    <div className="fieldBookingBtnArea">
                        <button type="button" className="btnPrimary" onClick={onFieldBookingLayerOpen}>
                            예약 하기
                        </button>
                    </div>
                    <FieldBookingLayer
                        fieldBookingOpen={fieldBookingOpen}
                        isSelfCheckInPossible={fieldBookingData?.isSelfCheckInPossible}
                        teeTime={fieldBookingData?.roundAt}
                        setFieldBookingOpen={setFieldBookingOpen}
                        golfClubName={fieldBookingData?.golfClubName}
                        courseName={fieldBookingData?.courseName}
                        isCancelPossibility={fieldBookingData?.isCancelPossibility}
                        cancelPossiblePeriod={fieldBookingData?.cancelPossiblePeriod}
                        hole={fieldBookingData?.adminHole}
                        afterCancelPeriod={fieldBookingData?.isAfterCancelPeriod}
                        sumGreentFee={sumGreentFee}
                        innerSelectPeople={innerSelectPeople}
                        courseId={fieldData.courseId}
                        timeSheetId={fieldData.timeSheetId}
                        golfClubId={id}
                        roundPlayer={innerSelectPeople}
                        placeboInformation={fieldBookingData?.placeboInformation}
                        isCartFeeIncluded={fieldBookingData?.isCartFeeIncluded}
                    />
                </div>
            </div>
            <DefaultAlert
                open={open}
                handleClose={handleClose}
                title={AFTER_CANCEL_PERIOD_ALERT_TITLE}
                contents={AFTER_CANCEL_PRRIOD_ALERT_CONTENT}
            />
        </>
    );
}

export default FieldBooking;
