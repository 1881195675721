import React, {useEffect, useState} from 'react';
import Button from '../../components/button/Button';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import './membership.scss';
import {fetchWrapper, history} from '../../_helpers';
import {useLocation} from 'react-router-dom';
import {subTitle} from '../../common/messages';
import Icon from '../icons/Icon';
import {useDispatch, useSelector} from 'react-redux';
import {inviteUtils} from '../../common/inviteUtils';
import {joinActions} from '../../_store/joinOnShareLink.slice';
import {localStorageUtil} from '../../common/utils';
import {getCookie, removeCookie} from '../../common/cookieUtils';
import {authActions, store, userActions} from '../../_store';
import {createAccountSuccess, loginSuccess} from '../../_helpers/amplitude/events/auth.events';
import AirbridgeUtil from '../../common/airbridgeUtil';
import {boolean} from 'yup';

function JoinComplete() {
    const dispatch = useDispatch();
    const accessToken = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');
    //디바이스 높이값 체크
    const {height} = useWindowDimensions();
    const location = useLocation();

    const [member, _setMember] = useState(location?.state?.member);

    const joinInviteAction = JSON.parse(localStorageUtil.get('joinInviteAction'));
    const bookingId = joinInviteAction?.inviteProp?.bookingId;
    const bookingMemberId = joinInviteAction?.inviteProp?.bookingMemberId;
    // console.log('joinInviteAction', joinInviteAction);
    // console.log('joinInviteAction bookingId', bookingId);
    // console.log('joinInviteAction bookingMemberId', bookingMemberId);

    const joinShareAction = JSON.parse(localStorageUtil.get('joinShareAction'));
    const golfClubId = joinShareAction?.shareProp?.golfClubId;
    const shareStatus = joinShareAction?.shareProp?.shareStatus;
    // console.log('joinShareAction', joinShareAction);
    // console.log('joinShareAction golfClubId', golfClubId);
    // console.log('joinShareAction shareStatus', shareStatus);

    const joinSelfChInPromotionAction = JSON.parse(localStorageUtil.get('joinSelfChInPromotionAction'));

    //동반자 초대 / 공유하기 / 딥링크 게이트 타고 넘어왔을 때
    const [fromGateInvite, setFromGateInvite] = useState(false);
    const [fromGateShare, setFromGateShare] = useState(false);
    const [fromDeepLink, setFromDeepLink] = useState(false);

    //홈으로 가기 버튼
    const nextPage = () => {
        history.navigate('/main');
        localStorageUtil.remove('joinInviteAction');
        localStorageUtil.remove('joinShareAction');
        localStorageUtil.remove('joinSelfChInPromotionAction');
    };
    //티타임 예약하러 가기 (골프장 상세)
    const goBookingDetail = () => {
        if (fromGateShare) {
            history.navigate(`/field-detail/${golfClubId}`);
            localStorageUtil.remove('joinShareAction');
        } else {
            nextPage();
        }
    };

    const {user: authUser} = useSelector((x) => x.auth);
    const users = useSelector((x) => x?.users);

    //초대 확인하기 버튼
    const goBookingList = () => {
        //쿠키로 로그인 처리
        if (!localStorageUtil.get('user') && accessToken && refreshToken) {
            localStorageUtil.set('user', JSON.stringify({accessToken, refreshToken}));
            removeCookie('accessToken');
            removeCookie('refreshToken');
            store.dispatch(authActions.refresh());
            store.dispatch(userActions.getAll());
        }

        inviteUtils.inviteProc(bookingId, bookingMemberId);
        // localStorageUtil.remove('joinInviteAction');
    };

    const goHome = () => {
        history.navigate('/main');
    };

    useEffect(() => {
        if (joinSelfChInPromotionAction) {
            setFromDeepLink(true);
        }
        if (joinShareAction && golfClubId && bookingMemberId) {
            setFromGateShare(true);
        }
        if (joinInviteAction && bookingId && bookingMemberId) {
            setFromGateInvite(true);
        }
        fetchWrapper.get('/amplitude-events/login-success').then((result) => {
            createAccountSuccess(result.data);
            AirbridgeUtil.event.signUp(result.data.method);
        });
    }, []);

    return (
        <>
            <div id="contents">
                <div className="completeArea" style={{minHeight: `${height}px`}}>
                    <div className="inner">
                        <div className="completeMsg">
                            <i className="icon">
                                <Icon name="check" width="60px" height="60px" viewBox="0 0 60 60" fill="none" />
                            </i>
                            {member && member?.name && (
                                <>
                                    <strong className="name">{member?.name}</strong>님,
                                    <br />
                                </>
                            )}
                            회원가입이 완료되었습니다
                            <p className="txt">{subTitle.memberJoinComplete}</p>
                        </div>
                        <div className="btnFixedBottom">
                            <div className="inner">
                                {fromGateInvite || fromDeepLink ? (
                                    <>
                                        {fromDeepLink ? (
                                            <Button type="button" onClick={goHome}>
                                                <span>셀프체크인 시작하기</span>
                                            </Button>
                                        ) : (
                                            <Button type="button" onClick={goBookingList}>
                                                <span>라운드 확인하기</span>
                                            </Button>
                                        )}
                                    </>
                                ) : (
                                    <div className="btnTooltipWrap">
                                        <i className="member_tooltip_open"></i>
                                        <Button type="button" onClick={nextPage}>
                                            <span>티타임 예약하러 가기</span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default JoinComplete;
