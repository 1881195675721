export const installLink = {
    GOLAGA_ANDROID: 'https://play.google.com/store/apps/details?id=golf.golaga',
    GOLAGA_IOS: 'https://itunes.apple.com/app/id1661923157',
    KAKAOMAP_ANDROID: 'https://play.google.com/store/apps/details?id=net.daum.android.map',
    KAKAOMAP_IOS: 'https://itunes.apple.com/app/id304608425',
    NAVERMAP_ANDROID: 'https://play.google.com/store/apps/details?id=com.nhn.android.nmap',
    NAVERMAP_IOS: 'https://itunes.apple.com/app/id311867728',
    TMAP_ANDROID: 'https://play.google.com/store/apps/details?id=com.skt.tmap.ku',
    TMAP_IOS: 'https://itunes.apple.com/app/id431589174',
    KAKAO_ANDROID: 'https://play.google.com/store/apps/details?id=com.kakao.talk&hl=ko',
    KAKAO_IOS: 'https://apps.apple.com/kr/app/kakaotalk/id362057947'
};
