import React from 'react';

import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import Icon from '../../icons/Icon';
import {appUtils} from 'common/utils';

//현장결제
const PaymentBookingSuccessOnSite = (props) => {
    // 예약 정보
    const {bookingData} = props;
    const {caddieType, payment} = bookingData;
    // 결제 정보
    const {
        // paymentType,
        paymentTypeName,
        // paymentDetailType,
        // paymentDetailTypeName,
        // prepaymentType,
        // prepaymentTypeName,
        // transactionMethod,
        // totalAmount,
        totalGreenFee,
        // greenFeePerPerson,
        expectOnsiteAmount,
        cartFee,
        caddieFee,
        // caddieFeeInfo,
        // reserveAmount,
        // onsiteGreenFee,
        // totalExpectAmount,
        // otherAmountMessage,
        isCartFeeIncluded
    } = payment;

    return (
        <div className="payment-booking-success-info">
            <div className="sub-title-area">
                <h2>{paymentInfoProperty.payment_infomation}</h2>
            </div>
            <dl className="total-payment">
                <dt className="payment-booking-init payment-success-700">
                    {paymentInfoProperty.onsite_payment_predict}
                </dt>
                <dd className="payment-success-800">{appUtils.amountFormat(expectOnsiteAmount)}</dd>
            </dl>
            <div className="payment-expanded-content">
                <dl>
                    <dt>
                        <i className="teeTimeBoxIcon-advance-payment">
                            <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                        </i>
                        {paymentInfoProperty.totalGreenFee}
                    </dt>
                    <dd>
                        {totalGreenFee === 0
                            ? paymentInfoProperty.greenFee_golf_contact
                            : appUtils.amountFormat(totalGreenFee)}
                    </dd>
                </dl>
                <dl>
                    <dt>
                        <i className="teeTimeBoxIcon-advance-payment">
                            <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                        </i>
                        {paymentInfoProperty.cartFee}
                    </dt>
                    <dd>{`${isCartFeeIncluded ? '그린피에 포함' : appUtils.amountFormat(cartFee)}`}</dd>
                </dl>

                {/* 캐디 타입이 SELF(노캐디) 일 경우 캐디피 비노출  */}
                {['SELF', '노캐디'].includes(caddieType) ? null : (
                    <dl>
                        <dt>
                            <i className="teeTimeBoxIcon-advance-payment">
                                <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                            </i>
                            {paymentInfoProperty.caddieFee}
                        </dt>
                        <dd> {appUtils.amountFormat(caddieFee)}</dd>
                    </dl>
                )}
            </div>
            <div className="payment-booking-success-info border-top">
                <dl>
                    <dt>{paymentInfoProperty.payment_type}</dt>
                    <dd>{paymentTypeName}</dd>
                </dl>
            </div>
        </div>
    );
};

export default PaymentBookingSuccessOnSite;
