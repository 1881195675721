import '../../_view/board/board.scss';
import {useLocation} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from 'react';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import BoardDetail from '../../_view/board/BoardDetail';

function ClubBoardDetail({boardId, golfClubId}) {
    const location = useLocation();
    const [detailData, setDetailData] = useState(null);

    const getBoardDetail = useCallback(() => {
        fetchWrapper.get(`/notice/detail?boardId=${boardId}&golfClubId=${golfClubId}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                let data = response?.data?.detail;
                setDetailData(data || null);
            } else {
                console.log(response);
            }
        });
    }, [boardId, golfClubId]);

    useEffect(() => {
        getBoardDetail();
    }, [getBoardDetail]);

    return <BoardDetail data={detailData} />;
}

export default ClubBoardDetail;
