import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import DatePicker from '../../common/calendar/DatePicker';
import FilterArea from '../../reservation/drawer/FilterArea';
import FieldSection from '../../reservation/OutdoorMain/FieldSection';
import {appUtils, localStorageUtil} from '../../../common/utils';
import {fetchWrapper, history} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import BasicFilterDrawer from '../../reservation/drawer/BasicFilterDrawer';
import '../mypage.scss';

import {FieldMainContext_myPage} from '../../../_store/context/FieldMainContext_myPage';
import {debounce} from 'lodash';
import SelfCheckInGuideLayer from '../../smart-round/SelfCheckInGuideLayer';
import DirectionSnackbar from '../../alert/DirectionSnackbar';
import {
    clickBasicFilter,
    clickInitBasicFilter,
    clickSetBasicFilter,
    clickDate
} from '../../../_helpers/amplitude/events/click.event';
import {viewFavorite} from '_helpers/amplitude/events/view.events';
import {locationTaxonomy} from '../../../_helpers/amplitude/taxonomy/location.taxonomy';
import {bookingConstant, validationUtil} from 'common/payment';

const {kakao} = window;

const selectDefaultDate = new Date();
selectDefaultDate.setDate(selectDefaultDate.getDate() + 3);

//기본 리스트 api 호출 수
let golfClubApiCallCnt = 0;

function Favorites({bookingData}) {
    // =================================== 변수 선언부 =========================================
    const fieldMainRef = useRef({});
    // 장소 검색 객체를 생성합니다
    const kakaoGeocoder = kakao ? new kakao.maps.services.Geocoder() : null;
    //GPS 위성 정보(현재 좌표) 확인
    let locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));

    // =================================== 변수 선언부 =========================================

    // =================================== state 선언부 ======================================= //
    //날짜 선택
    const [selectDate, setSelectDate] = useState(selectDefaultDate);
    //리스트 로딩 스피너
    const [isLoading, setIsLoading] = useState(false);
    // 페이지 갯수
    const [page, _setPage] = useState(0);
    // 페이지 사이즈
    let [size, setSize] = useState(50);
    // 다음페이지
    const [hasNext, _setHasNext] = useState(false);
    // 기본 필터 Drawer
    const [basicFilterOpen, setBasicFilterOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({address: ''});
    const [townName, setTownName] = useState('');
    // 위도, 경도
    const [latitude, setLatitude] = useState(locationStorage?.lat || 0);
    const [longitude, setLongitude] = useState(locationStorage?.lon || 0);

    // 필터 리스트
    const [basicFilter, setBasicFilter] = useState([]);
    // deepCopyBasicFilter
    const [copyBasicFilter, setCopyBasicFilter] = useState([]);

    // 고급 필터 리스트
    const [advancedFilter, setAdvancedFilter] = useState([]);
    // deepCopyAdvancedFilter
    const [copyAdvancedFilter, setCopyAdvancedFilter] = useState([]);

    //선택된 고급 필터 리스트
    const [selectAdvancedList, setSelectAdvancedList] = useState([]);

    //선택된 고급 필터 리스트 임시로 담기
    const [selectAdvancedListTemp, setSelectAdvancedListTemp] = useState([]);

    // 권역 / 지역
    const [region, setRegion] = useState([]);
    // 선택된 권역 / 지역 임시로 담기
    const [regionTemp, setRegionTemp] = useState([]);
    // 티타임
    const [teeTime, setTeeTime] = useState([]);
    // 선택된 티타임 임시 (적용시 원본에 반영)
    const [teeTimeTemp, setTeeTimeTemp] = useState([]);

    // 내장인원 (숫자 배열)
    const [playerCnt, setPlayerCnt] = useState([]);
    // 선택된 내장인원 임시로 담기
    const [playerCntTemp, setPlayerCntTemp] = useState([]);

    // 홀수
    const [holeCnt, setHoleCnt] = useState([]);
    // 선택된 홀수 임시로 담기
    const [holeCntTemp, setHoleCntTemp] = useState([]);

    // 그린피
    const [greenFee, setGreenFee] = useState([]);
    // 선택된 그린피 임시로 담기
    const [greenFeeTemp, setGreenFeeTemp] = useState([]);
    /* 2023.07.07 결제방식 추가 */
    const [paymentType, setPaymentType] = useState([]);
    const [paymentTypeTemp, setPaymentTypeTemp] = useState([]);

    //검색된 골프장 수
    let [golfListLen, setGolfListLen] = useState(0);

    //전체 골프장 수
    let [golfTotalCnt, setGolfTotalCnt] = useState(0);

    //필터 drawer ListCount
    let [basicFilterDrawerCnt, setBasicFilterDrawerCnt] = useState(0);

    //기본 필터 및 고급필터에 대한 카운트 변화를 하기위한 리스트 데이터
    const [filterCntListData, setFilterCntListData] = useState([]);
    //copy 기본 필터 및 고급필터에 대한 카운트 변화를 하기위한 리스트 데이터
    const [copyFilterCntListData, setCopyFilterCntListData] = useState([]);

    //필터 된 데이터 카운트를 정제하여 필터 적용시 필터된 리스트의 갯수를 똑같이 관리
    const [selectFilteredDataList, setSelectFilteredDataList] = useState([]);
    const [copySelectFilteredDataList, setCopySelectFilteredDataList] = useState([]);

    //선택된 필터가 있을때 필터 버튼 사용여 하나도 없을땐 필터 비활성화
    let [isFilterInitBtnDisable, setIsFilterInitBtnDisable] = useState(false);
    //선택된 필터가 있을때 필터 버튼 사용여 하나도 없을땐 필터 비활성화
    let [isAdvancedFilterInitBtnDisable, setIsAdvancedFilterInitBtnDisable] = useState(false);

    // 메인 추천 골프장 리스트
    const [getGolfClub, setGetGolfClub] = useState([]);
    //필터 리셋 여부
    const [isFilterReset, setIsFilterReset] = useState(false);

    //필터 적용하기 클릭여부
    const [btnApplyClicked, setBtnApplyClicked] = useState(false);
    //고급 필터 리셋 여부
    let [isAdvancedFilterReset, setIsAdvancedFilterReset] = useState(false);
    //고급 필터 적용하기 클릭여부
    const [btnAdvancedApplyClicked, setBtnAdvancedApplyClicked] = useState(false);

    //날짜만 선택 하였을 때 (필터 적용안하고 default 리스트 토탈 카운트 값 필터가 풀렸을 때 전체 카운트 불러오기 위함
    const [defaultAllGolfListCnt, setDefaultAllGolfListCnt] = useState(0);

    const [isSnackBarOpen, setIsSnackBarOpen] = useState({
        open: false,
        msg: ''
    });

    // 셀프체크인 가이드 레이어 오픈 여부
    const [selfCheckInGuideLayerOpen, setSelfCheckInGuideLayerOpen] = useState(false);
    const handleOpen = () => setSelfCheckInGuideLayerOpen(true);
    const handleClose = () => setSelfCheckInGuideLayerOpen(false);

    // =================================== state 선언부 ======================================= //

    // =================================== 초기 api 연동 함수 ================================== //

    //카테고리 기본 필터 리스트 불러오기
    const getBasicFilterList = useCallback(() => {
        fetchWrapper
            .get(`/search/filter/list`, null)
            .then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    // 골프장 권역 필터 삭제  not in fieldMain
                    const tempFilterList = response.data.filter(
                        (item) => item.filterCode !== bookingConstant.basicFilterCode.BASIC_FILTER_REGION
                    );
                    const filterList = tempFilterList.map((item) => {
                        item.searchFilters.map((cate, idx) => {
                            cate['isSelect'] = false;

                            return cate;
                        });
                        return item;
                    });

                    setBasicFilter(filterList || null);

                    const copyFilterList = JSON.parse(JSON.stringify(filterList));
                    setCopyBasicFilter(copyFilterList);
                }
            })
            .catch((err) => {
                console.log('err : ' + err);
            });
    }, []);

    //카테고리 고급 필터 리스트 불러오기
    const getAdvancedFilterList = useCallback(() => {
        fetchWrapper.get(`/search/filter/list?filterType=DETAIL`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                const filterList = response.data.map((item, firstId) => {
                    item.searchFilters.map((cate, secondId) => {
                        cate['isSelect'] = false;
                        //임시로 아이디 만듬 식별 및 삭제가 현재 불가하기때문에 api 수정되면 아이디 값도 추가되서 들어올것임

                        return cate;
                    });
                    return item;
                });

                setAdvancedFilter(filterList || null);

                const copyFilterList = JSON.parse(JSON.stringify(filterList));
                setCopyAdvancedFilter(copyFilterList);
            }
        });
    }, []);

    // =================================== 초기 api 연동 함수 ================================== //

    // =================================== useEffect 선언부 ======================================= //
    // 컨텐츠 스크롤 영역 잡기 위한 클래스명 추가
    useEffect(() => {
        locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));

        document.documentElement.classList.add('fullPage');

        // 2023-05-09 by brown 쿼리스트랑 파싱 함수 호출
        getMaintainDate();

        // 로컬스토리지에 저장되어있으면 해당 정보로 셋팅
        const selectedLocation_util = JSON.parse(localStorageUtil.get('selectedLocation'));
        if (validationUtil.isNullChk(selectedLocation_util) || selectedLocation_util.address === '') {
            setCurrentLocationToTmap();
        } else {
            setSelectedLocation(selectedLocation_util);
        }
        return () => {
            document.documentElement.classList.remove('fullPage');
        };
    }, []);

    //뒤로가기 데이터를 유지하기위해 쿼리스트링 읽어옴
    const getMaintainDate = useCallback(() => {
        const urlData = new URLSearchParams(history?.location?.search).get('data');

        const urlParams = JSON.parse(urlData);

        if (urlParams && urlParams.selectDate) {
            let selectDate = decodeURI(urlParams.selectDate);
            const dateWithoutTimezone = selectDate.replace('GMT 0900', '');
            const date = new Date(dateWithoutTimezone);
            setSelectDate(date);
        }

        // 기본 필터 5개
        // 고급 필터 값
        // 위치 정보
        // 선택된 날짜
        // 정렬 값
        // 필터 초기화 버튼 값(?
        // 고급 필터 초기화 값(?
        // 스크롤 유지 값?
        // 페이징..;
    }, []);

    //필터 창 열릴때 안드로이드 백버튼 눌렀을때 webview 통신하여 닫기
    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                if (basicFilterOpen === true) {
                    onBasicFilterClose();
                }
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);
        return () => {
            document.removeEventListener('message', handleEvent);
        };
    }, [basicFilterOpen]);

    useEffect(() => {
        // 동네 이름 설정
        const locationSplit = selectedLocation.address.split(' ');
        setTownName(locationSplit[locationSplit.length - 2]);

        // 선택한 주소 저장
        if (selectedLocation.address !== '') {
            localStorageUtil.set('selectedLocation', JSON.stringify(selectedLocation));
        }
    }, [selectedLocation]);

    useEffect(() => {
        getBasicFilterList();
        getAdvancedFilterList();
    }, [getBasicFilterList]);

    // 카테고리 선택시 초기화 버튼 활성화 선택하지 않으면 초기화 버튼 비활성화
    useEffect(() => {
        if (
            /*region.length === 0 &&*/
            teeTime.length === 0 &&
            playerCnt.length === 0 &&
            holeCnt.length === 0 &&
            greenFee.length === 0 &&
            teeTimeTemp.length === 0 &&
            playerCntTemp.length === 0 &&
            holeCntTemp.length === 0 &&
            greenFeeTemp.length === 0 &&
            /* 2023.07.07 결제방식 추가 */
            paymentType.length === 0 &&
            paymentTypeTemp.length === 0
        ) {
            setIsFilterInitBtnDisable(true);
        } else {
            setIsFilterInitBtnDisable(false);
        }
    }, [
        teeTime,
        playerCnt,
        holeCnt,
        greenFee,
        teeTimeTemp,
        playerCntTemp,
        holeCntTemp,
        greenFeeTemp,
        /* 2023.07.07 결제방식 추가 */
        paymentType,
        paymentTypeTemp
    ]);

    useEffect(() => {
        if (selectAdvancedList.length === 0) {
            setIsAdvancedFilterInitBtnDisable(true);
        } else {
            setIsAdvancedFilterInitBtnDisable(false);
        }
    }, [selectAdvancedList]);

    /*const getGolfClubList =*/
    useEffect(() => {
        golfClubApiCallCnt = 0;

        const payload = makePayload(0);
        setPage(0);

        if (selectedLocation?.locationLat !== undefined) {
            categoryFilterGolfclubList(payload);
        }
    }, [selectedLocation, selectDate, isFilterReset, isAdvancedFilterReset]);

    useEffect(() => {
        const payload = makePayload(page);
        if (selectedLocation?.locationLat !== undefined) {
            categoryFilterGolfclubList(payload);
        }
    }, [page]);

    useEffect(() => {
        setIsFilterReset(false);
    }, [setBasicFilterOpen]);

    // =================================== useEffect 선언부 ======================================= //
    const myPage = useRef(page);
    const setPage = (pageNum) => {
        myPage.current = pageNum;
        _setPage(pageNum);
    };

    const myHasNext = useRef(hasNext);
    const setHasNext = (isNext) => {
        myHasNext.current = isNext;
        _setHasNext(isNext);
    };

    const onBasicFilterOpen = (codeName) => {
        setBasicFilterOpen(true);

        clickBasicFilter(codeName, 'favorite');

        appUtils.openWebViewModalPostMsg();
    };

    //기본 필터 닫기 함수
    const onBasicFilterClose = () => {
        const copyFilterList = JSON.parse(JSON.stringify(basicFilter));
        setCopyBasicFilter(copyFilterList);
        setBasicFilterOpen(false);

        setRegionTemp(region);
        setTeeTimeTemp(teeTime);
        setPlayerCntTemp(playerCnt);
        setGreenFeeTemp(greenFee);
        setHoleCntTemp(holeCnt);
        setPaymentTypeTemp(paymentType);
    };

    //고급 필터 닫기 함수
    const onAdvancedFilterClose = () => {
        const copyAdvancedFilterList = JSON.parse(JSON.stringify(advancedFilter));

        setCopyAdvancedFilter(copyAdvancedFilterList);

        setSelectAdvancedListTemp(selectAdvancedList);
    };

    const myGolfClubList = useRef(getGolfClub);
    const setGolfClubList = (list) => {
        myGolfClubList.current = list;
        setGetGolfClub(list);
    };

    const selectCategoryItem = (filterCode, code, name, btnActive, id) => {
        let index = copyBasicFilter.findIndex((item) => item.filterCode === filterCode);

        let isActive = JSON.parse(btnActive);

        copyBasicFilter[index].searchFilters.find((item) => {
            if (item.code === code) {
                item.isSelect = !item.isSelect;
            }
        });

        switch (filterCode) {
            // 권역 / 지역
            // case bookingConstant.basicFilterCode.BASIC_FILTER_REGION:
            //     if (isActive === false) {
            //         const data = {
            //             code: code,
            //             codeName: name,
            //             codeId: id,
            //             flag: bookingConstant.basicFilterCode.BASIC_FILTER_REGION
            //         };

            //         setRegionTemp([...regionTemp, data]);
            //     } else {
            //         setRegionTemp(regionTemp.filter((item) => item.codeId !== id));
            //     }
            //     break;
            // 티타임
            case bookingConstant.basicFilterCode.BASIC_FILTER_TEETIME:
                if (isActive === false) {
                    const data = {
                        code: code,
                        codeName: name,
                        codeId: id,
                        flag: bookingConstant.basicFilterCode.BASIC_FILTER_TEETIME
                    };

                    setTeeTimeTemp([...teeTimeTemp, data]);
                } else {
                    setTeeTimeTemp(teeTimeTemp.filter((item) => item.codeId !== id));
                }
                break;
            // 내장인원 (숫자 배열)
            case bookingConstant.basicFilterCode.BASIC_FILTER_ROUNDPLAYER:
                if (isActive === false) {
                    const data = {
                        code: Number(code),
                        codeName: name,
                        codeId: id,
                        flag: bookingConstant.basicFilterCode.BASIC_FILTER_ROUNDPLAYER
                    };

                    setPlayerCntTemp([...playerCntTemp, data]);
                } else {
                    setPlayerCntTemp(playerCntTemp.filter((item) => item.codeId !== id));
                }
                break;
            // 홀수
            case bookingConstant.basicFilterCode.BASIC_FILTER_HOLE:
                if (isActive === false) {
                    const data = {
                        code: Number(code),
                        codeName: name,
                        codeId: id,
                        flag: bookingConstant.basicFilterCode.BASIC_FILTER_HOLE
                    };

                    setHoleCntTemp([...holeCntTemp, data]);
                } else {
                    setHoleCntTemp(holeCntTemp.filter((item) => item.codeId !== id));
                }
                break;
            // 그린피
            case bookingConstant.basicFilterCode.BASIC_FILTER_GREENFEE:
                if (isActive === false) {
                    const data = {
                        code: code,
                        codeName: name,
                        codeId: id,
                        flag: bookingConstant.basicFilterCode.BASIC_FILTER_GREENFEE
                    };

                    setGreenFeeTemp([...greenFeeTemp, data]);
                } else {
                    setGreenFeeTemp(greenFeeTemp.filter((item) => item.codeId !== id));
                }
                break;
            /* 2023.07.07 결제방식 추가 */
            case bookingConstant.basicFilterCode.BASIC_FILTER_PAYMENT:
                if (isActive === false) {
                    const data = {
                        code: code,
                        codeName: name,
                        codeId: id,
                        flag: bookingConstant.basicFilterCode.BASIC_FILTER_PAYMENT
                    };

                    setPaymentTypeTemp([...paymentTypeTemp, data]);
                } else {
                    setPaymentTypeTemp(paymentTypeTemp.filter((item) => item.codeId !== id));
                }
                break;
            default:
                return false;
        }
    };

    //고급 필터 선택시 클릭 시 실행 함수
    // const selectAdvancedItem = (filterCode, code, name, btnActive, id) => {
    //     let isActive = JSON.parse(btnActive);

    //     const data = {name: name, codeId: id, filterCodeName: filterCodeName};

    //     if (isActive === false) {
    //         setSelectAdvancedListTemp([...selectAdvancedListTemp, data]);
    //     } else {
    //         setSelectAdvancedListTemp(selectAdvancedListTemp.filter((item) => item.codeId !== id));
    //     }

    //     let index = copyAdvancedFilter.findIndex((item) => item.filterCode === filterCode);

    //     copyAdvancedFilter[index].searchFilters.find((item) => {
    //         if (item.codeId === id) {
    //             item.isSelect = !item.isSelect;
    //         }
    //     });
    // };

    //필터 초기화 버튼 누를시 카테고리 필터 초기화
    const filterInitBtn = () => {
        setIsFilterReset(!isFilterReset);

        setRegion([]);
        setRegionTemp([]);
        setTeeTime([]);
        setTeeTimeTemp([]);
        setPlayerCnt([]);
        setPlayerCntTemp([]);
        setHoleCnt([]);
        setHoleCntTemp([]);
        setGreenFee([]);
        setGreenFeeTemp([]);
        /* 2023.07.07 결제방식 추가 */
        setPaymentType([]);
        setPaymentTypeTemp([]);
        setPage(0);

        copyBasicFilter.map((item) => {
            item.searchFilters.map((filter) => {
                filter.isSelect = false;
            });
        });

        setBasicFilter(JSON.parse(JSON.stringify(copyBasicFilter)));
        /*getGolfClubList();*/
        clickInitBasicFilter('favorite');
    };

    const advancedFilterInitBtn = () => {
        setIsAdvancedFilterReset(!isAdvancedFilterReset);

        setSelectAdvancedList([]);
        setSelectAdvancedListTemp([]);
        setPage(0);

        copyAdvancedFilter.map((item) => {
            item.searchFilters.map((filter) => {
                filter.isSelect = false;
            });
        });

        setAdvancedFilter(JSON.parse(JSON.stringify(copyAdvancedFilter)));
    };

    //골프장 리스트 당겨서 새로고침시 함수 실행
    const pullToGolfListRefresh = debounce(() => {
        const payload = makePayload(0);
        if (selectedLocation?.locationLat === undefined || selectedLocation?.locationLon === undefined) {
            payload.userLat = 37.5663;
            payload.userLon = 126.9779;
        }
        categoryFilterGolfclubList(payload);
    }, [300]);

    const makePayload = (page) => {
        const regionArr = appUtils.filterObjToArr(region, 0);
        //티타임
        const teeTimeArr = appUtils.filterObjToArr(teeTime, 0);
        //내장인원
        const playerCntArr = appUtils.filterObjToArr(playerCnt, 0);
        //홀 수

        const holeCntArr = appUtils.filterObjToArr(holeCnt, 0);
        //그린피
        const greenFeeArr = appUtils.filterObjToArr(greenFee, 0);
        /* 2023.07.07 결제방식추가 */
        const paymentTypeArr = appUtils.filterObjToArr(paymentType, 0);

        //고급필터
        const selectTagIdListArr = appUtils.filterObjToArr(selectAdvancedList, 1);

        // 리스트업
        return {
            address: regionArr,
            greenFeeCode: greenFeeArr,
            hole: holeCntArr,
            minimumRoundPlayer: playerCntArr,
            roundDate: appUtils.dateFormatTypeDash(selectDate),
            like: true,
            tagIdList: selectTagIdListArr,
            teeTimeCode: teeTimeArr,
            userLat: selectedLocation.locationLat === 0 ? 37.5663 : selectedLocation.locationLat,
            userLon: selectedLocation.locationLon === 0 ? 126.9779 : selectedLocation.locationLon,
            //페이징 추가로 수정 현재는 고정값
            page: page,
            size: size,
            paymentType: paymentTypeArr
        };
    };

    // '필터 적용하기' 버튼
    const onBtnApplyClicked = () => {
        setBtnApplyClicked(!btnApplyClicked);

        // ================== amplitude Filter Data =================
        const amp_sector = appUtils.filterObjToCommaStr(regionTemp, 0);
        const amp_teeTime = appUtils.filterObjToCommaStr(teeTimeTemp, 0);
        const amp_people = appUtils.filterObjToCommaStr(playerCntTemp, 0);
        const amp_count_hole = appUtils.filterObjToCommaStr(holeCntTemp, 0);
        const amp_green_fee = appUtils.filterObjToCommaStr(greenFeeTemp, 0);
        const amp_pay_condition = appUtils.filterObjToCommaStr(paymentTypeTemp, 0);

        const amp_data = {
            sector: amp_sector,
            teetime: amp_teeTime,
            people: amp_people,
            count_hole: amp_count_hole,
            greenfee: amp_green_fee,
            pay_condition: amp_pay_condition
        };

        clickSetBasicFilter(amp_data, locationTaxonomy.favorite);

        //개별 선택된 임시 카테고리를 원본 카테고리에 올림
        setRegion(regionTemp);
        setTeeTime(teeTimeTemp);
        setPlayerCnt(playerCntTemp);
        setHoleCnt(holeCntTemp);
        setGreenFee(greenFeeTemp);
        setPaymentType(paymentTypeTemp);
        setPage(0);

        //console.log(regionTemp);
        //파라미터
        //지역
        const regionArr = appUtils.filterObjToArr(regionTemp, 0);
        //티타임
        const teeTimeArr = appUtils.filterObjToArr(teeTimeTemp, 0);
        //내장인원
        const playerCntArr = appUtils.filterObjToArr(playerCntTemp, 0);
        //홀 수

        const holeCntArr = appUtils.filterObjToArr(holeCntTemp, 0);
        //그린피
        const greenFeeArr = appUtils.filterObjToArr(greenFeeTemp, 0);
        /* 2023.07.07 결제방식 추가  */
        const paymentTypeArr = appUtils.filterObjToArr(paymentTypeTemp, 0);

        //고급필터
        const selectTagIdListArr = appUtils.filterObjToArr(selectAdvancedList, 1);

        //멤버 아디

        //최소 라운드 플레이어

        // 날짜 *
        //위도 경도 0

        const data = {
            roundDate: appUtils.dateFormatTypeDash(selectDate),
            /*roundDate: "2023-02-26",*/
            //지역
            address: regionArr,
            //홀수
            hole: holeCntArr,
            //멤버
            minimumRoundPlayer: playerCntArr,
            //티타임
            teeTimeCode: teeTimeArr,
            //그린피
            greenFeeCode: greenFeeArr,
            tagIdList: selectTagIdListArr,
            /* 2023.07.07 결제방식 추가  */
            paymentType: paymentTypeArr,
            //위도
            userLat: selectedLocation.locationLat,
            //경도
            userLon: selectedLocation.locationLon,

            //페이징 추가로 수정 현재는 고정값
            page: 0,
            size: size,
            like: true
        };

        categoryFilterGolfclubList(data);

        setBasicFilter(JSON.parse(JSON.stringify(copyBasicFilter)));

        setBasicFilterOpen(false);
    };

    //해당 일자에 선택된 리스트 불러오는 함수
    const categoryFilterGolfclubList = (data) => {
        if (page === 0) {
            setIsLoading(true);
        }

        fetchWrapper
            .post(`/golfClub/like/golfClubs`, data)
            .then((response) => {
                let totalCount;
                if (response?.code === SUCCESS_CODE) {
                    //console.log('categoryFilterGolfclubList result', response);
                    totalCount = response?.data.totalCount;

                    setHasNext(response?.data.hasNext);
                    // 2023-03-14 lists 조건 추가
                    // list가 0인 경우 추가
                    if (response?.data?.lists.length > 0) {
                        if (myPage.current === 0) {
                            // 0번페이지면 그대로 리스트업, 스크롤 초기화
                            // setGolfClubList([]);
                            // fieldMainRef.current.goToScrollTop();
                            setGolfClubList(response?.data.lists || null);
                        } else {
                            // 0번페이지가 아니면 기존 리스트 뒤에 x번째의 페이지 리스트 추가
                            const margeList = [...myGolfClubList.current, ...response?.data.lists];

                            // 같은 id 중복제거
                            const removeDuplication = margeList.reduce(function (acc, current) {
                                if (acc.findIndex(({id}) => id === current.id) === -1) {
                                    acc.push(current);
                                }
                                return acc;
                            }, []);
                            setGolfClubList(removeDuplication);
                        }
                        fetchWrapper.get('/amplitude-events/view-favorite').then((response) => {
                            viewFavorite(response.data);
                        });

                        const urlData = new URLSearchParams(history?.location?.search).get('data');

                        if (urlData) {
                            const urlParams = JSON.parse(urlData);
                            if (urlParams && urlParams.selectDate) {
                                fetchWrapper
                                    .get(`/amplitude-events/click-date?roundDate=${data.roundDate}`)
                                    .then((response) => {
                                        response.data.location = 'favorite';
                                        response.data.result_cc_count = totalCount;
                                        clickDate(response.data);
                                    });
                            }
                        }
                    } else {
                        setGolfClubList([]);
                    }

                    setGolfListLen(myGolfClubList.current.length);
                    setGolfTotalCnt(response?.data.totalCount);
                    if (golfClubApiCallCnt === 0) {
                        setDefaultAllGolfListCnt(response?.data.totalCount);
                    }
                    /*setBasicFilterDrawerCnt(myGolfClubList.current.length);*/
                    setBasicFilterDrawerCnt(response?.data?.totalCount);
                    if (page === 0) {
                        setIsLoading(false);
                    }

                    golfClubApiCallCnt++;

                    //console.log('golfClubList : ', myGolfClubList.current);
                } else {
                }
            })
            .catch((err) => console.log('err : ', err));
    };

    const deleteInterestItem = (item) => {
        const newList = myGolfClubList.current.filter((golfZoneListitem) => golfZoneListitem.id !== item.id);
        setGolfClubList(newList);
    };

    //고급 필터 적용하기 버튼
    const onBtnAdvancedApplyClicked = () => {
        setBtnAdvancedApplyClicked(!btnAdvancedApplyClicked);

        setAdvancedFilter(JSON.parse(JSON.stringify(copyAdvancedFilter)));
        setSelectAdvancedList(selectAdvancedListTemp);

        setPage(0);

        //파라미터
        //지역
        const regionArr = appUtils.filterObjToArr(region, 0);
        //티타임
        const teeTimeArr = appUtils.filterObjToArr(teeTime, 0);
        //내장인원
        const playerCntArr = appUtils.filterObjToArr(playerCnt, 0);
        //홀 수

        const holeCntArr = appUtils.filterObjToArr(holeCnt, 0);
        //그린피
        const greenFeeArr = appUtils.filterObjToArr(greenFee, 0);
        /* 2023.07.07 결제방식추가 */
        const paymentTypeArr = appUtils.filterObjToArr(paymentType, 0);

        //고급필터
        const selectTagIdListArr = appUtils.filterObjToArr(selectAdvancedListTemp, 1);

        //멤버 아디

        //최소 라운드 플레이어

        // 날짜 *
        //위도 경도 0

        const data = {
            roundDate: appUtils.dateFormatTypeDash(selectDate),
            /*roundDate: "2023-02-26",*/
            //지역
            address: regionArr,
            //홀수
            hole: holeCntArr,
            //멤버
            minimumRoundPlayer: playerCntArr,
            //티타임
            teeTimeCode: teeTimeArr,
            //그린피
            greenFeeCode: greenFeeArr,
            tagIdList: selectTagIdListArr,
            //위도
            userLat: selectedLocation.locationLat,
            //경도
            userLon: selectedLocation.locationLon,
            page: 0,
            size: size,
            like: true,
            /* 2023.07.07 */
            paymentType: paymentTypeArr
        };

        categoryFilterGolfclubList(data);
    };

    const setCurrentLocationToTmap = () => {
        let latlng;

        if (validationUtil.isNullChk(locationStorage) || locationStorage?.lat === 0 || locationStorage?.lon === 0) {
            // 서울시청
            latlng = {lat: 37.5663, lon: 126.9779};
        } else {
            if (locationStorage.gpsrequest === 0) {
                // 현재위치
                latlng = {lat: locationStorage.lat, lon: locationStorage.lon};
            } else {
                // 서울시청
                latlng = {lat: 37.5663, lon: 126.9779};
            }
        }

        fetch(
            `https://apis.openapi.sk.com/tmap/geo/reversegeocoding?appKey=EjLNVEBVhu8j8ch3AT37V2cmowkakcCN90PdxxL8&version=1&format=json&callback=result&coordType=WGS84GEO&addressType=A10&lon=${latlng.lon}&lat=${latlng.lat}`,
            {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json'
                }
            }
        )
            .then((response) => response.json())
            .then((response) => {
                // console.log('myLocation!!!!', response);
                const myAddr = response?.addressInfo?.fullAddress?.split(',');
                const orginAddr = myAddr[1];
                const roadAddr = myAddr[2];

                // 위도, 경도 저장
                setLatitude(latlng.lat);
                setLongitude(latlng.lon);

                // 선택된 위치 정보 저장
                setSelectedLocation({
                    address: roadAddr,
                    locationType: 'currentPlace',
                    locationLat: latlng.lat,
                    locationLon: latlng.lon,
                    orginAddr: orginAddr,
                    townName: response?.addressInfo?.legalDong
                });
            });
    };

    const onClickGoBackBtn = () => {
        history.navigate('/mypage');
    };

    const onClickSelfCheckInBtn = (e) => {
        handleOpen();
        appUtils.openWebViewModalPostMsg();
    };

    //=================================== 함수 선언부 ======================================= //

    const value = useMemo(
        () => ({
            setBasicFilterOpen,
            btnApplyClicked,
            onBtnApplyClicked,
            //고급 필터 적용하기
            btnAdvancedApplyClicked,
            onBtnAdvancedApplyClicked,

            onBasicFilterClose,
            onAdvancedFilterClose,

            //기본필터 복사본
            copyBasicFilter,
            //고급필터 복사본
            copyAdvancedFilter,
            //날짜 선택
            selectDate,
            setSelectDate,
            region,
            teeTime,
            playerCnt,
            holeCnt,
            greenFee,

            //선택된 지역
            regionTemp,
            teeTimeTemp,
            playerCntTemp,
            holeCntTemp,
            greenFeeTemp,
            /* 2023.07.07 결제방식 추가 */
            paymentType,
            paymentTypeTemp,

            //선택된 고급필터
            selectAdvancedList,
            selectAdvancedListTemp,

            //기본 필터 리스트 크기
            basicFilterDrawerCnt,
            setBasicFilterDrawerCnt,
            //필터 카운팅용 데이터
            filterCntListData,
            setFilterCntListData,
            copyFilterCntListData,
            setCopyFilterCntListData,

            //적용된 필터데이터 관리용
            selectFilteredDataList,
            setSelectFilteredDataList,

            copySelectFilteredDataList,
            setCopySelectFilteredDataList,

            golfListLen
        }),
        [
            setBasicFilterOpen,
            btnApplyClicked,
            onBtnApplyClicked,
            //고급 필터 적용하기
            btnAdvancedApplyClicked,
            onBtnAdvancedApplyClicked,

            onBasicFilterClose,
            onAdvancedFilterClose,

            //기본필터 복사본
            copyBasicFilter,
            //고급필터 복사본
            copyAdvancedFilter,
            //날짜 선택
            selectDate,
            setSelectDate,
            region,
            teeTime,
            playerCnt,
            holeCnt,
            greenFee,
            //기본 필터 리스트 크기
            basicFilterDrawerCnt,
            setBasicFilterDrawerCnt,

            //선택된 지역
            regionTemp,
            teeTimeTemp,
            playerCntTemp,
            holeCntTemp,
            greenFeeTemp,
            /* 2023.07.07 결제방식 추가 */
            paymentType,
            paymentTypeTemp,

            //선택된 고급필터
            selectAdvancedList,
            selectAdvancedListTemp,

            //필터 카운팅용 데이터
            filterCntListData,
            setFilterCntListData,
            copyFilterCntListData,
            setCopyFilterCntListData,

            //적용된 필터데이터 관리용
            selectFilteredDataList,
            setSelectFilteredDataList,

            copySelectFilteredDataList,
            setCopySelectFilteredDataList,

            golfListLen
        ]
    );

    return (
        <FieldMainContext_myPage.Provider value={value}>
            <div id="header">
                <div className="inner favoritesHeader">
                    <button className="btnBack" onClick={onClickGoBackBtn}></button>
                    <h1 className="pageTitle">즐겨찾는 골프장</h1>
                </div>
            </div>
            <div id="contents">
                <div className="contentsArea" id="fieldMain">
                    <DatePicker selectDate={selectDate} setSelectDate={setSelectDate} />
                    <FilterArea
                        basicFilter={basicFilter}
                        onBasicFilterOpen={onBasicFilterOpen}
                        isFilterInitBtnDisable={isFilterInitBtnDisable}
                        filterInitBtn={filterInitBtn}
                        //선택된
                        selectAdvancedList={selectAdvancedList}
                        contextFlag="mypage-favorites"
                    />
                    <div className="mypageFilterArea">
                        <FieldSection
                            golfClubData={myGolfClubList.current}
                            bookingData={bookingData}
                            searchDate={selectDate}
                            townName={townName}
                            golfTotalCnt={golfTotalCnt}
                            hasNext={myHasNext}
                            setPage={setPage}
                            ref={fieldMainRef}
                            contextFlag="mypage-favorites"
                            pullToGolfListRefresh={pullToGolfListRefresh}
                            isLoading={isLoading}
                            setIsSnackBarOpen={setIsSnackBarOpen}
                            onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                            deleteInterestItem={deleteInterestItem}
                            isMyPage={true}
                            setGolfClubList={setGolfClubList}
                        />
                    </div>
                </div>
            </div>

            {/* 기본 필터 Layer */}
            <BasicFilterDrawer
                basicFilter={basicFilter}
                basicFilterOpen={basicFilterOpen}
                selectCategoryItem={selectCategoryItem}
                filterInitBtn={filterInitBtn}
                isFilterReset={isFilterReset}
                isFilterInitBtnDisable={isFilterInitBtnDisable}
                contextFlag="mypage-favorites"
                defaultAllGolfListCnt={defaultAllGolfListCnt}
            />

            {/* 관심 골프장 on/off 안내 snackBar */}
            <DirectionSnackbar
                direction="up"
                position="bottom"
                open={isSnackBarOpen.open}
                msg={isSnackBarOpen.msg}
                duration={1000}
                setOpen={setIsSnackBarOpen}
                isNavigation={false}
            />

            {/* 셀프체크인 가이드 Layer */}
            <SelfCheckInGuideLayer handleOpen={selfCheckInGuideLayerOpen} handleClose={handleClose} />
        </FieldMainContext_myPage.Provider>
    );
}

export default Favorites;
