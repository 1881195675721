import React, {useEffect} from 'react';
import Header from '../Header';
import {Link} from 'react-router-dom';
import {viewPolicy} from '../../_helpers/amplitude/events/view.events';

function Policy() {
    useEffect(() => {
        viewPolicy();
    }, []);
    return (
        <>
            <Header back={true} pageTitle="약관 및 정책" />
            <div id="contents">
                <div className="contentsArea">
                    <div className="inner">
                        <ul className="listType05">
                            <li>
                                <Link to="/service-agree">서비스 이용약관</Link>
                            </li>
                            <li>
                                <Link to="/location-service_policy">위치기반 서비스 이용약관</Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy">개인정보처리방침</Link>
                            </li>
                            <li>
                                <Link to="/open-license">오픈소스 라이센스</Link>
                            </li>
                            <li>
                                <Link to="/get-reservation-agree">예약정보 조회 · 전송 약관</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Policy;
