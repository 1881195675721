import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {appUtils, localStorageUtil} from '../common/utils';
import {fetchWrapper} from '../_helpers';
import {CNFRMTITMESSAGE, SUCCESS_CODE} from '../common/constants';

/**
 * type 00 confirm : title + message
 * @type {string}
 */
const name = 'alert';
const initialState = {
    type: '00',
    open: false,
    id: '',
    messageData: {
        title: '',
        message: ''
    }
};

const alertSlice = createSlice({
    name,
    initialState,
    reducers: {
        showAlert: {
            reducer: (state, action) => {
                state.open = true;
                state.id = action.payload.id;
                state.type = action.payload.type;
                state.messageData = action.payload?.messageData;
            }
        },
        closeAlert: {
            reducer: (state, action) => {
                state.open = false;
            }
        }
    }
});

export const alertActions = {...alertSlice.actions};
export const alertReducer = alertSlice.reducer;
