import React from 'react';
import Modal from '@mui/material/Modal';
import {localStorageUtil} from '../../common/utils';
import {formatTodayDateToIsoString} from 'common/notWatchingTodayUtils';

function MaintainanceNoticeModal({open, handleClose}) {
    const modalClose = () => {
        handleClose();
    };

    // '오늘 하루 보지 않기' 필요 시
    // const getLocalstorage = localStorageUtil.get('get_maintainance_notice');
    // const setPopUpHide = () => {
    //     if (!getLocalstorage || (getLocalstorage && getLocalstorage !== formatTodayDateToIsoString))
    //         localStorageUtil.set('get_maintainance_notice', formatTodayDateToIsoString);

    //     modalClose();
    // };

    const noticeStartDate = process.env?.REACT_APP_NOTICE_START_DATE;
    const noticeEndDate = process.env.REACT_APP_NOTICE_END_DATE;

    return (
        <>
            {/* 점검 안내 모달 */}
            <Modal open={open} onClose={modalClose}>
                <div className=" maintainanceNoticeModal">
                    {/* '오늘 하루 보지 않기' 필요 시 */}
                    {/* <div type="button" className="popUpHide" onClick={setPopUpHide}>
                        <span className="btn_ar"></span> 오늘 하루 보지 않기
                    </div> */}
                    <div id="alertConWrap">
                        <div className="alertTitle">서비스 점검 안내</div>
                        <div className="alertContents">
                            <p>잠시 후 서비스 점검이 시작됩니다.</p>
                            <p>
                                점검시간 : {noticeStartDate} ~ {noticeEndDate}
                            </p>
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={modalClose}>
                            확인
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default MaintainanceNoticeModal;
