import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {fetchWrapper, history} from '../../../_helpers';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import Header from 'components/Header';
import DefaultAlert from 'components/alert/DefaultAlert';
import PaymentBookingInfo from './PaymentBookingInfo';
import PaymentBookingDetails from './PaymentBookingDetail';
import PaymentBookingUserInfo from './PaymentBookingUserInfo';
import PaymentBookingNotice from './PaymentBookingNotice';
import PaymentBookingPrivacyConsent from './PaymentBookingPrivacyConsent';
import Button from 'components/button/Button';
import {btn} from 'common/payment/locales/kr/btn';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {bookingCancel} from 'common/payment/locales/kr/booking-cancel';
import {SUCCESS_CODE} from 'common/constants';
import {paymentBookingActions} from '_store/paymentBooking.slice';
import moment from 'moment';
import {cryptoUtil, qsUtil, paymentConstant, bookingConstant} from 'common/payment';
import {appUtils} from 'common/utils';

import './payment-booking.scss';
import DirectionSnackbar from 'components/alert/DirectionSnackbar';
import Loading from 'common/loading/Loading';
import {loadingActions} from '_store/loading.slice';
import {clickReservationBtn} from '_helpers/amplitude/events/click.event';
import {clickTaxonomy} from '_helpers/amplitude/taxonomy/click-event.taxonomy';
import {etcTaxonomy} from '_helpers/amplitude/taxonomy/etc-event.taxonomy';
import {reservationComplete} from '_helpers/amplitude/events/etc.event';
import {viewTaxonomy} from '_helpers/amplitude/taxonomy/view.taxonomy';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {viewReservation} from '_helpers/amplitude/events/view.events';

const {SETTLE_PG} = window;

const PaymentBooking = () => {
    const {id} = useParams();
    const allCheckRef = useRef(null); // 체크박스 컴포넌트에 대한 ref
    const dispatch = useDispatch();

    const {
        roundPlayer,
        totalAmount,
        totalGreenFee,
        cartFee,
        expectOnsiteAmount,
        caddieFee,
        transactionMethod,
        isCartFeeIncluded
    } = useSelector((x) => x?.paymentBooking);
    const {isLoading} = useSelector((x) => x?.loading);

    // 예약하기 하단 모달에서 전달한 데이터
    const bookingParams = history?.location?.state;

    const {timeSheetId, courseId, paymentType, golfClubName, golfClubId} = bookingParams;

    const initialAlertValue = {
        open: false,
        title: '',
        contents: ''
    };
    // 취소 가능 기한 알림창
    const [open, setOpen] = useState(initialAlertValue);
    // 예약하려는 티타임 정보
    const [prepareRoundBooking, setPrepareRoundBooking] = useState({});
    //SnackBar 창 open state
    const [snackBarOpen, setSnackBarOpen] = useState({
        open: false,
        msg: ''
    });

    // 체크박스 상태관리
    const [allChecked, setAllChecked] = useState(false);
    const [checkList, setCheckList] = useState([
        {
            title: golfClubName + ' 취소/위약 규정',
            type: 'GOLF_CLUB_AGREE',
            msg: '골프장 취소/위약규정 동의',
            checked: false,
            require: true
        },
        {
            title: '개인정보 제3자 제공 동의',
            type: 'BOOKING_PRIVACY',
            msg: '개인정보 제3자 제공 동의',
            checked: false,
            require: true
        }
    ]);

    const redirectFieldDetail = () => {
        history.navigate(-1);
    };

    // 예약하려는 티타임 정보 조회
    const getPrepareRoundBooking = async () => {
        dispatch(loadingActions.showLoading());
        try {
            const prepareRoundBookingParams = {
                golfClubId: golfClubId,
                timeSheetId: timeSheetId
            };

            // view-reservaion event 추가
            await amplitudeEvents({
                eventName: viewTaxonomy.view_reservation,
                timeSheetId: timeSheetId,
                golfClubId: golfClubId
            });

            const postPrepareRoundBookingRes = await fetchWrapper.post(
                `/prepare/round-booking`,
                prepareRoundBookingParams
            );

            if (!(postPrepareRoundBookingRes?.code === SUCCESS_CODE) || !postPrepareRoundBookingRes?.data) {
                throw new Error('에약 전 정보 저장중 오류');
            }

            const prepareRoundBookingRes = await fetchWrapper.get(`/prepare/round-booking`);

            if (!prepareRoundBookingRes?.code === SUCCESS_CODE) {
                throw new Error('예약하려는 티타임 정보 조회 오류');
            }

            setPrepareRoundBooking(prepareRoundBookingRes?.data);
            /**
             * 취소 불가능 티타임 안내 팝업 표시 여부
             * 2023.07.19 백엔드 협의
             * isCancelPossibility : Boolean
             * true : 취소가능기한 존재 , false : 취소 가능기한 경과로 팝업 표시
             */
            prepareRoundBookingRes?.data?.isCancelPossibility
                ? setOpen(initialAlertValue)
                : setOpen({
                      open: true,
                      title: bookingCancel.cancel_possible_elapse_title,
                      contents: bookingCancel.cancel_possible_elapse_sub_text
                  });

            const roundPlayer = prepareRoundBookingRes?.data?.playerLimit || 4;

            await getPrepareRoundBookingTradeAmount(roundPlayer);
        } catch (error) {
            console.error(error);
            redirectFieldDetail();
        } finally {
            dispatch(loadingActions.hideLoading());
        }
    };

    // 예약하려는 티타임 가격 상세 조회
    const getPrepareRoundBookingTradeAmount = async (roundPlayer) => {
        try {
            await dispatch(paymentBookingActions.setInnerPeople({roundPlayer}));
            const {payload, error} = await dispatch(
                paymentBookingActions.getPrepareRoundBookingTradeAmount(roundPlayer)
            );

            if (error || !payload) {
                throw new Error('예약하려는 티타임의 가격 상세 정보 조회 중 에러');
            }
        } catch (error) {
            console.error(error);
            redirectFieldDetail();
        }
    };

    // 예약 가능여부 조회
    const getBookingPossibility = async () => {
        const {courseId, timeSheetId, golfClubId} = prepareRoundBooking;

        const querystring = qsUtil.stringifyQueryString({golfClubId, courseId, timeSheetId});

        return await fetchWrapper.get(`/booking/possibility${querystring}`, null);
    };

    // 예약 성공 이후 예약 알림톡 호출
    const smsRoundBooking = async (roundBookingId) => {
        await fetchWrapper.post(`/sms/round-booking`, {roundBookingId: roundBookingId});
    };

    // 이벤트별 앰플리튜드 분기
    const amplitudeEvents = async (payload) => {
        const {eventName, bookingId, timeSheetId, golfClubId} = payload;

        if (!payload) {
            return;
        }

        if (eventName === viewTaxonomy.view_reservation) {
            await fetchWrapper
                .get(`/amplitude-events/view-reservation?golfClubId=${golfClubId}&timeSheetId=${timeSheetId}`)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        viewReservation(response.data, getAmplitudeRootPath());
                    }
                });
        } else {
            const clickDateResponse = await fetchWrapper
                .get(
                    `/amplitude-events/click-date?roundDate=${moment(prepareRoundBooking?.roundAt).format(
                        'YYYY-MM-DD'
                    )}`
                )
                .then((response) => response.code === SUCCESS_CODE && response.data);

            const amplitudeEventProperty = {
                root: getAmplitudeRootPath(),
                people: roundPlayer,
                total_prepayment_amount: appUtils.numberFormat(totalAmount),
                total_green_fee: appUtils.numberFormat(totalGreenFee),
                cart_fee: isCartFeeIncluded
                    ? paymentInfoProperty.cartFee_included_in_greenFee
                    : appUtils.numberFormat(cartFee),
                total_fieldpayment_amount: appUtils.numberFormat(expectOnsiteAmount),
                caddie_fee: appUtils.numberFormat(caddieFee),
                payment_method: paymentType === paymentConstant.paymentType.ONSITE ? '' : transactionMethod,
                buffer_period: clickDateResponse?.buffer_period,
                day_of_the_week: clickDateResponse?.day_of_the_week,
                day_type: clickDateResponse?.day_type
            };

            if (eventName === clickTaxonomy.click_reservation_btn) {
                await fetchWrapper
                    .get(`/amplitude-events/view-reservation?golfClubId=${golfClubId}&timeSheetId=${timeSheetId}`)
                    .then((response) => {
                        if (response.code === SUCCESS_CODE) {
                            const data = {...response.data, ...amplitudeEventProperty};
                            clickReservationBtn(data);
                        }
                    });
            } else if (eventName === etcTaxonomy.reservation_complete) {
                await fetchWrapper
                    .get(`/amplitude-events/click-reservation-btn?roundBookingId=${bookingId}`)
                    .then((response) => {
                        if (response.code === SUCCESS_CODE) {
                            const data = {...response.data, ...amplitudeEventProperty};
                            reservationComplete(data);
                        }
                    });
            }
        }
    };

    // 하단 [예약,결제] 버튼 클릭
    const onClickPaymentBooking = async () => {
        dispatch(loadingActions.showLoading());

        /**
         * 1. allChecked :골프장 규정 및 개인정보 이용 전체동의 확인
         * 2. isPossibility : 예약 가능 확인 ( + 블랙리스트 여부 추가 )
         * 3. paymentConstant.paymentType.ONSITE : 현장결제 결제타입 확인
         */
        try {
            if (!allChecked) {
                const uncheckedItems = checkList.filter((item) => item.require && !item.checked);
                // 체크박스가 선택되지 않았고 대상 요소가 있으면
                setTimeout(() => {
                    allCheckRef.current?.scrollIntoView({block: 'start', behavior: 'smooth'});
                }, 100);

                return setOpen({
                    open: true,
                    title: uncheckedItems[0].title + '안내',
                    contents: uncheckedItems[0].title + '을 확인 하신 후 동의하셔야 예약이 가능합니다.'
                });
            }

            const bookingPossibilityResponse = await getBookingPossibility();

            if (!bookingPossibilityResponse?.code === SUCCESS_CODE) {
                throw new Error(bookingPossibilityResponse?.message);
            }
            // 예약 가능 여부 판단
            if (!bookingPossibilityResponse?.data?.isPossibility) {
                return setSnackBarOpen({
                    open: true,
                    msg: bookingPossibilityResponse?.data?.impossibleReason
                });
            }

            await amplitudeEvents({
                eventName: clickTaxonomy.click_reservation_btn,
                timeSheetId: timeSheetId,
                golfClubId: golfClubId
            });

            if (paymentType === paymentConstant.paymentType.ONSITE) {
                let bookingData = {};
                const roundBookingPayload = {
                    prepareRoundBookingId: prepareRoundBooking.id,
                    pgTradeNumber: null,
                    roundPlayerCount: roundPlayer
                };
                const roundbookingResponse = await fetchWrapper.post('/round-booking', roundBookingPayload);

                // 예약 실패
                if (
                    !roundbookingResponse?.data?.isBookingComplete ||
                    roundbookingResponse?.code === bookingConstant.roundBookingResultCode.FAIL
                ) {
                    bookingData = {
                        eventName: viewTaxonomy.view_reservation_fail,
                        type: 'booking',
                        code: roundbookingResponse?.code,
                        message: roundbookingResponse?.message,
                        data: {
                            ...bookingParams,
                            roundPlayer: roundPlayer,
                            courseName: prepareRoundBooking?.courseName,
                            adminHole: prepareRoundBooking?.adminHole,
                            roundAt: prepareRoundBooking?.roundAt,
                            expectOnsiteAmount: expectOnsiteAmount,
                            totalGreenFee: totalGreenFee,
                            cartFee: cartFee,
                            caddieFee: caddieFee,
                            transactionMethod: transactionMethod
                        }
                    };

                    history.navigate('/payment/booking-fail', {state: bookingData});
                }
                // 현장 결제 예약 성공
                else {
                    const reservationComplete = etcTaxonomy.reservation_complete;
                    const roundbookingSuccessResponse = await fetchWrapper.get(
                        `/round-booking/${roundbookingResponse?.data?.bookingId}`
                    );

                    await smsRoundBooking(roundbookingResponse?.data?.bookingId);

                    await amplitudeEvents({
                        eventName: reservationComplete,
                        bookingId: roundbookingResponse?.data?.bookingId
                    });

                    bookingData = {
                        eventName: reservationComplete,
                        type: 'booking',
                        code: roundbookingSuccessResponse?.code,
                        message: roundbookingSuccessResponse?.message,
                        data: roundbookingSuccessResponse?.data
                    };

                    history.navigate('/payment/booking-success', {
                        state: bookingData
                    });
                }
            } else {
                pay('card');
            }
        } catch (error) {
            console.log(error);
            setOpen({
                open: true,
                title: '',
                contents: error.message && '에러가 발생했습니다'
            });
        } finally {
            dispatch(loadingActions.hideLoading());
        }
    };

    /* 전자결제 연동 */
    const pay = () => {
        const initData = init();

        SETTLE_PG.pay(initData, function (rsp) {});
    };

    const init = (type = 'card') => {
        const {
            REACT_APP_HECTO_MERCHANT_ID,
            REACT_APP_HECTO_HASH_KEY,
            // REACT_APP_HECTO_ENCRYPT_KEY,
            REACT_APP_HECTO_BASE_URL,
            REACT_APP_HECTO_MCHTNAME,
            REACT_APP_HECTO_MCHTENAME,
            // REACT_APP_CONN_TIMEOUT,
            // REACT_APP_READ_TIMEOUT,
            REACT_APP_PAYMENT_REDIRECT_NOTICE_URI,
            REACT_APP_PAYMENT_REDIRECT_NEXT_URI
        } = process.env;

        const {gbNo, golfClubShortName, bookerMemberId, bookerName, roundAt, id} = prepareRoundBooking;

        const currDate = new Date();
        const year = currDate.getFullYear().toString();
        const month = ('0' + (currDate.getMonth() + 1)).slice(-2).toString();
        const day = ('0' + currDate.getDate()).slice(-2).toString();
        const hours = ('0' + currDate.getHours()).slice(-2).toString();
        const mins = ('0' + currDate.getMinutes()).slice(-2).toString();
        const secs = ('0' + currDate.getSeconds()).slice(-2).toString();

        const tradeDate = year + month + day;
        const tradeTime = hours + mins + secs;

        // 결제 상품명 : 골프장명(약어)티타임일시[yyyy.mm.dd(요일)hh:mm)]
        const pmtPrdtNm = golfClubShortName + moment(roundAt).format('YYYY.MM.DD(dd) HH:mm');
        const method = type;

        // Hash Key : 상점아이디 + 결제수단 + 상점주문번호 + 요청일자 + 요청시간 + 거래금액(평문) + 해쉬키
        const hashPlainText =
            REACT_APP_HECTO_MERCHANT_ID +
            method +
            gbNo +
            tradeDate +
            tradeTime +
            String(totalAmount) +
            REACT_APP_HECTO_HASH_KEY;

        /* 가상예약번호, 내장인원 수 쿼리스트링으로 전달*/
        const mchtParam = qsUtil.stringifyQueryString(
            {prepareRoundBookingId: id, roundPlayerCount: roundPlayer},
            false
        );

        const initData = {
            env: REACT_APP_HECTO_BASE_URL,
            mchtId: REACT_APP_HECTO_MERCHANT_ID,
            // 고정값
            method: method,
            // yyyyMMdd
            trdDt: tradeDate,
            // HH24MISS
            trdTm: tradeTime,
            // 예약주문번호 gbNo
            mchtTrdNo: gbNo,
            // 상점한글명
            mchtName: REACT_APP_HECTO_MCHTNAME,
            // 상점영문명
            mchtEName: REACT_APP_HECTO_MCHTENAME,
            // 결제상품명
            pmtPrdtNm: pmtPrdtNm,
            // 거래금액
            trdAmt: cryptoUtil.encryptWithAES256(String(totalAmount)),
            // 결과처리 URL(결제 후 결과 전달되는 페이지의 URL)
            notiUrl: REACT_APP_PAYMENT_REDIRECT_NOTICE_URI,
            // 결과화면 URL
            nextUrl: REACT_APP_PAYMENT_REDIRECT_NEXT_URI,
            // 고객 강제 종료시 결과 전달 및 이동페이지 URL
            cancUrl: REACT_APP_PAYMENT_REDIRECT_NEXT_URI,
            // 상점고객 아이디
            mchtCustId: cryptoUtil.encryptWithAES256(bookerMemberId),
            // 고객명
            mchtCustNm: cryptoUtil.encryptWithAES256(bookerName),
            // SHA256 Hash Key
            pktHash: cryptoUtil.encryptWithSHA256(hashPlainText),
            // 결제를 위한 동적 파라미터 추가
            mchtParam: mchtParam,
            // 표시유형: popup, iframe, self, blank
            ui: {
                type: 'self'
            }
        };

        return initData;
    };

    useEffect(() => {
        getPrepareRoundBooking();
    }, []);

    return (
        <Fragment>
            {isLoading && <div className="loading-overlay">{<Loading />}</div>}
            <Header back={true} pageTitle={bookingInfoProperty.booking_title} />
            <div id="payment-booking-wrapper">
                <div className="payment-booking-wrapper-inner">
                    <div className="payment-booking-wrapper-content">
                        {/* 예약 정보 */}
                        <PaymentBookingInfo paymentType={paymentType} prepareRoundBooking={prepareRoundBooking} />

                        {/* 결제 정보 */}
                        <PaymentBookingDetails
                            paymentType={paymentType}
                            prepareRoundBooking={prepareRoundBooking}
                            getPrepareRoundBookingTradeAmount={getPrepareRoundBookingTradeAmount}
                        />

                        {/* 예약자 정보 */}
                        <PaymentBookingUserInfo prepareRoundBooking={prepareRoundBooking} />

                        {/* 골프장 예약 공지 */}
                        <PaymentBookingNotice prepareRoundBooking={prepareRoundBooking} />

                        {/* 골프장 이용 규정 및 개인정보 동의 */}
                        <PaymentBookingPrivacyConsent
                            ref={allCheckRef}
                            prepareRoundBooking={prepareRoundBooking}
                            allChecked={allChecked}
                            setAllChecked={setAllChecked}
                            checkList={checkList}
                            setCheckList={setCheckList}
                            golfClubName={golfClubName}
                        />
                    </div>
                </div>

                {/*법적고지*/}
                <div className="payment-booking-legal-notice">
                    <h2>{bookingInfoProperty.booking_legal}</h2>
                    <p>{bookingInfoProperty.booking_legal_notice}</p>
                </div>

                {/*결제하기 */}
                <div className="payment-booking-btn-area">
                    <Button
                        type="button"
                        delay={500}
                        className="btnPrimary"
                        // disabled={!allChecked}
                        onClick={onClickPaymentBooking}
                    >
                        {paymentType === paymentConstant.paymentType.ONSITE
                            ? btn.booking_onsite_btn
                            : `${appUtils.amountFormat(totalAmount)} ${btn.payment_btn}`}
                    </Button>
                </div>
            </div>

            <DefaultAlert
                open={open.open}
                handleClose={() => setOpen(initialAlertValue)}
                title={open.title}
                contents={open.contents}
            />

            <DirectionSnackbar
                direction={'up'}
                position={'bottom'}
                open={snackBarOpen.open}
                msg={snackBarOpen.msg}
                duration={2000}
                setOpen={setSnackBarOpen}
            />
        </Fragment>
    );
};

export default PaymentBooking;
