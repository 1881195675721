import React, {useState} from 'react';
import Header from '../Header';
import Navigation from '../navigation/Navigation';
import {Swiper, SwiperSlide} from 'swiper/react';
import './smartRound_02.scss';
import {Link} from 'react-router-dom';

function SmartRoundGuide() {
    const smartRoundGuide = {
        ready: [
            {
                title: '동반자 초대하기',
                content: '함께 라운드 가는 동반자를 초대해 보세요! 카카오톡 친구, 주소록 친구 정보를 불러올 수 있어요.',
                imgName: 'partner'
            },
            {
                title: '코스가이드 확인하기',
                content:
                    '라운드 예정 골프장의 홀별 코스정보를 미리 확인하세요! 야디지 정보와 코스 공략가이드의 도움을 받아보세요.',
                imgName: 'course'
            },
            {
                title: '음식메뉴 미리보기',
                content:
                    '클럽하우스와 그늘집에서 즐길 수 있는 음식메뉴를 미리 체크하세요! 카테고리별 주문가능한 음식 정보를 볼 수 있어요.',
                imgName: 'food'
            }
        ],
        today: [
            {
                title: '셀프 체크인',
                content:
                    '모바일로 쉽고 빠르게 체크인 해보세요! 자동으로 락커키 발급과 캐디 배치 현황을 바로 확인 할 수 있어요.',
                imgName: 'checkin'
            },
            {
                title: '스코어 관리',
                content: '오늘 라운드 스코어를 확인하고 기록해 보세요! 지난 라운드 기록과 비교해 볼 수 있어요.',
                imgName: 'ready'
            }
            // {
            //     title: '후불 정산',
            //     content: '라운드 비용 정산도 후불 결제 가능해요! 동반자들과 1/N 정산하고, 정산 내용을 공유해 보세요.',
            //     imgName: 'ready'
            // }
        ]
    };

    const [activeSlide, setActiveSlide] = useState('');

    const onSwiper = (e) => {
        setActiveSlide(e.realIndex);
    };
    const onSlideChange = (e) => {
        setActiveSlide(e.realIndex);
    };

    return (
        <>
            <div id="contents" className="smartRoundGuideBg">
                <div id="smartRoundGuide">
                    <Header back={true} pageTitle="스마트라운드 가이드" />
                    <div className="contentsArea">
                        <div className="section">
                            <div className="ttArea">
                                <h2>아직 라운드 전 인가요?</h2>
                                <p>스마트라운드로 미리 준비해보세요!</p>
                            </div>
                            <div className="guideCon">
                                <div className={`guideSwiperWrap swiperActive-${activeSlide}`}>
                                    <Swiper
                                        loop={true}
                                        pagination={{
                                            el: '.my-custom-pagination-1',
                                            clickable: true
                                        }}
                                        className="smartRoundGuideSwiper"
                                        onSwiper={onSwiper}
                                        onSlideChange={onSlideChange}
                                    >
                                        {smartRoundGuide.ready.map((data, idx) => {
                                            return (
                                                <SwiperSlide key={idx} className="list">
                                                    <div className="guideTxt">
                                                        <strong className="tit">{data.title}</strong>
                                                        <p>{data.content}</p>
                                                    </div>
                                                    <img
                                                        src={`/images/smart-round/smartRoundGuide_${data.imgName}.png`}
                                                        alt={data.title}
                                                    />
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                                <div className="my-custom-pagination-1"></div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="ttArea">
                                <h2>라운드 당일 인가요?</h2>
                                <p>스마트라운드로 즐거운 라운드 되세요!</p>
                            </div>
                            <div className="guideCon">
                                <div className="guideSwiperWrap">
                                    <Swiper
                                        loop={true}
                                        pagination={{
                                            el: '.my-custom-pagination-2',
                                            clickable: true
                                        }}
                                        className="smartRoundGuideSwiper"
                                    >
                                        {smartRoundGuide.today.map((data, idx) => {
                                            return (
                                                <SwiperSlide key={idx} className="list">
                                                    <div className="guideTxt">
                                                        <strong className="tit">{data.title}</strong>
                                                        <p>{data.content}</p>
                                                    </div>
                                                    <img
                                                        src={`/images/smart-round/smartRoundGuide_${data.imgName}.png`}
                                                        alt={data.title}
                                                    />
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                                <div className="my-custom-pagination-2"></div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="ttArea">
                                <h2>간편한 예약정보 불러오기</h2>
                            </div>
                            <div className="guideCon">
                                <div className="guideWrap">
                                    <div className="callMyReserve">
                                        <div className="guideTxt">
                                            <div className="titWrap">
                                                <img
                                                    src="/images/smart-round/smart_round_guide_reserve_plus.png"
                                                    alt="예약정보 불러오기"
                                                    className="plusIcon"
                                                />
                                                <strong className="tit">예약정보 불러오기란?</strong>
                                            </div>
                                            <p>
                                                골프장 홈페이지 및 다른 예약 앱으로 예약한 라운드도{' '}
                                                <b>골라가 앱에서 예약정보 불러오기</b>로 셀프체크인이 가능해요.
                                            </p>
                                        </div>
                                        <div className="imgWrap">
                                            <img
                                                src="/images/smart-round/smart_round_guide_reserve.png"
                                                alt="예약정보 불러오기"
                                                className="calenderImg"
                                            />
                                        </div>
                                        <div className="btnArea">
                                            <Link to="/get-my-reservation" className="btnPrimary">
                                                서비스 시작하기
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Navigation />
        </>
    );
}

export default SmartRoundGuide;
