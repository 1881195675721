import React from 'react';
import Icon from '../../icons/Icon';

function RecentSearchBelowItem({searchListItem, onRemove, onClickRecentItem}) {
    return (
        <li className="searchList">
            <div className="txt" onClick={() => onClickRecentItem(searchListItem)}>
                <strong>{searchListItem.address}</strong>
                <span>{searchListItem.orginAddr}</span>
            </div>
            <div onClick={() => onRemove(searchListItem)}>
                <Icon name="close" width="12" height="12" fill="#848588" />
            </div>
        </li>
    );
}

export default RecentSearchBelowItem;
