import React, {useCallback, useEffect, useState} from 'react';
import Header from '../../../components/Header';
import TitleArea from '../../../components/common/TitleArea';
import Avatar from '../../../components/common/Avatar';
import Button from '../../../components/button/Button';
import ToolTip from '../../tooltip/Tooltip';
import {fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import {appUtils} from '../../../common/utils';
import {pageTitle} from '../../../common/messages';
import ScheduledDetailPay from './ScheduledDetailPay';
import FinishedDetailPay from './FinishedDetailPay';
import Dialog from '@mui/material/Dialog';
import DefaultAlert from '../../alert/DefaultAlert';

function BookingDetail({detailOpen, detailClose, bookingId, scheduledStatus, finishedStatus, status}) {
    // 데이터
    const [bookingDetail, setBookingDetail] = useState(null); // booking Detail 데이터
    const [bookingMemberList, setBookingMemberList] = useState([]); // booking memberList 데이터
    const [hostInfo, setHostInfo] = useState(null); // 라운드 참석자에 있는 host

    // 예약 취소 알림창
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const getBookingDetail = () => {
            fetchWrapper.get(`/booking/detail/${bookingId}?bookingStatus=${status}`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    // 디테일 페이지 데이터
                    setBookingDetail(response.data?.booking);

                    // 라운드 참석자 데이터
                    let memberList = response.data?.bookingMemberList;

                    // 라운드 참석자 중 호스트 찾기
                    let hostInfo = null;
                    memberList.forEach((v) => {
                        if (v.host) {
                            hostInfo = v;
                            // return;
                        }
                        setHostInfo(hostInfo);
                        setBookingMemberList(memberList);
                    });
                }
            });
        };
        getBookingDetail();
    }, []);

    // 전화번호 하이픈 적용
    const phoneNumber = hostInfo?.phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');

    return (
        <>
            {/* 레이어 팝업 */}
            <Dialog fullScreen open={detailOpen} onClose={detailClose} className="fullPageModal">
                <div className="layerPop">
                    <div className="layerHeader">
                        <div className="inner">
                            <button type="button" className="btnClose" onClick={detailClose}>
                                닫기
                            </button>
                        </div>
                    </div>
                    <div className="layerContents">
                        <section className="section">
                            <TitleArea
                                pageTitle={
                                    scheduledStatus === true
                                        ? pageTitle.scheduledRoundDetail
                                        : finishedStatus === false
                                        ? pageTitle.finishedRoundDetail
                                        : ''
                                }
                            />
                            <div className="rezInfoBox">
                                <div className="ttArea">
                                    <strong className="tt">{bookingDetail?.golfClubName}</strong>
                                </div>
                                <div className="detailList">
                                    <dl>
                                        <dt>예약번호</dt>
                                        <dd>{bookingDetail?.bookingNo}</dd>
                                    </dl>
                                    <dl>
                                        <dt>날짜</dt>
                                        <dd>{appUtils.dateFormatTypeFull(bookingDetail?.roundAt)}</dd>
                                    </dl>
                                    <dl>
                                        <dt>코스명</dt>
                                        <dd>{bookingDetail?.course}</dd>
                                    </dl>
                                </div>
                            </div>
                            <div className="playerList">
                                <strong className="tt">라운드 참석자({bookingMemberList?.length}인)</strong>
                                <div className="avatarWrap">
                                    <ul className="listCon">
                                        {bookingMemberList
                                            ? bookingMemberList.map((data, idx) => {
                                                  return (
                                                      <li key={idx}>
                                                          <Avatar
                                                              src={data?.profileImageURL}
                                                              name={data?.name}
                                                              gender={data?.gender}
                                                              golagaMember={data?.golagaMember}
                                                              data={data}
                                                              head={data?.host}
                                                          />
                                                      </li>
                                                  );
                                              })
                                            : ''}
                                    </ul>
                                </div>
                            </div>
                        </section>
                        <div className="gap"></div>
                        <section className="section">
                            <div className="infoType01">
                                <div>
                                    <dl>
                                        <dt>예약자</dt>
                                        <dd>
                                            {hostInfo?.name} ({phoneNumber})
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>내장 조건</dt>
                                        <dd>4인 필수</dd>
                                    </dl>
                                    <dl>
                                        <dt>
                                            {scheduledStatus === true
                                                ? '취소 가능 기한' + ' '
                                                : finishedStatus === false
                                                ? '결제 완료 날짜'
                                                : ''}
                                            {scheduledStatus === true && (
                                                <ToolTip
                                                    placement="top"
                                                    contents="예약 취소 및 위약금 규정 안내는 예약하신 골프장에 문의바랍니다."
                                                />
                                            )}
                                        </dt>
                                        <dd>
                                            {scheduledStatus === true
                                                ? `${appUtils.dateFormatTypeBooking(
                                                      bookingDetail?.cancelAbleDue
                                                  )} 17:00`
                                                : finishedStatus === false
                                                ? appUtils.dateFormatTypeFull(bookingDetail?.payCompleteAt)
                                                : ''}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                            {scheduledStatus === true && (
                                <div className="btnArea">
                                    <Button type="button" className="medium" lightGray onClick={handleOpen}>
                                        예약 취소
                                    </Button>
                                </div>
                            )}
                        </section>
                        {bookingDetail && scheduledStatus === true ? (
                            <ScheduledDetailPay data={bookingDetail.detailPay} />
                        ) : bookingDetail && finishedStatus === false ? (
                            <FinishedDetailPay data={bookingDetail.completePay} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </Dialog>

            <DefaultAlert
                open={open}
                handleClose={handleClose}
                title={'예약취소 안내'}
                contents={'추후 제공될 예정입니다.'}
            />
        </>
    );
}

export default BookingDetail;
