import React, {useState, useEffect, useCallback} from "react";
import GolfClubListSlider from "./GolfClubListSlider";
import {MapMarker, Map} from "react-kakao-maps-sdk";

function GolfClubMapList({golfClubData, searchDate}) {
    // floatingButton2 내에 할당해야하는 ref 값이 강제로 필요함
    // const scrollRef = useRef(null);
    // 위도, 경도 초기값
    let initialLat = golfClubData[0].clubLat;
    let initialLng = golfClubData[0].clubLon;

    const [mapState, setMapState] = useState({
        center: {
            lat: initialLat,
            lng: initialLng
        }
    });

    const [markers, setMarkers] = useState([]);

    const changeMapCenter = useCallback(
        (x, y) => {
            setMapState({
                ...mapState,
                center: {
                    lat: x,
                    lng: y
                }
            });
        },
        [mapState]
    );

    // 마커 데이터 초기화
    useEffect(() => {
        if (golfClubData && golfClubData.length > 0) {
            const newMarkers = [];
            golfClubData.map((item) => {
                if (item.clubLat && item.clubLon) {
                    newMarkers.push({
                        position: {
                            lat: item.clubLat,
                            lng: item.clubLon
                        }
                    });
                }
            });
            setMarkers(newMarkers);
        }
    }, [golfClubData]);

    // 데이터 변경시 위도, 경도 변경
    useEffect(() => {
        setMapState({
            center: {
                lat: golfClubData[0].clubLat,
                lng: golfClubData[0].clubLon
            }
        });
    }, [golfClubData]);

    return (
        <>
            <div className="golClubMapSection">
                {/*지도 영역*/}
                <div className="mapArea" id="map">
                    <Map
                        center={mapState.center}
                        isPanto={false}
                        level={3} // 지도의 확대 레벨
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        {
                            // 마커 표시
                            markers.length > 0 &&
                                markers.map((item, idx) => (
                                    <MapMarker
                                        key={idx}
                                        position={item.position}
                                    />
                                ))
                        }
                    </Map>
                </div>

                {/*리스트 영역*/}
                <div className="golfClubSliderWrap">
                    <GolfClubListSlider
                        golfClubData={golfClubData}
                        searchDate={searchDate}
                        changeMapCenter={changeMapCenter}
                    />
                </div>
            </div>
        </>
    );
}

export default GolfClubMapList;
