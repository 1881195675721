import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from 'react';
import Icon from '../../icons/Icon';
import GolfClubList from './GolfClubList';
import GolfClubMapList from './GolfClubMapList';
import {appUtils} from '../../../common/utils';

const FieldSection = forwardRef(
    (
        {
            bookingData,
            /*onLocationFilterOpen,*/
            golfClubData,
            searchDate,
            /*townName,*/
            golfTotalCnt,
            hasNext,
            setPage,
            contextFlag,
            pullToGolfListRefresh,
            isLoading,
            onSortModalOpen,
            setIsSnackBarOpen,
            onClickSelfCheckInBtn,
            deleteInterestItem,
            isMyPage,
            setGolfClubList
        },
        ref
    ) => {
        // 추천 골프장 > 리스트형 / 지도형 체크
        const [listType, setListType] = useState(true);
        // 스크롤 내 floating button 활성화 목적
        const scrollRef = useRef(null);

        // 스크롤 최상단으로 이동
        function goToScrollTop() {
            const scrollViewport = scrollRef.current;
            scrollViewport.scrollTop = 0;
        }

        //console.log(isLoading);

        useImperativeHandle(ref, () => ({
            goToScrollTop
        }));

        return (
            <Fragment>
                {!(contextFlag === 'mypage-favorites') && (
                    <div className="filterInfoArea">
                        <div className="tt leftContent">
                            {contextFlag === 'main' ? '추천 골프장' : '검색결과'}{' '}
                            <span className={'num'}>{appUtils.numberFormat(golfTotalCnt)}</span>
                        </div>
                        <div className="rightContent">
                            <button type="button" className="sortIcon" onClick={onSortModalOpen}>
                                <Icon name="sort" width="18px" height="18px" fill="#3906C3" viewBox="0 0 18 18" />
                            </button>
                        </div>
                        {/*<div className="filterBtnArea">*/}
                        {/*<button type="button" className="btnLocation" onClick={onLocationFilterOpen}>
                                <Icon name="gps_02" width="13px" height="13px" /> {townName}
                            </button>*/}
                        {/* 지도보기 임시주석, 목록보기만 활성화*/}
                        {/* {listType ? (*/}
                        {/*    <button*/}
                        {/*        type="button"*/}
                        {/*        className="btnListStyle"*/}
                        {/*        onClick={() => setListType(false)}*/}
                        {/*    >*/}
                        {/*        <Icon name="map" width="16" height="16" /> 지도보기*/}
                        {/*    </button>*/}
                        {/*) : (*/}
                        {/*    <button*/}
                        {/*        type="button"*/}
                        {/*        className="btnListStyle"*/}
                        {/*        onClick={() => setListType(true)}*/}
                        {/*    >*/}
                        {/*        <Icon name="list" width="16" height="16" /> 목록보기*/}
                        {/*    </button>*/}
                        {/*)}*/}
                        {/*</div>*/}
                    </div>
                )}

                {listType ? (
                    // 추천 골프장 > 리스트형
                    <GolfClubList
                        bookingData={bookingData}
                        golfClubData={golfClubData}
                        searchDate={searchDate}
                        ref={scrollRef}
                        setPage={setPage}
                        pullToGolfListRefresh={pullToGolfListRefresh}
                        contextFlag={contextFlag}
                        isLoading={isLoading}
                        hasNext={hasNext}
                        setIsSnackBarOpen={setIsSnackBarOpen}
                        onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                        deleteInterestItem={deleteInterestItem}
                        isMyPage={isMyPage}
                        setGolfClubList={setGolfClubList}
                    />
                ) : (
                    // 추천 골프장 > 지도형
                    <GolfClubMapList golfClubData={golfClubData} searchDate={searchDate} />
                )}
            </Fragment>
        );
    }
);

export default FieldSection;
