import {paymentConstant} from 'common/payment';

const {paymentType, hectoOutResultCode} = paymentConstant;

export const paymentInfoProperty = {
    /* 결제 내역 상세 */
    payment_detail_title: '결제내역 상세',
    payment_detail: '결제내역',

    /* 결제 관련 텍스트 */
    payment_infomation: '결제 정보',
    payment_type: '결제 방식',
    payment_date: '결제일시',
    payment_person_name: '결제자명',
    payment_method: '결제 수단',
    payment_card_classification: '카드 구분',
    payment_card_number: '카드 번호',
    payment_cancel_amount: '결제 취소 금액',
    payment_amount: '결제 금액',
    payment_all_amount: '총 결제 금액',
    payment_deposit: '결제한 예약금',

    payment_predict_amount: '결제 예정 금액',

    payment_all_payment_amount_caddiefee: '* 총 결제 금액은 ‘캐디피’ 불포함입니다.',

    payment_non_caddiefee: '* 결제 예정 금액은 ‘캐디피’ 불포함 금액입니다.',

    onsite_payment_predict: '현장 결제 예상 금액',
    all_payment_predict: '전체 예상 비용',
    deposit: '예약금',

    onsite_payment_greenFee: '현장 결제 그린피',
    onsite_greenFee_sub_text: '* 예약금을 제외한 현장에서 추가로 결제하실 그린피입니다.',
    greenFee: '그린피',
    cartFee: '카트피',
    caddieFee: '캐디피',
    totalGreenFee: '총 그린피',

    self_checkIn_promotion: '셀프체크인 프로모션 적용',
    self_checkIn_promotion_discount: '1만원 할인',

    /* 결제 상태코드에 따른 문구 변경 */
    payment_status_code: {
        [hectoOutResultCode.SUCCESS]: '요청이 성공적으로 처리되었습니다.',
        [hectoOutResultCode.CANCEL]: '예약 취소 접수 완료',
        [hectoOutResultCode.FAIL]: '결제를 완료하지 못했습니다'
    },

    /* 결제 타입에 따른 결제정보 타이틀 */
    payment_type_title: {
        [paymentType.PREPAY]: '총 결제 금액',
        [paymentType.RESERVE]: '결제할 예약금',
        [paymentType.ONSITE]: '현장 결제 예상 금액'
    },

    greenFee_golf_contact: '그린피 골프장 문의',

    /* 카트피가 그린피에 포함된 경우 */
    cartFee_included_in_greenFee: '그린피에 포함'
};
