import React, {useEffect, useState} from 'react';
import CardDetailT1 from './CardDetailT1';
import CardDetailT2 from './CardDetailT2';
import CardDetailT3 from './CardDetailT3';
import CardTitle from './CardTitle';

function Card({
    bookingData,
    bookingList,
    bookingType,
    roundStatus,
    status,
    roundAt,
    setMemberReset,
    InviteOpen,
    setInviteOpen,
    handleInviteOpen,
    onClickSelfCheckInBtn
}) {
    const cardDetail = () => {
        if (bookingType === 'round') {
            return (
                <CardDetailT1
                    bookingList={bookingList}
                    bookingData={bookingData}
                    setMemberReset={setMemberReset}
                    roundAt={roundAt}
                    InviteOpen={InviteOpen}
                    setInviteOpen={setInviteOpen}
                    handleInviteOpen={handleInviteOpen}
                />
            );
        } else if (bookingType === 'schdRound') {
            return (
                <CardDetailT2 bookingData={bookingData} scheduledStatus={roundStatus} status={status.toUpperCase()} />
            );
        } else if (bookingType === 'cmpltRound') {
            // 완료 타입을 지정해줬기 때문에 status 변경됨
            // setStatus("POST");
            return (
                <CardDetailT3 bookingData={bookingData} finishedStatus={roundStatus} status={status.toUpperCase()} />
            );
        }
    };

    return (
        <div className="cardList02">
            <div className="innerSection">
                <CardTitle bookingData={bookingData} onClickSelfCheckInBtn={onClickSelfCheckInBtn} />
                {cardDetail()}
            </div>
        </div>
    );
}

export default Card;
