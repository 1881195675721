/*
 *  root 호출 정의
 *
 * */

export const rootTaxonomy = {
    home: 'home',
    search_favorite_tab: 'search_favorite_tab',
    search_popular_tab: 'search_popular_tab',
    search_preview: 'search_preview',
    search_result: 'search_result',
    sharing: 'sharing',
    favorite: 'favorite',
    near_recommendation: 'near_recommendation',
    reservation_detail_tab_upcoming: 'reservation_detail_tab_upcoming',
    reservation_detail_tab_past: 'reservation_detail_tab_past',
    reservation_detail_tab_cancel: 'reservation_detail_tab_cancel',
    smartround: 'smartround',
    info_reservation: 'info_reservation',
    cc_detail: 'cc_detail',
    home_bottom_banner: 'home_bottom_banner',
    myinfo: 'myinfo',
    reservation_details: 'reservation_details',
    myinfo_tooltip: 'myinfo_tooltip'
};
