import React, {useEffect, useState} from 'react';
import Icon from '../icons/Icon';
import Button from '../button/Button';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {authActions, store, userActions} from '../../_store';
import {useDispatch} from 'react-redux';
import {history} from '../../_helpers';
import {appUtils, localStorageUtil} from '../../common/utils';
import AirbridgeUtil from '../../common/airbridgeUtil';
import {viewWithdrawalSuccess} from '../../_helpers/amplitude/events/view.events';

function WithdrawalComplete({title, titleTxt}) {
    const {height} = useWindowDimensions();
    const dispatch = useDispatch();
    //  로그아웃 페이지 유무
    const [logoutYn, setLogoutYn] = useState(history?.location);
    const onClick = () => {
        dispatch(userActions.initUser());
        dispatch(authActions.initToken());
        dispatch(authActions.logout());
    };

    useEffect(() => {
        dispatch(userActions.initUser());
        dispatch(authActions.initToken());

        if (localStorageUtil.get('memberChannel')) {
            appUtils.openWebViewModalPostMsg('andBackOn');
        }

        if (logoutYn?.state?.flag === 'logout') {
            appUtils.openWebViewModalPostMsg('andBackOn');
        }
        return () => {
            // 만약 창에서 버튼을 클릭하지 않고 탈퇴만 처리한 경우 강제로 지워줌
            appUtils.openWebViewModalPostMsg('andBackOff');
            localStorageUtil.remove('memberChannel');
        };
    }, [history?.location]);

    useEffect(() => {
        viewWithdrawalSuccess();
        const leave = () => store.dispatch(authActions.leave());
        leave();
        AirbridgeUtil.event.withdrawal();
    }, []);

    return (
        <div id="contents">
            <div className="completeArea" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <div className="completeMsg">
                        <i className="icon">
                            <Icon name="logout" width="60px" height="60px" />
                        </i>
                        <strong className="tt">{title}</strong>
                        <p className="txt">{titleTxt}</p>
                    </div>

                    <div className="btnFixedBottom">
                        <div className="inner">
                            <Button type="button" onClick={onClick}>
                                확인
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WithdrawalComplete;
