export const bookingCancel = {
    /* 예약 취소 접수 완료 */
    booking_cancel_receipt_complete_title: '예약 취소 접수를 완료하였습니다.',
    booking_cancel_complete_title: '예약 취소를 완료하였습니다.',

    booking_cancel_receipt_complete_sub_text:
        '취소 확정 시, 골라가 알림톡으로 안내해드리겠습니다. \n자세한 내용은 고객센터(1600-1552)로 문의하여 주시기 바랍니다.',

    booking_cancel_complete_sub_text:
        '알림톡을 못 받으셨거나 취소를 확인하실 수 없다면, 고객센터(1600-1552)로 문의주시기 바랍니다',

    /* 예약 취소 접수 실패 */
    booking_cancel_receipt_fault: '예약 건이 존재하지 않아 취소를 진행할 수 없습니다.',

    booking_cancel_infomation: '취소 정보',
    booking_cancel_receipt_date: '취소 접수 일시',
    booking_cancel_complete_date: '취소 완료 일시',
    booking_cancel_reason: '취소 사유',

    booking_cancel_amount: '취소 금액',

    cancel_confirm_title: '예약을 취소하시겠어요?',

    cancel_possible: '취소 가능 기한',
    cancel_possible_sub_text: '* 기한 경과 후 취소 시, 위약금 및 패널티가 발생합니다.',

    cancel_possible_contract: '* 취소 가능 기한 이후에 예약 취소 시 위약금 및 페널티가 발생할 수 있습니다.',

    /* 취소 가능 기한 타이틀 문구 */
    cancel_possible_elapse_title: '취소 불가 티타임',
    cancel_possible_elapse_sub_text:
        '해당 티타임은 예약 취소 시 위약금이 발생할 수 있습니다. 예약 시 참고 부탁 드립니다.',
    booking_cancel_detail: '예약 취소 상세',
    cancel_receipt: '취소 접수'
};
