import React from "react";
import {alertActions} from "_store/alert.slice";
import {useDispatch} from "react-redux";

export const AlertUtils = () => {
    const dispatch = useDispatch();

    return {
        show: (type, title, message) => {
            dispatch(
                alertActions.showAlert({
                    type: type,
                    messageData: {
                        title: title,
                        message: message
                    }
                })
            );
        }
    };
};
