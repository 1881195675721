import React from 'react';
import CourseInfo from './CourseInfo';
import SkeletonCourseCon from '../skeleton/SkeletonCourseCon';
import {useSelector} from 'react-redux';
import './course.scss';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import {viewCCNotice} from '../../_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';
import {clickIntroCourseguide} from '../../_helpers/amplitude/events/click.event';

function CourseCon({data, golfClubId, golfClubName, open, handleAlertOpen, mapControl, handicapDisplayYN}) {
    /*const exeDeepLink = (url) => {
        if (!url.trim().length) return;
        window.location.href = url;
    };*/
    const member = useSelector((x) => x?.users?.users);

    // console.debug(`member`, member);

    const redireactApp = () => {
        if (!data?.isCourseGuideExposed) {
            handleAlertOpen('해당 골프장은\n코스 확인 기능을 지원하지 않습니다.');
            return;
        }

        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${golfClubId}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                clickIntroCourseguide(response.data, data.isCourseGuideExposed);
            }
        });

        /*exeDeepLink(
            `golaga://course-guide/${golfClubId}`
            // `golaga://course-guide?golfClub=${golfClubId}&course=${courseId}`
        );
        // checkInstallApp();*/
        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'goNavi',
                golfClubId,
                courseCode: data?.courseCode,
                teeingGround: member?.teeingGround,
                mapControlYN: mapControl,
                handicapDisplayYN: handicapDisplayYN
            })
        );
    };

    return (
        <>
            {/* title, info */}
            {data ? (
                <div className="courseCon">
                    <div className="ttArea">
                        <div className="golfClubTitle">{golfClubName}</div>
                    </div>
                    {/* 코스 상세 정보 */}
                    <CourseInfo data={data} open={open} />
                </div>
            ) : (
                <SkeletonCourseCon />
            )}

            {/* 버튼 */}
            <div className="btnArea">
                <button type="button" className="btnPrimary" onClick={redireactApp}>
                    홀별 코스 확인하기
                </button>
            </div>
        </>
    );
}

export default CourseCon;
