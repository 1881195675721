import React from 'react';
import './banner.scss';

const Banner = (props) => {
    const {
        layout = 'title-image', // title-image | image-title,
        type = '05', // 05 | 06 | 07 | 08
        bannerTitle = {}, // 배너 텍스트 정보
        src, // 배너 배경 이미지
        onClick = () => {}, // 배너 클릭 이벤트
        radius = false // 배너 외곽선 둥글게
    } = props;

    const {mainTitle, subTitle, titleType, keyword} = bannerTitle;

    const renderMainTitle = () => {
        if (titleType && keyword) {
            return mainTitle.split(keyword).reduce((prev, current, index) => {
                if (index !== 0) {
                    return [
                        ...prev,
                        <span key={keyword + index} className={`font-${titleType}`}>
                            {keyword}
                        </span>,
                        current
                    ];
                }
                return [...prev, current];
            }, []);
        }
        return mainTitle;
    };

    return (
        <div className={`banner type-${type} ${radius && 'banner-radius'}`} onClick={onClick}>
            {layout === 'image-title' && (
                <div className={`banner-image-wrapper type-${type} ${radius && 'banner-radius'}`}>
                    <img className={`banner-image-area`} src={src} />
                </div>
            )}
            <div className={`banner-title-wrapper type-${type}`}>
                {mainTitle && <span className="banner-title">{renderMainTitle()}</span>}
                {subTitle && <span className="banner-subtitle">{subTitle}</span>}
            </div>
            {layout === 'title-image' && (
                <div className={`banner-image-wrapper type-${type} ${radius && 'banner-radius'}`}>
                    <img className={`banner-image-area`} src={src} />
                </div>
            )}
        </div>
    );
};

export default Banner;
