import React from 'react';
import TitleArea from '../../../components/common/TitleArea';
import Header from '../../../components/Header';
import GetReservationInfoAgree from '../reservation/GetReservationInfoAgree';

function MyReservationAgreePop({title}) {
    return (
        <>
            <Header close={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="agreeCon">
                            <GetReservationInfoAgree />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyReservationAgreePop;
