import React from 'react';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {history} from '../../_helpers';
import './membership.scss';
import {authActions} from '../../_store';
import {useDispatch} from 'react-redux';
import Icon from '../icons/Icon';

function LeftMember(props) {
    const {title, titleTxt} = props;

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();
    const dispatch = useDispatch();

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
          type: 'kakao/unlink'
      })
    );

    //하단 버튼
    const nextPage = () => {
        history.navigate('/member/login', {replace: true});
        dispatch(authActions.logout());
    };
    return (
        <>
            <Header back={false} />
            <div className="completeArea" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <div className="completeMsg">
                        <i className="icon">
                            <Icon name="exclamationMark" width="60px" height="60px" viewBox="0 0 60 60" fill="none" />
                        </i>
                        <strong className="tt">{title}</strong>
                        <p className="txt">{titleTxt}</p>
                    </div>

                    <div className="btnFixedBottom">
                        <div className="inner">
                            <Button type="button" onClick={nextPage}>
                                확인
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LeftMember;
