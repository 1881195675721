import {useRef, useState} from 'react';
import './business-information.scss';
import {ENVIRONMENT} from 'common/constants';
import Button from 'components/button/Button';

const {REACT_APP_ENV} = process.env;

const BusinessInformation = () => {
    const [expanded, setExpanded] = useState(false);
    const businessInformationWrapper = useRef(null);

    const onClickExpanded = () => {
        setTimeout(() => {
            businessInformationWrapper &&
                businessInformationWrapper.current.scrollIntoView({block: 'start', behavior: 'smooth'});
        }, 100);

        setExpanded(!expanded);
    };

    const businessInformationData = [
        {label: '대표이사', content: '박준태'},
        {label: '사업자등록번호', content: '106-86-38206'},
        {label: '사업장소재지', content: '서울특별시 용산구 한강대로 98길 3, 10층'},
        {label: '통신판매업', content: '제2023-서울용산-1215 호'},
        {label: '고객센터', content: '1600-1552'}
    ];

    const onClickLink = () => {
        const url = 'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1068638206';
        if (window.ReactNativeWebView) {
            //앱 버전 RN에서 받아옴
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    inappurl: url,
                    type: 'openInappBrowser',
                    inapptitle: '골라가'
                })
            );
        } else {
            window.location.href = url;
        }
    };

    return (
        <div className="business-information-wrapper" ref={businessInformationWrapper}>
            <div className="business-information-about fw-medium">
                (주)그린잇 사업자 정보
                <button
                    type="button"
                    className={`btn-detail ${expanded ? 'expanded' : ''}`}
                    onClick={onClickExpanded}
                />
            </div>
            {expanded && (
                <div className="business-information-container">
                    {businessInformationData.map((obj) => {
                        return (
                            <div key={obj.label} className="business-information-info">
                                <span className="business-information-label fw-medium">{obj.label}</span>
                                <span className="business-information-content fw-regular">{obj.content}</span>
                            </div>
                        );
                    })}
                    <button className="business-information-link" onClick={onClickLink}>
                        사업자 정보 확인
                    </button>
                    {/* development 전용 강제 에러 */}
                    {REACT_APP_ENV === ENVIRONMENT.DEVELOPMENT ? (
                        <Button
                            style={{width: '15%'}}
                            onClick={() => {
                                throw new Error('Intentional error');
                            }}
                        >
                            ERROR TEST
                        </Button>
                    ) : null}
                </div>
            )}
            <div className="business-information-copyright fw-regular">
                Copyright ⓒ GREENIT Co., Ltd. All right reserved
            </div>
        </div>
    );
};

export default BusinessInformation;
