import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchWrapper} from '../_helpers';
import {SUCCESS_CODE} from '../common/constants';
// import { localStorageUtil } from "../common/utils";

const name = 'checkin';
const initialState = {
    smartRoundCheckIn: false,
    todayReserv: false,
    todayReservData: {
        introUrl: '',
        golfClubName: '',
        bookingId: '',
        golfClubId: '',
        areaInfo: '',
        title: '',
        roundAt: '',
        courseName: ''
    },
    rendering: true,
    root: {
        eventPage: false,
        mainContents: false,
        firstRender: false
    },
    promotionToast: false
};

export const getTodayBooking = createAsyncThunk('getTodayBooking', async () => {
    // const response = await fetchWrapper.get("/booking/days-before?day=0");
    const response = await fetchWrapper.get('/booking/alert/0');
    if (response.code === SUCCESS_CODE) {
        if (response?.data) {
            return response?.data;
        }
    }
    return false;
});

const setTodayReserv = (state, action) => {
    state.todayReserv = action.payload;
    state.rendering = false;
    // debugger;
    if (action.payload) {
        // 당일 예약 존재 && 코스가이드
        //셀프체크인 활성화
        state.todayReservData.title = action.payload?.introDesc;
        state.todayReservData.areaInfo = action.payload?.address;
        state.todayReservData.golfClubId = action.payload?.golfClubId;
        state.todayReservData.bookingId = action.payload?.bookingId;
        state.todayReservData.introUrl = action.payload?.videoClipUrl;
        state.todayReservData.golfClubName = action.payload?.golfClubName;
        state.todayReservData.roundAt = action.payload?.roundAt;
        state.todayReservData.courseName = action.payload?.courseName;
    } else {
        state.todayReservData.title = '';
        state.todayReservData.areaInfo = '';
        state.todayReservData.golfClubId = '';
        state.todayReservData.bookingId = '';
        state.todayReservData.introUrl = '';
        state.todayReservData.golfClubName = '';
        state.todayReservData.roundAt = '';
        state.todayReservData.courseName = '';
    }
};

const checkinSlice = createSlice({
    name,
    initialState,
    reducers: {
        showSmartRoundCheckIn: {
            reducer: (state, action) => {
                state.smartRoundCheckIn = action.payload;
            }
        },
        setPromotionToast: {
            reducer: (state, action) => {
                state.promotionToast = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTodayBooking.pending, (state) => {
                state.rendering = true;
            })
            .addCase(getTodayBooking.fulfilled, (state, action) => setTodayReserv(state, action))
            .addCase(getTodayBooking.rejected, (state, action) => {
                state.todayReserv = false;
                state.rendering = false;
            });
    }
});

export const checkinActions = {...checkinSlice.actions};
export const checkinReducer = checkinSlice.reducer;
