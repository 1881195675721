import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE} from '../common/constants';

const name = 'promotion';

const initialState = {
    promotionId: null, // 프로모션ID
    promotionDesc: '적용 가능한 프로모션이 없습니다.', // 프로모션 설명
    validStartDate: '', // 프로모션 적용 시작일자
    validEndDate: '', // 프로모션 적용 종료일자
    activeYn: 'N', // 적용여부
    displayStartDate: '', // 프로모션 게시 시작일자
    displayEndDate: '', // 프로모션 게시 종료일자
    displayYn: 'N', // 게시여부
    rendering: true,
    promotionCheckinModal: false // 프로모션 체크인 모달 표시 여부
};

// Reducer
const reducers = {
    setPromotionCheckinModal: {
        reducer: (state, action) => {
            state.promotionCheckinModal = action.payload;
        }
    }
};

// ExtraAction
const extraActions = {
    getPromotionApplicable: createAsyncThunk('getPromotionApplicable', async () => {
        const response = await fetchWrapper
            .get(`/${name}/applicable`)
            .catch((error) => console.log('프로모션 조회중 에러', error));

        if (response.code === SUCCESS_CODE) {
            return response.data;
        } else {
            // 성공코드가 아닌 경우 초기화 , 8401 포함
            return initialState;
        }
    })
};

const extraReducers = {
    getPromotionApplicable: () => {
        let {pending, fulfilled, rejected} = extraActions.getPromotionApplicable;
        return {
            [pending]: (state) => {
                state.rendering = true;
            },
            [fulfilled]: (state, action) => {
                const payloadData = action.payload;
                for (const key in payloadData) {
                    if (payloadData.hasOwnProperty(key)) {
                        state[key] = payloadData[key];
                    }
                }
                state.rendering = false;
            },
            [rejected]: (state, action) => {
                console.error(action.error);
                state.rendering = false;
            }
        };
    }
};

// ExtraReducer 등록
const createExtraReducers = () => {
    return {
        ...extraReducers.getPromotionApplicable()
    };
};

// create slice
const promotionSlice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: createExtraReducers()
});

// exports
export const promotionActions = {...promotionSlice.actions, ...extraActions};
export const promotionReducer = promotionSlice.reducer;
