export const errMsg = {
    USER_NAME_EMPTY: '이름을 입력해주세요',
    USER_NAME_MINLENGTH: '이름은 2~20자로 입력해주세요',
    USER_NAME_REG: '이름은 한글로 입력해주세요',
    USER_BIRTH_EMPTY: '주민등록번호를 입력해주세요',
    USER_BIRTH_GENDER_EMPTY: '주민등록번호 뒤 1자리를 입력해주세요',
    USER_BIRTH_MINLENGTH: '주민등록번호 앞 6자리를 입력해주세요',
    TELECOM_EMPTY: '통신사를 선택해주세요',
    AUTH_PHONE_AGREE: '휴대전화 본인인증 약관에 동의해주세요',
    USER_PHONE_EMPTY: '휴대전화번호를 입력해주세요',
    USER_PHONE_MINLENGTH: '휴대전화번호를 정확히 입력해주세요',
    USER_ID_EMPTY: '이메일 아이디를 입력하세요',
    USER_ID_REG: '이메일 형식으로 입력해주세요',
    USER_AUTH_TIME_OVER: '인증번호 입력시간을 초과했습니다',
    USER_ID_EXIST: '이미 사용중인 아이디입니다',
    USER_NAME_CHECK: '이름을 정확히 입력해주세요', // 자음 혹은 모음이 섞여있는 경우에 사용
    USER_BIRTH_UNDER_14: '만 14세 미만은 가입하실 수 없습니다.',
    USER_BIRTH_INVALID_DATE: '유효한 생년월일을 입력해주세요',
    USER_NOT_FOUND_INFO: '입력하신 정보와 일치하는 아이디가 없습니다.',
    USER_ID_WITHDRAWAL_CHECK:
        '해당 이메일 주소로 탈퇴한 이력이 있어 가입하실 수 없습니다.\n다른 이메일로 가입을 진행하세요.',
    WITHDRAWAL_NOT_POSSIBLE: '진행중인 예약 건이 있어 탈퇴가 불가합니다. \n예약 취소 또는 이행 후 다시 시도해주세요.',
    BAG_NAME_LENGTH: '백네임은 2~10자 이하로 입력해주세요.'
};

export const successMsg = {
    USER_ID_CHECK_OK: '사용 가능한 아이디입니다.',
    USER_AUTH_SUCCESS: '인증에 성공하였습니다.',
    USER_AUTH_TIME_RESET: '인증번호 입력 시간이 연장되었습니다.'
};

export const pageTitle = {
    company: '(주)그린잇',
    join: '회원가입을 위해 \n 본인인증을 진행해주세요',
    findId: '등록된 회원정보 인증 후 \n 아이디를 확인하세요',
    findPw: '휴대전화 본인인증 후 \n 비밀번호를 재설정하세요',
    authentication_common: '휴대전화번호로\n 본인인증을 진행해주세요',
    authNumber: '새로운 휴대전화 번호로\n 본인인증을 진행해주세요',
    joinProcess: '아이디와 비밀번호를 \n 설정해주세요',
    joinApple: '이메일 정보를 확인 후\n 회원약관에 동의해주세요',
    findIdResult: '고객님의 회원정보로 \n 등록된 아이디가 있습니다',
    findPwResult: '새로운 비밀번호로\n 설정 완료되었습니다!',
    authPw: '현재 사용중인 비밀번호를\n 입력해주세요',
    settingPw: '새로운 비밀번호를 \n 입력해주세요',
    SnsJoin: '내 이메일 계정에 \n 카카오 계정을 연결할게요',
    AppleJoin: '내 이메일 계정에 \n 애플 계정을 연결할게요',
    snsJoinResult: '계정 연동을 완료했어요!',
    withdrawal: '회원탈퇴 신청에 앞서\n아래 유의사항을 확인해주세요',
    myCard: '결제카드 관리',
    appBoard: '앱 공지사항',
    alarm: '알림',
    courseGuide: '보다 좋은 스코어를 위해 \n 코스 정보 확인은 필수겠죠?',
    getMyReservation: `나의 골프장 예약정보를\n한 곳에서 관리하세요!`,
    scheduledRound: '라운드 예정인 골프장 정보를 \n 미리 알아보고 가세요!',
    finishedRound: '라운드 완료한 골프장 정보를 \n 다시 확인해 보세요',
    smartRound: '예약한 골프장을 스마트 \n 라운드에서 미리 알아보세요!',
    scheduledRoundDetail: '예약된 라운드 상세 정보를 \n 확인해 보세요',
    finishedRoundDetail: '완료한 라운드 결제 내역을 \n 확인해 보세요',
    search: '검색',
    score: '스코어',
    bkgAgreement: '제휴 골프장 예약 정보 조회 및 \n 개인정보 수집 · 이용 동의',
    pushSetting: '푸시 설정',
    policy: '약관 및 정책',
    serviceAgree: '서비스 이용약관',
    personalInfo: '개인정보 수집 및 이용 동의',
    privacyPolicyOther: '개인정보 제3자 제공동의',
    marketingPolicy: '마케팅 정보 수신 동의',
    locationService: '위치기반 서비스 이용약관',
    appPermission: '앱 이용 권한 안내',
    shareSet: '오늘의 라운드\n이용내역이에요',
    ShareDetail: '비용을 정산하여\n공유해주세요',
    sharePayment: '결제하실 금액을\n확인하세요',
    SharePaymentResult: '결제가\n완료되었습니다!',
    openLicense: '오픈소스 라이센스 고시',
    reservationAgree: '예약정보 조회 · 전송동의',
    booking: '예약하기',
    bookingComplete: '예약완료',
    existingId: '이미 사용중인 아이디입니다.', // 카카오, Apple로 시작할때 이미 사용중인 이메일아이디인 경우
    withdrawnAccount: '다른 이메일로\n 가입을 진행해주세요',
    notFoundAccount: '등록된 회원정보가 없습니다',
    successConnection: '예약정보 연동이 완료되었습니다',
    withdrawalComplete: '탈퇴 완료되었습니다',
    linkedgc: '제휴 골프장',
    noPhoneUser: '원활한 서비스 이용을 위해\n 휴대폰 본인인증을 진행해주세요'
};
/** 페이지타이틀 내 서브타이틀 관리를 위해추가*/
export const subTitle = {
    memberJoinComplete: '골라가로 시작하는 즐거운 골프 라이프!',
    existingId: '다른 계정으로 회원가입을 진행해주세요',
    withdrawnAccount: '탈퇴된 계정은 재사용하실 수 없습니다',
    findIdResult: '아래 계정으로 로그인해주세요',
    notFoundAccount: '회원가입을 진행하신 후 서비스를 이용해주세요',
    successConnection: '셀프체크인부터 코스가이드까지\n' + '스마트한 골라가의 기능을 이용해보세요!',
    withdrawalComplete: '그동안 골라가를 이용해주셔서 감사합니다'
};

export const alertMsg = {
    introTit: '안내',
    deleteCard: {
        title: '간편결제 카드 삭제',
        message: '선택하신 카드를 삭제하시겠습니까?'
    }
};

// 좋아요 설정 시 / 해제 시 스낵바 노출 문구
export const likeMsg = {
    likeCreate: '즐겨찾는 골프장으로 등록 되었습니다.\n' + '‘내 정보’ 메뉴에서 확인하실 수 있습니다.',
    likeDelete: '즐겨찾는 골프장에서 해제 되었습니다.'
};
