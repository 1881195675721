import React, {useContext, useEffect, useState} from 'react';
import classNames from 'classnames';

function SelectItem({item, selectCategoryItem, filterCode, isFilterReset, filterFlag, filterCodeName}) {
    const [btnActive, setBtnActive] = useState(false);

    useEffect(() => {
        setBtnActive(false);
    }, [isFilterReset]);

    const onFilterClick = (e) => {
        e.preventDefault();

        setBtnActive(!btnActive);

        const isActive = e.target.dataset.isselect;

        selectCategoryItem(filterCode, e.target.dataset.code, e.target.dataset.name, isActive, e.target.dataset.id, e.target.dataset.filtercodename);
    };

    return (
        <li>
            <button
                className={`${item.isSelect === true ? 'on' : ''} btnList`}
                onClick={onFilterClick}
                data-code={item.code}
                data-name={item.codeName}
                data-isselect={item.isSelect}
                data-id={item.codeId}
                data-filtercodename={filterCodeName}
            >
                {filterFlag === 'ADVANCED' ? '# ' + item.codeName : item.codeName}
            </button>
        </li>
    );
}

export default SelectItem;
