import React, {useCallback, useEffect, useRef, useState} from 'react';
import FoodMenuList from './FoodMenuList';
import FoodResList from './FoodResList';
import styles from './food.module.scss';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import '../skeleton/skeleton.scss';
import 'swiper/css';
import SkeletonFoodMenuList from '../skeleton/SkeletonFoodMenuList';
import SkeletonTabButton from '../skeleton/SkeletonTabButton';
import {useLocation} from 'react-router-dom';
import clsx from 'clsx';
import DefaultAlert from '../alert/DefaultAlert';
import {viewFoodMenu} from '../../_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';
import Icon from '../../components/icons/Icon';
import {throttle} from 'lodash';

/**
 * 전체탭
 * @type {*[]}
 */
const categoryAll = {
    id: '',
    midCode: '',
    midName: '',
    smallCode: '',
    smallName: '전체',
    orderSeq: 0
};

//let golfClubId;
let isMenu;

function Food({usePath, golfClubIdParam, detailData, golfClubNameParam, isMenuApplied}) {
    const location = useLocation();

    // 클릭했을 때 주는 ID, Index 값
    const [currentRestIdx, setCurrentRestIdx] = useState(0); //선택된 식당 인덱스
    const [currentRestId, setCurrentRestId] = useState(null); //선택된 식당 ID
    const [currentCtgrIdx, setCurrentCtgrIdx] = useState(0); //선택된 카테고리 인덱스

    // 데이터
    const [restData, setRestData] = useState(null); // 상단 레스토랑 메뉴 데이터
    const [categoryData, setCategoryData] = useState([{...categoryAll}]); // 하단 카테고리 탭메뉴 데이터
    const [menuData, setMenuData] = useState(null); // 하단 카테고리 메뉴 리스트 데이터
    const [tabLoading, setTabLoading] = useState(true); // 탭 로딩 화면
    const [loading, setLoading] = useState(true); // 리스트 로딩 화면

    // 인피니티 스크롤
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [hasNext, setHasNext] = useState(false);
    const target = useRef(null);

    const [alertOpen, setAlertOpen] = useState(false);

    const [golfClubName, setGolfClubName] = useState('');

    const handleAlertOpen = () => setAlertOpen(true);
    const handleAlertClose = () => setAlertOpen(false);

    // 탭메뉴 클릭 핸들러
    const onHandleRestTab = (idx, id) => {
        // 레스토랑 리스트 탭메뉴
        setCurrentRestId(id);
        setCurrentRestIdx(idx);
        setCurrentCtgrIdx(0);
        setPage(0);
        /*  if (usePath !== rootTaxonomy.cc_detail) {
            window.scrollTo({
                top: 0
            });
        }*/
    };

    const onHandleMenuTab = (index) => {
        // 음식메뉴 탭메뉴
        setCurrentCtgrIdx(index);
        setPage(0);

        // const categorySection = document.getElementById('foodCategory');

        /* if (usePath !== rootTaxonomy.cc_detail) {
            window.scrollTo({
                top: categorySection.offsetTop - 100
            });
        }*/
    };

    // 상단 레스토랑 리스트
    const getRestList = useCallback(() => {
        fetchWrapper.get(`/restaurant/list/${golfClubIdParam}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                let restDataTemp = response?.data?.restaurants || null;
                setRestData(restDataTemp);

                if (restDataTemp) {
                    setCurrentRestId(response?.data?.restaurants[0].id);
                }
            }
        });
        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${golfClubIdParam}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                viewFoodMenu(response.data, getAmplitudeRootPath());
            }
        });
    }, [golfClubIdParam]);

    // 하단 카테고리 탭 리스트
    const getTabList = useCallback(() => {
        setTabLoading(true);

        fetchWrapper.get(`/restaurant/category/${currentRestId}`, null).then((response) => {
            setTabLoading(false);

            if (response.code === SUCCESS_CODE) {
                // categoryList에 데이터 넣기 (전체 버튼 객체 복사되지 않은)
                let categoryList = response?.data.restaurantCategorise || null;
                // categoryData State(초기값: 전체 버튼 객체 복사)에 전체 버튼 객체 넣고
                categoryList?.unshift(categoryAll);
                // 카테고리 데이터에 전체 버튼 들어간 데이터로 변경
                setCategoryData(categoryList);
            }
        });
    }, [currentRestId]);

    const getParamData = useCallback(() => {
        // golfClubId = golfClubIdParam;

        setGolfClubName(golfClubNameParam);
        isMenu = isMenuApplied;
    }, [golfClubIdParam, isMenuApplied]);

    // 레스토랑 리스트 데이터 요청 함수
    useEffect(() => {
        getParamData();
        getRestList();
    }, [golfClubIdParam, isMenuApplied]);

    //레스토랑 메뉴 변경할때
    useEffect(() => {
        if (currentRestId !== null) {
            getTabList();
        }
        /*getTabMenuList();*/
    }, [currentRestId]);

    //카테고리 변경할 때
    useEffect(() => {
        if (page === 0) {
            getTabMenuList();
        }
    }, [categoryData, currentCtgrIdx]);

    useEffect(() => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight && hasNext) {
            window.scrollBy({top: -100, behavior: 'auto'});
        }
    }, [currentCtgrIdx, hasNext]);

    // 하단 카테고리 탭메뉴 리스트
    const getTabMenuList = () => {
        setLoading(true);

        let categoryId = currentCtgrIdx === 0 ? '' : categoryData[currentCtgrIdx]?.id;

        //리스트 개수 나디아와 협의 : 20개
        fetchWrapper
            .get(`/restaurant/menu/${currentRestId}?categoryId=${categoryId}&page=${page}&size=${20}`, null)
            .then((response) => {
                setLoading(false);

                if (response.code === SUCCESS_CODE) {
                    let ctgrMenuList = response?.data?.restaurantMenus;

                    setHasNext(response?.data?.hasNext);

                    if (page === 0) {
                        setMenuData(ctgrMenuList || null);
                    } else {
                        setMenuData((prevLists) => [...prevLists, ...ctgrMenuList]);
                    }
                }
            });
    };

    const foodListRef = useRef(null);

    useEffect(() => {
        const handleScroll = throttle(() => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight && hasNext) {
                setPage((prev) => prev + 1);
            }
        }, [300]);

        window.addEventListener('touchstart', handleScroll, {passive: true});
        return () => {
            window.removeEventListener('touchstart', handleScroll);
        };
    }, [menuData, hasNext]);

    useEffect(() => {
        if (page > 0 && hasNext) {
            getTabMenuList();
        }
    }, [page, hasNext]);

    return (
        <>
            <div id="contents" className={detailData && 'food-ccDetail-contents'}>
                <div id="foodOrder" className="contentsArea">
                    {isMenu ? (
                        <div className={`tabType01 ${detailData && 'food-ccDetail'}`}>
                            {/* 레스토랑 이름 탭메뉴 */}
                            <FoodResList
                                restData={restData}
                                currentRestIdx={currentRestIdx}
                                menuData={menuData}
                                onHandleRestTab={onHandleRestTab}
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    <div id="foodCategory" className="section" ref={foodListRef}>
                        {/* ==== 탭메뉴 ==== */}
                        {isMenu ? (
                            <div
                                className={`${clsx(styles.foodTabArea, 'tabType02 fullWidth')} ${
                                    detailData && 'food-ccDetail-category'
                                }`}
                            >
                                <div className="scrollArea">
                                    {tabLoading ? (
                                        <>
                                            <SkeletonTabButton num={5} />
                                        </>
                                    ) : isMenu && categoryData?.length > 1 ? (
                                        <>
                                            {categoryData.map((tab, idx) => {
                                                return (
                                                    <button
                                                        id="categoryList"
                                                        key={tab?.id}
                                                        type="button"
                                                        className={`tab ` + (currentCtgrIdx === idx && 'active')}
                                                        onClick={() => onHandleMenuTab(idx)}
                                                    >
                                                        {tab?.smallName}
                                                    </button>
                                                );
                                            })}
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="foodCatWrap">
                            {isMenu && menuData?.length > 0 ? (
                                <div
                                    className={clsx(styles.noticeInfo, 'infoBox01')}
                                    id={`${detailData && 'food-ccDetail-infoBox'}`}
                                >
                                    <p className="textIndent">
                                        골프장 상황에 따라 음식메뉴 정보가 일치하지 않을 수 있습니다.
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}

                            {/* ==== 메뉴 리스트 ==== */}
                            {isMenu && loading && page === 0 ? (
                                <>
                                    {/* ==== 첫 렌더링 메뉴 리스트 스켈레톤 ==== */}
                                    <SkeletonFoodMenuList num={5} />
                                </>
                            ) : isMenu && menuData?.length > 0 ? (
                                <div>
                                    {menuData?.map((data, idx) => {
                                        return (
                                            <FoodMenuList
                                                key={idx}
                                                name={data?.productName}
                                                category={data?.smallName}
                                                desc={data?.description}
                                                price={data?.standardAmt}
                                                imgUrl={data?.stillImageUrl}
                                                data={data}
                                            />
                                        );
                                    })}
                                    {loading && (
                                        <>
                                            {/* ==== 인피니티 스크롤 리스트 스켈레톤 ==== */}
                                            <SkeletonFoodMenuList num={3} />
                                        </>
                                    )}
                                    <div
                                        ref={target}
                                        style={{
                                            position: 'absolute',
                                            bottom: '20px'
                                        }}
                                    ></div>
                                </div>
                            ) : (
                                <>
                                    <div className="noDataList">
                                        <i className="icon">
                                            <Icon
                                                name="noDataList"
                                                width="60px"
                                                height="60px"
                                                viewBox="0 0 60 60"
                                                fill="#F0F1F4"
                                            />
                                        </i>
                                        {isMenu === false ? (
                                            <>
                                                <p className="bigTxt">
                                                    {golfClubName}의
                                                    <br /> 음식메뉴 미리보기 서비스를 준비중입니다.
                                                </p>
                                                <span>빠른 시일내에 이용 가능하도록 준비하겠습니다.</span>
                                            </>
                                        ) : (
                                            <p className="bigTxt">음식메뉴 정보가 없습니다.</p>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <DefaultAlert
                open={alertOpen}
                handleOpen={handleAlertOpen}
                handleClose={handleAlertClose}
                title={''}
                contents={'예약 내역이 없습니다.\n예약 후 이용해 주세요.'}
            />
        </>
    );
}

export default Food;
