import React from 'react';
import PartnerInviteIcon from '../common/PartnerInviteIcon';

function PartnerInviteIconList({inviteIconNum, handleInviteOpen, smroundInvitation}) {
    const partnerInviteIcon = () => {
        let array = [];
        for (let i = 0; i < inviteIconNum; i++) {
            array.push(
                <PartnerInviteIcon key={i} handleInviteOpen={handleInviteOpen} smroundInvitation={smroundInvitation} />
            );
        }
        return array;
    };

    return partnerInviteIcon();
}

export default PartnerInviteIconList;
