import React, {useState, useEffect, useContext, useCallback} from 'react';
import '../reservation.scss';
import SelectItemList from './SelectItemList';
import FilterDrawer from '../../common/FilterDrawer';
import {fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import {FieldMainContext} from '../../../_store/context/FieldMainContext';
import {FieldMainContext_searchResult} from '../../../_store/context/FieldMainContext_SearchResult';
import {FieldMainContext_myPage} from '../../../_store/context/FieldMainContext_myPage';
import {appUtils} from '../../../common/utils';

function BasicFilterDrawer({
    basicFilterOpen,
    basicFilter,
    selectCategoryItem,
    filterInitBtn,
    isFilterReset,
    isFilterInitBtnDisable,
    contextFlag,
    defaultAllGolfListCnt,
    searchWord
}) {
    const {
        //=============== FieldMain,SearchResult 공통 변수 ==============//
        onBasicFilterClose,
        btnApplyClicked,
        onBtnApplyClicked,
        copyBasicFilter,
        selectDate,
        // setFilterCntListData,
        // copyFilterCntListData,
        // setCopyFilterCntListData,

        //기본 필터 리스트 카운트
        basicFilterDrawerCnt,
        setBasicFilterDrawerCnt,

        region,
        teeTime,
        playerCnt,
        holeCnt,
        greenFee,

        regionTemp,
        teeTimeTemp,
        playerCntTemp,
        holeCntTemp,
        greenFeeTemp,
        /* 2023.07.07 결제방식추가*/
        paymentType,
        paymentTypeTemp,
        //적용된 고급필터 데이터
        selectAdvancedList

        //=============== FieldMain, SearchResult 공통 변수 ==============//
    } = useContext(
        contextFlag === 'main'
            ? FieldMainContext
            : contextFlag === 'search-result'
            ? FieldMainContext_searchResult
            : FieldMainContext_myPage
    );

    const [isSelectBasicFilter, setIsSelectBasicFilter] = useState(false);
    const [isFilterLoding, setIsFilterLoding] = useState(false);

    useEffect(() => {
        if (basicFilterOpen === true) {
            // getFilterGolfclubCntList();
            setIsFilterLoding(false);
        }
    }, [basicFilterOpen]);

    useEffect(() => {
        const selectFilterTemp = [
            ...regionTemp,
            ...teeTimeTemp,
            ...playerCntTemp,
            ...holeCntTemp,
            ...greenFeeTemp,
            ...paymentTypeTemp
        ];
        const selectFilter = [...region, ...teeTime, ...playerCnt, ...holeCnt, ...greenFee, ...paymentType];

        if (selectFilterTemp.length > 0 || selectFilter.length > 0) {
            setIsSelectBasicFilter(true);
        } else {
            setIsSelectBasicFilter(false);
        }
    }, [
        regionTemp,
        teeTimeTemp,
        playerCntTemp,
        holeCntTemp,
        greenFeeTemp,
        region,
        teeTime,
        playerCnt,
        holeCnt,
        greenFee,
        /* 2023.07.07 결제방식추가*/
        paymentType,
        paymentTypeTemp
    ]);

    const makePayload = (page, sortOrder) => {
        const regionArr = appUtils.filterObjToArr(regionTemp, 0);
        //티타임
        const teeTimeArr = appUtils.filterObjToArr(teeTimeTemp, 0);
        //내장인원
        const playerCntArr = appUtils.filterObjToArr(playerCntTemp, 0);
        //홀 수

        const holeCntArr = appUtils.filterObjToArr(holeCntTemp, 0);
        //그린피
        const greenFeeArr = appUtils.filterObjToArr(greenFeeTemp, 0);

        //고급필터
        const selectTagIdListArr = appUtils.filterObjToArr(selectAdvancedList, 1);

        /* 2023.07.07 결제방식추가*/
        const paymentTypeArr = appUtils.filterObjToArr(paymentTypeTemp, 0);

        // 리스트업
        return {
            address: regionArr,
            greenFeeCode: greenFeeArr,
            hole: holeCntArr,
            minimumRoundPlayer: playerCntArr,
            roundDate: appUtils.dateFormatTypeDash(selectDate),
            tagIdList: selectTagIdListArr,
            areaOrClubName: searchWord,
            teeTimeCode: teeTimeArr,
            userLat: 0,
            userLon: 0,
            page: 0,
            size: 0,
            sortOrder: 'DISTANCE',
            paymentType: paymentTypeArr
        };
    };

    const getFilterCount = useCallback(() => {
        const data = makePayload();
        setIsFilterLoding(true);

        let countApiUrl = '/golfClub/pre/golfclubs/count';

        if (contextFlag === 'mypage-favorites') {
            countApiUrl = '/golfClub/pre/golfclubs/like/count';
        } else {
            countApiUrl = '/golfClub/pre/golfclubs/count';
        }

        fetchWrapper.post(countApiUrl, data).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                if (contextFlag === 'mypage-favorites') {
                    let selectCnt =
                        Number(regionTemp.length) +
                        Number(teeTimeTemp.length) +
                        Number(playerCntTemp.length) +
                        Number(holeCntTemp.length) +
                        Number(greenFeeTemp.length) +
                        Number(paymentTypeTemp.length);
                    if (selectCnt === 0) {
                        setBasicFilterDrawerCnt(response?.data?.totalCount);
                    } else {
                        setBasicFilterDrawerCnt(response?.data?.count);
                    }
                } else {
                    setBasicFilterDrawerCnt(response?.data?.count);
                }
                setIsFilterLoding(false);
            }
        });
    }, [
        regionTemp,
        teeTimeTemp,
        playerCntTemp,
        holeCntTemp,
        greenFeeTemp,
        paymentTypeTemp,
        selectDate,
        selectAdvancedList
    ]);

    useEffect(() => {
        if (basicFilterOpen === true) {
            getFilterCount();
        }
    }, [
        regionTemp,
        teeTimeTemp,
        playerCntTemp,
        holeCntTemp,
        greenFeeTemp,
        paymentTypeTemp,
        basicFilterOpen,
        selectDate
    ]);

    return (
        <FilterDrawer
            isApplyBtnActive={isSelectBasicFilter}
            drawerOpen={basicFilterOpen}
            drawerClose={onBasicFilterClose}
            title="기본 필터 설정"
            btnStatus="isDoubleBtn"
            filterInitBtn={filterInitBtn}
            isFilterInitBtnDisable={isFilterInitBtnDisable}
            btnApplyClicked={btnApplyClicked}
            onBtnApplyClicked={onBtnApplyClicked}
            filterListCnt={basicFilterDrawerCnt}
            filterFlag="BASIC"
            isFilterLoding={isFilterLoding}
        >
            <div>
                {copyBasicFilter
                    ? copyBasicFilter.map((data, idx) => {
                          return (
                              <SelectItemList
                                  selectCategoryItem={selectCategoryItem}
                                  key={idx}
                                  data={data}
                                  isFilterReset={isFilterReset}
                              />
                          );
                      })
                    : ''}
            </div>
        </FilterDrawer>
    );
}

export default BasicFilterDrawer;
