import React from 'react';
import BookingPrice from '../../components/round-booking/BookingPrice';
import PartnerInvite from './PartnerInvite';
import SmartRoundButton from './SmartRoundButton';

/* 라운드 예약 디테일 */
function CardDetailT1({bookingData, bookingList, setMemberReset, InviteOpen, setInviteOpen, handleInviteOpen}) {
    return (
        <>
            {/*동반자 초대*/}
            <PartnerInvite
                bookingData={bookingData}
                bookingList={bookingList}
                isBookingCancel={false}
                setMemberReset={setMemberReset}
                InviteOpen={InviteOpen}
                setInviteOpen={setInviteOpen}
                handleInviteOpen={handleInviteOpen}
            />
            <BookingPrice bookingData={bookingData} />
            <div className="btnArea">
                <SmartRoundButton bookingData={bookingData} disabled={false} />
            </div>
        </>
    );
}

export default CardDetailT1;
