import React, {useState} from 'react';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {history} from '../../_helpers';
import './membership.scss';
import {useLocation} from 'react-router-dom';
import {appUtils} from '../../common/utils';
import FindIdResult from './FindIdResult';
import {useParams} from 'react-router';

function KakaoFound({title, titleTxt}) {
    const location = useLocation();
    const params = useParams();
    const channel = params?.channel?.toLowerCase();
    const searchParams = new URLSearchParams(location.search);
    // const [userData, _setUserData] = useState(location.state.userData);

    console.debug(location.state);
    console.debug(channel);

    const [userMail, setUserMail] = useState(searchParams.get('userMail') || location.state.email);
    const [createdAt, setCreatedAt] = useState(searchParams.get('createdAt') || location.state.createdAt);
    // const iconClassName = channel ? 'icon' + appUtils.toUpperLowerCaseStr(channel) : 'iconEmail';

    return (
        <FindIdResult title={title} titleTxt={titleTxt} userMail={userMail} createdAt={createdAt} channel={channel} />
    );
}

export default KakaoFound;
