import React from 'react';

import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from 'react-router-dom';
import {ThemeProvider} from '@mui/material';
import {store} from '_store';
import App from './App';
import './index.css';

import theme from './App.global.theme';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {CookiesProvider} from 'react-cookie';
import * as Sentry from '@sentry/react';
import {ENVIRONMENT} from 'common/constants';

const {REACT_APP_SENTRY_DSN, REACT_APP_API_URL, REACT_APP_ENV} = process.env;

if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: REACT_APP_SENTRY_DSN,
        environment: REACT_APP_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                )
            }),
            new Sentry.Replay()
        ],
        tracePropagationTargets: ['localhost', REACT_APP_API_URL],
        beforeSend(event, hint) {
            const exception = hint.originalException;
            if (exception.message === '접근권한이 없습니다.') {
                return null;
            }
            return event;
        },

        // Sentry performance 측정
        tracesSampleRate: 0.5,

        // profilesSampleRate 추가
        profilesSampleRate: 1.0,

        //성공 replays 주석처리
        //replaysSessionSampleRate: REACT_APP_ENV === ENVIRONMENT.PRODUCTION ? 0.1 : 0,
        replaysOnErrorSampleRate: REACT_APP_ENV === ENVIRONMENT.PRODUCTION ? 0.3 : 0
    });
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <CookiesProvider>
                <PersistGate loading={null} persistor={persistStore(store)}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </PersistGate>
            </CookiesProvider>
        </Provider>
    </ThemeProvider>
);
