import React, {useState} from 'react';
import styled, {css} from 'styled-components';

const StyledButton = styled.button`
    position: relative;
    display: block;
    width: 100%;
    height: 3rem;
    border-radius: 2.5rem;
    font-size: 1rem;
    font-weight: 600;
    transition: background 1s;

    &.medium {
        font-size: 0.875rem;
        height: 2.625rem;
    }

    &.small {
        font-size: 0.75rem;
        height: 2rem;
    }

    &:disabled {
        background-color: #a0a4af;
        color: #fff;
        transition: background 0s !important;
    }

    height: ${(props) => props.height || '3rem'};
    color: ${(props) => props.color || '#fff'};
    background: ${(props) => props.background || '#3906C3'};
    border: ${(props) => props.border || 'none'};

    &:not(:disabled):active {
        background-color: #280488 !important;
        transition: background 0s;
    }

    ${(props) =>
        props.primaryLine &&
        css`
            color: #3906c3;
            background: #fff;
            border: 1px solid #3906c3;

            &:disabled {
                color: #a0a4af;
                background-color: #f8f8f8;
                border-color: #ebebeb;
            }

            &:not(:disabled):active {
                background-color: #d4d4d4 !important;
            }
        `}

    ${(props) =>
        props.secandary &&
        css`
            color: #3906c3;
            background: #eaf5f5;

            &:not(:disabled):active {
                background-color: #c4b5ed !important;
            }
        `}

    ${(props) =>
        props.lightGray &&
        css`
            color: #3c414a;
            background: #f0f1f4;

            &:not(:disabled):active {
                background-color: #cdd0da !important;
            }
        `}

    ${(props) =>
        props.darkGray &&
        css`
            color: #fff;
            background: #3c414a;

            &:disabled {
                background-color: #a0a4af;
            }

            &:not(:disabled):active {
                background-color: #3c414a !important;
            }
        `}

    ${(props) =>
        props.cta &&
        css`
            color: #fff;
            background: #00305b;

            &:not(:disabled):active {
                background-color: #170055 !important;
            }
        `}
`;

function Button({children, onClick, delay = 300, ...props}) {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = (evt) => {
        if (!isClicked) {
            setIsClicked(true);
            onClick(evt);
            setTimeout(() => {
                setIsClicked(false);
            }, delay);
        }
    };

    return (
        <StyledButton onClick={handleClick} disabled={isClicked} {...props}>
            {children}
        </StyledButton>
    );
}

export default Button;
