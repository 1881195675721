import {useEffect, useState} from 'react';
import {appUtils} from '../../common/utils';

function BoardDetail({data}) {
    const createDate = appUtils.dateFormatTypeBoard(data?.displayStrAt);

    const [linkStyle, setLinkStyle] = useState(false);

    useEffect(() => {
        /**
         * 메인페이지에서 공지사항 호출 시
         * 하단 네비게이션의 a태그 스타일이 강제로 활성화되므로 parentElemnt의 id를 체크
         * */
        if (data) {
            let selectedLink = document.getElementsByTagName('a');
            if (selectedLink) {
                for (let i = 0; i < selectedLink.length; i++) {
                    const item = selectedLink[i];
                    const parentItemId = item.parentElement?.id;

                    if (!parentItemId) {
                        item.style.cssText = 'color:blue; text-decoration:underline;';
                        const url = item.getAttribute('href');
                        item.onclick = (e) => {
                            e.preventDefault();
                            movePage(url);
                        };
                        setLinkStyle(true);
                    }
                }
            }
        }
    }, [data, linkStyle]);

    const movePage = (url) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    inappurl: url,
                    type: 'openInappBrowser',
                    inapptitle: '골라가'
                })
            );
        } else {
            window.location.href = url;
        }
    };

    return (
        <>
            <div id="notice">
                <div className="inner">
                    <div className="detailCon">
                        <div className="detailTitle">
                            <div className="titleArea">
                                <h1>{data?.title}</h1>
                                <em className="date">{createDate}</em>
                            </div>
                        </div>
                        <div className="detailContents">
                            {data?.boardFiles?.length > 0 &&
                                data?.boardFiles?.map((item, idx) => {
                                    return (
                                        item.type === 'IMAGE' && <img key={idx} src={item?.path} alt={data?.title} />
                                    );
                                })}
                            <div className="detailTxt" dangerouslySetInnerHTML={{__html: data?.content}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BoardDetail;
