import { useEffect, useState } from "react";
import { appUtils } from "../../common/utils";
import { useDispatch, useSelector } from "react-redux";
import { timerActions } from "../../_store/timer.slice";

function Timer({ setReset, setOverAction }) {
    const timerData = useSelector(x => x.timer);
    const { initialSec, done, reset } = timerData;
    const dispatch = useDispatch();
    const [minutes, setMinutes] = useState(initialSec / 60 < 1 ? 0 : initialSec / 60);
    const [sec, setSec] = useState(initialSec % 60);

    useEffect(() => {
        const countDown = setInterval(() => {
            if (done) {
                return;
            }
            if (sec > 0) {
                setSec(sec - 1);
            } else if (sec === 0) {
                if (minutes === 0) {
                    setOverAction && setOverAction();
                    clearInterval(countDown);
                } else {
                    setMinutes(minutes - 1);
                    setSec(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(countDown);
        };
    }, [minutes, sec, done]);

    useEffect(() => {
        if (reset) {
            setMinutes(initialSec / 60 < 1 ? 0 : initialSec / 60);
            setSec(initialSec % 60);
            dispatch(timerActions.setReset(false));
        }
    }, [reset]);

    return (
        <>
            <b className={`time ${done && "done"}`}>{appUtils.appendZero(minutes)}:{appUtils.appendZero(sec)}</b>
        </>
    );
}

export default Timer;