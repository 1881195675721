import React from "react";
import Header from "../../components/Header";
import TitleArea from "../../components/common/TitleArea";
import Navigation from "../navigation/Navigation";

function Score({ title }) {
    return (
        <>
            <Header back={true} />
            <div id="contents">
                <div className="inner">
                    <TitleArea pageTitle={title} />
                </div>
            </div>
            <Navigation />
        </>
    );
}

export default Score;
