import React, {useContext, useEffect, useState} from 'react';
import {FieldMainContext} from '../../../_store/context/FieldMainContext';
import {FieldMainContext_searchResult} from '../../../_store/context/FieldMainContext_SearchResult';
import {FieldMainContext_myPage} from '../../../_store/context/FieldMainContext_myPage';
import {bookingConstant} from 'common/payment';

function FilterCategory({idx, onBasicFilterOpen, filter, count, codeName, contextFlag}) {
    const {region, teeTime, playerCnt, holeCnt, greenFee, paymentType} = useContext(
        contextFlag === 'main'
            ? FieldMainContext
            : contextFlag === 'search-result'
            ? FieldMainContext_searchResult
            : FieldMainContext_myPage
    );

    const [title, setTitle] = useState('');
    const [isBasicCategory, setIsBasicCategory] = useState(false);

    useEffect(() => {
        switch (filter.filterCode) {
            case bookingConstant.basicFilterCode.BASIC_FILTER_REGION:
                // 0 일때는 기본 라벨
                if (region.length <= 0) {
                    setIsBasicCategory(false);
                    setTitle(codeName);
                } else {
                    setIsBasicCategory(true);

                    // 1개일때는 외 안 붙임
                    if (region.length === 1) {
                        setTitle(region[0].codeName);
                        //1개 이상일때는 외 붙임
                    } else {
                        setTitle(region[0].codeName + ' 외 ' + (region.length - 1));
                    }
                }
                break;
            case bookingConstant.basicFilterCode.BASIC_FILTER_TEETIME:
                if (teeTime.length <= 0) {
                    setIsBasicCategory(false);
                    setTitle(codeName);
                } else {
                    setIsBasicCategory(true);

                    // 1개일때는 외 안 붙임
                    if (teeTime.length === 1) {
                        setTitle(teeTime[0].codeName);
                        //1개 이상일때는 외 붙임
                    } else {
                        setTitle(teeTime[0].codeName + ' 외 ' + (teeTime.length - 1));
                    }
                }
                break;
            case bookingConstant.basicFilterCode.BASIC_FILTER_ROUNDPLAYER:
                if (playerCnt.length <= 0) {
                    setIsBasicCategory(false);
                    setTitle(codeName);
                } else {
                    setIsBasicCategory(true);

                    // 1개일때는 외 안 붙임
                    if (playerCnt.length === 1) {
                        setTitle(playerCnt[0].codeName);
                        //1개 이상일때는 외 붙임
                    } else {
                        setTitle(playerCnt[0].codeName + ' 외 ' + (playerCnt.length - 1));
                    }
                }
                break;
            case bookingConstant.basicFilterCode.BASIC_FILTER_HOLE:
                if (holeCnt.length <= 0) {
                    setIsBasicCategory(false);
                    setTitle(codeName);
                } else {
                    setIsBasicCategory(true);

                    // 1개일때는 외 안 붙임
                    if (holeCnt.length === 1) {
                        setTitle(holeCnt[0].codeName);
                        //1개 이상일때는 외 붙임
                    } else {
                        setTitle(holeCnt[0].codeName + ' 외 ' + (holeCnt.length - 1));
                    }
                }

                break;
            case bookingConstant.basicFilterCode.BASIC_FILTER_GREENFEE:
                if (greenFee.length <= 0) {
                    setIsBasicCategory(false);
                    setTitle(codeName);
                } else {
                    setIsBasicCategory(true);

                    // 1개일때는 외 안 붙임
                    if (greenFee.length === 1) {
                        setTitle(greenFee[0].codeName);
                        //1개 이상일때는 외 붙임
                    } else {
                        setTitle(greenFee[0].codeName + ' 외 ' + (greenFee.length - 1));
                    }
                }
                break;
            /* 2023.07.07 결제방식 추가 */
            case bookingConstant.basicFilterCode.BASIC_FILTER_PAYMENT:
                if (paymentType.length <= 0) {
                    setIsBasicCategory(false);
                    setTitle(codeName);
                } else {
                    setIsBasicCategory(true);

                    // 1개일때는 외 안 붙임
                    if (paymentType.length === 1) {
                        setTitle(paymentType[0].codeName);
                        //1개 이상일때는 외 붙임
                    } else {
                        setTitle(paymentType[0].codeName + ' 외 ' + (paymentType.length - 1));
                    }
                }
                break;
            default:
        }
    }, [region, teeTime, playerCnt, holeCnt, greenFee, paymentType, filter]);

    return (
        <li className={`idx-${idx}`} key={idx} onClick={() => onBasicFilterOpen(codeName)}>
            {' '}
            {/* 활성화 되었을때 클래스 "on" 추가*/}
            <button type="button" className={isBasicCategory === true ? 'on' : ''}>
                {/*<span>{codeName}</span>*/}
                <span>{isBasicCategory === true ? title : codeName}</span>
            </button>
        </li>
    );
}

export default FilterCategory;
