import Spinner from './Spinner';
import './loading.scss';

function LoadingGlobal() {
    return (
        <div className="loading-global">
            <Spinner />
        </div>
    );
}

export default LoadingGlobal;
