import PaymentBookingCardPre from './PaymentBookingCardPre';
import PaymentBookingCardPost from './PaymentBookingCardPost';
import PaymentBookingCardCancel from './PaymentBookingCardCancel';
import {paymentConstant} from 'common/payment';

import React, {useState, useEffect} from 'react';
import './payment-booking-card.scss';
import PaymentDetailModal from '../PaymentDetailModal/PaymentDetailModal';
import {appUtils} from 'common/utils';

const Status = (bookingData, detailModalControl) => ({
    preRound: (
        <PaymentBookingCardPre
            paymentType={bookingData.paymentType}
            bookingData={bookingData}
            detailModalControl={detailModalControl}
        />
    ),
    pastRound: (
        <PaymentBookingCardPost
            paymentType={bookingData.paymentType}
            bookingData={bookingData}
            detailModalControl={detailModalControl}
        />
    ),
    cancelRound: (
        <PaymentBookingCardCancel
            paymentType={bookingData.paymentType}
            bookingData={bookingData}
            detailModalControl={detailModalControl}
        />
    )
});

const Detail = (props) => {
    const {status, bookingData, detailModalControl} = props;

    return Status(bookingData, detailModalControl)[status];
};

const PaymentBookingCard = (props) => {
    const {status, bookingData} = props;

    const [openDetailModal, setOpenDetailModal] = useState(false);

    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;

            if (type === 'webModalClose') {
                detailModalControl(false);
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);

        return () => document.removeEventListener('message', handleEvent);
    }, []);

    const detailModalControl = (modalStatus) => {
        if (modalStatus) {
            setOpenDetailModal(true);

            appUtils.openWebViewModalPostMsg();
        } else {
            setOpenDetailModal(false);
        }
    };

    const statusData = {
        //예정라운드
        preRound: 'preRound',
        //지난라운드
        pastRound: 'pastRound',
        //취소라운드
        cancelRound: 'cancelRound'
    };

    // const paymentTradeAmountTypeData = {
    //     //현장결제
    //     ONSITE: paymentConstant.paymentType.ONSITE,
    //     //선결제
    //     PREPAY: paymentConstant.paymentType.PREPAY,
    //     //예약금결제
    //     RESERVE: paymentConstant.paymentType.RESERVE
    // };

    let roundStatus;
    //let paymentType = bookingData?.paymentType;

    switch (status) {
        case 'pre':
            roundStatus = statusData.preRound;
            break;
        case 'post':
            roundStatus = statusData.pastRound;
            break;
        case 'cancel':
            roundStatus = statusData.cancelRound;
            break;
        default:
            break;
    }

    return (
        <>
            <Detail status={roundStatus} detailModalControl={detailModalControl} bookingData={bookingData} />

            <PaymentDetailModal
                openDetailModal={openDetailModal}
                detailModalControl={detailModalControl}
                bookingData={bookingData}
                status={status}
            ></PaymentDetailModal>
        </>
    );
};

export default PaymentBookingCard;
