import React from "react";
import SkeletonElement from "./SkeletonElement";

function SkeletonTabButton({ num }) {
    function list() {
        let array = [];
        for (let i = 0; i < num; i++) {
            array.push(
                <SkeletonElement
                    type="tabButton"
                    variant="rectangular"
                    key={i}
                />
            );
        }
        return array;
    }

    return list();
}

SkeletonTabButton.defaultProps = {
    num: 3
};
export default SkeletonTabButton;
