import React from 'react';
import Icon from '../icons/Icon';

function BookingHeader({townName, onLocationFilterOpen}) {
    return (
        <>
            <div id="header" className="headerFixed">
                <div className="inner bookingHeaderWrap">
                    <div className="leftCon">
                        <Icon name="logo" fill="#ffffff" className="logo" />
                        {/*<span className="txt">필드골프</span>*/}
                    </div>
                    <div className="rightCon">
                        <button
                            type="button"
                            className="btnLocation"
                            style={{color: '#ffffff'}}
                            onClick={onLocationFilterOpen}
                        >
                            <Icon name="gps_02" width="20" height="20" viewBox="0 0 20 20" fill="#ffffff" />
                            &nbsp;{townName}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingHeader;
