import React, { useEffect, useState } from "react";
import DefaultAlert from "../alert/DefaultAlert";
import { appUtils } from "../../common/utils";

function ReadyAlert() {
    // 준비중 Alert
    const [readyAlert, setReadyAlert] = useState(false);
    const readyAlertClose = () => setReadyAlert(false);

    useEffect(() => {
        appUtils.notInServiceEventHandler(readyAlert, setReadyAlert);
    });

    return (
        <>
            {readyAlert && (
                <DefaultAlert
                    open={readyAlert}
                    handleClose={readyAlertClose}
                    title={"서비스 안내"}
                    contents={"추후 제공될 예정입니다."}
                />
            )}
        </>
    );
}

export default ReadyAlert;
