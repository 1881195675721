import React from 'react';
import Card from '../booking/Card';
import {Link} from 'react-router-dom';
import Icon from '../../components/icons/Icon';

function CardList({
    bookingData,
    bookingType,
    status,
    setBookingListReset,
    setMemberReset,
    InviteOpen,
    setInviteOpen,
    handleInviteOpen,
    onClickSelfCheckInBtn
}) {
    return (
        <div className="rezListWrap">
            {/* 카드 리스트 (전체 껍데기)*/}
            {bookingData && bookingData.length ? (
                bookingData.map((data, idx) => {
                    return (
                        <Card
                            bookingData={data}
                            bookingList={bookingData}
                            status={status.value}
                            bookingType={bookingType}
                            key={idx}
                            setBookingListReset={setBookingListReset}
                            setMemberReset={setMemberReset}
                            InviteOpen={InviteOpen}
                            setInviteOpen={setInviteOpen}
                            handleInviteOpen={handleInviteOpen}
                            onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                        />
                    );
                })
            ) : (
                <div className="noDataList">
                    {status.value === 'cancel' ? (
                        <>
                            <i className="icon">
                                <Icon name="caution" viewBox="0 0 60 60" width="60px" height="60px" fill="#F0F1F4" />
                            </i>
                            <p>취소하신 티타임 내역이 없습니다.</p>
                        </>
                    ) : (
                        <>
                            <p className="bigTxt">
                                {status.value === 'pre' || status.value === 'smartRound'
                                    ? '현재 예정된 라운드가 없습니다.'
                                    : '이용하신 라운드 내역이 없습니다.'}
                                <br />
                                지금 새로운 예약을 진행해보세요!
                            </p>
                            <div className="btnArea">
                                <Link to="/" className="btnPrimary medium">
                                    골프장 찾아보기
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default CardList;
