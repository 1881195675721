import {EMAIL_MAXLENGTH, EMAIL_REG, KOREAN_REG, NAME_MAXLENGTH, NUMBER_REG, PASSWORD_REG, PHONE_REG} from './constants';
import * as Yup from 'yup';
import {errMsg} from './messages';

import moment from 'moment';
import {history} from '../_helpers';
import {validationUtil} from './payment';

const timeOptions = {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit'
};
export const appUtils = {
    /**
     * Object 비교
     * @param orgin
     * @param diff
     * @returns {boolean}
     */
    isSameObject: (orgin, diff) => {
        return JSON.stringify(orgin) === JSON.stringify(diff);
    },
    /**
     정규식과 타입에 따라 처리
     * @parapm event maxLength  regex
     * @returns {value}
     * */
    isValidInputCheck: (event, maxLength, regex) => {
        const currentValue = event.target.value;

        if (event.target.value.length >= maxLength) {
            return event.target.blur();
        }
        event.target.value = currentValue.replace(regex, '');
    },

    /* 첫글자 대문자
     * */
    toUpperLowerCaseStr: (str) => {
        return str.length === 0 ? '' : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },

    /**
     * Object 비교
     * @param orgin
     * @param diff
     * @returns {boolean}
     */
    getOnlyNumber: (text) => {
        let regex = /[^0-9]/g;
        return text.replace(regex, '');
    },
    isEmpty: (text) => {
        return !appUtils.isNotEmpty(text);
    },
    isNotEmpty: (text) => {
        return text !== null && text !== undefined && text !== '' && text !== '';
    },
    /**
     * 스페이스(공백) 입력 제한
     * @param event
     * @returns {value}
     */
    noSpaceForm: (event) => {
        let value = event.target.value.replaceAll(/\s/gi, '');
        event.target.value = value;
    },
    /**
     * 이메일 정규식 체크
     * @param str
     * @returns {boolean}
     */
    checkEmailReg: (str) => {
        return EMAIL_REG.test(str);
    },
    /**
     * 패스워드 정규식 체크
     * @param str
     * @returns {boolean}
     */
    checkPasswordReg: (str) => {
        return PASSWORD_REG.test(str);
    },
    /**
     * 한글 정규식 체크
     * @param str
     * @returns {boolean}
     */
    checkKoreanReg: (str) => {
        return KOREAN_REG.test(str);
    },
    /**
     * 숫자 정규식 체크
     * @param str
     * @returns {boolean}
     */
    checkNumberReg: (str) => {
        return NUMBER_REG.test(str);
    },
    getIsoStringToDate: (dateString) => {
        if (!date) return;
        let date = new Date(dateString);
        return new Date(date.getTime() + new Date().getTimezoneOffset());
    },
    /**
     * 숫자 ,(콤마) 처리
     * @param number
     * @param disit
     */
    numberFormat: (number, disit = 0) => {
        if (isNaN(number)) {
            return '';
        }
        number = `${number}`.replace(/,/gi, '');
        return parseFloat(number).toLocaleString('en', {
            maximumFractionDigits: disit
        });
    },

    /**
     * 금액 변환
     * */
    amountFormat: (amount, currency = '원') => {
        const excludedValues = [null, undefined, NaN, ''];
        if (excludedValues.includes(amount)) {
            return '-' + currency;
        }

        const formattedAmount = amount.toLocaleString('ko-KR');
        return formattedAmount + currency;
    },

    /**
     * 문자열 숫자 변환
     * @param str
     * @returns {string|number}
     */
    strToInt: (str) => {
        let result = parseInt(str.replace(/,/g, '') || '');
        if (isNaN(result)) {
            return '';
        }
        return result;
    },
    appendZero: (number) => {
        return number < 10 ? `0${number}` : `${number}`;
    },
    getYYYYMMDDHHMMSS: () => {
        let nowDate = new Date();
        let yyyy = nowDate.getFullYear().toString();
        let MM = appUtils.appendZero(nowDate.getMonth() + 1, 2);
        let dd = appUtils.appendZero(nowDate.getDate(), 2);
        let hh = appUtils.appendZero(nowDate.getHours(), 2);
        let mm = appUtils.appendZero(nowDate.getMinutes(), 2);
        let ss = appUtils.appendZero(nowDate.getSeconds(), 2);

        return yyyy + MM + dd + hh + mm + ss;
    },
    getYYYYMMDDHH: () => {
        let nowDate = new Date();
        let yyyy = nowDate.getFullYear().toString();
        let MM = appUtils.appendZero(nowDate.getMonth() + 1, 2);
        let dd = appUtils.appendZero(nowDate.getDate(), 2);
        let hh = appUtils.appendZero(nowDate.getHours(), 2);

        return yyyy + MM + dd + hh;
    },
    getYYYYMMDDD: () => {
        let nowDate = new Date();
        let yyyy = nowDate.getFullYear().toString();
        let MM = appUtils.appendZero(nowDate.getMonth() + 1, 2);
        let dd = appUtils.appendZero(nowDate.getDate(), 2);

        return yyyy + MM + dd;
    },
    getYYYYMMDDHyphen: () => {
        let nowDate = new Date();
        let yyyy = nowDate.getFullYear().toString();
        let MM = appUtils.appendZero(nowDate.getMonth() + 1, 2);
        let dd = appUtils.appendZero(nowDate.getDate(), 2);

        return `${yyyy}-${MM}-${dd}`;
    },
    /**
     * 만 나이
     * @param birthStr
     * @returns {number}
     */
    calcAge: (birthStr) => {
        let nowDate = new Date();
        let year = nowDate.getFullYear();
        let month = nowDate.getMonth() + 1;
        let date = nowDate.getDate();

        month = appUtils.appendZero(month);
        date = appUtils.appendZero(date);

        let mmdd = month + date;

        let birthYyyy = birthStr.substring(0, 4);
        let birthMmdd = birthStr.substring(4);

        return mmdd < birthMmdd ? year - birthYyyy - 1 : year - birthYyyy;
    },
    passwordRegCheck: (password) => {
        let numberCnt = password.search(/[0-9]/g);
        let engCnt = password.search(/[a-z]/gi);
        let speCnt = password.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
        /* 영문 + 숫자 / 영문 + 특수문자 / 숫자 + 특수문자 */
        return (numberCnt > -1 && engCnt > -1) || (engCnt > -1 && speCnt > -1) || (numberCnt > -1 && speCnt > -1);
    },

    /** 최소 길이 체크
     * */
    minLengthCheck: (value, min) => {
        return value.length >= min;
    },
    /**
     * 입력한 패스워드 내 아이디, 도메인, 전체 이메일의 포함 여부를 체크하기 위한 함수
     * */
    passwordValidCheck: (obj) => {
        let email = obj.userMail;
        let id = email.split('@')[0];
        let domainUrl = email.split('@')[1];
        let password = obj.userPassword;

        appUtils.minLengthCheck(password, window.config.password.minLength);

        return password && id && !(password.includes(id) || password.includes(email) || password.includes(domainUrl));
    },

    /**
     * 날짜 형식
     * @param date
     * @returns {string}
     */
    changeDateString: (date) => {
        let yyyy = date.getFullYear();
        let mm = ('00' + date.getMonth().toString()).slice(-2);
        let dd = ('00' + date.getDate().toString()).slice(-2);
        return yyyy + mm + dd;
    },

    /**
     * 준비중 Alert
     * @param readyAlert
     * @returns {boolean}
     */
    notInServiceEventHandler: (readyAlert, setReadyAlert) => {
        const alertBtn = document.querySelectorAll('.readyAlert');

        alertBtn.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                setReadyAlert(true);
            });
        });

        return () => {
            alertBtn.forEach((btn) => {
                btn.removeEventListener('click', setReadyAlert(false));
            });
        };
    },
    /**
     * Input Focus RN WebView Event Export
     * @param event
     * @returns {boolean}
     */
    offsetPadding: () => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'offsetPadding'}));
    },
    defaultPadding: () => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({type: 'defaultPadding'}));
    },
    /**
     * yyyy.mm.dd(week)
     * @param isoString
     * @returns {`${string}.${string}.${string}`}
     */
    dateFormatTypeBooking: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);

        let dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'short'}).format(d);

        return `${ye}.${mo}.${da}(${dd})`;
    },
    dateFormatBirthDay: (date) => {
        if (!date) return '';
        return date.replaceAll(/-/g, '.');
    },
    /**
     * yyyy.mm.dd(week)
     * @param isoString
     * @returns {`${string}.${string}.${string}`}
     */
    dateFormatTypeRoundAt: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);

        let dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'short'}).format(d);
        let hh = new Intl.DateTimeFormat('en', {
            ...timeOptions
        }).format(d);

        return `${ye}.${mo}.${da}(${dd}) ${hh}`;
    },
    /**
     * mm.dd(week) hh:mm:ss
     * @param isoString
     * @returns {`${string}.${string}.${string} (${string}) ${string}`}
     */
    dateFormatTypeNoYear: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);

        let dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'short'}).format(d);
        let hh = new Intl.DateTimeFormat('en', {
            ...timeOptions
        }).format(d);

        return `${mo}.${da}(${dd}) ${hh}`;
    },
    /**
     * yyyy.mm.dd(week) hh:mm:ss
     * @param isoString
     * @returns {`${string}.${string}.${string} (${string}) ${string}`}
     */
    dateFormatTypeFull: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
        let hh = new Intl.DateTimeFormat('en', {
            ...timeOptions
        }).format(d);
        let dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'short'}).format(d);
        return `${ye}.${mo}.${da}(${dd}) ${hh}`;
    },
    /**
     * yyyy.mm.dd week
     * @param isoString
     * @returns {`${string}.${string}.${string} ${string}`}
     */
    dateFormatTypeDayLong: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
        let dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'long'}).format(d);
        return `${ye}.${mo}.${da} ${dd}`;
    },
    /**
     * yyyy.mm.dd(week) hh:mm:ss
     * @param isoString
     * @returns {`${string}`}
     */
    dateFormatTypeOnlyTime: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let hh = new Intl.DateTimeFormat('en', {
            ...timeOptions
        }).format(d);
        return `${hh}`;
    },
    /**
     * yyyy.mm.dd
     * @param isoString
     */
    dateFormatTypeBoard: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);

        return `${ye}.${mo}.${da}`;
    },
    /**
     * yyyy-mm-dd
     * @param isoString
     */
    dateFormatTypeDash: (isoString) => {
        if (!isoString || validationUtil.isNullChk(isoString)) return '';

        let d = new Date(isoString);
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);

        return `${ye}-${mo}-${da}`;
    },
    strToDate: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        return d;
    },
    strToTime: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let hh = new Intl.DateTimeFormat('en', {
            ...timeOptions
        }).format(d);

        return `${hh}`;
    },
    nowDateToYYYYMMDDStr: (target = null) => {
        let nowDate = new Date();
        if (target) {
            nowDate = appUtils.getIsoStringToDate(target);
        }
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(nowDate);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(nowDate);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(nowDate);

        return `${ye}-${mo}-${da}`;
    },
    calcDDay: (targetDate) => {
        let oneDay = 1000 * 60 * 60 * 24;
        let dateStr = targetDate.split('T')[0];

        let bookingDate = new Date(dateStr);

        let date = new Date(appUtils.nowDateToYYYYMMDDStr());

        let calcDate = bookingDate - date;
        let diffDay = calcDate / oneDay;
        return diffDay;
    },
    calcTime: (targetDate) => {
        if (!targetDate) return null;
        let year = targetDate.split('T')[0].split('-')[0];
        let month = targetDate.split('T')[0].split('-')[1];
        let day = targetDate.split('T')[0].split('-')[2];
        let hour = targetDate.split('T')[1].split(':')[0];
        let min = targetDate.split('T')[1].split(':')[1];
        let cancelAbleAt = new Date(year, month > 1 ? month - 1 : 0, day, hour, min).getTime();
        let leftTime = cancelAbleAt - new Date().getTime();

        return leftTime;
    },
    /**
     * mm월 dd일
     * @param isoString
     */
    dateFormatTypeDay: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);

        let dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'short'}).format(d);

        return `${mo}월 ${da}일(${dd})`;
    },

    /**
     * yyyy년 mm월 dd일
     * @param isoString
     */
    nowDateToYYYYMMDD: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(d);
        let dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'short'}).format(d);

        return `${ye}년 ${mo}월 ${da}일(${dd})`;
    },
    agreeDateToYYYYMD: (isoString) => {
        if (!isoString) return '';
        let d = new Date(isoString);
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(d);
        let mo = new Intl.DateTimeFormat('en', {month: 'numeric'}).format(d);
        let da = new Intl.DateTimeFormat('en', {day: 'numeric'}).format(d);
        let dd = new Intl.DateTimeFormat('ko-KR', {weekday: 'short'}).format(d);

        return `${ye}년 ${mo}월 ${da}일(${dd})`;
    },
    isToday: (compareDate) => {
        let today = appUtils.nowDateToYYYYMMDDStr();
        return today === compareDate.split('T')[0];
    },
    // 실제 유효한 생년월일인지 체크
    isValidDate: (val) => {
        if (!val) return false;
        const current = moment(); // 현재일자
        const prefix = val.substring(0, 2); // 입력받은 년도
        const century21 = current.format('YY') + 1; // 세기체크
        const century = prefix < century21 ? '20' : '19'; // 1900년도 || 2000년도
        const dateObj = moment(century + prefix + val.substring(2), 'YYYYMMDD');

        // 실제 날짜 && 오늘날짜보다 같거나 이전인지 확인
        return dateObj.isValid() && dateObj.isSameOrBefore(current, 'day');
    },
    getValidationConfig: (type) => {
        let config = null;
        if (type === 'AUTH') {
            config = {
                userName: Yup.string()
                    .trim()
                    .required(errMsg.USER_NAME_EMPTY)
                    .min(2, errMsg.USER_NAME_MINLENGTH)
                    .test('userName', errMsg.USER_NAME_CHECK, (val) => /^[가-힣]+$/.test(val)),
                userBirth: Yup.string()
                    .trim()
                    .required(errMsg.USER_BIRTH_EMPTY)
                    .min(6, errMsg.USER_BIRTH_MINLENGTH)
                    .test('is-valid-date', errMsg.USER_BIRTH_INVALID_DATE, (val) => appUtils.isValidDate(val)),
                userGender: Yup.string().trim().required(errMsg.USER_BIRTH_GENDER_EMPTY),
                userPhone: Yup.string()
                    .trim()
                    .required(errMsg.USER_PHONE_EMPTY)
                    .min(10, errMsg.USER_PHONE_MINLENGTH)
                    .matches(PHONE_REG, errMsg.USER_PHONE_MINLENGTH),
                authAgree: Yup.string().trim().oneOf(['true'], errMsg.AUTH_PHONE_AGREE),
                userTelecom: Yup.string().trim().required(errMsg.TELECOM_EMPTY)
            };
        } else if (type === 'FIND_PASSWORD') {
            config = {
                userId: Yup.string().trim().required(errMsg.USER_ID_EMPTY).matches(EMAIL_REG, errMsg.USER_ID_REG),
                userName: Yup.string().trim().required(errMsg.USER_NAME_EMPTY).min(2, errMsg.USER_NAME_MINLENGTH),
                userBirth: Yup.string().trim().required(errMsg.USER_BIRTH_EMPTY).min(6, errMsg.USER_BIRTH_MINLENGTH),
                userGender: Yup.string().trim().required(errMsg.USER_BIRTH_GENDER_EMPTY),
                userPhone: Yup.string()
                    .trim()
                    .required(errMsg.USER_PHONE_EMPTY)
                    .min(10, errMsg.USER_PHONE_MINLENGTH)
                    .matches(PHONE_REG, errMsg.USER_PHONE_MINLENGTH),
                authAgree: Yup.string().trim().oneOf(['true'], errMsg.AUTH_PHONE_AGREE),
                userTelecom: Yup.string().trim().required(errMsg.TELECOM_EMPTY)
            };
        } else if (type === 'FIND_PASSWORD_2') {
            config = {
                userName: Yup.string()
                    .trim()
                    .required(errMsg.USER_NAME_EMPTY)
                    .min(2, errMsg.USER_NAME_MINLENGTH)
                    .test('userName', errMsg.USER_NAME_CHECK, (val) => /^[가-힣]+$/.test(val)),
                userBirth: Yup.string().trim().required(errMsg.USER_BIRTH_EMPTY).min(6, errMsg.USER_BIRTH_MINLENGTH),
                userGender: Yup.string().trim().required(errMsg.USER_BIRTH_GENDER_EMPTY),
                userPhone: Yup.string()
                    .trim()
                    .required(errMsg.USER_PHONE_EMPTY)
                    .min(10, errMsg.USER_PHONE_MINLENGTH)
                    .matches(PHONE_REG, errMsg.USER_PHONE_MINLENGTH),
                authAgree: Yup.string().trim().oneOf(['true'], errMsg.AUTH_PHONE_AGREE),
                userTelecom: Yup.string().trim().required(errMsg.TELECOM_EMPTY)
            };
        }
        return config;
    },
    // 0을 붙여 두 자리수로 변경하여 반환 (날짜 형식에 사용)
    cfSetAddZero: (isoString) => {
        let num = parseInt(isoString);
        let str = num > 9 ? num : '0' + num;
        return str.toString();
    },

    formatPhoneNumber: (phoneNumberString) => {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return null;
    },

    firstInputFocus: () => {
        const input = document.querySelector('input');
        input?.focus();
    },

    /**
     * 웹 -> 웹뷰 안드로이드 모달 컨트롤 열기 메세지 보내기
     * typeName : webModalOpen , etc...
     * */
    openWebViewModalPostMsg: (typeName) => {
        let payload = {
            type: typeName ?? 'webModalOpen',
            isOpen: true
        };
        window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
    },
    //웹 -> 웹뷰 회원가입 안드로이드 백버튼 Confirm 모달 함수
    openSignUpConfirmPostMsg: () => {
        let payload = {
            type: 'authenticationOpen',
            isOpen: true
        };
        window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
    },
    //웹 -> 웹뷰 회원가입 안드로이드 백버튼 Confirm 모달 함수
    closeSignUpConfirmPostMsg: () => {
        let payload = {
            type: 'authenticationClose',
            isOpen: false
        };
        window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
    },

    queryStringNavigate: (path, payload, state) => {
        history.navigate(path + '?data=' + encodeURI(JSON.stringify(payload)), {replace: true, state: state});
    },

    //기본 필터 post시 Obj -> Arr 변환
    filterObjToArr: (obj, flag) => {
        // 0 : code
        // 1 : codeId
        // 2 : codeName
        let dataArr = [];
        if (flag === 0) {
            obj.map((item, idx) => {
                dataArr[idx] = item.code;
            });
        } else if (flag === 2) {
            obj.map((item, idx) => {
                dataArr[idx] = item.codeName;
            });
        } else {
            obj.map((item, idx) => {
                dataArr[idx] = item.codeId;
            });
        }
        return dataArr;
    },
    filterObjToCommaStr: (obj, flag) => {
        let commaStr = '';
        if (flag === 0) {
            obj?.map((item, idx) => {
                if (idx === 0) {
                    commaStr += item.codeName;
                } else {
                    commaStr += ', ' + item.codeName;
                }
            });
        }

        return commaStr;
    },
    advFilterAmpParse: (obj, flag) => {
        if (flag === 0) {
            let filterCodeNameArr = [];
            obj?.map((item, idx) => {
                filterCodeNameArr[idx] = item.filterCodeName;
            });

            const set = new Set(filterCodeNameArr);

            const uniqueArr = [...set];

            let filterCodeNameStr = '';
            uniqueArr.map((name, idx) => {
                if (idx === 0) {
                    filterCodeNameStr += name;
                } else {
                    filterCodeNameStr += ', ' + name;
                }
            });

            return filterCodeNameStr;
        } else if (flag === 1) {
            let advCodeNameStr = '';

            obj?.map((item, idx) => {
                if (idx === 0) {
                    advCodeNameStr += item.name;
                } else {
                    advCodeNameStr += ', ' + item.name;
                }
            });

            return advCodeNameStr;
        }
    },
    getdistance: (latitude, longitude, golfClubLatitude, golfClubLongitude) => {
        // RN에서 받아온 현재 내 위치
        let lat1 = latitude;
        let lon1 = longitude;

        // 클럽하우스 기준 좌표
        let golfClubPoint = JSON.parse(localStorageUtil.get('checkInData'));
        let lat2 = golfClubLatitude;
        let lon2 = golfClubLongitude;

        // The math module contains a function
        // named toRadians which converts from
        // degrees to radians.
        lon1 = (lon1 * Math.PI) / 180;
        lon2 = (lon2 * Math.PI) / 180;
        lat1 = (lat1 * Math.PI) / 180;
        lat2 = (lat2 * Math.PI) / 180;
        // Haversine formula
        let dlon = lon2 - lon1;
        let dlat = lat2 - lat1;
        let a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
        let c = 2 * Math.asin(Math.sqrt(a));
        let r = 6371e3;
        // calculate the result
        return c * r;
    },

    /**
     * Safari browser 호환을 위해 '-' (하이픈) => '/' (슬래시) 변경 함수
     * @param selectDate (YYYY-MM-DD)
     */
    getCompatibleDate: (selectDate) => {
        let parseDate = selectDate.replaceAll('-', '/');

        return parseDate;
    }
};

export const localStorageUtil = {
    get: (name) => {
        return localStorage.getItem(name) || null;
    },
    set: (name, value) => {
        localStorage.setItem(name, value);
    },
    remove: (name) => {
        localStorage.removeItem(name);
    },
    allClear: () => {
        localStorage.clear();
    }
};

// 퍼미션 결과
export const RESULTS = Object.freeze({
    UNAVAILABLE: 'unavailable',
    BLOCKED: 'blocked',
    DENIED: 'denied',
    GRANTED: 'granted',
    LIMITED: 'limited'
});

export const getImagePermission = () => {
    const webview = window.ReactNativeWebView;
    if (!webview) return;
    let camera = localStorageUtil.get('CAMERA');
    let photo = localStorageUtil.get('PHOTO');

    if (!camera) {
        webview.postMessage(
            JSON.stringify({
                type: 'CAMERA'
            })
        );
    } else if (camera.toUpperCase() === RESULTS.BLOCKED.toUpperCase()) {
        webview.postMessage(
            JSON.stringify({
                type: 'SETTING'
            })
        );
    } else if (camera.toUpperCase() !== RESULTS.GRANTED.toUpperCase()) {
        webview.postMessage(
            JSON.stringify({
                type: 'CAMERA'
            })
        );
    }

    if (!photo) {
        webview.postMessage(
            JSON.stringify({
                type: 'PHOTO'
            })
        );
    }
};

export const getExternalStoragePermission = () => {
    const webview = window.ReactNativeWebView;
    if (!webview) return;
    let externalStorage = localStorageUtil.get('EXTERNAL_STORAGE');

    if (!externalStorage) {
        webview.postMessage(
            JSON.stringify({
                type: 'EXTERNAL_STORAGE'
            })
        );
    } else if (externalStorage.toUpperCase() === RESULTS.BLOCKED.toUpperCase()) {
        webview.postMessage(
            JSON.stringify({
                type: 'SETTING'
            })
        );
    } else if (externalStorage.toUpperCase() !== RESULTS.GRANTED.toUpperCase()) {
        webview.postMessage(
            JSON.stringify({
                type: 'EXTERNAL_STORAGE'
            })
        );
    }
};
