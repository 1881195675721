import React from 'react';
import Button from '../../components/button/Button';
// import Header from '../../components/Header';
// import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {history} from '../../_helpers';
import './membership.scss';
// import {authActions} from '../../_store';
import {useDispatch} from 'react-redux';
import Icon from '../icons/Icon';

function NotFoundAccount(props) {
    const {title, titleTxt} = props;

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();
    // const dispatch = useDispatch();

    //하단 버튼
    const nextPage = () => {
        history.navigate('/member/join', {replace: true});
        // dispatch(authActions.logout());
    };
    // 이전페이지로 되돌아가기
    const previousPage = () => {
        history.navigate(-1);
    };

    return (
        <div id="contents">
            <div className="completeArea" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <div className="completeMsg">
                        <i className="icon">
                            <Icon name="questionMark" width="60" height="60" viewBox="0 0 60 60" />
                        </i>
                        <strong className="tt">{title}</strong>
                        <p className="txt">{titleTxt}</p>
                    </div>

                    <div className="btnFixedBottom">
                        <div className="inner">
                            <Button type="button" darkGray onClick={previousPage}>
                                다시 시도하기
                            </Button>
                            <Button type="button" onClick={nextPage}>
                                회원가입
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFoundAccount;
