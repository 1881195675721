import React, {useCallback, useEffect, useState} from 'react';
import Header from '../../../components/Header';
import './getMyReservation.scss';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import {fetchWrapper, history} from '../../../_helpers';
import {useNavigate} from 'react-router-dom';
import AgreementDrawer from './AgreementDrawer';
import DefaultAlert from '../../alert/DefaultAlert';
import GetMyReservDatailTop from '../../../assets/images/reservation/my_reserve_detail_top.png';
import GetMyReservDatailMiddle from '../../../assets/images/reservation/my_reserve_detail_middle.png';
import GetMyReservDatailBottom from '../../../assets/images/reservation/my_reserve_detail_bottom.png';
import {SUCCESS_CODE} from '../../../common/constants';
import {connectOtherBookingComplete, viewOtherBookingCcList} from '_helpers/amplitude/events/view.events';
import {useDispatch, useSelector} from 'react-redux';
import {store, userActions} from '../../../_store';
import PolicyLayer from 'components/membership/PolicyLayer';
import {checkinActions} from '_store/checkin.slice';
import {localStorageUtil} from 'common/utils';

function GetMyReservation({title}) {
    const {height} = useWindowDimensions(); // 디바이스 높이값 체크
    history.navigate = useNavigate();

    // 이미 동의한 사용자일 경우 Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleAlertOpen = (msg) => {
        setAlertMessage(msg);
        setAlertOpen(true);
    };
    const handleAlertClose = () => setAlertOpen(false);

    // 이전 체크값 유지
    const agreementCheck = sessionStorage.getItem('agreementCheck');

    const [checkStatus, setCheckStatus] = useState(agreementCheck === 'true' ? true : false);

    useEffect(() => {
        sessionStorage.setItem('agreementCheck', checkStatus);
    }, [checkStatus]);

    const removeCheck = () => {
        sessionStorage.removeItem('agreementCheck');
    };

    const dispatch = useDispatch();

    // Drawer 제어
    const [drawerState, setDrawerState] = useState(false);
    const onClickClose = () => {
        setDrawerState(false);
    };

    // member api
    const [member, setMember] = useState(null);

    const getMemberInfo = useCallback(() => {
        fetchWrapper.get(`/member`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setMember(response.data || null);
            }
        });
    }, []);

    // 제휴골프장 보기 버튼
    const moveToPartners = () => {
        sessionStorage.setItem('keepCheck', true);

        history.navigate('/linked-golfclub-v2');
        viewOtherBookingCcList(); // 앰플리튜드 이벤트
    };

    //예약정보 불러오기 약관 레이어
    const [agreementOpen, setAgreementOpen] = useState(false);

    //예약정보 불러오기 약관 레이어 오픈
    const agreementLayerOpen = () => {
        setAgreementOpen(true);
    };

    //예약정보 불러오기 약관 레이어 닫기
    const agreementLayerClose = () => {
        setAgreementOpen(false);
    };

    useEffect(() => {
        getMemberInfo();
        return () => {
            if (!sessionStorage.getItem('keepCheck')) {
                removeCheck();
            }
        };
    }, []);

    const users = useSelector((x) => x?.users?.users);

    const {todayReserv} = useSelector((x) => x.checkin);

    //#region todo 공통화 || slice화 시키기
    // 예약정보 조회,전송 동의 처리
    const checkReservAgreement = (event) => {
        const payload = {
            agreementType: 'BOOKING_CHECK',
            isAgreement: 'true',
            email: users?.email
        };

        // 예약정보 조회,전송 동의 & User 정보 member에 저장
        fetchWrapper.post(`/member/change/agreement/status`, payload).then((res) => {
            store.dispatch(userActions.setUser({key: 'isBookingCheckAgree', value: 'true'}));
        });
    };

    // 예약정보 동의 -> 타 플랫폼 예약 api 호출 -> 금일 라운드 체크 후 navigate 이동
    const getMyReserve = async (event) => {
        // 예약정보 조회,전송 동의 api 호출
        if (!member.isBookingCheckAgree) {
            checkReservAgreement(event);
            // sessionStorage 체크값 삭제
            removeCheck();

            const curUrl = sessionStorage.getItem('curUrl');
            connectOtherBookingComplete({agree_type: 'detail_page'}, curUrl); // 앰플리튜드 이벤트 전송

            // 타 플랫폼 예약정보 불러오는 api 호출
            const resLinkedGolfClub = await fetchWrapper.get('/booking/linkedGolfClub');
            if (resLinkedGolfClub.code === SUCCESS_CODE) {
                // 금일 예약 라운드있는지 api 호출
                const resTodayReserv = await fetchWrapper.get('/booking/alert/0');
                if (resTodayReserv.code === SUCCESS_CODE) {
                    const todayReservData = resTodayReserv.data;

                    const roundData = {
                        title: '',
                        areaInfo: '',
                        golfClubId: '',
                        bookingId: '',
                        introUrl: '',
                        golfClubName: '',
                        roundAt: '',
                        courseName: ''
                    };

                    if (todayReservData) {
                        // 당일 예약 존재 && 코스가이드
                        //셀프체크인 활성화
                        roundData.title = todayReservData.introDesc;
                        roundData.areaInfo = todayReservData.address;
                        roundData.golfClubId = todayReservData.golfClubId;
                        roundData.bookingId = todayReservData.bookingId;
                        roundData.introUrl = todayReservData.videoClipUrl;
                        roundData.golfClubName = todayReservData.golfClubName;
                        roundData.roundAt = todayReservData.roundAt;
                        roundData.courseName = todayReservData.courseName;
                    }
                    // 라운드 있을 시 페이지 이동
                    history.navigate('/intro', {state: {roundData, intro: true}});
                } else {
                    // 라운드 없을 시 페이지 이동
                    history.navigate('/main');
                }
            }
        } else {
            handleAlertOpen('이미 예약정보 조회∙전송에 동의하셨습니다');
        }
    };
    //#endregion

    const moveSuccess = (event) => {
        const localStorageData = {
            getReservationAgree: true,
            date: new Date()
        };
        localStorageUtil.set('get_reservation_agree', JSON.stringify(localStorageData));

        getMyReserve(event);
        dispatch(checkinActions.setPromotionToast(true));
        setTimeout(() => {
            toastClose();
        }, 2000);
    };

    const toastClose = () => {
        dispatch(checkinActions.setPromotionToast(false));
    };

    return (
        <>
            <Header back={true} backLink={-1} />
            <div id="contents" style={{minHeight: `${height}px`, display: 'flex'}}>
                <div id="get-my-reserv" className="contentsArea">
                    <div className="reser-inner">
                        <section id="section_top">
                            <div className="tit">
                                더 스마트한 골라가 <br /> 라운드를 즐겨보세요
                            </div>
                            <div className="txt">
                                골프장 홈페이지 및 다른 예약 앱으로 예약했어도
                                <br />
                                골라가 앱 하나로 셀프체크인이 가능해요
                                <br />
                                <p>
                                    내장하기 전에 미리 하세요!<span className="under-line"></span>
                                </p>
                            </div>
                            <div className="img-area">
                                <img src={GetMyReservDatailTop} alt="나의 골프장 예약정보를 한 곳에서 관리하세요!" />
                            </div>
                        </section>
                        <section id="section_mid">
                            <div className="tit">골라가 셀프체크인은요?</div>
                            <div className="img-area">
                                <img src={GetMyReservDatailMiddle} alt="나의 골프장 예약정보를 한 곳에서 관리하세요!" />
                            </div>
                        </section>
                        <section id="section_btm">
                            <div className="tit">예약정보 불러오기란?</div>
                            <div className="txt">
                                홈페이지, 전화, 타 App에서 예약했어도
                                <br />
                                골라가 제휴 골프장이라면,
                                <br />
                                예약내역에서 확인할 수 있어요!
                            </div>
                            <div className="img-area">
                                <img src={GetMyReservDatailBottom} alt="나의 골프장 예약정보를 한 곳에서 관리하세요!" />
                            </div>
                            <div type="button" className="btnPartners" onClick={moveToPartners}>
                                제휴 골프장 보기
                                <div type="button" className="btn_ar">
                                    {/* 자세히 보기 */}
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="btnArea">
                        <div className="checkLabel">
                            <label>
                                <div className="customCheck">
                                    <input
                                        type="checkbox"
                                        onChange={() => {
                                            setCheckStatus((prev) => !prev);
                                        }}
                                        checked={checkStatus}
                                    />
                                    <i className={`icon ${checkStatus ? 'checked' : ''}`}>
                                        <svg
                                            width="100%"
                                            height="100%"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17 8L11.0525 15L7 10.9168"
                                                stroke={checkStatus ? '#3906C3' : '#A0A4AF'}
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </i>
                                </div>
                                <span className={checkStatus ? '' : 'gray'}>[필수] </span>
                                예약정보 조회 &middot; 전송동의
                            </label>
                            <button type="button" className="btn_ar" onClick={agreementLayerOpen}>
                                {/* 자세히 보기 */}
                            </button>
                        </div>
                        <button className="button btnPrimary" disabled={!checkStatus} onClick={moveSuccess}>
                            예약정보 불러오기
                        </button>
                    </div>
                </div>
            </div>
            {/* 예약정보 조회 동의 Drawer
            <AgreementDrawer isOpen={drawerState} onClickClose={onClickClose} /> */}
            {/* 예약정보 불러오기 약관 레이어 */}
            <PolicyLayer
                open={agreementOpen}
                agreeType="GET_RESERVATION_INFO_AGREE"
                title="예약정보 조회 · 전송동의"
                handleClose={agreementLayerClose}
            />
            {/*이미 동의한 사용자일 경우 Alert*/}
            <DefaultAlert
                open={alertOpen}
                handleOpen={handleAlertOpen}
                handleClose={handleAlertClose}
                title={''}
                contents={alertMessage}
            />
        </>
    );
}

export default GetMyReservation;
