import React from 'react';
import Modal from '@mui/material/Modal';

function PartnerDeleteAlert({open, handleClose, name, onDeleteMember, nonMember}) {
    // open State, 닫기 버튼 부모로부터 받아옴

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        {nonMember ? (
                            <div className="alertContents">선택한 동반자를 삭제하시겠습니까?</div>
                        ) : (
                            <div className="alertContents">{name}님을 동반자에서 삭제하시겠습니까?</div>
                        )}
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={handleClose}>
                            취소
                        </button>
                        <button type="button" onClick={onDeleteMember}>
                            삭제
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default PartnerDeleteAlert;
