import {store, authActions} from '_store';
import {localStorageUtil} from '../common/utils';
import {getCookie} from '../common/cookieUtils';
import {useDebugValue} from 'react';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

/**
 * request 호출
 * @param method
 * @param failCallBack
 * @param contentType
 * @returns {function(*, *): Promise<*>}
 */
function request(method, callBack = null, contentType = 'application/json') {
    return (url, body) => {
        let baseUrl = `${process.env.REACT_APP_API_URL}${url}`;
        const requestOptions = {
            method,
            headers: authHeader(baseUrl)
        };
        if (body) {
            // 이미지 업로드, body formData 체크
            if (body instanceof FormData) {
                requestOptions.body = body;
            } else {
                requestOptions.headers['Content-Type'] = contentType;
                requestOptions.body = JSON.stringify(body);
            }
        }
        return fetch(baseUrl, requestOptions)
            .then(handleResponse)
            .then((apiData) => {
                if (apiData?.isRefreshToken) {
                    return reCallApi(baseUrl, requestOptions, contentType);
                }
                // alertFlg 가 우선순위를 가짐.
                if (apiData?.alertFlag) {
                    /*
TODO ollie alertFlg true 일때, 레벨 확인 후 공통 alert 처리
CALLBACK은 alert 후 처리에 활용
*/
                }
                return apiData;
            })
            .catch((error) => {
                throw new Error(error);
            });
    };
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    const isLoggedIn = !!token;
    const accessToken = token?.accessToken;
    const refreshToken = token?.refreshToken;
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
    if (isLoggedIn && isApiUrl) {
        return {
            Authorization: `Bearer ${accessToken}`,
            MultiLogin: `${refreshToken}`
        };
    } else {
        return {};
    }
}

function authToken() {
    return store.getState().auth.user;
}

function refreshToken() {
    let baseUrl = `${process.env.REACT_APP_API_URL}/authentication/refresh/token`;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            accessToken: store.getState().auth.user?.accessToken,
            refreshToken: store.getState().auth.user?.refreshToken,
            visitInfo: window.visitInfo
        })
    };

    return fetch(baseUrl, requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            if (result.code !== '0000') {
                const logout = () => store.dispatch(authActions.logout());
                logout();
            }
            if (result.data?.accessToken !== store.getState().auth.user?.accessToken) {
                localStorageUtil.remove('user');
                localStorageUtil.set('user', JSON.stringify(result.data));
                const refresh = () => store.dispatch(authActions.refresh());
                refresh();
            }
            result.isRefreshToken = true;
            return result;
        })
        .catch(() => {
            const logout = () => store.dispatch(authActions.logout());
            logout();
        });
}

function reCallApi(baseUrl, requestOptions, contentType) {
    requestOptions.headers = authHeader(baseUrl);

    if (!(requestOptions?.body instanceof FormData)) {
        requestOptions.headers['Content-Type'] = contentType;
    }

    return fetch(baseUrl, requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        });
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403].includes(response.status) && authToken()) {
                return refreshToken();
            } else if ([499].includes(response.status) && authToken()) {
                alert('다른 기기에서 로그인하였습니다.');
                const logout = () => store.dispatch(authActions.logout());
                logout();
            } else {
                // TODO ollie 시스템 오류화면
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
