import React from 'react';
import Avatar from '../common/Avatar';

function PartnerItem({data, editable, handleAlertOpen, setDeleteMemberId, showIcon}) {
    const memberName = data?.name;
    const maskedName = memberName?.replace(memberName.substring(1, 2), '*'); // 마스킹 처리

    const golagaMember = data?.golagaMember;
    const gender = data?.gender;
    const inviteChannel = data?.inviteChannel;
    const checkinAt = data?.checkinAt;
    const phone = data?.phone;

    const lastFourDigits = phone.substring(phone.length - 4, phone.length);

    return (
        <>
            <li>
                <Avatar
                    handleAlertOpen={handleAlertOpen}
                    setDeleteMemberId={setDeleteMemberId}
                    data={data}
                    editable={editable}
                    showIcon={showIcon}
                />
                <div className="userInfo">
                    <strong className="name">
                        {/* 카카오로 초대된 비회원은 이름에 "비회원" 노출 */}
                        <i className="memberName">
                            {!golagaMember ? '수락대기' : maskedName}
                            {/* 회원일 시 남/여 노출 */}
                            {golagaMember && gender && `(${gender === 'MALE' ? '남' : '여'})`}
                        </i>

                        {/* 비회원일 시 초대 경로 아이콘 노출 */}
                        {!golagaMember && inviteChannel === 'KEYIN' ? (
                            <i className="icon iconPhone"></i>
                        ) : !golagaMember && inviteChannel === 'KAKAO' ? (
                            <i className="icon iconKakao"></i>
                        ) : null}

                        {/* KEYIN일 시, 휴대폰 뒷 4자리 노출 */}
                        {!golagaMember && inviteChannel === 'KEYIN' && (
                            <i className="lastFourDigits">{lastFourDigits}</i>
                        )}
                    </strong>
                    <div className="state">
                        {/* <span className="complete">카드등록 완료</span> */}
                        {/* <span>카드등록 대기</span> */}

                        {!golagaMember ? (
                            <span>초대장 발송 완료</span>
                        ) : golagaMember && !checkinAt ? (
                            <span>셀프체크인 대기</span>
                        ) : (
                            <span className="complete">셀프체크인 완료</span>
                        )}
                    </div>
                </div>
            </li>
        </>
    );
}

export default PartnerItem;
