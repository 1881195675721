import {createSlice} from '@reduxjs/toolkit';

const name = 'kakaoNotInstall';
const initialState = {
    type: 'kakaoNotInstall',
    open: false
};

const kakaoNotInstallSlice = createSlice({
    name,
    initialState,
    reducers: {
        openAlert: {
            reducer: (state, action) => {
                state.open = action.payload;
            }
        }
    }
});

export const kakaoNotInstallActions = {...kakaoNotInstallSlice.actions};
export const kakaoNotInstallReducer = kakaoNotInstallSlice.reducer;
