import React, {useContext, useState, useEffect, useCallback} from 'react';
import '../reservation.scss';
import SelectItemList from './SelectItemList';
import FilterDrawer from '../../common/FilterDrawer';
import {FieldMainContext} from '../../../_store/context/FieldMainContext';
import {fetchWrapper} from '../../../_helpers';
import {appUtils} from '../../../common/utils';
import {SUCCESS_CODE} from '../../../common/constants';
import {FieldMainContext_searchResult} from '../../../_store/context/FieldMainContext_SearchResult';
import {FieldMainContext_myPage} from '../../../_store/context/FieldMainContext_myPage';

function AdvancedFilterDrawer({
    advancedFilterOpen,
    setAdvancedFilterOpen,
    advancedFilterInitBtn,
    isAdvancedFilterReset,
    isAdvancedFilterInitBtnDisable,
    selectAdvancedItem,
    contextFlag,
    defaultAllGolfListCnt,
    searchWord
}) {
    const {
        copyAdvancedFilter,
        onAdvancedFilterClose,
        btnAdvancedApplyClicked,
        onBtnAdvancedApplyClicked,

        //기본 필터 리스트 카운트
        basicFilterDrawerCnt,
        setBasicFilterDrawerCnt,
        selectDate,
        // setFilterCntListData,
        // copyFilterCntListData,
        // setCopyFilterCntListData,

        //선택된 고급필터
        selectAdvancedListTemp,
        selectAdvancedList,

        region,
        teeTime,
        playerCnt,
        holeCnt,
        greenFee,
        paymentType,

        golfListLen
    } = useContext(
        contextFlag === 'main'
            ? FieldMainContext
            : contextFlag === 'search-result'
            ? FieldMainContext_searchResult
            : FieldMainContext_myPage
    );

    const [isSelectAdvancedFilter, setIsSelectAdvancedFilter] = useState(false);
    const [isFilterLoding, setIsFilterLoding] = useState(false);

    useEffect(() => {
        if (advancedFilterOpen === true) {
            // getFilterGolfclubCntList();
            setIsFilterLoding(false);
        }
    }, [advancedFilterOpen]);

    useEffect(() => {
        if (selectAdvancedListTemp.length > 0 || selectAdvancedList.length > 0) {
            setIsSelectAdvancedFilter(true);
        } else {
            setIsSelectAdvancedFilter(false);
        }
    }, [selectAdvancedListTemp, selectAdvancedList]);

    const makePayload = (page, sortOrder) => {
        const regionArr = appUtils.filterObjToArr(region, 0);
        //티타임
        const teeTimeArr = appUtils.filterObjToArr(teeTime, 0);
        //내장인원
        const playerCntArr = appUtils.filterObjToArr(playerCnt, 0);
        //홀 수

        const holeCntArr = appUtils.filterObjToArr(holeCnt, 0);
        //그린피
        const greenFeeArr = appUtils.filterObjToArr(greenFee, 0);

        /* 2023.07.07  */
        const paymentTypeArr = appUtils.filterObjToArr(paymentType, 0);

        //고급필터
        const selectTagIdListArr = appUtils.filterObjToArr(selectAdvancedListTemp, 1);

        // 리스트업
        return {
            address: regionArr,
            greenFeeCode: greenFeeArr,
            hole: holeCntArr,
            minimumRoundPlayer: playerCntArr,
            roundDate: appUtils.dateFormatTypeDash(selectDate),
            tagIdList: selectTagIdListArr,
            areaOrClubName: searchWord,
            teeTimeCode: teeTimeArr,
            userLat: 0,
            userLon: 0,
            page: 0,
            size: 0,
            sortOrder: 'DISTANCE',
            paymentType: paymentTypeArr
        };
    };

    const getFilterCount = useCallback(() => {
        const data = makePayload();
        setIsFilterLoding(true);

        let countApiUrl = '/golfClub/pre/golfclubs/count';

        if (contextFlag === 'mypage-favorites') {
            countApiUrl = '/golfClub/pre/golfclubs/like/count';
        } else {
            countApiUrl = '/golfClub/pre/golfclubs/count';
        }

        fetchWrapper.post(countApiUrl, data).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setBasicFilterDrawerCnt(response?.data?.count);
                setIsFilterLoding(false);
            }
        });
    }, [selectAdvancedListTemp, selectDate, region, teeTime, holeCnt, greenFee, playerCnt, paymentType]);

    useEffect(() => {
        if (advancedFilterOpen === true) {
            getFilterCount();
        }
    }, [selectAdvancedListTemp, advancedFilterOpen, selectDate]);

    return (
        <FilterDrawer
            isApplyBtnActive={isSelectAdvancedFilter}
            drawerOpen={advancedFilterOpen}
            drawerClose={onAdvancedFilterClose}
            title="고급 필터 설정"
            btnStatus="isDoubleBtn"
            filterInitBtn={advancedFilterInitBtn}
            isFilterInitBtnDisable={isAdvancedFilterInitBtnDisable}
            //고급 필터 적용
            //고급 필터 적용 버튼 클릭
            btnApplyClicked={btnAdvancedApplyClicked}
            onBtnApplyClicked={onBtnAdvancedApplyClicked}
            filterListCnt={basicFilterDrawerCnt}
            isFilterLoding={isFilterLoding}
        >
            <div>
                {copyAdvancedFilter
                    ? copyAdvancedFilter.map((data, idx) => {
                          return (
                              <SelectItemList
                                  selectCategoryItem={selectAdvancedItem}
                                  key={idx}
                                  data={data}
                                  isFilterReset={isAdvancedFilterReset}
                                  filterFlag="ADVANCED"
                              />
                          );
                      })
                    : ''}
            </div>
        </FilterDrawer>
    );
}

export default AdvancedFilterDrawer;
