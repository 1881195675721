import React, {useCallback, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {appUtils} from '../../../common/utils';
import LikeButton from '../../button/LikeButton';
import TeaTimeDrawer from '../drawer/TeaTimeDrawer';

function GolfClubListSlider({golfClubData, changeMapCenter, searchDate}) {
    // 티타임 정보 Layer
    const [teaTimeOpen, setTeaTimeOpen] = useState(false);
    const onTeaTimeOpen = () => {
        setTeaTimeOpen(true);
    };

    // 클릭한 리스트 골프 클럽 아이디
    const [golfClubId, setGolfClubId] = useState(null);

    const handleSliderChange = useCallback(
        (e) => {
            const idx = e.activeIndex;
            changeMapCenter(golfClubData[idx].clubLat, golfClubData[idx].clubLon);
        },
        [golfClubData]
    );

    return (
        <>
            <Swiper
                speed={800}
                slidesPerView={'auto'}
                spaceBetween={12}
                centeredSlides={true}
                onSlideChange={handleSliderChange}
                className="golfClubList"
            >
                {golfClubData?.map((list) => {
                    return (
                        <SwiperSlide className="list" key={list?.id}>
                            <div className="golfClubBox">
                                <div
                                    className="infoArea"
                                    onClick={() => {
                                        onTeaTimeOpen();
                                        setGolfClubId(list?.id);
                                    }}
                                >
                                    <strong className="place">{list?.clubName}</strong>
                                    <span className="location">
                                        {list?.clubArea} · {list?.distance}km
                                    </span>
                                    <b className="price">
                                        {list?.remainingCnt === 0
                                            ? '잔여 티타임이 없습니다.'
                                            : `${appUtils.numberFormat(list?.greenFee)}원~`}
                                    </b>
                                </div>
                                <div className="sideArea">
                                    <LikeButton clubId={list?.id} interest={list?.interest} golfClubName={list?.golfClubName}/>
                                    <strong className="count">{list?.remainingCnt}팀</strong>
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            {/*티타임 정보 Layer*/}
            <TeaTimeDrawer
                teaTimeOpen={teaTimeOpen}
                setTeaTimeOpen={setTeaTimeOpen}
                searchDate={searchDate}
                golfClubId={golfClubId}
            />
        </>
    );
}

export default GolfClubListSlider;
