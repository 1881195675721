import React, { useEffect } from "react";
import TitleArea from "../../components/common/TitleArea";
import Icon from "../icons/Icon";
import Button from "../../components/button/Button";

const styles = {
    fillIconColor: "#212121"
};

function AppPermission({ title }) {
    // 스크롤 없는 풀페이지 스타일 선언을 위한 클래스명 추가
    useEffect(() => {
        document.documentElement.classList.add("fullPage");
        return () => {
            document.documentElement.classList.remove("fullPage");
        };
    }, []);

    return (
        <>
            <div id="contents">
                <div id="appPermission" className="contentsArea">
                    <div className="inner">
                        <TitleArea
                            pageTitle={title}
                            titleTxt={
                                "그린 골프예약 서비스 이용을 위해 \n 아래 권한을 허용해주세요."
                            }
                        />
                        <div className="infoCon">
                            <section>
                                <h2>필수 접근 권한</h2>
                                <p>필수 접근 권한 없음</p>
                            </section>
                            <section>
                                <h2>선택적 접근 권한</h2>
                                <ul className="prmList">
                                    <li>
                                        <i className="icon">
                                            <Icon
                                                name="gps"
                                                fill={styles.fillIconColor}
                                            />
                                        </i>
                                        <div className="txtArea">
                                            <strong>GPS</strong>
                                            <span>셀프 체크인, 거리 측정</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="icon">
                                            <Icon
                                                name="gallery"
                                                fill={styles.fillIconColor}
                                            />
                                        </i>
                                        <div className="txtArea">
                                            <strong>
                                                카메라 · 갤러리 · 저장공간
                                            </strong>
                                            <span>
                                                프로필 사진촬영 및 이미지 수정
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="icon">
                                            <Icon
                                                name="phone"
                                                fill={styles.fillIconColor}
                                            />
                                        </i>
                                        <div className="txtArea">
                                            <strong>전화</strong>
                                            <span>
                                                휴대폰 본인 인증 및 골프장 대표
                                                전화 연결
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className="icon">
                                            <Icon
                                                name="user"
                                                fill={styles.fillIconColor}
                                            />
                                        </i>
                                        <div className="txtArea">
                                            <strong>주소록</strong>
                                            <span>
                                                골프 예약 인원 초대 시 주소록
                                                검색 기능
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </section>
                        </div>
                        <p className="agreeInfo">
                            선택권한의 경우 서비스 사용 중, 필요한 시점에 동의를
                            받고 <br />
                            있습니다. 허용하지 않아도 서비스 이용 가능합니다.
                        </p>
                        <div className="btnFixedBottom">
                            <div className="inner">
                                <Button type="button">확인</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AppPermission;
