import React from "react";
import IdentificationSKT from "./IdentificationSKT";
import IdentificationLGT from "./IdentificationLGT";
import IdentificationKTF from "./IdentificationKTF";

function Identification({ telecomCode }) {
    const Identification = () => {
        const type = telecomCode;

        if (type === "SKT" || type === "SKM") {
            return <IdentificationSKT />;
        } else if (type === "LGT" || type === "LGM") {
            return <IdentificationLGT />;
        } else if (type === "KTF" || type === "KTM") {
            return <IdentificationKTF />;
        }
    };

    return (
        <>
            {Identification()}
            <section>
                <h2>&lt;SCI평가정보(주) 귀중&gt;</h2>
                <p>
                    SCI평가정보(주) (이하 “회사”)가 SK텔레콤(주), (주)케이티,
                    LG유플러스(주)의 업무 대행을 통해 제공하는 휴대폰
                    본인확인서비스와 관련하여 고객으로부터 수집한 고유식별정보를
                    이용 및 처리하거나 제3자에게 제공하고자 할 때에는
                    ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’에 따라서
                    고객으로 부터 동의를 받아야 합니다.
                </p>
                <br />
                <h3>제 1 조 [고유식별정보의 이용 및 처리 목적]</h3>
                <p>
                    “회사”는 고객의 고유식별정보를 아래의 목적으로 이용 및
                    처리할 수 있습니다.
                    <br />
                    1. 정보통신망법 제23조에서 정한 본인확인서비스 제공
                    <br />
                    2. 부정 이용 방지 및 수사의뢰
                    <br />
                    3. 휴대폰번호보호서비스 해제 (서비스 가입자에 한함)
                </p>
                <br />
                <h3>제 2 조 [고유식별정보의 이용 및 처리 내용]</h3>
                <p>
                    SK텔레콤(주), (주)케이티, LG유플러스(주)의 통신사 또는
                    통신사의 대행업무를 수행하는 사업자에 정보를 전송하여
                    방송통신위원회에서 고시로 지정한 휴대폰본인확인기관을 통해
                    본인여부를 확인하고 결과를 제공받기 위해 고유식별정보가 이용
                    및 처리됩니다.
                    <br />- 이용 및 처리되는 고유식별정보 :{" "}
                    <strong className="underLine">
                        주민등록번호(내국인), 외국인등록번호(외국인)
                    </strong>
                    <br />
                    <br />
                    상기와 같이 고유식별정보 이용 및 처리에 동의합니다.
                </p>
            </section>
        </>
    );
}

export default Identification;
