import React, {useCallback, useEffect, useState} from 'react';
import PartnerTooltip from '../tooltip/PartnerTooltip';
import PartnerDeleteAlert from '../alert/PartnerDeleteAlert';
import PartnerInviteModal from '../common/PartnerInviteModal';
import PartnerItem from './PartnerItem';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import DefaultAlert from '../alert/DefaultAlert';
import PartnerInviteIconList from '../common/PartnerInviteIconList';
import CommonAlert from '../alert/CommonAlert';
import Modal from '@mui/material/Modal';
import {useDispatch, useSelector} from 'react-redux';
import {kakaoNotInstallActions} from '../../_store/kakaoNotInstall.slice';
import {installLink} from '../../common/links';

function Partner({
    editable,
    bookingMember,
    getBookingMemberList,
    assignAlertOpen,
    checkInData,
    maximumPlayer,
    roundPlayer,
    setMemberReset,
    showIcon,
    bookingData
}) {
    const dispatch = useDispatch();
    const alertData = useSelector((x) => x.alert);

    // 동반자 삭제 안내 Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedName, setSelectedName] = useState(null);
    const handleAlertOpen = (e) => {
        const name = e.target.dataset.name;
        setSelectedName(name);
        setAlertOpen(true); // 동반자 삭제 임시 중단 해제
    };
    const handleAlertClose = () => setAlertOpen(false);

    // 동반자 삭제 오류 Alert
    const [alertContents, setAlertContents] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false);
    const deleteErrorAlertOpen = () => setDeleteOpen(true);
    const deleteErrorAlertClose = () => setDeleteOpen(false);

    // 동반자 초대방식 선택 팝업
    const [InviteOpen, setInviteOpen] = useState(false);
    const handleInviteOpen = () => setInviteOpen(true);
    const handleInviteClose = (isInvite) => {
        setInviteOpen(false);

        // 멤버 추가했을때 멤버 정보리셋
        isInvite && setMemberReset(true);
    };

    // 새로고침 rotate 이벤트 임시 처리
    const [isRefreshing, setIsRefreshing] = useState(false);

    const setBookingMember = () => {
        getBookingMemberList(); // bookingMember 재렌더링
        assignAlertOpen(); // 카트/캐디 알림배너 최초 1번만 실행

        // 새로고침 rotate 이벤트 임시 처리
        setIsRefreshing(true);
        setTimeout(() => setIsRefreshing(false), 1000);
    };

    // ============== 동반자 삭제 ===============
    const [memberId, setMemberId] = useState(null);
    const [bookingId, setBookingId] = useState(null);

    const onDeleteMember = useCallback(() => {
        if (memberId) {
            fetchWrapper.delete(`/booking/invite/${bookingData.id}/${memberId}`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    // 리렌더링
                    setMemberReset(true);
                    handleAlertClose();
                } else {
                    // 실패 시 얼럿
                    handleAlertClose();
                    deleteErrorAlertOpen();
                    setAlertContents('시스템 오류로 인해 정상적으로 처리되지 않았습니다.');
                }
            });
        }
    }, [bookingData, memberId]);

    // memberId 세팅
    const setDeleteMemberId = (id) => {
        setMemberId(id);
    };

    // 친구 추가 버튼 노출 조건
    const [invitationable, setInvitationable] = useState(false);

    useEffect(() => {
        if (maximumPlayer > roundPlayer) {
            setInvitationable(true);
        } else {
            setInvitationable(false);
        }
    }, [maximumPlayer, roundPlayer]);

    const inviteIconNum = maximumPlayer - roundPlayer;

    // 초대 확인 Alert 확인 클릭 시 멤버 리셋
    const goSetting = useCallback(async () => {
        setMemberReset(true);
    }, []);

    // ===== 카카오톡 미 설치 시 설치 페이지로 이동 =====
    // 플레이스토어, 앱스토어 링크
    const androidLink = installLink.KAKAO_ANDROID;
    const iosLink = installLink.KAKAO_IOS;

    // 디바이스 구분
    let _device = '';
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) {
        _device = 'android';
    } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
        _device = 'ios';
    }

    // 디바이스 초기값 세팅
    const [device, setDevice] = useState(_device);

    function redirectStore() {
        if (device === 'android') {
            window.location.assign(androidLink);
        } else if (device === 'ios') {
            window.location.assign(iosLink);
        }
    }

    // 카톡 미 설치 시 Alert
    const kakaoNotInstall = useSelector((x) => x?.kakaoNotInstall?.open);
    const [resFailAlert, setResFailAlert] = useState(false);
    const resFailAlertOpen = () => setResFailAlert(true);
    const resFailAlertClose = () => {
        setResFailAlert(false);
        dispatch(
            kakaoNotInstallActions.openAlert({
                open: false
            })
        );
    };
    const onCloseAlert = () => {
        handleInviteOpen();
        resFailAlertClose();
    };

    useEffect(() => {
        if (kakaoNotInstall?.open) {
            resFailAlertOpen();
        }
    }, [kakaoNotInstall]);

    return (
        <>
            <div id="partner" className="smCard">
                <div>
                    <div className="ttArea">
                        <h2>동반자 정보 현황</h2>
                        <div className="refreshArea">
                            {/* <span className="time">5분 전</span> */}
                            <button onClick={setBookingMember} className={`btnRefresh ${isRefreshing ? 'rotate' : ''}`}>
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div className="partnerInfoWrap">
                        <ul className="listCon">
                            {/* user가 host(true)일 때만 delete & btnAdd 노출 */}
                            {bookingMember?.length
                                ? bookingMember.map((data, idx) => {
                                      return (
                                          <PartnerItem
                                              key={data.id}
                                              data={data}
                                              editable={editable}
                                              handleAlertOpen={handleAlertOpen}
                                              setDeleteMemberId={setDeleteMemberId}
                                              showIcon={showIcon}
                                          />
                                      );
                                  })
                                : ''}
                            {/* 동반자 초대하기 */}
                            {editable && invitationable && showIcon && (
                                <PartnerInviteIconList
                                    inviteIconNum={inviteIconNum}
                                    handleInviteOpen={handleInviteOpen}
                                    smroundInvitation={invitationable}
                                />
                            )}
                        </ul>
                        <div className="bookingAlert">
                            <strong className="AlertTxt">예약 양도 및 취소 안내</strong>
                            <PartnerTooltip placement="top" checkInData={checkInData} />
                        </div>
                    </div>
                </div>
            </div>

            {/* 동반자 삭제 안내 Alert */}
            <PartnerDeleteAlert
                open={alertOpen}
                handleClose={handleAlertClose}
                name={selectedName}
                onDeleteMember={onDeleteMember}
            />

            {/* 동반자 초대방식 선택 팝업  */}
            <PartnerInviteModal
                InviteOpen={InviteOpen}
                handleInviteClose={handleInviteClose}
                setInviteOpen={setInviteOpen}
                bookingData={bookingData}
                setMemberReset={setMemberReset}
                resFailAlertOpen={resFailAlertOpen}
            />

            {/* 동반자 삭제 오류 Alert */}
            <DefaultAlert
                open={deleteOpen}
                handleClose={deleteErrorAlertClose}
                title={'시스템 장애 발생'}
                contents={alertContents}
            />

            {/* 카카오톡 초대완료 후 확인 Alert */}
            {(alertData?.open && alertData?.id === 'partnerInvite' && (
                <CommonAlert id={alertData?.id} successCallBack={goSetting} kakaoNotInstall={true} />
            )) ||
                ''}

            {/*<CommonAlert successCallBack={goSetting} kakaoNotInstall={true} />*/}

            {/* 카카오톡 미설치 시 확인 Alert */}
            <Modal open={resFailAlert} onClose={resFailAlertClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">서비스 안내</div>
                        <div className="alertContents">
                            카카오톡 앱이 설치되어 있지 않습니다.
                            <br /> 앱을 설치하시겠습니까?
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" className="button cancel" onClick={onCloseAlert}>
                            취소
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                resFailAlertClose();
                                // 카카오톡 설치 페이지로 이동
                                redirectStore();
                            }}
                        >
                            확인
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default React.memo(Partner);
