import React from 'react';

function BookingPersonalInfo({golfClubName}) {
    return (
        <>
            <section>
                <h2>개인정보 제3자 제공 동의 (골프장 예약 시 동의) </h2>
                <p>
                    회사는 회원의 개인정보를 골프장 예약 서비스 계약 이행 및 커뮤니케이션을 위해 서비스 예약/결제 시
                    개인정보 제공에 대한 동의를 구한 후 아래의 정보를 제3자에게 제공하고 있습니다. 본 개인정보 제3자
                    제공에 대한 동의를 거부하실 수 있으며, 이 경우 골프장 예약 서비스 이용이 제한될 수 있습니다.
                </p>
                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                    <colgroup>
                        <col style={{width: '20%'}} />
                        <col style={{width: '28%'}} />
                        <col style={{width: '28%'}} />
                        <col style={{width: '24%'}} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>제공 받는 자</th>
                            <th>제공목적</th>
                            <th>제공항목</th>
                            <th>보유/이용기간</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="alignC">[{golfClubName}]</td>
                            <td>고객 예약 확인 및 안내</td>
                            <td>예약자 명, 예약자 성별, 예약자 휴대전화번호</td>
                            <td>회원탈퇴 또는 동의 철회 시까지 단, 관계 법령이 정한 시점까지 보존</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </>
    );
}

export default BookingPersonalInfo;
