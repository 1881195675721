import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import {Link, useLocation} from 'react-router-dom';
import {appUtils, localStorageUtil} from '../../common/utils';
import {fetchWrapper, history} from '../../_helpers';
import DirectionSnackbar from 'components/alert/DirectionSnackbar';
import {SUCCESS_CODE} from 'common/constants';
import {includes} from 'lodash';
import './intro.scss';

function Intro() {
    const location = useLocation();

    const [roundData, setRoundData] = useState(location?.state?.roundData);
    const serenity = roundData?.golfClubId === '2c5ca1ef-b336-11ed-ae09-040300000000';

    // member api
    const [member, setMember] = useState(null);

    // snackBar
    const [snackBarOpen, setSnackBarOpen] = useState({
        open: false,
        msg: ''
    });

    const changeDate = appUtils.dateFormatTypeBoard(Date.now());

    const {REACT_APP_ASSET_URL} = process.env;

    // 스크롤 없는 풀페이지 스타일 선언을 위한 클래스명 추가
    useEffect(() => {
        let skip = JSON.parse(localStorageUtil.get('skip'));

        let isSkip =
            skip?.length > 0
                ? skip?.findIndex((item) => item?.bookingId === location?.state?.roundData?.bookingId) !== -1
                : false;

        if (isSkip) {
            history.navigate('/main');
        }

        document.documentElement.classList.add('fullPage');
        return () => {
            document.documentElement.classList.remove('fullPage');
        };
    }, []);

    const onClickSkipIntro = () => {
        let intro = {
            bookingId: roundData?.bookingId,
            date: appUtils.getYYYYMMDDD()
        };
        let skip = JSON.parse(localStorageUtil.get('skip'));

        // 값이 없거나 객체일 경우 빈배열 선언
        if (!skip || !Array.isArray(skip)) skip = [];

        skip = [...skip, intro];

        localStorageUtil.set('skip', JSON.stringify(skip));
        // window.ReactNativeWebView?.clearHistory();
    };
    // debugger;

    useEffect(() => {
        let localStorage = localStorageUtil.get('get_reservation_agree');
        if (localStorage) {
            localStorage = JSON.parse(localStorage);
        } else {
            localStorage = null;
        }
        const prevUrl = sessionStorage.getItem('prevUrl');
        const currentUrl = sessionStorage.getItem('curUrl');
        console.log(localStorage, prevUrl);
        if (
            localStorage &&
            localStorage.getReservationAgree === true &&
            ['/main', '/get-my-reservation', '/mypage'].includes(prevUrl) &&
            !['/booking', '/mypage/booking'].includes(currentUrl)
        ) {
            setSnackBarOpen({
                open: true,
                msg:
                    `${changeDate}` +
                    '에 예약정보 불러오기에 \n 동의하였습니다.\n 내정보 수정하기에서 동의를 취소할 수 있습니다.'
            });
        }

        // fetchWrapper.get(`/member`, null).then((response) => {
        //     if (response?.code === SUCCESS_CODE) {
        //         setMember(response.data || null);

        //         setSnackBarOpen({
        //             open: true,
        //             msg:
        //                 `${changeDate}` +
        //                 '에 예약정보 불러오기에 \n 동의하였습니다.\n 내정보 수정하기에서 동의를 취소할 수 있습니다.'
        //         });
        //     }
        // });
    }, []);

    return location?.state?.intro ? (
        <>
            <div className="videoPlayerArea">
                {serenity ? (
                    <>
                        <div className="full-bg-serenity"></div>
                        <div className="auto-screen-serenity">
                            <ReactPlayer
                                url={REACT_APP_ASSET_URL + roundData?.introUrl}
                                playing={true}
                                muted={true}
                                playsinline={true}
                                light={false}
                                loop={true}
                                width="100%"
                                height="100%"
                                className="videoPlayer"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {/* FullScreen Player */}
                        <div className="fullScreenPlayer">
                            <ReactPlayer
                                url={REACT_APP_ASSET_URL + roundData?.introUrl}
                                playing={true}
                                muted={true}
                                playsinline={true}
                                light={false}
                                loop={true}
                                width="100%"
                                height="100%"
                                className="videoPlayer"
                            />
                        </div>

                        {/* AutoScreen Player */}
                        <div className="autoScreenPlayer">
                            <ReactPlayer
                                url={REACT_APP_ASSET_URL + roundData?.introUrl}
                                playing={true}
                                muted={true}
                                playsinline={true}
                                light={false}
                                loop={true}
                                width="100%"
                                height="100%"
                                className="videoPlayer"
                            />
                        </div>
                    </>
                )}

                <div className="introTxtArea">
                    <div className="txtCon">
                        <div className="flexCon">
                            <p className="title">{roundData?.title}</p>
                            <strong className="golfClubName">{roundData?.golfClubName}</strong>
                            <div className="bookingInfo">
                                <p className="date">{appUtils.dateFormatTypeRoundAt(roundData?.roundAt)}</p>
                                <div className="location">
                                    <span>{roundData?.areaInfo}</span>
                                    <span>{roundData?.courseName}</span>
                                </div>
                            </div>
                        </div>

                        {/* 건너띄기 */}
                        <Link
                            to={`/self-check-in-v2?bookingId=${roundData?.bookingId}&golfClubId=${roundData?.golfClubId}`}
                            onClick={onClickSkipIntro}
                            className="button btnSkip"
                        >
                            셀프체크인 시작하기
                            <span className="btn_ar"></span>
                        </Link>
                    </div>
                </div>
            </div>
            <DirectionSnackbar
                direction={'up'}
                position={'bottom'}
                open={snackBarOpen.open}
                msg={snackBarOpen.msg}
                duration={2000}
                setOpen={setSnackBarOpen}
            />
        </>
    ) : null;
}

export default Intro;
