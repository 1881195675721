import React from 'react';
import HistoryItem from './HistoryItem';
import './GolfZoneSearch.scss';

function HistoryList({historyList, onRemove, onClickHistoryItem}) {
    return (
        <ul className="historyList">
            {historyList.map((historyItem) => (
                <HistoryItem
                    historyItem={historyItem}
                    key={historyItem.id}
                    onRemove={onRemove}
                    onClickHistoryItem={onClickHistoryItem}
                />
            ))}
        </ul>
    );
}

export default HistoryList;
