import React from 'react';

function AllCheckbox({children, allChecked, toggleAllCheck, allCheckDisabled}) {
    return (
        <label>
            <div className="customCheck">
                <input type="checkbox" disabled={allCheckDisabled} checked={allChecked} onChange={toggleAllCheck} />
                <i className={`icon round ${allChecked ? 'checked' : ''}`}>
                    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill={allChecked ? '#3906C3' : 'white'} />
                        <path
                            d="M17 9L11.0525 16L7 11.9168"
                            stroke={allChecked ? 'white' : '#A0A4AF'}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </i>
            </div>
            {children}
        </label>
    );
}

export default AllCheckbox;
