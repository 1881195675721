import React, {useState, useEffect, useCallback, useRef} from 'react';
import TitleArea from '../common/TitleArea';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination, EffectCoverflow} from 'swiper';
import {useLocation} from 'react-router-dom';
import {fetchWrapper} from '../../_helpers';
import {ALREADY_CHECKEDIN, CHECKIN_TIME_ONE_HOUR_CODE, SUCCESS_CODE} from '../../common/constants';
import CheckIn from './checkin/CheckIn';
import Partner from './Partner';
import Header from '../Header';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {useSelector} from 'react-redux';
import {tossUtils} from '../../common/tossUtils';
import {appUtils, localStorageUtil} from '../../common/utils';
import './smartRound.scss';
import CheckInBackGround from './checkin/CheckInBackGround';
import MapFloatingButton from './MapFloatingButton';
import DefaultAlert from '../alert/DefaultAlert';
import {debounce} from 'lodash';
import {viewCompanionInfo} from '../../_helpers/amplitude/events/view.events';
import {failSelfCheckinGps} from '../../_helpers/amplitude/events/etc.event';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';

SwiperCore.use([Pagination, EffectCoverflow]);

let timer = null;
let _time = 0;
const startTimer = (inTime, timeOver) => {
    _time = 59;
    timer = setInterval(() => {
        _time--;
        if (_time > 0) {
            inTime(_time);
        } else {
            timeOver();
            clearInterval(timer);
        }
    }, 1000);
};

function SmartRound({onClose}) {
    const refSwiper = useRef();
    const location = useLocation();
    const [roundData, setRoundData] = useState(location?.state?.roundData);
    const [gfClubId, setGfClubId] = useState(roundData?.golfClubId);
    const [cardTitle, setCardTitle] = useState('');

    // 셀프 체크인 비디오 플레이어
    const [playing, setPlaying] = useState(true);

    // BookingMemberList
    const [cartList, setCartList] = useState(null);
    const [caddieList, setCaddieList] = useState(null);
    const [golfBagList, setGolfBagList] = useState(null);
    const [maximumPlayer, setMaximumPlayer] = useState(null);
    const [roundPlayer, setRoundPlayer] = useState(null);
    const [bookingMember, setBookingMember] = useState(null);
    const [bookingData, setBookingData] = useState(null);
    const [roundAt, setRoundAt] = useState(null);
    const [slideIdx, setSlideIdx] = useState(0);
    // GPS 기반 / QR 체크인 화면 전환
    const [qrCheckIn, setQrCheckIn] = useState(false);

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    // 셀프체크인 준비완료
    const [checkInReady, setCheckInReady] = useState(false);

    // CheckIn Main Data
    const [checkInData, setCheckInData] = useState(null);

    // User Token Id
    const auth = useSelector((x) => x?.auth?.user);
    let userTokenId = tossUtils.decodeSubject(auth.accessToken);

    // 로그인된 유저 찾기
    const [loggedInUser, setLoggedInUser] = useState(null);

    //GPS 위성 정보(현재 좌표) 확인
    let locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
    const [latitude, setLatitude] = useState(locationStorage?.lat || '');
    const [longitude, setLongitude] = useState(locationStorage?.lon || '');

    // 라운드 남은 시간 계산
    // let roundAtStr = checkInData?.roundAt;
    // let year = roundAtStr?.split('T')[0].split('-')[0];
    // let month = roundAtStr?.split('T')[0].split('-')[1];
    // let day = roundAtStr?.split('T')[0].split('-')[2];
    // let hour = roundAtStr?.split('T')[1].split(':')[0];
    // let min = roundAtStr?.split('T')[1].split(':')[1];
    // let bookingAt = new Date(year, month > 1 ? month - 1 : 0, day, hour, min).getTime();
    // let leftTime = bookingAt - new Date().getTime();
    // let leftHour = leftTime / 1000 / 60 / 60;
    // const [leftHour, setLeftHour] = useState(-1);
    // const [alertMessage, setAlertMessage] = useState('');

    // 셀프 체크인 불가 Alert
    const [selfCheckIn, setSelfCheckIn] = useState(false);
    const [alertContents, setAlertContents] = useState('');
    const handleSelfCheckInOpen = () => setSelfCheckIn(true);
    const handleSelfCheckInClose = () => setSelfCheckIn(false);

    // top > 셀프체크인 버튼
    const gpsOn = () => {
        setQrCheckIn(false);
    };

    // localstorage setting
    const onAssignAlertSkip = () => {
        let smartRound = {
            bookingId: roundData?.bookingId,
            date: appUtils.getYYYYMMDDD() // 년/월/일
        };
        localStorageUtil.set('smart-round', JSON.stringify(smartRound));
    };

    // 카트 및 캐디 배정 Alert - 최초 1번만 띄우고 skip
    const [assignAlert, setAssignAlert] = useState(false);
    const assignAlertOpen = () => {
        let smartRound = JSON.parse(localStorageUtil.get('smart-round'));
        if (smartRound?.bookingId === roundData?.bookingId && smartRound?.date === appUtils.getYYYYMMDDD()) {
            return;
        }
        if (cartList && caddieList) {
            setAssignAlert(true);
            onAssignAlertSkip();
        }
    };

    const onSlideChange = (e) => {
        setCardTitle(e.slides[e.activeIndex].dataset.title);

        // 셀프 체크인 비디오 플레이어
        if (e.activeIndex === 0) {
            setSlideIdx(0);
            setPlaying(true);
        } else {
            setSlideIdx(1);
            setPlaying(false);
        }
    };
    const onSwiper = (e) => {
        setCardTitle(e.slides[e.activeIndex].dataset.title);

        // 셀프 체크인 비디오 플레이어
        if (e.activeIndex === 0) {
            setPlaying(true);
        }
    };

    const [qrReset, setQrReset] = useState(false);
    const [isQrCheck, setIsQrCheck] = useState(false);
    // Get 'Checkin Data'
    const getCheckInData = () => {
        // localStorageUtil.remove("checkInData");
        localStorageUtil.set('roundData', JSON.stringify(roundData));

        if (roundData?.bookingId) {
            fetchWrapper.get(`/booking/${roundData?.bookingId}/round`, null).then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    setCheckInData(response?.data);
                    setIsQrCheck(response?.data?.isQrCheckin);
                    if (!response.data.isSmartRoundApplied) {
                        refSwiper.current.swiper.slideTo(1);
                    }

                    localStorageUtil.set('checkInData', JSON.stringify(response?.data));
                }
            });
        }
    };

    useEffect(() => {
        getCheckInData();
    }, [roundData?.bookingId, checkInReady, qrReset]);

    // 편집권한 부여 (조건 동반자 목록 존재, isHost true)
    const [editable, setEditable] = useState(false);

    // 로그인된 유저 찾기
    const setloggedInUser = useCallback(() => {
        if (tossUtils.decodeSubject(auth.accessToken) && bookingMember?.length) {
            let userInfo = null; // 로그인된 유저정보 담을 곳
            bookingMember.forEach((v) => {
                // Id가 일치하면 해당 객체 전송
                if (v.memberId === tossUtils.decodeSubject(auth.accessToken) || null) {
                    userInfo = v;
                    setLoggedInUser(userInfo);

                    // host는 편집권한 부여
                    if (v.host === true) {
                        setEditable(true);
                    } else {
                        setEditable(false);
                    }
                    return;
                }
            });
        }
    }, [bookingMember, qrReset]);

    useEffect(() => {
        setloggedInUser();
    }, [setloggedInUser]);

    // Get 'BookingMember List'
    const [memberReset, setMemberReset] = useState(false);

    const getBookingMemberList = useCallback(() => {
        roundData?.bookingId &&
            fetchWrapper.get(`/booking/member-list/${roundData?.bookingId}`, null).then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    let maximumPlayer = response?.data?.playerLimit;
                    let roundPlayer = response?.data?.roundPlayer;

                    viewCompanionInfo(roundPlayer);

                    setBookingData(response?.data || null);
                    setMaximumPlayer(maximumPlayer || null);
                    setRoundPlayer(roundPlayer || null);
                    setBookingMember(response?.data?.bookingMemberList || null);
                    setMemberReset(false);
                    setRoundAt(response?.data?.roundAt || null);

                    const bookingDay = new Date(response.data.roundAt);
                    const today = new Date();
                    let isToday = true;
                    if (bookingDay.getDay() > today.getDay()) isToday = false;
                    if (bookingDay.getMonth() > today.getMonth()) isToday = false;
                    if (bookingDay.getFullYear() > today.getFullYear()) isToday = false;

                    if (!isToday) {
                        refSwiper.current.swiper.slideTo(1);
                    }
                }
            });
    }, [roundData?.bookingId, checkInReady, memberReset]);

    useEffect(() => {
        getBookingMemberList();
    }, [getBookingMemberList]);

    // Get 'Caddie List'
    const getCaddieData = useCallback(() => {
        if (roundData?.bookingId) {
            setCaddieList(null);
            fetchWrapper.get(`/booking/${roundData?.bookingId}/caddies`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    let caddieList = response?.data?.caddieList;
                    setCaddieList(caddieList?.length ? caddieList : null);
                }
            });
        }
    }, [roundData?.bookingId, checkInReady]);

    useEffect(() => {
        getCaddieData();
    }, [getCaddieData]);

    // Get 'Cart List'
    const getCartData = useCallback(() => {
        if (roundData?.bookingId) {
            setCartList(null);
            fetchWrapper.get(`/booking/${roundData?.bookingId}/carts`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    let cartList = response?.data?.golfCartList;
                    setCartList(cartList?.length ? cartList : null);
                }
            });
        }
    }, [roundData?.bookingId, checkInReady]);

    useEffect(() => {
        getCartData();
    }, [getCartData]);

    // Get 'Golf Bag List'
    const getGolfBagData = useCallback(() => {
        if (loggedInUser?.memberId) {
            fetchWrapper.get(`/member/bags`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    let golfBag = response?.data?.golfBagList;
                    setGolfBagList(golfBag?.length ? golfBag : null);
                }
            });
        }
    }, [loggedInUser?.memberId, checkInReady]);

    useEffect(() => {
        getGolfBagData();
    }, [getGolfBagData]);

    // 현재시간과 티타임 시간 비교
    const bookingTime = new Date(roundAt).getTime(); // 예약날짜
    const todayTime = new Date().getTime(); // 오늘날짜
    const showIcon = bookingTime > todayTime;

    // 공지사항 문구 계속 노출, 최초 1회 선택 시 비노출로 변경
    const noticeAlertShow = () => {
        if (localStorageUtil.get('noticeTooltipHide')) {
            return false;
        } else {
            if (checkInData?.golfClubName) {
                return `${checkInData?.golfClubName}의 공지사항을 확인해보세요!`;
            } else {
                return false;
            }
        }
    };

    // === QR 코드 ===
    const [qrImg, setQrImg] = useState(null);
    // QR 체크인 state
    const [time, setTime] = useState(59); // 타이머
    const [isActive, setIsActive] = useState(false); // qr코드 활성화
    // QR 타이머 리셋 함수
    const clickResetTimer = useCallback(
        debounce(() => {
            fetchWrapper
                .get(`/booking/${roundData?.bookingId}/${loggedInUser?.id}/qr/char`, null)
                .then((res) => {
                    if (res?.code === ALREADY_CHECKEDIN) {
                        setQrReset(true);
                    } else if (res?.code === SUCCESS_CODE) {
                        setQrImg(res?.data);
                        setIsActive(true);
                        _time = 59;
                        setTime(59);
                    }
                })
                .catch((error) => console.log('error:', error));
        }, 300),
        [loggedInUser]
    );

    useEffect(() => {
        if (qrImg) {
            startTimer(inTimeCallback, timeOverCallback);
        }
    }, [qrImg]);

    const bookingId = roundData?.bookingId;

    const inTimeCallback = (t) => {
        setTime(t);

        // 키오스크 QR체크인 여부 확인 >> 체크인 시 완료 페이지로 렌더링
        fetchWrapper.get(`/booking/${bookingId}/qrcode/check/${qrImg}`, null).then((response) => {
            if (response?.code === SUCCESS_CODE || response?.code === ALREADY_CHECKEDIN) {
                setCheckInReady(true);
                getCheckInData();
                setQrCheckIn(false);

                // 티타임 경과 1시간 이내일 시 > 체크인 완료 후 Alert 노출
                fetchWrapper
                    .get(`/booking/checkin/possibility?bookingId=${roundData?.bookingId}&checkinType=QRCODE`)
                    .then((response) => {
                        if (response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                            // 셀프체크인 Alert Open
                            handleSelfCheckInOpen();
                            setAlertContents(response?.message);
                            clearInterval(timer);
                        }
                    });
            }
        });
    };

    const timeOverCallback = () => {
        setIsActive(false);
    };

    // top > QR체크인 버튼
    const qrCheckOn = useCallback(
        debounce(() => {
            if (!roundData?.bookingId) return;

            // QR체크인 가능 여부 체크
            fetchWrapper
                .get(`/booking/checkin/possibility?bookingId=${roundData?.bookingId}&checkinType=QRCODE`)
                .then((response) => {
                    if (response?.code === SUCCESS_CODE || response?.code === CHECKIN_TIME_ONE_HOUR_CODE) {
                        // QR 체크인 timer 작동
                        if (!qrCheckIn) {
                            if (loggedInUser?.id) {
                                setQrCheckIn(true);
                                setIsActive(true);
                                if (_time <= 0) {
                                    clickResetTimer();
                                }
                            }
                        }
                    } else {
                        // QR체크인 Alert Open
                        handleSelfCheckInOpen();
                        setAlertContents(response?.message);

                        fetchWrapper
                            .get(`/amplitude-events/reservation-complete?bookingId=${roundData.bookingId}`, null)
                            .then((response) => {
                                if (response.code === SUCCESS_CODE) {
                                    failSelfCheckinGps(response.data, getAmplitudeRootPath());
                                }
                            });
                    }
                });
        }, 300),
        [qrCheckIn, loggedInUser, _time, roundData, latitude, longitude]
    );

    const isCheckdIn = useCallback(() => {
        return checkInData && loggedInUser && loggedInUser.checkinAt;
    }, [checkInData, loggedInUser]);

    useEffect(() => {
        const t = timer;
        return () => {
            clearInterval(t);
        };
    }, [timer]);

    useEffect(() => {
        _time = 0;

        // RN에서 웹으로 데이터를 전송했을때 message이벤트가 실행됩니다.
        document.addEventListener('message', (e) => {
            if (typeof e.data == 'object') {
                return;
            }
            setLatitude(JSON.parse(e.data)?.lat);
            setLongitude(JSON.parse(e.data)?.lon);
        });

        window.addEventListener('message', (e) => {
            if (typeof e.data == 'object') {
                return;
            }
            setLatitude(JSON.parse(e.data)?.lat);
            setLongitude(JSON.parse(e.data)?.lon);
        });
    }, []);

    return (
        <>
            <div className="landingContents">
                <Header home={true} fixed={false} notice={true} noticeAlert={noticeAlertShow()} gfClubId={gfClubId} />
                <div id="smartRound" className="contentsArea" style={{minHeight: `${height}px`}}>
                    <div className="inner">
                        {checkInData && <CheckInBackGround checkInData={checkInData} />}
                        {/*<div className="overlay"></div>*/}
                        {slideIdx !== 0 || isCheckdIn() ? (
                            <div className="tabSelfCheckIn">
                                <button
                                    className={slideIdx === 0 ? 'on' : ''}
                                    onClick={() => {
                                        refSwiper.current.swiper.slideTo(0);
                                    }}
                                >
                                    셀프 체크인
                                </button>
                                <button
                                    className={slideIdx === 1 ? 'on' : ''}
                                    onClick={() => {
                                        refSwiper.current.swiper.slideTo(1);
                                    }}
                                >
                                    동반자 정보
                                </button>
                            </div>
                        ) : isQrCheck && !qrCheckIn ? (
                            // isQrCheck(api)로 QR코드 활성화 여부 체크 후, qrCheckIn(버튼) 클릭할때마다 '셀프체크인' 또는 'QR체크인' 토글
                            <div className="btnCheckInWrapper">
                                <button className="btnQr" onClick={qrCheckOn}>
                                    <i className="iconQr"></i>QR 체크인
                                </button>
                            </div>
                        ) : isQrCheck && qrCheckIn ? (
                            <div className="btnCheckInWrapper">
                                <button className="btnSelfCheckIn" onClick={gpsOn}>
                                    <i className="iconGps"></i>셀프 체크인
                                </button>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className={'smartRoundWrap'}>
                            <Swiper ref={refSwiper} effect="none" onSlideChange={onSlideChange} onSwiper={onSwiper}>
                                <SwiperSlide data-title={'즐거운 라운드를 위한 \n 셀프체크인 준비가 되셨나요?'}>
                                    <CheckIn
                                        user={loggedInUser}
                                        golfBagList={golfBagList}
                                        cartList={cartList}
                                        caddieList={caddieList}
                                        checkInData={checkInData}
                                        getCheckInData={getCheckInData}
                                        setCheckInReady={setCheckInReady}
                                        setAssignAlert={setAssignAlert}
                                        assignAlertOpen={assignAlertOpen}
                                        qrCheckIn={qrCheckIn}
                                        host={editable}
                                        bookingMemberId={loggedInUser?.id}
                                        getCartData={getCartData}
                                        getCaddieData={getCaddieData}
                                        time={time}
                                        isActive={isActive}
                                        qrImg={qrImg}
                                        clickResetTimer={clickResetTimer}
                                        setIsActive={setIsActive}
                                        setTime={setTime}
                                        _time={_time}
                                        setQrCheckIn={setQrCheckIn}
                                    />
                                </SwiperSlide>
                                <SwiperSlide data-title={'같이 라운드하는 동반자 \n 정보 현황을 확인해주세요'}>
                                    <Partner
                                        loggedInUser={loggedInUser}
                                        editable={editable}
                                        bookingMember={bookingMember}
                                        maximumPlayer={maximumPlayer}
                                        roundPlayer={roundPlayer}
                                        getBookingMemberList={getBookingMemberList}
                                        assignAlertOpen={assignAlertOpen}
                                        checkInData={checkInData}
                                        setMemberReset={setMemberReset}
                                        showIcon={showIcon}
                                        bookingId={roundData?.bookingId}
                                        bookingData={bookingData}
                                    />
                                </SwiperSlide>
                                {/*<SwiperSlide*/}
                                {/*    data-title={*/}
                                {/*        "간편결제 카드 등록하고 \n 편리한 라운딩을 즐기세요"*/}
                                {/*    }*/}
                                {/*>*/}
                                {/*    <EasyPayment/>*/}
                                {/*</SwiperSlide>*/}
                                {/*<SwiperSlide data-title={'골프장 당일 이용 시 지켜야 할 준수사항을 알려드려요'}>*/}
                                {/*    <Etiquette />*/}
                                {/*</SwiperSlide>*/}
                            </Swiper>
                        </div>
                        <div
                            className={
                                slideIdx === 0 && !isCheckdIn() && !qrCheckIn
                                    ? 'courseGuideArea'
                                    : 'courseGuideArea black'
                            }
                        >
                            <button className="btnCourseGuide" onClick={onClose}>
                                코스 & 음식메뉴 보러가기 >
                            </button>
                            {/*<button className="btnMapFloating" onClick={() => {}}>*/}
                            {/*    <Icon*/}
                            {/*        name="circleMapIcon"*/}
                            {/*        width="1.5rem"*/}
                            {/*        height="1.5rem"*/}
                            {/*        viewBox="0 0 24 24"*/}
                            {/*        fill="#fff"*/}
                            {/*    />*/}
                            {/*</button>*/}

                            {/* ----------------------- 0509 by joanna ios 앱심사 승인완료 다시살리기 --------------------*/}
                            {/*4. 스마트라운드
                            - 셀프체크인> '길찾기' 플로팅 버튼 삭제(히든)*/}
                            <MapFloatingButton golfClubId={gfClubId} />
                            {/* ----------------------- 0509 by joanna ios 앱심사 승인완료 다시살리기 --------------------*/}
                        </div>
                    </div>
                </div>
            </div>

            {/* 셀프 체크인 Alert */}
            <DefaultAlert open={selfCheckIn} handleClose={handleSelfCheckInClose} contents={alertContents} />
        </>
    );
}

export default SmartRound;
