import React from 'react';
import TitleArea from '../../../components/common/TitleArea';
import Header from '../../../components/Header';
import JoinLocationService from '../member/JoinLocationService';

function LocationPolicyPop({title}) {
    return (
        <>
            <Header close={true} closeWindow={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="agreeCon">
                            <JoinLocationService />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LocationPolicyPop;
