import {Fragment} from 'react';
import './promotion-checkin-modal.scss';
import Button from 'components/button/Button';

const PromotionCheckinModal = (props) => {
    const {open, onClose} = props;

    return (
        open && (
            <Fragment>
                {/* dimm */}
                <div className="dimmed-overlay" />
                <div className="promotion-checkin-modal-wrapper">
                    <div className="promotion-checkin-modal">
                        {/* 상단 이미지 영역 */}
                        <div className="promotion-checkin-image-wrapper">
                            <div className="promotion-checkin-image" />
                        </div>
                        {/* 콘텐츠영역 */}
                        <div className="promotion-checkin-content-wrapper">
                            {/* 하단 이미지 영역*/}
                            <div className="promotion-checkin-bottom-image"></div>
                            <div className="promotion-checkin-content">
                                {/* 콘텐츠 - 타이틀영역 */}
                                <div className="promotion-checkin-title-wrapper">
                                    <span className="promotion-checkin-main-title">
                                        골라가 셀프체크인 <br />
                                        경품 이벤트 응모 완료!
                                    </span>
                                    <span className="promotion-checkin-sub-title">
                                        9월 한 달간 골라가 셀프체크인을 이용하면, 자동 응모되는 이벤트에 참여
                                        완료되었습니다.
                                    </span>
                                    <span className="promotion-checkin-sub-title-02">
                                        * 자세한 내용은 내정보 &gt; 이벤트에서 확인해주세요
                                    </span>
                                </div>
                                {/* 콘텐츠 - 버튼영역 */}
                                <div className="promotion-checkin-button-wrapper">
                                    <Button onClick={onClose} background={'#7d3cde'}>
                                        확인
                                    </Button>
                                    {/* <span className="promotion-checkin-button-title">확인</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    );
};

export default PromotionCheckinModal;
