import {bookingConstant} from 'common/payment/constants';
import {paymentConstant} from 'common/payment';

const {paymentType} = paymentConstant;

export const bookingInfoProperty = {
    /* 예약 / 예약 취소 관련 텍스트 */
    booking_number: '예약번호',
    booking_person_name: '예약자명',
    booking_golfclub_name: '골프장명',
    booking_teetime: '티타임',

    booking_complete: '예약완료',
    booking_title: '예약하기',
    booking_legal: '법적고지',
    booking_legal_notice:
        '㈜그린잇은 통신판매중개시스템의 제공자로서, 통신판매의당사자가 아니며 상품의 예약 및 결제, 환불등과 관련한 의무는 각 판매자(골프장)에 있습니다.',

    booking_infomation: '예약정보',
    booking_greenFee_per_person: '그린피(1인)',

    /* 예약 완료 관련 텍스트 */
    booking_person_info: '예약자 정보',
    booking_course_name: '코스명',
    booking_hole_info: '홀 정보',
    booking_round_player: '내장인원',
    booking_inner_condition: '내장조건',

    /* 홈페이지 예약 공지 문구 */
    booking_homepage: '홈페이지 예약',
    booking_homepage_notice: '해당 티타임은 골프장 홈페이지에서 예약 가능합니다',
    booking_cartFee_and_caddieFee_sub_text: '* 카트피, 캐디피는 골프장 상황에 따라 변동될 수 있습니다.',
    booking_caddieFee_sub_text: '* 캐디피는 골프장 상황에 따라 변동될 수 있습니다.',

    /* 골프장 에약 공지 */
    booking_golfclub_notice: '골프장 예약 공지',
    /* 예약 상태코드에 따른 문구 변경 */
    booking_status_code: {
        [bookingConstant.roundBookingResultCode.SUCCESS]: '예약이 완료되었습니다',
        [bookingConstant.roundBookingResultCode.FAIL]: '예약을 완료하지 못했습니다'
    },

    /* 외부 예약시 보여주는 문구 */
    booking_external_confirm: '예약처에서 확인',

    /* 예약 실패 시, 결제타입에 따른 결제정보 타이틀, 현장결제는 미표시 */
    booking_type_title: {
        [paymentType.PREPAY]: '최종 결제 금액',
        [paymentType.RESERVE]: '결제한 예약금'
    }
};
