import Icon from '../../icons/Icon';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {appUtils} from 'common/utils';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import Button from 'components/button/Button';
import {paymentConstant} from 'common/payment';

const PaymentBookingDetails = (props) => {
    const {paymentType, prepareRoundBooking, getPrepareRoundBookingTradeAmount} = props;
    const {minPlayer, playerLimit, caddieType} = prepareRoundBooking;

    const {
        prepaymentType,
        paymentTypeName,
        transactionMethod,
        totalAmount,
        totalGreenFee,
        expectOnsiteAmount,
        cartFee,
        caddieFee,
        onsiteGreenFee,
        totalExpectAmount,
        isCartFeeIncluded,
        otherAmountMessage
    } = useSelector((x) => x?.paymentBooking);

    const [selectedRoundPlayer, setSelectedRoundPlayer] = useState(playerLimit);

    const setPlayerButtons = (min, limit) =>
        min === limit ? [] : Array.from({length: Math.max(0, limit - min + 1)}, (_, i) => min + i);

    const roundPlayerButtonList = setPlayerButtons(minPlayer, playerLimit);

    const handleButtonClick = async (roundPlayer) => {
        if (selectedRoundPlayer === roundPlayer) return;

        setSelectedRoundPlayer(roundPlayer);
        await getPrepareRoundBookingTradeAmount(roundPlayer);
    };

    useEffect(() => {
        setSelectedRoundPlayer(playerLimit);
    }, [playerLimit]);

    const PaymentBookingDetail = (props) => {
        const {paymentType} = props;

        return (
            <div className="payment-booking-pay-info-wrapper">
                {/* 현장결제 */}
                {paymentType === paymentConstant.paymentType.ONSITE && (
                    <>
                        <div className="payment-booking-pay-info-container pt-20 pb-20">
                            <h2>{paymentInfoProperty.payment_infomation}</h2>
                            <div className="payment-booking-pay-info">
                                <span className="payment-booking-pay-info-amount fw-bold">
                                    {paymentInfoProperty.payment_type_title[paymentType]}
                                </span>
                                <span className="payment-booking-pay-info-amount fw-extrabold">
                                    {appUtils.amountFormat(expectOnsiteAmount)}
                                </span>
                            </div>
                            <div className="payment-booking-pay-info">
                                <span>
                                    <i className="teeTimeBoxIcon-advance-payment">
                                        <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                                    </i>
                                    {paymentInfoProperty.totalGreenFee}
                                </span>
                                <span className="fw-regular">
                                    {totalGreenFee === 0
                                        ? paymentInfoProperty.greenFee_golf_contact
                                        : appUtils.amountFormat(totalGreenFee)}
                                </span>
                            </div>
                            <div className="payment-booking-pay-info">
                                <span>
                                    <i className="teeTimeBoxIcon-advance-payment">
                                        <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                                    </i>
                                    {paymentInfoProperty.cartFee}
                                </span>
                                <span className="fw-regular">
                                    {isCartFeeIncluded ? '그린피에 포함' : appUtils.amountFormat(cartFee)}
                                </span>
                            </div>
                            {/* 캐디 타입이 SELF(노캐디) 일 경우 캐디피 비노출  */}
                            {['SELF', '노캐디'].includes(caddieType) ? null : (
                                <div className="payment-booking-pay-info">
                                    <span>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.caddieFee}
                                    </span>
                                    <span className="fw-regular">{appUtils.amountFormat(caddieFee)}</span>
                                </div>
                            )}
                            <div className="payment-booking-pay-notice">
                                <div className="payment-booking-pay-notice bg-gray">
                                    <span>*{`${otherAmountMessage}`}</span>
                                </div>
                            </div>
                        </div>

                        <div className="payment-booking-pay-method-info-wrapper">
                            <div className="payment-booking-pay-method-info-container">
                                <div className="payment-booking-pay-method-info">
                                    <span className="payment-booking-pay-method-info label">
                                        {paymentInfoProperty.payment_type}
                                    </span>
                                    <span className="payment-booking-pay-method-info content">{paymentTypeName}</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/* 선결제 */}
                {paymentType === paymentConstant.paymentType.PREPAY && (
                    <>
                        <div className="payment-booking-pay-info-container dash pt-20">
                            <h2>{paymentInfoProperty.payment_infomation}</h2>
                            <div className="payment-booking-pay-info">
                                <span className="payment-booking-pay-info-amount fw-bold">
                                    {paymentInfoProperty.payment_type_title[paymentType]}
                                </span>
                                <span className="payment-booking-pay-info-amount fw-extrabold">
                                    {appUtils.amountFormat(totalAmount)}
                                </span>
                            </div>
                            <div className="payment-booking-pay-info">
                                <span>
                                    <i className="teeTimeBoxIcon-advance-payment">
                                        <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                                    </i>
                                    {paymentInfoProperty.totalGreenFee}
                                </span>
                                <span className="fw-regular">{appUtils.amountFormat(totalGreenFee)}</span>
                            </div>
                            {/* prepaymentType === "20" 일 경우 카트피가 결제 정보에 노출 그 이외의 경우 현장 결제 예상금액에 노출 */}
                            {prepaymentType === paymentConstant.prepaymentType.PAYMENT_TYPE_20 && (
                                <div className="payment-booking-pay-info">
                                    <span>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.cartFee}
                                    </span>
                                    {/* 카트피 포함 여부에 따라 '그린피에 포함 노출' */}
                                    <span className="fw-regular">
                                        {`${
                                            isCartFeeIncluded === true
                                                ? '그린피에 포함'
                                                : appUtils.amountFormat(cartFee)
                                        }`}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="payment-booking-pay-info-container dash">
                            <div className="payment-booking-pay-info">
                                <span className="fw-bold">현장 결제 예상 금액</span>
                                <span className="fw-extrabold">{appUtils.amountFormat(expectOnsiteAmount)}</span>
                            </div>
                            {prepaymentType !== paymentConstant.prepaymentType.PAYMENT_TYPE_20 && (
                                <div className="payment-booking-pay-info">
                                    <span>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.cartFee}
                                    </span>
                                    {/* 카트피 포함 여부에 따라 '그린피에 포함 노출' */}
                                    <span className="fw-regular">
                                        {`${
                                            isCartFeeIncluded === true
                                                ? '그린피에 포함'
                                                : appUtils.amountFormat(cartFee)
                                        }`}
                                    </span>
                                </div>
                            )}
                            {/* 캐디 타입이 SELF(노캐디) 일 경우 캐디피 비노출  */}
                            {['SELF', '노캐디'].includes(caddieType) ? null : (
                                <div className="payment-booking-pay-info">
                                    <span>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.caddieFee}
                                    </span>
                                    <span className="fw-regular">{appUtils.amountFormat(caddieFee)}</span>
                                </div>
                            )}
                        </div>

                        <div className="payment-booking-pay-info-container pb-20">
                            <div className="payment-booking-pay-info">
                                <span className="fw-bold">전체 예상 비용</span>
                                <span className="fw-extrabold">{appUtils.amountFormat(totalExpectAmount)}</span>
                            </div>

                            <div className="payment-booking-pay-notice">
                                <div className="payment-booking-pay-notice bg-gray">
                                    <span>{`* ${otherAmountMessage}`}</span>
                                </div>
                            </div>
                        </div>
                        <div className="payment-booking-pay-method-info-wrapper">
                            <div className="payment-booking-pay-method-info-container line">
                                <div className="payment-booking-pay-method-info">
                                    <span className="payment-booking-pay-method-info label">
                                        {paymentInfoProperty.payment_type}
                                    </span>
                                    <span className="payment-booking-pay-method-info content">{paymentTypeName}</span>
                                </div>
                            </div>
                            <div className="payment-booking-pay-method-info-container">
                                <div className="payment-booking-pay-method-info">
                                    <span className="payment-booking-pay-method-info label">
                                        {paymentInfoProperty.payment_method}
                                    </span>
                                    <span className="payment-booking-pay-method-info content">{transactionMethod}</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/* 예약금결제  */}
                {paymentType === paymentConstant.paymentType.RESERVE && (
                    <>
                        <div className="payment-booking-pay-info-container dash pt-20">
                            <h2>{paymentInfoProperty.payment_infomation}</h2>
                            <div className="payment-booking-pay-info">
                                <span className="payment-booking-pay-info-amount fw-bold">
                                    {paymentInfoProperty.payment_type_title[paymentType]}
                                </span>
                                <span className="payment-booking-pay-info-amount fw-extrabold">
                                    {appUtils.amountFormat(totalAmount)}
                                </span>
                            </div>
                            <div className="payment-booking-pay-info">
                                <span>
                                    <i className="teeTimeBoxIcon-advance-payment">
                                        <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                                    </i>
                                    {paymentInfoProperty.totalGreenFee}
                                </span>
                                <span className="fw-regular">{appUtils.amountFormat(totalGreenFee)}</span>
                            </div>

                            {/* prepaymentType === "20" 일 경우 카트피가 결제 정보에 노출 그 이외의 경우 현장 결제 예상금액에 노출 */}
                            {prepaymentType === paymentConstant.prepaymentType.PAYMENT_TYPE_20 && (
                                <div className="payment-booking-pay-info">
                                    <span>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.cartFee}
                                    </span>
                                    {/* 카트피 포함 여부에 따라 '그린피에 포함 노출' */}
                                    <span className="fw-regular">
                                        {`${
                                            isCartFeeIncluded === true
                                                ? '그린피에 포함'
                                                : appUtils.amountFormat(cartFee)
                                        }`}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="payment-booking-pay-info-container dash">
                            <div className="payment-booking-pay-info">
                                <span className="fw-bold">현장 결제 예상 금액</span>
                                <span className="fw-extrabold">{appUtils.amountFormat(expectOnsiteAmount)}</span>
                            </div>
                            <div className="payment-booking-pay-info">
                                <span>
                                    <i className="teeTimeBoxIcon-advance-payment">
                                        <Icon name="payment-tab-icon" width="12" height="12" viewBox="0 0 12 12"></Icon>
                                    </i>
                                    현장 결제 그린피
                                </span>
                                <span className="fw-regular">{appUtils.amountFormat(onsiteGreenFee)}</span>
                            </div>
                            <div className="payment-booking-pay-info-sub">
                                <span className="fw-regular">{paymentInfoProperty.onsite_greenFee_sub_text}</span>
                            </div>
                            {prepaymentType !== paymentConstant.prepaymentType.PAYMENT_TYPE_20 && (
                                <div className="payment-booking-pay-info">
                                    <span>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.cartFee}
                                    </span>
                                    {/* 카트피 포함 여부에 따라 '그린피에 포함 노출' */}
                                    <span className="fw-regular">
                                        {`${
                                            isCartFeeIncluded === true
                                                ? '그린피에 포함'
                                                : appUtils.amountFormat(cartFee)
                                        }`}
                                    </span>
                                </div>
                            )}
                            {/* 캐디 타입이 SELF(노캐디) 일 경우 캐디피 비노출  */}
                            {['SELF', '노캐디'].includes(caddieType) ? null : (
                                <div className="payment-booking-pay-info">
                                    <span>
                                        <i className="teeTimeBoxIcon-advance-payment">
                                            <Icon
                                                name="payment-tab-icon"
                                                width="12"
                                                height="12"
                                                viewBox="0 0 12 12"
                                            ></Icon>
                                        </i>
                                        {paymentInfoProperty.caddieFee}
                                    </span>
                                    <span className="fw-regular">{appUtils.amountFormat(caddieFee)}</span>
                                </div>
                            )}
                        </div>

                        <div className="payment-booking-pay-info-container pb-20">
                            <div className="payment-booking-pay-info">
                                <span className="fw-bold">전체 예상 비용</span>
                                <span className="fw-extrabold">{appUtils.amountFormat(totalExpectAmount)}</span>
                            </div>

                            <div className="payment-booking-pay-notice">
                                <div className="payment-booking-pay-notice bg-gray">
                                    <span>{`* ${otherAmountMessage}`}</span>
                                </div>
                            </div>
                        </div>
                        <div className="payment-booking-pay-method-info-wrapper">
                            <div className="payment-booking-pay-method-info-container line">
                                <div className="payment-booking-pay-method-info">
                                    <span className="payment-booking-pay-method-info label">
                                        {paymentInfoProperty.payment_type}
                                    </span>
                                    <span className="payment-booking-pay-method-info content">{paymentTypeName}</span>
                                </div>
                            </div>
                            <div className="payment-booking-pay-method-info-container">
                                <div className="payment-booking-pay-method-info">
                                    <span className="payment-booking-pay-method-info label">
                                        {paymentInfoProperty.payment_method}
                                    </span>
                                    <span className="payment-booking-pay-method-info content">{transactionMethod}</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            {/* 내장 인원 */}
            {roundPlayerButtonList.length > 0 ? (
                <div className="payment-booking-detail-inner-people-btn-wrapper">
                    <h2>{bookingInfoProperty.booking_round_player}</h2>
                    <div className="payment-booking-detail-inner-people-btn-list">
                        {roundPlayerButtonList.map((roundPlayerCount) => (
                            <Button
                                key={roundPlayerCount}
                                delay={500}
                                className={`payment-booking-deatil-inner-people-btn ${
                                    selectedRoundPlayer === roundPlayerCount ? 'select' : ''
                                }`}
                                onClick={() => handleButtonClick(roundPlayerCount)}
                            >
                                {roundPlayerCount}명
                            </Button>
                        ))}
                    </div>
                </div>
            ) : null}
            <PaymentBookingDetail paymentType={paymentType} />
        </>
    );
};

export default PaymentBookingDetails;
