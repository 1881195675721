//fieldMain Context api
import {createContext} from 'react';

export const initFieldMainContext_myPage = {
    //기본 필터 창
    setBasicFilterOpen: () => {},

    onBasicFilterClose: () => {},
    onAdvancedFilterClose: () => {},
    //필터 적용하기
    btnApplyClicked: false,
    onBtnApplyClicked: () => {},
    //고급 필터 적용하기
    btnAdvancedApplyClicked: false,
    onBtnAdvancedApplyClicked: () => {},

    isFilterAllChk: false,

    //기본 필터 복사본
    copyBasicFilter: [],
    //고급 필터 복사본
    copyAdvancedFilter: [],

    //날짜 선택
    selectDate: null,
    setSelectDate: () => {},

    //선택된 지역
    region: [],
    regionTemp: [],
    //티타임
    teeTime: [],
    teeTimeTemp: [],
    // 내장인원 (숫자 배열)
    playerCnt: [],
    playerCntTemp: [],
    // 홀수
    holeCnt: [],
    holeCntTemp: [],
    // 그린피
    greenFee: [],
    greenFeeTemp: [],
    golfListLen: 0,
    /* 2022.07.07 결제방식 추가 */
    paymentType: [],
    paymentTypeTemp: [],

    //고급필터
    selectAdvancedList: [],
    selectAdvancedListTemp: [],

    //기본 필터 drawer ListCount
    basicFilterDrawerCnt: 0,
    setBasicFilterDrawerCnt: () => {},

    //필터 카운팅용 데이터
    filterCntListData: [],
    setFilterCntListData: () => {},
    copyFilterCntListData: [],
    setCopyFilterCntListData: () => {}
};

export const FieldMainContext_myPage = createContext(initFieldMainContext_myPage);
