import React, {useEffect, useState} from 'react';
import {appUtils} from '../../common/utils';
import {PW_MAXLENGTH} from '../../common/constants';

function SettingPwField({
    formValue,
    setFormValue,
    passwordMinLength,
    passwordRegYN,
    passwordIncludeId,
    setPasswordValid
}) {
    const [passwordShow, setPasswordShow] = useState(false);

    useEffect(() => {
        setPasswordValid();
    }, [formValue?.userPassword]);

    const onChangePassword = (e) => {
        let value = e.target.value.replaceAll(/\s/gi, '');
        setFormValue({
            ...formValue,
            [e.target.name]: value
        });
    };

    const onDeletePassowordInput = () => {
        setFormValue({
            ...formValue,
            userPassword: ''
        });
    };

    return (
        <>
            <div className="row fieldArea">
                <div className="passwordArea">
                    <input
                        type={passwordShow ? 'text' : 'password'}
                        {...{
                            name: 'userPassword',
                            maxLength: PW_MAXLENGTH,
                            minLength: window.config.password.minLength,
                            value: formValue.userPassword,
                            placeholder: '비밀번호 입력',
                            className: 'textField',
                            onChange: (e) => onChangePassword(e),
                            onFocus: () => appUtils.offsetPadding(),
                            onBlur: () => appUtils.defaultPadding()
                        }}
                    />
                    <div className="btnArea">
                        {formValue?.userPassword.length ? (
                            <button type="button" className="btnDelete" onClick={onDeletePassowordInput}>
                                삭제
                            </button>
                        ) : (
                            ''
                        )}
                        <button
                            type="button"
                            className={passwordShow ? 'btnHidePw' : 'btnShowPw'}
                            onClick={() => setPasswordShow(!passwordShow)}
                        >
                            비밀번호
                        </button>
                    </div>
                </div>
                <ul className="checkList">
                    <li className={`${passwordMinLength && passwordRegYN && 'done'}`}>
                        <i className="iconCheck"></i> 영문/숫자/특수문자(공백제외) 2개 이상 조합 (
                        {window.config.password.minLength}자 ~ {PW_MAXLENGTH}자)
                    </li>
                    <li className={`${passwordIncludeId && 'done'}`}>
                        <i className="iconCheck"></i> 이메일 아이디를 포함하지 않음
                    </li>
                </ul>
            </div>
        </>
    );
}

export default SettingPwField;
