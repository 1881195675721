import React from 'react';
import {useDispatch} from 'react-redux';
import {history} from '../../_helpers';
import HeaderTooltip from '../../components/tooltip/HeaderTooltip';
import {checkinActions} from '../../_store/checkin.slice';

function Notice({noticeAlert, gfClubId}) {
    const dispatch = useDispatch();

    const hideCheckin = () => {
        dispatch(checkinActions.showSmartRoundCheckIn(false));
    };

    const onClickNotice = () => {
        history.navigate(`/club-board/${gfClubId}`);
        hideCheckin();
    };

    return (
        <>
            {noticeAlert && <HeaderTooltip noticeAlert={noticeAlert} />}
            <button type="button" className="btnNotice" onClick={onClickNotice}>
                공지
            </button>
        </>
    );
}

export default Notice;
