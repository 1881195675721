import Drawer from '@mui/material/Drawer';
import React, {useCallback, useEffect, useState} from 'react';
import {fetchWrapper, history} from '../../_helpers';
import AgreementDrawer from '../mypage/get-my-reservation/AgreementDrawer';
import DefaultAlert from '../alert/DefaultAlert';
import {SUCCESS_CODE} from '../../common/constants';
import Icon from '../icons/Icon';
import {useDispatch, useSelector} from 'react-redux';
import {store, userActions} from '../../_store';
import PolicyLayer from '../membership/PolicyLayer';
import {connectOtherBookingComplete, viewPopupOtherBookingCancelConfirm} from '_helpers/amplitude/events/view.events';
import ConfirmAgreeModal from './ConfirmAgreeModal';
import {appUtils, localStorageUtil} from 'common/utils';
import {checkinActions} from '_store/checkin.slice';

function AgreementInfoModal(props) {
    const {
        open,
        handleNoticeClose,
        onClickViewDetail,
        handleAgreementReOpen,
        handleConfirmAgreeModalClose
        // isOpen
    } = props;

    const changeDate = appUtils.dateFormatTypeBoard(Date.now());
    const dispatch = useDispatch();

    // 이미 동의한 사용자일 경우 Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const handleAlertOpen = (msg) => {
        setAlertMessage(msg);
        setAlertOpen(true);
    };
    const handleAlertClose = () => setAlertOpen(false);

    // member api
    const [member, setMember] = useState(null);

    const getMemberInfo = useCallback(() => {
        fetchWrapper.get(`/member`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setMember(response.data || null);
            }
        });
    }, []);

    const users = useSelector((x) => x?.users?.users);
    const [checkStatus, setCheckStatus] = useState(false);

    //예약정보 불러오기 약관 레이어
    const [agreementOpen, setAgreementOpen] = useState(false);

    //예약정보 불러오기 약관 레이어 오픈
    const agreementLayerOpen = () => {
        setAgreementOpen(true);
    };

    //예약정보 불러오기 약관 레이어 닫기
    const agreementLayerClose = () => {
        setAgreementOpen(false);
    };

    useEffect(() => {
        getMemberInfo();
    }, []);

    //#region todo 공통화 || slice화 시키기
    // 예약정보 조회,전송 동의 처리
    const checkReservAgreement = (event) => {
        const payload = {
            agreementType: 'BOOKING_CHECK',
            isAgreement: 'true',
            email: users?.email
        };

        // 예약정보 조회,전송 동의 & User 정보 member에 저장
        fetchWrapper.post(`/member/change/agreement/status`, payload).then((res) => {
            store.dispatch(userActions.setUser({key: 'isBookingCheckAgree', value: 'true'}));
            const curUrl = sessionStorage.getItem('curUrl');
            connectOtherBookingComplete({agree_type: 'modal'}, curUrl); // 앰플리튜드 이벤트 전송
        });
    };

    // 예약정보 동의 -> 타 플랫폼 예약 api 호출 -> 금일 라운드 체크 후 navigate 이동
    const getMyReserve = async (event) => {
        // 예약정보 조회,전송 동의 api 호출
        checkReservAgreement(event);
        // sessionStorage 체크값 삭제
        removeCheck();

        // 타 플랫폼 예약정보 불러오는 api 호출
        const resLinkedGolfClub = await fetchWrapper.get('/booking/linkedGolfClub');
        if (resLinkedGolfClub.code === SUCCESS_CODE) {
            // 금일 예약 라운드있는지 api 호출
            const resTodayReserv = await fetchWrapper.get('/booking/alert/0');
            if (resTodayReserv.code === SUCCESS_CODE) {
                const todayReservData = resTodayReserv.data;

                const roundData = {
                    title: '',
                    areaInfo: '',
                    golfClubId: '',
                    bookingId: '',
                    introUrl: '',
                    golfClubName: '',
                    roundAt: '',
                    courseName: ''
                };

                if (todayReservData) {
                    // 당일 예약 존재 && 코스가이드
                    //셀프체크인 활성화
                    roundData.title = todayReservData.introDesc;
                    roundData.areaInfo = todayReservData.address;
                    roundData.golfClubId = todayReservData.golfClubId;
                    roundData.bookingId = todayReservData.bookingId;
                    roundData.introUrl = todayReservData.videoClipUrl;
                    roundData.golfClubName = todayReservData.golfClubName;
                    roundData.roundAt = todayReservData.roundAt;
                    roundData.courseName = todayReservData.courseName;
                }
                // 라운드 있을 시 페이지 이동
                history.navigate('/intro', {state: {roundData, intro: true}});
            }
        }
    };
    //#endregion

    const moveSuccess = (event) => {
        const localStorageData = {
            getReservationAgree: true,
            date: new Date()
        };
        localStorageUtil.set('get_reservation_agree', JSON.stringify(localStorageData));
        getMyReserve(event);
        // 앰플리튜드 이벤트 전송

        onSuccessAgree();
        dispatch(checkinActions.setPromotionToast(true));
        setTimeout(() => {
            toastClose();
        }, 2000);
    };

    const toastClose = () => {
        dispatch(checkinActions.setPromotionToast(false));
    };

    const agreeCheck = () => {
        setErrorMessage(true);
    };

    // 정보 미동의 시 확인 모달
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const confirmModal = () => {
        handleNoticeClose();

        setTimeout(() => {
            setConfirmModalOpen(true);
            viewPopupOtherBookingCancelConfirm(); // 앰플리튜드 이벤트 전송
        }, [200]);
    };
    const confirmModalClose = () => {
        setConfirmModalOpen(false);
    };

    // 예약정보 조회,전송 동의 Alert
    const [alertState, setAlertState] = useState(false);
    const onAlertClose = () => {
        setAlertState(false);
    };

    // 연동 확인 - 예약정보 조회,전송 동의
    const [reservChecked, setReservChecked] = useState({
        isBookingCheckAgree: member?.isBookingCheckAgree || false,
        bookingCheckAgreeDate: member?.bookingCheckAgreeDate || changeDate
    });

    const onSuccessAgree = () => {
        setReservChecked({
            isBookingCheckAgree: true,
            bookingCheckAgreeDate: ''
        });

        onAlertClose();
    };

    // 모달 -> 자세히 보기 페이지 이동 시 체크값 유지
    useEffect(() => {
        sessionStorage.setItem('agreementCheck', checkStatus);
    }, [checkStatus]);

    const removeCheck = () => {
        sessionStorage.removeItem('agreementCheck');
    };

    return (
        <>
            <Drawer anchor="bottom" open={open} onClose={confirmModal} className="agreementInfoModal">
                <div className="btnArea" onClick={handleNoticeClose}>
                    <button type="button" className="close"></button>
                    <i onClick={confirmModal}>
                        <Icon name="close" fill="#D7D9E2" width="16" height="16" />
                    </i>
                </div>
                <div className="drawerContents">
                    <div className="drawerContentWrap">
                        <strong>
                            골라가에서
                            <br />
                            스마트한 라운드를
                            <br />
                            즐겨보세요
                        </strong>
                        <div className="txtContents">
                            <div className="txtSection01">
                                <div className="number">1.</div>
                                <div className="txtWrap">
                                    <div>
                                        {/* todo overflow textwrap */}
                                        다른 곳에서 예약한 라운드도 골라가에서
                                        <br />
                                        연결할 수 있어요!
                                    </div>
                                    <p className="detail" onClick={onClickViewDetail}>
                                        자세히 보기<span className="btn_ar"></span>
                                    </p>
                                    <span className="detailEtc">* 내정보에서 쉽게 연결을 해제할 수 있어요</span>
                                </div>
                            </div>
                            <div className="txtSection02">
                                <div className="number">2.</div>
                                <div>
                                    셀프체크인, 동반자 초대, 코스가이드도
                                    <br />
                                    골라가 하나로 이용할 수 있어요.
                                </div>
                            </div>
                            <div className="checkSection">
                                <ul className="agreeCheckList">
                                    <li>
                                        <div className="checkLabel">
                                            <label>
                                                <div className="customCheck">
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => {
                                                            setCheckStatus((prev) => !prev);
                                                        }}
                                                        checked={checkStatus}
                                                    />
                                                    <i
                                                        className={`icon ${checkStatus ? '' : 'checked'}`}
                                                        style={{backgroundColor: `${checkStatus ? '#B09BE7' : '#fff'}`}}
                                                    >
                                                        <svg
                                                            width="100%"
                                                            height="100%"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M17 8L11.0525 15L7 10.9168"
                                                                stroke={checkStatus ? '#FFF' : '#A0A4AF'}
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </i>
                                                </div>
                                                <div className="agreeCheckTxt">
                                                    [필수] 예약정보 조회 &middot; 전송동의
                                                </div>
                                                <button type="button" className="btn_ar" onClick={agreementLayerOpen}>
                                                    자세히보기
                                                </button>
                                            </label>
                                        </div>
                                        {/* todo 체크 시 display: none*/}
                                        {!checkStatus && errorMessage
                                            ? errorMessage && (
                                                  <span className="checkAlert">
                                                      예약정보 조회 &middot; 전송동의에 동의해주세요.
                                                  </span>
                                              )
                                            : ''}
                                    </li>
                                </ul>
                            </div>
                            <div className="btnWrap" onClick={checkStatus ? handleNoticeClose : null}>
                                <button className="btnPrimary" onClick={checkStatus ? moveSuccess : agreeCheck}>
                                    셀프체크인 시작하기
                                    <span className="btn_ar"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>

            {/* 예약정보 불러오기 약관 레이어 */}
            <PolicyLayer
                open={agreementOpen}
                agreeType="GET_RESERVATION_INFO_AGREE"
                title="예약정보 조회 · 전송동의"
                handleClose={agreementLayerClose}
            />

            {/*이미 동의한 사용자일 경우 Alert*/}
            <DefaultAlert
                open={alertOpen}
                handleOpen={handleAlertOpen}
                handleClose={handleAlertClose}
                title={''}
                contents={alertMessage}
            />

            {/* 미동의 상태에서 modal close할 경우 다시 확인 modal*/}
            <ConfirmAgreeModal
                open={confirmModalOpen}
                handleClose={confirmModalClose}
                handleAgreementReOpen={handleAgreementReOpen}
                handleConfirmAgreeModalClose={handleConfirmAgreeModalClose}
            />
        </>
    );
}

export default AgreementInfoModal;
