import React from 'react';
import styled from 'styled-components';

function InnerPeopleBtn({innerPeopleNum, innerPeopleCurBtn, handleInnerPeopleClick, playerLimit, innerBtnCnt}) {
    let array = [];
    let peopleArr = [];
    for (let i = 0; i < innerBtnCnt; i++) {
        console.log('innerPeopleNum : ' + innerPeopleNum);
        if (innerPeopleNum === 0 || innerPeopleNum === null) {
            peopleArr[i] = 2 + i;
        } else {
            peopleArr[i] = innerPeopleNum + i;
        }
    }

    for (let i = 0; i < innerBtnCnt; i++) {
        array.push(
            <button
                key={i}
                onClick={handleInnerPeopleClick}
                data-idx={i}
                value={peopleArr[i]}
                className={`${
                    innerPeopleCurBtn === i ? 'fieldBookingInnerPeopleBtn-select' : 'fieldBookingInnerPeopleBtn'
                } button 
            `}
            >
                {peopleArr[i]}명
            </button>
        );
    }

    return array;
}

export default InnerPeopleBtn;
