import React, { useEffect, useState } from "react";
import Icon from "../icons/Icon";
import Drawer from "@mui/material/Drawer";
import { localStorageUtil } from "../../common/utils";

function CourseModal({ open, courseList, setCourseId, handleDrawerClose }) {
    const [checked, setChecked] = useState(true);
    const [checkInData, setCheckInData] = useState(
        JSON.parse(localStorageUtil.get("checkInData"))
    );

    useEffect(() => {
        window.addEventListener("beforeunload", () => {
            setCheckInData(null);
        });
        return () => {
            window.removeEventListener("beforeunload", () => {
                setCheckInData(null);
            });
        };
    }, []);

    useEffect(() => {
        setCheckInData(JSON.parse(localStorageUtil.get("checkInData")));
    }, [localStorageUtil.get("checkInData")]);

    return (
        checkInData && (
            <>
                <Drawer
                    anchor="bottom"
                    open={open}
                    onClose={handleDrawerClose}
                    className="defaultDrawer"
                    keepMounted={true}
                >
                    <div className="drawer-content">
                        <div className="drawerTitle">
                            <div className="tt">코스 선택</div>
                            <button
                                type="button"
                                className="btnClose"
                                onClick={handleDrawerClose}
                            >
                                <Icon name="close" fill="#848588" />
                            </button>
                        </div>
                        <div className="drawerContents">
                            <ul
                                className="selectList"
                                onClick={handleDrawerClose}
                            >
                                {courseList?.length
                                    ? courseList.map((data, idx) => {
                                          return (
                                              <li key={data?.id}>
                                                  <label className="customCheck02">
                                                      <input
                                                          type="radio"
                                                          name="courseType"
                                                          value={
                                                              data?.courseName
                                                          }
                                                          onClick={() =>
                                                              setCourseId(
                                                                  data?.id
                                                              )
                                                          }
                                                          defaultChecked={
                                                              checkInData &&
                                                              data?.id ===
                                                                  checkInData.courseId
                                                                  ? true
                                                                  : idx === 0 ??
                                                                    true
                                                          }
                                                      />
                                                      <span>
                                                          {data?.courseName}
                                                      </span>
                                                      <i className="icon"></i>
                                                  </label>
                                              </li>
                                          );
                                      })
                                    : ""}
                            </ul>
                        </div>
                    </div>
                </Drawer>
            </>
        )
    );
}

export default CourseModal;
