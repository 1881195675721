import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import {useSelector} from 'react-redux';

function TagNameModal({
    alertOpen,
    setAlertOpen,
    alertClose,
    setGolfBag,
    golfBag,
    golfBagList,
    selfCheckInProcess,
    assignAlertOpen
}) {
    const member = useSelector((x) => x?.users?.users);

    // const [savedGolfBag, setSavedGolfBag] = useState()

    // 골프백의 테그네임 재설정 Modal
    const [tagNameOpen, setTagNameOpen] = useState(false);
    const handleTagNameOpen = () => {
        setAlertOpen(false);
        setTagNameOpen(true);
    };
    const handleTagNameClose = () => {
        setTagNameOpen(false);
        setGolfBag('');
    };

    // 골프백 태그 네임 '예' 클릭 시 실행
    const moveToCheckInPage = () => {
        selfCheckInProcess(); // 체크인 + checkInReady -> true
        alertClose();
        setTimeout(assignAlertOpen, 3000);
    };

    // 태그네임 입력 후, '수정완료' 클릭 시 실행
    const modifyDone = () => {
        selfCheckInProcess(); // 체크인 + checkInReady -> true
        handleTagNameClose();
        setTimeout(assignAlertOpen, 3000);
    };

    return (
        <>
            {/* 골프백의 테그네임 정보 확인 Alert */}
            <Modal open={alertOpen} onClose={alertClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">골프백 네임태그 확인</div>
                        <div className="alertContents">
                            가져오신 골프백 네임태그가 {golfBagList?.length ? golfBagList[0]?.tagName : member?.name}
                            인가요?
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={handleTagNameOpen}>
                            아니오
                        </button>
                        <button type="button" onClick={moveToCheckInPage}>
                            예
                        </button>
                    </div>
                </div>
            </Modal>

            {/* 골프백의 테그네임 재설정 Modal */}
            <Modal open={tagNameOpen} onClose={handleTagNameClose}>
                <div className="defaultModal">
                    <div className="modalContents">
                        <p>{member?.name}님의 골프백 네임태그를 입력해주세요</p>
                        <form>
                            <div className="fieldAreaWrap">
                                <div className="row fieldArea">
                                    <input
                                        type="text"
                                        className="textField small"
                                        placeholder={golfBagList ? golfBagList[0]?.tagName : member?.name}
                                        value={golfBag || ''}
                                        onChange={(event) => {
                                            setGolfBag(event.target.value);
                                        }}
                                    />
                                    {golfBag?.length > 0 ? (
                                        <div className="btnArea">
                                            <button
                                                type="button"
                                                className="btnDelete"
                                                onClick={() => {
                                                    setGolfBag('');
                                                }}
                                            >
                                                삭제
                                            </button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modalBtnArea">
                        <button type="button" onClick={handleTagNameClose} className="btnGray">
                            취소
                        </button>
                        <button type="button" onClick={modifyDone}>
                            수정 완료
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default TagNameModal;
