import React from 'react';
import styles from './food.module.scss';
import {appUtils} from '../../common/utils';
import clsx from 'clsx';

function FoodMenuList({name, price, category, desc, imgUrl}) {
    return (
        <>
            <div className={`${styles.listArea} ${imgUrl ? 'flex jcsb-flex' : ''}`}>
                <div className={styles.txtWrap}>
                    <div className={styles.ttArea}>
                        <p className={styles.name}>
                            {name} <span className={clsx(styles.statusIcon, 'statusIcon line')}>{category}</span>
                        </p>
                    </div>
                    {desc ? <p className={`${styles.desc} food-desc`}>{desc}</p> : null}
                    <p className={styles.price}>{appUtils.numberFormat(price)}원</p>
                </div>
                {imgUrl && (
                    <div className={styles.imgWrap}>
                        <img src={imgUrl} alt={name} />
                    </div>
                )}
            </div>
        </>
    );
}

export default FoodMenuList;
