import React, {useCallback, useEffect, useState} from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';
import {openNotWatchingTodayNotice, setNotWatchingTodayExpires} from 'common/notWatchingTodayUtils';

const modifiers = [
    {
        name: 'offset',
        options: {
            offset: [0, 10]
        }
    },
    {
        name: 'preventOverflow',
        enabled: true,
        options: {
            altAxis: false,
            altBoundary: false,
            tether: false,
            rootBoundary: 'document',
            padding: 20
        }
    }
];

function PartnerTooltip({placement, checkInData, bookingData}) {
    const [controlledVisible, setControlledVisible] = useState(false);
    const handleNoticeOpen = () => setControlledVisible(true);

    const noticeName = 'check_partner_tooltip';
    const currentId = bookingData?.id;

    const {getTooltipProps, setTooltipRef, setTriggerRef, visible} = usePopperTooltip(
        {
            trigger: 'click',
            visible: controlledVisible,
            onVisibleChange: setControlledVisible
        },
        {
            placement: placement,
            modifiers
        }
    );

    // '오늘 하루 보지 않기' > 로컬스토리지 세팅
    const setTooltipHide = () => {
        setNotWatchingTodayExpires(noticeName, currentId);
        setControlledVisible(false);
    };

    // '오늘하루 보지 않기' > 툴팁 오픈
    useEffect(() => {
        if (bookingData) {
            openNotWatchingTodayNotice(noticeName, currentId, handleNoticeOpen);
        }
    }, [bookingData]);

    useEffect(() => {
        const handleKeyDown = ({key}) => {
            if (key === 'Escape') {
                setControlledVisible(false);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <button onClick={setTooltipHide} className="btnTooltip" ref={setTriggerRef} type="button">
                자세히보기
                {visible && <span className={placement === 'top' ? 'toolTipArrow' : 'toolTipArrowReverse'}></span>}
            </button>

            {visible && (
                <div
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: 'defaultTooltip'
                    })}
                >
                    <div className="tooltipContainer">
                        <div className="tooltipBox">
                            <div className="ttWrap">
                                <div className="tt">예약 양도 및 취소 안내</div>
                                <button type="button" className="btnClose" onClick={setTooltipHide}>
                                    툴팁 닫기
                                </button>
                            </div>
                            <ul className="listType01">
                                <li>
                                    예약자 본인 미내장 및 예약 양도는 당사 또는 골프장을 통해 예약 정지가 부과됩니다.
                                </li>
                                <li>
                                    취소 불가 기간 내 코로나 및 기타 질병으로 인한 취소 요청 시 반드시 인증이 가능한
                                    증빙 자료를 제출하여야 합니다.
                                </li>
                                <li>인증이 어려운 경우 정상 취소가 불가할 수 있습니다.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default PartnerTooltip;
