import React from "react";

function PersonalInfoLGT() {
    return (
        <>
            <section>
                <h2>&lt;LG유플러스 귀중&gt;</h2>
                <p>
                    (주)LG유플러스 (이하 ‘회사’라 함)가 제공하는 휴대폰
                    본인확인서비스를 이용하기 위해, 다음과 같이 ‘회사’가 본인의
                    개인정보를 수집 및 이용하고, 개인정보의 취급을 위탁하는 것에
                    동의합니다.
                </p>
                <br />
                <h3>1. 수집항목</h3>
                <p>
                    - 고객의 생년월일, 이동전화번호, 성명, 성별, 내/외국인 구분
                    <br />
                    - 연계정보(CI), 중복가입확인정보(DI)
                    <br />- 고객이 이용하는 웹사이트 등
                </p>
                <br />
                <h3>2. 이용목적</h3>
                <p>
                    <strong className="underLine">
                        - 고객이 웹사이트 또는 Application 등에 입력한
                        본인확인정보의 정확성 여부 확인
                        <br />
                        - 해당 웹사이트 또는 Application 등에 연계정보(CI)와
                        중복가입확인정보(DI) 전송
                        <br />
                        - 서비스 관련 상담 및 불만 처리
                        <br />- 기타 법률에서 정한 목적
                    </strong>
                </p>
                <br />
                <h3>3. 개인정보의 보유 및 이용기간</h3>
                <p>
                    <strong className="underLine">
                        고객이 서비스를 이용하는 기간에 한하여 보유 및 이용을
                        원칙으로 하되, 법률에서 정하는 경우 해당 기간까지 보유
                        및 이용(세부사항은 ‘회사’의 개인정보취급방침에 따름)
                    </strong>
                </p>
                <br />
                <h3>4. 본인확인서비스 제공을 위한 개인정보의 취급 위탁</h3>
                <p>
                    <strong className="underLine">
                        - 수탁자 : SCI평가정보(주)
                    </strong>
                    <br />- 취급위탁 업무 : 본인확인정보의 정확성 여부 확인,
                    연계정보(CI)/중복가입확인정보(DI) 전송, 서비스 관련 상담 및
                    불만 처리 등
                    <br />
                    <br />
                    상기 개인정보 수집 및 이용과 취급위탁에 동의하지 않으실
                    경우, 서비스를 이용하실 수 없습니다.
                    <br />
                    <br />
                    * ‘회사’가 제공하는 서비스의 개인정보 취급과 관련된 사항은,
                    아래의 홈페이지에 기재된 개인정보취급방침에 따릅니다.
                    (www.lguplus.co.kr)
                    <br />
                    <br />
                    본인은 위 내용을 숙지하였으며 이에 동의합니다.
                </p>
            </section>
        </>
    );
}

export default PersonalInfoLGT;
