import React, {useEffect, useState} from 'react';
import {appUtils} from '../../common/utils';
import Dialog from '@mui/material/Dialog';
import ClubBoardDetail from '../../components/club-board/ClubBoardDetail';
import AppBoardDetail from '../../components/mypage/app-board/AppBoardDetail';
import {clickNoticePromotion} from '_helpers/amplitude/events/click.event';

function Boarditem({golfClubId, item, clubName}) {
    const createDate = appUtils.dateFormatTypeBoard(item?.displayStrAt);
    const [boardType, setBoardType] = useState('');
    const keyword = '9월 골라가 경품이벤트 안내';

    useEffect(() => {
        if (clubName === '골라가') {
            setBoardType('app-board');
        } else {
            setBoardType('club-board');
        }
    }, [clubName]);

    // 공지사항 상세 LayerPop
    const [detailOpen, setDetailOpen] = useState(false);
    const handleOpen = (title) => {
        /* 앰플리튜드 이벤트 추가 */
        if (title) {
            if (title.includes(keyword)) {
                clickNoticePromotion();
            }
        }
        setDetailOpen(true);
        /* 안드로이드 모달 오픈 시 뒤로가기 버튼으로 닫게 처리 */
        appUtils.openWebViewModalPostMsg();
    };
    const handleClose = () => setDetailOpen(false);

    useEffect(() => {
        function handleEvent(message) {
            let type = JSON.parse(message.data).type;
            if (type === 'webModalClose' && detailOpen) {
                handleClose();
            }
        }
        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);
        return () => {
            document.removeEventListener('message', handleEvent);
        };
    }, [detailOpen]);

    return (
        <>
            <li>
                {boardType === 'club-board' ? (
                    <div className="anchorArea" onClick={() => handleOpen(item?.title)}>
                        <div className="ttArea">
                            <strong className="title">
                                {item?.title}
                                {item?.isNew && <span className="newIcon">N</span>}
                            </strong>
                            <span className="date">{createDate}</span>
                        </div>
                        <button type="button" className="btn_ar">
                            자세히보기
                        </button>
                    </div>
                ) : (
                    <div className="anchorArea" onClick={() => handleOpen(item?.title)}>
                        <div className="ttArea">
                            <strong className="title">
                                {item?.title}
                                {item?.isNew && <span className="newIcon">N</span>}
                            </strong>
                            <span className="date">{createDate}</span>
                        </div>
                        <button type="button" className="btn_ar">
                            자세히보기
                        </button>
                    </div>
                )}
            </li>

            {/*/!* 레이어 팝업 *!/*/}
            <Dialog fullScreen open={detailOpen} onClose={handleClose} className="fullPageModal">
                <div className="layerPop">
                    <div className="layerHeader">
                        <div className="inner">
                            <button type="button" className="btnClose" onClick={handleClose}>
                                닫기
                            </button>
                        </div>
                    </div>
                    <div className="layerContents">
                        {boardType === 'club-board' ? (
                            <ClubBoardDetail boardId={item?.id} golfClubId={golfClubId} />
                        ) : boardType === 'app-board' ? (
                            <AppBoardDetail boardId={item?.id} golfClubId={golfClubId} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default Boarditem;
