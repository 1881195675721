import React from 'react';
import Header from 'components/Header';

import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {btn} from 'common/payment/locales/kr/btn';

import Button from 'components/button/Button';

import PaymentBookingSuccessOnSite from 'components/Payment/PaymentBookingSuccess/PaymentBookingSuccessOnSite';
import PaymentBookingSuccessPre from 'components/Payment/PaymentBookingSuccess/PaymentBookingSuccessPre';
import PaymentBookingSuccessReserve from 'components/Payment/PaymentBookingSuccess/PaymentBookingSuccessReserve';
import PaymentBookingSuccessInfo from './PaymentBookingSuccessInfo';
import PaymentBookingCancelPossibilityDate from '../PaymentBooking/PaymentBookingCancelPossibilityDate';
import {history} from '_helpers';
import './payment-booking-success.scss';
import {YN} from 'common/constants';
import PromotionCheckinNotice from 'components/Promotion/PromotionCheckinNotice/PromotionCheckinNotice';

const Status = (bookingData) => ({
    ONSITE: <PaymentBookingSuccessOnSite bookingData={bookingData} paymentType={bookingData.paymentType} />,
    PREPAY: <PaymentBookingSuccessPre bookingData={bookingData} paymentType={bookingData.paymentType} />,
    RESERVE: <PaymentBookingSuccessReserve bookingData={bookingData} paymentType={bookingData.paymentType} />
});

const Detail = (props) => {
    const {paymentType, bookingData} = props;
    return Status(bookingData)[paymentType];
};

const PaymentBookingSuccess = () => {
    const {
        // type,
        //  code,
        data
        //    message
    } = history.location?.state;
    const {
        roundBookingId,
        golfClubName,
        payment,
        isAfterCancelPeriod,
        cancelPossiblePeriod,
        promotionActiveYn,
        promotionDisplayYn
    } = data;

    // 유효한 프로모션 게시인지 확인
    const promotionYn = promotionActiveYn === YN.Y && promotionDisplayYn === YN.Y;

    // 홈으로 돌아가기
    const handleHomeBack = () => {
        history.navigate('/');
    };

    // 동반자 초대하기
    const handleGoMyBooking = () => {
        history.navigate(`/mypage/booking?bookingId=${roundBookingId}&flag=pre`);
    };

    return (
        <>
            <Header pageTitle={bookingInfoProperty.booking_complete} fixed={true} />
            <div id="payment-booking-success">
                <div className="payment-booking-success-contents">
                    <div className="payment-booking-success">
                        <div className="inner">
                            <div className="section">
                                <div className="tt-area">
                                    <strong className="tt">{golfClubName} 예약이 완료되었습니다</strong>
                                </div>
                            </div>
                            <div>
                                {/* 2023.09.01 ~ 2023.09.30 셀프 체크인 경품 프로모션 안내 문구 */}
                                {promotionYn && <PromotionCheckinNotice />}
                                <PaymentBookingSuccessInfo isComfirmPage={false} bookingData={data} />
                                <Detail paymentType={payment.paymentType} bookingData={data} />
                                <PaymentBookingCancelPossibilityDate
                                    isAfterCancelPeriod={isAfterCancelPeriod}
                                    cancelPossiblePeriod={cancelPossiblePeriod}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="payment-booking-cancel-status-btn-bottom">
                <div className="inner">
                    <Button type="button" darkGray onClick={handleHomeBack}>
                        {btn.gohome_btn}
                    </Button>
                    <Button type="button" onClick={handleGoMyBooking}>
                        {btn.companion_invite_btn}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default PaymentBookingSuccess;
