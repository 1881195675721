import React, {useState, useEffect, useCallback} from 'react';
import {Datepicker} from '@meinefinsternis/react-horizontal-date-picker';
import {ko} from 'date-fns/locale';
import moment from 'moment';
import {debounce} from '@mui/material';
import {appUtils} from '../../../common/utils';
import {useLocation} from 'react-router-dom';

function HorizontalSlider(props) {
    const {setSelectMonth, selectDate, setSelectDate, holidayDate, startDate, endDate} = props;
    const [date, setDate] = useState({
        startValue: moment(startDate).toDate(),
        endValue: null,
        endDate: moment(endDate).toDate(),
        rangeDates: []
    });

    let viewport = null;
    let monthTitles = null;
    let monthDivs = null;
    let scrollParent = null;
    let dayOn = null;

    const location = useLocation();
    const handleChange = (e) => {
        const [startValue, endValue, rangeDates] = e;

        setDate((prev) => ({...prev, startValue, endValue, rangeDates}));
        setSelectDate(startValue);

        setTimeout(() => {
            const el = document.querySelector('.datePickerWrap .dateSlider .dayOn');
            if (el) {
                el.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
            }
        }, 200);

        appUtils.queryStringNavigate(location.pathname, {selectDate: encodeURI(startValue)}, location?.state);
    };

    const scrollEventListner = useCallback(() => {
        let month = monthTitles[0];
        for (let i = 0; i < monthDivs.length; i++) {
            const offset = monthDivs[i].getBoundingClientRect().left - viewport.getBoundingClientRect().left;
            if (offset <= 0) month = monthTitles[i];
        }
        if (month) {
            setSelectMonth(month.innerText);
        }
    }, []);

    //공휴일 체크
    const holidayClass = () => {
        const monthDivs = document.querySelectorAll('.Er');
        const monthTitles = document.querySelectorAll('.jb');

        const startYear = selectDate.getFullYear(); //올해
        const diffMonth = 12 - parseInt(monthTitles?.[0]?.innerText.slice(0, -1)); //현재 달을 기준으로 12월까지 남은 개월 수 계산

        const monthLen = monthDivs?.length;
        for (let i = 0; i < monthLen; ++i) {
            let year = startYear;
            const month = appUtils.cfSetAddZero(monthTitles?.[i].innerText.slice(0, -1)); //월
            const dayList = monthDivs[i].querySelectorAll('.dayList'); //일

            if (diffMonth < i) {
                year = startYear + 1; //내년
            }

            const dayListLen = dayList.length;
            for (let j = 0; j < dayListLen; ++j) {
                const day = dayList[j].querySelector('.date').innerText;
                const fullDay = `${year}-${month}-${day}`;

                //공휴일 데이터와 날짜가 맞는지 체크
                holidayDate?.filter((item) => {
                    if (item.date === fullDay) {
                        dayList[j].querySelector('.day').classList.add('red');
                        dayList[j].querySelector('.date').classList.add('red');
                    }
                });
            }
        }
    };

    useEffect(() => {
        viewport = document.querySelector('.dateSlider');
        scrollParent = document.querySelector('.dr');
        monthTitles = document.querySelectorAll('.jb');
        monthDivs = document.querySelectorAll('.Er');
        dayOn = document.querySelector('.datePickerWrap .dateSlider .dayOn');

        scrollEventListner();
        scrollParent.removeEventListener('scroll', scrollEventListner);
        scrollParent.addEventListener('scroll', debounce(scrollEventListner, 100));
    }, []);

    useEffect(() => {
        holidayClass();
    }, [holidayDate]);

    useEffect(() => {
        setTimeout(() => {
            const el = document.querySelector('.datePickerWrap .dateSlider .dayOn');
            if (el) {
                el.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
            }
        }, 200);
    }, []);

    return (
        <div className="dateSlider">
            <Datepicker
                onChange={handleChange}
                locale={ko}
                startValue={selectDate}
                endValue={selectDate}
                endDate={date.endDate}
                classNames={{
                    selectedDay: 'dayOn',
                    dayItem: 'dayList',
                    dayLabel: 'day',
                    dateLabel: 'date',
                    weekendItem: 'weekend'
                }}
                // {selectedDay: string, rangeDays: string, dayItem: string, dayLabel: string, monthLabel: string, dateLabel: string, weekendItem: string}
            />
        </div>
    );
}

export default HorizontalSlider;
