import React from 'react';
import Modal from '@mui/material/Modal';
import {history} from '../../_helpers';

function DefaultAlert(props) {
    // open State, 닫기 버튼 부모로부터 받아옴
    const {open, handleClose, pageLink, closeTxt} = props;

    // '확인' 버튼이 링크인경우
    const nextPage = () => {
        history.navigate(pageLink);
    };
    const handleBackdropClick = (event) => {
        event.stopPropagation();
        return false;
    };

    return (
        <>
            <Modal open={open} onClose={pageLink ? handleBackdropClick : handleClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        {props.title && <div id="alertTitle">{props.title}</div>}
                        <div className="alertContents">{props.contents}</div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={pageLink ? nextPage : handleClose}>
                            {closeTxt ? closeTxt : '확인'}
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default DefaultAlert;
