const AirbridgeUtil = {
    syncAmplitude: (deviceId) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: 'airbridge.syncAmplitude',
                    deviceId
                })
            );
        }
    },
    setUser: (email, attributes) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: 'airbridge.setUser',
                    email,
                    attributes
                })
            );
        }
    },
    event: {
        signUp: (memberChannel) => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.signUp',
                        memberChannel
                    })
                );
            }
        },
        login: (memberChannel) => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.login',
                        memberChannel
                    })
                );
            }
        },
        logout: () => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.logout'
                    })
                );
            }
        },
        addToWishlist: (golfClubId, golfClubName) => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.event.default',
                        category: 'airbridge.addToWishlist',
                        action: golfClubName,
                        semanticAttributes: {
                            products: [
                                {
                                    productID: golfClubId,
                                    name: golfClubName
                                }
                            ]
                        }
                    })
                );
            }
        },
        reservation: (golfClubId, golfClubName, price, dateTime, headCount) => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.event.default',
                        category: 'airbridge.schedule',
                        action: golfClubName,
                        semanticAttributes: {
                            totalValue: headCount * price,
                            totalQuantity: headCount,
                            datetime: dateTime,
                            currency: 'KRW',
                            products: [
                                {
                                    productID: golfClubId,
                                    name: golfClubName,
                                    price: price,
                                    currency: 'KRW'
                                }
                            ]
                        }
                    })
                );
            }
        },
        cancelReservation: (golfClubId, golfClubName, price, dateTime, headCount) => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.event.default',
                        category: 'cancel_reservation',
                        action: golfClubName,
                        semanticAttributes: {
                            totalValue: headCount * price,
                            totalQuantity: headCount,
                            datetime: dateTime,
                            currency: 'KRW',
                            products: [
                                {
                                    productID: golfClubId,
                                    name: golfClubName,
                                    price: price,
                                    currency: 'KRW'
                                }
                            ]
                        }
                    })
                );
            }
        },
        inviteCompanion: (channel) => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.event.default',
                        category: 'click_invite_companion_try',
                        action: channel
                    })
                );
            }
        },
        completeSelfCheckIn: () => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.event.default',
                        category: 'complete_gps_self_checkin'
                    })
                );
            }
        },
        withdrawal: () => {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                        type: 'airbridge.event.default',
                        category: 'view_withdrawal_success'
                    })
                );
            }
        }
    }
};

export default AirbridgeUtil;
