import React, {useState} from 'react';
import {Link} from '@mui/material';
import {history} from '_helpers';

function PersonalInformation() {
    const handleOpen = () => {
        history.navigate('/privacy-policy');
    };

    return (
        <>
            <section>
                <p>
                    아래 사항에 동의하시면 (주)그린잇이 제공하는 골라가 골프예약 서비스를 보다 편리하게 이용하실 수
                    있습니다.
                </p>
            </section>
            <section>
                <h2>거부할 권리와 불이익</h2>
                <p>
                    개인정보의 수집∙이용에 동의 하지 않을 수 있습니다. 다만 동의하지 않으시면 골라가의 서비스 이용이
                    불가능합니다.
                </p>
            </section>
            <section>
                <h2>[회원가입]</h2>
                <p>
                    1. 이용목적
                    <br />
                    회원가입의사 확인, 이용자 식별·인증, 회원서비스 제공, 회원자격 유지·관리, 부정이용방지, 만14세 미만
                    아동 이용제한, 고객 고지, 고객 상담 및 고충처리
                    <br />
                    2. 수집항목
                    <br />
                    이름, 휴대전화번호, 생년월일, 성별, 이메일 주소(ID), 비밀번호, 본인확인정보(CI)
                    <br />
                    3. 보유∙이용기간
                    <br />
                    회원탈퇴 시까지 단, 관계 법령이 정한 시점까지 보존
                </p>
            </section>
            <section>
                <h2>[제휴 골프장 예약정보 조회]</h2>
                <p>
                    1. 이용목적 <br />
                    제휴 골프장 예약 조회 및 비대면 서비스(셀프체크인, 동반자초대 등) 제공
                    <br />
                    2. 수집 항목 <br />
                    이름, 휴대전화번호
                    <br />
                    3. 정보를 보내는 곳 <br />
                    제휴 골프장
                    <br />
                    4. 전송요구 내역정보 <br />
                    예약처, 골프장명, 코스명, 라운드 홀수, 티타임 날짜 및 시간, 예약한 그린피(1인) 정보, 총 그린피 결제
                    예상 금액, 결제방법 등
                    <br />
                    5. 정보를 받는 곳 <br />
                    골라가(그린잇)
                    <br />
                    6. 보유∙이용기간 <br />
                    제휴 골프장 회원 예약정보 조회 및 전송요구내역 통합조회 후 서비스 목적 달성시 까지
                    <br />
                    7. 자동 업데이트 <br />
                    메인 페이지, 예약내역 페이지, 스마트라운드 페이지 진입 시 자동 업데이트
                    <br />
                </p>
            </section>
            <section>
                <p>
                    서비스 이용과정에서 기기정보, IP주소, 쿠키, 서비스 이용기록 등이 자동으로 수집될 수 있습니다.
                    <br />위 동의를 거부할 권리가 있으며, 동의를 거부하실 경우 서비스 이용이 제한됩니다. 더 자세한
                    내용에 대해서는
                    <Link to="/" onClick={handleOpen}>
                        <span>[개인정보처리방침]</span>
                    </Link>
                    을 참고하시기 바랍니다.
                    <br />
                </p>
            </section>
        </>
    );
}

export default PersonalInformation;
