import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade} from 'swiper';
import visual01 from '../../assets/images/membership/intro_visual01.png';
import visual02 from '../../assets/images/membership/intro_visual02.png';
import visual03 from '../../assets/images/membership/intro_visual03.png';
import 'swiper/css/effect-fade';

function MemGateSlider() {
    const [sliderDelay, setSliderDelay] = useState(5000);

    return (
        <>
            <div className="gateSlider">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    speed={2000}
                    autoplay={{
                        delay: sliderDelay,
                        disableOnInteraction: false
                    }}
                    effect="fade"
                    loop={true}
                    modules={[Autoplay, EffectFade]}
                    runCallbacksOnInit={true}
                    onSlideChange={() => {
                        setSliderDelay(3000);
                    }}
                >
                    <SwiperSlide className="list fadeOut">
                        <div
                            className="imgArea"
                            style={{
                                backgroundImage: `url(${visual01})`
                            }}
                        ></div>
                    </SwiperSlide>
                    <SwiperSlide className="list fadeIn">
                        <div
                            className="imgArea"
                            style={{
                                backgroundImage: `url(${visual02})`
                            }}
                        ></div>
                    </SwiperSlide>
                    <SwiperSlide className="list slideRight">
                        <div
                            className="imgArea"
                            style={{
                                backgroundImage: `url(${visual03})`
                            }}
                        ></div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    );
}

export default MemGateSlider;
