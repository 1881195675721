import {smartRoundActions} from '_store/smartRound.slice';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import CourseGuideV2 from './CourseGuide';
import FoodV2 from './Food';
import SmartRoundFooter from './Layout/SmartRoundFooter';
import SmartRoundHeader from './Layout/SmartRoundHeader';
import PartnerV2 from './Partner';
import SelfCheckInV2 from './SelfCheckIn';
import './smart-round-v2.scss';

export const SmartRoundMenu = {
    SelfCheckIn: 'self-check-in-v2',
    Partner: 'partner-v2',
    CourseGuide: 'course-guide-v2',
    Food: 'food-v2'
};

const SmartRoundV2 = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location?.search);
    const dispatch = useDispatch();
    const auth = useSelector((x) => x?.auth?.user);
    const smartRound = useSelector((x) => x?.smartRound?.smartRound);

    const amplitudeEvent = (name) => {};

    useEffect(() => {
        const golfClubId = searchParams.get('golfClubId');
        let bookingId = searchParams.get('bookingId');
        dispatch(smartRoundActions.setSmartRoundData({golfClubId, bookingId}));
        dispatch(smartRoundActions.getCourseList(golfClubId));
        dispatch(smartRoundActions.getSmartRound(golfClubId)).then((res) => {
            if (!bookingId) bookingId = res?.payload?.bookingId;
            if (bookingId) {
                dispatch(smartRoundActions.getRound(bookingId));
                dispatch(smartRoundActions.getBags());
                dispatch(smartRoundActions.getBookingMemberList(bookingId));
            }
        });

        return () => {};
    }, []);

    return (
        <>
            <SmartRoundHeader color={location.pathname.includes(SmartRoundMenu.SelfCheckIn) ? 'white' : 'black'} />
            {location.pathname.includes(SmartRoundMenu.SelfCheckIn) ? (
                <SelfCheckInV2 />
            ) : location.pathname.includes(SmartRoundMenu.Partner) ? (
                <PartnerV2 />
            ) : location.pathname.includes(SmartRoundMenu.CourseGuide) ? (
                <CourseGuideV2 />
            ) : location.pathname.includes(SmartRoundMenu.Food) ? (
                <FoodV2 />
            ) : (
                <SelfCheckInV2 />
            )}
            <SmartRoundFooter />
        </>
    );
};

export default SmartRoundV2;
