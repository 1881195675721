import React from 'react';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {paymentConstant} from 'common/payment/constants';
import {appUtils} from 'common/utils';
import {validationUtil} from 'common/payment';

const PreRoundDetail = (props) => {
    const {paymentType, paymentTradeData} = props;

    switch (paymentType) {
        case paymentConstant.paymentType.PREPAY:
            return (
                <>
                    <div className="payment-info-type">
                        <div className="sub-title-area">
                            <h2>{paymentInfoProperty.payment_infomation}</h2>
                        </div>
                        <dl>
                            <dt>{paymentInfoProperty.payment_date}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.tradeCreatedAt)
                                    ? '-'
                                    : appUtils.dateFormatTypeFull(paymentTradeData?.tradeCreatedAt)}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{paymentInfoProperty.payment_person_name}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.tradeCustomerName)
                                    ? '-'
                                    : paymentTradeData?.tradeCustomerName}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{paymentInfoProperty.payment_method}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.paymentMethod)
                                    ? '-'
                                    : paymentTradeData?.paymentMethod}
                            </dd>
                        </dl>
                        <dl className="border-bottom black">
                            <dt>{paymentInfoProperty.payment_card_classification}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.cardName)
                                    ? '-'
                                    : paymentTradeData?.cardName}
                            </dd>
                        </dl>
                        <dl className="border-bottom black">
                            <dt>{paymentInfoProperty.payment_card_number}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.cardNumber)
                                    ? '-'
                                    : validationUtil.cardNumberFormatter(paymentTradeData?.cardNumber)}
                            </dd>
                        </dl>
                    </div>
                    <div className="payment-info-type">
                        <span className="total-payment">
                            <dl>
                                <dt>{paymentInfoProperty.payment_all_amount}</dt>
                                <dd>
                                    {validationUtil.isNullChk(paymentTradeData?.tradeAmount)
                                        ? '-'
                                        : appUtils.amountFormat(paymentTradeData?.tradeAmount)}
                                </dd>
                            </dl>
                        </span>
                        <div className="non-caddieFee-text">
                            <p>{paymentInfoProperty.payment_non_caddiefee}</p>
                        </div>
                    </div>
                </>
            );
            break;
        case paymentConstant.paymentType.RESERVE:
            return (
                <>
                    <div className="payment-info-type">
                        <div className="sub-title-area">
                            <h2>{paymentInfoProperty.payment_infomation}</h2>
                        </div>
                        <dl>
                            <dt>{paymentInfoProperty.payment_date}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.tradeCreatedAt)
                                    ? ''
                                    : appUtils.dateFormatTypeFull(paymentTradeData?.tradeCreatedAt)}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{paymentInfoProperty.payment_person_name}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.tradeCustomerName)
                                    ? '-'
                                    : paymentTradeData?.tradeCustomerName}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{paymentInfoProperty.payment_method}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.paymentMethod)
                                    ? '-'
                                    : paymentTradeData?.paymentMethod}
                            </dd>
                        </dl>
                        <dl className="border-bottom black">
                            <dt>{paymentInfoProperty.payment_card_classification}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.cardName)
                                    ? '-'
                                    : paymentTradeData?.cardName}
                            </dd>
                        </dl>
                        <dl className="border-bottom black">
                            <dt>{paymentInfoProperty.payment_card_number}</dt>
                            <dd>
                                {validationUtil.isNullChk(paymentTradeData?.cardNumber)
                                    ? '-'
                                    : validationUtil.cardNumberFormatter(paymentTradeData?.cardNumber)}
                            </dd>
                        </dl>
                    </div>
                    <div className="payment-info-type">
                        <span className="total-payment">
                            <dl>
                                <dt>{paymentInfoProperty.payment_deposit}</dt>
                                <dd>
                                    {validationUtil.isNullChk(paymentTradeData?.tradeAmount)
                                        ? '-'
                                        : appUtils.amountFormat(paymentTradeData?.tradeAmount)}
                                </dd>
                            </dl>
                        </span>
                        <div className="non-caddieFee-text">
                            <p>{paymentInfoProperty.payment_non_caddiefee}</p>
                        </div>
                    </div>
                </>
            );
            break;
        default:
            return <></>;
            break;
    }
};

export default PreRoundDetail;
