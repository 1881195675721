import Badge from 'components/badge/Badge';
import moment from 'moment';
import PaymentBookingCancelPossibilityDate from './PaymentBookingCancelPossibilityDate';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {appUtils} from 'common/utils';
import {useSelector} from 'react-redux';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';

const PaymentBookingInfo = (props) => {
    const {paymentType, prepareRoundBooking} = props;

    const {
        golfClubName,
        courseName,
        adminHole,
        roundAt,
        cancelPossiblePeriod,
        isAfterCancelPeriod,
        isSelfCheckInPossible
    } = prepareRoundBooking;

    const {greenFeePerPerson} = useSelector((x) => x?.paymentBooking);

    return (
        <>
            {/* 골프장 정보 */}
            <div className="payment-booking-info">
                <div className="payment-booking-title-area">
                    <div className="payment-booking-state">
                        <Badge type={paymentType} />
                        {isSelfCheckInPossible ? <Badge type={'SELF_CHECK_IN'} /> : null}
                    </div>
                    <h2>{golfClubName}</h2>
                </div>
                <div className="payment-booking-detail-list">
                    <div className="infoType06">
                        <dl>
                            <dt>{bookingInfoProperty.booking_teetime}</dt>
                            <dd>{moment(roundAt).format('YYYY.MM.DD(dd) HH:mm')}</dd>
                        </dl>
                        <dl>
                            <dt>{bookingInfoProperty.booking_course_name}</dt>
                            <dd>{courseName ?? '-'}</dd>
                        </dl>
                        <dl>
                            <dt>{bookingInfoProperty.booking_hole_info}</dt>
                            <dd>{adminHole ?? '-'}</dd>
                        </dl>
                        <dl>
                            <dt>{bookingInfoProperty.booking_greenFee_per_person}</dt>
                            <dd>
                                {greenFeePerPerson === 0
                                    ? paymentInfoProperty.greenFee_golf_contact
                                    : appUtils.amountFormat(greenFeePerPerson)}
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>

            {/* 내장 조건 : 선택 내장 조건 이 없는 경우 내장조건 영역 비노출 */}
            {prepareRoundBooking?.timeSheetTags?.length > 0 && (
                <div className="payment-booking-info-inner-condition">
                    <h2>{bookingInfoProperty.booking_inner_condition}</h2>
                    <ul className="payment-booking-info-inner-condition-list">
                        {prepareRoundBooking?.timeSheetTags?.map((value, idx) => {
                            return <li key={idx}>{value.tagName}</li>;
                        })}
                    </ul>
                </div>
            )}
            {/* 취소 가능 기한 */}
            <PaymentBookingCancelPossibilityDate
                isAfterCancelPeriod={isAfterCancelPeriod}
                cancelPossiblePeriod={cancelPossiblePeriod}
            />
        </>
    );
};

export default PaymentBookingInfo;
