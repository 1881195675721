import React, {useEffect} from 'react';
import Icon from '../../icons/Icon';
import Button from '../../button/Button';

import useWindowDimensions from '../../hooks/UseWindowDimensions';
import {btn} from 'common/payment/locales/kr/btn';

import {fetchWrapper, history} from '_helpers';
import {paymentCancel} from 'common/payment/locales/kr/payment-cancel';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import './payment-booking-fail.scss';
import {paymentConstant} from 'common/payment';
import moment from 'moment';
import {appUtils} from 'common/utils';
import {SUCCESS_CODE} from 'common/constants';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import {viewReservationFail, viewReservationPaymentFail} from '_helpers/amplitude/events/view.events';
import {viewTaxonomy} from '_helpers/amplitude/taxonomy/view.taxonomy';

const PaymentBookingFail = () => {
    const {height} = useWindowDimensions();

    const bookingData = history.location?.state;

    const {eventName, type, code, message, data} = bookingData;

    // 홈으로 돌아가기
    const handleHomeBack = () => {
        history.navigate('/');
    };

    // 예약 실패 화면 내에서 새로고침한 경우
    if (type === 'booking' && !data) {
        handleHomeBack();
    }

    const onClickRetryPayment = async () => {
        try {
            const response = await fetchWrapper.get(`/prepare/round-booking/trade-amount?roundPlayer=${4}`);

            if (!response.code === SUCCESS_CODE) {
                throw new Error('다시 결제 하기 중 실패');
            }

            const bookingParams = {
                timeSheetId: data?.timeSheetId,
                courseId: data?.courseId,
                paymentType: response.data.paymentType,
                golfClubName: data?.golfClubName,
                golfClubId: data?.golfClubId
            };

            history.navigate(`/payment/booking/${data?.golfClubId}`, {state: bookingParams});
        } catch (error) {
            console.log(error);
            handleHomeBack();
        }
    };

    useEffect(() => {
        /*  예약 결제 실패 */
        if (bookingData) {
            const amplitudeEvent = async () => {
                const clickDateResponse = await fetchWrapper
                    .get(`/amplitude-events/click-date?roundDate=${moment(data?.roundAt).format('YYYY-MM-DD')}`)
                    .then((response) => response.code === SUCCESS_CODE && response.data);

                await fetchWrapper
                    .get(
                        `/amplitude-events/view-reservation?golfClubId=${data?.golfClubId}&timeSheetId=${data?.timeSheetId}`
                    )
                    .then((response) => {
                        const eventPropertys = {
                            ...response.data,
                            root: getAmplitudeRootPath(),
                            people: data?.roundPlayer,
                            total_prepayment_amount: appUtils.numberFormat(data?.totalAmount),
                            total_green_fee: appUtils.numberFormat(data?.totalGreenFee),
                            cart_fee: data?.cartFee,
                            total_fieldpayment_amount: appUtils.numberFormat(data?.expectOnsiteAmount),
                            caddie_fee: appUtils.numberFormat(data?.caddieFee),
                            payment_method: data?.transactionMethod,
                            buffer_period: clickDateResponse?.buffer_period,
                            day_of_the_week: clickDateResponse?.day_of_the_week,
                            day_type: clickDateResponse?.day_type,
                            [eventName === viewTaxonomy.view_reservation_fail
                                ? 'reservation_fail_reason'
                                : 'reservation_payment_fail_reason']: `[${code}]/${message}`
                        };

                        eventName === viewTaxonomy.view_reservation_fail
                            ? viewReservationFail(eventPropertys)
                            : viewReservationPaymentFail(eventPropertys);
                    });
            };
            amplitudeEvent();
        }
    }, [bookingData]);

    return (
        <div id="contents" className="payment-booking-fail-contents">
            <div className="cancel-status-area" style={{minHeight: `${height}px`}}>
                <div className="inner">
                    <div className="cancel-status-title-msg">
                        <i className="icon">
                            <Icon name="exclamationMark" width="60" height="60" viewBox="0 0 60 60" />
                        </i>
                        <strong className="tt">
                            {`${type === 'booking' ? '예약을' : '결제를'} 완료하지 못했습니다`}
                        </strong>
                        <p className="txt">{message}</p>
                    </div>

                    {type === 'booking' ? (
                        <div className="booking-info">
                            <div className="payment-info-type">
                                <dl>
                                    <dt>{bookingInfoProperty.booking_golfclub_name}</dt>
                                    <dd>{data?.golfClubName}</dd>
                                </dl>
                                <dl>
                                    <dt>{bookingInfoProperty.booking_teetime}</dt>
                                    <dd>{data?.roundAt ? moment(data?.roundAt).format('YYYY.MM.DD(dd) HH:mm') : ''}</dd>
                                </dl>
                                <dl
                                    className={`${
                                        data?.paymentType === paymentConstant.paymentType.ONSITE ? 'border-bottom' : ''
                                    }`}
                                >
                                    <dt>
                                        {bookingInfoProperty.booking_course_name} /{' '}
                                        {bookingInfoProperty.booking_hole_info}
                                    </dt>
                                    <dd>
                                        {data?.courseName ?? '-'} ∙ {data?.adminHole ?? '-'}
                                    </dd>
                                </dl>
                                {data?.paymentType === paymentConstant.paymentType.ONSITE ? (
                                    <></>
                                ) : (
                                    <dl className="border-bottom">
                                        <dt>{bookingInfoProperty.booking_type_title[data.paymentType]}</dt>
                                        <dd className="total-payment">{appUtils.amountFormat(data?.totalAmount)}</dd>
                                    </dl>
                                )}
                            </div>
                        </div>
                    ) : null}

                    <div className="btn-bottom">
                        <div className="cancel-status-sub-text-area">
                            <p>{paymentCancel.payment_fail_request_sub_text}</p>
                        </div>
                        <div className="inner">
                            {type === 'booking' ? (
                                <Button type="button" onClick={handleHomeBack}>
                                    {btn.gohome_btn}
                                </Button>
                            ) : (
                                <>
                                    <Button type="button" darkGray onClick={handleHomeBack}>
                                        {btn.gohome_btn}
                                    </Button>
                                    <Button type="button" onClick={onClickRetryPayment}>
                                        {btn.re_payment_btn}
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentBookingFail;
