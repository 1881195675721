import React from 'react';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {paymentInfoProperty} from 'common/payment/locales/kr/payment-info-property';
import {appUtils} from 'common/utils';

const PaymentBookingSuccessInfo = (props) => {
    const {bookingData} = props;
    return (
        <div className="payment-booking-success-info">
            <dl>
                <dt className="payment-success-700">{bookingInfoProperty.booking_person_info}</dt>
                <dd>
                    {bookingData?.bookerName
                        ? `${bookingData?.bookerName} (${appUtils.formatPhoneNumber(bookingData?.bookerPhoneNumber)})`
                        : ''}
                </dd>
            </dl>
            <dl>
                <dt className="payment-success-700">{bookingInfoProperty.booking_teetime}(일/시)</dt>
                <dd>{appUtils.dateFormatTypeFull(bookingData?.roundAt)}</dd>
            </dl>
            <dl>
                <dt className="payment-success-700">
                    {bookingInfoProperty.booking_course_name}/{bookingInfoProperty.booking_hole_info}
                </dt>
                <dd>
                    {bookingData?.courseName}∙{bookingData?.hole}
                </dd>
            </dl>
            <dl>
                <dt className="payment-success-700">{bookingInfoProperty.booking_round_player}</dt>
                <dd>{bookingData?.roundPlayerCount}명</dd>
            </dl>

            <dl className="border-bottom black">
                <dt className="payment-success-700">{bookingInfoProperty.booking_inner_condition}</dt>
                <dd>
                    <ul style={{whiteSpace: 'break-spaces'}}>
                        {bookingData?.timeSheetTags?.map((item, idx) => {
                            return <li key={idx}>{item.tagName}</li>;
                        })}
                    </ul>
                </dd>
            </dl>
        </div>
    );
};

export default PaymentBookingSuccessInfo;
