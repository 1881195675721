import {dateUtil} from 'common/payment';
import {bookingCancel} from 'common/payment/locales/kr/booking-cancel';
import Badge from 'components/badge/Badge';
import moment from 'moment';

const PaymentBookingCancelPossibilityDate = (props) => {
    const {cancelPossiblePeriod, isAfterCancelPeriod} = props;

    return (
        <div className="payment-booking-info-cancel-possibility-date">
            <div>
                <h2>{bookingCancel.cancel_possible}</h2>
                <span className={`payment-booking-cancel ${isAfterCancelPeriod}`}>
                    {moment(cancelPossiblePeriod).format('YYYY-MM-DD(dd) HH:mm')}
                </span>
                <Badge
                    type={`${isAfterCancelPeriod ? 'CANCEL_RECEPTION' : 'CANCEL_POSSIBILITY_DATE'}`}
                    date={dateUtil.cancelDateRestTime(cancelPossiblePeriod)}
                />
            </div>
            <div className="payment-booking-info-cancel-notice">{bookingCancel.cancel_possible_sub_text}</div>
        </div>
    );
};

export default PaymentBookingCancelPossibilityDate;
