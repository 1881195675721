import React, {useRef, useEffect, useState} from 'react';
import '../../_view/board/board.scss';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import Board from '../../_view/board/Board';
import {useParams} from 'react-router-dom';
import useIntersectionObserver from '../../common/observerUtils';
import {localStorageUtil} from '../../common/utils';
import {viewCCNotice} from '../../_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';
import {useSelector} from 'react-redux';

function ClubBoard() {
    const {id} = useParams();
    const noticeFrom = useSelector((x) => x?.smartRound.noticeFrom);

    const [boardDataList, setBoardDataList] = useState(null);
    const [clubName, setClubName] = useState('');
    const [page, setPage] = useState(0);
    const [size, setsize] = useState(10);

    // 인피니티 스크롤
    const [hasNext, setHasNext] = useState(false);
    const ref = useRef();

    //리스트 로딩 스피너
    const [isLoading, setIsLoading] = useState(false);

    const getBoardList = () => {
        setIsLoading(true);
        fetchWrapper.get(`/notice/list?golfClubId=${id}&page=${page}&size=${size}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                const data = response?.data;

                setClubName(data?.golfClubName || null);
                setHasNext(data?.hasNext);

                // hasNext가 없으면 초기 데이터 넣어주기
                if (hasNext && page > 0) {
                    setBoardDataList((prev) => [...prev, ...data.lists] || null);
                } else {
                    setBoardDataList(data.lists || null);
                }
            }

            setIsLoading(false);
        });

        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${id}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                viewCCNotice(response.data, getAmplitudeRootPath());
            }
        });
    };

    useEffect(() => {
        id && getBoardList();
    }, [page]);

    // 인피니티 스크롤 커스텀 훅 사용
    const [observe, unobserve] = useIntersectionObserver(() => {
        setPage((prev) => prev + 1);
    });

    useEffect(() => {
        if (hasNext) {
            observe(ref.current);
        } else if (hasNext === false && page > 0) {
            unobserve(ref.current);
        }
    }, [hasNext]);

    useEffect(() => {
        // 공지사항 문구 계속 노출, 최초 1회 선택 시 비노출로 변경
        if (!localStorageUtil.get('noticeTooltipHide')) {
            localStorageUtil.set('noticeTooltipHide', true);
        }
        console.log('rootPath', getAmplitudeRootPath());
    }, []);

    return (
        <Board
            golfClubId={id}
            boardDataList={boardDataList}
            clubName={clubName}
            ref={ref}
            backLink={noticeFrom}
            isLoading={isLoading}
        />
    );
}

export default ClubBoard;
