import React, {useCallback, useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import TitleArea from '../common/TitleArea';
import AuthServiceAgree from '../../components/agreement/telecom/AuthServiceAgree';
import Identification from '../../components/agreement/telecom/Identification';
import AgencyPolicy from '../../components/agreement/telecom/AgencyPolicy';
import PersonalInfo from '../../components/agreement/telecom/PersonalInfo';
import ThridPrivacy from '../../components/agreement/telecom/ThridPrivacy';
import JoinServiceAgree from '../agreement/member/JoinServiceAgree'; // 서비스 이용약관
import PersonalInformation from '../../components/agreement/member/PersonalInformation'; // 개인정보 수집 및 이용 동의
import JoinLocationService from '../../components/agreement/member/JoinLocationService'; // 위치정보 수집 및 이용 동의
import PrivacyPolicyOtherCompany from '../../components/agreement/member/PrivacyPolicyOtherCompany'; // 개인정보 제3자 제공동의
import MarketingPolicy from '../../components/agreement/member/MarketingPolicy'; // 마케팅 정보 수신 동의
import GolfClubDetailAgree from '../agreement/reservation/GolfClubDetailAgree';
import BookingPersonalInfo from '../agreement/reservation/BookingPersonalInfo';
import GetReservationInfoAgree from '../agreement/reservation/GetReservationInfoAgree'; // 예약정보 조회 · 전송동의

function PolicyLayer({open, handleClose, title, telecomCode, agreeType, contents, golfClubName}) {
    const scrollRef = useRef(null);
    const [isTitleVisible, setIsTitleVisible] = useState(false);

    const onScroll = useCallback((evt) => {
        const div = evt.target;

        const currentScrollPosition = div.scrollTop;

        if (currentScrollPosition > 0) {
            setIsTitleVisible(true);
        } else {
            setIsTitleVisible(false);
        }

        //console.log(currentScrollPosition);
    }, []);

    const layerClose = () => {
        handleClose();
        setTimeout(() => {
            setIsTitleVisible(false);
        }, [100]);
    };

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose} className="fullPageModal">
                <div className="layerPop">
                    <div className={`layerHeader2 ${isTitleVisible ? 'border' : ''}`}>
                        {agreeType !== 'GET_RESERVATION_INFO_AGREE' && (
                            <div className={`layerHeader_title ${isTitleVisible ? 'visible' : ''}`}>{title}</div>
                        )}
                        <button type="button" className="btnClose" onClick={layerClose}>
                            닫기
                        </button>
                    </div>
                    <div className="layerContents2" ref={scrollRef} onScroll={onScroll}>
                        <div id="agreeContents" className="inner">
                            <TitleArea pageTitle={title} />
                            <div className="agreeCon">
                                {agreeType ? (
                                    <>
                                        {/****** 통신사 이용 약관 ******/}
                                        {agreeType === 'TELECOM_SERVICE' && <AuthServiceAgree />}
                                        {agreeType === 'TELECOM_ID' && <Identification telecomCode={telecomCode} />}
                                        {agreeType === 'TELECOM_AGREE' && <AgencyPolicy telecomCode={telecomCode} />}
                                        {agreeType === 'TELECOM_PRIVACY' && <PersonalInfo telecomCode={telecomCode} />}
                                        {agreeType === 'TELECOM_THRID' && <ThridPrivacy telecomCode={telecomCode} />}

                                        {/****** 회원가입 이용 약관 ******/}
                                        {agreeType === 'JOIN_SERVICE' && <JoinServiceAgree />}
                                        {agreeType === 'JOIN_PRIVACY' && <PersonalInformation />}
                                        {agreeType === 'JOIN_LOCATION' && <JoinLocationService />}
                                        {agreeType === 'JOIN_THRID' && <PrivacyPolicyOtherCompany />}
                                        {agreeType === 'JOIN_MARKETING' && <MarketingPolicy />}

                                        {/***** 골프장별 취소/위약규정 ******/}
                                        {agreeType === 'GOLF_CLUB_AGREE' && (
                                            <GolfClubDetailAgree contents={contents} title={title} />
                                        )}

                                        {/***** 예약/개인정보 제3자 제공동의 ******/}
                                        {agreeType === 'BOOKING_PRIVACY' && (
                                            <BookingPersonalInfo golfClubName={golfClubName} />
                                        )}

                                        {/***** 예약정보 조회 · 전송동의 ******/}
                                        {agreeType === 'GET_RESERVATION_INFO_AGREE' && <GetReservationInfoAgree />}
                                    </>
                                ) : (
                                    '페이지를 찾을 수 없습니다.'
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default PolicyLayer;
