import React from 'react';
import './badge.scss';

const badgeTypes = {
    DEFAULT: '', // 기본값
    PREPAY: '선결제',
    RESERVE: '예약금결제',
    ONSITE: '현장결제',
    SELF_CHECK_IN: '셀프체크인',
    CANCEL_POSSIBILITY_DATE: (date) => `${date} 남음`, // 취소가능기한 표시
    CANCEL_RECEPTION: '경과', // 취소가능기한 경과
    CANCEL_POSSIBILITY_DATE_MYPAGE: (date) => `${date} 남음`,
    CANCEL_RECEPTION_MYPAGE: '경과',
    EVENT: '이벤트',
    SELF_CHECK_IN_ARROW: '셀프체크인',
    UNAFFILIATED: '미제휴'
};

function Badge(props) {
    const {type = 'DEFAULT', style, date, onClick} = props;
    const lowerCaseType = type.toLowerCase();
    const findClassName = lowerCaseType.replace(/_/g, '-');

    return (
        <span style={style} className={`badge ${findClassName}`} onClick={onClick}>
            {typeof badgeTypes[type] === 'function' ? badgeTypes[type](date) : badgeTypes[type]}
        </span>
    );
}

export default Badge;
