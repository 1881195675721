import React from "react";

function MarketingPolicy() {
    return (
        <>
            <section>
                <p>
                    본 마케팅 정보 수신에 대한 동의를 거부하실 수 있으며, 이 경우 회원가입은 가능하나 일부 서비스 이용 및 각종 광고, 이벤트 및 고객 맞춤형 상품 추천 등의 혜택정보 제공이 제한될 수 있습니다.
                    <br/>
                </p>
                <table
                    cellPadding="0"
                    cellSpacing="0"
                    className="agreeInfoTb"
                >
                    <colgroup>
                        <col style={{width: "25%"}}/>
                        <col style={{width: "25%"}}/>
                        <col style={{width: "25%"}}/>
                        <col style={{width: "25%"}}/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>항목</th>
                        <th>이용목적</th>
                        <th>수집 항목</th>
                        <th>보유 기간</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="agreeInfoTr">
                        <td>마케팅</td>
                        <td>
                            상품광고 및 서비스 안내, 이벤트 정보와 고객 맞춤형 상품 추천
                        </td>
                        <td>
                            이름, 휴대전화번호, 이메일 주소, 마케팅 정보 수신 동의 여부
                        </td>
                        <td>
                            회원탈퇴 또는 동의 철회 시까지
                        </td>
                    </tr>
                    </tbody>
                </table>
            </section>
        </>
    );
}

export default MarketingPolicy;
