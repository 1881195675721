import React, {useEffect, useState} from 'react';
import AvatarDefaultImg from '../../assets/images/common/profile_default.svg';
import InviteCompeleteImg from '../../assets/images/common/profile_invite_complete.svg';
import {useSelector} from 'react-redux';

const Avatar = ({data, editable, handleAlertOpen, setDeleteMemberId, src, showIcon, defaultSrc, isBookingCancel}) => {
    const member = useSelector((x) => x?.users?.users);

    const avatarName = data?.name;
    const maskedName = avatarName?.replace(avatarName.substring(1, 2), '*'); // 마스킹 처리

    const profileUrl = src ? src : data ? data?.profileImageURL : member?.profileImageURL;
    const memberName = data ? maskedName : member?.maskedName;
    const gender = data?.gender;
    const head = !!data?.host;
    const deleteIcon = editable && showIcon && !data?.host && data?.checkinAt === null;
    const inviteChannel = data?.inviteChannel;

    const [nonMember, _setNonMember] = useState(data ? !data?.golagaMember : false); // 비회원
    const [profileImg, setProfileImg] = useState(AvatarDefaultImg);

    const onErrorProfileImage = () => {
        setProfileImg(AvatarDefaultImg);
    };

    useEffect(() => {
        if (profileUrl) {
            // 프로필 사진을 등록한 경우
            // 브라우저 캐시를 초기화 하기 위해 timestamp로 변경
            setProfileImg(profileUrl + `?timestamp=${new Date().getTime()}`);
        } else {
            // 프로필 사진 등록하지 않은 경우
            setProfileImg(AvatarDefaultImg);
            if (inviteChannel) {
                setProfileImg(InviteCompeleteImg);
            }
        }
    }, [profileUrl]);

    return (
        <>
            <div className={head ? 'Avatar head' : 'Avatar'}>
                <div className={`thumbArea ${isBookingCancel ? 'off' : ''}`}>
                    <div className="profileImg">
                        <img
                            src={profileImg}
                            alt={memberName}
                            className={nonMember || !profileUrl ? 'borderNone' : ''}
                            onError={onErrorProfileImage}
                        />
                        {deleteIcon ? (
                            <button
                                className="btnDelete"
                                onClick={(e) => {
                                    handleAlertOpen(e);
                                    setDeleteMemberId(data?.id, data?.golagaMember);
                                }}
                                data-name={memberName}
                            >
                                삭제
                            </button>
                        ) : null}
                    </div>
                </div>

                <div>
                    <p className="name">
                        {/* 비회원일 시 초대 경로 아이콘 노출 */}
                        {nonMember && inviteChannel === 'KEYIN' ? (
                            <i className="icon iconPhone"></i>
                        ) : nonMember && inviteChannel === 'KAKAO' ? (
                            <i className="icon iconKakao"></i>
                        ) : null}

                        {/* 카카오로 초대된 비회원은 이름에 "비회원" 노출 */}
                        {/*{nonMember && inviteChannel === 'KAKAO' ? '비회원' : memberName}*/}
                        {nonMember ? '수락대기' : memberName}

                        {/* 회원일 시 남/여 노출 */}
                        {!nonMember && gender && `(${gender === 'MALE' ? '남' : '여'})`}
                    </p>
                </div>
            </div>
        </>
    );
};

export default Avatar;
