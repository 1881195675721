import {createSlice} from '@reduxjs/toolkit';

const name = 'invite';
const initialState = {
    type: 'KEYIN',
    friend: {
        name: '',
        phone: ''
    },
    refresh: false
};

const inviteSlice = createSlice({
    name,
    initialState,
    reducers: {
        setFriend: {
            reducer: (state, action) => {
                state.friend = action.payload;
            }
        },
        setRefresh: {
            reducer: (state, action) => {
                state.refresh = action.payload;
            }
        }
    }
});

export const inviteActions = {...inviteSlice.actions};
export const inviteReducer = inviteSlice.reducer;
