import React from 'react';
import {Skeleton} from "@mui/material";
import './skeleton.scss'

const SkeletonElement = ({ type, variant }) => {
    const classes = `skeleton ${type}`;

    return (
        <Skeleton variant={variant} className={classes}></Skeleton>
    );
}

export default SkeletonElement;