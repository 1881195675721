import React, {useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import iconKakao from '../../../assets/images/reservation/img-kakao.png';
import iconMessage from '../../../assets/images/reservation/img-message.png';
import iconLink from '../../../assets/images/reservation/img-link.png';
import DefaultAlert from '../../alert/DefaultAlert';
import DirectionSnackbar from '../../alert/DirectionSnackbar';
import {encodeURI} from 'js-base64';
import {RESULTS, appUtils, getExternalStoragePermission, localStorageUtil} from '../../../common/utils';

const {Kakao} = window;

function ShareDrawer({open, handleClose, golfClubId, detailData, shareStatus}) {
    let locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
    let _device = '';
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) {
        _device = 'android';
    } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
        _device = 'ios';
    }

    const [snackBarOpen, setSnackBarOpen] = useState({
        open: false,
        msg: ''
    });

    const [alertOpen, setAlertOpen] = useState(false);
    const handleAlertOpen = () => setAlertOpen(true);
    const handleAlertClose = () => setAlertOpen(false);
    const [device, setDevice] = useState(locationStorage?.device || '');

    const kakakoShare = () => {
        const golfClubName = detailData?.golfClubName;
        const golfClubImg = detailData?.stillImageUrls[0].pathUrl;
        const golfClubDsc = detailData?.golfClubDescription;
        // const address = detailData?.addressLocal;
        // const addressToArray = address.split(' ');
        // const shortAddress = addressToArray.slice(0, 2).join(' ');
        const shortAddress = detailData?.location;
        const queryString = `?golfClubName=${encodeURI(golfClubName)}&shortAddress=${encodeURI(
            shortAddress
        )}&golfClubId=${golfClubId}`;

        setTimeout(() => {
            // 카카오 공유하기
            Kakao?.Share?.createCustomButton({
                container: '#shareKakao',
                templateId: Number(process.env.REACT_APP_KAKAO_MESSAGE_ID),
                templateArgs: {
                    GOLF_CLUB_IMG: golfClubImg,
                    GOLF_CLUB_NAME: golfClubName,
                    ADDRESS: shortAddress,
                    GOLF_CLUB_DSC: golfClubDsc,
                    WEB_PATH: process.env.REACT_APP_KAKAO_SHARE_PATH + queryString
                },
                installTalk: true
            });
        }, 100);
    };

    const onClickMessage = () => {
        const golfClubName = detailData?.golfClubName;
        const address = detailData?.addressLocal;
        // const address = detailData?.addressLocal;
        // const addressToArray = address.split(' ');
        // const shortAddress = addressToArray.slice(0, 2).join(' ');
        const shortAddress = detailData?.location;
        const queryString = `?golfClubName=${golfClubName}&shortAddress=${shortAddress}&golfClubId=${golfClubId}`;
        let shareTitle = '골라가'; // 하드코딩
        let shareText = `골프클럽 : ${golfClubName}`; // 하드코딩
        let URLPreFix = process.env.REACT_APP_DEEPLINK_PREFIX;
        let shareURL = URLPreFix + process.env.REACT_APP_MESSAGE_SHARE_PATH + queryString;

        if (navigator.share) {
            navigator
                .share({
                    title: shareTitle,
                    text: shareText,
                    url: shareURL
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error share', error));
        } else {
            // 서비스 안내 Alert
            handleAlertOpen();
        }
    };

    const onClickLink = () => {
        const golfClubName = detailData?.golfClubName;
        const address = detailData?.addressLocal;
        const addressToArray = address.split(' ');
        const shortAddress = addressToArray.slice(0, 2).join(' ');
        const queryString = `?golfClubName=${encodeURI(golfClubName)}&shortAddress=${encodeURI(
            shortAddress
        )}&golfClubId=${golfClubId}`;

        let URLPreFix = process.env.REACT_APP_DEEPLINK_PREFIX;
        let shareURL = URLPreFix + process.env.REACT_APP_MESSAGE_SHARE_PATH + queryString;

        if (window.ReactNativeWebView) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    type: 'CLIPBOARD',
                    clipboard: shareURL
                })
            );
        } else {
            navigator.clipboard.writeText(shareURL);
        }

        setSnackBarOpen({
            open: true,
            msg: '클립보드에 링크가 저장되었습니다.'
        });
    };

    // 시스템 공유하기 소스 보존
    const onClickSystemShare = () => {
        if (!detailData) return;

        // 골프클럽 약식 주소
        const address = detailData.addressLocal;
        const addressToArray = address?.split(' ');
        const shortAddress = addressToArray?.slice(0, 2).join(' ');
        // 골프장명
        const golfClubName = detailData?.golfClubName;
        const golfClubId = detailData?.id;

        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'share',
                golfClubName,
                shortAddress,
                golfClubId
            })
        );
    };

    useEffect(() => {
        if (open) {
            kakakoShare(detailData);
        }

        return () => {
            Kakao?.Share.cleanup();
        };
    }, [open, detailData]);

    return (
        <>
            <Drawer anchor="bottom" open={open} onClose={handleClose} className="defaultDrawer shareDrawer">
                <div className="drawer-content">
                    <div className={'drawerContents'}>
                        <button id="shareKakao">
                            <img src={iconKakao} alt="kakao" />
                            <p>카카오톡</p>
                        </button>
                        <button onClick={() => onClickSystemShare()}>
                            <img src={iconMessage} alt="message" />
                            <p>앱 선택</p>
                        </button>
                        <button onClick={() => onClickLink()}>
                            <img src={iconLink} alt="link" />
                            <p>링크복사</p>
                        </button>
                    </div>
                </div>
            </Drawer>
            <DefaultAlert
                open={alertOpen}
                handleOpen={handleAlertOpen}
                handleClose={handleAlertClose}
                title={'미지원 디바이스'}
                contents={'공유하기 기능을 지원하지 않는 디바이스 입니다.'}
            />
            <DirectionSnackbar
                direction={'up'}
                position={'bottom'}
                open={snackBarOpen.open}
                msg={snackBarOpen.msg}
                duration={2000}
                setOpen={setSnackBarOpen}
            />
        </>
    );
}

export default ShareDrawer;
