import React, {useState, useEffect, useRef} from 'react';
import Navigation from '../../navigation/Navigation';
import './GolfZoneSearch.scss';
import GolfZoneList from './GolfZoneList';
import SearchDrawer from './SearchDrawer';
import {fetchWrapper, history} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import Icon from '../../icons/Icon';
import {likeMsg} from '../../../common/messages';
import DirectionSnackbar from '../../alert/DirectionSnackbar';
import ExclamationIcon from '../../../assets/images/common/exclamation.svg';
import Loading from '../../../common/loading/Loading';
import {appUtils, localStorageUtil} from '../../../common/utils';
import SelfCheckInGuideLayer from '../../smart-round/SelfCheckInGuideLayer';
import {rootTaxonomy} from '../../../_helpers/amplitude/taxonomy/root.taxonomy';
import {viewSearchFavoriteTab, viewSearchPopularTab} from '../../../_helpers/amplitude/events/view.events';
import {clickSearchKeyInput} from '../../../_helpers/amplitude/events/click.event';
import Banner from 'components/common/Banner/Banner';
import banner_search_iphone_11_pro from '../../../assets/images/banner/banner_search_iphone_11_pro.png';

function GolfZoneSearch() {
    const [status, setStatus] = useState('interest');
    const [interestList, setInterestList] = useState([]);
    const [popularClubList, setPopularClubList] = useState([]);
    const [navigationState, _setNavigationState] = useState(history.location.state);
    const [isSnackBarOpen, setIsSnackBarOpen] = useState({
        open: false,
        msg: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const [golfZoneList, _setGolfZoneList] = useState([]);
    const myGolfZoneList = useRef(golfZoneList);

    // 셀프체크인 가이드 레이어 오픈 여부
    const [selfCheckInGuideLayerOpen, setSelfCheckInGuideLayerOpen] = useState(false);
    const handleOpen = () => setSelfCheckInGuideLayerOpen(true);
    const handleClose = () => setSelfCheckInGuideLayerOpen(false);

    const setGolfZoneList = (list) => {
        myGolfZoneList.current = list;
        _setGolfZoneList(list);
    };

    // 기본 필터 Layer
    const [basicFilterOpen, setBasicFilterOpen] = useState(false);

    //배너별 타이틀
    const bannerTitle = {
        mainTitle: '즐겨찾는 골프장관리',
        subTitle: '나의 관심 골프장만 따로 티타임조회'
    };

    useEffect(() => {
        // 로컬스토리지에 저장되어있으면 해당 정보로 셋팅
        let selectedLocation_util = JSON.parse(localStorageUtil.get('selectedLocation'));

        // OutdoorDetail.js와 비슷한 방어 로직 삽입
        if (selectedLocation_util === null) {
            let latlng;
            const locationStorage = JSON.parse(localStorageUtil.get('LOCATION'));
            if (locationStorage === null) {
                // 서울시청
                latlng = {lat: 37.5663, lon: 126.9779};
            } else {
                if (locationStorage.gpsrequest === 0) {
                    // 현재위치
                    latlng = {lat: locationStorage.lat, lon: locationStorage.lon};
                } else {
                    // 서울시청
                    latlng = {lat: 37.5663, lon: 126.9779};
                }
            }
            selectedLocation_util = {
                locationLat: latlng.lat,
                locationLon: latlng.lon
            };
        }

        sessionStorage.setItem('root', rootTaxonomy.search_favorite_tab);

        setIsLoading(true);
        // 관심 골프장 리스트업
        fetchWrapper
            .get(
                `/golfClub/pre/interestClub?userLat=${selectedLocation_util.locationLat.toFixed(
                    5
                )}&userLon=${selectedLocation_util.locationLon.toFixed(5)}`,
                null
            )
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    //console.log('interestList', response?.data);
                    const myInterest = response?.data;
                    myInterest.map((item) => {
                        item['isInterest'] = true;
                    });
                    const newMyInterest = JSON.parse(JSON.stringify(myInterest));
                    setInterestList(newMyInterest);
                } else {
                    console.log(response?.error);
                }

                setIsLoading(false);
            });

        // 인기 검색 골프장 리스트업
        fetchWrapper
            .get(
                `/search/popularity/golf-club?userLat=${selectedLocation_util.locationLat.toFixed(
                    5
                )}&userLon=${selectedLocation_util.locationLon.toFixed(5)}`,
                null
            )
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    //console.log('popularClubList', response?.data);
                    const myPopularClub = response?.data;
                    myPopularClub.map((item) => {
                        item['isInterest'] = false;
                    });
                    const newMyPopularClub = JSON.parse(JSON.stringify(myPopularClub));
                    setPopularClubList(newMyPopularClub);
                } else {
                    console.log(response?.error);
                }

                setIsLoading(false);
            });

        if (navigationState === null) {
            return;
        }

        // 검색 결과에서부터 왔다면 검색 drawer open
        if (navigationState.linkFrom === 'realTimeSearchList' || navigationState.linkFrom === 'search-result') {
            setBasicFilterOpen(true);
            sessionStorage.setItem('root', rootTaxonomy.search_preview);
        } else if (navigationState.linkFrom === 'search') {
            setBasicFilterOpen(false);
        }
    }, []);

    useEffect(() => {
        if (golfZoneList.length === 0) {
            setGolfZoneList(interestList);
        }
    }, [interestList]);

    useEffect(() => {
        setGolfZoneList([]);

        if (status === 'interest') {
            sessionStorage.setItem('root', rootTaxonomy.search_favorite_tab);
            viewSearchFavoriteTab();
            setGolfZoneList(interestList);
        } else if (status === 'searchRank') {
            sessionStorage.setItem('root', rootTaxonomy.search_popular_tab);
            viewSearchPopularTab();
            setGolfZoneList(popularClubList);
        }
    }, [status]);

    const onBasicFilterOpen = () => {
        clickSearchKeyInput();
        setBasicFilterOpen(true);
        sessionStorage.setItem('root', rootTaxonomy.search_preview);
    };

    const changeStatus = (e) => {
        setStatus(e);
    };

    const removeFavoriteItem = (item) => {
        const newList = golfZoneList.filter((golfZoneListitem) => golfZoneListitem.golfClubId !== item.golfClubId);
        setInterestList(newList);
        setGolfZoneList(newList);

        setIsSnackBarOpen({
            open: true,
            msg: likeMsg.likeDelete
        });
    };

    const onClickSelfCheckInBtn = (e) => {
        handleOpen();
        appUtils.openWebViewModalPostMsg();
    };

    // 스크롤 없는 풀페이지 스타일 선언을 위한 클래스명 추가
    useEffect(() => {
        document.documentElement.classList.add('fullPage');
        return () => {
            document.documentElement.classList.remove('fullPage');
        };
    }, []);

    return (
        <div id="contents">
            <div className="golfZonSearch">
                {/* 검색창 */}
                <div className="topBar">
                    <div className="searchBox">
                        <div className="iconInput">
                            <input
                                type="text"
                                placeholder="지역, 골프장명으로 검색하세요."
                                onClick={onBasicFilterOpen}
                                defaultValue=""
                                onChange={(e) => {
                                    e.target.value = '';
                                }}
                            />
                        </div>
                        <div className="btnArea">
                            <span>
                                <Icon name="search" width="15" height="17" viewBox="0 0 15 17" />
                            </span>
                        </div>
                    </div>
                </div>

                {/* 탭 메뉴 */}
                <div className="tabType01">
                    <ul>
                        <li className={`tab ` + (status === 'interest' && 'on')}>
                            <button
                                type="button"
                                onClick={() => {
                                    changeStatus('interest');
                                }}
                            >
                                즐겨찾는 골프장
                            </button>
                        </li>
                        <li
                            className={`tab ` + (status === 'searchRank' && 'on')}
                            onClick={() => {
                                changeStatus('searchRank');
                            }}
                        >
                            <button type="button">인기 검색 골프장</button>
                        </li>
                    </ul>
                </div>

                {/* 광고 구좌 */}
                <div className="advertiseImage">
                    {/* <img src="/images/banner/Banner_01.png" alt="즐겨찾는 골프장 관리" /> */}
                    <Banner type="05" bannerTitle={bannerTitle} src={banner_search_iphone_11_pro} radius={true} />
                </div>

                {/* 골프장 리스트 */}
                {isLoading ? (
                    <Loading />
                ) : golfZoneList.length === 0 ? (
                    <div className="noDataList golfZoneSearchNoData">
                        <i className="icon">
                            <img src={ExclamationIcon} alt="" />
                        </i>
                        <p>등록된 즐겨찾는 골프장이 없습니다</p>
                        <span>
                            관심 골프장을 등록 하시고
                            <br />
                            보다 쉽고 빠르게 예약해 보세요
                        </span>
                    </div>
                ) : (
                    <div className="scrollArea02">
                        <div className="inner">
                            <GolfZoneList
                                golfzoneList={myGolfZoneList.current}
                                isSearchRank={status === 'searchRank'}
                                setIsSnackBarOpen={setIsSnackBarOpen}
                                removeFavoriteItem={removeFavoriteItem}
                                isLoading={isLoading}
                                onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                            />
                        </div>
                    </div>
                )}
            </div>
            <Navigation />

            {/* 기본 필터 Layer */}
            <SearchDrawer basicFilterOpen={basicFilterOpen} setBasicFilterOpen={setBasicFilterOpen} />

            <DirectionSnackbar
                direction={'up'}
                position={'bottom'}
                open={isSnackBarOpen.open}
                msg={isSnackBarOpen.msg}
                duration={2000}
                setOpen={setIsSnackBarOpen}
                isNavigation={true}
            />

            {/* 셀프체크인 가이드 Layer */}
            <SelfCheckInGuideLayer handleOpen={selfCheckInGuideLayerOpen} handleClose={handleClose} />
        </div>
    );
}

export default GolfZoneSearch;
