import React from 'react';
import './maintainance-notice.scss';
import Icon from '../icons/Icon';

const MaintainanceNotice = () => {
    const noticeText = process.env?.REACT_APP_NOTICE_CONTENTS.split('.');
    const noticeDate = process.env?.REACT_APP_NOTICE_START_DATE;
    const noticeEndDate = process.env.REACT_APP_NOTICE_END_DATE;

    return (
        <>
            <div className="maintainance-wrap">
                <div className="maintainance-notice">
                    <Icon name="logo" fill="#3906C3" className="logo" />
                    <div className="notice-contents">
                        <p>{noticeText[0]}.</p>
                        <p>{noticeText[1]}.</p>
                    </div>
                    <p className="notice-date">
                        점검시간 : {noticeDate} <br />~ {noticeEndDate}
                    </p>
                </div>
            </div>
        </>
    );
};

export default MaintainanceNotice;
