import {fetchWrapper} from '_helpers';
import {smartRoundActions} from '_store/smartRound.slice';
import {SUCCESS_CODE} from 'common/constants';
import DefaultAlert from 'components/alert/DefaultAlert';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './self-check-in.scss';

const CheckInAfter = (props) => {
    const {} = props;
    const auth = useSelector((x) => x?.auth.user);
    const user = useSelector((x) => x?.smartRound.user);
    const bookingId = useSelector((x) => x?.smartRound.bookingId);
    const round = useSelector((x) => x?.smartRound.round);
    const caddieList = useSelector((x) => x?.smartRound.caddieList);
    const golfCartList = useSelector((x) => x?.smartRound.golfCartList);
    const checkinMessage = useSelector((x) => x?.smartRound.checkinMessage);
    const golfBagList = useSelector((x) => x?.smartRound.golfBagList);
    const checkInAlertMessage = useSelector((x) => x?.smartRound.checkInAlertMessage);
    const dispatch = useDispatch();

    const [isRefreshing, setIsRefreshing] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertContents, setAlertContents] = useState('');
    const [isPromotion, setIsPromotion] = useState(false);
    const [promotionAlertOpen, setPromotionAlertOpen] = useState(false);

    const onClickRefresh = () => {
        setIsRefreshing(true);
        fetchWrapper.get(`/booking/${bookingId}/checkin/check/${user?.id}`, null).then((response) => {
            // CMS 통신 새로고침
            dispatch(smartRoundActions.getBags());
            dispatch(smartRoundActions.getBookingMemberList(bookingId)).finally(() => {
                setIsRefreshing(false);
            });
        });
    };

    useEffect(() => {
        if (checkInAlertMessage) {
            setAlertContents(checkInAlertMessage);
            setAlertOpen(true);
        }
        onClickRefresh();
        return () => {};
    }, []);

    useEffect(() => {
        checkPromotionMemeber();
    }, [isPromotion]);

    // 프로모션 대상자 api 가져오기
    // const checkPromotionMemeber = async () => {
    //     const response = await fetchWrapper.get('/promotion/applicable');
    //     if (response.code === SUCCESS_CODE) {

    //     }
    // };

    const checkPromotionMemeber = async () => {
        // 셀프체크인 프로모션 적용
        const resPromotionApplicable = await fetchWrapper.get('/promotion/applicable');
        if (resPromotionApplicable.code === SUCCESS_CODE) {
            const payloadPromotion = {
                promotionId: resPromotionApplicable.data.promotionId,
                roundBookingId: bookingId
            };
            const resPromotionApply = await fetchWrapper.post('/promotion/apply', payloadPromotion);
            if (resPromotionApply.code === SUCCESS_CODE) {
                // setIsPromotion(true);
                setPromotionAlertOpen(true);
                if (isPromotion && !checkInAlertMessage) {
                    setPromotionAlertOpen(true);
                }
            }
        }
    };

    // 시간경과 얼럿 후 프로모션 창 열기
    const closeAlert = () => {
        if (isPromotion) {
            setPromotionAlertOpen(true);
        }
        setAlertOpen(false);
    };

    return (
        <div className="check-in-content">
            <div className="check-in-card checked-in">
                <div className="check-in-info">
                    <div className="top-container">
                        <p className="booking-type">
                            예약처 : {round?.bookingTypeName ? round?.bookingTypeName : '골라가'}{' '}
                        </p>
                        <div className="btn-area">
                            <button onClick={onClickRefresh} className={`${isRefreshing ? 'rotate' : ''}`}>
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div className="name-container">
                        <div className="name-golf-bag">
                            <div className="name-gender">
                                <p className="name">{user?.name}</p>
                                <p className="gender">({user?.gender === 'MALE' ? '남' : '여'})</p>
                            </div>
                            <p className="golf-bag">
                                {/* 용원cc 골프백 네임 입력 skip */}
                                {round.golfClubId === '2c5c8fb8-b336-11ed-ae09-040300000000' ? '' : '골프백네임: '}
                                {round.golfClubId !== '2c5c8fb8-b336-11ed-ae09-040300000000' &&
                                golfBagList &&
                                golfBagList.length > 0 ? (
                                    <i>{golfBagList[0]?.tagName ? golfBagList[0]?.tagName : user?.name}</i>
                                ) : (
                                    ''
                                )}
                            </p>
                        </div>
                        <div className="locker">
                            <p className="title">락커번호</p>
                            <p className="num">{user?.lockerNo}</p>
                        </div>
                    </div>
                    {/* 카트, 캐디 */}
                    {checkinMessage ? (
                        <div className="helper-info">
                            <dl className="cart">
                                <dt>
                                    <span>{checkinMessage}</span>
                                </dt>
                            </dl>
                        </div>
                    ) : (
                        <div className="helper-info">
                            <dl className="cart">
                                <dt>• 카트</dt>
                                <dd>
                                    {golfCartList
                                        ? golfCartList.map((data, idx) => {
                                              return <span key={`cart-${idx}`}>{data?.cartNo}</span>;
                                          })
                                        : ''}
                                </dd>
                            </dl>
                            <dl className="caddy">
                                <dt>• 캐디</dt>
                                <dd>
                                    {caddieList
                                        ? caddieList.map((data, idx) => {
                                              return <span key={`caddie-${idx}`}>{data?.caddieName}님</span>;
                                          })
                                        : ''}
                                </dd>
                            </dl>
                        </div>
                    )}
                    <p className="info">
                        • 락커번호와 캐디 정보는 라운드 종료 후 내 정보 {'>'} <br />
                        &nbsp;&nbsp;&nbsp;예약내역 {'>'} 지난라운드에서 확인 할 수 있어요!
                    </p>
                </div>
            </div>
            <DefaultAlert
                open={alertOpen}
                handleClose={() => {
                    // alert 모달 순서 => 시간 경과 alert 창 이후 프로모션 대상자 적용 alert 창 @나디아_07.07
                    closeAlert();
                }}
                contents={alertContents}
                //contents={"골프장 반경 1km 이내일 때 진행 가능합니다. 골프장에 도착할 때 즈음 다시 시도해보세요."}
            />
            <DefaultAlert
                open={promotionAlertOpen}
                handleClose={() => {
                    setPromotionAlertOpen(false);
                }}
                contents={
                    '셀프체크인 이벤트 대상자입니다.\n 정산 시, 프론트에서\n 그린피 1만원 할인 적용됩니다.\n 동반자 초대 시, 팀당 최대 4만원까지 할인!'
                }
            />
        </div>
    );
};

export default CheckInAfter;
