import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {smartRoundActions} from '_store/smartRound.slice';
import {kakaoNotInstallActions} from '_store/kakaoNotInstall.slice';
import {SUCCESS_CODE} from 'common/constants';
import {installLink} from 'common/links';
import {Modal} from '@mui/material';
import PartnerItem from 'components/smart-round/PartnerItem';
import PartnerInviteIconList from 'components/common/PartnerInviteIconList';
import PartnerTooltip from 'components/tooltip/PartnerTooltip';
import PartnerDeleteAlert from 'components/alert/PartnerDeleteAlert';
import PartnerInviteModal from 'components/common/PartnerInviteModal';
import DefaultAlert from 'components/alert/DefaultAlert';
import CommonAlert from 'components/alert/CommonAlert';
import {fetchWrapper} from '_helpers';
import {viewCompanionInfo} from '_helpers/amplitude/events/view.events';

import './partner.scss';

// 디바이스 구분
let device = '';
const ua = navigator.userAgent.toLowerCase();
if (ua.indexOf('android') > -1) {
    device = 'android';
} else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
    device = 'ios';
}

// 디바이스 초기값 세팅
const redirectStore = () => {
    if (device === 'android') {
        window.location.assign(installLink.KAKAOMAP_ANDROID);
    } else if (device === 'ios') {
        window.location.assign(installLink.KAKAOMAP_IOS);
    }
};

const PartnerV2 = (props) => {
    const dispatch = useDispatch();
    const {bookingId, playerLimit, roundPlayer, golfClubId} = useSelector((x) => x.smartRound);
    const round = useSelector((x) => x?.smartRound.round);
    const bookingMember = useSelector((x) => x?.smartRound.bookingMemberList);
    const user = useSelector((x) => x?.smartRound.user);
    const alertData = useSelector((x) => x?.alert);
    const kakaoNotInstall = useSelector((x) => x?.kakaoNotInstall?.open);

    const [editable, setEditable] = useState(false);
    const [nonMember, setNonMember] = useState(false);

    // 동반자 삭제 안내 Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedName, setSelectedName] = useState(null);

    const handleAlertOpen = (e) => {
        const name = e.target.dataset.name;
        setSelectedName(name);
        setAlertOpen(true); // 동반자 삭제 임시 중단 해제
    };

    const showIcon = useCallback(() => {
        // 현재시간과 티타임 시간 비교
        const bookingTime = new Date(round?.roundAt).getTime(); // 예약날짜
        const todayTime = new Date().getTime(); // 오늘날짜
        return bookingTime > todayTime;
    }, [round]);

    // 동반자 삭제 오류 Alert
    const [alertContents, setAlertContents] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false);

    const setMemberReset = () => {
        dispatch(smartRoundActions.getBookingMemberList(bookingId));
    };

    // 동반자 초대방식 선택 팝업
    const [InviteOpen, setInviteOpen] = useState(false);
    const handleInviteOpen = () => setInviteOpen(true);
    const handleInviteClose = (isInvite) => {
        setInviteOpen(false);
        // 멤버 추가했을때 멤버 정보리셋
        isInvite && setMemberReset();
    };

    // 새로고침 rotate 이벤트 임시 처리
    const [isRefreshing, setIsRefreshing] = useState(false);

    const setBookingMember = () => {
        setMemberReset();

        // 새로고침 rotate 이벤트 임시 처리
        setIsRefreshing(true);
        setTimeout(() => setIsRefreshing(false), 1000);
    };

    // ============== 동반자 삭제 ===============
    const [memberId, setMemberId] = useState(null);

    const onDeleteMember = useCallback(() => {
        if (memberId) {
            fetchWrapper.delete(`/booking/invite/${bookingId}/${memberId}`).then((res) => {
                if (res.code === SUCCESS_CODE) {
                    // 리렌더링
                    setAlertOpen(false);
                } else {
                    // 실패 시 얼럿
                    setAlertOpen(false);
                    setDeleteOpen(true);
                    setAlertContents(res.message ? res.message : '시스템 오류로 인해 정상적으로 처리되지 않았습니다.');
                }
                setMemberReset();
            });
        }
    }, [memberId]);

    // memberId 세팅
    const setDeleteMemberId = (id, golagaMember) => {
        setMemberId(id);

        if (golagaMember === false) {
            setNonMember(true);
        } else {
            setNonMember(false);
        }
    };

    // 초대 확인 Alert 확인 클릭 시 멤버 리셋
    const goSetting = useCallback(async () => {
        smartRoundActions.getBookingMemberList(bookingId); // bookingMember 재렌더링
    }, []);

    // 카톡 미 설치 시 Alert
    const [resFailAlert, setResFailAlert] = useState(false);
    const resFailAlertOpen = () => setResFailAlert(true);
    const resFailAlertClose = () => {
        setResFailAlert(false);
        dispatch(
            kakaoNotInstallActions.openAlert({
                open: false
            })
        );
    };
    const onCloseAlert = () => {
        handleInviteOpen();
        resFailAlertClose();
    };

    useEffect(() => {
        if (kakaoNotInstall?.open) {
            resFailAlertOpen();
        }
    }, [kakaoNotInstall]);

    // Amplitude Event
    useEffect(() => {
        if (roundPlayer) {
            viewCompanionInfo(roundPlayer);
        }

        // host 편집권한 부여
        if (user && user?.host) {
            setEditable(true);
        }
    }, []);

    // 티타임 시간 지났을 때 member list 1명인 경우 발생 > 툴팁 아래로 위치 변경
    // const bookingTime = new Date(round?.roundAt).getTime(); // 예약날짜
    // const todayTime = new Date().getTime(); // 오늘날짜
    // let timeOver = bookingTime < todayTime;
    // const [toolTipPlacement, setToolTipPlacement] = useState('');

    // useEffect(() => {
    //     if (timeOver && bookingMember?.length === 1) {
    //         setToolTipPlacement('bottom');
    //     } else if (!timeOver) {
    //         setToolTipPlacement('top');
    //     }
    // }, [bookingMember, timeOver]);

    return (
        <div>
            <div className="companion-wrapper">
                <div className="title">
                    <h2>동반자 정보 현황</h2>
                    <div className="refresh-area">
                        {/* <span className='time'>5분 전</span> */}
                        <button onClick={setBookingMember} className={`btnRefresh ${isRefreshing ? 'rotate' : ''}`}>
                            Refresh
                        </button>
                    </div>
                </div>
                <div className="partner-info-wrapper">
                    <ul className="list-container">
                        {/* user가 host(true)일 때만 delete & btnAdd 노출 */}
                        {bookingMember?.length
                            ? bookingMember.map((data, idx) => {
                                  return (
                                      <PartnerItem
                                          key={data.id}
                                          data={data}
                                          editable={editable}
                                          handleAlertOpen={handleAlertOpen}
                                          setDeleteMemberId={setDeleteMemberId}
                                          showIcon={showIcon}
                                      />
                                  );
                              })
                            : ''}
                        {/* 동반자 초대하기 */}
                        {editable && playerLimit > roundPlayer && showIcon() && (
                            <PartnerInviteIconList
                                inviteIconNum={playerLimit - roundPlayer}
                                handleInviteOpen={handleInviteOpen}
                                smroundInvitation={true}
                            />
                        )}
                    </ul>
                    <div className="bookingAlert">
                        <div className="tt">예약 양도 및 취소 안내</div>
                        <ul className="listType01">
                            <li>예약자 본인 미내장 및 예약 양도는 당사 또는 골프장을 통해 예약 정지가 부과됩니다.</li>
                            <li>
                                취소 불가 기간 내 코로나 및 기타 질병으로 인한 취소 요청 시 반드시 인증이 가능한 증빙
                                자료를 제출하여야 합니다.
                            </li>
                            <li>인증이 어려운 경우 정상 취소가 불가할 수 있습니다.</li>
                        </ul>
                        {/* <PartnerTooltip
                            placement={toolTipPlacement}
                            checkInData={round}
                            bookingData={{
                                id: bookingId
                            }}
                        /> */}
                    </div>
                </div>
            </div>

            {/* 동반자 삭제 안내 Alert */}
            <PartnerDeleteAlert
                open={alertOpen}
                handleClose={() => setAlertOpen(false)}
                name={selectedName}
                onDeleteMember={onDeleteMember}
                nonMember={nonMember}
            />

            {/* 동반자 초대방식 선택 팝업  */}
            <PartnerInviteModal
                InviteOpen={InviteOpen}
                handleInviteClose={handleInviteClose}
                setInviteOpen={setInviteOpen}
                bookingData={{
                    roundAt: round?.roundAt,
                    roundPlayer: roundPlayer,
                    id: bookingId,
                    golfClubId: golfClubId,
                    courseName: round?.course
                }}
                setMemberReset={setMemberReset}
                resFailAlertOpen={resFailAlertOpen}
            />

            {/* 동반자 삭제 오류 Alert */}
            <DefaultAlert
                open={deleteOpen}
                handleClose={() => {
                    setDeleteOpen(false);
                }}
                title={'시스템 장애 발생'}
                contents={alertContents}
            />

            {/* 카카오톡 초대완료 후 확인 Alert */}
            {(alertData?.open && alertData?.id === 'partnerInvite' && (
                <CommonAlert id={alertData?.id} successCallBack={goSetting} kakaoNotInstall={true} />
            )) ||
                ''}

            {/* 카카오톡 미설치 시 확인 Alert */}
            <Modal open={resFailAlert} onClose={resFailAlertClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">서비스 안내</div>
                        <div className="alertContents">
                            카카오톡 앱이 설치되어 있지 않습니다.
                            <br /> 앱을 설치하시겠습니까?
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" className="button cancel" onClick={onCloseAlert}>
                            취소
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                resFailAlertClose();
                                // 카카오톡 설치 페이지로 이동
                                redirectStore();
                            }}
                        >
                            확인
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PartnerV2;
