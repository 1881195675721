import React from 'react';
import Modal from '@mui/material/Modal';
import {formatTodayDateToIsoString} from 'common/notWatchingTodayUtils';
import {localStorageUtil} from '../../common/utils';
import {clickDisagreeOtherBookingBtn} from '_helpers/amplitude/events/view.events';

function ConfirmAgreeModal({open, handleClose, handleAgreementReOpen, handleConfirmAgreeModalClose}) {
    const moveSuccess = (event) => {
        handleAgreementReOpen();
        handleClose();
    };

    const modalClose = () => {
        // sessionStorage 체크값 삭제
        removeCheck();

        handleClose();
        clickDisagreeOtherBookingBtn();
        handleConfirmAgreeModalClose();
    };

    const getLocalstorage = localStorageUtil.get('get_my_reserve_drawer');

    // todo '오늘 하루 보지 않기' 로컬스토리지 세팅
    const setPopUpHide = () => {
        if (!getLocalstorage || (getLocalstorage && getLocalstorage !== formatTodayDateToIsoString))
            localStorageUtil.set('get_my_reserve_drawer', formatTodayDateToIsoString);

        modalClose();
    };

    // sessionStorage 체크값 삭제
    const removeCheck = () => {
        sessionStorage.removeItem('agreementCheck');
    };

    return (
        <>
            {/* 예약정보 불러오기 미동의 시 다시 확인 Alert */}
            <Modal open={open} onClose={modalClose}>
                <div className=" confirmAgreeModal">
                    <div type="button" className="popUpHide" onClick={setPopUpHide}>
                        <span className="btn_ar"></span> 오늘 하루 보지 않기
                    </div>
                    <div id="alertConWrap">
                        예약정보 불러오기 미 동의 시,
                        <br />
                        일부 셀프체크인, 동반자초대 서비스 이용에
                        <br />
                        제한이 있습니다. 더 많은 골프장의
                        <br />
                        스마트한 골라가 서비스를 이용해 보세요!
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={modalClose}>
                            다음에 하기
                        </button>
                        <button type="button" onClick={moveSuccess}>
                            예약정보 불러오기
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ConfirmAgreeModal;
