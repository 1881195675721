import React, {useCallback, useEffect} from 'react';
import ChannelTalk from '../common/ChannelTalk';
import {fetchWrapper, history} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import {useSelector} from 'react-redux';
const CS = () => {
    const member = useSelector((x) => x?.users?.users);
    const getBookingList = useCallback(() => {
        fetchWrapper.get(`/booking/list/pre`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                const bookingList = response.data.lists;
                let bookingStrList = [];
                if (bookingList && bookingList.length > 0) {
                    for (let i = 0; i < bookingList.length; i++) {
                        const bookingInfo = bookingList[i];

                        bookingStrList.push(
                            `예약번호:${bookingInfo.bookingNo}, ` +
                                `골프클럽:${bookingInfo.golfClubName}, ` +
                                `티타임:${bookingInfo.roundAt}`
                        );
                    }
                }

                ChannelTalk.updateUser({
                    profile: {
                        name: member?.name,
                        email: member?.email,
                        mobileNumber: member?.cellPhone,
                        gender: member?.gender,
                        bookingList: bookingStrList
                    }
                });

                ChannelTalk.showMessenger();
            }
        });
    }, []);

    useEffect(() => {
        ChannelTalk.init();

        ChannelTalk.boot({
            pluginKey: process.env.REACT_APP_CHANNELTALK_PLUGIN_KEY,
            mobileMessengerMode: 'iframe',
            memberId: member?.email,
            hideChannelButtonOnBoot: true
        });

        ChannelTalk.onHideMessenger(() => history.navigate('/mypage'));

        getBookingList();

        return () => {
            ChannelTalk.hideChannelButton();
            ChannelTalk.destroy();
        };
    }, []);
    return <></>;
};

export default CS;
