import React, {useEffect, useState} from 'react';
import Back from './button/Back';
import Home from './button/Home';
import Alarm from './button/Alarm';
import Notice from './button/Notice';
import Close from './button/Close';
import {history} from '../_helpers';

function Header({
    back,
    backLink,
    home,
    alarm,
    notice,
    close,
    closeLink,
    closeWindow,
    fixed,
    noticeAlert,
    gfClubId,
    pageTitle,
    border
}) {
    // Header 스크롤시 border 생기게
    const [scrollActive, setScrollActive] = useState(false);
    const [headerFixed, setHeaderFixed] = useState(true);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrollActive(true);
        } else {
            setScrollActive(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const locationLink = () => history.navigate(closeLink);
    const windowClose = () => window.close();

    useEffect(() => {
        if (fixed === false) {
            setHeaderFixed(false);
        } else {
            setHeaderFixed(true);
        }
    }, []);

    const headerClassName = () => {
        if (headerFixed) {
            if (scrollActive) {
                return 'fixed';
            } else if (border) {
                return 'borderHeader';
            }
        } else {
            return 'absolute';
        }
    };

    return (
        <>
            <div id="header" className={headerClassName()}>
                <div className="inner headerWrap">
                    <div className="leftCon">
                        {back ? <Back backLink={backLink} /> : null}
                        {home ? <Home /> : null}
                        {pageTitle ? <h1 className="pageTitle">{pageTitle}</h1> : null}
                    </div>
                    <div className="rightCon">
                        {alarm ? <Alarm /> : null}
                        {notice ? <Notice gfClubId={gfClubId} noticeAlert={noticeAlert} /> : null}
                        {close ? <Close onClick={closeLink ? locationLink : closeWindow ? windowClose : null} /> : null}
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(Header);
