import {createSlice} from '@reduxjs/toolkit';
import {localStorageUtil} from '../common/utils';

const name = 'app';
const initialState = {
    reqNum: '',
    isLoading: false
};

const appSlice = createSlice({
    name,
    initialState,
    reducers: {
        setReqNum: {
            reducer: (state, action) => {
                const name = 'indentification-reqNum';
                if (action?.payload?.length) {
                    state.reqNum = action.payload;
                    localStorageUtil.set(name, action.payload);
                } else {
                    localStorageUtil.remove(name);
                }
            }
        },
        setLoading: {
            reducer: (state, action) => {
                state.isLoading = action.payload;
            }
        }
    }
});

export const appActions = {...appSlice.actions};
export const appReducer = appSlice.reducer;
