import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {FiX} from 'react-icons/fi';
import {GrNext} from 'react-icons/gr';
import styled, {keyframes, css} from 'styled-components';
import {appUtils} from '../../../common/utils';
import {useDispatch} from 'react-redux';
import {appActions} from '../../../_store/app.slice';
import {checkinActions} from '../../../_store/checkin.slice';

const fadeIn = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1
    }
    to {
        opacity: 0
    }
`;

const slideInUp = keyframes`
    from {
        transform: translate3d(0, 8%, 0);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    to {
        transform: translate3d(0, 0, 0);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
`;

const slideInDown = keyframes`
    from {
        transform: translate3d(0, -30%, 0);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    to {
        transform: translate3d(0, 0, 0);
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
`;

const slideOutUp = keyframes`
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, -30%, 0);
    }
`;

const slideOutDown = keyframes`
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 10%, 0);
    }
`;

const Modal = styled.div`
    position: fixed;
    width: calc(100% - 1.5rem);
    left: 0.75rem;
    top: 1.25rem;
    animation: ${fadeIn} 0.3s;
    animation-fill-mode: forwards;
    z-index: 1000;

    ${(props) =>
        props.disappear &&
        css`
            animation-name: ${fadeOut};
        `}
`;

const Popup = styled.div`
    border: 1px solid #ebebeb;
    border-radius: 0.5rem;
    background: #fff;
    animation: ${slideInDown} 0.3s 50ms;
    animation-fill-mode: both;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);

    ${(props) =>
        props.disappear &&
        css`
            animation: ${slideOutUp} 0.3s;
        `}
`;

const PopupContents = styled.div`
    z-index: 1;
    position: relative;
    padding: 1.25rem 3.125rem 1.25rem 1rem;

    .tit {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 1.0625rem;
        font-weight: 700;
        word-break: keep-all;
    }

    p {
        font-size: 0.875rem;
        color: #848588;
        line-height: 1.25rem;
        word-break: keep-all;
    }

    .modalClose {
        position: absolute;
        top: 1.125rem;
        right: 0.875rem;

        span {
            display: none;
        }
    }
`;

const BtnContents = styled.div`
    border-top: 1px solid #ebebeb;
    font-size: 0.875rem;

    a {
        position: relative;
        display: flex;
        width: 100%;
        padding: 1rem 2.8125rem 1rem 1.25rem;

        .place {
            max-width: 50%;
            flex-shrink: 0;
            font-weight: 700;
            color: #3906c3;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .time {
            position: relative;
            padding-left: 0.4375rem;
            margin-left: 0.375rem;
            color: #848588;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 0.75rem;
                background: #848588;
                opacity: 0.3;
            }
        }

        .btnMore {
            position: absolute;
            top: 50%;
            right: 1.25rem;
            transform: translateY(-50%);

            polyline {
                stroke: #848588;
            }
        }
    }
`;

function AlarmLayer({visible, onCancel, bookingAlarm}) {
    const dispatch = useDispatch();
    const [animate, setAnimate] = useState(false);
    const [userVisible, setUserVisible] = useState(visible);

    useEffect(() => {
        if (userVisible && !visible) {
            setAnimate(true);
        }
        setUserVisible(visible);
    }, [userVisible, visible]);

    if (!animate && !userVisible) return null;

    return (
        <Modal disappear={!visible} onAnimationEnd={() => setAnimate(false)}>
            <Popup disappear={!visible}>
                <PopupContents disappear={!visible}>
                    <strong className="tit">
                        {bookingAlarm.isToday === true ? '오늘' : '내일'} 골프 라운드가 예약되어 있네요!
                    </strong>
                    <p>가기 전 스마트 라운드로 예약된 골프장을 미리 알아보고 가세요!</p>
                    <button className="modalClose" onClick={onCancel}>
                        <span>팝업 닫기</span>
                        <FiX size={'1.3125rem'} color={'999'} />
                    </button>
                </PopupContents>
                <BtnContents>
                    <Link
                        to={`/self-check-in-v2?bookingId=${bookingAlarm?.bookingId}&golfClubId=${bookingAlarm?.golfClubId}`}
                    >
                        <strong className="place">{bookingAlarm.golfClubName}</strong>
                        <span className="time">{appUtils.dateFormatTypeRoundAt(bookingAlarm.roundAt)}</span>
                        <GrNext size={'0.8125rem'} className="btnMore" />
                    </Link>
                </BtnContents>
            </Popup>
        </Modal>
    );
}

export default AlarmLayer;
