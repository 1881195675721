import Drawer from '@mui/material/Drawer';
import Icon from '../icons/Icon';
import React, {useState} from 'react';

function SelectDrawer({label, items, onSelected, isOpen, onClickClose, selected}) {
    return (
        <Drawer anchor="bottom" open={isOpen} onClose={onClickClose} className="defaultDrawer">
            <div className="drawer-content">
                <div className="drawerTitle">
                    <div className="tt">{label}</div>
                    <button type="button" className="btnClose" onClick={onClickClose}>
                        <Icon name="close02" width="24" height="24" viewBox="0 0 24 24" />
                    </button>
                </div>
                <div className="drawerContents">
                    <ul className="selectList">
                        {items.map((item, key) => {
                            return (
                                <li key={key}>
                                    <button
                                        type="button"
                                        value={item.value}
                                        className={selected === item.value ? 'on' : ''}
                                        onClick={onSelected}
                                    >
                                        {item.name}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Drawer>
    );
}

export default SelectDrawer;
