// ref: https://www.notion.so/greenit-co/afcd52393fb44f3f89e33c03ccc245f3?v=47fb401b41dc4b67a9f6ed8d0682c9fd&p=c8999e5313504283a981b3808929bfb6&pm=s
import {sendAmplitudeEvent} from '../amplitude.helper';
import {viewTaxonomy} from '../taxonomy/view.taxonomy';

/**
 * view intro page. 앱 처음으로 열 때.
 */
export const viewIntroPage = () => {
    sendAmplitudeEvent(viewTaxonomy.view_intro_page);
};

/**
 * 메인 페이지 진입 이벤트
 */
export const viewHome = () => {
    sendAmplitudeEvent(viewTaxonomy.view_home);
};

/**
 * 이메일 로그인 페이지 노출 시 전송
 */
export const viewEmaiLoginPage = () => {
    sendAmplitudeEvent(viewTaxonomy.view_email_login_page);
};

/**
 * view_cc_detail - 골프장 상세
 * @param {*} data
 * @param {*} root
 */
export const viewCcDetail = (data, root) => {
    if (!root) root = 'home';
    const eventData = {
        root: root,
        cc_name: data?.golfClubName,
        address: data?.addressLocal,
        partner_cc_yn: data?.isPartner,
        cc_sort: data?.businessType,
        hole_count: data?.hole,
        cc_id: data?.id
    };
    sendAmplitudeEvent(viewTaxonomy.view_cc_detail, eventData);
};

/**
 * 즐겨찾기 골프장 페이지 보기
 * @param {[golfClub Like list]} data
 */
export const viewFavorite = (data) => {
    sendAmplitudeEvent(viewTaxonomy.view_favorite, data);
};

/**
 * 검색>즐겨찾는 골프장 탭 보기
 */
export const viewSearchFavoriteTab = () => {
    sendAmplitudeEvent(viewTaxonomy.view_search_favorite_tab);
};

/**
 * 검색>인기 검색 골프장 탭 보기
 */
export const viewSearchPopularTab = () => {
    sendAmplitudeEvent(viewTaxonomy.view_search_favorite_tab);
};

export const viewSearchResult = (resultCount) => {
    sendAmplitudeEvent(viewTaxonomy.view_search_result, {results_count: resultCount});
};

/**
 * view_reservation_details - 예약내역 페이지 보기 이벤트
 * @param {string} tab 예정라운드, 지난라운드, 취소라운드
 * @param {int} count 노출 리스트 item(예약) 수}
 */
export const viewReservationDetails = (tab, count) => {
    let tabStr = '';
    if (tab === 'pre') {
        tabStr = '예정라운드';
    } else if (tab === 'post') {
        tabStr = '지난라운드';
    } else if (tab === 'cancel') {
        tabStr = '취소라운드';
    }

    sendAmplitudeEvent(viewTaxonomy.view_reservation_details, {tab: tabStr, count: count});
};

/**
 * view_intro_courseguide - 코스가이드 tab 첫 화면
 * @param {*} data
 * @param {*} root
 */
export const viewIntroCourseguide = (data, root) => {
    const eventData = {
        root: root,
        today_yn: data?.today_yn,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(viewTaxonomy.view_intro_courseguide, eventData);
};

/**
 * view_courseguide - 맵제네레이터 랜더링시
 * @param {*} data
 * @param {*} root
 */
export const viewCourseguide = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* 추가 */
        course_info: data?.course_info,
        hole_info: data?.hole_info,
        par_info: data?.par_info,
        map_view: data?.map_view,
        distance_view: data?.distance_view,
        tee_ground: data?.tee_ground,
        smartdot_yn: data?.smartdot_yn,
        you_are_hear_yn: data?.you_are_hear_yn
    };

    sendAmplitudeEvent(viewTaxonomy.view_courseguide, eventData);
};

/**
 * view_tab_cc_detail - 티타임 & 골프장 소개 탭
 * @param {string} tab 탭
 */
export const viewTabCCDetail = (tab) => {
    sendAmplitudeEvent(viewTaxonomy.view_tab_cc_detail, {tab: tab});
};

/**
 * view_smartround_list - 스마트 라운드 리스트
 * @param {integer} Count 리스트 item 갯수
 */
export const viewSmartRoundList = (count) => {
    sendAmplitudeEvent(viewTaxonomy.view_smartround_list, {count: count});
};

/**
 * view-cc-notice 셀프체크인 우측 상단 종모양 눌렀을때
 * @param {*} data
 * */
export const viewCCNotice = (data, root) => {
    const eventData = {
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(viewTaxonomy.view_cc_notice, eventData);
};

/**
 * view_food_menu - 음식 메뉴 화면
 * @param {*} data
 * @param {*} root
 * */
export const viewFoodMenu = (data, root) => {
    const eventData = {
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };

    sendAmplitudeEvent(viewTaxonomy.view_food_menu, eventData);
};

/**
 * view_signup_email_step1 - 이메일 회원가입시 본인인증 페이지
 *
 * */

export const viewSignupEmailStep1 = () => {
    sendAmplitudeEvent(viewTaxonomy.view_signup_email_step1);
};

/**
 * view_signup_email_step2 - 이메일 회원가입시 id-pwd 설정 페이지
 *
 **/

export const viewSignupEmailStep2 = () => {
    sendAmplitudeEvent(viewTaxonomy.view_signup_email_step2);
};

/**
 * view_signup_email_step2 - 애플 회원가입시 본인인증 페이지
 *
 **/
export const viewSignupAppleStep1 = () => {
    sendAmplitudeEvent(viewTaxonomy.view_signup_apple_step1);
};

/**
 *
 * view_signup_apple_step2 - 애플 회원가입시 id확인 및 약관동의 페이지
 *
 * */
export const viewSignupAppleStep2 = () => {
    sendAmplitudeEvent(viewTaxonomy.view_signup_apple_step2);
};

/**
 * view_cc_cancel_policy - 위약규정 클릭
 * @param {*} data
 * @param {*} root
 * */
export const viewCcCancelPolicy = (data, root) => {
    const eventData = {
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id
    };
    sendAmplitudeEvent(viewTaxonomy.view_cc_cancel_policy, eventData);
};

/**
 * view_myinfo - 내정보 페이지 보는 이벤트
 *
 * */
export const viewMyinfo = () => {
    sendAmplitudeEvent(viewTaxonomy.view_myinfo);
};

/**
 * view_change_myinfo - 내정보 변경 이벤트
 *
 * */
export const viewChangeMyinfo = () => {
    sendAmplitudeEvent(viewTaxonomy.view_change_myinfo);
};

/**
 * view_change_mobile_num - 핸드폰번호 변경
 *
 * */

export const viewChangeMobileNum = () => {
    sendAmplitudeEvent(viewTaxonomy.view_change_mobile_num);
};

/**
 * view_withdrawal - 탈퇴하기 보는 이벤트
 *
 * */
export const viewWithdrawal = () => {
    sendAmplitudeEvent(viewTaxonomy.view_withdrawal);
};

/**
 * view_reservation - 예약하기 화면
 * @param {*} data
 * @param {string} root
 * */
export const viewReservation = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,

        day_type: data?.day_type,
        buffer_period: data?.buffer_period,
        day_of_the_week: data?.day_of_the_week
    };

    sendAmplitudeEvent(viewTaxonomy.view_reservation, eventData);
};

/**
 * view-gps-self-checkin
 * @param {*} data
 * */
export const viewGpsSelfCheckIn = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        //추가
        tee_time_left_hour: data?.tee_time_left_hour,
        reservation_type: data?.reservation_type
    };

    sendAmplitudeEvent(viewTaxonomy.view_gps_self_checkin, eventData);
};

/**
 * view_qr_self_checkin - qr checkin
 * @param {*} data view_gps_self_checkin과 동일
 * */
export const viewQrSelfCheckin = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        //추가
        tee_time_left_hour: data?.tee_time_left_hour,
        reservation_type: data?.reservation_type
    };
    sendAmplitudeEvent(viewTaxonomy.view_qr_self_checkin, eventData);
};

/**
 * view_smartround_video - 스마트라운드 비디오
 * @param {*} data reservation_complete과 동일
 * @param {stirng} root
 * */
export const viewSmartroundVideo = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people
    };

    sendAmplitudeEvent(viewTaxonomy.view_smartround_video, eventData);
};

/**
 * view_modal_cancel - 예약 취소 모달 볼 때
 * @param {*} data reservation_complete과 동일 + panalty_period
 * @param {stirng} root
 * */
export const viewModalCancel = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        //panalty_period 추가
        penalty_period: data?.penalty_period === true ? 'y' : 'n'
    };

    sendAmplitudeEvent(viewTaxonomy.view_modal_cancel, eventData);
};

/**
 * view_cancel_policy
 * @param {*} data view_modal_cancel 동일
 * @param {stirng} root
 * */

export const viewCancelPolicy = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        //panalty_period 추가
        penalty_period: data?.penalty_period === true ? 'y' : 'n'
    };

    sendAmplitudeEvent(viewTaxonomy.view_cancel_policy, eventData);
};

/**
 * view_cancel_reason
 * @param {*} data view_modal_cancel 동일
 * @param {stirng} root
 * */

export const viewCancelReason = (data, root) => {
    const eventData = {
        /* view_cc_detail */
        root: root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        //panalty_period 추가
        penalty_period: data?.penalty_period === true ? 'y' : 'n'
    };

    sendAmplitudeEvent(viewTaxonomy.view_cancel_reason, eventData);
};

/**
 * view_companion_info 동반자 초대
 * @param {number} invitee_people
 * */

export const viewCompanionInfo = (invitee_people) => {
    sendAmplitudeEvent(viewTaxonomy.view_companion_info, {invitee_people: invitee_people});
};

/**
 * view_self_checkIn - 셀프 체크인 화면
 *  @param {*} data reservation_complete와 동일
 *  @param {string} root
 * */

export const viewSelfCheckin = (data, root) => {
    const startPoint = sessionStorage.getItem('reservation_start_point');
    const eventData = {
        /* view_cc_detail */
        root: startPoint ? startPoint : root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,
        people: data?.people,

        day_type: data?.day_type,
        buffer_period: data?.buffer_period,
        day_of_the_week: data?.day_of_the_week,

        /* 추가 */
        reservation_type: data?.reservation_type,
        tee_time_left_hour: data?.tee_time_left_hour,
        complete_checkin_yn: data?.complete_checkin_yn
    };

    sendAmplitudeEvent(viewTaxonomy.view_self_checkin, eventData);
};

/**
 * view_notice - 공지사항
 *
 * */
export const viewNotice = () => {
    sendAmplitudeEvent(viewTaxonomy.view_notice);
};

/**
 * view_policy
 *
 * */
export const viewPolicy = () => {
    sendAmplitudeEvent(viewTaxonomy.view_policy);
};

/**
 * view_withdrawal_success - 회원 탈퇴 성공적으로 완료 시
 *
 * */
export const viewWithdrawalSuccess = () => {
    sendAmplitudeEvent(viewTaxonomy.view_withdrawal_success);
};

/**
 * view_home_bottom_modal - '예약정보 불러오기' 메인 드로어 오픈 시
 *
 * */
export const viewHomeBottomModal = (title) => {
    const eventData = {
        title: title
    };
    sendAmplitudeEvent(viewTaxonomy.view_home_bottom_modal, eventData);
};

/**
 * view_other_booking_details - '예약정보 불러오기' 상세페이지 진입 시
 * @param {*} root
 * */
export const viewOtherBookingDetails = (root) => {
    const eventData = {
        root: root
    };

    sendAmplitudeEvent(viewTaxonomy.view_other_booking_details, eventData);
};

/**
 * view_other_booking_cc_list - '예약정보 불러오기' 상세페이지에서 <제휴골프장 보기> 클릭 시
 * */
export const viewOtherBookingCcList = () => {
    sendAmplitudeEvent(viewTaxonomy.view_other_booking_cc_list);
};

/**
 * click_other_booking_connect_btn - '예약정보 불러오기' 상세페이지에서 <예약정보 불러오기> 클릭 시
 * */
export const clickOtherBookingConnectBtn = () => {
    sendAmplitudeEvent(viewTaxonomy.click_other_booking_connect_btn);
};

/**
 * view_other_booking_agree_modal - '예약정보 불러오기' 상세페이지에서 <예약정보 불러오기> 모달 오픈 시
 * */
export const viewOtherBookingAgreeModal = () => {
    sendAmplitudeEvent(viewTaxonomy.view_other_booking_agree_modal);
};

/**
 * view_other_booking_policy - '예약정보 불러오기' 예약정보 조회전송 약관 풀팝업 오픈 시
 * */
export const viewOtherBookingPolicy = () => {
    sendAmplitudeEvent(viewTaxonomy.view_other_booking_policy);
};

/**
 * connect_other_booking_complete - '예약정보 불러오기' 예약정보 API 연동 완료 시
 * @param {*} data
 * @param {string} root
 * */
export const connectOtherBookingComplete = (data, root) => {
    const eventData = {
        root: root,

        agree_type: data?.agree_type
    };
    sendAmplitudeEvent(viewTaxonomy.connect_other_booking_complete, eventData);
};

/**
 * view_popup_other_booking_ask - 홈 진입시 예약정보 불러오기 검은모달 봤을 때
 * */
export const viewPopupOtherBookingAsk = () => {
    sendAmplitudeEvent(viewTaxonomy.view_popup_other_booking_ask);
};

/**
 * view_popup_other_booking_cancel_confirm - x 눌러서 나오는 팝업 봤을 때
 * */
export const viewPopupOtherBookingCancelConfirm = () => {
    sendAmplitudeEvent(viewTaxonomy.view_popup_other_booking_cancel_confirm);
};
/**
 * click_disagree_other_booking_btn - x 눌러서 나오는 팝업에서 '다음에 하기' 클릭 했을 때,
 * 배경 눌러서 팝업 닫을 때
 * */
export const clickDisagreeOtherBookingBtn = () => {
    sendAmplitudeEvent(viewTaxonomy.click_disagree_other_booking_btn);
};

/** ====================선결제 관련 추가 ================= */

/**
 * view_reservation_payment_fail - 예약 결제 실패
 * @param {*} data click_reservation_btn과 동일 + reservation_payment_fail_reason (PG 결제 실패코드 + 응답 메세지)
 * click_reservation_btn 은 예약 성공 후 생성된 데이터를 기반으로 사용가능하므로 해당 케이스는 사용불가능
 * view_reservation + click_date 를 조회 후 프론트에서 포맷해서 전달
 */

export const viewReservationPaymentFail = (data, root) => {
    const startPoint = sessionStorage.getItem('reservation_start_point');
    const eventData = {
        /* view_cc_detail */
        root: startPoint ? startPoint : root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,

        /* click_reservation_btn */
        people: data?.people,
        total_prepayment_amount: data?.total_prepayment_amount,
        total_green_fee: data?.total_green_fee,
        cart_fee: data?.cart_fee,
        total_fieldpayment_amount: data?.total_fieldpayment_amount,
        caddie_fee: data?.caddie_fee,
        payment_method: data?.payment_method,

        buffer_period: data?.buffer_period,
        day_of_the_week: data?.day_of_the_week,
        day_type: data?.day_type,

        /* view_reservation_payment_fail */
        reservation_payment_fail_reason: data?.reservation_payment_fail_reason
    };

    console.log('viewReservationPaymentFail ::', eventData);

    sendAmplitudeEvent(viewTaxonomy.view_reservation_payment_fail, eventData);
};

/**
 * view_reservation_fail - 예약 실패 (CMS유효성체크)
 * @param {*} data click_reservation_btn과 동일 + reservation_fail_reason (CMS 결제 실패 응답 메세지)
 * click_reservation_btn 은 예약 성공 후 생성된 데이터를 기반으로 사용가능하므로 해당 케이스는 사용불가능
 * view_reservation + click_date 를 조회 후 프론트에서 포맷해서 전달
 */

export const viewReservationFail = (data, root) => {
    const startPoint = sessionStorage.getItem('reservation_start_point');
    const eventData = {
        /* view_cc_detail */
        root: startPoint ? startPoint : root,
        cc_name: data?.cc_name,
        address: data?.address,
        partner_cc_yn: data?.partner_cc_yn,
        cms_type: data?.cms_type,
        cc_sort: data?.cc_sort,
        hole_count: data?.hole_count,
        cc_id: data?.cc_id,

        /* view_reservation */
        conditions: data?.conditions,
        caddytype: data?.caddytype,
        signup_duty_cc_website_yn: data?.signup_duty_cc_website_yn,
        min_customer: data?.min_customer,
        reservation_date: data?.reservation_date,
        teetime: data?.teetime,
        course_name: data?.course_name,
        hole_info: data?.hole_info,
        checkin_condition: data?.checkin_condition,
        available_cancel_date_left: data?.available_cancel_date_left,
        green_fee: data?.green_fee,
        pay_condition: data?.pay_condition,

        /* click_reservation_btn */
        people: data?.people,
        total_prepayment_amount: data?.total_prepayment_amount,
        total_green_fee: data?.total_green_fee,
        cart_fee: data?.cart_fee,
        total_fieldpayment_amount: data?.total_fieldpayment_amount,
        caddie_fee: data?.caddie_fee,
        payment_method: data?.payment_method,

        buffer_period: data?.buffer_period,
        day_of_the_week: data?.day_of_the_week,
        day_type: data?.day_type,

        /* view_reservation_fail */
        reservation_fail_reason: data?.reservation_fail_reason
    };

    console.log('view_reservation_fail:: ', eventData);

    sendAmplitudeEvent(viewTaxonomy.view_reservation_fail, eventData);
};

/**
 * view_cancel_fail_alert - 예약취소 실패 (CMS유효성체크) alert
 * @param {*} data click_cancel_policy 동일 + fail_reason (CMS 취소 실패 응답 메세지)
 *
 *
 */

export const viewCancelFailAlert = (data, root, fail_reason) => {
    const startPoint = sessionStorage.getItem('reservation_start_point');
    /* ============== click_reservation_btn + fail_reason (CMS 취소 실패 응답 메세지)============= */

    data.root = startPoint ? startPoint : root;
    data.payment_method = data?.pay_condition === '현장결제' ? '' : '신용카드';
    data.cancel_payment_fail_reason = fail_reason;

    sendAmplitudeEvent(viewTaxonomy.view_cancel_fail_alert, data);
};

/**
 * view_cancel_payment_fail - 예약취소 결제(취소) 실패
 * @param {*} data click_cancel_policy(click_reservation_btn) 동일 + cancel_payment_fail_reason (PG 결제취소 실패코드 + 응답 메세지)
 *
 *
 */

export const viewCancelPaymentFail = (data, root, cancel_payment_fail_reason = '') => {
    const startPoint = sessionStorage.getItem('reservation_start_point');

    data.root = startPoint ? startPoint : root;
    data.payment_method = data?.pay_condition === '현장결제' ? '' : '신용카드';
    //cancel_payment_fail_reason 추가
    data.cancel_payment_fail_reason = cancel_payment_fail_reason;

    sendAmplitudeEvent(viewTaxonomy.view_cancel_payment_fail, data);
};
