import React, {useCallback, useEffect, useState} from 'react';
import Agreement from './Agreement';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import './membership.scss';
import {errMsg, pageTitle, successMsg} from '../../common/messages';
import {appUtils, localStorageUtil} from '../../common/utils';
import {fetchWrapper, history} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import DefaultAlert from '../alert/DefaultAlert';
import {useLocation} from 'react-router-dom';
import {authActions, store, userActions} from '../../_store';
import {view_signup_apple_step2, viewSignupAppleStep2} from '../../_helpers/amplitude/events/view.events';

function JoinApple() {
    const location = useLocation();
    const [userMail, setUserMail] = useState(location.state.userMail);

    const {height} = useWindowDimensions();

    const [formValue, setFormValue] = useState({
        userMail: ''
    });
    const [activeButton, setActiveButton] = useState(false);
    const [venderOpen, setVenderOpen] = useState(false);
    const [emailValidation, setEmailValidation] = useState({
        message: '',
        error: false
    });
    const [requireAllCheck, setRequireAllCheck] = useState(false);

    // 마케팅 정보 수신동의 회원가입 시, 키벨류 필요
    const [isMarketingAgree, setIsMarketingAgree] = useState(false);

    const isActive = useCallback(() => {
        let isOk = emailValidation.message && !emailValidation.error;
        isOk = isOk && requireAllCheck;

        setActiveButton(!!isOk);
    }, [emailValidation, requireAllCheck]);

    const [open, setOpen] = useState(false);
    const [alertData, setAlertData] = useState({
        title: '',
        description: ''
    });
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        isActive();
    }, [isActive]);

    useEffect(() => {
        setFormValue({...formValue, userMail: userMail});
        onValidEmail(userMail);
        viewSignupAppleStep2();
    }, []);
    // 회원가입 처리
    const nextPage = () => {
        const reqNum = localStorageUtil.get('indentification-reqNum');
        if (reqNum?.length) {
            fetchWrapper
                .post(`/member/join/apple`, {
                    reqNum,
                    email: formValue.userMail.trim().toLowerCase(),
                    memberChannel: 'APPLE',
                    isMarketingAgree
                })
                .then((response) => {
                    if (response?.code === SUCCESS_CODE) {
                        // 스토어 , 로컬스토리지 토큰 세팅
                        const authToken = response?.data?.authToken;
                        store.dispatch(
                            authActions.setToken({
                                accessToken: authToken.accessToken,
                                refreshToken: authToken.refreshToken
                            })
                        );
                        // 스토어 유저정보 세팅
                        store.dispatch(userActions.getAll());
                        history.navigate('/member/join/complete', {
                            state: {member: response.data}
                        });
                    } else {
                        setAlertData({
                            title: '서비스 안내',
                            message: response?.message
                        });
                        handleOpen();
                    }
                });
        } else {
            //인증오류
            // console.debug("인증오류");
        }
    };

    const clearInputValidation = (name) => {
        if (name === 'userMail') {
            setEmailValidation({error: false, message: ''});
        } else if (name === 'userPassword') {
            // setPasswordMinLength(false);
            // setPasswordIncludeId(false);
            // setPasswordRegYN(false);
        }
    };

    const onBlurEmail = () => {
        appUtils.defaultPadding();
        if (venderOpen) return;
        let value = formValue?.userMail;
        if (!value) {
            clearInputValidation('userMail');
            return;
        }
        onValidEmail(value);
    };

    const onValidEmail = (value) => {
        let lowerMail = value.toLowerCase();
        // 이메일 형식 확인
        if (!appUtils.checkEmailReg(lowerMail)) {
            setEmailValidation({error: true, message: errMsg.USER_ID_REG});
        }
        fetchWrapper.get(`/member/email/${lowerMail}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setEmailValidation({
                    error: false,
                    message: successMsg.USER_ID_CHECK_OK
                });
            } else {
                setEmailValidation({
                    error: true,
                    message: response?.message
                });
            }
        });
    };

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="membership">
                    <div className="inner">
                        <form autoComplete="off">
                            <TitleArea pageTitle={pageTitle.joinApple} />
                            <div className="fieldAreaWrap">
                                {/* 에러 메시지 출력시 fieldArea, textField, infoMsg에 클래스 error 추가. */}

                                <div className="row fieldArea">
                                    <input
                                        {...{
                                            readOnly: true,
                                            type: 'email',
                                            name: 'userMail',
                                            maxLength: 100,
                                            minLength: 6,
                                            value: formValue?.userMail,
                                            placeholder: '이메일 ID',
                                            className: `textField ${emailValidation?.error && 'is-invalid error'}`
                                        }}
                                    />
                                    {emailValidation.message && (
                                        <p className={`infoMsg ${emailValidation.error && 'error'}`}>
                                            {emailValidation.message}
                                        </p>
                                    )}
                                </div>
                                <Agreement
                                    setRequireAllCheck={setRequireAllCheck}
                                    setIsMarketingAgree={setIsMarketingAgree}
                                />
                            </div>
                            <div className="btnFixedBottom">
                                <div className="inner">
                                    <Button
                                        type="button"
                                        disabled={!activeButton}
                                        background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                        onClick={nextPage}
                                    >
                                        회원가입 완료
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <DefaultAlert open={open} handleClose={handleClose} title={alertData.title} contents={alertData.message} />
        </>
    );
}

export default JoinApple;
