import React, { useEffect, useState } from "react";
import Button from "../../components/button/Button";
import ToolTip from "../../components/tooltip/Tooltip";
import { useNavigate } from "react-router-dom";
import { appUtils } from "../../common/utils";
import { history } from "../../_helpers";
import BookingDetail from "../../components/mypage/booking/BookingDetail";

/* 내정보 예약 디테일 - 라운드 예정 */
function CardDetailT2({ bookingData, scheduledStatus, status }) {
    const [detailOpen, setDetailOpen] = useState(false);
    const handleDetailOpen = () => {
        setDetailOpen(true);
    };
    const handleDetailClose = () => {
        setDetailOpen(false);
    };

    return (
        <>
            <div className="totalInfo">
                <dl className="totalPrice">
                    <dt>
                        1인 그린피{" "}
                        <ToolTip
                            placement="top"
                            contents="카트피 및 캐디피 등 부대비용을 포함하지 않은 금액입니다."
                        />
                    </dt>
                    <dd>{appUtils.numberFormat(bookingData.perAmount)}원</dd>
                </dl>
            </div>
            <div className="btnArea">
                <Button
                    type="button"
                    className="medium"
                    onClick={handleDetailOpen}
                >
                    예약 상세 정보
                </Button>
            </div>
            {detailOpen && (
                <BookingDetail
                    detailOpen={detailOpen}
                    detailClose={handleDetailClose}
                    bookingId={bookingData?.id}
                    scheduledStatus={scheduledStatus}
                    status={status}
                />
            )}
        </>
    );
}

export default CardDetailT2;
