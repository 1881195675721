import React, {useState, useRef, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';
import Header from '../../Header';
import Avatar from '../../common/Avatar';
import {fetchWrapper} from '../../../_helpers';
import {appUtils, localStorageUtil} from '../../../common/utils';
import {SUCCESS_CODE} from '../../../common/constants';
import Switch from '@mui/material/Switch';
import DirectionSnackbar from '../../alert/DirectionSnackbar';
import imageCompression from 'browser-image-compression';
import {store, userActions} from '../../../_store';
import Icon from '../../icons/Icon';
import Modal from '@mui/material/Modal';
import {viewChangeMyinfo} from '../../../_helpers/amplitude/events/view.events';
import AgreementDrawer from '../get-my-reservation/AgreementDrawer';

function MyInfoModify() {
    const [member, setMember] = useState(null);
    const [profileImg, setProfileImg] = useState(null);
    const changeDate = appUtils.dateFormatTypeBoard(Date.now());

    // 예약정보 조회,전송 동의 철회 Alert
    const [alertState, setAlertState] = useState(false);
    const onAlertClose = () => {
        setAlertState(false);
    };

    const onAlertOpen = () => {
        setAlertState(true);
    };

    // 동의, 거부 텍스트 변경
    const [agreeTxt, setAgreeTxt] = useState('');
    // 토글 버튼 체크 여부, 체크일시 확인 - 마케팅 수신 동의
    const [agreeChecked, setAgreeChecked] = useState({
        isMarketingAgree: member?.isMarketingAgree || false,
        marketingAgreeDate: member?.marketingAgreeDate || changeDate
    });
    // 토글 버튼 체크 여부, 체크일시 확인 - 예약정보 조회,전송 동의
    const [reservChecked, setReservChecked] = useState({
        isBookingCheckAgree: member?.isBookingCheckAgree || false,
        bookingCheckAgreeDate: member?.bookingCheckAgreeDate || changeDate
    });

    // Drawer 제어
    const [drawerState, setDrawerState] = useState(false);

    // AgreementDrawer 제어
    const [isAgreementCheck, setIsAgreementCheck] = useState(false);

    const onClickClose = () => {
        setDrawerState(false);
    };

    // snackBar
    const [snackBarOpen, setSnackBarOpen] = useState({
        open: false,
        msg: ''
    });
    // 가입경로
    const [joinRoute, setJoinRoute] = useState(null);

    const memberChannel = member?.memberChannel;
    // 프로필 이미지 업로드
    const fileInput = useRef(null);
    // 사진 변경
    const onChange = async (e) => {
        const selectFile = e.target.files[0];
        // 파일 미선택
        if (!selectFile) {
            return;
        }

        try {
            // 압축 옵션
            const options = {maxSizeMB: 1, maxWidthOrHeight: 1920, useWebWorker: true};
            // 이미지 압축
            const compressedFile = await imageCompression(selectFile, options);
            // formData로 전환해야 header 변경 별도로 없이 자동세팅됨
            const formData = new FormData();
            formData.append('profile', compressedFile);

            const res = await fetchWrapper.post(`/member/upload/profile/image`, formData);

            if (res.code === SUCCESS_CODE) {
                const findName = 'profileImageURL';
                // 디비에 멤버가 없다면 스토어내에도 없으므로 같이 추가
                store.dispatch(userActions.setUser({key: findName, value: res.data[findName]}));
                member[findName] = res.data[findName];
                //이미지 등록 성공, 화면에 프로필 사진 표시
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImg(res['data'][findName] + `?timestamp=${new Date().getTime()}`);
                    }
                };
                reader.readAsDataURL(compressedFile);
                profileImgAlertOpen();
            } else {
                console.log('이미지 업로드 중 실패');
            }
        } catch (error) {
            console.log('이미지 압축 업로드 오류: ', error);
        }
    };

    // member api
    const getMemberInfo = useCallback(() => {
        fetchWrapper.get(`/member`, null).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                setMember(response.data || null);
                setAgreeChecked({
                    isMarketingAgree: response.data.isMarketingAgree,
                    marketingAgreeDate: response.data.marketingAgreeDate
                });
                setReservChecked({
                    isBookingCheckAgree: response.data.isBookingCheckAgree,
                    bookingCheckAgreeDate: response.data.bookingCheckAgreeDate
                });
                // setAgreeChecked(rese)
            }
        });
    }, []);

    // 프로필 사진 변경
    const profileImgAlertOpen = () => {
        setSnackBarOpen({
            open: true,
            msg: '프로필 사진 변경이 완료되었습니다.'
        });
    };

    // 마케팅 정보 수신 동의/거부
    const agreeAlertOpen = () => {
        setSnackBarOpen({
            open: true,
            msg: `${changeDate}
                골라가 마케팅 정보 수신에 ${agreeTxt}하셨습니다.`
        });
    };

    // 마케팅 정보 수신 동의/거부 onChange 이벤트
    const checkHandler = (event) => {
        setAgreeChecked({isMarketingAgree: event.target.checked, marketingAgreeDate: changeDate});
        agreeAlertOpen();

        const payload = {
            agreementType: 'MARKETING',
            isAgreement: event.target.checked,
            email: member?.email
        };

        // 마케팅 동의 여부 관리를 위해 추가
        fetchWrapper.post(`/member/change/agreement/status`, payload);

        localStorageUtil.set(
            'agreeChecked',
            JSON.stringify({
                checked: event.target.checked,
                agreeAt: new Date().toISOString()
            })
        );
    };

    //#region 예약정보 조회,전송 동의 onChange(토글) 이벤트
    const checkReservAgreement = () => {
        if (reservChecked.isBookingCheckAgree) {
            // 현재 상태가 true일 때 토글 클릭 시 확인 Alert 오픈
            if (reservChecked.bookingCheckAgreeDate) {
                onAlertOpen();
                return;
            }
        } else {
            setDrawerState(true);
        }
    };

    useEffect(() => {
        if (isAgreementCheck) {
            isToggleChange();
        }
    }, [isAgreementCheck]);

    const isToggleChange = () => {
        if (!reservChecked.isBookingCheckAgree) {
            setReservChecked({
                isBookingCheckAgree: true,
                bookingCheckAgreeDate: changeDate
            });

            // 스낵바 오픈
            setSnackBarOpen({
                open: true,
                msg:
                    '예약정보 조회 ∙ 전송에 동의하셨습니다. \n' +
                    '이제 외부 예약 건에 대한 스마트라운드\n' +
                    '서비스 이용이 가능합니다.'
            });
            fetchWrapper.get('/booking/linkedGolfClub');

            setIsAgreementCheck(false);
        }
    };
    //#endregion

    const onCancelAgree = () => {
        setReservChecked({
            isBookingCheckAgree: false,
            bookingCheckAgreeDate: ''
        });

        const payload = {
            agreementType: 'BOOKING_CHECK',
            isAgreement: false,
            email: member?.email
        };

        // 예약정보 조회,전송 동의 여부 관리를 위해 추가 (payload로 해당 값 전달)
        fetchWrapper.post(`/member/change/agreement/status`, payload).then((res) => {
            store.dispatch(userActions.setUser({key: 'isBookingCheckAgree', value: false}));
        });

        onAlertClose();

        // 스낵바 오픈
        setSnackBarOpen({
            open: true,
            msg: '예약정보 조회 ∙ 전송 동의 철회되었습니다'
        });
        popupHideCancel();
        localStorageUtil.remove('get_reservation_agree');
    };
    // 로컬 스토리지 삭제
    const popupHideCancel = () => localStorageUtil.remove('get_my_reserve_drawer');

    // 마케팅 정보 수신 동의/거부 텍스트 변경
    useEffect(() => {
        if (agreeChecked.isMarketingAgree === true) {
            setAgreeTxt('철회');
        } else {
            setAgreeTxt('동의');
        }
    }, [agreeChecked.isMarketingAgree]);

    useEffect(() => {
        getMemberInfo();
        viewChangeMyinfo();
    }, [getMemberInfo]);

    useEffect(() => {
        setProfileImg(member?.profileImageURL);
    }, [member?.profileImageURL]);

    useEffect(() => {
        switch (memberChannel) {
            case 'KAKAO':
                setJoinRoute({
                    class: 'circleKakao',
                    title: '카카오 계정'
                });
                break;
            case 'APPLE':
                setJoinRoute({
                    class: 'circleApple',
                    title: 'Apple 계정'
                });
                break;
            default:
                setJoinRoute({
                    class: 'circleEmail',
                    title: '이메일'
                });
        }
    }, [memberChannel]);

    return (
        <>
            <Header back={true} pageTitle="회원정보 수정" />
            <div id="contents" className="bgGray">
                <div id="myInfoModify" className="contentsArea">
                    <div className="inner">
                        <section className="profileArea">
                            <div className="profileImgArea">
                                <Avatar src={profileImg} golagaMember={true} />
                                <label htmlFor="profile"></label>
                                <input
                                    id="profile"
                                    type="file"
                                    accept="image/jpg,image/png,image/jpeg"
                                    name="profile"
                                    onChange={(evt) => onChange(evt)}
                                    ref={fileInput}
                                />
                            </div>
                            {/*<b className="name">{member?.name}</b>*/}
                            <b className="name">{member?.maskedName}</b>
                        </section>

                        <section className="myInfoDetail">
                            <div className="infoType04">
                                <div className="list">
                                    <dl>
                                        <dt>가입경로</dt>
                                        <dd>
                                            <i className="joinRoute">
                                                <Icon
                                                    name={joinRoute?.class}
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                />
                                            </i>
                                            {joinRoute?.title}
                                        </dd>
                                    </dl>
                                </div>
                                <div className="list">
                                    <dl>
                                        <dt>이메일</dt>
                                        {/*<dd className="mail">{member?.email}</dd>*/}
                                        <dd className="mail">{member?.maskedEmail}</dd>
                                    </dl>
                                </div>
                                <div className="list">
                                    <dl>
                                        <dt>생년월일</dt>
                                        <dd>{appUtils.dateFormatBirthDay(member?.birthdate || '')}</dd>
                                    </dl>
                                </div>
                                <div className="list">
                                    <dl>
                                        <dt>성별</dt>
                                        <dd className="mail">{member?.gender === 'MALE' ? '남성' : '여성'}</dd>
                                    </dl>
                                </div>
                                <div className="list">
                                    <dl>
                                        <dt>휴대폰 번호</dt>
                                        {/*<dd>{member?.cellPhone}</dd>*/}
                                        <dd>{member?.maskedCellPhone}</dd>
                                    </dl>
                                    {/* TODO : 기획(조이) 협의 - 1.2.9v 작업에 추가  */}
                                    <div className="btnArea">
                                        <Link to="/mypage/auth-phone" className="btnGrayLight small">
                                            변경
                                        </Link>
                                    </div>
                                </div>
                                {memberChannel === 'EMAIL' && (
                                    <div className="list">
                                        <strong className="tt">비밀번호 </strong>
                                        <div className="btnArea">
                                            <Link to="/mypage/auth-pw" className="btnGrayLight small">
                                                변경
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>

                        {/* 예약 정보 조회.전송 동의 */}
                        <section className="agreeSection">
                            <div className="ttArea">
                                <div className="tt">
                                    <p>예약 정보 조회 &middot; 전송동의</p>
                                    {reservChecked.isBookingCheckAgree && (
                                        <span className="date">{reservChecked.bookingCheckAgreeDate} 동의</span>
                                    )}
                                </div>

                                <Switch
                                    checked={reservChecked.isBookingCheckAgree}
                                    onChange={checkReservAgreement}
                                    className="defaultSwitch"
                                    disableRipple
                                />
                            </div>
                            <div className="agreeCon">
                                <p>제휴 골프장의 예약정보를 조회 및 전송받아 골라가에서 확인하실 수 있습니다.</p>
                            </div>
                        </section>

                        {/* 마케팅 정보 수신 동의 */}
                        <section className="agreeSection">
                            <div className="ttArea">
                                <div className="tt">
                                    <p>마케팅 정보 수신 동의</p>
                                    {agreeChecked.isMarketingAgree && (
                                        <span className="date">{agreeChecked.marketingAgreeDate} 동의</span>
                                    )}
                                </div>

                                <Switch
                                    checked={agreeChecked.isMarketingAgree}
                                    onChange={checkHandler}
                                    className="defaultSwitch"
                                    disableRipple
                                />
                            </div>
                            <div className="agreeCon">
                                <p>다양한 혜택과 이벤트 소식을 문자,이메일,앱 푸시로 받으실 수 있습니다.</p>
                            </div>
                        </section>
                    </div>
                    <div className="sideBtnArea">
                        <Link to="/mypage/withdrawal">회원탈퇴</Link>
                    </div>
                </div>
            </div>

            {/* 예약정보 조회,전송 동의 철회 Alert */}
            <Modal open={alertState} onClose={onAlertClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div className="alertContents">
                            예약정보 조회 ∙ 전송 동의를 철회하시면
                            <br />
                            외부예약 건에 대해 셀프체크인을
                            <br />
                            이용하실 수 없습니다.
                            <br />
                            그래도 철회하시겠습니까?
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={onAlertClose}>
                            아니오
                        </button>
                        <button type="button" onClick={onCancelAgree}>
                            철회하기
                        </button>
                    </div>
                </div>
            </Modal>

            <DirectionSnackbar
                direction={'up'}
                position={'bottom'}
                open={snackBarOpen.open}
                msg={snackBarOpen.msg}
                duration={2000}
                setOpen={setSnackBarOpen}
            />
            <AgreementDrawer
                open={drawerState}
                onClose={onClickClose}
                isAgreementCheck={() => setIsAgreementCheck(true)}
            />
        </>
    );
}

export default MyInfoModify;
