import React, {useCallback, useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {fetchWrapper, history} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import {clickBnbHome, clickBnbMyInfo, clickBnbSearch, clickBnbSmartRound} from '_helpers/amplitude/events/click.event';
import {smartroundPreview} from '../../_helpers/amplitude/events/etc.event';
import {useDispatch} from 'react-redux';
import {smartRoundActions} from '_store/smartRound.slice';

function Navigation() {
    // Navigation 있을때 하단 여백 추가를 위한 클래스명 추가
    const dispatch = useDispatch();
    const location = useLocation();

    const commonNavList = [
        {pathname: '/main', classname: 'home', title: '홈'},
        {pathname: '/search', classname: 'search', title: '검색'},
        {pathname: '/booking', classname: 'smartRound', title: '스마트라운드'},
        {pathname: '/mypage', classname: 'mypage', title: '내 정보'}
    ];

    const getBookingList = () => {
        clickBnbSmartRound();
        fetchWrapper.get(`/booking/list/smartround?includeMember=true`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                const dataList = response?.data.lists;

                if (dataList.length === 0) {
                    smartroundPreview('Y');
                    history.navigate('/smart-round-guide');
                } else if (dataList.length === 1) {
                    smartroundPreview('N');
                    dispatch(smartRoundActions.setFrom({from: '/main'}));
                    history.navigate(
                        `/self-check-in-v2?bookingId=${dataList[0]?.id}&golfClubId=${dataList[0]?.golfClubId}`
                    );
                } else if (dataList.length > 1) {
                    smartroundPreview('N');
                    history.navigate('/booking');
                }
            }
        });
    };

    useEffect(() => {
        document.body.classList.add('naviBottom');
        return () => {
            document.body.classList.remove('naviBottom');
        };
    }, []);

    return (
        <>
            <div id="Navigation">
                {commonNavList.map((navi) =>
                    navi.pathname === '/' || navi.pathname === '/main' ? (
                        <NavLink
                            to={`${navi.pathname}`}
                            className={({isActive}) =>
                                `${navi.classname} ${isActive || location.pathname === '/' ? 'on' : ''}`
                            }
                            key={navi.classname}
                            onClick={clickBnbHome}
                            end
                        >
                            <i className={navi.classname}></i>
                            <span>{navi.title}</span>
                        </NavLink>
                    ) : navi.classname === 'smartRound' ? (
                        <NavLink
                            className={`${navi.classname} ${
                                location.pathname === '/booking' || location.pathname === '/smart-round-guide'
                                    ? 'on'
                                    : ''
                            }`}
                            key={navi.classname}
                            onClick={getBookingList}
                        >
                            <i className={navi.classname}></i>
                            <span>{navi.title}</span>
                        </NavLink>
                    ) : (
                        <NavLink
                            to={`${navi.pathname}`}
                            className={({isActive}) => `${navi.classname} ${isActive ? 'on' : ''}`}
                            key={navi.classname}
                            onClick={navi.pathname === '/search' ? clickBnbSearch : clickBnbMyInfo}
                        >
                            <i className={navi.classname}></i>
                            <span>{navi.title}</span>
                        </NavLink>
                    )
                )}
            </div>
        </>
    );
}

export default Navigation;
