import React, {useCallback, useEffect, useRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import TitleArea from '../../../common/TitleArea';
import {fetchWrapper, history} from '../../../../_helpers';
import {SUCCESS_CODE} from '../../../../common/constants';
import {AlertUtils} from '../../../../common/alertUtils';
import Modal from '@mui/material/Modal';
import Loading from '../../../../common/loading/Loading';
import {viewCancelFailAlert, viewCancelReason} from '../../../../_helpers/amplitude/events/view.events';
import {getAmplitudeRootPath} from '../../../../_helpers/amplitude/amplitude.helper';
import {cancelReservation} from '../../../../_helpers/amplitude/events/etc.event';
import AirbridgeUtil from '../../../../common/airbridgeUtil';
import {bookingConstant} from 'common/payment';
import {useDispatch, useSelector} from 'react-redux';
import {loadingActions} from '_store/loading.slice';

function BookingCancelReasonLayer(props) {
    const {handleOpen, handleClose, bookingData, passCancelAble, setBookingListReset} = props;

    const dispatch = useDispatch();
    // 스크롤 이벤트
    const scrollRef = useRef(null);
    // 타이틀 활성화
    const [isTitleVisible, setIsTitleVisible] = useState(false);
    // Radio 상태관리
    const [selectValue, setSelectValue] = useState(null);
    // 동의 체크 버튼 활성화
    const [activeButton, setActiveButton] = useState(false);
    // 기타 항목의 사용자 입력창 활성화
    const [activeTextArea, _setActiveTextArea] = useState(false);
    const myActiveTextArea = useRef(activeTextArea);
    const setActiveTextArea = (active) => {
        myActiveTextArea.current = active;
        _setActiveTextArea(active);
    };
    // 기타 항목 사용자 입력
    const [textAreaInput, setTextAreaInput] = useState({
        active: false,
        textInput: ''
    });

    // 로딩페이지
    const {isLoading} = useSelector((x) => x.loading);
    // const [isLoading, setIsLoading] = useState(false);

    // 서비스 안내 Alert
    window.alert = AlertUtils();

    // 취소사유 데이터
    const [labelList, setLabelList] = useState([]);
    const getCancelReasonList = useCallback(() => {
        fetchWrapper.get(`/booking/cancel/reasonCode/list`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                if (response.data !== null) {
                    response.data.map((item) => {
                        item['checked'] = false; // 체크박스 상태관리
                    });
                }

                setLabelList(response.data || []);
            }
        });
    }, []);

    // 스크롤 이벤트
    const onScroll = useCallback((evt) => {
        const div = evt.target;

        const currentScrollPosition = div.scrollTop;

        if (currentScrollPosition > 0) {
            setIsTitleVisible(true);
        } else {
            setIsTitleVisible(false);
        }
    }, []);

    useEffect(() => {
        myActiveTextArea.current = false;
        if (handleOpen) {
            getCancelReasonList();

            fetchWrapper.get(`/amplitude-events/view-modal-cancel?bookingId=${bookingData?.id}`).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    viewCancelReason(response.data, getAmplitudeRootPath());
                }
            });
        } else {
            setLabelList([]);
            setSelectValue(null);
            setActiveButton(false);
        }
    }, [handleOpen]);

    //예약취소 함수

    // 레이어 닫기
    const layerClose = () => {
        handleClose();
        setTimeout(() => {
            setIsTitleVisible(false);
        }, [100]);

        // 기타 사유 textarea 초기화
        setTextAreaInput({
            active: false,
            textInput: ''
        });
    };

    const textOnChanged = (e) => {
        const textareaValueLength = e.target.value.length;

        if (textareaValueLength > 50) {
            return;
        }

        setTextAreaInput({
            active: textareaValueLength > 0,
            textInput: e.target.value
        });

        // 취소사유선택 > 기타 선택 시 사유 입력 필수로 적용
        if (textareaValueLength > 0) {
            setActiveButton(true);
        } else {
            setActiveButton(false);
        }
    };

    // Radio 상태관리
    const onCheckClick = (e) => {
        const id = e.target.value;
        const otherReasonId = '9758b1cf-d1da-11ed-8eaf-0242ac110003'; // 기타 사유
        setSelectValue(id);

        // 리스트의 checked 업데이트
        labelList.map((item) => {
            item.checked = item.id === id;
        });

        // 기타 항목의 사용자 입력창 활성화
        if (id === otherReasonId) {
            setActiveTextArea(true);
        } else {
            setActiveTextArea(false);
        }

        const checkedList = labelList.filter((item) => item.checked === true);
        if (checkedList.length > 0) {
            // 취소사유선택 > 기타 선택 시 사유 입력 필수로 적용
            if (checkedList[0].id === otherReasonId && !textAreaInput.active) {
                setActiveButton(false);
            } else {
                setActiveButton(true);
            }
        } else {
            setActiveButton(false);
        }
    };

    // 취소접수 완료 레이어
    const [handleAlert, setHandleAlert] = useState({
        open: false,
        message: '',
        flag: null
    });

    const onHandleAlert = (alert, code) => {
        setHandleAlert(alert);

        //cms 에서 취소 불가
        if (code === '8101') {
            fetchWrapper
                .get(`/amplitude-events/click-reservation-btn?roundBookingId=${bookingData?.id}`, null)
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        viewCancelFailAlert(
                            response.data,
                            getAmplitudeRootPath(),
                            '[' + alert?.code + '] / ' + alert?.message
                        );
                    }
                });
        }
    };

    const handleAlertClose = () => {
        if (handleAlert.flag === null) {
            setHandleAlert({
                open: false,
                message: '',
                flag: null
            });
            layerClose();
        } else {
            history.navigate(`/mypage/booking?bookingId=${bookingData?.id}&flag=${handleAlert.flag}`);
            setBookingListReset(true);
        }
    };

    // 완료 버튼
    const onBookingCancel = async () => {
        if (activeButton === false) {
            return;
        }

        // open loading page and btn disable
        // setIsLoading(true);
        dispatch(loadingActions.showLoading());
        setActiveButton(false);

        fetchWrapper
            .get(`/cancel/possibility?bookingId=${bookingData?.id}&timeSheetId=${bookingData?.timeSheetId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    //

                    //7991 : 라운드 전날 임박 취소
                    // if (response?.data?.impossibleCode === '7991') {
                    //     // 취소가능기한이 경과한 경우 > 취소사유 선택 풀 팝업(규정 및 유의사항 체크 팝업) 오픈
                    //     onHandleAlert({
                    //         open: true,
                    //         message: response?.data?.impossibleReason,
                    //         flag: 'pre'
                    //     });
                    // } else {
                    //결제 취소 플로우
                    bookingDelete();
                    // }
                } else {
                    //          예약내역 확인 불가 : 7999
                    //           취소기한 경과 : 7991
                    //           cms에서 취소 불가 : 8101 (Data 항목에서 상세 사유가 나옴)*/
                    onHandleAlert(
                        {
                            open: true,
                            message: response?.data?.impossibleReason,
                            flag: 'pre'
                        },
                        response.code
                    );
                }
            });
    };

    const bookingDelete = () => {
        const payload = {
            roundBookingId: bookingData?.id,
            cancelReasonCode: selectValue,
            cancelReasonMessage: activeTextArea ? textAreaInput.textInput : ''
            // timeSheetId: bookingData?.timeSheetId
        };

        //알람톡 보내기 함수
        const alarmTalkSend = () => {
            fetchWrapper
                .post(`/sms/round-booking-cancel`, {roundBookingId: bookingData?.id})
                .then((response) => {
                    if (response?.code === SUCCESS_CODE) {
                        console.log('알림톡 보내기 성공');
                    } else {
                        console.log('알림톡 보내기 실패');
                    }
                })
                .catch((err) => {
                    console.log('[bookingcancel alarmTalk err : ]' + err);
                });
        };

        fetchWrapper
            .delete(`/round-booking`, payload)
            .then((response) => {
                // close loading page and btn enable
                // setIsLoading(false);
                dispatch(loadingActions.hideLoading());
                setActiveButton(true);
                if (response?.code === SUCCESS_CODE) {
                    console.log('여기 취소성공');

                    // 알림톡 함수 호출 추가
                    alarmTalkSend();

                    // 예약 취소 완료 Alert 노출
                    if (passCancelAble) {
                        //취소 접수 화면
                        // onHandleAlert({
                        //     open: true,
                        //     message: `취소 확정 시, 골라가 알림톡으로 \n안내해 드릴 예정이며 취소내역에서도 \n확인하실 수 있습니다.`,
                        //     flag: 'pre'
                        // });

                        history.navigate('/mypage/booking-cancel', {
                            state: {
                                bookingId: bookingData?.id,
                                status: bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_RECEIPT_COM
                            }
                        });
                    } else {
                        //취소 완료 화면
                        // onHandleAlert({
                        //     open: true,
                        //     message: `예약이 취소되었습니다.`,
                        //     flag: 'cancel'
                        // });

                        history.navigate('/mypage/booking-cancel', {
                            state: {
                                bookingId: bookingData?.id,
                                status: bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_COM
                            }
                        });
                    }
                    fetchWrapper
                        .get(`/amplitude-events/cancel-reservation?bookingId=${bookingData?.id}`, null)
                        .then((response) => {
                            cancelReservation(response.data, getAmplitudeRootPath());
                        });
                    // airbridge event
                    AirbridgeUtil.event.cancelReservation(
                        bookingData.golfClubId,
                        bookingData.golfClubName,
                        bookingData.perAmount,
                        bookingData.roundAt,
                        bookingData.playerLimit
                    );
                    //cms 취소 불가 오류
                    /*정상 : 0000
                    예약내역 확인 불가 : 7999
                    취소기한 경과 : 7991
                    cms에서 취소 불가 : 8101 (Data 항목에서 상세 사유가 나옴)*/
                } else {
                    console.log('여기 취소실패');
                    //예약 취소 완료하지 못했습니다. 화면
                    // onHandleAlert({
                    //     open: true,
                    //     message: response?.data?.cancelMessage ? response?.data?.cancelMessage : response?.message,
                    //     flag: null
                    // });
                    history.navigate('/mypage/booking-cancel', {
                        state: {
                            bookingId: bookingData?.id,
                            status: bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_FAULT,
                            cancelPaymentFailReason: '[' + response?.code + '] /' + response?.message
                        }
                    });
                }
            })
            .catch((err) => {
                console.log('booking cancel err : ' + err);
                // close loading page and btn enable
                // setIsLoading(false);
                dispatch(loadingActions.hideLoading());
                setActiveButton(true);
                history.navigate('/mypage/booking-cancel', {
                    state: {
                        bookingId: bookingData.id,
                        status: bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_FAULT
                    }
                });
            });
    };

    return (
        <>
            <Dialog fullScreen open={handleOpen} onClose={handleClose} transitionDuration={0} className="fullPageModal">
                <div className="layerPop">
                    <div className={`layerHeader2 ${isTitleVisible ? 'border' : ''}`}>
                        <div className={`layerHeader_title ${isTitleVisible ? 'visible' : ''}`}>취소사유</div>
                        <button type="button" className="btnClose" onClick={layerClose}>
                            닫기
                        </button>
                    </div>
                    <div className="layerContents3 " ref={scrollRef} onScroll={onScroll}>
                        <div className="inner">
                            <div>
                                <TitleArea pageTitle={'예약 취소사유를 선택해주세요.'} />
                                <div className="labelCheckList">
                                    <ul>
                                        {labelList?.map((item, idx) => {
                                            return (
                                                <li className="list" key={idx}>
                                                    <label
                                                        className={`customCheck03 ${
                                                            selectValue === item?.id ? 'checked' : ''
                                                        }`}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="bookingCancelLabel"
                                                            value={item?.id}
                                                            onChange={(e) => onCheckClick(e)}
                                                            checked={selectValue === item?.id}
                                                        />
                                                        <i className="icon"></i>
                                                        <span>{item?.name}</span>
                                                    </label>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    {myActiveTextArea.current && (
                                        <div className="textAreaField">
                                            <textarea
                                                maxLength="50"
                                                value={textAreaInput.textInput}
                                                onChange={textOnChanged}
                                                className={textAreaInput.active ? 'active' : ''}
                                            />
                                            <span className="length">
                                                <b>{textAreaInput.textInput.length}</b>/50자
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modalBtnArea">
                                <button
                                    type="button"
                                    className="btnPrimary"
                                    disabled={!activeButton}
                                    onClick={onBookingCancel}
                                >
                                    완료
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && <div className="loading-overlay">{<Loading />}</div>}
            </Dialog>

            {/* Alert */}
            <Modal open={handleAlert.open} className="highZIndex">
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div className="alertContents">{handleAlert.message}</div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" onClick={handleAlertClose}>
                            확인
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default BookingCancelReasonLayer;
