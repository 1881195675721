import React from 'react';
import Icon from '../../icons/Icon';
import SearchResultBelowItem from './SearchResultBelowItem';
import RecentSearchBelowItem from './RecentSearchBelowItem';

function RecentSearchBelow({recentSearchList, onRemove, onRemoveAll, onClickRecentItem}) {
    return (
        <>
            <div className="contentsTitle">
                <div className="inner">
                    <div className="title">최근 검색</div>
                    <div className="reset" onClick={onRemoveAll}>
                        초기화
                    </div>
                </div>
            </div>
            <div className="filterScrollArea">
                <div className="inner">
                    <ul className="recentSearch">
                        {recentSearchList.length !== 0 ? (
                            <div>
                                {recentSearchList.map((recentSearchListItem, idx) => (
                                    <RecentSearchBelowItem
                                        searchListItem={recentSearchListItem}
                                        key={idx}
                                        onRemove={onRemove}
                                        onClickRecentItem={onClickRecentItem}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div>최근 검색 결과 없음</div>
                        )}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default RecentSearchBelow;
