import React, {useState, useEffect} from 'react';

import useWindowDimensions from '../../hooks/UseWindowDimensions';
import Icon from '../../icons/Icon';
import Button from '../../button/Button';
import './payment-cancel-status.scss';
import PaymentDetailModal from 'components/Payment/PaymentDetailModal/PaymentDetailModal';
import {bookingCancel} from 'common/payment/locales/kr/booking-cancel';
import {paymentCancel} from 'common/payment/locales/kr/payment-cancel';
import {btn} from 'common/payment/locales/kr/btn';

import PaymentBookingCancelConfirm from 'components/Payment/PaymentBookingCancelConfirm/PaymentBookingCancelConfirm';
import {fetchWrapper, history} from '_helpers';
import {bookingConstant} from 'common/payment';
import {viewCancelPaymentFail} from '_helpers/amplitude/events/view.events';
import {SUCCESS_CODE} from 'common/constants';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';

const PaymentCancelStatus = (props) => {
    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    const [cancelStatusType, setCancelStatusType] = useState(
        history?.location?.state?.status || bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_FAULT
    );

    const [bookingId, setBookingId] = useState(history?.location?.state?.bookingId);

    useEffect(() => {
        setCancelStatusType(history?.location?.state?.status);

        if (history?.location?.state?.status === bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_FAULT) {
            fetchWrapper
                .get(
                    `/amplitude-events/click-reservation-btn?roundBookingId=${history?.location?.state?.bookingId}`,
                    null
                )
                .then((response) => {
                    if (response.code === SUCCESS_CODE) {
                        viewCancelPaymentFail(
                            response.data,
                            getAmplitudeRootPath(),
                            history?.location?.state?.cancelPaymentFailReason
                        );
                    }
                });
        }

        setBookingId(history?.location?.state?.bookingId);
    }, [history]);

    const onClickGoBackHome = () => {
        history.navigate('/');
    };

    //다시 취소하기, 예약내역 확인
    const onClickReCancelBtn = () => {
        history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=pre`);
    };

    //취소 내역 확인
    // 취소 라운드 라우팅 이슈로 인한 라우팅 현재는 사용안함
    const onClickCancelHistoryBtn = () => {
        history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=cancel`);
    };

    switch (cancelStatusType) {
        //예약 취소 접수 완료
        case bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_RECEIPT_COM:
            return (
                <div id="contents" className="payment-cancel-status-contents">
                    <div className="cancel-status-area" style={{minHeight: `${height}px`}}>
                        <div className="inner">
                            <div className="cancel-status-title-msg">
                                <i className="icon">
                                    <Icon name="exclamationMark" width="60" height="60" viewBox="0 0 60 60" />
                                </i>
                                <strong className="tt">{bookingCancel.booking_cancel_receipt_complete_title}</strong>
                                {/* <p className="txt">{paymentCancel.payment_cancel_request_complete_text}</p> */}
                            </div>

                            <div className="cancel-status-btn-fixed-bottom">
                                <div className="cancel-status-sub-text-area">
                                    <p>{bookingCancel.booking_cancel_receipt_complete_sub_text}</p>
                                </div>
                                <div className="inner">
                                    <Button type="button" darkGray onClick={onClickGoBackHome}>
                                        {btn.gohome_btn}
                                    </Button>
                                    <Button type="button" onClick={onClickReCancelBtn}>
                                        {btn.booking_list_confirm}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
            break;
        //예약 취소 완료
        case bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_COM:
            return (
                <div id="contents" className="payment-cancel-status-contents">
                    <div className="cancel-status-area" style={{minHeight: `${height}px`}}>
                        <div className="inner">
                            <div className="cancel-status-title-msg">
                                <i className="icon">
                                    <Icon name="exclamationMark" width="60" height="60" viewBox="0 0 60 60" />
                                </i>
                                <strong className="tt">{bookingCancel.booking_cancel_complete_title}</strong>
                                <p className="txt">{paymentCancel.payment_cancel_request_complete_text}</p>
                            </div>

                            <div className="cancel-status-btn-fixed-bottom">
                                <div className="cancel-status-sub-text-area">
                                    <p>{bookingCancel.booking_cancel_complete_sub_text}</p>
                                </div>
                                <div className="inner">
                                    <Button type="button" darkGray onClick={onClickGoBackHome}>
                                        {btn.gohome_btn}
                                    </Button>
                                    <Button type="button" onClick={onClickReCancelBtn}>
                                        {btn.booking_list_confirm}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
            break;
        //예약 취소 실패
        case bookingConstant.bookingCancelStatusType.BOOKING_CANCEL_FAULT:
            return (
                <div id="contents" className="payment-cancel-status-contents">
                    <div className="cancel-status-area" style={{minHeight: `${height}px`}}>
                        <div className="inner">
                            <div className="cancel-status-title-msg">
                                <i className="icon">
                                    <Icon name="exclamationMark" width="60" height="60" viewBox="0 0 60 60" />
                                </i>
                                <strong className="tt">{paymentCancel.payment_cancel_request_fault_title}</strong>
                                <p className="txt">{paymentCancel.payment_cancel_request_fault_text}</p>
                            </div>

                            <div className="cancel-status-btn-fixed-bottom">
                                <div className="cancel-status-sub-text-area">
                                    <p>{paymentCancel.payment_cancel_request_fault_sub_text}</p>
                                </div>
                                <div className="inner">
                                    <Button type="button" darkGray onClick={onClickGoBackHome}>
                                        {btn.gohome_btn}
                                    </Button>
                                    <Button type="button" onClick={onClickReCancelBtn}>
                                        {btn.re_cancel_btn}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
            break;
    }
};

export default PaymentCancelStatus;
