import Modal from '@mui/material/Modal';
import {useDispatch, useSelector} from 'react-redux';
import {alertActions} from '../../_store/alert.slice';
import {CANCELTMESSAGE, CNFRMTITMESSAGE, SETTINGMESSAGE, SETTINGMESSAGE_CHECKIN} from '../../common/constants';

function CommonAlert({successCallBack, failCallBack}) {
    const dispatch = useDispatch();
    const alertData = useSelector((x) => x.alert);

    const closeAlert = () => {
        dispatch(alertActions.closeAlert());
    };

    const onClickYes = () => {
        closeAlert();
        successCallBack && successCallBack();
    };

    const onClickNo = () => {
        closeAlert();
        failCallBack && failCallBack();
    };

    return (
        <>
            <Modal open={alertData?.open}>
                {alertData?.type === 'KAKAOINVITE' ? (
                    <div className="defaultModal">
                        <div className="modalContents">
                            <p className="alignC">카카오톡에서 1명을 초대하였습니다</p>
                            {/*<div className="avatarWrap">*/}
                            {/*    <ul className="listCon">*/}
                            {/*        <li>*/}
                            {/*            <Avatar*/}
                            {/*                defaultSrc={true}*/}
                            {/*                // data={bookingMember}*/}
                            {/*                // number={friend?.phone ? appUtils.formatPhoneNumber(friend?.phone) : ''}*/}
                            {/*                golagaMember={false}*/}
                            {/*                gender={null}*/}
                            {/*            />*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                        </div>
                        <div className="modalBtnArea">
                            {/*<button type="button" className="button cancel" onClick={onClickNo}>*/}
                            {/*    취소*/}
                            {/*</button>*/}
                            <button type="button" className="button submit" onClick={onClickYes}>
                                확인
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="defaultAlert">
                        <div id="alertConWrap">
                            {alertData?.messageData?.title && <div id="alertTitle">{alertData.messageData.title}</div>}
                            <div className="alertContents">{alertData?.messageData?.message}</div>
                        </div>
                        <div className="alertBtnArea">
                            {alertData?.type === CNFRMTITMESSAGE ||
                            alertData?.type === CANCELTMESSAGE ||
                            alertData?.type === SETTINGMESSAGE ||
                            alertData?.type === SETTINGMESSAGE_CHECKIN ? (
                                <button type="button" onClick={onClickNo}>
                                    {alertData.type === CANCELTMESSAGE ||
                                    alertData?.type === SETTINGMESSAGE ||
                                    alertData?.type === SETTINGMESSAGE_CHECKIN
                                        ? '취소'
                                        : '아니오'}
                                </button>
                            ) : null}
                            <button type="button" onClick={onClickYes}>
                                {alertData?.type === CNFRMTITMESSAGE ? '예' : '확인'}
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
}

export default CommonAlert;
