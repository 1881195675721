import React, {useEffect, useState} from "react";
import Header from "../Header";
import TitleArea from "../common/TitleArea";
import BasicAlert from "../../components/alert/BasicAlert";
import Switch from "@mui/material/Switch";
import "./mypage.scss";
import {localStorageUtil} from "../../common/utils";

function SetAlarm({ title }) {
    // 알림 상태 Alert
    const [onAlert, setOnAlert] = useState(!JSON.parse(localStorageUtil.get("infoChecked")) && !JSON.parse(localStorageUtil.get("eventChecked")));      //알람 처리
    const onAlertOpen = () => setOnAlert(true);
    const onAlertClose = () => setOnAlert(false);

    const [infoChecked, setInfoChecked] = useState(JSON.parse(localStorageUtil.get("infoChecked")) || false);
    const [eventChecked, setEventChecked] = useState(JSON.parse(localStorageUtil.get("eventChecked")) || false);
    const [allChecked, setAllChecked] = useState(JSON.parse(localStorageUtil.get("infoChecked")) && JSON.parse(localStorageUtil.get("eventChecked")));

    useEffect(() => {
        if (infoChecked && eventChecked) {
            setAllChecked(true);
        } else {
            setAllChecked(false);
        }
        if (infoChecked || eventChecked) {
            onAlertClose();
        } else {
            onAlertOpen();
        }
    }, [infoChecked, eventChecked]);

    const onChangeAllCheck = (e) => {
        setInfoChecked(!e.checked);
        setEventChecked(!e.checked);
    }

    const toggleAllChecked = (e) => {
        setAllChecked(value => !value);
        setInfoChecked(e.target.checked);
        localStorageUtil.set('infoChecked', e.target.checked);
        setEventChecked(e.target.checked);
        localStorageUtil.set('eventChecked', e.target.checked);
    }

    const toggleInfoChecked = (e) => {
        setInfoChecked(value => !value);
        localStorageUtil.set('infoChecked', e.target.checked);
    }
    const toggleEventChecked = (e) => {
        setEventChecked(value => !value);
        localStorageUtil.set('eventChecked', e.target.checked);
    }

    return (
        <>
            <Header back={true} />
            <div id="contents">
                <div className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="pushListWrap">
                            <ul className="pushList">
                                <li>
                                    <div className="txtArea">
                                        <strong>전체 알림</strong>
                                        <span>정보 및 이벤트 알림</span>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={allChecked}
                                            onChange={toggleAllChecked}
                                            className="defaultSwitch"
                                            disableRipple
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="txtArea">
                                        <strong>정보 알림</strong>
                                        <span>
                                            골프장 콘텐츠 및 서비스 정보
                                        </span>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={infoChecked}
                                            onChange={toggleInfoChecked}
                                            className="defaultSwitch"
                                            disableRipple
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="txtArea">
                                        <strong>이벤트 알림</strong>
                                        <span>혜택 및 이벤트 정보</span>
                                    </div>
                                    <div>
                                        <Switch
                                            checked={eventChecked}
                                            onChange={toggleEventChecked}
                                            className="defaultSwitch"
                                            disableRipple
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* 알림 상태 Alert */}
            <BasicAlert
                title="알림이 꺼져있습니다."
                contents="푸시 알림을 받으려면 기기 설정에서 알림을 허용해주세요."
                open={onAlert}
                handleClose={onAlertClose}
            />
        </>
    );
}

export default SetAlarm;
