import CancelStatusPage from 'components/Payment/PaymentCancelStatus/PaymentCancelStatus';
import PaymentBookingComplete from 'components/Payment/PaymentBookingComplete/PaymentBookingComplete';
import PaymentBookingFail from 'components/Payment/PaymentBookingFail/PaymentBookingFail';
import PaymentBooking from '../components/Payment/PaymentBooking/PaymentBooking';
import PaymentBookingSuccess from 'components/Payment/PaymentBookingSuccess/PaymentBookingSuccess';

export const paymentRoutes = [
    {
        path: '/mypage/booking-cancel',
        element: <CancelStatusPage />,
        isLogin: true
    },
    /* 결제 성공 */
    {
        path: '/payment/booking-complete',
        element: <PaymentBookingComplete />,
        isLogin: true
    },
    /* 예약, 결제 실패 */
    {
        path: '/payment/booking-fail',
        element: <PaymentBookingFail />,
        isLogin: true
    },
    /* 예약하기  */
    {
        path: '/payment/booking/:id',
        element: <PaymentBooking />,
        isLogin: true
    },
    /* 예약 성공  */
    {
        path: '/payment/booking-success',
        element: <PaymentBookingSuccess />,
        isLogin: true
    }
];
