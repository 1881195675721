import React from "react";
import Header from "components/Header";
import TitleArea from "../../common/TitleArea";
import JoinLocationService from "components/agreement/member/JoinLocationService";

function LocationService() {
    return (
        <>
            <Header close={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={"위치기반 서비스 이용약관"} />
                        <div className="agreeCon">
                            <JoinLocationService />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LocationService;
