import React, {useCallback, useLayoutEffect, useState, useEffect} from 'react';
import Header from '../../Header';
import {fetchWrapper, history} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import {Link, useSearchParams} from 'react-router-dom';
import BookingCardList from './BookingCardList';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import {useLocation} from 'react-router-dom';
import Loading from '../../../common/loading/Loading';
import Icon from '../../icons/Icon';
import CommonAlert from '../../alert/CommonAlert';
import SearchMapDrawer from '../../reservation/drawer/SearchMapDrawer';
import Modal from '@mui/material/Modal';
import {useDispatch, useSelector} from 'react-redux';
import {kakaoNotInstallActions} from '../../../_store/kakaoNotInstall.slice';
import {installLink} from '../../../common/links';
import {appUtils, localStorageUtil} from '../../../common/utils';
import SelfCheckInGuideLayer from '../../smart-round/SelfCheckInGuideLayer';
import {rootTaxonomy} from '../../../_helpers/amplitude/taxonomy/root.taxonomy';
import {viewOtherBookingDetails, viewReservationDetails} from '../../../_helpers/amplitude/events/view.events';
import DefaultAlert from '../../alert/DefaultAlert';
import {inviteActions} from '../../../_store/invite.slice';

function Booking() {
    const myRef = React.useRef(null);
    const dispatch = useDispatch();
    const member = useSelector((x) => x?.users?.users);

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    let bookingId = searchParams.get('bookingId');

    let joinSelfChInGuideAction = JSON.parse(localStorageUtil.get('joinSelfChInGuideAction'));

    //예약내역
    const [status, setStatus] = useState({
        value: 'pre',
        includeMember: 'true'
    });

    const [bookingData, setBookingData] = useState(null);
    const [memberReset, setMemberReset] = useState(false);
    const [isDetailCourseGuide, setIsDetailCourseGuide] = useState(history.location.state?.backLink);
    const refresh = useSelector((x) => x?.invite.refresh);

    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    //리스트 로딩 스피너
    const [isLoading, setIsLoading] = useState(false);

    //상세정보 펼쳐보기 이벤트 제어
    const [hasDisabledAnimation, setHasDisabledAnimation] = useState(false);
    const [preStatus, setPreStatus] = useState('pre');

    // 지도(길찾기) Drawer
    const [mapDrawerOpen, setMapDrawerOpen] = useState(false);

    // 셀프체크인 가이드 레이어 오픈 여부
    const [selfCheckInGuideLayerOpen, setSelfCheckInGuideLayerOpen] = useState(false);
    const handleOpen = () => setSelfCheckInGuideLayerOpen(true);
    const handleClose = () => setSelfCheckInGuideLayerOpen(false);

    useEffect(() => {
        if (history.location?.state === 'post') {
            onChangeTab({
                value: 'post',
                includeMember: 'false'
            });
            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_past);
        } else if (history.location?.state === 'cancel') {
            onChangeTab({
                value: 'cancel',
                includeMember: 'false'
            });
            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_cancel);
        } else {
            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_upcoming);
        }
    }, []);

    useEffect(() => {
        let flag = searchParams.get('flag');

        if (location.pathname === '/mypage/booking' && flag === 'pre') {
            // console.log(status);
            // console.log('location.pathname', location.pathname);
            // console.log('여기');

            setStatus({
                value: flag,
                includeMember: 'true'
            });

            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_upcoming);
        } else if (location.pathname === '/mypage/booking' && flag === 'cancel') {
            setStatus({
                value: flag,
                includeMember: 'false'
            });
            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_cancel);
        } else {
            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_past);
        }
    }, [location]);

    const onChangeTab = (state) => {
        console.log('state', state);

        setStatus(state);

        if (state.value === 'pre') {
            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_upcoming);
        } else if (state.value === 'post') {
            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_past);
        } else if (state.value === 'cancel') {
            sessionStorage.setItem('root', rootTaxonomy.reservation_detail_tab_cancel);
        }

        window.scrollTo({
            top: 0
        });

        if (searchParams.has('bookingId')) {
            searchParams.delete('bookingId');
            searchParams.delete('flag');
            setSearchParams(searchParams);
        }
    };

    //예약취소시 예약내역 리렌더링
    const [bookingListReset, setBookingListReset] = useState(false);

    //예약내역 데이터
    const getBookingList = useCallback(() => {
        if (history.location?.state !== null && history.location?.state !== status.value) {
            return;
        }

        setIsLoading(true);
        setBookingData([]);

        fetchWrapper
            /**
             *
             * @TODO : 결제 완료 이후 페이지 네이션 구현
             */
            .get(`/booking/list/${status.value}?includeMember=${status.includeMember}&page=0&size=100`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    if (response.data !== null) {
                        response.data.lists.map((item) => {
                            item['isActive'] = false;
                            item['isBookingCancel'] = false;
                        });

                        const deepCopyBookingPreData = JSON.parse(JSON.stringify(response?.data.lists));
                        setBookingData(deepCopyBookingPreData || []);
                        setMemberReset(false);
                        setBookingListReset(false);
                        location.state = null;

                        viewReservationDetails(
                            status?.value,
                            deepCopyBookingPreData ? deepCopyBookingPreData.length : 0
                        );
                    }
                }

                setIsLoading(false);
            });

        // 탭 이동시에는 에니메이션 false
        if (preStatus !== status.value) {
            setHasDisabledAnimation(true);
        }
        setPreStatus(status.value);
    }, [status.value, status.includeMember, memberReset, bookingListReset]);

    useLayoutEffect(() => {
        getBookingList();
    }, [getBookingList]);

    useEffect(() => {
        // console.log('bookingId: ', bookingId);
        if (bookingId !== null) {
            let selectBookingList = myRef.current.childNodes;

            selectBookingList.forEach((item) => {
                let listBookingId = item.getAttribute('id');
                if (bookingId === listBookingId) {
                    const tabH = document.querySelector('.tabType01').clientHeight;
                    // item.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'});

                    setTimeout(() => {
                        window.scrollTo({
                            top: item?.offsetTop - tabH + 10,
                            behavior: 'smooth'
                        });
                    }, [100]);
                }
            });
        }
    }, [bookingData]);

    // Alert 확인 클릭 시 콜백
    const handleCommonAlertClose = useCallback(async () => {
        setMemberReset(true);
    }, []);

    // 골프클럽 상세 데이터
    const [detailData, setDetailData] = useState(null);
    const getDetailData = useCallback(
        (golfClubId) => {
            fetchWrapper.get(`/golfClub/detail/${golfClubId}`, null).then((response) => {
                let detailData = null;
                if (response?.code === SUCCESS_CODE) {
                    detailData = response?.data;
                }
                setDetailData(detailData);
            });
        },
        [detailData]
    );

    useEffect(() => {
        if (detailData !== null) {
            setMapDrawerOpen(true);

            appUtils.openWebViewModalPostMsg();
        }
    }, [detailData]);

    const alarmMessage = JSON.parse(localStorageUtil.get('AlarmMessage'));
    const [alertContents, setAlertContents] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const alarmErrorAlertOpen = () => {
        if (alarmMessage === null) return;
        setAlertOpen(true);
    };
    const alarmErrorAlertClose = () => setAlertOpen(false);

    const getAlarmInfo = () => {
        let joinShareAction = JSON.parse(localStorageUtil.get('joinShareAction'));
        let joinAlarmAction = JSON.parse(localStorageUtil.get('joinAlarmAction'));
        let joinSMAction = JSON.parse(localStorageUtil.get('joinSMAction'));
        let joinCourseAction = JSON.parse(localStorageUtil.get('joinCourseAction'));
        let joinClubBoardAction = JSON.parse(localStorageUtil.get('joinClubBoardAction'));
        let joinCSAction = JSON.parse(localStorageUtil.get('joinCSAction'));
        let joinSelfChInGuideAction = JSON.parse(localStorageUtil.get('joinSelfChInGuideAction'));
        let alarmMessage = JSON.parse(localStorageUtil.get('AlarmMessage'));

        if (joinAlarmAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinAlarmAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (joinSMAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinSMAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (joinCourseAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinCourseAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (joinClubBoardAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinClubBoardAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (joinSelfChInGuideAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinSelfChInGuideAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (alarmMessage) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        }
        dispatch(inviteActions.setRefresh(false));
    };

    useEffect(() => {
        if (refresh) getAlarmInfo();
    }, [refresh]);

    useEffect(() => {
        getAlarmInfo();
    }, []);

    // ===== 카카오톡 미 설치 시 설치 페이지로 이동 =====
    // 플레이스토어, 앱스토어 링크
    const androidLink = installLink.KAKAO_ANDROID;
    const iosLink = installLink.KAKAO_IOS;

    // 디바이스 구분
    let _device = '';
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) {
        _device = 'android';
    } else if (ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('ipod') > -1) {
        _device = 'ios';
    }

    // 디바이스 초기값 세팅
    const [device, setDevice] = useState(_device);

    function redirectStore() {
        if (device === 'android') {
            window.location.assign(androidLink);
        } else if (device === 'ios') {
            window.location.assign(iosLink);
        }
    }

    const onClickViewDetail = () => {
        viewOtherBookingDetails(rootTaxonomy.reservation_details);
    };

    // 카톡 미 설치 시 Alert
    const kakaoNotInstall = useSelector((x) => x?.kakaoNotInstall?.open);
    const [kakaoAlert, setKakaoAlert] = useState(false);
    const kakaoAlertOpen = () => setKakaoAlert(true);
    const kakaoAlertClose = () => {
        setKakaoAlert(false);
        dispatch(
            kakaoNotInstallActions.openAlert({
                open: false
            })
        );
    };

    // 동반자 초대방식 선택 팝업
    const [InviteOpen, setInviteOpen] = useState(false);
    const handleInviteOpen = () => setInviteOpen(true);

    const onCloseAlert = () => {
        // 동반자 초대 모달창 오픈
        handleInviteOpen();
        kakaoAlertClose();
    };

    useEffect(() => {
        // '카카오톡에서 친구 초대' 후 앱 미설치 시 App에서 Error state 전송받아서 redux에 저장 -> state 변경 시 Alert 오픈
        if (kakaoNotInstall?.open) {
            kakaoAlertOpen();
        }
    }, [kakaoNotInstall]);

    const onClickSelfCheckInBtn = (e) => {
        handleOpen();
        appUtils.openWebViewModalPostMsg();
    };

    useEffect(() => {
        // 알림톡 '이용가이드' 버튼 클릭 후 진입 시 '셀프체크인 이용가이드' 팝업창 오픈
        if (joinSelfChInGuideAction) {
            handleOpen();
            localStorageUtil.remove('joinSelfChInGuideAction');
        }
    }, [joinSelfChInGuideAction]);

    useEffect(() => {
        if (history.location?.state === 'post') {
            onChangeTab({
                value: 'post',
                includeMember: 'false'
            });
        } else if (history.location?.state === 'cancel') {
            onChangeTab({
                value: 'cancel',
                includeMember: 'false'
            });
        }
    }, []);

    const setNoDataTxt = (state) => {
        if (state === 'pre') {
            // 예약정보 조회 이미 동의 했으면 원래 문구, 미동의 유저는 문구 수정 및 '외부예약 불러오기' 버튼 노출
            if (member?.isBookingCheckAgree) {
                return (
                    <>
                        <p className="bigTxt">
                            현재 예정된 라운드가 없습니다.
                            <br />
                            지금 새로운 예약을 진행해보세요!
                        </p>
                        <div className="btnArea">
                            <Link to="/" className="btnPrimary medium">
                                골프장 찾아보기
                            </Link>
                        </div>
                    </>
                );
            } else {
                return (
                    <>
                        <p className="bigTxt">
                            골라가에서 예약된 정보가 없습니다.
                            <br />
                            지금 티타임을 예약하시거나
                            <br />
                            외부예약 불러오기를 이용해보세요!
                            <br />
                        </p>
                        <div className="btnArea">
                            <Link to="/" className="btnGrayLine04 medium">
                                티타임 예약하기
                            </Link>
                            <Link to="/get-my-reservation" onClick={onClickViewDetail} className="btnGrayLine04 medium">
                                외부예약 불러오기
                            </Link>
                        </div>
                    </>
                );
            }
        } else if (state === 'post') {
            return (
                <>
                    <p className="bigTxt">
                        이용하신 라운드 내역이 없습니다.
                        <br />
                        지금 새로운 예약을 진행해보세요!
                    </p>
                    <div className="btnArea">
                        <Link to="/" className="btnPrimary medium">
                            골프장 찾아보기
                        </Link>
                    </div>
                </>
            );
        } else if (state === 'cancel') {
            return (
                <>
                    <i className="icon">
                        <Icon name="caution" viewBox="0 0 60 60" width="60px" height="60px" fill="#F0F1F4" />
                    </i>
                    <p>취소하신 티타임 내역이 없습니다.</p>
                </>
            );
        }
    };

    return (
        <>
            <Header
                back={true}
                backLink={isDetailCourseGuide === 'DetailCourseGuide' ? -1 : `/mypage`}
                pageTitle="예약내역"
            />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div className="contentsArea" id="bookingList">
                    <div className="tabType01">
                        <ul>
                            <li className={`tab ` + (status.value === 'pre' && 'on')}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        onChangeTab({
                                            value: 'pre',
                                            includeMember: 'true'
                                        });
                                    }}
                                >
                                    예정 라운드
                                </button>
                            </li>
                            <li className={`tab ` + (status.value === 'post' && 'on')}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        onChangeTab({
                                            value: 'post',
                                            includeMember: 'false'
                                        });
                                    }}
                                >
                                    지난 라운드
                                </button>
                            </li>
                            <li className={`tab ` + (status.value === 'cancel' && 'on')}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        onChangeTab({
                                            value: 'cancel',
                                            includeMember: 'false'
                                        });
                                    }}
                                >
                                    취소 라운드
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div className="rezListWrap" ref={myRef}>
                        {/* 카드 리스트 (전체 껍데기)*/}
                        {isLoading ? (
                            <Loading />
                        ) : bookingData?.length ? (
                            bookingData?.map((data, idx) => {
                                return (
                                    <BookingCardList
                                        bookingData={data}
                                        bookingList={bookingData}
                                        status={status.value}
                                        hasDisabledAnimation={hasDisabledAnimation}
                                        setHasDisabledAnimation={setHasDisabledAnimation}
                                        key={idx}
                                        setBookingListReset={setBookingListReset}
                                        setMemberReset={setMemberReset}
                                        getDetailData={getDetailData}
                                        onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                                    />
                                );
                            })
                        ) : (
                            <div className="noDataList">{setNoDataTxt(status?.value)}</div>
                        )}
                    </div>
                </div>
            </div>

            {/*지도 Drawer*/}
            <SearchMapDrawer
                mapDrawerOpen={mapDrawerOpen}
                setMapDrawerOpen={setMapDrawerOpen}
                detailData={detailData}
            />

            {/* 카카오톡 초대완료 후 확인 Alert */}
            <CommonAlert successCallBack={handleCommonAlertClose} />

            {/* 카카오톡 미설치 시 확인 Alert */}
            <Modal open={kakaoAlert} onClose={kakaoAlertClose}>
                <div className="defaultAlert">
                    <div id="alertConWrap">
                        <div id="alertTitle">서비스 안내</div>
                        <div className="alertContents">
                            카카오톡 앱이 설치되어 있지 않습니다.
                            <br /> 앱을 설치하시겠습니까?
                        </div>
                    </div>
                    <div className="alertBtnArea">
                        <button type="button" className="button cancel" onClick={onCloseAlert}>
                            취소
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                kakaoAlertClose();
                                // 카카오톡 설치 페이지로 이동
                                redirectStore();
                            }}
                        >
                            확인
                        </button>
                    </div>
                </div>
            </Modal>

            {/* 셀프체크인 가이드 Layer */}
            <SelfCheckInGuideLayer handleOpen={selfCheckInGuideLayerOpen} handleClose={handleClose} />
            {/* 알림톡 통해 진입 시 예약 확인 불가 Alert */}
            <DefaultAlert
                open={alertOpen}
                handleClose={alarmErrorAlertClose}
                // title={isMessage}
                contents={alarmMessage}
            />
        </>
    );
}

export default Booking;
