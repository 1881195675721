import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

const { Kakao } = window;

const Profile = () => {
    const [user_id, setUserId] = useState();
    const [nickName, setNickName] = useState();
    const [profileImage, setProfileImage] = useState();
    const getProfile = async () => {
        try {
            // Kakao SDK API를 이용해 사용자 정보 획득
            if (Kakao.API == undefined) {
                Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
            }
            let data = await Kakao?.API?.request({
                url: "/v2/user/me"
            });
            // 사용자 정보 변수에 저장
            setUserId(data.id);
            setNickName(data.properties.nickname);
            setProfileImage(data.properties.profile_image);
        } catch (err) {
            // console.log(err);
        }
    };
    useEffect(() => {
        getProfile();
    }, []);

    const navigate = useNavigate();

    const kakaoLogout = async () => {
        try {
            if (!Kakao.Auth.getAccessToken()) {
                return;
            }
            Kakao.Auth.logout(function () {
                // console.log(Kakao.Auth.getAccessToken());
            });
            navigate("/member");
        } catch (err) {
            // console.log(err);
        }
    };

    const kakaoUnlink = async () => {
        try {
            Kakao.API.request({
                url: "/v1/user/unlink",
                success: function (res) {
                    alert("success: " + JSON.stringify(res));
                },
                fail: function (err) {
                    alert("fail: " + JSON.stringify(err));
                }
            });
            navigate("/member");
        } catch (err) {
            // console.log(err);
        }
    };

    return (
        <>
            <div>
                <h2>{user_id}</h2>
                <h2>{nickName}</h2>
                <img src={profileImage}></img>
            </div>
            <hr />
            <div>
                <button className="api-btn" onClick={kakaoLogout}>
                    로그아웃
                </button>
                <button className="api-btn" onClick={kakaoUnlink}>
                    앱 연결끊기
                </button>
            </div>
        </>
    );
};
export default Profile;
