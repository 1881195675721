import React from "react";
import TitleArea from "../../common/TitleArea";
import Header from "components/Header";
import JoinServiceAgree from "components/agreement/member/JoinServiceAgree";

function ServiceAgree({ title }) {
    return (
        <>
            <Header close={true} />
            <div id="contents">
                <div id="agreeContents" className="contentsArea">
                    <div className="inner">
                        <TitleArea pageTitle={title} />
                        <div className="agreeCon">
                            <JoinServiceAgree />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServiceAgree;
