import React from 'react';
import {useInView} from 'react-intersection-observer';

function CourseInfo({data}) {
    const {ref, inView} = useInView({
        threshold: 1
    });

    return (
        <>
            <div className={`courseDetail ${inView ? 'afterHide' : ''}`}>
                <div className="courseImgArea">
                    {data?.imgUrl ? (
                        <img src={data?.imgUrl} alt={data?.name} />
                    ) : (
                        <div className="courseNoImg">
                            <p>
                                해당 골프장은 제휴 진행중입니다.
                                <br />
                                빠른 시일내에 제휴될 수 있도록
                                <br />
                                코스가이드를 많이 활용해 보세요!
                            </p>
                        </div>
                    )}
                </div>
                <div className="courseInfo">
                    <h3>
                        {data?.courseName} ({data?.holeNo})
                    </h3>
                    <p>{data?.description}</p>
                    <div className="locationRef" ref={ref}></div>
                </div>
            </div>
        </>
    );
}

export default React.memo(CourseInfo);
