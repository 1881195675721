import Modal from '@mui/material/Modal';
import {setNotWatchingTodayExpires} from 'common/notWatchingTodayUtils';
import {useEffect, useState} from 'react';
import SwiperCore, {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

SwiperCore.use([Pagination]);

function GolfClubNoticeModal(props) {
    const {open, handleClose, golfclubId, golfClubNoticeData} = props;

    const noticeName = 'golfclub_detail_modal_expires';

    // 오늘 하루 보지 않기 > 로컬스토리지 세팅
    const onClickExpiresClose = () => {
        setNotWatchingTodayExpires(noticeName, golfclubId);
        handleClose();
    };

    const [linkStyle, setLinkStyle] = useState(false);
    useEffect(() => {
        if (golfClubNoticeData && open) {
            setTimeout(() => {
                let modalContents = document.getElementsByClassName('modalContents');

                if (modalContents.length > 0) {
                    let selectedLink = modalContents[0].getElementsByTagName('a');
                    if (selectedLink) {
                        for (let i = 0; i < selectedLink.length; i++) {
                            const item = selectedLink[i];
                            item.style.cssText = 'color:blue; text-decoration:underline;';
                            const url = item.getAttribute('href');
                            item.onclick = (e) => {
                                e.preventDefault();
                                movePage(url);
                            };
                        }
                        setLinkStyle(true);
                    }
                }
            }, 100);
        }
    }, [golfClubNoticeData, open, linkStyle]);

    const movePage = (url) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    inappurl: url,
                    type: 'openInappBrowser',
                    inapptitle: '골라가'
                })
            );
        } else {
            window.location.href = url;
        }
    };

    return (
        <>
            <Modal open={open} className="lowZIndex">
                <div className="defaultModal noticeModal">
                    <div className="modalContents">
                        <div id="modalTitle">안내 드립니다</div>
                        <div className="noticeCon">
                            <Swiper loop={true} pagination={{clickable: true}} className="noticeSwiper">
                                {golfClubNoticeData?.length > 0 &&
                                    golfClubNoticeData?.map((data) => {
                                        return (
                                            <SwiperSlide key={data?.id} className="list">
                                                <div>[{data?.title}]</div>
                                                <br />
                                                <div dangerouslySetInnerHTML={{__html: data?.content}}></div>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>
                    </div>
                    <div className="modalBtnArea">
                        <button type="button" onClick={onClickExpiresClose}>
                            오늘 하루 보지 않기
                        </button>
                        <button type="button" onClick={handleClose}>
                            닫기
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default GolfClubNoticeModal;
