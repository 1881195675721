import React from 'react';
import {useLocation} from "react-router-dom";

function Redirect() {
    const location = useLocation();
    const url = location.state.url

    if (location) window.location.href = url;

    return null;
}

export default Redirect;