import React, {useEffect, useState} from 'react';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import {fetchWrapper, history} from '../../_helpers';
import './membership.scss';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import qs from 'qs';
import axios from 'axios';
import {authActions, userActions} from '../../_store';
import DefaultAlert from '../alert/DefaultAlert';
import {errMsg} from '../../common/messages';

const {Kakao} = window;

function JoinKakao({title}) {
    //디바이스 높이값 체크
    const {height} = useWindowDimensions();
    const [userEmail, setUserEmail] = useState('');
    const [joinDate, setJoinDate] = useState('');
    const [duplicated, setDuplicated] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [joinAlready, setJoinAlready] = useState(false);
    const [showHeader, setShowHeader] = useState(true);
    const [payload, setPayload] = useState({});
    const [open, setOpen] = useState(false);
    const [alertData, setAlertData] = useState({
        title: '',
        description: ''
    });

    //하단 버튼
    const defaultPage = () => {
        history.navigate('/member');
    };
    const setComplete = () => {
        // history.navigate("/member/join/sns/complete");
        fetchWrapper.post(`/member/join/kakao`, payload).then((response) => {
            if (response?.code === '0000') {
                setDuplicated(false);
                setCompleted(true);
                setShowHeader(false);
            }
        });
    };

    const login = () => {
        // history.navigate("/reservation-agree");
        history.navigate('/member/login');
    };

    const onClickNextPage = (path) => {
        history.navigate(path);
    };

    // const existingId = () => {
    //     history.navigate('/member/existingId/kakao');
    // };

    const dispatch = useDispatch();
    const code = new URL(window.location.href).searchParams.get('code');
    const error = new URL(window.location.href).searchParams.get('error');
    // const error_description = new URL(window.location.href).searchParams.get('error_description');

    if (Kakao.API === undefined) {
        Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
    const navigate = useNavigate();
    const handleClose = () => {
        setOpen(false);
        // 카카오 로그인 실패시 뜨는 창이므로 로그아웃 처리시켜버림
        dispatch(userActions.initUser());
        dispatch(authActions.initToken());
        dispatch(authActions.logout());
        // navigate('/member');
    };

    const getToken = async () => {
        const payload = qs.stringify({
            grant_type: 'authorization_code',
            client_id: process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY,
            // redirect_uri: process.env.REACT_APP_KAKAO_REDIRECT_URI,
            code: code,
            client_secret: process.env.REACT_APP_KAKAO_CLIENT_SECRET
        });
        try {
            // access token 가져오기
            const res = await axios.post('https://kauth.kakao.com/oauth/token', payload);

            if (res && res?.data) {
                // Kakao Javascript SDK 초기화
                // Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
                // access token 설정
                Kakao.Auth.setAccessToken(res?.data?.access_token);
                // navigate("/auth/kakao/profile");
                let data = await Kakao?.API?.request({
                    url: '/v2/user/me'
                });

                dispatch(
                    authActions.login({
                        username: data.kakao_account.email,
                        password: `${data.id}`,
                        channel: 'KAKAO'
                    })
                ).then((result) => {
                    if (result.type === 'auth/login/fulfilled') {
                        // 카카오 계정으로 기존에 탈퇴한 이력이 있는 이메일인 경우

                        if (result.payload.code === '8015') {
                            setAlertData({
                                title: '가입안내',
                                description: errMsg.USER_ID_WITHDRAWAL_CHECK
                            });
                            // 탈퇴된 계정인 경우
                            onClickNextPage('/member/left/kakao');
                            return setOpen(true);
                        }
                        if (result.payload.code === '8000') {
                            //가입가능한 계정 8000
                            const payload = {
                                email: data.kakao_account?.email.trim().toLowerCase(),
                                name: data.kakao_account?.name,
                                nickname: data.properties?.nickname || data.kakao_account?.name,
                                cellPhone:
                                    data.kakao_account?.has_phone_number === false
                                        ? ''
                                        : '0' + data.kakao_account?.phone_number.replace(/^\+.*\s/, ''),
                                password: `${data.id}`,
                                gender: data.kakao_account?.gender.toUpperCase(),
                                birthdate:
                                    data.kakao_account?.birthyear +
                                    '-' +
                                    data.kakao_account?.birthday.substring(0, 2) +
                                    '-' +
                                    data.kakao_account?.birthday.substring(2),
                                userCI: data.kakao_account?.ci,
                                profileImageURL: data?.kakao_account?.profile?.profile_image_url,
                                memberChannel: 'KAKAO'
                            };
                            setPayload(payload);
                            if (!payload.cellPhone) {
                                console.log('해당 카카오 계정은 본인 명의의 휴대전화정보가 저장되지 않는 계정입니다.');
                                // 본인인증 회원가입 영역에 페이로드값을 전달해서 디폴트 추가할것
                                return navigate('/member/join', {state: payload});
                            }
                            fetchWrapper.post(`/member/join/kakao`, payload).then((response) => {
                                if (response?.code === '0000') {
                                    console.log('member join email , kakao 이메일 가입완료');
                                    setDuplicated(false);
                                    setCompleted(true);
                                    setShowHeader(false);
                                } else if (response?.code === '5000') {
                                    if (response?.data?.channel?.length > 0) {
                                        if (response.data.channel === 'KAKAO') {
                                            //이미 연동된 계정이 있습니다.
                                            // setJoinAlready(true);
                                            // setUserEmail(response.data.email);
                                            // setJoinDate(response.data.createdAt);
                                            return navigate('/member/found/kakao', {state: response.data});
                                        }
                                    } else {
                                        setDuplicated(true);
                                        setUserEmail(response.data.email);
                                        setJoinDate(response.data.createdAt);
                                    }
                                }
                            });
                        }
                        if (result.payload.code === '0000') {
                            console.log('0000');
                            return false;
                        }
                        if (result.payload.code === '8010') {
                            // 가입 경로가 다르다
                            console.log('8010');
                            // 탈퇴된 계정인 경우
                            onClickNextPage('/member/existingId/kakao');
                            return false;
                        }
                    }
                });
            } else {
                // console.log(res);
            }
        } catch (err) {
            console.log('err , [token] 에러 ', err);
            dispatch(authActions.initToken());
            dispatch(userActions.initUser());
            dispatch(authActions.logout());
        }
    };
    useEffect(() => {
        if (error) {
            navigate('/member');
        }
        if (code) {
            // if (Kakao.API == undefined) {
            //     Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
            // }
            // if (Kakao.Auth.getAccessToken()) {
            //     navigate("/auth/kakao/profile");
            // } else {
            //     getToken();
            // }
            getToken();
        }
    }, []);

    return (
        <>
            <Header back={showHeader} />
            {duplicated && (
                <div id="contents" style={{minHeight: `${height}px`}}>
                    <div className="inner">
                        <form>
                            <TitleArea
                                pageTitle={title}
                                titleTxt={'연동하시면 기존 회원정보 그대로 \n서비스 이용이 가능합니다'}
                            />
                            <div className="resultIdInfoArea">
                                <div className="resultIdInfo">{userEmail && <p>{userEmail}</p>}</div>
                                {joinDate && <span className="date">가입날짜: {joinDate}</span>}
                            </div>
                            <div className="btnFixedBottom btnFullWidth">
                                <div className="inner">
                                    <Button type="button" onClick={defaultPage} secandary>
                                        취소
                                    </Button>
                                    <Button type="button" onClick={setComplete}>
                                        카카오 계정과 연동
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {completed && (
                <div id="contents" style={{minHeight: `${height}px`}}>
                    <div className="inner">
                        <form>
                            <TitleArea
                                pageTitle={'카카오 연동 완료'}
                                titleTxt={'이제 카카오 계정으로 서비스를 \n이용하실 수 있습니다'}
                            />
                            <div className="btnFixedBottom">
                                <div className="inner">
                                    <Button type="button" onClick={login}>
                                        메인 화면으로 가기
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {joinAlready && (
                <div id="contents" style={{minHeight: `${height}px`}}>
                    <div className="inner">
                        <form>
                            <TitleArea
                                pageTitle={'카카오 연동 오류'}
                                titleTxt={'아래의 이메일 계정에 \n이미 카카오 계정 연동이 완료되었습니다'}
                            />
                            <div className="resultIdInfoArea">
                                <div className="resultIdInfo">{userEmail && <p>{userEmail}</p>}</div>
                                {joinDate && <span className="date">가입날짜: {joinDate}</span>}
                            </div>
                            <div className="btnFixedBottom btnFullWidth">
                                <div className="inner">
                                    <Button type="button" onClick={login}>
                                        로그인 화면으로 이동
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {!duplicated && !completed && !joinAlready && (
                <div id="contents" style={{minHeight: `${height}px`}}>
                    <div className="inner">
                        <form>
                            <TitleArea
                                pageTitle={'카카오 연동 처리중'}
                                titleTxt={'카카오 간편 로그인 처리 중입니다.'}
                            />
                        </form>
                    </div>
                </div>
            )}
            {/* 탈퇴한 계정일 경우 alert 표출  */}
            <DefaultAlert
                open={open}
                handleClose={handleClose}
                title={alertData.title}
                contents={alertData.description}
            />
        </>
    );
}

export default JoinKakao;
