import {Navigate, useLocation, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {fetchWrapper, history} from '_helpers';
import {getCookie, removeCookie} from '../common/cookieUtils';
import {authActions, store, userActions} from '../_store';
import {localStorageUtil} from '../common/utils';

export {PrivateRoute};

function PrivateRoute({children}) {
    const dispatch = useDispatch();
    const accessToken = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');

    if (!localStorageUtil.get('user') && accessToken && refreshToken) {
        localStorageUtil.set('user', JSON.stringify({accessToken, refreshToken}));
        removeCookie('accessToken');
        removeCookie('refreshToken');
        store.dispatch(authActions.refresh());
        store.dispatch(userActions.getAll());
    }
    const {user: authUser} = useSelector((x) => x.auth);
    const users = useSelector((x) => x?.users);

    if (!authUser) {
        // 로그인 페이지로 이동
        console.info('로그인 페이지로 이동');
        const jump = localStorageUtil.get('jump');

        if (jump) {
            localStorageUtil.remove('jump');
            return <Navigate to="/member" state={{from: jump}} />;
        }

        return <Navigate to="/member" state={{from: history.location}} />;
    } else {
        if (!users || !users.users) {
            dispatch(userActions.getAll());
        }
    }

    // authorized so return child components
    return children;
}
