import React, {useCallback, useEffect, useState} from 'react';
import {fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE} from '../../../common/constants';
import BoardDetail from '../../../_view/board/BoardDetail';
import '../../../_view/board/board.scss';

function AppBoardDetail({boardId}) {
    const [detailData, setDetailData] = useState(null);

    const getBoardDetail = useCallback(() => {
        fetchWrapper.get(`/notice/detail?boardId=${boardId}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                let data = response?.data?.detail;
                setDetailData(data || null);
            } else {
                console.log(response);
            }
        });
    }, [boardId]);

    useEffect(() => {
        getBoardDetail();
    }, [getBoardDetail]);

    return <BoardDetail data={detailData} />;
}

export default AppBoardDetail;
