import React from "react";

function AgencyPolicyLGT() {
    return (
        <>
            <section>
                <h2>&lt;LGU+ 본인확인서비스 이용약관&gt;</h2>
            </section>
            <section>
                <h2>제 1 조 (목적)</h2>
                <p>
                    본 약관은 주민번호를 대체한 휴대폰 본인확인서비스(이하
                    “서비스”)를 제공하는 LG유플러스(이하 “회사”)와 이용
                    고객(이하 “이용자”)간에 서비스 제공에 관한 이용조건 및 절차
                    등 기타 필요한 사항을 정함을 목적으로 합니다.
                </p>
            </section>
            <section>
                <h2>제 2 조 (용어의 정리)</h2>
                <p>
                    본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                    <br />
                    1. “휴대폰 본인확인서비스””라 함은 이용자가 인터넷상에서
                    본인 명의의 휴대폰을 이용하여 주민번호를 입력하지 않고
                    본인임을 안전하게 식별 및 확인하는 방법을 제공하는 서비스를
                    말합니다.
                    <br />
                    2. “이용자”라 함은 서비스의 이용을 위해 자신의
                    본인확인정보를 회사, 인증대행사 및 타 본인확인기관 등에게
                    제공하고, 본인임을 확인 받고자 하는 자를 말합니다.
                    <br />
                    3. “본인확인정보”라 함은 이용자가 입력한 생년월일, 성별,
                    성명, 내/외국인, 휴대폰번호, 통신사 등 본인 식별에 필요한
                    이용자의 정보를 말합니다.
                    <br />
                    4. “접근매체”라 함은 모바일 통신 단말기(피쳐폰, 스마트폰)를
                    지칭한다.
                    <br />
                    5. “중복가입확인정보(DI)”라 함은 웹사이트에 가입하고자 하는
                    이용자의 중복확인을 위해 제공되는 정보를 말합니다.
                    <br />
                    6. “연계정보(CI)”라 함은 인터넷사업자의 온ᆞ오프라인 서비스
                    연계 등의 목적으로 이용자를 식별하기 위해 제공되는 정보를
                    말합니다.
                    <br />
                    7. “본인확인기관”이라 함은 주민등록번호를 사용하지 아니하고
                    본인을 확인하는 방법 또는 서비스를 제공하는 자로
                    방송통신위원회로부터 본인확인기관으로 지정을 받은 자를
                    의미합니다.
                    <br />
                    8. “인증대행사”이라 함은 회사를 대신하여 서비스의 제공 및
                    지원 등의 중계 업무를 담당하는 곳으로 회사와 업무 지원에
                    대한 계약이 완료되어 인터넷사업자에서 서비스를 제공하는
                    사업자를 말합니다.
                    <br />
                    9. “인터넷사업자”라 함은 인터넷을 이용하여 정보를
                    제공하거나, 정보의 제공을 매개하는 일을 업으로 하는 자로
                    회사 또는 인증 대행사와의 서비스 계약을 통해 운영하며,
                    인터넷 웹사이트의 이용자에 대한 본인확인정보를 제공 받는
                    사업자를 말합니다.
                </p>
            </section>
            <section>
                <h2>제 3 조 (약관의 효력 및 변경)</h2>
                <p>
                    1. 본 약관은 이용자에게 서비스 화면에 게시하거나, 회사
                    홈페이지(www.uplus.co.kr)에 게시하여 공지함으로써 효력이
                    발생합니다.
                    <br />
                    2. 회사는 약관의 규제에 관한 법률 및 기타 관련 법령에
                    위배되지 않는 범위에서 본 약관의 내용을 개정할 수 있으며,
                    변경된 경우에는 제1항과 같은 방법으로 공지합니다. 다만
                    ‘이용자’의 권리와 의무에 관한 중요한 사항은 변경된 내용의
                    시행 15일 이전에 공지합니다.
                    <br />
                    3. 이용자는 변경된 약관에 대한 내용을 알지 못하여 발생하는
                    손해 및 피해에 대해서는 회사가 책임을 지지 않습니다.
                </p>
            </section>
            <section>
                <h2>제 4 조 (접근매체의 관리 등)</h2>
                <p>
                    1. 이용자는 접근매체를 제3자에게 대여하거나 사용을
                    위임하거나 양도 또는 담보 목적으로 제공할 수 없습니다.
                    <br />
                    2. 이용자는 자신의 접근매체를 제3자에게 제공 또는 노출하거나
                    방치하여서는 안되며, 접근매체의 도용이나 위ᆞ 변조를 방지하기
                    위해 충분한 주의를 기울여야 합니다.
                </p>
            </section>
            <section>
                <h2>제 5 조 (서비스 이용방법)</h2>
                <p>
                    1. 서비스는 본인명의의 휴대폰을 소지하고 이동전화 서비스를
                    정상적으로 계속 이용하고 있는 이용자에 한하여 서비스를
                    제공합니다. {"{"}회사의 ‘이동전화이용약관’상 이용 정지(제한
                    포함), 일시 정지, 계약해지(개통취소 포함) 상태인
                    이용자에게는 제공 되지 않습니다.{"}"}.<br />
                    2. 서비스는 이용자가 주민등록번호를 입력하지 아니하고 본인의
                    생년월일과 본인 명의로 된 휴대폰정보를 이용하여 본인 식별
                    또는 본인 확인이 가능하도록 하는 휴대폰인증 서비스 입니다.
                    <br />
                    3. 회사는 인증대행사를 통해 인터넷사업자에게 서비스를
                    제공하며, 인터넷사업자는 회원가입, ID/PW찾기, 성인 인증 등
                    이용자의 본인확인이 필요한 경우 이용자에게 서비스를 제공
                    합니다.
                    <br />
                    4. 제2항의 경우 이용자가 자신의 생년월일, 성명, 성별,
                    내/외국인, 휴대폰번호, 통신사 등의 정보를 입력한 후 입력한
                    정보가 이용자 본인의 정보와 일치한 경우에는 이용자 본인
                    명의의 휴대폰번호로 수신된 1회성 비밀번호(이하 “승인번호”)를
                    정확하게 입력하면 본인 식별 또는 본인 확인이 이루어 집니다.
                    <br />
                    5. 제3항과 같이 본인확인이 완료 된 이용자에 대해서는
                    본인확인정보와 중복가입확인정보 및 연계정보가 인터넷
                    사업자에게 제공되며, 인터넷사업자가 중복가입확인정보 또는
                    연계정보 등을 이용하여 이용자 관리 및 컨텐츠를 제공 운영
                    합니다.
                </p>
            </section>
            <section>
                <h2>제 6 조 (서비스 제공시간)</h2>
                <p>
                    1. 회사는 연중무휴 1일 24시간 서비스를 제공함을 원칙으로
                    합니다. 다만, 회사는 서비스 설비의 장애, 서비스 이용의 폭주
                    등 기술상의 이유로 서비스를 일시 정지할 수 있고, 서비스 설비
                    정기 점검 등 운영상의 목적으로 시간을 정하고 공지한 후
                    서비스를 일시 정지할 수 있습니다.
                </p>
            </section>
            <section>
                <h2>제 7 조 (회사의 권리와 의무)</h2>
                <p>
                    1. 회사가 접근매체의 발급주체가 아닌 경우에는 접근매체의
                    위조나 변조로 발생한 사고로 인하여 이용자에게 발생한 손해에
                    대하여 배상책임이 없습니다.
                    <br />
                    2. 회사는 이용자가 서비스 이용약관이나 안내사항 등을
                    확인하지 않아 발생한 손해, 이용자에게 책임있는 사유로
                    접근매체를 누설 또는 노출하거나 방치한 손해 등 이용자의
                    부주의에 기인한 손해에 대하여 배상책임이 없습니다.
                    <br />
                    3. 회사의 이용자에 대한 손해배상 책임범위는 통상손해에
                    한합니다.
                    <br />
                    4. 회사는 서비스 제공시 접근매체를 이용하여 이용자의 신원,
                    권한 및 거래지시의 내용 등을 확인할 수 있습니다.
                    <br />
                    5. 회사는 서비스 제공과 관련하여 인지한 이용자의
                    본인확인정보를 본인의 승낙 없이 제3자에게 누설하거나
                    제공하지 않습니다. 단, 국가기관의 요구가 있는 경우, 범죄에
                    대한 수사상의 목적이 있는 경우 등 기타 관계 법령에서 정한
                    절차에 따른 요청이 있는 경우에는 그러하지 않습니다.
                    <br />
                    6. 회사는 이용자에게 안정적으로 서비스를 제공하기 위하여
                    지속적으로 서비스의 예방점검, 유지보수 등을 이행하며 서비스
                    장애가 발생하는 경우 지체없이 서비스를 복구합니다.
                    <br />
                    7. 회사는 복제폰, 대포폰, 불법 휴대폰 대출(일명 휴대폰깡) 등
                    시장질서를 교란시키는 불법행위로 의한 피해를 방지하기 위하여
                    사전통지 없이 서비스를 제한하거나 중지할 수 있습니다.
                </p>
            </section>
            <section>
                <h2>제 8 조 (이용자의 의무)</h2>
                <p>
                    1. 이용자는 서비스를 이용함에 있어서 다음 각호에 해당하는
                    행위를 하여서는 안되며, 회사는 위반 행위에 따르는 일체의
                    법적 책임을 지지 않습니다.
                    <br />
                    <span className="circleNum">①</span> 타 이용자의
                    본인확인정보 및 승인번호를 부정하게 사용 및 도용하는 행위
                    <br />
                    <span className="circleNum">②</span> 회사 또는 제3자의
                    지식재산권 등 기타 권리를 침해하는 행위
                    <br />
                    <span className="circleNum">③</span> 범죄 행위 및 범죄적
                    행위와 관련있는 행위
                    <br />
                    <span className="circleNum">④</span> 관련 법령에 위배되는
                    행위
                    <br />
                    <span className="circleNum">⑤</span> 기타 서비스의 정상적
                    운영, 유지 등을 방해하거나 지연시키는 행위
                    <br />
                    2. 이용자는 본 약관에서 규정하는 사항과 서비스에 대한
                    이용안내 또는 주의사항 등을 준수하여야 합니다.
                    <br />
                    3. 이용자가 본 약관을 위반하여 회사에 손해가 발생한 경우에는
                    이용자가 모든 손해를 배상하여야 합니다.
                </p>
            </section>
            <section>
                <h2>제 9 조 (본인인증 정보의 제공금지)</h2>
                <p>
                    회사는 서비스를 제공함에 있어 취득한 이용자의 정보 또는
                    자료를 이용자의 동의 없이 제3자에게 제공, 누설하거나 서비스
                    목적 외에 사용하지 않습니다.
                </p>
            </section>
            <section>
                <h2>제 10 조 (서비스의 안정성 확보)</h2>
                <p>
                    1. 회사는 서비스의 안전성과 신뢰성을 확보하기 위하여
                    업무처리지침의 제정 및 시행, 정보 처리시스템 및 전산자료의
                    관리 등의 관리적 조치와 모니터링 체계 및 해킹방지시스템 구축
                    및 운영 등의 기술적 조치를 취해야 합니다.
                    <br />
                    2. 회사는 서버 및 통신기기의 정상작동여부 확인을 위하여
                    정보처리시스템 자원 상태의 감시, 경고 및 제어가 가능한
                    모니터링 체계를 갖추어야 합니다.
                    <br />
                    3. “회사”는 해킹 침해 방지를 위하여 다음 각 호의 시스템 및
                    프로그램을 설치하여 운영합니다.
                    <br />
                    <span className="circleNum">①</span> 침입차단시스템 설치
                    <br />
                    <span className="circleNum">②</span> 침입탐지시스템 설치
                    <br />
                    <span className="circleNum">③</span> 그 밖에 필요한 보호장비
                    또는 암호프로그램 등 정보보호시스템 설치
                    <br />
                    4. ‘회사’는 컴퓨터바이러스 감염을 방지하기 위하여 다음 각
                    호를 포함한 대책을 수립, 운영하고 있습니다.
                    <br />
                    <span className="circleNum">①</span> 출처, 유통경로 및
                    제작자가 명확하지 아니한 응용프로그램은 사용을 자제하고
                    불가피할 경우에는 컴퓨터바이러스 검색프로그램으로 진단 및
                    치료 후 사용할 것<br />
                    <span className="circleNum">②</span> 컴퓨터바이러스 검색,
                    치료 프로그램을 설치하고 최신 버전을 유지할 것<br />
                    <span className="circleNum">③</span> 컴퓨터바이러스 감염에
                    대비하여 방어, 탐색 및 복구 절차를 마련할 것
                </p>
            </section>
            <section>
                <h2>제 11 조 (이용자의 개인정보보호)</h2>
                <p>
                    회사는 관련법령이 정하는 방에 따라서 이용자의 개인정보를
                    보호하기 위하여 노력하며, 이용자의 개인정보에 관한 사항은
                    관련 법령 및 회사가 정하는 개인정보취급방침에 정한 바에
                    따릅니다.
                </p>
            </section>
            <section>
                <h2>제 12 조 (개인정보의 처리)</h2>
                <p>
                    1. 회사는 서비스 제공을 위하여 수집된 본인확인정보의 취급 및
                    관리 등의 업무를 스스로 수행함을 원칙으로 하나, 필요한 경우
                    아래 표와 같이 회사가 선정한 사업자에게 위탁할 수 있습니다.
                </p>
                <br />
                <p>[ 개인정보의 취급 위탁 ]</p>
                <table cellPadding="0" cellSpacing="0" className="agreeInfoTb">
                    <colgroup>
                        <col style={{ width: "40%" }} />
                        <col style={{ width: "60%" }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>수탁자</th>
                            <th>위탁업무내용</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>에스씨아이평가정보(주)</td>
                            <td>
                                연계정보, 중복가입확인정보 생성 및 전송
                                <br />
                                본인확인 서비스 중계 업무 제공
                            </td>
                        </tr>
                        <tr>
                            <td>한국모바일인증(주)</td>
                            <td>본인확인 서비스 중계 업무 제공</td>
                        </tr>
                        <tr>
                            <td>코리아크레딧뷰로(주)</td>
                            <td>본인확인 서비스 중계 업무 제공</td>
                        </tr>
                        <tr>
                            <td>NICE평가정보(주)</td>
                            <td>
                                연계정보, 중복가입확인정보 생성 및 전송
                                <br />
                                본인확인 서비스 중계 업무 제공
                            </td>
                        </tr>
                        <tr>
                            <td>(주) 다날</td>
                            <td>본인확인 서비스 중계 업무 제공</td>
                        </tr>
                        <tr>
                            <td>(주)NHN한국사이버결제</td>
                            <td>본인확인 서비스 중계 업무 제공</td>
                        </tr>
                        <tr>
                            <td>(주)드림시큐리티</td>
                            <td>본인확인 서비스 중계 업무 제공</td>
                        </tr>
                        <tr>
                            <td>KG모빌리언스</td>
                            <td>본인확인 서비스 중계 업무 제공</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    2. 회사는 서비스 제공시 인터넷사업자가 필요로 하는 이용자
                    식별정보(중복가입확인정보, 연계정보)의 생성 및 제공을 위하여
                    아래 표와 같이 다른 본인확인기관에게 본인확인정보를 제공할
                    수 있으며, 수집된 식별정보(중복가입 확인정보, 연계정보)는
                    본인 식별 및 확인 위한 목적으로 회사 또는 인증 대행사를 통해
                    인터넷사업자에게 제공할 수 있습니다.
                    <br />
                    <br />
                    3. 개인정보 처리 및 위탁 등에 관한 사항은 관련법령 및 회사가
                    정하는 개인정보취급방침에 정한 바에 따릅니다.
                </p>
            </section>
            <section>
                <h2>제 13 조 (약관 외 준칙)</h2>
                <p>
                    본 약관에 명시되지 아니한 사항에 대해서는 정보통신망 이용
                    촉진 및 정보보호 등에 관한 법률 등 기타 관련 법령 또는
                    상관례에 따릅니다.
                </p>
            </section>
            <section>
                <p>
                    [부칙]
                    <br />
                    (시행일) 이 약관은 공시한 날로부터 시행합니다.
                </p>
            </section>
        </>
    );
}

export default AgencyPolicyLGT;
