import React from 'react';
import ShareButton from '../../button/ShareButton';
import LikeButton from '../../button/LikeButton';
import {history} from '../../../_helpers';
import {smartRoundActions} from '_store/smartRound.slice';
import {useLocation} from 'react-router-dom';
import Icon from '../../icons/Icon';
import {useDispatch} from 'react-redux';

function OutdoorDetailHeader({
    clubId,
    detailData,
    pageTitle,
    interest,
    searchBackLink,
    shareStatus,
    golfClubName,
    setIsSnackBarOpen,
    handleShareOpen
}) {
    const location = useLocation();
    const dispatch = useDispatch();

    const goBackLink = () => {
        const stateData = {
            linkFrom: searchBackLink
        };

        if (searchBackLink === 'search' || searchBackLink === 'realTimeSearchList') {
            history.navigate(-1, {state: stateData});
        } else if (searchBackLink === 'main') {
            /*history.navigate('/main', {state: stateData});*/
            history.navigate(-1, {state: stateData});
        } else if (searchBackLink === 'mypage-favorites') {
            history.navigate(-1);
        } else if (searchBackLink?.includes('search-result')) {
            const splitWord = searchBackLink.split(' ');
            history.navigate(-1, {state: splitWord[1]});
        } else if (searchBackLink === 'bookingList') {
            history.navigate('/mypage/booking', {state: history.location?.state?.tab});
        } else if (searchBackLink === 'smartRoundBookingList') {
            history.navigate('/booking');
        } else if (searchBackLink === 'nearby') {
            history.navigate(-1);
        } else {
            history.navigate('/main');
        }
    };

    const onClickGoBackBtn = () => {
        goBackLink();
    };

    const onClickNoti = () => {
        let path = `${location.pathname}?golfClubId=${clubId}`;
        dispatch(smartRoundActions.setNoticeFrom({noticeFrom: path}));
        history.navigate(`/club-board/${clubId}`);
    };

    return (
        <>
            <div id="header" className="outdoorDetailHeader">
                <div className="inner headerWrap">
                    <div className="leftCon">
                        <button className="btnBack" onClick={onClickGoBackBtn}>
                            뒤로가기
                        </button>
                        {pageTitle ? <h1 className="pageTitle">{pageTitle}</h1> : null}
                    </div>
                    <div className="rightCon">
                        <button className="btn-noti" onClick={onClickNoti}>
                            <Icon name="icon-smart-round-noti" width="24" height="24" viewBox="0 0 24 24" fill="#000" />
                        </button>
                        <ShareButton handleShareOpen={handleShareOpen} />
                        <LikeButton
                            clubId={clubId}
                            interest={interest}
                            golfClubName={pageTitle}
                            setIsSnackBarOpen={setIsSnackBarOpen}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default React.memo(OutdoorDetailHeader);
