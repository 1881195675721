import React from "react";
import AgencyPolicySKT from "./AgencyPolicySKT";
import AgencyPolicyLGT from "./AgencyPolicyLGT";
import AgencyPolicyKTF from "./AgencyPolicyKTF";

function AgencyPolicy({ telecomCode }) {
    const type = telecomCode;

    if (type === "SKT" || type === "SKM") {
        return <AgencyPolicySKT />;
    } else if (type === "LGT" || type === "LGM") {
        return <AgencyPolicyLGT />;
    } else if (type === "KTF" || type === "KTM") {
        return <AgencyPolicyKTF />;
    }
}

export default AgencyPolicy;
