import {Fragment, forwardRef, useState} from 'react';
import AllCheckbox from 'components/button/AllCheckbox';
import PolicyLayer from 'components/membership/PolicyLayer';
import {appUtils} from 'common/utils';
const PaymentBookingPrivacyConsent = forwardRef((props, ref) => {
    const {prepareRoundBooking, allChecked, setAllChecked, checkList, setCheckList, golfClubName} = props;
    const {placeboInformation} = prepareRoundBooking;

    // 약관 동의 팝업
    const [agreeLayerTitle, setAgreeLayerTitle] = useState('');
    const [agreeType, setAgreeType] = useState('');
    const [agreementOpen, setAgreementOpen] = useState(false);

    // 골프장 규정 자세히보기 팝업 오픈 여부
    const agreementLayerOpen = (e) => {
        setAgreementOpen(true);
        setAgreeLayerTitle(e.target.dataset.title);
        setAgreeType(e.target.dataset.type);

        appUtils.openWebViewModalPostMsg();
    };

    const handleCheckboxChange = (type) => {
        const updatedCheckList = checkList.map((item) => {
            if (item.type === type) {
                return {...item, checked: !item.checked};
            }
            return item;
        });
        setCheckList(updatedCheckList);

        const isAllChecked = updatedCheckList.every((item) => item.checked);
        setAllChecked(isAllChecked);
    };
    const toggleAllCheck = () => {
        const updatedCheckList = checkList.map((item) => ({
            ...item,
            checked: !allChecked
        }));
        setCheckList(updatedCheckList);
        setAllChecked(!allChecked);
    };
    return (
        <Fragment>
            <div className="payment-booking-agree-wrapper agree-check-area collapse" ref={ref}>
                <div className="check-all">
                    <div className="checkLabel">
                        {/* ---------------ALL CHECK BTN--------------- */}
                        <AllCheckbox allChecked={allChecked} toggleAllCheck={toggleAllCheck} allCheckDisabled={false}>
                            골프장 규정 및 개인정보 이용 전체 동의
                        </AllCheckbox>
                    </div>
                </div>
                <ul className="agree-check-list agree-check-list-booking">
                    {/* -----------------CHECK LIST------------------- */}
                    {checkList.map((labelData, idx) => (
                        <li key={idx}>
                            <div className="checkLabel">
                                <label>
                                    <div className="customCheck">
                                        <input
                                            type="checkbox"
                                            onChange={() => handleCheckboxChange(labelData.type)}
                                            checked={checkList[idx].checked}
                                        />
                                        <i className={`icon ${checkList[idx].checked ? 'checked' : ''}`}>
                                            <svg
                                                width="100%"
                                                height="100%"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17 8L11.0525 15L7 10.9168"
                                                    stroke={checkList[idx].checked ? '#3906C3' : '#A0A4AF'}
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </i>
                                    </div>
                                    <span className={labelData.require ? '' : 'gray'}>
                                        {labelData.require ? '[필수] ' : '[선택] '}
                                    </span>
                                    {labelData.msg}{' '}
                                </label>
                                {labelData.type && (
                                    <button
                                        type="button"
                                        className="btn-ar"
                                        onClick={agreementLayerOpen}
                                        data-title={labelData.title}
                                        data-type={labelData.type}
                                    >
                                        자세히보기
                                    </button>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>

                {/* 개인정보 이용내용 동의사항 주의사항*/}
                <div className="payment-booking-caution-text">
                    * 상기 동의 사항은 예약하고자 하는 골프장의 서비스 이용을 위한 최소한의 정보로써, 미동의 시 예약이
                    불가합니다.
                </div>
            </div>
            {/* 골프장 규정 및 개인정보 이용 전체 동의 Layer*/}
            <PolicyLayer
                open={agreementOpen}
                agreeType={agreeType}
                title={agreeLayerTitle}
                handleClose={() => setAgreementOpen(false)}
                contents={placeboInformation}
                golfClubName={golfClubName}
            />
        </Fragment>
    );
});

export default PaymentBookingPrivacyConsent;
