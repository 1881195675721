import React from 'react';

function CheckInTop({checkInData, onResetBtn, isRefreshing, user}) {
    return (
        <div className="ttArea">
            {user?.checkinAt && (
                <>
                    <span className="num">예약 번호 : {checkInData?.gbNo}</span>
                    <div className="refreshArea">
                        {/* <span className="time">5분 전</span> */}
                        <button onClick={onResetBtn} className={`btnRefresh ${isRefreshing ? 'rotate' : ''}`}>
                            Refresh
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default React.memo(CheckInTop);
