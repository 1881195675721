import React, {useEffect, useState} from 'react';
import Drawer from '@mui/material/Drawer';
import Icon from '../icons/Icon';

const WebAccessInfoPaymentModal = (props) => {
    const {paymentModalOpen, setPaymentModalOpen} = props;
    const handleWebAccessInfoClose = () => {
        setPaymentModalOpen(false);
    };

    // 앱 이용하기 링크
    const URLPreFix = process.env.REACT_APP_DEEPLINK_PREFIX;
    const suggestAppURL = URLPreFix + process.env.REACT_APP_SUGGEST_APP_PREPAY_PATH;

    return (
        <Drawer
            anchor="bottom"
            open={paymentModalOpen}
            onClose={handleWebAccessInfoClose}
            className="defaultDrawer webAccessInfoModal"
        >
            <div className="drawer-content">
                <div className="drawerContents">
                    <div className="logoArea">
                        <i className="logoIcon">
                            <Icon name="logoIcon" width="78" height="78" viewBox="0 0 78 78" />
                        </i>
                        <strong className="logoTxt">golaga</strong>
                    </div>
                    <div className="txtCon">
                        <strong className="bigTxt">보다 안전한 결제 진행을 위해 </strong>
                        <strong className="bigTxt">골라가 앱을 설치해 주세요</strong>
                    </div>
                    <div className="btnWrap">
                        <a href={suggestAppURL} target="_blank" rel="noopener noreferrer" className="btnPrimary">
                            앱 이용하기
                        </a>
                        <button type="button" onClick={handleWebAccessInfoClose} className="btnAnchor">
                            괜찮아요. 모바일웹으로 볼게요.
                        </button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default WebAccessInfoPaymentModal;
