/* Package Import */
import {history} from '_helpers';
import {getAmplitudeUserInfo} from '_helpers/amplitude/amplitude.helper';
import amplitude from 'amplitude-js';
import axios from 'axios';
import LoadingGlobal from 'common/loading/LoadingGlobal';
import {isNil} from 'lodash';
import {useEffect, useState} from 'react';
import {useDeviceSelectors} from 'react-device-detect';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import './App.global.scss';
import AppRoutes from './AppRoutes';
import Firebase from './Firebase';
import RNListener from './_components/RNListener';
import {rootTaxonomy} from './_helpers/amplitude/taxonomy/root.taxonomy';
import {store} from './_store';
import {globalActions} from './_store/global.slice';
import AirbridgeUtil from './common/airbridgeUtil';
import ReadyAlert from './components/common/ReadyAlert';
import ScrollToTop from './components/common/ScrollToTop';
import AppUpdateModal from './components/modal/AppUpdateModal';
import * as Sentry from '@sentry/react';

window.config = {password: {minLength: 8}};

const setLocation = (url) => {
    if (sessionStorage.getItem('prevUrl') === null) {
        sessionStorage.setItem('prevUrl', url);
    } else {
        sessionStorage.setItem('prevUrl', sessionStorage.getItem('curUrl'));

        getUrlRootPath();
    }

    sessionStorage.setItem('curUrl', url);
};

const getUrlRootPath = () => {
    let prevPath = sessionStorage.getItem('prevUrl');

    if (prevPath === '/' || prevPath === '/main') {
        sessionStorage.setItem('root', rootTaxonomy.home);
    } else if (prevPath === '/search-result') {
        sessionStorage.setItem('root', rootTaxonomy.search_result);
    } else if (prevPath === '/mypage/favorites') {
        sessionStorage.setItem('root', rootTaxonomy.favorite);
    } else if (prevPath.includes('/search')) {
        sessionStorage.setItem('root', rootTaxonomy.search_favorite_tab);
    } else if (prevPath.includes('/field-detail')) {
        sessionStorage.setItem('root', rootTaxonomy.cc_detail);
    } else if (prevPath === '/mypage/booking') {
    } else if (prevPath === '/booking') {
        sessionStorage.setItem('root', rootTaxonomy.smartround);
    } else {
        sessionStorage.setItem('root', prevPath);
    }
};

function App() {
    const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);
    const isLoading = useSelector((x) => x.app.isLoading);

    // 임시 리다이렉션
    if (window.location.href.includes('main.golaga.golf')) {
        window.location.href = 'https://golaga.golf';
    }

    // 앱 강제 업데이트 체크
    const appUpdate = useSelector((x) => x?.global?.appUpdate);
    const appVersion = useSelector((x) => x?.global?.appVersion);
    const appDeviceInfo = useSelector((x) => x?.global?.deviceInfo);
    const amplitudeDeviceId = null;

    // if (process.env.REACT_APP_WEB_URL.includes('://golaga.golf') || process.env.REACT_APP_WEB_URL.includes('://stg.golaga.golf')) {
    //     if (!location.pathname.startsWith('/gate-')) {
    //         window.location.href = '/gate-page';
    //     }
    // }

    if (!window.Kakao?.isInitialized()) {
        window.Kakao?.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }

    let persistedUserInfo = '';
    // console.log(JSON.parse(localStorage.getItem('lastUser')));
    // if (localStorage.getItem('persist:root') !== null) {
    //     if (JSON.parse(localStorage.getItem('persist:root')).users !== null) {
    //         persistedUserInfo = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).users).users.email;
    //         console.log('persistedUserInfo', persistedUserInfo);
    //     }
    // }
    if (localStorage.getItem('lastUser')) {
        persistedUserInfo = JSON.parse(localStorage.getItem('lastUser')).email;
    }
    // amplitude initialize
    amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY, persistedUserInfo, {
        defaultTracking: {
            pageViews: {
                trackOn: () => {
                    return window.location.pathname.includes('member');
                }
            },
            sessions: true
        },
        // trackingOptions platform false
        trackingOptions: {
            platform: false
        }
    });

    AirbridgeUtil.syncAmplitude(amplitude.getInstance().getDeviceId());

    const user = localStorage.getItem('user');
    if (user) {
        // react-device-detect library userPlaform property 추가
        const {isMobileOnly, isAndroid, isIOS, isBrowser, isDesktop} = selectors;
        let userPlatform;

        if (isMobileOnly) {
            // IOS - Mobile
            // IOS - APP
            // Android - Mobile
            // Android - APP

            if (isAndroid) {
                if (window.ReactNativeWebView) {
                    userPlatform = 'Android - APP';
                } else {
                    userPlatform = 'Android - Mobile';
                }
            } else if (isIOS) {
                if (window.ReactNativeWebView) {
                    userPlatform = 'IOS - APP';
                } else {
                    userPlatform = 'IOS - Mobile';
                }
            } else {
                userPlatform = '';
            }
        } else {
            userPlatform = 'PC';
        }

        getAmplitudeUserInfo(userPlatform);
    }
    // 컨텐츠 최소 높이 디바이스 높이로
    const handleResize = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    //creating function to load ip address from the API
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/');
        window.visitInfo = res?.data;
    };

    useEffect(() => {
        // Firebase 초기화
        Firebase.initializeFirebase();
        Firebase.remoteConfig.initialize();

        store.dispatch(globalActions.getAppVersion());
        store.dispatch(globalActions.setDeviceInfo());

        //앱 버전 RN에서 받아옴
        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'appVersion'
            })
        );
        window.ReactNativeWebView?.postMessage(
            JSON.stringify({
                type: 'deviceInfo'
            })
        );

        getData();
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    history.navigate = useNavigate();
    history.location = useLocation();

    useEffect(() => {
        setLocation(history?.location?.pathname);
    }, [history.navigate]);

    const [kakaoAuthInfo, setKakaoAuthInfo] = useState({
        redirectUri: '',
        state: '',
        nonce: ''
    });

    useEffect(() => {
        const listener = (e) => {
            if (!e || typeof e?.data === 'object') {
                return;
            }
            try {
                let data = JSON.parse(e?.data);
                if (data && data?.type === 'login/kakao') {
                    debugger;
                    window.Kakao?.Auth?.authorize({
                        ...kakaoAuthInfo,
                        prompts: 'login',
                        redirectUri: `${process.env.REACT_APP_WEB_URL}/auth/kakao/callback`
                    });
                }
                return true;
            } catch (e) {
                return false;
            }
        };

        // RN에서 웹으로 데이터를 전송했을때 message이벤트가 실행됩니다.
        document.addEventListener('message', listener);
        window.addEventListener('message', listener);
        return () => {
            document.removeEventListener('message', listener);
            window.removeEventListener('message', listener);
        };
    });

    return (
        <Sentry.ErrorBoundary>
            <RNListener />
            <AppUpdateModal />

            {(isNil(appDeviceInfo) || (appVersion !== null && appUpdate.forceUpdate === false)) && (
                <>
                    <ScrollToTop />
                    <AppRoutes />
                    <ReadyAlert />
                </>
            )}
            {isLoading && <LoadingGlobal />}
        </Sentry.ErrorBoundary>
    );
}

export default App;
