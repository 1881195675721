import {useState} from 'react';
import {fetchWrapper} from '../../../_helpers';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import {clickCCHomepageLink} from '../../../_helpers/amplitude/events/click.event';
import {SUCCESS_CODE} from '../../../common/constants';
import {appUtils} from '../../../common/utils';
import DefaultAlert from '../../alert/DefaultAlert';
import NearbyClubSlider from './NearbyClubSlider';

function GolfClubDetail({
    detailData,
    nearbyClubData,
    agreementLayerOpen,
    onClickNearByClub,
    navigate,
    onClickSelfCheckInBtn
}) {
    const [penaltyClauseModal, setPenaltyClauseModal] = useState(false);
    const handlePenaltyClauseModal = () => setPenaltyClauseModal(false);
    const onClick = () => {
        setPenaltyClauseModal(true);
    };

    /* useEffect(() => {
        window.scrollTo(0, 0);
    }, [detailData]);*/

    const homepageOnClick = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    inappurl: detailData?.homepage,
                    type: 'openInappBrowser',
                    inapptitle: detailData?.golfClubShortName
                })
            );
        } else {
            window.location.href = detailData?.homepage;
        }

        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${detailData?.id}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                clickCCHomepageLink(response.data, getAmplitudeRootPath());
            }
        });
    };

    return (
        <>
            <section className="section02">
                <div className="titleArea03">
                    <h2>기본 정보</h2>
                </div>
                <div className="infoType05">
                    <dl>
                        <dt>주소</dt>
                        <dd>
                            <ul className="address">
                                <li>도로명 : {detailData?.addressLoad ? detailData?.addressLoad : '-'}</li>
                                <li>지번 : {detailData?.addressLocal ? detailData?.addressLocal : '-'}</li>
                            </ul>
                        </dd>
                    </dl>
                    <div className="gap_thin"></div>
                    <dl>
                        <dt>전화</dt>
                        <dd>{detailData?.phoneNumber ? detailData?.phoneNumber : '-'}</dd>
                    </dl>
                    <div className="gap_thin"></div>
                    <dl>
                        <dt>인근IC</dt>
                        <dd>{detailData?.aroundIc ? detailData?.aroundIc : '-'}</dd>
                    </dl>
                    <div className="gap_thin"></div>
                    <dl>
                        <dt>홈페이지</dt>
                        <dd>
                            {detailData?.homepage ? (
                                <button
                                    type="button"
                                    onClick={() => {
                                        homepageOnClick();
                                    }}
                                    className="link"
                                >
                                    {detailData?.homepage}
                                </button>
                            ) : (
                                '-'
                            )}
                        </dd>
                    </dl>
                    <div className="gap_thin"></div>
                    {/* 2023-04-14 mvp 부킹 > 골프장 정보> 7.골프장 소개 내 ‘고객센터’ 항목 제외 주석처리*/}
                    {/*<dl>*/}
                    {/*    <dt>고객센터</dt>*/}
                    {/*    <dd>{detailData?.customerServiceUrl ? detailData?.customerServiceUrl : '-'}</dd>*/}
                    {/*</dl>*/}
                    <dl>
                        <dt>운영제</dt>
                        <dd>{detailData?.businessType ? detailData?.businessType : '-'}</dd>
                    </dl>
                    <div className="gap_thin"></div>
                    <dl>
                        <dt>홀 정보</dt>
                        <dd>{detailData?.hole ? `${detailData?.hole}홀` : '-'}</dd>
                    </dl>
                    <div className="gap_thin"></div>
                    <dl>
                        <dt>코스구성</dt>
                        <dd>{detailData?.courseName ? detailData?.courseName : '-'}</dd>
                    </dl>
                    <div className="gap_thin"></div>
                    <dl>
                        <dt>위약규정</dt>
                        <dd>
                            {/*<dd>*/}
                            {/*    <button type="button" onClick={agreementLayerOpen} className="link">*/}
                            {/*        상세보기*/}
                            {/*    </button>*/}
                            {/*</dd>*/}
                            <button
                                type="button"
                                onClick={detailData?.isPartner ? agreementLayerOpen : onClick}
                                className="link"
                            >
                                상세보기
                            </button>
                        </dd>
                    </dl>
                    <div className="gap_thin"></div>
                    <dl>
                        <dt>카트피</dt>
                        <dd>
                            {detailData?.cartFee
                                ? appUtils.numberFormat(detailData?.cartFee) + '원'
                                : '그린피 골프장 문의'}
                        </dd>
                    </dl>
                    <div className="gap_thin"></div>
                    <dl>
                        <dt>캐디피</dt>
                        <dd>{detailData?.caddieFee ? detailData?.caddieFee : '캐디피 골프장 문의'}</dd>
                    </dl>

                    {detailData?.greenFee && (
                        <>
                            <div className="gap_thin"></div>
                            <dl>
                                <dt>그린피</dt>
                                <dd>{detailData?.greenFee}</dd>
                            </dl>
                        </>
                    )}
                </div>
            </section>
            {detailData?.bookingInformation && (
                <>
                    <section className="section02 detailNotieSection">
                        <div className="titleArea03">
                            <h2 className="green">[{detailData?.golfClubName}] 에서 알립니다!</h2>
                        </div>
                        <div className="infoTxtCon">
                            <div dangerouslySetInnerHTML={{__html: detailData?.bookingInformation}}></div>
                        </div>
                    </section>
                </>
            )}
            {nearbyClubData?.length && (
                <>
                    <section className="section02">
                        <div className="titleArea03">
                            <h2>주변 인근 골프장</h2>
                        </div>
                        <div className="golfClubSlider fullWidth">
                            <NearbyClubSlider
                                nearbyClubData={nearbyClubData}
                                onClickNearBy={onClickNearByClub}
                                onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                            />
                        </div>
                    </section>
                </>
            )}

            {/* 미제휴인경우 위약규정 얼럿 표시 => 실제로 데이터가 존재해도 나오면 안됨 */}
            <DefaultAlert
                open={penaltyClauseModal}
                handleClose={handlePenaltyClauseModal}
                title={''}
                contents={'위약규정 준비중 입니다.'}
            />
        </>
    );
}

export default GolfClubDetail;
