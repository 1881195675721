import React, {useState} from 'react';
import GolfZoneListItem from './GolfZoneListItem';

function GolfZoneList({golfzoneList, isSearchRank, removeFavoriteItem, setIsSnackBarOpen, onClickSelfCheckInBtn}) {
    return (
        <ul className={`golfZoneList ${golfzoneList.length > 9 ? 'wideNum' : ''}`}>
            {golfzoneList.map((golfZoneListItem, idx) => (
                <GolfZoneListItem
                    golfzoneListItem={golfZoneListItem}
                    key={idx}
                    isSearchRank={isSearchRank}
                    rankNum={idx}
                    removeFavoriteItem={removeFavoriteItem}
                    setIsSnackBarOpen={setIsSnackBarOpen}
                    onClickSelfCheckInBtn={onClickSelfCheckInBtn}
                />
            ))}
        </ul>
    );
}

export default GolfZoneList;
