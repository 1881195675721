import {fetchWrapper, history} from '../_helpers';
import {localStorageUtil} from './utils';

/**
 *  동반자 초대 utils
 */
export const inviteUtils = {
    /**
     * 동반자 초대 승인 처리 후 예약정보로 페이지 이동
     * @param bookingId
     * @param bookingMemberId
     */
    inviteProc: (bookingId, bookingMemberId) => {
        console.log('bookingId, bookingMemberId', bookingId, bookingMemberId);
        // if (bookingId && bookingMemberId) {
        // 동반자 초대 승인 후 예약 페이지로 이동
        return fetchWrapper.post(`/booking/invite-agree/${bookingId}/${bookingMemberId}`, {}).then((response) => {
            console.log(response);
            if (response?.code === '0000') {
                // 처리 성공
                inviteUtils.navigateMypageBooking(bookingId);
            } else if (response?.code === '8009' || response?.code === '8094') {
                // 라운드예정 탭으로 이동!
                console.log(response?.message, 'message');
                localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                history.navigate('/mypage/booking');
            } else if (response?.code === '8089') {
                // 라운드취소 탭으로 이동!
                history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=cancel`);
            } else if (
                response?.code === '8091' ||
                response?.code === '8092' ||
                response?.code === '8093' ||
                response?.code === '8095' ||
                response?.code === '8002'
            ) {
                // 홈으로npm 이동 후 얼럿 표시
                console.log(response?.message, '8002 message');
                localStorageUtil.set('AlarmMessage', JSON.stringify(response?.message));
                history.navigate('/main');
            }
            return response;
        });
        // }
        // else {
        //
        // }
    },
    /**
     * 동반자 초대 승인 파라미터(예약ID, 라운드맴버ID) 전달
     * -> 스토리지에 파라미터 확인 후 리턴값을 셋팅하며, 스토리지에 파라미터가 없을 시 넘어온 파라미터로 리턴값 셋팅하고 스토리지에 값을 저장한다.
     */
    getInviteParams: (urlParams) => {
        const inviteParams = localStorageUtil.get('inviteParams'); // 스토리지에 저장된 동반자 초대정보
        const shareParams = localStorageUtil.get('shareParams'); // 공유하기 파라미터
        const alarmParams = localStorageUtil.get('alarmParams'); // 알림톡 파라미터
        const smParams = localStorageUtil.get('smParams'); // 알림톡 > 스마트라운드 파라미터
        const courseParams = localStorageUtil.get('courseParams'); // 알림톡 > 코스정보 파라미터

        let inviteChannel = null;
        let bookingId = null;
        let bookingMemberId = null;
        let golfClubId = null;
        let shareStatus = null;
        let alarmStatus = null;
        let alarmId = null;
        let smStatus = null;
        let smGolfClubId = null;
        let smBookingId = null;
        let smClubName = null;
        let courseStatus = null;
        let courseGolfClubId = null;
        let courseCode = null;
        let mapControlYN = null;
        let csStatus = null;

        // 동반자 초대
        if (inviteParams?.inviteChannel && inviteParams?.bookingId && inviteParams?.bookingMemberId) {
            inviteChannel = inviteParams.inviteChannel;
            bookingId = inviteParams.bookingId;
            bookingMemberId = inviteParams.bookingMemberId;
        } else if (urlParams?.inviteChannel && urlParams?.bookingId && urlParams?.bookingMemberId) {
            inviteChannel = urlParams.inviteChannel;
            bookingId = urlParams.bookingId;
            bookingMemberId = urlParams.bookingMemberId;
            localStorageUtil.set('inviteParams', JSON.stringify(urlParams));
        }

        // 공유하기 > golfClubId params 세팅 (Login 페이지의 useParams 이슈 때문에 코드 분리 불가)
        if (shareParams?.golfclubId && shareParams?.shareStatus) {
            golfClubId = shareParams?.golfclubId;
            shareStatus = shareParams?.shareStatus;
        } else if (urlParams?.golfclubId) {
            golfClubId = urlParams?.golfclubId;
            shareStatus = urlParams?.shareStatus;
            localStorageUtil.set('shareParams', JSON.stringify(urlParams));
        }

        // 알림톡
        if (alarmParams?.status) {
            alarmStatus = alarmParams?.status;
        } else if (alarmParams?.id) {
            alarmId = alarmParams?.id;
        } else if (urlParams?.alarmId && urlParams?.alarmStatus) {
            alarmStatus = urlParams?.alarmStatus;
            alarmId = urlParams?.alarmId;
            localStorageUtil.set('alarmParams', JSON.stringify(urlParams));
        }

        // 알림톡 > 스마트라운드
        if (smParams?.smStatus && smParams?.smGolfClubId && smParams?.smBookingId && smParams?.smClubName) {
            smStatus = smParams?.smStatus;
            smGolfClubId = smParams?.smGolfClubId;
            smBookingId = smParams?.smBookingId;
            smClubName = smParams?.smClubName;
        } else if (urlParams?.smStatus && urlParams?.smGolfClubId && urlParams?.smBookingId && urlParams?.smClubName) {
            smStatus = urlParams?.smStatus;
            smGolfClubId = urlParams?.smGolfClubId;
            smBookingId = urlParams?.smBookingId;
            smClubName = urlParams?.smClubName;
            localStorageUtil.set('smParams', JSON.stringify(urlParams));
        }

        // 알림톡 > 코스 정보
        if (
            courseParams?.courseStatus &&
            courseParams?.courseGolfClubId &&
            courseParams?.courseCode &&
            courseParams?.mapControlYN
        ) {
            courseStatus = courseParams?.courseStatus;
            courseGolfClubId = courseParams?.courseGolfClubId;
            courseCode = courseParams?.courseCode;
            mapControlYN = courseParams?.mapControlYN;
        } else if (
            urlParams?.courseStatus &&
            urlParams?.courseGolfClubId &&
            urlParams?.courseCode &&
            urlParams?.mapControlYN
        ) {
            courseStatus = urlParams?.courseStatus;
            courseGolfClubId = urlParams?.courseGolfClubId;
            courseCode = urlParams?.courseCode;
            mapControlYN = urlParams?.mapControlYN;
            localStorageUtil.set('courseParams', JSON.stringify(urlParams));
        }

        if (urlParams?.csStatus) {
            csStatus = urlParams?.csStatus;
        }

        return {
            inviteChannel,
            bookingId,
            bookingMemberId,
            golfClubId,
            shareStatus,
            alarmStatus,
            alarmId,
            smStatus,
            smGolfClubId,
            smBookingId,
            smClubName,
            courseStatus,
            courseGolfClubId,
            courseCode,
            mapControlYN,
            csStatus
        };
    },
    /**
     * 스토리지에 예약정보 관련 파라미터 유무 확인
     */
    isInviteParams: () => {
        const inviteParams = localStorageUtil.get('inviteParams');
        return inviteParams && inviteParams.inviteChannel && inviteParams.bookingId && inviteParams.bookingMemberId;
    },
    /**
     * 동반자 초대 후 예약페이지로 이동
     */
    navigateMypageBooking: (bookingId) => {
        history.navigate(`/mypage/booking?bookingId=${bookingId}&flag=pre`);
    },
    /**
     * 동반자 초대 app에서 deeplink로 진입 후 페이지 이동
     */
    initPage: (inviteChannel, bookingId, bookingMemberId) => {
        inviteUtils.inviteProc(bookingId, bookingMemberId);
    }
};
