import React, {useCallback, useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {SmartRoundMenu} from '..';
import {useDispatch, useSelector} from 'react-redux';
import DefaultAlert from 'components/alert/DefaultAlert';
import {smartRoundActions} from '_store/smartRound.slice';
import {clickCompanionInfo, clickSelfCheckin} from '_helpers/amplitude/events/click.event';
import {getAmplitudeRootPath} from '_helpers/amplitude/amplitude.helper';
import {fetchWrapper} from '_helpers';
import {SUCCESS_CODE} from 'common/constants';

function SmartRoundFooter() {
    // Navigation 있을때 하단 여백 추가를 위한 클래스명 추가
    const bookingId = useSelector((x) => x?.smartRound.bookingId);
    const golfClubId = useSelector((x) => x?.smartRound.golfClubId);
    const smartRound = useSelector((x) => x?.smartRound.smartRound);
    const timer = useSelector((x) => x?.smartRound.timer);

    const dispatch = useDispatch();

    const queryString = `?golfClubId=${golfClubId}${bookingId ? '&bookingId=' + bookingId : ''}`;

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        document.body.classList.add('naviBottom');
        return () => {
            document.body.classList.remove('naviBottom');
        };
    }, []);

    const location = useLocation();

    const onClickMenu = (navi) => {
        amplitudeEvent(navi.classname);

        if (timer !== null) {
            clearInterval(timer);
            dispatch(smartRoundActions.setTimer({timer: null}));
        }

        if ([`/${SmartRoundMenu.SelfCheckIn}`, `/${SmartRoundMenu.Partner}`].includes(navi.pathname) && !bookingId) {
            setAlertMessage('예정된 라운드가 없습니다.');
            setAlertOpen(true);
        } else if ([`/${SmartRoundMenu.Food}`].includes(navi.pathname) && !smartRound?.isMenuApplied) {
            setAlertMessage(
                '현재 해당 골프장의 음식메뉴\n미리보기 서비스를 준비중입니다.\n빠른 시일내에 이용 가능하도록 준비하겠습니다.'
            );
            setAlertOpen(true);
        }
    };

    const smartroundNavList = [
        {pathname: `/${SmartRoundMenu.SelfCheckIn}`, classname: SmartRoundMenu.SelfCheckIn, title: '셀프 체크인'},
        {pathname: `/${SmartRoundMenu.Partner}`, classname: SmartRoundMenu.Partner, title: '동반자 초대'},
        {pathname: `/${SmartRoundMenu.CourseGuide}`, classname: SmartRoundMenu.CourseGuide, title: '코스가이드'},
        {pathname: `/${SmartRoundMenu.Food}`, classname: SmartRoundMenu.Food, title: '음식메뉴'}
    ];

    const amplitudeEvent = (name) => {
        fetchWrapper.get(`/amplitude-events/view-cc-detail?golfClubId=${golfClubId}`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                const _data = response.data;
                if (bookingId) {
                    _data.available = true;
                } else {
                    _data.available = false;
                }

                switch (name) {
                    case SmartRoundMenu.SelfCheckIn:
                        clickSelfCheckin(_data, getAmplitudeRootPath());
                        break;
                    case SmartRoundMenu.Partner:
                        clickCompanionInfo(_data, getAmplitudeRootPath());
                        break;
                    case SmartRoundMenu.CourseGuide:
                        break;
                    case SmartRoundMenu.Food:
                        break;
                }
            }
        });
    };

    return (
        <div id="smart-round-footer">
            {smartroundNavList.map((navi) => (
                <NavLink
                    to={
                        ([`/${SmartRoundMenu.SelfCheckIn}`, `/${SmartRoundMenu.Partner}`].includes(navi.pathname) &&
                            !bookingId) ||
                        ([`/${SmartRoundMenu.Food}`].includes(navi.pathname) && !smartRound?.isMenuApplied)
                            ? '#'
                            : `${navi.pathname + queryString}`
                    }
                    className={location.pathname === navi.pathname ? 'on' : ''}
                    key={navi.classname}
                    onClick={() => {
                        onClickMenu(navi);
                    }}
                >
                    <i className={navi.classname}></i>
                    <span>{navi.title}</span>
                </NavLink>
            ))}
            <DefaultAlert
                open={alertOpen}
                handleClose={() => {
                    setAlertOpen(false);
                }}
                contents={alertMessage}
            />
        </div>
    );
}

export default SmartRoundFooter;
