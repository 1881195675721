import React, {useCallback, useEffect, useState} from 'react';
import BookingAvatar from '../../components/round-booking/BookingAvatar';
import PartnerInviteIconList from '../../components/common/PartnerInviteIconList';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import {tossUtils} from '../../common/tossUtils';
import PartnerInviteModal from '../../components/common/PartnerInviteModal';
import PartnerDeleteAlert from '../../components/alert/PartnerDeleteAlert';
import DefaultAlert from '../../components/alert/DefaultAlert';
import {useDispatch, useSelector} from 'react-redux';

function PartnerInvite({bookingData, bookingList, isBookingCancel, setMemberReset}) {
    // 편집권한 부여 (조건 동반자 목록 존재, isHost true)
    const [editable, setEditable] = useState(false);
    const [InviteOpen, setInviteOpen] = useState(false);

    // User Token Id
    const auth = useSelector((x) => x?.auth?.user);

    const handleInviteOpen = (event) => {
        setInviteOpen(true);
    };
    // // 동반자 초대방식 선택 팝업
    // const [InviteOpen, setInviteOpen] = useState(false);
    // const handleInviteOpen = () => setInviteOpen(true);
    const handleInviteClose = (isInvite) => {
        setInviteOpen(false);

        // 멤버 추가했을때 멤버 정보리셋
        isInvite && setMemberReset(true);
    };

    // 친구 추가 버튼 노출 조건
    const [invitationable, setInvitationable] = useState(false);
    const maximumPlayer = bookingData?.playerLimit;
    const roundPlayer = bookingData?.roundPlayer;
    const inviteIconNum = maximumPlayer - roundPlayer;

    useEffect(() => {
        if (maximumPlayer > roundPlayer) {
            setInvitationable(true);
        } else {
            setInvitationable(false);
        }
    }, [maximumPlayer, roundPlayer]);

    // 로그인된 유저 찾기
    const [loggedInUser, setLoggedInUser] = useState(null);
    let memberList = bookingData?.bookingMemberList;

    const setloggedInUser = useCallback(() => {
        if (tossUtils.decodeSubject(auth.accessToken) && memberList?.length) {
            let userInfo = null; // 로그인된 유저정보 담을 곳
            memberList.forEach((v) => {
                // 로그인한 user의 해당 객체 전송
                if (v.memberId === tossUtils.decodeSubject(auth.accessToken) || null) {
                    userInfo = v;
                    setLoggedInUser(userInfo);

                    // host는 편집권한 부여
                    if (v?.host) {
                        setEditable(true);
                    }
                    return;
                }
            });
        }
    }, [bookingData]);

    useEffect(() => {
        setloggedInUser();
    }, [setloggedInUser]);

    // 현재시간과 티타임 시간 비교
    const bookingTime = new Date(bookingData?.roundAt).getTime(); // 예약날짜
    const todayTime = new Date().getTime(); // 오늘날짜
    const showIcon = bookingTime > todayTime;

    // 동반자 삭제 안내 Alert
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedName, setSelectedName] = useState(null);
    const handleAlertOpen = (e) => {
        const name = e.target.dataset.name;
        setSelectedName(name);
        setAlertOpen(true);
    };
    const handleAlertClose = () => setAlertOpen(false);

    // ============== 동반자 삭제 ===============
    const [memberId, setMemberId] = useState(null);
    const [bookingId, setBookingId] = useState(null);

    // 동반자 삭제 오류 Alert
    const [alertContents, setAlertContents] = useState('');
    const [deleteOpen, setDeleteOpen] = useState(false);
    const deleteErrorAlertOpen = () => setDeleteOpen(true);
    const deleteErrorAlertClose = () => setDeleteOpen(false);

    // memberId 세팅
    const [nonMember, setNonMember] = useState(false);

    const setDeleteMemberId = (id, golagaMember) => {
        setMemberId(id);

        if (golagaMember === false) {
            setNonMember(true);
        } else {
            setNonMember(false);
        }
    };

    // bookingId 세팅
    const setBookingIdForMember = () => {
        if (!memberId) return;

        bookingList?.forEach((v) => {
            v.bookingMemberList.forEach((i) => {
                if (i?.id === memberId || null) {
                    setBookingId(v?.id);
                }
            });
            return;
        });
    };

    // 삭제할 memberId 값 세팅되면 -> 해당 bookingId 세팅
    useEffect(() => {
        memberId && setBookingIdForMember();
    }, [memberId]);

    const onDeleteMember = () => {
        if (memberId) {
            fetchWrapper.delete(`/booking/invite/${bookingId}/${memberId}`, null).then((response) => {
                if (response.code === SUCCESS_CODE) {
                    // 리렌더링
                    setMemberReset(true);
                    handleAlertClose();
                } else {
                    // 실패 시 얼럿
                    handleAlertClose();
                    deleteErrorAlertOpen();
                    setAlertContents('시스템 오류로 인해 정상적으로 처리되지 않았습니다.');
                }
            });
        }
    };

    return (
        <>
            <div className="inviteInfo">
                <div className="ttWrap">
                    <h3>동반자 초대</h3>
                </div>
                <div className="avatarWrap">
                    <ul className={`listCon ${roundPlayer < 4 || maximumPlayer < 4 ? 'fixedWidth' : ''}`}>
                        {bookingData?.bookingMemberList?.map((data, idx) => {
                            return (
                                <BookingAvatar
                                    key={idx}
                                    data={data}
                                    editable={editable}
                                    showIcon={showIcon}
                                    handleAlertOpen={handleAlertOpen}
                                    setDeleteMemberId={setDeleteMemberId}
                                    isBookingCancel={isBookingCancel}
                                />
                            );
                        })}
                        {editable && invitationable && showIcon && !isBookingCancel && (
                            <PartnerInviteIconList inviteIconNum={inviteIconNum} handleInviteOpen={handleInviteOpen} />
                        )}
                    </ul>
                </div>
            </div>

            {/* 동반자 초대방식 선택 팝업  */}
            <PartnerInviteModal
                InviteOpen={InviteOpen}
                handleInviteClose={handleInviteClose}
                setInviteOpen={setInviteOpen}
                bookingData={bookingData}
                setMemberReset={setMemberReset}
            />

            {/* 동반자 삭제 안내 Alert */}
            <PartnerDeleteAlert
                open={alertOpen}
                handleClose={handleAlertClose}
                name={selectedName}
                onDeleteMember={onDeleteMember}
                nonMember={nonMember}
            />

            {/* 동반자 삭제 오류 Alert */}
            <DefaultAlert
                open={deleteOpen}
                handleClose={deleteErrorAlertClose}
                title={'시스템 장애 발생'}
                contents={alertContents}
            />
        </>
    );
}

export default PartnerInvite;
