import React, {useCallback, useEffect, useState} from 'react';
import './main.scss';
import {fetchWrapper, history} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import FieldMain from '../reservation/FieldMain';
import {localStorageUtil} from '../../common/utils';
import DefaultAlert from '../alert/DefaultAlert';
import {useDispatch, useSelector} from 'react-redux';
import {inviteActions} from '../../_store/invite.slice';
import {useNavigate} from 'react-router-dom';
import GetMyReservDrawer from './GetMyReservDrawer';
import {viewHome, viewOtherBookingDetails, viewPopupOtherBookingAsk} from '_helpers/amplitude/events/view.events';
import WebAccessInfoModal from '../modal/WebAccessInfoModal';
import {rootTaxonomy} from '_helpers/amplitude/taxonomy/root.taxonomy';
import AgreementInfoModal from 'components/modal/AgreementInfoModal';
import {appUtils} from '../../common/utils';
import DirectionSnackbar from 'components/alert/DirectionSnackbar';
import {formatTodayDateToIsoString} from 'common/notWatchingTodayUtils';
import MaintainanceNoticeModal from '../modal/MaintainanceNoticeModal';

function Main() {
    history.navigate = useNavigate();
    const promotionToast = useSelector((x) => x?.checkin.promotionToast);

    const [bookingData, setBookingData] = useState(null);

    const changeDate = appUtils.dateFormatTypeBoard(Date.now());

    const [isAgreementCheck, setIsAgreementCheck] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState({
        open: false,
        msg: ''
    });

    // alarmLayer 노출 시점 수정
    // confirm modal close -> alarmlayer modal open
    const [isConfirmModalClose, setIsConfirmModalClose] = useState(false);

    // '내 예약정보 불러오기' 팝업
    const [agreementInfoModalOpen, setAgreementInfoModalOpen] = useState(false);

    const [maintainanceNoticeModalOpen, setMaintainanceNoticeModalOpen] = useState(false);

    const handleNoticeOpen = () => {
        setAgreementInfoModalOpen(true);
        viewPopupOtherBookingAsk(); // 앰플리튜드 이벤트 전송
    };
    const handleNoticeClose = () => {
        setAgreementInfoModalOpen(false);
    };

    const handleMaintainanceNoticeOpen = () => {
        setMaintainanceNoticeModalOpen(true);
    };
    const handleMaintainanceNoticeClose = () => {
        setMaintainanceNoticeModalOpen(false);
    };

    const promotionToastOpen = () => {
        if (promotionToast) {
            setSnackBarOpen({
                open: true,
                msg:
                    `${changeDate}` +
                    `에 예약정보 불러오기에  \n 동의하였습니다. \n 내정보 수정하기에서 동의를 취소할 수 있습니다.`
            });
        }
    };

    // 메인 팝업 > '자세히 보기' 클릭 이벤트
    const onClickViewDetail = () => {
        setAgreementInfoModalOpen(false);

        // '예약정보 불러오기' 확인 페이지로 이동
        history.navigate('/get-my-reservation');

        viewOtherBookingDetails(rootTaxonomy.home_bottom_banner);
    };

    // 알림톡 통해 진입 시 예약 확인 불가 Alert
    const dispatch = useDispatch();
    const refresh = useSelector((x) => x?.invite.refresh);

    const alarmMessage = JSON.parse(localStorageUtil.get('AlarmMessage'));
    const [alertOpen, setAlertOpen] = useState(false);
    const alarmErrorAlertOpen = () => {
        if (alarmMessage === null) return;
        setAlertOpen(true);
    };
    const alarmErrorAlertClose = () => setAlertOpen(false);

    // 예정된 예약리스트
    // todo 사용하지 않는 bookingData 삭제
    const getBookingList = useCallback(() => {
        fetchWrapper.get(`/booking/list/smartround`, null).then((response) => {
            if (response.code === SUCCESS_CODE) {
                setBookingData(response?.data?.lists || null);
            }
        });
    }, []);

    const getAlarmInfo = () => {
        let joinShareAction = JSON.parse(localStorageUtil.get('joinShareAction'));
        let joinAlarmAction = JSON.parse(localStorageUtil.get('joinAlarmAction'));
        let joinSMAction = JSON.parse(localStorageUtil.get('joinSMAction'));
        let joinCourseAction = JSON.parse(localStorageUtil.get('joinCourseAction'));
        let joinClubBoardAction = JSON.parse(localStorageUtil.get('joinClubBoardAction'));
        let joinCSAction = JSON.parse(localStorageUtil.get('joinCSAction'));
        let joinSelfChInGuideAction = JSON.parse(localStorageUtil.get('joinSelfChInGuideAction'));
        let alarmMessage = JSON.parse(localStorageUtil.get('AlarmMessage'));

        if (joinAlarmAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinAlarmAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (joinSMAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinSMAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (joinCourseAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinCourseAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (joinClubBoardAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinClubBoardAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (joinSelfChInGuideAction) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('joinSelfChInGuideAction');
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        } else if (alarmMessage) {
            alarmErrorAlertOpen();
            setTimeout(() => {
                localStorageUtil.remove('AlarmMessage');
            }, 1000);
        }
        dispatch(inviteActions.setRefresh(false));
    };

    useEffect(() => {
        viewHome();
    }, []);

    useEffect(() => {
        if (refresh) getAlarmInfo();
    }, [refresh]);

    useEffect(() => {
        getBookingList();
    }, [getBookingList]);

    // member api
    const [member, setMember] = useState(null);

    const getMemberInfo = useCallback(async () => {
        const response = await fetchWrapper.get(`/member`, null);
        if (response?.code === SUCCESS_CODE) {
            setMember(response.data || null);
            if (response?.data.isBookingCheckAgree) {
                setIsAgreementCheck(true);
            }
        }
    }, []);

    const getLocalstorage = localStorageUtil.get('get_my_reserve_drawer');

    // '오늘 하루 보지 않기' 모달 오픈
    useEffect(() => {
        if (member?.isBookingCheckAgree === false) {
            if (!getLocalstorage || (getLocalstorage && getLocalstorage !== formatTodayDateToIsoString))
                handleNoticeOpen();
        }
    }, [member]);

    useEffect(() => {
        // 서비스 점검 시 모달 오픈 및 페이지 이동
        const checkToday = new Date();
        const noticeStartDate = new Date(process.env.REACT_APP_NOTICE_START_DATE);
        const noticeEndDate = new Date(process.env.REACT_APP_NOTICE_END_DATE);
        const preExposureDate = process.env.REACT_APP_NOTICE_PRE_EXPOSURE_DATE;

        if (Number(process.env.REACT_APP_NOTICE) && checkToday < noticeEndDate) {
            const diffMSec = noticeStartDate.getTime() - checkToday.getTime();
            const changeMin = parseInt(diffMSec / (60 * 1000));

            if (changeMin <= preExposureDate) {
                // modal open
                handleMaintainanceNoticeOpen();
                if (changeMin < 0) {
                    // 서비스 점검 페이지 이동
                    history.navigate('/maintainance-notice');
                }
            }
        }

        getAlarmInfo();
        getMemberInfo();

        // 타 플랫폼 예약정보 불러오는 api 호출
        // 2023-07-04 주석 처리 중복 값 제거 위함
        // fetchWrapper.get('/booking/linkedGolfClub');
    }, []);
    return (
        <>
            <FieldMain
                bookingData={bookingData}
                handleConfirmAgreeModalClose={isConfirmModalClose}
                isAgreementCheck={isAgreementCheck}
            />
            {/* 알림톡 통해 진입 시 예약 확인 불가 Alert */}
            <DefaultAlert
                open={alertOpen}
                handleClose={alarmErrorAlertClose}
                // title={isMessage}
                contents={alarmMessage}
            />
            {/* 내 예약정보 불러오기 드로어 */}
            <GetMyReservDrawer handleNoticeClose={handleNoticeClose} />
            {/* 정보동의  */}
            <AgreementInfoModal
                open={agreementInfoModalOpen}
                handleAgreementReOpen={() => setAgreementInfoModalOpen(true)}
                handleNoticeClose={handleNoticeClose}
                onClickViewDetail={onClickViewDetail}
                handleConfirmAgreeModalClose={() => setIsConfirmModalClose(true)}
            />
            {/*웹 외부 접속 허용 대응 안내*/}
            <WebAccessInfoModal />

            {/*스낵바 오픈*/}
            <DirectionSnackbar
                direction={'up'}
                position={'bottom'}
                open={promotionToast}
                msg={snackBarOpen.msg}
                duration={20}
                setOpen={promotionToastOpen}
            />
            {/* 점검 안내 모달 / 점검 30분 전*/}
            <MaintainanceNoticeModal open={maintainanceNoticeModalOpen} handleClose={handleMaintainanceNoticeClose} />
        </>
    );
}

export default Main;
