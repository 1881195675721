import {createSlice} from '@reduxjs/toolkit';

const name = 'timer';
const initialState = {
    initialSec: 180,
    reset: false,
    done: false
};

const timerSlice = createSlice({
    name,
    initialState,
    reducers: {
        setInitialSec: {
            reducer: (state, action) => {
                state.initialSec = action.payload;
            }
        },
        setDone: {
            reducer: (state, action) => {
                state.done = action.payload;
            }
        },
        setReset: {
            reducer: (state, action) => {
                state.reset = action.payload;
            }
        },
        setCleanUp: (state) => {
            state = initialState;
        }
    }
});

export const timerActions = {...timerSlice.actions};
export const timerReducer = timerSlice.reducer;
