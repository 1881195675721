export const viewTaxonomy = {
    view_intro_page: 'view_intro_page',
    view_email_login_page: 'view_email_login_page',
    view_signup_email_step1: 'view_signup_email_step1',
    view_signup_email_step2: 'view_signup_email_step2',
    view_signup_apple_step1: 'view_signup_apple_step1',
    view_signup_apple_step2: 'view_signup_apple_step2',
    view_home: 'view_home',
    view_search_favorite_tab: 'view_search_favorite_tab',
    view_search_popular_tab: 'view_search_popular_tab',
    view_search_result: 'view_search_result',
    view_cc_detail: 'view_cc_detail',
    view_tab_cc_detail: 'view_tab_cc_detail',
    view_cc_cancel_policy: 'view_cc_cancel_policy',
    view_reservation: 'view_reservation',
    view_smartround_list: 'view_smartround_list',
    view_smartround_video: 'view_smartround_video', //
    view_gps_self_checkin: 'view_gps_self_checkin',
    view_qr_self_checkin: 'view_qr_self_checkin',
    view_self_checkin: 'view_self_checkin',
    view_companion_info: 'view_companion_info',
    view_intro_courseguide: 'view_intro_courseguide',
    view_courseguide: 'view_courseguide',
    view_food_menu: 'view_food_menu',
    view_cc_notice: 'view_cc_notice',
    view_myinfo: 'view_myinfo',
    view_change_myinfo: 'view_change_myinfo',
    view_change_mobile_num: 'view_change_mobile_num',
    view_withdrawal: 'view_withdrawl',
    view_withdrawal_success: 'view_withdrawal_success',
    view_reservation_details: 'view_reservation_details',
    view_favorite: 'view_favorite',
    view_notice: 'view_notice',
    view_policy: 'view_policy',
    view_cancel_policy: 'view_cancel_policy',
    view_cancel_reason: 'view_cancel_reason',
    view_modal_cancel: 'view_modal_cancel',
    view_home_bottom_modal: 'view_home_bottom_modal',
    view_other_booking_details: 'view_other_booking_details',
    view_other_booking_cc_list: 'view_other_booking_cc_list',
    click_other_booking_connect_btn: 'click_other_booking_connect_btn',
    view_other_booking_agree_modal: 'view_other_booking_agree_modal',
    view_other_booking_policy: 'view_other_booking_policy',
    connect_other_booking_complete: 'connect_other_booking_complete',
    view_popup_other_booking_ask: 'view_popup_other_booking_ask',
    view_popup_other_booking_cancel_confirm: 'view_popup_other_booking_cancel_confirm',
    click_disagree_other_booking_btn: 'click_disagree_other_booking_btn',
    //    ================결제 Amplitude 추가 항목======================

    // - 추가 항목
    /**
     */
    view_reservation_payment_fail: 'view_reservation_payment_fail',
    view_reservation_fail: 'view_reservation_fail',
    view_cancel_fail_alert: 'view_cancel_fail_alert',
    view_cancel_payment_fail: 'view_cancel_payment_fail'

    //    ================결제 Amplitude 추가 항목======================
};
