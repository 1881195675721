import React, {useCallback, useEffect, useState} from 'react';
import Icon from '../../icons/Icon';
import Drawer from '@mui/material/Drawer';
import AllCheckbox from '../../button/AllCheckbox';
import {history, fetchWrapper} from '../../../_helpers';
import FieldBookingCancelPeriod from './FieldBookingCancelPeriod';
import PolicyLayer from '../../membership/PolicyLayer';
import DefaultAlert from '../../alert/DefaultAlert';
import {appUtils} from '../../../common/utils';
import DirectionSnackbar from '../../alert/DirectionSnackbar';
import {FAIL_CODE, SUCCESS_CODE} from '../../../common/constants';
import Loading from '../../../common/loading/Loading';

import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
// import {modalCheckReservation} from '../../../_helpers/amplitude/events/etc.event';

function FieldBookingLayer({
    fieldBookingOpen,
    isSelfCheckInPossible,
    setFieldBookingOpen,
    golfClubName,
    courseName,
    cancelPossiblePeriod,
    hole,
    afterCancelPeriod,
    sumGreentFee,
    innerSelectPeople,
    courseId,
    timeSheetId,
    golfClubId,
    roundPlayer,
    teeTime,
    placeboInformation,
    isCancelPossibility,
    isCartFeeIncluded
}) {
    const onFieldBookingLayerClose = () => {
        setFieldBookingOpen(false);
    };

    // 체크박스 상태관리
    const [allChecked, setAllChecked] = useState(false);

    // 미 동의 시 알림창 오픈
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    const [alertTitle, setAlertTitle] = useState('');
    const [alertContent, setAlertContent] = useState('');

    // 체크박스 상태관리 골프장 규정 및 개인정보 이용약관
    const [checkList, setCheckList] = useState([]);

    //SnackBar 창 open state
    const [snackBarOpen, setSnackBarOpen] = useState({
        open: false,
        msg: ''
    });

    const [snackBarMsg, setSnackBarMsg] = useState('');

    //예약 불가 팝업 모달
    const [isPossibleility, setIsPossibleility] = useState(false);

    const [bookingPossible, setBookingPossible] = useState([]);

    // 로딩페이지
    const [isLoading, setIsLoading] = useState(false);
    // 예약하기 버튼 disabled
    const [reservationCompleteBtnDisabled, setReservationCompleteBtnDisabled] = useState(false);

    /*const handlePossibleilityModalClose = () => setIsPossibleilityModalOpen(false);*/

    const agreeDataList = useCallback(() => {
        setCheckList([
            {
                title: golfClubName + ' 취소/위약 규정',
                type: 'GOLF_CLUB_AGREE',
                msg: '골프장 취소/위약규정 동의',
                checked: false,
                require: true
            },
            /* {
                title: '개인정보 수집 및 이용동의',
                type: 'JOIN_PRIVACY',
                msg: '개인정보 수집 및 이용동의',
                checked: false,
                require: true
            },*/
            {
                title: '개인정보 제3자 제공 동의',
                type: 'BOOKING_PRIVACY',
                msg: '개인정보 제3자 제공 동의',
                checked: false,
                require: true
            }
        ]);
    }, [golfClubName]);

    useEffect(() => {
        function handleEvent(message) {
            console.log(message.data);
            let type = JSON.parse(message.data).type;
            console.log('type', type);

            if (type === 'webModalClose') {
                agreementLayerClose();
            }
        }

        // app => web 으로 이벤트메세지 받아서 정책
        document.addEventListener('message', handleEvent);

        return () => document.removeEventListener('message', handleEvent);
    }, []);

    // useEffect(() => {
    //     if (fieldBookingOpen === true) {
    //         fetchWrapper
    //             .get(`/amplitude-events/view-reservation?golfClubId=${golfClubId}&timeSheetId=${timeSheetId}`, null)
    //             .then((response) => {
    //                 modalCheckReservation(response.data, roundPlayer, getAmplitudeRootPath());
    //             });
    //     }
    // }, [fieldBookingOpen]);

    useEffect(() => {
        agreeDataList();
    }, [golfClubName]);

    // 약관 동의 팝업
    const [agreeLayerTitle, setAgreeLayerTitle] = useState('');
    const [agreeType, setAgreeType] = useState('');

    const toggleAllCheck = () => {
        setAllChecked(!allChecked);
        setCheckList((checks) =>
            checks.map((c) => ({
                ...c,
                checked: !allChecked
            }))
        );
    };

    //디바운스
    function debounce(func, timeout = 300) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    const closeSneckBar = () => {
        setTimeout(() => {
            setSnackBarOpen({
                open: false,
                msg: snackBarMsg
            });
        }, [2500]);
    };

    //예약하기 함수
    const bookingCreate = (payload) => {
        fetchWrapper
            .post('/booking/create', payload)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    //console.log('여기');
                    history.navigate('/field-booking-complete', {
                        state: {golfClubId: golfClubId, bookingId: response.data.bookingId},
                        replace: true
                    });
                    //예약 실패시
                    if (response.data.bookingComplete === false) {
                        setSnackBarOpen({
                            open: true,
                            msg: snackBarMsg
                        });

                        history.navigate('/main');

                        closeSneckBar();
                    }
                } else if (response.code === FAIL_CODE) {
                    // 0003 error
                    setSnackBarOpen({
                        open: true,
                        msg: response.message
                    });
                } else {
                    console.log('예약 실패 message: ', snackBarMsg);
                    setSnackBarOpen({
                        open: true,
                        msg: snackBarMsg
                    });

                    closeSneckBar();
                }
            })
            .catch((err) => console.log('err : ' + err))
            .finally((res) => {
                // close loading page and btn enable
                setIsLoading(false);
                setReservationCompleteBtnDisabled(false);
            });
    };

    // 예약완료하기 버튼 클릭
    const onFieldBookingComplete = async () => {
        if (allChecked === true) {
            // ======= api 연동 부분 =======
            //console.log('onFieldBookingComplete IN');

            if (reservationCompleteBtnDisabled === true) {
                return;
            }

            // open loading page and btn disable
            setIsLoading(true);
            setReservationCompleteBtnDisabled(true);

            const payload = {
                courseId: courseId,
                timeSheetId: timeSheetId,
                golfClubId: golfClubId,
                roundPlayer: roundPlayer
            };

            //예약이 가능한지 조회
            let isPossibleility = false;

            fetchWrapper
                .get(
                    `/booking/possibility?golfClubId=${payload.golfClubId}&courseId=${payload.courseId}&timeSheetId=${payload.timeSheetId}`,
                    null
                )
                .then((response) => {
                    //console.log(response.data);
                    isPossibleility = response?.data?.isPossibility;
                    //console.log(isPossibleility);

                    if (isPossibleility === true) {
                        console.log('예약 가능');
                        bookingCreate(payload);
                    } else {
                        console.log('예약 불가 message:', response?.data.impossibleReason);

                        setSnackBarOpen({
                            open: true,
                            msg: snackBarMsg
                        });
                        setIsPossibleility(true);
                        setSnackBarMsg(response?.data.impossibleReason);

                        //2초후 스낵바가 닫히지 않는 오류때문에 실행
                        closeSneckBar();

                        // close loading page and btn enable
                        setIsLoading(false);
                        setReservationCompleteBtnDisabled(false);
                    }
                })
                .catch((err) => {
                    console.log('err : ' + err);
                    isPossibleility = false;

                    // close loading page and btn enable
                    setIsLoading(false);
                    setReservationCompleteBtnDisabled(false);
                });
        } else {
            checkList.some((checks) => {
                if (!checks.checked) {
                    // console.log(checks.title);
                    setAlertTitle(checks.msg + ' 안내');
                    if (checks.type === 'RESERVATION_CANCEL_AGREE') {
                        setAlertContent(checks.msg + '을 확인 하신 후 동의하셔야 예약이 가능합니다.');
                    } else {
                        setAlertContent(checks.title + '를 확인 하신 후 동의하셔야 예약이 가능합니다.');
                    }
                    setOpen(true);

                    return true;
                }
            });
        }
    };

    const onCheckClick = (index) => {
        let checkSet = [...checkList];
        let checkedItem = {
            ...checkList[index],
            checked: !checkList[index].checked
        };
        checkSet[index] = checkedItem;

        setCheckList(checkSet);
        setAllChecked(isAllChecked(checkSet));
    };

    const isAllChecked = (checkSet) => {
        let checkedCnt = checkSet.filter((v) => {
            return v.checked;
        }).length;
        return checkSet.length === checkedCnt;
    };

    const [agreementOpen, setAgreementOpen] = useState(false);

    //예약하기 레이어 오픈
    const agreementLayerOpen = (e) => {
        setAgreementOpen(true);
        setAgreeLayerTitle(e.target.dataset.title);
        setAgreeType(e.target.dataset.type);

        appUtils.openWebViewModalPostMsg();
    };

    //예약 확인하기 Drawer 창 닫기
    const agreementLayerClose = () => {
        setAgreementOpen(false);
    };

    return (
        <>
            <Drawer
                anchor="bottom"
                open={fieldBookingOpen}
                onClose={onFieldBookingLayerClose}
                className="rezInfoDrawer"
            >
                <div className="drawer-content">
                    <div className="drawerInner fieldBookingContentArea">
                        <div className="topBtnArea">
                            <button type="button" className="btnClose" onClick={onFieldBookingLayerClose}>
                                <Icon name="close" />
                            </button>
                        </div>
                        <div className="drawerContents fieldBookingLayerContents">
                            <div className="fieldBookingTitleArea">
                                {isSelfCheckInPossible && <span className="statusIcon selfCheckIn">셀프체크인</span>}
                                <h2>{golfClubName}</h2>
                            </div>
                            {/* 예약 정보
                            <div className="fieldBookingInfo">
                                <p className="date">{teeTime !== null ? appUtils.dateFormatTypeFull(teeTime) : ''}</p>
                                 <div className="ttArea">
                                    <strong className="tt">{golfClubName}</strong>
                                </div>
                                <div className="courseInfo">
                                    {courseName !== null ? courseName : '-'} · {hole === 0 ? '-' : `${hole}홀`}
                                </div>
                            </div>*/}
                            <div className="fieldBookingDetailList">
                                <div className="infoType06">
                                    <dl>
                                        <dt>티타임</dt>
                                        <dd>{teeTime !== null ? appUtils.dateFormatTypeFull(teeTime) : ''}</dd>
                                    </dl>
                                    <dl>
                                        <dt>코스명</dt>
                                        {/* 코스 명 데이터 미 조회시 - 로 노출*/}
                                        <dd>{courseName !== null ? courseName : '-'}</dd>
                                    </dl>
                                    <dl>
                                        <dt>홀정보</dt>
                                        {/* 홀 정보 데이터 미 조회시 - 로 노출*/}
                                        <dd>{hole !== '' ? `${hole}` : '-'}</dd>
                                    </dl>
                                </div>
                            </div>

                            {/* 총 결제 금액 */}
                            <div className="fieldBookingLayerPaymentWrap">
                                <dl>
                                    <dt>
                                        총 현장 결제 금액 <span>({innerSelectPeople}명)</span>
                                    </dt>
                                    <dd>
                                        {sumGreentFee === 0 ? (
                                            <b>그린피 골프장 문의</b>
                                        ) : (
                                            appUtils.numberFormat(sumGreentFee) + '원'
                                        )}
                                    </dd>
                                </dl>
                                {/*<p className="warningMsg">* 총 결제할 금액은 ‘카트피’와 ‘캐디피’ 불포함 금액입니다.</p>*/}
                                <p className="warningMsg">
                                    {isCartFeeIncluded === true
                                        ? '* 총 결제할 금액은 ‘캐디피’ 불포함 금액입니다.'
                                        : '* 총 결제할 금액은 ‘카트피’와 ‘캐디피’ 불포함 금액입니다.'}
                                </p>

                                {/* 취소 가능 기한 컴포넌트 분리해야됨 (경과 분기도 추가해야됨 )*/}
                                <FieldBookingCancelPeriod
                                    cancelPossibility={isCancelPossibility}
                                    cancelPossiblePeriod={cancelPossiblePeriod}
                                />
                            </div>

                            <div className="agreeCheckArea collapse fieldBookingLayerAgreeWrap">
                                <div className="checkAll">
                                    <div className="checkLabel">
                                        {/* ---------------ALL CHECK BTN--------------- */}
                                        <AllCheckbox
                                            allChecked={allChecked}
                                            toggleAllCheck={toggleAllCheck}
                                            allCheckDisabled={false}
                                        >
                                            골프장 규정 및 개인정보 이용 전체 동의
                                        </AllCheckbox>
                                    </div>
                                </div>
                                <ul className="agreeCheckList agreeCheckList-booking">
                                    {/* -----------------CHECK LIST------------------- */}
                                    {checkList.map((labelData, idx) => (
                                        <li key={idx}>
                                            <div className="checkLabel">
                                                <label>
                                                    <div className="customCheck">
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => {
                                                                onCheckClick(idx);
                                                            }}
                                                            checked={checkList[idx].checked}
                                                        />
                                                        <i
                                                            className={`icon ${
                                                                checkList[idx].checked ? 'checked' : ''
                                                            }`}
                                                        >
                                                            <svg
                                                                width="100%"
                                                                height="100%"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17 8L11.0525 15L7 10.9168"
                                                                    stroke={
                                                                        checkList[idx].checked ? '#3906C3' : '#A0A4AF'
                                                                    }
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </i>
                                                    </div>
                                                    <span className={labelData.require ? '' : 'gray'}>
                                                        {labelData.require ? '[필수] ' : '[선택] '}
                                                    </span>
                                                    {labelData.msg}{' '}
                                                </label>
                                                {labelData.type && (
                                                    <button
                                                        type="button"
                                                        className="btn_ar"
                                                        onClick={agreementLayerOpen}
                                                        data-title={labelData.title}
                                                        data-type={labelData.type}
                                                    >
                                                        자세히보기
                                                    </button>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                    {/* ------------------------------------------ */}
                                </ul>
                            </div>

                            {/* 개인정보 이용내용 동의사항 주의사항*/}
                            <div className="booking-cautionTxt03">
                                * 상기 동의 사항은 예약하고자 하는 골프장의 서비스 이용을 위한 최소한의 정보로써, 미동의
                                시 예약이 불가합니다.
                            </div>
                        </div>
                    </div>
                    {/* 예약완료하기 버튼 */}
                    <div className="drawerBtnArea fieldBookingBtnArea">
                        <button className="button btnPrimary" onClick={onFieldBookingComplete}>
                            예약완료하기
                        </button>
                    </div>
                </div>

                <DirectionSnackbar
                    direction={'up'}
                    position={'bottom'}
                    open={snackBarOpen.open}
                    msg={
                        //default 문구 2023-04-12 오스틴이 결정
                        // 4/28일 수정됨
                        snackBarMsg === ''
                            ? '예약하실 수 없는 티타임 입니다. 다른 티타임을 선택해 주세요.'
                            : snackBarMsg
                    }
                    duration={2000}
                    setOpen={setSnackBarOpen}
                />
                {isLoading && <div className="loadingPage">{<Loading />}</div>}
            </Drawer>

            {/* 골프장 규정 및 개인정보 이용 전체 동의 Layer*/}
            <PolicyLayer
                open={agreementOpen}
                agreeType={agreeType}
                title={agreeLayerTitle}
                handleClose={agreementLayerClose}
                contents={placeboInformation || ''}
                golfClubName={golfClubName}
            />

            <DefaultAlert open={open} handleClose={handleClose} title={alertTitle} contents={alertContent} />

            {/*   <DefaultAlert
                open={isPossibleilityModalOpen}
                handleClose={handlePossibleilityModalClose}
                title={alertTitle}
                contents={alertContent}
            />*/}
        </>
    );
}

export default FieldBookingLayer;
