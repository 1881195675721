import react, {useState, useEffect, useCallback, useContext} from 'react';
import useCollapse from 'react-collapsed';
import {appUtils} from '../../../common/utils';
import LikeButton from '../../button/LikeButton';
import Icon from '../../icons/Icon';
import React from 'react';
import {fetchWrapper} from '../../../_helpers';
import {SUCCESS_CODE, YN} from '../../../common/constants';
import {FieldMainContext} from '../../../_store/context/FieldMainContext';
import {FieldMainContext_searchResult} from '../../../_store/context/FieldMainContext_SearchResult';
import {FieldMainContext_myPage} from '../../../_store/context/FieldMainContext_myPage';
import Spinner from '../../../common/loading/Spinner';
import Loading from '../../../common/loading/Loading';
import TeeTimeSpinner from '../../../common/loading/TeeTimeSpinner';

import Fragment from 'react';
import {clickTeetimeAccordianBtn, clickTeetimeItem} from '../../../_helpers/amplitude/events/click.event';
import {getAmplitudeRootPath} from '../../../_helpers/amplitude/amplitude.helper';
import {modalCheckReservation} from '../../../_helpers/amplitude/events/etc.event';
import {bookingInfoProperty} from 'common/payment/locales/kr/booking-info-property';
import {paymentConstant} from 'common/payment';
import Badge from 'components/badge/Badge';
import {useSelector} from 'react-redux';

function Collapse(props) {
    const collapseProps = useCollapse(props);
    return props.children(collapseProps);
}

const {REACT_APP_INSTALL_INDUCE_ENABLED} = process.env;

const TeeTimeHorizonList = ({
    list,
    contextFlag,
    onTeaTimeOpen,
    setIsSnackBarOpen,
    onClickGolfClubListItem,
    deleteInterestItem,
    isMyPage,
    searchDate,
    timeSheetDrawerOpen,
    setPaymentModalOpen,
    onClickSelfCheckInBtn
}) => {
    const {region, teeTime, playerCnt, holeCnt, greenFee, paymentType} = useContext(
        contextFlag === 'main'
            ? FieldMainContext
            : contextFlag === 'search-result'
            ? FieldMainContext_searchResult
            : FieldMainContext_myPage
    );
    const [isExpanded, setExpanded] = useState(false);
    //티타임 데이터
    const [teeTimeData, setTeeTimeData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    // promotion 정보 여부
    const {displayYn, activeYn} = useSelector((state) => state?.promotion);
    const promotionYn = displayYn === YN.Y && activeYn === YN.Y;

    useEffect(() => {
        if (isExpanded === true) {
            getTeeTimeData();
        } else {
        }
    }, [isExpanded]);

    const getTeeTimeData = useCallback(() => {
        setIsLoading(true);
        const payload = {
            golfClubId: `${list.id}`,
            roundDate: appUtils.dateFormatTypeDash(searchDate),
            address: appUtils.filterObjToArr(region, 0),
            teeTimeCode: appUtils.filterObjToArr(teeTime, 0),
            minimumRoundPlayer: appUtils.filterObjToArr(playerCnt, 0),
            hole: appUtils.filterObjToArr(holeCnt, 0),
            greenFeeCode: appUtils.filterObjToArr(greenFee, 0),
            /* 2023.07.07 결제방식 추가 */
            paymentType: appUtils.filterObjToArr(paymentType, 0)
        };

        /*console.log(payload);*/

        fetchWrapper.post(`/booking/pre/timesheet/`, payload).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                response?.data?.timeSheetTables?.map((timeSheetTable) => {
                    timeSheetTable.timeSheets.map((timeSheet) => {
                        timeSheet['isActive'] = false;
                    });
                });

                setIsLoading(false);

                setTeeTimeData(response?.data || null);

                // setIsLoading(false);
            }
        });
    }, []);

    const teeTimeSelect = (timeSheetData) => {
        /* 웹 환경에서 선결제, 예약금결제 예약시도 시 앱설치 유도 팝업 노출 */
        if (
            [paymentConstant.paymentType.PREPAY, paymentConstant.paymentType.RESERVE].includes(
                timeSheetData.paymentType
            ) &&
            Number(REACT_APP_INSTALL_INDUCE_ENABLED) &&
            !window.ReactNativeWebView
        ) {
            setPaymentModalOpen(true);
            return;
        }

        const querySelector = document.querySelectorAll('.teeTimeBoxSpan-select');
        if (querySelector.length > 0) {
            /*console.log(querySelector);*/
            querySelector[0].classList.remove('teeTimeBoxSpan-select');
        }

        const tempTeeTimeData = JSON.parse(JSON.stringify(teeTimeData));
        tempTeeTimeData?.timeSheetTables?.filter((timeSheetTable) => {
            timeSheetTable?.timeSheets?.map((timeSheet) => {
                if (timeSheet.timeSheetId === timeSheetData.timeSheetId) {
                    timeSheet['isActive'] = !timeSheet.isActive;

                    if (timeSheet.isActive === true) {
                        fetchWrapper
                            .get(
                                `/amplitude-events/view-reservation?golfClubId=${list.id}&timeSheetId=${timeSheetData.timeSheetId}`
                            )
                            .then((response) => {
                                let teetime_range = timeSheetData?.roundTime.split(':');

                                let location;
                                console.log(contextFlag, 'contextFlag');
                                if (contextFlag === 'main') {
                                    location = 'home';
                                } else if (contextFlag.includes('search-result')) {
                                    location = 'search';
                                } else {
                                    location = 'favorite';
                                }

                                sessionStorage.setItem('reservation_start_point', location);

                                if (teetime_range.length > 0)
                                    clickTeetimeItem(
                                        response.data,
                                        getAmplitudeRootPath(),
                                        teetime_range.length > 0 ? Number(teetime_range[0]) : '',
                                        location
                                    );
                            });

                        const fullIsoString = new Date(
                            appUtils.getCompatibleDate(tempTeeTimeData?.searchDate) + ' ' + timeSheet.roundTime
                        );

                        const fullIsoArr = appUtils.dateFormatTypeFull(fullIsoString).split(' ');
                        let dateStr = fullIsoArr[0];
                        let timeStr = fullIsoArr[1];

                        const teeTimeDataPayload = {
                            golfCLubName: tempTeeTimeData.golfClubName,
                            golfClubShortName: tempTeeTimeData.golfClubShortName,
                            searchDate: tempTeeTimeData.searchDate,
                            bookingUrl: tempTeeTimeData.bookingUrl,
                            dateStr: dateStr,
                            timeStr: timeStr,
                            golfClubId: list.id
                        };

                        timeSheetDrawerOpen(timeSheet, teeTimeDataPayload);
                    }
                } else {
                    timeSheet['isActive'] = false;
                }
            });
        });

        setTeeTimeData((prev) => {
            return tempTeeTimeData;
        });
    };

    return (
        <>
            <Collapse isExpanded={list.isToggle}>
                {({isExpanded, getToggleProps, getCollapseProps}) => (
                    <>
                        <li key={list?.id} className="golfClubBox">
                            <div
                                className="infoArea"
                                style={{cursor: 'none'}}
                                onClick={(e) => onClickGolfClubListItem(e, list?.id)}
                            >
                                <div style={{width: '100%', cursor: 'pointer'}}>
                                    <strong className="place">
                                        <div className="pr-5">{list?.golfClubShortName}</div>
                                        {list?.isSelfCheckInPossible && (
                                            <>
                                                {list?.promotionActiveYn === YN.Y && <Badge type={'EVENT'} />}
                                                <Badge
                                                    type={'SELF_CHECK_IN_ARROW'}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onClickSelfCheckInBtn();
                                                    }}
                                                />
                                            </>
                                        )}
                                        {/* {list?.partnership === false && <span className="statusIcon line">미제휴</span>} */}
                                        {list?.partnership === false && <Badge type={'UNAFFILIATED'} />}
                                    </strong>
                                    <span className="location">
                                        {list?.golfClubArea} · {list?.userDistance}
                                        km
                                    </span>
                                </div>
                                {contextFlag === 'mypage-favorites' ? (
                                    <>
                                        <b className="price">
                                            {list?.remaining === 0
                                                ? list?.partnership === true
                                                    ? '잔여 티타임이 없습니다.'
                                                    : ''
                                                : `${appUtils.numberFormat(list?.greenFee)}원~`}
                                        </b>
                                    </>
                                ) : (
                                    <>
                                        <b className="price">
                                            {list?.remaining === 0 || list?.greenFee === 0 || list?.greenFee === '0'
                                                ? /* 2023-04-17 오스틴과 협의 즐겨찾는 골프장 / 메인 그린피 골프장 문의로 통일 (티타임이 없으면 그린피도 없음)*/
                                                  '그린피 골프장 문의'
                                                : `${appUtils.numberFormat(list?.greenFee)}원~`}
                                        </b>
                                    </>
                                )}
                            </div>
                            <div className="sideArea">
                                <LikeButton
                                    clubId={list?.id}
                                    interest={list?.golfClubLike}
                                    golfClubName={list?.golfClubName}
                                    setIsSnackBarOpen={setIsSnackBarOpen}
                                    deleteInterestItem={deleteInterestItem}
                                    isMyPage={isMyPage}
                                    clickItem={list}
                                />
                                {list?.remaining > 0 ? (
                                    <button
                                        {...getToggleProps({
                                            onClick: () => {
                                                setExpanded((prevExpanded) => !prevExpanded);
                                                onTeaTimeOpen(list.id, isExpanded);

                                                if (!isExpanded === true) {
                                                    fetchWrapper
                                                        .get(`/amplitude-events/view-cc-detail?golfClubId=${list?.id}`)
                                                        .then((response) => {
                                                            if (response.code === SUCCESS_CODE) {
                                                                clickTeetimeAccordianBtn(
                                                                    response.data,
                                                                    getAmplitudeRootPath(),
                                                                    !isExpanded
                                                                );
                                                            }
                                                        });
                                                }
                                            }
                                        })}
                                        type="button"
                                        className={`btnTeaTime`}
                                    >
                                        <strong
                                            className="count"
                                            /*className={`${
                                                contextFlag === 'mypage-favorites' &&
                                                list?.remaining === 0 &&
                                                'hide'
                                            } count`}*/
                                        >
                                            {list?.remaining}팀
                                        </strong>

                                        {list?.isToggle ? (
                                            <Icon name="circle-arrow-top" width="18" height="19" viewBox="0 0 18 19" />
                                        ) : (
                                            <Icon
                                                name="circle-arrow-bottom"
                                                width="18"
                                                height="19"
                                                viewBox="0 0 18 19"
                                            />
                                        )}
                                    </button>
                                ) : (
                                    <button type="button" className={`btnTeaTime`}>
                                        <strong
                                            className="count"
                                            /*className={`${
                                            contextFlag === 'mypage-favorites' &&
                                            list?.remaining === 0 &&
                                            'hide'
                                            } count`}*/
                                        >
                                            {list?.remaining}팀
                                        </strong>
                                    </button>
                                )}
                            </div>
                        </li>

                        <div className="teeTimeBox" {...getCollapseProps()}>
                            <>
                                {teeTimeData ? (
                                    teeTimeData?.timeSheetTables?.map((item, idx) => (
                                        <span key={idx}>
                                            {item?.timeSheets?.map((timeSheets, idx) => (
                                                <span
                                                    className={`teeTimeBoxSpan ${
                                                        timeSheets?.isActive === true ? 'teeTimeBoxSpan-select' : ''
                                                    }`}
                                                    key={idx}
                                                    onClick={() => {
                                                        teeTimeSelect(timeSheets);
                                                    }}
                                                >
                                                    <b>{timeSheets?.roundTime}</b>
                                                    <strong>
                                                        {timeSheets.greenFeePerPerson === 0
                                                            ? '골프장 문의'
                                                            : appUtils.numberFormat(timeSheets?.greenFeePerPerson) +
                                                              '원'}
                                                    </strong>
                                                    {timeSheets?.isHomepageOpen === true ? (
                                                        <i className="teeTimeBoxIcon-golf-course">
                                                            <Icon
                                                                name="icon-home"
                                                                width="12"
                                                                height="12"
                                                                viewBox="0 0 12 12"
                                                            ></Icon>
                                                            {bookingInfoProperty.booking_homepage}
                                                        </i>
                                                    ) : (
                                                        /* TODO: paymentType이 서버에서 변경되면 같이 변경할 것*/
                                                        ['현장결제', ''].includes(timeSheets?.paymentTypeName) ===
                                                            false && (
                                                            <i className="teeTimeBoxIcon-advance-payment">
                                                                <Icon
                                                                    name="icon-golf-advancepayment"
                                                                    width="12"
                                                                    height="12"
                                                                    viewBox="0 0 12 12"
                                                                ></Icon>
                                                                {timeSheets?.paymentTypeName}
                                                            </i>
                                                        )
                                                    )}
                                                </span>
                                            ))}
                                        </span>
                                    ))
                                ) : (
                                    <div className="teeTimeLoadingBox">
                                        <Spinner spinnerSize={30} />
                                    </div>
                                )}
                            </>
                        </div>
                    </>
                )}
            </Collapse>
            <div className="golfListGap"></div>
        </>
    );
};

export default TeeTimeHorizonList;
