import React, {useCallback, useEffect, useState} from 'react';
import Button from '../../components/button/Button';
import Header from '../../components/Header';
import TitleArea from '../../components/common/TitleArea';
import useWindowDimensions from '../hooks/UseWindowDimensions';
import './membership.scss';
import AuthenticationFields from './AuthenticationFields';
import * as Yup from 'yup';
import {alertMsg, errMsg, successMsg} from '../../common/messages';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {EMAIL_REG, SUCCESS_CODE} from '../../common/constants';
import {fetchWrapper, history} from '../../_helpers';
import {useDispatch} from 'react-redux';
import {timerActions} from '../../_store/timer.slice';
import {appUtils} from '../../common/utils';
import DefaultAlert from '../alert/DefaultAlert';
import {appActions} from '../../_store/app.slice';

/**
 * findId, findPW 에서 사용하던 휴대폰 본인인증 통합
 * */
function FindPW(props) {
    const {
        title, // 본인인증 공통타이틀
        type //  id || pw
    } = props;

    const {height} = useWindowDimensions(); // 디바이스 높이값 체크
    const dispatch = useDispatch();
    const [formModify, setFormModify] = useState(true); // 폼 내 인풋제어

    // 만 14세 이상 회원가입 안내 모달
    const [open, setOpen] = useState(false);
    const [alertData, setAlertData] = useState({
        title: '',
        description: ''
    });
    const [authValidation, setAuthValidation] = useState({
        message: '',
        error: false
    });
    // const handleOpen = () => setOpen(true);

    const initForm = () => {
        setFormModify(true); // form disabled 처리
        setActiveTimer(false); // 타이머 노출 처리
        setReactive(false); // 동작처리
        setReqNum('');
        setCertDate('');
    };

    const handleClose = () => setOpen(false);

    const [requestConfirm, _setRequestConfirm] = useState(false);
    // 하단 '인증번호', '다음'버튼
    const [activeButton, setActiveButton] = useState(false);
    const [activeTimer, setActiveTimer] = useState(false);
    const [reactive, setReactive] = useState(false);
    const [reqNum, setReqNum] = useState('');
    const [_certDate, setCertDate] = useState('');

    const [findIdData, setFindIdData] = useState({
        userBirth: '',
        userGender: 1,
        userName: '',
        userPhone: ''
    });
    const nextPage = (data) => {
        if (!data) return;
        history.navigate(`/member/find-pw/setting-pw`, {state: data});
    };

    const nextPage_FindId = (userData) => {
        history.navigate('/member/find-id/result', {state: {userData}});
    };

    useEffect(() => {
        const listener = (event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault();
            }
        };
        document.addEventListener('keydown', listener);

        /* 로그인 상관 없이 변경 페이지 머물도록 하단 주석 처리 */
        // if (authUser) history.navigate("/");

        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, []);

    // 인증 완료시 프로세스 정지 처리
    useEffect(() => {
        if (requestConfirm) {
            dispatch(timerActions.setDone(requestConfirm));
            setAuthValidation({
                error: false,
                message: successMsg.USER_AUTH_SUCCESS
            });
            // 인증완료시
        }
    }, [requestConfirm]);
    /**
     * 인증 요청
     * Authentication.js 와 동일
     */
    const requestIdentity = (payloadData) => {
        fetchWrapper.post(`/authentication/phone`, {...payloadData}).then((response) => {
            if (response?.code === SUCCESS_CODE) {
                if (response?.alertFlag) return authProcess(response?.data);
            } else {
                setAlertData({
                    title: response?.data?.title,
                    description: response?.data?.message
                });
                setOpen(true);
            }
        });
    };

    /**
     * 인증 확인
     */
    const checkAuthNumber = () => {
        const smsNum = document.querySelector("input[name='sms']").value;
        const confirmSeq = '01';

        const defaultData = {confirmSeq, reqNum, smsNum};
        const payload = type === 'id' ? {...defaultData} : {...defaultData, purpose: 'FIND_PW'};

        fetchWrapper.post(`/authentication/confirm`, payload).then((response) => {
            if (type === 'id') {
                // 아이디찾기
                if (response?.code === '0000' || response?.code === '8015') {
                    // 사용자 정보 없으면 인증성공 0000, 탈퇴한 게정상태면 8015
                    // 포워딩 페이지가 필요함
                    dispatch(appActions.setReqNum(null));
                    setAlertData({
                        title: response?.data?.title || null,
                        description: response?.data?.message || response?.message
                    });
                    setOpen(true);
                    history.navigate('/member/notFoundAccount');
                } else if (response?.code === '5000') {
                    //활성화 아이디 정보 존재
                    // findIdInfo();
                    // console.log(response?.data);
                    nextPage_FindId(response?.data);
                } else {
                    setAlertData({
                        title: response?.data?.title || null,
                        description: response?.data?.message || response?.message
                    });
                    setOpen(true);
                }
            } else {
                //비밀번호 찾기
                if (response?.code === '0000') {
                    findPwInfo(findIdData, {
                        userId: '',
                        certDate: response.data?.contents?.certDate,
                        reqNum: response.data?.contents?.reqNum,
                        smsNum: smsNum
                    });
                } else {
                    dispatch(appActions.setReqNum(null));
                    setAlertData({
                        title: response?.data?.title,
                        description: response?.data?.message
                    });
                    setOpen(true);
                }
            }
        });
    };

    const findIdInfo = () => {
        fetchWrapper
            .post(`/member/find-id`, findIdData)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    nextPage_FindId(response?.data);
                } else if (response.code === '4000') {
                    setAlertData({
                        title: '인증정보 불일치',
                        description: response.message || response.data.message
                    });

                    setOpen(true);
                } else {
                    setAlertData({
                        title: response?.data?.title,
                        description: response?.data?.message
                    });

                    setOpen(true);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const findPwInfo = (data, confirmData) => {
        fetchWrapper
            .post(`/member/info`, {...data, reqNum: confirmData.reqNum})
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    // 기존에 등록된 데이터가 존재하는데 이메일가입이 아닌 경우
                    if (response.data && response.data.channel !== 'EMAIL') {
                        // 등록된 아이디 화면으로 표시
                        return history.navigate('/member/find-id/result', {state: {userData: response.data}});
                    }
                    nextPage({...confirmData, userId: response?.data?.email});
                } else if (response.code === '8000') {
                    // 등록된 개인정보 없음
                    history.navigate('/member/notFoundAccount');
                    // dispatch(appActions.setReqNum(null));
                    // setAlertData({
                    //     title: '',
                    //     description: response?.message
                    // });
                    // setOpen(true);
                }
            })
            .catch((err) => alert(err));
    };

    /**
     * 입력값 validation
     */
    const onClickValidation = (data) => {
        let year = '19';
        let genderNumber = parseInt(data.userGender);
        // 1/2 : 19--, 3/4 : 20--, 5/6 : 19-- 외국인, 7/8 : 20-- 외국인, 9/0 : 18--
        if (genderNumber === 3 || genderNumber === 4 || genderNumber === 7 || genderNumber === 8) {
            year = '20';
        } else if (genderNumber === 9 || genderNumber === 0) {
            year = '18';
        }
        let birth = `${year}${data.userBirth}`;
        let age = appUtils.calcAge(birth);
        // 만 14세 미만 가입불가
        if (age < 14) {
            setAlertData({
                title: alertMsg.introTit,
                description: errMsg.USER_BIRTH_UNDER_14
            });
            setOpen(true);
            return false;
        }

        let payloadData = {
            gender: genderNumber,
            fgnGbn: '1',
            certDate: appUtils.getYYYYMMDDHHMMSS(),
            telco: data.userTelecom, // 통신사
            cellPhone: data.userPhone,
            name: data.userName,
            birth: birth
        };
        // id 찾기 위해 변수로 선언
        setFindIdData({
            userBirth: birth.substring(2),
            userGender: genderNumber,
            userPhone: data.userPhone,
            userName: data.userName
        });

        // 인증번호 호출
        requestIdentity(payloadData);
    };

    const authProcess = (data) => {
        setAlertData({
            title: '인증번호 발송',
            description: '인증번호를 확인해주세요.'
        });
        setOpen(true);

        if (data?.contents?.reqNum && data?.contents?.certDate) {
            setFormModify(false); // form disabled 처리
            setActiveTimer(true); // 타이머 노출 처리
            setReactive(true); // 동작처리
            setReqNum(data.contents.reqNum);
            setCertDate(data.contents.certDate);
        } else {
            initForm();
        }
    };

    const validationSchema = Yup.object().shape(appUtils.getValidationConfig('FIND_PASSWORD_2'));

    const {
        handleSubmit,
        control,
        formState: {errors},
        setValue,
        // register,
        watch,
        setFocus,
        clearErrors
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(validationSchema)
    });

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const [_emailValidation, setEmailValidation] = useState({
        message: '',
        error: false
    });

    const onBlurEmail = (e) => {
        clearErrors('userId');
        setEmailValidation({
            error: false,
            message: ''
        });
        let value = e.target.value?.toLowerCase();
        if (!value.length) {
            setEmailValidation({
                error: true,
                message: '이메일 아이디를 입력하세요.'
            });
            return false;
        } else if (value.length > 0 && !value.match(EMAIL_REG)) {
            setEmailValidation({
                error: true,
                message: '이메일 형식으로 입력해주세요.'
            });
            return false;
        }
        // console.debug("onBlurEmail", e);
        onValidEmail(value);
        return true;
    };

    const onValidEmail = (value) => {
        let lowerMail = value.toLowerCase();
        if (!lowerMail.length || !value.match(EMAIL_REG)) {
            return false;
        }
        fetchWrapper.get(`/member/email/${lowerMail}?check=N`, null).then((response) => {
            // errors['userId'] = { type:'test', message: response?.message}
            if (response.code === SUCCESS_CODE) {
                setEmailValidation({
                    error: false,
                    message: response?.message
                });
            } else {
                setEmailValidation({
                    error: true,
                    message: response?.message
                });
                forceUpdate();
            }
        });
        return true;
    };
    // 페이지 진입시 첫 input 자동 포커스
    useEffect(() => {
        appUtils.firstInputFocus();
    }, []);

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="membership">
                    <div className="inner">
                        <form onSubmit={handleSubmit(onClickValidation)}>
                            <TitleArea pageTitle={title} />
                            <div className="fieldAreaWrap">
                                {/* membership QA - 이메일 필드 삭제
                                {/* 에러 메시지 출력시 fieldArea, textField, infoMsg에 클래스 error 추가. */}
                                {/*<div className="row fieldArea">*/}
                                {/*    <input*/}
                                {/*        {...register("userId")}*/}
                                {/*        name="userId"*/}
                                {/*        type="text"*/}
                                {/*        className={`textField ${*/}
                                {/*            (errors?.userId ||*/}
                                {/*                emailValidation.error ===*/}
                                {/*                    true) &&*/}
                                {/*            "is-invalid error"*/}
                                {/*        }`}*/}
                                {/*        disabled={!formModify}*/}
                                {/*        onChange={(e) => {*/}
                                {/*            let value =*/}
                                {/*                e.target.value.replaceAll(*/}
                                {/*                    /\s/gi,*/}
                                {/*                    ""*/}
                                {/*                );*/}
                                {/*            value = value.replace(*/}
                                {/*                /[ \{\}\[\]\/?,;:|\)*~!^\-_+┼<>\#$%&\ '\"\\(\=]/gi,*/}
                                {/*                ""*/}
                                {/*            );*/}
                                {/*            e.target.value = value;*/}
                                {/*            onValidEmail(value);*/}
                                {/*        }}*/}
                                {/*        maxLength={EMAIL_MAXLENGTH}*/}
                                {/*        placeholder="이메일 아이디 입력"*/}
                                {/*        onFocus={appUtils.offsetPadding}*/}
                                {/*        onBlur={(e) => {*/}
                                {/*            appUtils.defaultPadding();*/}
                                {/*            onBlurEmail(e);*/}
                                {/*        }}*/}
                                {/*    />*/}
                                {/*    <p className="infoMsg error">*/}
                                {/*        {emailValidation.error*/}
                                {/*            ? emailValidation.message*/}
                                {/*            : errors?.userId?.message}*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                                <AuthenticationFields
                                    formProp={{
                                        errors,
                                        control,
                                        watch,
                                        setValue,
                                        setFocus
                                    }}
                                    onSubmit={handleSubmit(onClickValidation)}
                                    formModify={formModify}
                                    activeTimer={activeTimer}
                                    setActiveTimer={setActiveTimer}
                                    reactive={reactive}
                                    setReactive={setReactive}
                                    setActiveButton={setActiveButton}
                                    authValid={{
                                        authValidation: authValidation,
                                        setAuthValidation: setAuthValidation
                                    }}
                                />
                            </div>
                            <div className="btnFixedBottom">
                                <div className="inner">
                                    <Button
                                        type="button"
                                        disabled={!activeButton}
                                        background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                        onClick={checkAuthNumber}
                                    >
                                        다음
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* 만 14세 미만 가입불가 */}
            <DefaultAlert
                open={open}
                handleClose={handleClose}
                title={alertData.title}
                contents={alertData.description}
            />
        </>
    );
}

export default FindPW;
