import React, {useCallback, useEffect, useState} from 'react';
import Button from '../../button/Button';
import Header from '../../Header';
import TitleArea from '../../common/TitleArea';
import DefaultAlert from '../../alert/DefaultAlert';
import useWindowDimensions from '../../hooks/UseWindowDimensions';
import SettingPwField from '../../membership/SettingPwField';
import {appUtils} from '../../../common/utils';
// import {useLocation} from 'react-router-dom';
import {fetchWrapper, history} from '../../../_helpers';
import '../../membership/membership.scss';
import {SUCCESS_CODE} from '../../../common/constants';

function ModifyPw({title}) {
    // const location = useLocation();
    //디바이스 높이값 체크
    const {height} = useWindowDimensions();

    const [formValue, setFormValue] = useState({
        userPassword: '',
        userMail: history.location?.state?.userMail
    });
    const [passwordMinLength, setPasswordMinLength] = useState(false);
    const [passwordRegYN, setPasswordRegYN] = useState(false);
    const [passwordIncludeId, setPasswordIncludeId] = useState(false);

    //하단 '변경 완료'버튼
    const [activeButton, setActiveButton] = useState(false);
    const isActive = useCallback(() => {
        let isOk = passwordMinLength && passwordRegYN && passwordIncludeId;

        setActiveButton(!!isOk);
    }, [passwordMinLength, passwordIncludeId, passwordRegYN]);

    const [payload, _setPayload] = useState({
        loginId: history.location?.state?.userMail,
        newPassword: ''
    });

    useEffect(() => {
        isActive();
    }, [isActive]);

    const setPasswordValid = () => {
        // let id = formValue.userMail;
        // let password = formValue.userPassword;
        // setPasswordMinLength(minLengthCheck(formValue.userPassword, window.config.password.minLength));
        // setPasswordIncludeId(password && id && !password.includes(id));
        // setPasswordRegYN(appUtils.passwordRegCheck(formValue.userPassword));
        setPasswordMinLength(appUtils.minLengthCheck(formValue.userPassword, window.config.password.minLength));
        setPasswordIncludeId(appUtils.passwordValidCheck(formValue));
        setPasswordRegYN(appUtils.passwordRegCheck(formValue.userPassword));
    };

    /** utils.js 로 이동*/
    // const minLengthCheck = (value, min) => {
    //     return value.length >= min;
    // };

    //하단 '변경 완료'버튼
    const nextPage = () => {
        payload.newPassword = formValue.userPassword;
        // TODO ollie password 변경 API
        if (activeButton) {
            fetchWrapper.put(`/member/password`, payload).then((response) => {
                if (response?.code === SUCCESS_CODE) {
                    history.navigate('/member/find-pw/result');
                } else {
                }
            });
        }
    };

    //비밀번호 변경 완료 Alert
    const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Header back={true} />
            <div id="contents" style={{minHeight: `${height}px`}}>
                <div id="membership">
                    <div className="inner">
                        <form>
                            <TitleArea pageTitle={title} />
                            <div className="fieldAreaWrap">
                                <SettingPwField
                                    formValue={formValue}
                                    setFormValue={setFormValue}
                                    passwordMinLength={passwordMinLength}
                                    passwordRegYN={passwordRegYN}
                                    passwordIncludeId={passwordIncludeId}
                                    setPasswordValid={setPasswordValid}
                                />
                            </div>
                            <div className="btnFixedBottom">
                                {' '}
                                {/* 버튼이 키보드 위에 고정되어야 하는 경우 btnFixedBottom에 클래스 "fixed" 추가 */}
                                <div className="inner">
                                    <Button
                                        type="button"
                                        background={`${activeButton ? '#3906C3' : '#A0A4AF'}`}
                                        onClick={nextPage}
                                    >
                                        변경 완료
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* 비밀번호 변경 완료 Alert */}
            <DefaultAlert
                open={open}
                handleClose={handleClose}
                title={'비밀번호 변경 완료'}
                contents={'새로운 비밀번호로 변경되었습니다.'}
                pageLink="/mypage/modify" // 마이페이지로 돌아가기
            />
        </>
    );
}

export default ModifyPw;
