import React, {useCallback, useEffect, useState} from 'react';
import {NavLink, Link, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {checkinActions} from '../../_store/checkin.slice';
import SelfCheckin from '../smart-round/SelfCheckin';
import {fetchWrapper} from '../../_helpers';
import {SUCCESS_CODE} from '../../common/constants';
import {clickFoodMenu} from '../../_helpers/amplitude/events/click.event';
import {getAmplitudeRootPath} from '../../_helpers/amplitude/amplitude.helper';

function RoundNavigation({roundNavi, courseInfo, handleAlertOpen}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [mypageBookingPage, setMyPageBookingPage] = useState('');

    // Navigation 있을때 하단 여백 추가를 위한 클래스명 추가
    useEffect(() => {
        document.body.classList.add('naviBottom');
        return () => {
            document.body.classList.remove('naviBottom');
        };
    }, []);

    // useEffect(() => {
    //     if (courseInfo?.roundBookingCount > 1 && location?.state?.isDetail) {
    //         setMyPageBookingPage('/mypage/booking');
    //     }
    // }, [courseInfo]);

    const roundNavList = [
        {
            pathname: 'checkin',
            classname: 'checkin',
            title: '셀프 체크인'
        },
        {
            pathname: `/course-guide`,
            classname: 'courseguide',
            title: '코스가이드'
        },
        {
            pathname: `/food`,
            classname: 'food',
            title: '음식 메뉴'
        }
        // {
        //     pathname: "/share",
        //     classname: "payment readyAlert",
        //     title: "정산·결제"
        // }
    ];

    const handleSmartRoundOpen = () => {
        // 골프장 상세에서 '코스가이드'로 들어왔을 때 버튼 노출 여부 & Alert 오픈 설정
        if (location?.state?.isDetail) {
            if (courseInfo.roundBookingCount === undefined) {
                dispatch(checkinActions.showSmartRoundCheckIn(false));
                return;
            }

            if (courseInfo.roundBookingCount >= 1) {
                dispatch(checkinActions.showSmartRoundCheckIn(true));
            } else if (courseInfo.roundBookingCount === 0) {
                handleAlertOpen('예약 내역이 없습니다.\n예약 후 이용해 주세요.');
            }
        } else {
            dispatch(checkinActions.showSmartRoundCheckIn(true));
        }
    };

    const onClickFoodTab = () => {
        if (!courseInfo?.isMenuApplied) {
            handleAlertOpen(
                '현재 해당 골프장의 음식메뉴\n미리보기 서비스를 준비중입니다.\n빠른 시일내에 이용 가능하도록 준비하겠습니다.'
            );
        }

        fetchWrapper
            .get(`/amplitude-events/view-cc-detail?golfClubId=${location?.state?.roundData?.golfClubId}`, null)
            .then((response) => {
                if (response.code === SUCCESS_CODE) {
                    clickFoodMenu(response?.data, courseInfo?.isMenuApplied, getAmplitudeRootPath());
                }
            });
    };

    return (
        <>
            <SelfCheckin />
            <div id="Navigation">
                {roundNavList.map((navi) =>
                    navi.pathname === 'checkin' ? (
                        <Link
                            to={courseInfo?.roundBookingCount > 1 ? `${mypageBookingPage}` : null}
                            state={{
                                roundData: {
                                    golfClubId: location.state?.roundData.golfClubId,
                                    bookingId: location.state?.isDetail
                                        ? courseInfo?.bookingId
                                        : location.state?.roundData.bookingId,
                                    golfClubName: location.state?.roundData.golfClubName
                                },
                                courseInfo: courseInfo,
                                backLink: 'DetailCourseGuide',
                                isDetail: location.state?.isDetail
                            }}
                            className={navi.classname}
                            key={navi.pathname}
                            onClick={handleSmartRoundOpen}
                            type="button"
                        >
                            <i className={navi.classname}></i>
                            <span>{navi.title}</span>
                        </Link>
                    ) : navi.classname === 'food' ? (
                        <NavLink
                            to={courseInfo?.isMenuApplied ? `/food` : null}
                            className={`${navi.classname} ${location.pathname === '/food' ? 'on' : ''}`}
                            state={{
                                roundData: location.state?.roundData,
                                courseInfo: courseInfo,
                                isDetail: location.state?.isDetail,
                                isMenuApplied: courseInfo?.isMenuApplied
                            }}
                            key={navi.pathname}
                            onClick={onClickFoodTab}
                        >
                            <i className={navi.classname}></i>
                            <span>{navi.title}</span>
                        </NavLink>
                    ) : (
                        <NavLink
                            to={`${navi.pathname}`}
                            state={{
                                roundData: location.state?.roundData,
                                courseInfo: courseInfo,
                                isDetail: location.state?.isDetail
                            }}
                            className={({isActive}) => `${navi.classname} ${isActive ? 'on' : ''}`}
                            key={navi.pathname}
                        >
                            <i className={navi.classname}></i>
                            <span>{navi.title}</span>
                        </NavLink>
                    )
                )}
            </div>
        </>
    );
}

export default RoundNavigation;
